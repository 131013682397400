import { FeedItemDisplayStatus } from "@marketpartner/backend-api"
import { MpFeedItem } from "@marketpartner/react-feed"
import { Box, Card } from "@mui/material"
import { FC, memo, ReactNode } from "react"
import { Bar } from "src/common/layout/Bar"
import { Spacer } from "src/common/layout/Spacer"
import { FeedItemContent } from "src/feeds/items/FeedItemContent"
import { FeedItemDisplayStatusIcon } from "src/feeds/items/FeedItemDisplayStatusIcon"
import { FeedItemLikes } from "src/feeds/items/FeedItemLikes"
import { FeedItemUser } from "src/feeds/items/FeedItemUser"
import { FeedDisplayMode, useFeedDisplayOptions } from "src/feeds/items/options/feed-display-context"
import { FeedItemSelectionContext, useFeedItemSelection } from "src/feeds/items/selection/feed-item-selection-context"

const indentationSpacing = 4

export type FeedItemProps = {
    item: MpFeedItem
    indentationLevel?: number
    memoFooterComponent?: ReactNode
}

export const FeedItem: FC<FeedItemProps> = ({
    item,
    ...props
}) => {
    const { selectedIds, toggleSelection } = useFeedItemSelection()
    const { displayMode, showDeletedItems } = useFeedDisplayOptions()

    if (displayMode === FeedDisplayMode.Pending && item.displayStatus !== FeedItemDisplayStatus.Pending) {
        return <></>
    }

    if (!showDeletedItems && item.displayStatus === FeedItemDisplayStatus.Removed) {
        return <></>
    }

    return <MemoFeedItem
        item={item}
        isSelected={selectedIds.has(item.id)}
        toggleSelection={toggleSelection}
        {...props}
    />
}

type MemoFeedItemProps = FeedItemProps & {
    isSelected: boolean
    toggleSelection: FeedItemSelectionContext["toggleSelection"]
}

/**
 * Important: This component is very performance-sensitive due to the large number of items
 * that might be displayed.
 * 
 * To prevent performance issues, avoid using any sort of context here unless it 
 * definitely affects this specific item. This may mean using a context in FeedItem and 
 * extracting only the necessary parts (e.g. isSelected).
 * 
 * Props should be kept as minimal and basic as possible to prevent unnecessary re-renders.
 * 
 * Note that child components/JSX are always considered a new object every time and will always
 * trigger a re-render. Any component props must therefore be memorized in the parent 
 * component using useMemo().
 */
const MemoFeedItem = memo<MemoFeedItemProps>(({
    item,
    isSelected,
    toggleSelection,
    indentationLevel = 0,
    memoFooterComponent,
}) => {

    return <Card
        onClick={e => toggleSelection(item.id, e.ctrlKey)}
        elevation={0}
        sx={{
            mt: 1,
            mr: 2,
            ml: 2 + indentationLevel * indentationSpacing,
            py: 1,
            px: 2,
            backgroundColor: theme => isSelected ? theme.palette.action.selected : undefined,
        }}
    >
        <Box sx={{
            opacity: item.displayStatus === FeedItemDisplayStatus.Public ? 1 : 0.4
        }}>
            <Bar>
                <FeedItemUser item={item} />
                <Spacer />
                <FeedItemDisplayStatusIcon status={item.displayStatus} />
            </Bar>
            <FeedItemContent item={item} sx={{
                mt: 1,
                mb: 0.5,
            }} />
            <Bar spacing={4}>
                <FeedItemLikes likeCount={item.likedByRegistrationIds.size} />
                {memoFooterComponent}
            </Bar>
        </Box>
    </Card>

})