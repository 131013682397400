import { Paper } from "@mui/material";
import { FC } from "react";
import { ClientAppTemplates } from "src/app-templates/client-templates/ClientAppTemplates";
import { AllAppsTab } from "src/app-templates/deployments/global/AllAppsTab";
import { DomainsGrid } from "src/app-templates/domains/DomainsGrid";
import { Page } from "src/common/layout/Page";
import { RoutedTabs } from "src/common/routing/RoutedTabs";
import { fullSize } from "src/common/styles";


export const AppAdminPage: FC = () => {
    return <Page
        title="Apps"
        minContentSize={400}
    >
        <Paper sx={{ ...fullSize, minHeight: "fit-content" }}>
            <RoutedTabs tabs={[{
                label: "Apps",
                path: "apps",
                element: <AllAppsTab />
            }, {
                label: "Domains",
                path: "domains",
                element: <DomainsGrid />
            }, {
                label: "Client templates",
                path: "client-templates",
                element: <ClientAppTemplates />,
            }]} />
        </Paper>
    </Page>
}