import { Permission } from "@marketpartner/backend-api"
import { AddAlarm } from "@mui/icons-material"
import { Button, SxProps } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { Bar } from "src/common/layout/Bar"
import { BarAndFlex } from "src/common/layout/BarAndFlex"
import { Spacer } from "src/common/layout/Spacer"
import { useNewScheduledEmailDialog } from "src/email/scheduling/NewScheduledEmailDialog"
import { ScheduledEmailsGrid } from "src/email/scheduling/ScheduledEmailsGrid"
import { useEvent } from "src/events/event-context"
import { ifHas } from "src/login/IfHas"


export type ScheduledEmailsProps = {
    sx?: SxProps
}

export const ScheduledEmails: FC<ScheduledEmailsProps> = ({
    sx
}) => {
    const client = useClient()!
    const event = useEvent()!
    
    const scheduleDialog = useNewScheduledEmailDialog()

    return <BarAndFlex sx={sx} barContent={<Bar p={2} spacing={2}>
        <Spacer />
        {ifHas(Permission.SendEmails, client.id, event.id, <Button
            children="Schedule email"
            onClick={() => scheduleDialog.open({})}
            startIcon={<AddAlarm />}
            variant="contained"
            color="primary"
        />)}
    </Bar>}>
        <ScheduledEmailsGrid />
    </BarAndFlex>
}