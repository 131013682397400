import { EmailTemplateId } from '@marketpartner/backend-api';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Tooltip, useTheme } from "@mui/material";
import { FC } from 'react';
import { useClient } from 'src/clients/client-context';
import { backend } from 'src/common/api';

export type EmailTemplateNameProps = {
    id: EmailTemplateId | null | undefined
}

export const EmailTemplateName: FC<EmailTemplateNameProps> = ({ id }) => {
    const client = useClient()!
    
    const templateNameQuery = backend.emailTemplates.useGetEmailTemplatesForClient([client.id], {
        enabled: Boolean(id),
        select: templates => templates.find(template => template.id === id)?.name,
    })
    const theme = useTheme()

    if (!id) {
        return <i style={{ color: theme.palette.text.secondary }}>
            None
        </i>
    }

    if (templateNameQuery.data) {
        return <>{templateNameQuery.data}</>
    }

    if (templateNameQuery.error) {
        return <Box display="flex" alignItems="center">
            {id}
            <Tooltip title="Error loading email templates">
                <WarningIcon style={{ color: theme.palette.warning.main }} />
            </Tooltip>
        </Box>
    }

    if (templateNameQuery.isPending) {
        return <>&nbsp;</>
    }

    return <Box display="flex" alignItems="center">
        {id}
        <Tooltip title="Template does not exist">
            <ErrorIcon style={{ color: theme.palette.error.main }} />
        </Tooltip>
    </Box>
}