import { EventDetails } from '@marketpartner/backend-api'
import { Link } from '@mui/material'
import { GridColDef, GridSortItem } from '@mui/x-data-grid-pro'
import { FC, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useClient } from 'src/clients/client-context'
import { backend } from 'src/common/api'
import { QueryDataGrid, QueryDataGridProps } from 'src/common/grid/QueryDataGrid'
import { link } from 'src/common/routing/routes'
import { DateTimeText } from 'src/common/time/DateTimeText'

const gridColumns: GridColDef<EventDetails>[] = [
    {
        field: "id",
        headerName: "Id",
        flex: 1,
        minWidth: 200,
    },
    {
        field: "name",
        headerName: "Name",
        renderCell: ({ row }) => <EventNameCell event={row} />,
        flex: 3,
        minWidth: 400,
    },
    {
        field: "startTime",
        headerName: "Start",
        flex: 1,
        minWidth: 200,
        renderCell: ({ row }) => <DateTimeText dateTime={row.startTime} timezone={row.timezone} />
    },
    {
        field: "endTime",
        headerName: "End",
        flex: 1,
        minWidth: 200,
        renderCell: ({ row }) => <DateTimeText dateTime={row.endTime} timezone={row.timezone} />
    },
    {
        field: "tags",
        headerName: "Tags",
        flex: 1,
        minWidth: 200,
        renderCell: ({ row }) => row.tags.join(", ")
    }
]

type EventGridProps = Omit<QueryDataGridProps<EventDetails>, "queryOrRows" | "columns">
export const EventGrid: FC<EventGridProps> = ({ ...props }) => {
    const client = useClient()!
    const [sortModel, setSortModel] = useState<GridSortItem[]>([
        { field: "startTime", sort: "desc" }
    ])
    const eventsQuery = backend.events.useGetEvents<EventDetails[]>([client.id])

    return <QueryDataGrid
        queryOrRows={eventsQuery}
        columns={gridColumns}
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        disableRowSelectionOnClick
        {...props}
    />
}

type EventNameCellProps = {
    event: EventDetails
}

const EventNameCell: FC<EventNameCellProps> = ({
    event
}) => {
    const client = useClient()!

    return <Link
        component={RouterLink}
        to={link.toEvent(client.id, event.id)}
    >
        {event.name}
    </Link>
}