import { Add } from "@mui/icons-material"
import { Button, Paper } from "@mui/material"
import { FC } from "react"
import { Page } from "src/common/layout/Page"
import { fullSize } from "src/common/styles"
import { useNewPollDialog } from "src/polls/NewPollDialog"
import { PollsGrid } from "src/polls/PollsGrid"

export const PollsPage: FC = () => {
    const newPollDialog = useNewPollDialog()

    return <Page
        title="Polls"
        actions={<Button
            onClick={() => newPollDialog.open({})}
            startIcon={<Add />}
            variant="contained"
        >
            Create poll
        </Button>}
    >
        <Paper sx={{ ...fullSize }}>
            <PollsGrid />
        </Paper>
    </Page>
}