import { Scope, Session } from "@marketpartner/backend-api";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { ConfirmDeleteDialog } from "src/common/dialogs/ConfirmDeleteDialog";
import { LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { useEvent } from "src/events/event-context";


type DeleteSessionDialogProps = LockableDialogProps & {
    session: Session
}

const DeleteSessionDialog: FC<DeleteSessionDialogProps> = ({
    session,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const deletion = backend.agendaItems.useDelete()

    return <ConfirmDeleteDialog
        itemName={session.name}
        formProps={{
            action: () => deletion.mutateAsync([client.id, event.id, session.agendaId, session.id]),
            text: <>This will delete all associated bookings</>,
            confirmText: session.name,
        }}
        {...props}
    />
}

export const useDeleteSessionDialog = createPopupHook({
    scope: Scope.Event,
    element: DeleteSessionDialog,
})
