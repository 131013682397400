import { Button, DialogActions, DialogContent } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { uniq } from "lodash";
import { FC, MouseEventHandler, useState } from "react";
import { Link } from "react-router-dom";
import { LockableDialog } from "src/common/dialogs/LockableDialog";
import { convertToSpreadSheetString } from "src/common/grid/grid-export";

export type DynamicColumnOptions = {
    headerPrefix: string
    filter: (columnName: string) => boolean
}

const defaultOptions: DynamicColumnOptions = {
    headerPrefix: "",
    filter: () => true,
}

export function getDynamicColumnDefinitions<Row extends Record<string, unknown>>(
    data: Row[],
    propertyName: keyof Row,
    options: Partial<DynamicColumnOptions> = defaultOptions
) {
    const fullOptions = {
        ...defaultOptions,
        ...options
    }

    return getDynamicColumnNames(data, propertyName)
        .filter(columnName => fullOptions.filter(columnName))
        .map((columnName): GridColDef => ({
            field: `${String(propertyName)}.${columnName}`,
            headerName: `${fullOptions.headerPrefix}${columnName}`,
            width: 300,
            valueGetter: ({ row }) => row[propertyName]?.[columnName],
            renderCell: ({ value }) => <ColumnCell value={value} />,
        }))
}

export function getDynamicColumnNames<Row extends Record<string, unknown>>(
    data: Row[],
    propertyName: keyof Row
) {
    data = data ?? []
    return uniq(data
        .flatMap(row => Object.keys(row[propertyName] ?? {}))
    )
}

// TODO: Maybe add some special rendering in there based on the value type (e.g. dates)

export type ColumnCellProps = {
    value: unknown
}

export const ColumnCell: FC<ColumnCellProps> = ({ 
    value
}) => {

    const [isOpen, setIsOpen] = useState(false)

    const formattedValue = convertToSpreadSheetString(value)

    const handleClick: MouseEventHandler = e => {
        e.preventDefault()
        setIsOpen(true)
    }

    return <>
        <Link to="/#" onClick={handleClick} style={{ color: "inherit", textDecoration: "none" }}>{formattedValue}</Link>
        <LockableDialog
            open={isOpen}
            onClose={() => setIsOpen(false)}
            maxWidth="md"
        >
            <DialogContent sx={{ minWidth: 200, fontSize: '1.3em' }}>{formattedValue}</DialogContent>
            <DialogActions>
                <Button color="inherit" variant="contained" onClick={() => setIsOpen(false)}>Close</Button>
            </DialogActions>
        </LockableDialog>
    </>
}
