import { Role } from "@marketpartner/backend-api";
import { toUtcDateTime } from "@marketpartner/mp-common";
import { Grid, TextField } from "@mui/material";
import { DateTime } from "luxon";
import { FC, useState } from "react";
import { RoleSelector } from "src/access/RoleSelector";
import { DialogForm, ExtendableDialogFormProps } from "src/common/dialogs/DialogForm";
import { LuxonDateTimePicker } from "src/common/time/LuxonDateTimePickers";
import { localTimezone } from "src/common/time/time-utils";

export type TokenFormData = {
    name: string
    description: string
    expires: DateTime
    role: Role
    clientId: string | null
    eventId: string | null
}

export type TokenFormProps = ExtendableDialogFormProps<TokenFormData>

export const TokenForm: FC<TokenFormProps> = ({
    ...props
}) => {
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [expires, setExpires] = useState<DateTime | null>(null)
    const [role, setRole] = useState<Role | null>(null)
    const [clientId, setClientId] = useState<string | null>(null)
    const [eventId, setEventId] = useState<string | null>(null)

    return <DialogForm
        isValid={Boolean(name && expires && role)}
        formData={{
            name,
            description,
            expires: toUtcDateTime(expires!),
            role: role!,
            clientId,
            eventId,
        }}
        {...props}
    >
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    label="Name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    fullWidth
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Description"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    multiline={true}
                    rows={3}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <LuxonDateTimePicker
                    timezone={localTimezone}
                    label="Expires at"
                    value={expires}
                    onChange={setExpires}
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <RoleSelector
                    excludedRoles={[Role.RegisteredUser, Role.Admin]}
                    role={role}
                    onChangeRole={setRole}
                    clientId={clientId}
                    onChangeClientId={setClientId}
                    eventId={eventId}
                    onChangeEventId={setEventId}
                />
            </Grid>
        </Grid>
    </DialogForm>
}