import { Button, Container, Paper, Stack, Typography } from "@mui/material"
import { FC } from "react"


export type AccessDeniedProps = {
    doLogout: () => void
}

export const AccessDenied: FC<AccessDeniedProps> = ({
    doLogout
}) => {

    return <Container fixed maxWidth="xs">
        <Paper sx={{
            my: 16,
            p: 4
        }}>
            <Stack sx={{ alignItems: "center" }}>
                <Typography variant="h3" component="h1">Access denied</Typography>
                <Typography>Your access token is invalid or expired</Typography>
                <Button variant="contained" color="primary" onClick={doLogout} sx={{ mt: 4 }}>
                    Back to login screen
                </Button>
            </Stack>
        </Paper>
    </Container>
}