import { Registration, Scope } from "@marketpartner/backend-api";
import { casualName } from "@marketpartner/mp-common";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { ConfirmDeleteDialog } from "src/common/dialogs/ConfirmDeleteDialog";
import { LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { useEvent } from "src/events/event-context";


export type DeleteRegistrationDialogProps = LockableDialogProps & {
    registration: Registration
    onDelete?: () => void
}

const DeleteRegistrationDialog: FC<DeleteRegistrationDialogProps> = ({
    registration,
    onDelete,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const deleteMutation = backend.registrations.useDeleteRegistration({
        disableWaitForInvalidation: true,
        onSuccess: onDelete,
    })
    const name = casualName(registration)

    return <ConfirmDeleteDialog
        itemName={name}
        formProps={{
            text: <>
                {registration.approvalState === "Approved" && <p>
                    {name} has already been approved. This will revoke their event access.
                </p>}
                <p>
                    Deleting {name} will allow them to re-register for the event. If this is not desired, consider rejecting the registration instead.
                </p>
            </>,
            action: () => deleteMutation.mutateAsync([client.id, event.id, registration.id]),
        }}
        {...props}
    />
}

export const useDeleteRegistrationDialog = createPopupHook({
    scope: Scope.Event,
    element: DeleteRegistrationDialog,
})