import { FlatEpResource } from "@marketpartner/backend-api"
import { Avatar, Stack, Typography } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { link } from "src/common/routing/routes"
import { EventPartnerCategorizedGrid } from "src/content/event-partner/EventPartnerCategorizedGrid"
import { EventPartnerContent } from "src/content/event-partner/EventPartnerContent"
import { useEvent } from "src/events/event-context"


export const EventPartnerResources: FC = () => {
    const client = useClient()!
    const event = useEvent()!
    const resourcesQuery = backend.content.useGetEventPartnerResources([client.id, event.id])

    return <EventPartnerContent
        name="resources"
        link={link.toEpResources(event.eventPartnerId!, event.epEventToken!)}
        request={resourcesQuery}
        render={resources => <EventPartnerCategorizedGrid
            data={resources}
            renderItem={resource => <Resource resource={resource} />}
        />}
    />
}

export type ResourceProps = {
    resource: FlatEpResource
}

export const Resource: FC<ResourceProps> = ({
    resource
}) => {
    return <Stack sx={{ width: 200, alignItems: "center" }}>
        <Avatar
            src={resource.tile_cropped}
            alt={resource.name}
            sx={{ mb: 1, width: 75, height: 75, border: 1, borderColor: "grey.300" }}
        />
        <Typography sx={{ textAlign: "center" }}>{resource.name}</Typography>
    </Stack>
}
