import { ActivityLookup, ActivityType, LocalDateTime } from "@marketpartner/backend-api"
import { Add, Delete } from "@mui/icons-material"
import { Button, IconButton, Stack, Typography } from "@mui/material"
import { DateTime } from "luxon"
import { FC } from "react"
import { ActivityIdInput } from "src/activities/ActivityIdInput"
import { Bar } from "src/common/layout/Bar"
import { LuxonDateTimePicker } from "src/common/time/LuxonDateTimePickers"
import { useEvent } from "src/events/event-context"


export type EditableActivityLookupsProps = {
    activityType: ActivityType
    lookups: ActivityLookup[]
    onChangeLookups: (lookups: ActivityLookup[]) => void
}

export const EditableActivityLookups: FC<EditableActivityLookupsProps> = ({
    activityType,
    lookups,
    onChangeLookups,
}) => {
    const event = useEvent()!

    const changeLookupProperty = <Property extends keyof ActivityLookup>(
        idx: number,
        property: Property,
        value: ActivityLookup[Property]
    ) => onChangeLookups(lookups.map((lookup, i) => i === idx ? {
        ...lookup,
        [property]: value,
    } : lookup))

    const addLookup = () => onChangeLookups([...lookups, {
        id: "",
        localStartIso: null,
        localEndIso: null,
    }])

    const removeLookup = (idx: number) => onChangeLookups(
        lookups.filter((_, i) => i !== idx)
    )

    const toEventTime = (localTimeIso: string | null) => localTimeIso ?
        LocalDateTime.fromISO(localTimeIso).inZone(event.timezone) :
        null
    const fromEventTime = (eventTime: DateTime | null) => eventTime ?
        LocalDateTime.fromZonedDateTime(eventTime).toISO() :
        null

    return <Stack spacing={2}>
        <Typography>Across any of the following activities:</Typography>
        {lookups.map((lookup, idx) => <Bar spacing={1}>
            <Stack spacing={1}>
                <ActivityIdInput
                    activityType={activityType}
                    activityId={lookup.id}
                    onChangeActivityId={id => changeLookupProperty(idx, "id", id)}
                    sx={{ width: "100%" }}
                />
                <Bar spacing={1}>
                    <LuxonDateTimePicker
                        label="Start"
                        value={toEventTime(lookup.localStartIso)}
                        onChange={start => changeLookupProperty(idx, "localStartIso", fromEventTime(start))}
                        fullWidth
                        timezone={event.timezone}
                    />
                    <LuxonDateTimePicker
                        label="End"
                        value={toEventTime(lookup.localEndIso)}
                        onChange={end => changeLookupProperty(idx, "localEndIso", fromEventTime(end))}
                        fullWidth
                        timezone={event.timezone}
                    />
                </Bar>
            </Stack>
            {lookups.length > 1 && <IconButton
                children={<Delete />}
                onClick={() => removeLookup(idx)}
            />}
        </Bar>)}
        <Button
            children="Add activity"
            onClick={addLookup}
            color="inherit"
            startIcon={<Add />}
            sx={{ alignSelf: "center" }}
        />
    </Stack>
}