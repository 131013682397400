import { Permission, reorderBody } from "@marketpartner/backend-api"
import { Add } from "@mui/icons-material"
import { Box, Button, Typography } from "@mui/material"
import { FC } from "react"
import { useParams } from "react-router"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { BarAndFlex } from "src/common/layout/BarAndFlex"
import { Spacer } from "src/common/layout/Spacer"
import { ReorderButtons } from "src/common/reordering/ReorderButtons"
import { useBulkReorder } from "src/common/reordering/useBulkReorder"

import { Bar } from "src/common/layout/Bar"
import { useEvent } from "src/events/event-context"
import { ifHas } from "src/login/IfHas"
import { useNewPollQuestionDialog } from "src/polls/questions/NewPollQuestionDialog"
import { PollQuestionsGrid } from "src/polls/questions/PollQuestionsGrid"

export const PollQuestions:FC = () => {

    const client = useClient()!
    const event = useEvent()!
    const params = useParams()
    const pollId = params.pollId!

    const pollQuestionsQuery = backend.pollQuestions.useGetAll([client.id, event.id, pollId])
    const reorderMutation = backend.pollQuestions.useOrder()

    const newPollQuestionDialog = useNewPollQuestionDialog()

    const reorder = useBulkReorder({
        items: pollQuestionsQuery.data ?? [],
        onReorder: items => reorderMutation.mutateAsync([client.id, event.id, pollId, reorderBody(items)])
    })

    return <BarAndFlex 
        barContent={<Bar p={1}>
            <Typography variant="h5">
                Questions
            </Typography>
            <Spacer />
            {ifHas(Permission.EditPolls, client.id, event.id, <>
                <Box sx={{paddingBottom: '10px'}}>
                    <ReorderButtons reorder={reorder} />
                </Box>
                {!reorder.isReordering && <Box sx={{paddingBottom: '10px', marginLeft: '10px'}}>
                    <Button 
                        onClick={() => newPollQuestionDialog.open({pollId})}
                        variant="contained"
                        color="primary"
                        children="Add question"
                        startIcon={<Add />}
                    />
                </Box>}
            </>)}
        </Bar>}
    >
        <PollQuestionsGrid
            queryOrRows={pollQuestionsQuery}
            rowReordering={reorder.isReordering}
            onRowOrderChange={({ oldIndex, targetIndex}) => reorder.reorderItem(oldIndex, targetIndex)}
            loading={reorder.isLoading}
            sortModel={reorder.isReordering ? [] : undefined}
        />
    </BarAndFlex>
}