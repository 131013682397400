import { RegistrationCategory, Scope } from "@marketpartner/backend-api"
import { BatchResult } from "@marketpartner/mp-common"
import { Check, Clear } from "@mui/icons-material"
import { Button, Stack, Typography } from "@mui/material"
import { FC, ReactNode, useState } from "react"
import { DialogStepper } from "src/common/dialogs/DialogStepper"
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog"
import { createPopupHook } from "src/common/dialogs/Popup"
import { Bar } from "src/common/layout/Bar"
import { ProgressWithLabel } from "src/common/loading/ProgressWithLabel"
import { SourceCategoriesSelector } from "src/registrations/configuration/categories/copying/SourceCategoriesSelector"
import { useCopyCategories } from "src/registrations/configuration/categories/copying/useCopyCategories"


type CopyCategoriesDialogProps = LockableDialogProps

const CopyCategoriesDialog: FC<CopyCategoriesDialogProps> = ({
    ...props
}) => {
    return <LockableDialog maxWidth="sm" fullWidth disableClickaway {...props}>
        <CopyCategoriesDialogContent />
    </LockableDialog>
}

const CopyCategoriesDialogContent: FC = () => {
    const [sourceCategories, setSourceCategories] = useState<RegistrationCategory[]>([])
    const copyCategories = useCopyCategories()

    const step = copyCategories.data ? 2 :
        !copyCategories.isIdle ? 1 : 0

    return <DialogStepper
        stepIdx={step}
        title="Copy categories"
        steps={[{
            label: "Select categories",
            content: <SourceCategoriesSelector
                categories={sourceCategories}
                onChangeCategories={setSourceCategories}
            />,
            nextAction: <Button
                children={`Copy ${sourceCategories.length} categories`}
                onClick={() => copyCategories.mutate(sourceCategories.map(cat => ({
                    ...cat,
                    localStart: null,
                    localEnd: null
                })))}
                variant="contained"
                disabled={sourceCategories.length === 0}
            />
        }, {
            label: "Copy",
            content: <ProgressWithLabel percentage={copyCategories.progress * 100} />,
        }, {
            label: "Results",
            content: <CopyResults result={copyCategories.data!} />
        }]}
    />
}

type CopyResultsProps = {
    result: BatchResult<unknown>
}

const CopyResults: FC<CopyResultsProps> = ({
    result,
}) => {
    const iconAndText = (icon: ReactNode, text: string) => <Bar spacing={1}>
        {icon}
        <Typography>
            {text}
        </Typography>
    </Bar>

    return <Stack>
        {iconAndText(
            <Check color="success" />,
            `${result.results.length} categories copied successfully.`
        )}
        {result.errors.length > 0 && iconAndText(
            <Clear color="error" />,
            `Failed to copy ${result.errors.length} categories.`
        )}
    </Stack>
}

export const useCopyCategoriesDialog = createPopupHook({
    scope: Scope.Event,
    element: CopyCategoriesDialog,
})