import { Permission } from '@marketpartner/backend-api';
import { Add, Settings } from '@mui/icons-material';
import Button from '@mui/material/Button';
import { FC } from 'react';
import { useNavigate } from 'react-router';
import { useClient } from 'src/clients/client-context';
import { Bar } from 'src/common/layout/Bar';
import { BarAndFlex } from 'src/common/layout/BarAndFlex';
import { Spacer } from 'src/common/layout/Spacer';
import { link } from 'src/common/routing/routes';
import { useEvent } from 'src/events/event-context';
import { ifHas } from 'src/login/IfHas';
import { useNewRegistrationCategoryMenu } from 'src/registrations/configuration/categories/NewRegistrationCategoryMenu';
import { RegistrationCategoriesGrid } from 'src/registrations/configuration/categories/RegistrationCategoriesGrid';
import { useRegistrationAccessDialog } from 'src/registrations/configuration/RegistrationAccessDialog';

export const RegistrationCategories: FC = () => {
    const client = useClient()!
    const event = useEvent()!
    const newCategoryMenu = useNewRegistrationCategoryMenu()
    const accessDialog = useRegistrationAccessDialog()
    const navigate = useNavigate()

    return <BarAndFlex barContent={<Bar
        spacing={1}
        p={1}
    >
        <Spacer />
        {ifHas(Permission.EditEventDetails, client.id, event.id, <Button
            onClick={() => accessDialog.open({})}
            color='primary'
            startIcon={<Settings />}
            children="Access & Approval"
        />)}
        {ifHas(Permission.EditEventDetails, client.id, event.id, <Button
            onClick={e => newCategoryMenu.open({
                anchorEl: e.currentTarget,
                onCreate: category => navigate(link.toRegistrationCategoryForm(client.id, event.id, category.id))
            })}
            variant='contained'
            color='primary'
            startIcon={<Add />}
            children="New category"
        />)}
    </Bar>}>
        <RegistrationCategoriesGrid />
    </BarAndFlex>
}
