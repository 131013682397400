import { Scope } from "@marketpartner/backend-api";
import { DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import { FC, useState } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { DialogForm } from "src/common/dialogs/DialogForm";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { DomainList } from "src/common/form-inputs/DomainList";
import { withNotification } from "src/common/notifications/with-notification";
import { useEvent } from "src/events/event-context";


export type RegistrationAccessDialogProps = LockableDialogProps

const RegistrationAccessDialog: FC<RegistrationAccessDialogProps> = (props) => {
    return <LockableDialog
        {...props}
    >
        <DialogTitle>Registration flow</DialogTitle>
        <RegistrationAccessForm />
    </LockableDialog>
}

const RegistrationAccessForm = () => {
    const client = useClient()!
    const event = useEvent()!
    const [accessMode, setAccessMode] = useState(event.accessMode)
    const [approvalMode, setApprovalMode] = useState(event.approvalMode)
    const [allowedDomains, setAllowedDomains] = useState(event.allowedDomains)
    const [autoApprovedDomains, setAutoApprovedDomains] = useState(event.autoApprovedDomains)

    const updateEventMutation = backend.events.useUpdateEvent(withNotification({
        successMessage: "Updated registration configuration",
        errorMessage: "Error updating registration configuration",
    }))

    return <DialogForm
        formData={{}}
        onSubmit={() => updateEventMutation.mutateAsync([client.id, event.id, {
            accessMode,
            approvalMode,
            allowedDomains,
            autoApprovedDomains,
        }])}
        actionName="Save"
    >
        <Grid container spacing={2}>
            <Grid item>
                <Stack spacing={2} minWidth={250}>
                    <Typography variant="h6">Access</Typography>
                    <SelectAndDomainList
                        value={accessMode}
                        values={["Open", "AllowedDomains", "InviteOnly"]}
                        showListIfValue="AllowedDomains"
                        onChange={e => setAccessMode(e.target.value)}
                        listValues={allowedDomains}
                        onChangeListValues={setAllowedDomains}
                        label="Access mode"
                    />

                </Stack>
            </Grid>
            <Grid item>
                <Stack spacing={2} minWidth={250}>
                    <Typography variant="h6">Approval</Typography>
                    <SelectAndDomainList
                        value={approvalMode}
                        values={["Auto", "AutoApproveDomains", "Manual"]}
                        showListIfValue="AutoApproveDomains"
                        onChange={e => setApprovalMode(e.target.value)}
                        listValues={autoApprovedDomains}
                        onChangeListValues={setAutoApprovedDomains}
                        label="Approval mode"
                    />
                </Stack>
            </Grid>
        </Grid>
    </DialogForm>
}

type SelectAndDomainListProps = {
    value: string
    values: string[]
    onChange: (e: any) => void
    showListIfValue: string
    listValues: string[]
    onChangeListValues: (values: string[]) => void
    label?: string
}

const SelectAndDomainList: FC<SelectAndDomainListProps> = ({
    value,
    values,
    onChange,
    showListIfValue,
    listValues,
    onChangeListValues,
    label
}) => {
    return <Stack alignItems="center">
        <FormControl fullWidth>
            <InputLabel children={label} />
            <Select value={value} onChange={onChange} label={label} fullWidth>
                {values.map(value => <MenuItem key={value} value={value}>{value}</MenuItem>)}
            </Select>
        </FormControl>
        {value === showListIfValue && <DomainList values={listValues} onChange={onChangeListValues} />}
    </Stack>
}

export const useRegistrationAccessDialog = createPopupHook({
    element: RegistrationAccessDialog,
    scope: Scope.Event,
})