import { EventFeed, LocalDateTime } from "@marketpartner/backend-api"
import { Lock } from "@mui/icons-material"
import LockOpen from "@mui/icons-material/LockOpen"
import { Checkbox, FormControlLabel, Grid, Stack, SxProps, ToggleButton, ToggleButtonGroup } from "@mui/material"
import { DateTime } from "luxon"
import { FC, useState } from "react"
import { DialogForm, ExtendableDialogFormProps } from "src/common/dialogs/DialogForm"
import { Bar } from "src/common/layout/Bar"
import { LuxonDateTimePicker } from "src/common/time/LuxonDateTimePickers"
import { useEvent } from "src/events/event-context"

export type EventFeedFormData = {
    requireApproval: boolean
    localEnd: LocalDateTime | null
}

export type EventFeedFormProps = ExtendableDialogFormProps<EventFeedFormData> & {
    feed?: EventFeed
}

export const EventFeedForm: FC<EventFeedFormProps> = ({
    feed,
    ...props
}) => {
    const [requireApproval, setRequireApproval] = useState(feed?.requireApproval ?? false)
    const [end, setEnd] = useState(feed?.end ?? null)

    return <DialogForm
        formData={{
            requireApproval,
            localEnd: end && LocalDateTime.fromZonedDateTime(end),
        }}
        {...props}
    >
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <FormControlLabel
                    label="Messages require approval"
                    control={<Checkbox
                        checked={requireApproval}
                        onChange={e => setRequireApproval(e.target.checked)}
                    />}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <OpenCloseSelector
                    endTime={end}
                    onChange={setEnd}
                />
            </Grid>
        </Grid>

    </DialogForm>
}

type OpenCloseSelectorProps = {
    endTime: DateTime | null
    onChange: (endTime: DateTime | null) => void
    sx?: SxProps
}

const OpenCloseSelector: FC<OpenCloseSelectorProps> = ({
    endTime,
    onChange,
    sx
}) => {
    const event = useEvent()!
    const now = DateTime.now().setZone(event.timezone)
    const isOpen = !endTime || endTime > now

    function open() {
        onChange(null)
    }

    function close() {
        onChange(now.set({ millisecond: 0, second: 0 }))
    }

    return <Stack spacing={2} sx={sx}>
        <Bar spacing={1}>
            <ToggleButtonGroup value={isOpen}>
                <ToggleButton value={true} selected={isOpen} onClick={open} color={isOpen ? "success" : undefined}>
                    <LockOpen />
                    Open
                </ToggleButton>
                <ToggleButton value={false} selected={!isOpen} onClick={close} color={!isOpen ? "error" : undefined}>
                    <Lock />
                    Closed
                </ToggleButton>
            </ToggleButtonGroup>
        </Bar>
        <LuxonDateTimePicker
            value={endTime}
            onChange={onChange}
            timezone={event.timezone}
            label="End time"
        />
    </Stack>
}