import { ScanningConfiguration } from "@marketpartner/backend-api"
import { ClearableTextField, ExternalLink } from "@marketpartner/mp-common-mui"
import { Edit } from "@mui/icons-material"
import { DialogTitle, FormLabel, Grid, TextField } from "@mui/material"
import { FC, useState } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { DialogButton } from "src/common/dialogs/DialogButton"
import { DialogForm } from "src/common/dialogs/DialogForm"
import { TagEditor } from "src/common/form-inputs/TagEditor"
import { withNotification } from "src/common/notifications/with-notification"
import { useEvent } from "src/events/event-context"

export type ScanningConfigurationButtonProps = {
    configuration: ScanningConfiguration
}

export const EditScanningConfigurationButton: FC<ScanningConfigurationButtonProps> = ({
    configuration,
}) => {
    return <DialogButton
        icon={<Edit />}
        text="Edit"
        variant="contained"
        color="primary"
        dialogProps={{
            maxWidth: "md",
            fullWidth: true,
        }}
    >
        <DialogTitle>Edit scanning configuration</DialogTitle>
        <ScanningConfigurationForm configuration={configuration} />
    </DialogButton>
}

type ScanningConfigurationFormProps = {
    configuration: ScanningConfiguration
}

const ScanningConfigurationForm: FC<ScanningConfigurationFormProps> = ({
    configuration,
}) => {
    const client = useClient()!
    const event = useEvent()!
    const [unlockCode, setUnlockCode] = useState(configuration.unlockCode)
    const [printFormat, setPrintFormat] = useState(configuration.printFormat ?? "")
    const [sessionNames, setSessionNames] = useState(configuration.sessionNames)
    const [customTokenField, setCustomTokenField] = useState(configuration.customTokenField)
    const updateMutation = backend.scanning.useUpdateConfiguration(withNotification({
        successMessage: "Updated scanning configuration",
        errorMessage: "Error updating scanning configuration",
    }))

    return <DialogForm
        formData={{
            unlockCode,
            printFormat: printFormat || null,
            sessionNames,
            customTokenField,
        }}
        onSubmit={formData => updateMutation.mutateAsync([client.id, event.id, formData])}
        actionName="Update"
    >
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ClearableTextField
                    label="Unlock code"
                    value={unlockCode}
                    onChange={e => setUnlockCode(e.target.value)}
                    onClear={() => setUnlockCode("")}
                />
            </Grid>
            <Grid item xs={12}>
                <ClearableTextField
                    label="Custom token field"
                    value={customTokenField ?? ""}
                    onChange={e => setCustomTokenField(e.target.value || null)}
                    onClear={() => setCustomTokenField(null)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Print format"
                    multiline
                    value={printFormat}
                    onChange={e => setPrintFormat(e.target.value)}
                    fullWidth
                    helperText={
                        <ExternalLink href="http://labelary.com/viewer.html">ZPL Online editor</ExternalLink>
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <FormLabel>
                    Session names
                </FormLabel>
                <TagEditor tags={sessionNames} onChange={setSessionNames} />
            </Grid>
        </Grid>
    </DialogForm>
}