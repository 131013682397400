import { EventContentProvider, EventDetails, Timezone } from "@marketpartner/backend-api"
import { toUtcDateTime } from "@marketpartner/mp-common"
import { Collapse, FormControlLabel, Grid, Stack, Switch, TextField, TextFieldProps, Typography } from "@mui/material"
import { DateTime } from "luxon"
import { FC, useState } from "react"
import { DialogForm, ExtendableDialogFormProps } from "src/common/dialogs/DialogForm"
import { IdField } from "src/common/form-inputs/IdField"
import { LuxonDateTimePicker } from "src/common/time/LuxonDateTimePickers"
import { utcTimezone } from "src/common/time/time-utils"
import { TimezoneField } from "src/common/time/TimezoneField"
import { EventTagEditor } from "src/events/EventTagEditor"
import { PublicAccessTokensSelector } from "src/events/PublicAccessTokensSelector"

export type EventFormData = {
    id: string
    name: string
    tags: string[]
    startTime: DateTime
    endTime: DateTime
    timezone: Timezone
    enablePublicAccessTokens: boolean
    contentProvider: EventContentProvider
}

export type EventFormProps = ExtendableDialogFormProps<EventFormData> & {
    event?: EventDetails
}

export const EventForm: FC<EventFormProps> = ({
    event,
    ...props
}) => {
    const [id, setId] = useState(event?.id ?? "")
    const [name, setName] = useState(event?.name ?? "")
    const [tags, setTags] = useState(event?.tags ?? [])
    const [startTime, setStartTime] = useState(event?.startTime)
    const [endTime, setEndTime] = useState(event?.endTime)
    const [timezone, setTimezone] = useState<Timezone | null>(event?.timezone ?? null)
    const [enablePublicAccessTokens, setEnablePublicAccessTokens] = useState(event?.enablePublicAccessTokens ?? false)
    const [useEventPartnerContent, setUseEventPartnerContent] = useState(event?.contentProvider !== "MPA")

    return <>
        <DialogForm
            formData={{
                id,
                name,
                tags,
                startTime: toUtcDateTime(startTime)!,
                endTime: toUtcDateTime(endTime)!,
                timezone: timezone!,
                enablePublicAccessTokens,
                contentProvider: useEventPartnerContent ? "EventPartner" : "MPA",
            }}
            isValid={Boolean(startTime && endTime && timezone)}
            {...props}
        >
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                    <NameField value={name} onChange={e => setName(e.target.value)} />
                </Grid>
                {!event && <Grid item xs={12}>
                    <IdField nameValue={name} idValue={id} onChangeId={setId} maxLength={24} />
                </Grid>}
                <Grid item xs={12}>
                    <EventTimesFields event={event} startTime={startTime} endTime={endTime} setStartTime={setStartTime} setEndTime={setEndTime} timezone={timezone} setTimezone={setTimezone} />
                </Grid>
                <Grid item xs={12}>
                    <PublicAccessTokensSelector enablePublicAccessTokens={enablePublicAccessTokens} onChange={setEnablePublicAccessTokens} />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={<Switch
                            checked={useEventPartnerContent}
                            onChange={(_, checked) => setUseEventPartnerContent(checked)}
                        />}
                        label="Use Event Partner for event content"
                    />
                </Grid>
                <Grid item xs={12}>
                    <EventTagEditor tags={tags} onChange={setTags} />
                </Grid>
            </Grid>
        </DialogForm>
    </>
}


const NameField = (props: TextFieldProps) => {
    return <TextField
        {...props}
        label="Name"
        fullWidth
        required
    />
}

type EventTimesFieldsProps = {
    event?: EventDetails
    startTime: DateTime | undefined
    endTime: DateTime | undefined
    setStartTime: (startTime: DateTime) => void
    setEndTime: (endTime: DateTime) => void
    timezone: Timezone | null
    setTimezone: (timezone: Timezone) => void
}

const EventTimesFields: FC<EventTimesFieldsProps> = ({
    event,
    startTime,
    endTime,
    setStartTime,
    setEndTime,
    timezone,
    setTimezone
}) => {

    const timezoneChanged = event && timezone !== event.timezone

    return <Stack>
        <Typography variant="h6" component="h4">
            Times
        </Typography>
        <Grid container sx={{ mt: 0 }} spacing={2}>
            <Grid item xs={12}>
                <TimezoneField
                    value={timezone}
                    onChange={timezone => timezone && setTimezone(timezone)}
                    inputProps={{
                        error: timezoneChanged,
                        helperText: timezoneChanged && "Warning: Changing the event timezone requires all times to be reviewed & updated (e.g. event segments, form start & end dates)",
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Collapse in={timezone !== null}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <LuxonDateTimePicker label="Start" timezone={timezone ?? utcTimezone} value={startTime} onChange={start => start && setStartTime(start)} fullWidth required />
                        </Grid>
                        <Grid item xs={6}>
                            <LuxonDateTimePicker label="End" timezone={timezone ?? utcTimezone} value={endTime} onChange={end => end && setEndTime(end)} fullWidth required />
                        </Grid>
                    </Grid>
                </Collapse>
            </Grid>
        </Grid>
    </Stack>
}