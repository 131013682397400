import { ConfiguredEmailTemplate, Permission, RegistrationEmailType } from "@marketpartner/backend-api"
import { Delete } from "@mui/icons-material"
import { Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { Bar } from "src/common/layout/Bar"
import { GridDetailPanel } from "src/common/layout/GridDetailPanel"
import { withNotification } from "src/common/notifications/with-notification"
import { EditableConfiguredEmailTemplate } from "src/email/templates/EditableConfiguredEmailTemplate"
import { useConfirmDeleteEmailTriggerOverrideDialog } from "src/email/triggers/ConfirmDeleteEmailTriggerOverrideDialog"
import { emailTriggerLabels } from "src/email/triggers/EmailTriggersGrid"
import { useEvent } from "src/events/event-context"
import { ifHas } from "src/login/IfHas"
import { NameAndSubtitle } from "src/registrations/NameAndSubtitle"


export type EmailTriggerOverridesProps = {
    triggerKey: RegistrationEmailType
}

export const EmailTriggerOverrides: FC<EmailTriggerOverridesProps> = ({
    triggerKey
}) => {
    const client = useClient()!
    const event = useEvent()!

    const categoriesQuery = backend.registrationCategories.useGetAll([client.id, event.id], {
        select: categories => categories
            .filter(category => category.triggeredEmailTemplateOverrides[triggerKey] !== null)
    })

    const updateCategoryMutation = backend.registrationCategories.useUpdate(withNotification({
        errorMessage: "Error updating email template"
    }))

    const deleteDialog = useConfirmDeleteEmailTriggerOverrideDialog()

    const handleOverrideTemplate = (
        categoryId: string,
        template: ConfiguredEmailTemplate | null
    ) => updateCategoryMutation.mutateAsync([client.id, event.id, categoryId, {
        triggeredEmailTemplateOverrides: {
            [triggerKey]: template === null ? {
                type: "DoNotSend"
            } : template
        }
    }])

    return <Collapse in={Boolean(categoriesQuery.data?.length)}>
        <GridDetailPanel>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Category</TableCell>
                            <TableCell>Template override</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {categoriesQuery.data?.map(category => {
                            const template = category.triggeredEmailTemplateOverrides[triggerKey]
                            return <TableRow key={category.id}>
                                <TableCell>
                                    <NameAndSubtitle
                                        name={category.name}
                                        subtitle={category.id}
                                        nameProps={{ variant: "body2" }} />
                                </TableCell>
                                <TableCell>
                                    <Bar>
                                        <EditableConfiguredEmailTemplate
                                            editLabel={`${emailTriggerLabels[triggerKey]} email template`}
                                            configuredTemplate={template?.type === "DoNotSend" ? null : template}
                                            onChange={template => handleOverrideTemplate(category.id, template)}
                                        />
                                        {ifHas(Permission.EditEventDetails, client.id, event.id, <IconButton
                                            children={<Delete />}
                                            onClick={() => deleteDialog.open({
                                                category,
                                                triggerKey
                                            })}
                                            color="error"
                                        />)}
                                    </Bar>
                                </TableCell>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </GridDetailPanel>
    </Collapse>
}