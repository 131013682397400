import { Add } from "@mui/icons-material";
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { ClientAppTemplateForm } from "src/app-templates/client-templates/ClientAppTemplateForm";
import { backend } from "src/common/api";
import { DialogButton, DialogButtonProps } from "src/common/dialogs/DialogButton";
import { withNotification } from "src/common/notifications/with-notification";

export type CreateClientAppTemplateButtonProps = Partial<DialogButtonProps> & {
    clientId: string
}

export const CreateClientAppTemplateButton: FC<CreateClientAppTemplateButtonProps> = ({
    clientId,
    ...props
}) => {
    const createMutation = backend.clientAppTemplates.useCreate(withNotification({
        successMessage: "Created client template",
        errorMessage: "Error creating client app template",
    }))

    return <DialogButton
        {...props}
        icon={<Add />}
        color="primary"
        variant="contained"
        text="Create"
        dialogProps={{
            maxWidth: "sm",
            fullWidth: true,
        }}
    >
        <DialogTitle>Create client template</DialogTitle>
        <ClientAppTemplateForm
            actionName="Create"
            onSubmit={formData => createMutation.mutateAsync([
                clientId,
                formData,
            ])}
        />
    </DialogButton>
}