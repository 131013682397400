import { Error, Warning } from "@mui/icons-material"
import { Collapse, Stack, Typography } from "@mui/material"
import { FC } from "react"
import { Bar } from "src/common/layout/Bar"
import { RegistrationCategoryCompatibility, RegistrationCategoryCompatibilityIssue } from "src/registrations/actions/copy/registration-category-compatibility"


export type RegistrationCategoryCompatibilityIssuesProps = {
    enabled: boolean
    includeGuests: boolean
    compatibility: RegistrationCategoryCompatibility
}

export const RegistrationCategoryCompatibilityIssues: FC<RegistrationCategoryCompatibilityIssuesProps> = ({
    enabled,
    includeGuests,
    compatibility,
}) => {
    return <Collapse in={Boolean(enabled && compatibility.level)}>
        <Stack spacing={1}>
            {compatibility.level === "warning" ?
                <IssuesHeader
                    header="Categories are configured differently"
                    description="Differences in the source & target categories mean that some data may not be copied."
                /> :
                <IssuesHeader
                    header="Categories incompatible"
                    description="Differences in the source & target configuration mean data cannot be copied."
                />
            }
            <FormIssues formName="Registration form" issues={compatibility.regFormIssues} />
            <FormIssues formName="Profile form" issues={compatibility.profileFormIssues} />
            <Collapse in={includeGuests}>
                <FormIssues formName="Guest form" issues={compatibility.guestFormIssues} />
            </Collapse>
        </Stack>
    </Collapse>
}

type IssuesHeaderProps = {
    header: string
    description: string
}

const IssuesHeader: FC<IssuesHeaderProps> = ({
    header,
    description,
}) => <Stack>
        <Typography variant="h6" children={header} />
        <Typography children={description} />
    </Stack>

type FormIssuesProps = {
    formName: string
    issues: RegistrationCategoryCompatibilityIssue[]
}

const FormIssues: FC<FormIssuesProps> = ({
    formName,
    issues
}) => {
    if (issues.length === 0) {
        return null
    }

    return <Stack spacing={0.5}>
        <Typography variant="h6" children={formName} />
        {issues.map(issue => <Bar key={issue.fieldName} spacing={1}>
            {issue.level === "warning" ? <Warning color="warning" /> : <Error color="error" />}
            <Typography>
                <b>{issue.fieldName}: </b>{issue.message}
            </Typography>
        </Bar>)}
    </Stack>
}