import { EventFieldEditPermission, Permission, Scope } from "@marketpartner/backend-api";
import { MuiFormElements } from "@marketpartner/mp-common-mui";
import { useDynamicFormState } from "@marketpartner/mp-common-react";
import { DialogTitle, Typography } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { asyncBackend, backend } from "src/common/api";
import { DialogForm } from "src/common/dialogs/DialogForm";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { useEvent } from "src/events/event-context";
import { useLoginContext } from "src/login/LoginContext";


export type EditEventFieldsDialogProps = LockableDialogProps & EditEventFieldsDialogContentProps

const EditEventFieldsDialog: FC<EditEventFieldsDialogProps> = ({
    ...props
}) => {
    return <LockableDialog
        {...props}
        maxWidth="sm"
        fullWidth
    >
        <DialogTitle>Edit event fields</DialogTitle>
        <EditEventFieldsDialogContent />
    </LockableDialog>
}

export type EditEventFieldsDialogContentProps = {}

const EditEventFieldsDialogContent: FC<EditEventFieldsDialogContentProps> = () => {
    const client = useClient()!
    const event = useEvent()!
    const login = useLoginContext()

    const updateEventMutation = backend.events.useUpdateEvent(withNotification({
        errorMessage: "Error updating event fields"
    }))

    const editableFields = login.hasPermission(Permission.EditEventDetails, client.id, event.id) ?
        event.fieldElements :
        event.fieldElements.filter(field => field.metadata?.editPermission === EventFieldEditPermission.EventAdmins)

    const fieldsState = useDynamicFormState({
        elements: editableFields,
        requirement: "partial",
        initialValues: event.fields,
    })

    return <DialogForm
        actionName="Save"
        formData={{}}
        onSubmit={() => updateEventMutation.mutateAsync([client.id, event.id, {
            fields: fieldsState.values,
        }])}
        isValid={fieldsState.invalidFields.length === 0}
    >
        {editableFields.length === 0 && <Typography variant="body2" color="text.secondary" sx={{ textAlign: "center", my: 4 }}>
            No editable fields available for this event.
        </Typography>}
        <MuiFormElements
            backend={asyncBackend}
            state={fieldsState}
            uploadPrefix={`clients/${client.id}/events/${event.id}/fields`}
            validationEnabled
        />
    </DialogForm>
}

export const useEditEventFieldsDialog = createPopupHook({
    element: EditEventFieldsDialog,
    scope: Scope.Event,
})