import { DataGridPro, DataGridProProps, GridValidRowModel } from "@mui/x-data-grid-pro";
import { UseQueryResult } from "@tanstack/react-query";
import { useMemo } from "react";
import { useCustomGridRowFilter } from "src/common/grid/useGridRowFilter";


export type QueryDataGridProps<Row extends GridValidRowModel> = Omit<DataGridProProps<Row>, "rows"> & {
    queryOrRows: UseQueryResult<Row[]> | Row[]
    filterRows?: (rows: Row[]) => Row[]
}

export const useRows = <Row extends GridValidRowModel>(
    queryOrRows: UseQueryResult<Row[]> | Row[]
): Row[] => {
    const data = Array.isArray(queryOrRows) ? queryOrRows : queryOrRows.data
    return useMemo(() => data ?? [], [data])
}

export const QueryDataGrid = <Row extends GridValidRowModel>({
    queryOrRows,
    filterRows,
    ...props
}: QueryDataGridProps<Row>) => {
    const rows = useRows(queryOrRows)
    const initialLoading = Array.isArray(queryOrRows) ?
        false :
        queryOrRows.isInitialLoading

    const propsWithFilter = useCustomGridRowFilter({
        filterRows,
        rows,
        ...props
    })

    return <DataGridPro
        loading={props.loading || initialLoading}
        {...propsWithFilter}
    />
}