import { RegistrationFieldVisibility } from "@marketpartner/backend-api"
import { ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps } from "@mui/material"
import { FC } from "react"
import { Bar } from "src/common/layout/Bar"
import { RegistrationFieldVisibilityIcon } from "src/registrations/configuration/categories/reg-form/RegistrationElementMetadataIcons"


export type RegistrationFieldVisibilitySelectorProps = Partial<ToggleButtonGroupProps> & {
    visibility: RegistrationFieldVisibility
    onChangeVisibility: (visibility: RegistrationFieldVisibility) => void
}

export const RegistrationFieldVisibilitySelector: FC<RegistrationFieldVisibilitySelectorProps> = ({
    visibility,
    onChangeVisibility,
    ...props
}) => {
    return <ToggleButtonGroup
        exclusive
        value={visibility}
        onChange={(_, value) => onChangeVisibility(value)}
        {...props}
    >
        {Object.values(RegistrationFieldVisibility).map(visibility => <ToggleButton
            value={visibility}
            key={visibility}
        >
            <Bar spacing={1}>
                <RegistrationFieldVisibilityIcon elementVisibility={visibility} />
                <span>{visibilityLabels[visibility]}</span>
            </Bar>
        </ToggleButton>)}
    </ToggleButtonGroup>
}

const visibilityLabels: Record<RegistrationFieldVisibility, string> = {
    AdminOnly: "Admin Only",
    VisibleToUser: "Visible to User",
    Public: "Public (in profile)",
}