import { ProfileVisibilityLevel, RegistrationCategory, Scope, StandardProfileFields } from "@marketpartner/backend-api"
import { OnlyPropertiesOfType } from "@marketpartner/mp-common"
import { BasicSelect } from "@marketpartner/mp-common-mui"
import { Checkbox, FormControlLabel, Grid } from "@mui/material"
import DialogTitle from "@mui/material/DialogTitle"
import { FC, useState } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { DialogForm } from "src/common/dialogs/DialogForm"
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog"
import { createPopupHook } from "src/common/dialogs/Popup"
import { withNotification } from "src/common/notifications/with-notification"
import { useEvent } from "src/events/event-context"
import { profileSettingsLabels } from "src/registrations/configuration/categories/profile-settings/profile-settings-labels"


export type ProfileSettingsDialogProps = LockableDialogProps & {
    category: RegistrationCategory
}

const ProfileSettingsDialog: FC<ProfileSettingsDialogProps> = ({
    category,
    ...props
}) => {
    return <LockableDialog maxWidth="sm" fullWidth {...props}>
        <DialogTitle>{category.name} profile settings</DialogTitle>
        <ProfileSettingsForm category={category} />
    </LockableDialog>
}

export const useProfileSettingsDialog = createPopupHook({
    element: ProfileSettingsDialog,
    scope: Scope.Event,
})

type ProfileSettingsFormProps = {
    category: RegistrationCategory
}

type BooleanProfileFields = OnlyPropertiesOfType<StandardProfileFields, boolean>

const ProfileSettingsForm: FC<ProfileSettingsFormProps> = ({
    category,
}) => {
    const client = useClient()!
    const event = useEvent()!
    const [fields, setFields] = useState({ ...category.defaultProfileFields })
    const [profileVisibilityLevel, setProfileVisibilityLevel] = useState(category.profileVisibilityLevel)
    const [profileEditableByUser, setProfileEditableByUser] = useState(category.profileEditableByUser)
    const updateCategory = backend.registrationCategories.useUpdate(withNotification({
        errorMessage: "Failed to update registration category",
    }))

    const labels = profileSettingsLabels(category)

    const setField = <Field extends keyof StandardProfileFields>(field: Field, newValue: StandardProfileFields[Field]) => {
        setFields(fields => ({
            ...fields,
            [field]: newValue
        }))
    }

    const fieldToggle = <Field extends keyof BooleanProfileFields>(
        field: Field,
    ) => <FormControlLabel
            control={<Checkbox
                checked={fields[field]}
                onChange={e => setField(field, e.target.checked)}
            />}
            label={labels[field]}
        />

    return <DialogForm
        actionName="Save"
        formData={fields}
        onSubmit={fields => updateCategory.mutateAsync([client.id, event.id, category.id, {
            defaultProfileFields: fields,
            profileVisibilityLevel,
            profileEditableByUser,
        }])}
    >
        <Grid container>
            <Grid item xs={12} sm={6}>
                <BasicSelect
                    options={Object.values(ProfileVisibilityLevel)}
                    label={labels.profileVisibilityLevel}
                    value={profileVisibilityLevel}
                    onChange={setProfileVisibilityLevel}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                {fieldToggle("profile_visible")}
            </Grid>
            <Grid item xs={12}>
                {fieldToggle("direct_message_opt_in")}
            </Grid>
            <Grid item xs={12}>
                {fieldToggle("allow_meeting_requests")}
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={<Checkbox
                        checked={profileEditableByUser}
                        onChange={e => setProfileEditableByUser(e.target.checked)}
                    />}
                    label={labels.profileEditableByUser}
                />
            </Grid>
        </Grid>
    </DialogForm>
}