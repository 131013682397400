import { Permission } from "@marketpartner/backend-api"
import { Paper } from "@mui/material"
import { FC } from "react"
import { useParams } from "react-router"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { LoadingSpinner } from "src/common/loading/LoadingSpinner"
import { fullSize } from "src/common/styles"
import { useEvent } from "src/events/event-context"
import { FormElementSourceType } from "src/forms/elements/copying/useFormElementSources"
import { EditableFormElements } from "src/forms/elements/EditableFormElements"
import { useLoginContext } from "src/login/LoginContext"

export const PollQuestionElements: FC = () => {

    const client = useClient()!
    const event = useEvent()!
    const params = useParams()
    const pollId = params.pollId!
    const questionId = parseInt(params.questionId!)
    const login = useLoginContext()


    const pollQuestionQuery = backend.pollQuestions.useGet([client.id, event.id, pollId, questionId])
    const updateMutation = backend.pollQuestions.useUpdate()

    const pollQuestion = pollQuestionQuery.data

    if (!pollQuestion) {
        return <LoadingSpinner />
    }

    return <Paper sx={{ ...fullSize }}>
        <EditableFormElements
            canEdit={login.hasPermission(Permission.EditPolls, client.id, event.id)}
            elements={pollQuestion.elements}
            onChangeElements={elements => updateMutation.mutateAsync([client.id, event.id, pollId, questionId, {
                elements
            }])}
            defaultSourceType={FormElementSourceType.PollQuestions}
            sizing="full-size"
        />
    </Paper>
}