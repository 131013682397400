import { StaticEmailTemplate, StaticEmailTemplateId } from "@marketpartner/backend-api";
import { sortBy } from "lodash";
import { FC } from "react";
import { backend } from "src/common/api";
import { LoadedAutocomplete, LoadedAutocompleteProps } from "src/common/form-inputs/LoadedAutocomplete";


export type StaticEmailTemplateSelectorProps = Omit<LoadedAutocompleteProps<StaticEmailTemplateId, StaticEmailTemplate>, "fetchRequest" | "selectedId" | "onSelectId"> & {
    label?: string
    selectedId: StaticEmailTemplateId | null
    onSelectId: (templateId: StaticEmailTemplateId | null) => void
}

export const StaticEmailTemplateSelector: FC<StaticEmailTemplateSelectorProps> = ({
    selectedId,
    onSelectId,
    ...props
}) => {
    const templatesQuery = backend.emailTemplates.useGetStaticEmailTemplates([], {
        select: templates => sortBy(templates, "engine", "name")
    })

    return <LoadedAutocomplete
        label="Email template"
        fetchRequest={templatesQuery}
        sx={{ minWidth: 250 }}
        size="medium"
        selectedId={selectedId}
        onSelectId={onSelectId}
        {...props}
    />
}