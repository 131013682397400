import { CheckboxConfig } from "@marketpartner/backend-api";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { StandardFields } from "src/forms/elements/types/StandardFields";
import { FormElementConfigComponent } from "src/forms/elements/types/formElementConfigTypes";


export const CheckboxConfigComponent: FormElementConfigComponent<CheckboxConfig> = ({
    config,
    updateConfigProperty,
}) => {
    return <>
        <StandardFields config={config} updateConfigProperty={updateConfigProperty} />
        <Grid item xs={12} >
            <FormControlLabel
                label="Checked by default"
                control={<Checkbox
                    checked={config.defaultValue}
                    onChange={e => updateConfigProperty("defaultValue", e.target.checked)}
                />}
            />
        </Grid>
    </>
}