import { AgendaItemType, LocalDateTime, Scope } from "@marketpartner/backend-api";
import { DialogTitle } from "@mui/material";
import { DateTime } from "luxon";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { BreakoutForm } from "src/content/agendas/breakouts/BreakoutForm";
import { useEvent } from "src/events/event-context";


type AddBreakoutDialogProps = LockableDialogProps & {
    agendaId: string
    start: DateTime
}

const AddBreakoutDialog: FC<AddBreakoutDialogProps> = ({
    agendaId,
    start,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const createbreakoutMutation = backend.agendaItems.useCreate(withNotification({
        successMessage: "Created breakout",
        errorMessage: "Error creating breakout",
    }))

    return <LockableDialog {...props}>
        <DialogTitle>
            New breakout
        </DialogTitle>
        <BreakoutForm
            onSubmit={formData => createbreakoutMutation.mutateAsync([client.id, event.id, agendaId, {
                type: AgendaItemType.Breakout,
                ...formData,
                localStart: LocalDateTime.fromZonedDateTime(start),
                localEnd: LocalDateTime.fromZonedDateTime(start),
            }])}
            actionName="Add"
        />
    </LockableDialog>
}

export const useAddBreakoutDialog = createPopupHook({
    scope: Scope.Event,
    element: AddBreakoutDialog,
})
