import { EventDetails, Scope } from "@marketpartner/backend-api";
import { DialogTitle } from "@mui/material";
import { countBy } from "lodash";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { EditableDialogList } from "src/common/dialogs/EditableDialogList";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { useCreateStripeInstanceDialog } from "src/payment/stripe/CreateStripeInstanceDialog";
import { useDeleteStripeInstanceDialog } from "src/payment/stripe/DeleteStripeInstanceDialog";
import { useEditStripeInstanceDialog } from "src/payment/stripe/EditStripeInstanceDialog";


export type StripeInstancesDialogProps = LockableDialogProps

const StripeInstancesDialog: FC<StripeInstancesDialogProps> = ({
    ...props
}) => {
    const client = useClient()!

    const instancesQuery = backend.stripeInstanceApi.useGetStripeInstances([client.id])
    const eventCountsQuery = backend.events.useGetEvents([client.id], {
        select: events => countBy((events as EventDetails[]), e => e.stripeInstanceId),
    })

    const createDialog = useCreateStripeInstanceDialog()
    const editDialog = useEditStripeInstanceDialog()
    const deleteDialog = useDeleteStripeInstanceDialog()

    return <LockableDialog
        maxWidth="sm"
        fullWidth
        {...props}
    >
        <DialogTitle>Stripe instances</DialogTitle>
        <EditableDialogList
            itemsQuery={instancesQuery}
            getPrimaryText={instance => instance.name}
            getTeritaryText={instance => !eventCountsQuery.data ? undefined :
                eventCountsQuery.data[instance.id] === undefined ? "No events" :
                    eventCountsQuery.data[instance.id] === 1 ? "1 event" :
                        `${eventCountsQuery.data[instance.id]} events`
            }
            addProps={{
                onClickAdd: () => createDialog.open({}),
            }}
            contextMenuProps={instance => ({
                onClickEdit: () => editDialog.open({ instance }),
                onClickDelete: () => deleteDialog.open({ instance }),
            })}
        />
    </LockableDialog>
}

export const useStripeInstancesDialog = createPopupHook({
    scope: Scope.Client,
    element: StripeInstancesDialog,
})