import { FormElementConfig } from "@marketpartner/backend-api";
import { FC } from "react";
import { CheckboxConfigComponent } from "src/forms/elements/types/CheckboxConfig";
import { CheckboxesConfigComponent } from "src/forms/elements/types/CheckboxesConfig";
import { CountrySelectConfigComponent } from "src/forms/elements/types/CountrySelectConfig";
import { EmailFieldConfigComponent } from "src/forms/elements/types/EmailFieldConfig";
import { EnhancedUploadConfigComponent } from "src/forms/elements/types/EnhancedUploadConfig";
import { HiddenFieldConfigComponent } from "src/forms/elements/types/HiddenFieldConfig";
import { MarkdownEditorConfigComponent } from "src/forms/elements/types/MarkdownEditorConfig";
import { MultiUploadConfigComponent } from "src/forms/elements/types/MultiUploadConfig";
import { PhoneNumberConfigComponent } from "src/forms/elements/types/PhoneNumberConfig";
import { RadioButtonsConfigComponent } from "src/forms/elements/types/RadioButtonsConfig";
import { SelectConfigComponent } from "src/forms/elements/types/SelectConfig";
import { SignatureConfigComponent } from "src/forms/elements/types/SignatureConfig";
import { TextareaConfigComponent } from "src/forms/elements/types/TextareaConfig";
import { TextConfigComponent } from "src/forms/elements/types/TextConfig";
import { TextFieldConfigComponent } from "src/forms/elements/types/TextFieldConfig";
import { UploadConfigComponent } from "src/forms/elements/types/UploadConfig";
import { UrlParameterConfigComponent } from "src/forms/elements/types/UrlParameterConfig";

export type PropertyUpdater<Config> = <Property extends Exclude<keyof Config, "type">>(
	property: Property,
	value: Config[Property]
) => void

export type FormElementConfigComponent<Config extends FormElementConfig> = FC<{
	config: Config
	updateConfigProperty: PropertyUpdater<Config>
}>

type FormElementConfigType<Config extends FormElementConfig> = {
	label: string
	configComponent: FormElementConfigComponent<Config>
	deprecated?: boolean
}

type FormElementConfigTypes = {
	[Config in FormElementConfig as Config["type"]]: FormElementConfigType<Config>
}

export const formElementConfigTypes: FormElementConfigTypes = {
	Checkbox: {
		label: "Checkbox",
		configComponent: CheckboxConfigComponent,
	},
	Checkboxes: {
		label: "Checkboxes",
		configComponent: CheckboxesConfigComponent,
	},
	CountrySelect: {
		label: "Country select",
		configComponent: CountrySelectConfigComponent,
	},
	EmailField: {
		label: "Email",
		configComponent: EmailFieldConfigComponent,
	},
	EnhancedUpload: {
		label: "File upload",
		configComponent: EnhancedUploadConfigComponent,
	},
	HiddenField: {
		label: "Hidden field",
		configComponent: HiddenFieldConfigComponent,
	},
	MarkdownEditor: {
		label: "Markdown editor",
		configComponent: MarkdownEditorConfigComponent,
	},
	PhoneNumber: {
		label: "Phone number",
		configComponent: PhoneNumberConfigComponent,
	},
	RadioButtons: {
		label: "Radio buttons",
		configComponent: RadioButtonsConfigComponent,
	},
	Select: {
		label: "Select",
		configComponent: SelectConfigComponent,
	},
	Signature: {
		label: "Signature",
		configComponent: SignatureConfigComponent,
	},
	Text: {
		label: "Static text",
		configComponent: TextConfigComponent,
	},
	Textarea: {
		label: "Textarea",
		configComponent: TextareaConfigComponent,
	},
	TextField: {
		label: "Text field",
		configComponent: TextFieldConfigComponent,
	},
	UrlParameter: {
		label: "URL parameter",
		configComponent: UrlParameterConfigComponent,
	},
	Upload: {
		label: "Legacy file upload",
		configComponent: UploadConfigComponent,
		deprecated: true,
	},
	MultiUpload: {
		label: "Legacy multi-file upload",
		configComponent: MultiUploadConfigComponent,
		deprecated: true,
	},
}

