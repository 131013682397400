import { AgendaItemType, BreakoutTrackId, LocalDateTime, Scope } from "@marketpartner/backend-api";
import { DialogTitle } from "@mui/material";
import { DateTime } from "luxon";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { AgendaHeadingForm } from "src/content/agendas/headings/AgendaHeadingForm";
import { useEvent } from "src/events/event-context";


type AddAgendaHeadingDialogProps = LockableDialogProps & {
    agendaId: string
    trackId: BreakoutTrackId | null
    suggestedStart: DateTime
}

const AddAgendaHeadingDialog: FC<AddAgendaHeadingDialogProps> = ({
    agendaId,
    trackId,
    suggestedStart,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const updateHeadingMutation = backend.agendaItems.useCreate(withNotification({
        successMessage: "Created heading",
        errorMessage: "Error creating heading",
    }))

    return <LockableDialog {...props}>
        <DialogTitle>
            Add heading
        </DialogTitle>
        <AgendaHeadingForm
            onSubmit={formData => updateHeadingMutation.mutateAsync([client.id, event.id, agendaId, {
                type: AgendaItemType.Heading,
                trackId,
                ...formData,
                localStart: LocalDateTime.fromZonedDateTime(formData.start),
            }])}
            suggestedStart={suggestedStart}
            actionName="Add"
        />
    </LockableDialog>
}

export const useAddAgendaHeadingDialog = createPopupHook({
    scope: Scope.Event,
    element: AddAgendaHeadingDialog,
})
