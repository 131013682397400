import { FC } from "react";
import { CreateEventAppButton } from "src/app-templates/deployments/event/CreateEventAppButton";
import { EventAppDeploymentsGrid } from "src/app-templates/deployments/event/EventAppDeploymentsGrid";
import { Page } from "src/common/layout/Page";


export const EventAppDeploymentsPage: FC = () => {
    return <Page
        title="Apps"
        actions={<>
            <CreateEventAppButton />
        </>}
    >
        <EventAppDeploymentsGrid />
    </Page>
}