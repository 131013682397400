import { Scope } from "@marketpartner/backend-api";
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { useEvent } from "src/events/event-context";
import { ComputedColumnForm } from "src/registrations/computed-columns/ComputedColumnForm";


export type EditComputedColumnDialogProps = LockableDialogProps & {
    columnIndex: number
}

const EditComputedColumnDialog: FC<EditComputedColumnDialogProps> = ({
    columnIndex,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const column = event.computedColumns[columnIndex]

    const updateEventMutation = backend.events.useUpdateEvent(withNotification({
        errorMessage: "Error updating column"
    }))

    return <LockableDialog
        maxWidth="sm"
        fullWidth
        {...props}
    >
        <DialogTitle>Edit column</DialogTitle>
        <ComputedColumnForm
            actionName="Save"
            existingColumn={column}
            onSubmit={column => updateEventMutation.mutateAsync([client.id, event.id, {
                computedColumns: event.computedColumns.map((c, idx) => idx === columnIndex ? column : c)
            }])}
        />
    </LockableDialog>
}

export const useEditComputedColumnDialog = createPopupHook({
    element: EditComputedColumnDialog,
    scope: Scope.Event,
})