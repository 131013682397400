import { FC } from "react"
import { BarAndFlex } from "src/common/layout/BarAndFlex"
import { Bar } from "src/common/layout/Bar"
import { Spacer } from "src/common/layout/Spacer"
import { AddEventPartnerInstanceButton } from "src/content/event-partner/instances/AddEventPartnerInstanceButton"
import { EventPartnerInstancesGrid } from "src/content/event-partner/instances/EventPartnerInstancesGrid"


export const EventPartnerInstances: FC = () => {

    return <BarAndFlex barContent={
        <Bar spacing={1} p={2}>
            <Spacer />
            <AddEventPartnerInstanceButton />
        </Bar>
    }>
        <EventPartnerInstancesGrid />
    </BarAndFlex>
}