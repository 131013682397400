import { defaultRegistrationElementMetadata, FormElementConfig, isFormField, RegistrationElementMetadata, RegistrationFieldVisibility } from "@marketpartner/backend-api"
import { Grid } from "@mui/material"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import { FC } from "react"
import { RegistrationFieldVisibilitySelector } from "src/registrations/configuration/categories/reg-form/RegistrationFieldVisibilitySelector"


export type RegistrationElementMetadataFormProps = {
    config: FormElementConfig<RegistrationElementMetadata>
    onChange: (config: FormElementConfig<RegistrationElementMetadata>) => void
}

export const RegistrationElementMetadataForm: FC<RegistrationElementMetadataFormProps> = ({
    config,
    onChange,
}) => {
    if (!isFormField(config)) {
        return null
    }

    const metadata = config.metadata ?? defaultRegistrationElementMetadata()
    const setProperties = (properties: Partial<RegistrationElementMetadata>) => {
        onChange({
            ...config,
            metadata: {
                ...metadata,
                ...properties,
            }
        })
    }

    const changeVisibility = (visibility: RegistrationFieldVisibility) => {
        setProperties({
            visibility,
            userEditable: visibility === RegistrationFieldVisibility.AdminOnly ? false : metadata.userEditable,
        })
    }

    return <>
        <Grid item xs={12}>
            <RegistrationFieldVisibilitySelector
                visibility={metadata.visibility}
                onChangeVisibility={changeVisibility}
            />
        </Grid>
        <Grid item xs={12}>
            <FormControlLabel
                label="Editable by user"
                control={
                    <Checkbox
                        checked={metadata.userEditable}
                        onChange={() => setProperties({ userEditable: !metadata.userEditable })}
                    />
                }
                disabled={metadata.visibility === RegistrationFieldVisibility.AdminOnly}
            />
        </Grid>
    </>
}

