import { Client } from '@marketpartner/backend-api'
import { createContext, FC, ReactNode, useContext } from 'react'
import { useLocation } from 'react-router'
import { backend } from 'src/common/api'
import { EnsureLoaded } from 'src/common/loading/EnsureLoaded'

export type ClientContext = Client & {
    isLoading: boolean
}

const clientIdContext = createContext<string | undefined>(undefined)
const clientContext = createContext<ClientContext | undefined>(undefined)

export const useClientId = () => useContext(clientIdContext)
export const useClient = () => useContext(clientContext)

export type EnsureClientContextLoadedProps = {
    children: ReactNode
}

export const EnsureClientContextLoaded: FC<EnsureClientContextLoadedProps> = ({
    children
}) => {
    const id = useClientId()
    const client = useClient()

    return <EnsureLoaded
        isLoading={Boolean(id && !client?.id)}
        isReloading={Boolean(client?.isLoading)}
        children={children}
    />
}

export type ClientContextProviderProps = {
    children: ReactNode
}

export const ClientContextProvider: FC<ClientContextProviderProps> = ({
    children
}) => {
    return <ClientIdContextProvider>
        <ClientContextProviderLoader children={children} />
    </ClientIdContextProvider>
}

const ClientContextProviderLoader: FC<ClientContextProviderProps> = ({
    children
}) => {
    const clientId = useContext(clientIdContext)
    const { data, isLoading } = backend.clients.useGet([clientId!], {
        enabled: Boolean(clientId)
    })

    if (!data) {
        return <>{children}</>
    }

    return <clientContext.Provider value={{
        ...data,
        isLoading,
    }} children={children} />
}

type ClientIdContextProviderProps = {
    children: ReactNode
}

const ClientIdContextProvider: FC<ClientIdContextProviderProps> = ({
    children
}) => {
    const location = useLocation()
    const clientMatch = location.pathname.match(/\/clients\/([^/]*)/)

    return <clientIdContext.Provider value={clientMatch?.[1]} children={children} />
}