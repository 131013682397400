import { FC } from "react";
import { AppDeploymentsGrid, AppDeploymentsGridProps } from "src/app-templates/deployments/AppDeploymentsGrid";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { useEvent } from "src/events/event-context";

export type EventAppDeploymentsGridProps = Partial<AppDeploymentsGridProps>

export const EventAppDeploymentsGrid: FC<EventAppDeploymentsGridProps> = ({
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const query = backend.appTemplateDeployments.useGetEventDeployments([client.id, event.id])

    return <AppDeploymentsGrid
        queryOrRows={query}
        nomenclature="App"
        {...props}
    />
}
