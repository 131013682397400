import { ContentCategoryId, ContentSubCategoryId } from "@marketpartner/backend-api"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { useEvent } from "src/events/event-context"
import { NameAndSubtitle, NameAndSubtitleProps } from "src/registrations/NameAndSubtitle"


export type ContentCategoryNameProps = Partial<NameAndSubtitleProps> & {
    categoryId: ContentCategoryId
    subCategoryId?: ContentSubCategoryId | null
    plural?: boolean
}

export const ContentCategoryName: FC<ContentCategoryNameProps> = ({
    categoryId,
    subCategoryId,
    plural = false,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!

    const categoryQuery = backend.contentCategories.useGetCategories([client.id, event.id], {
        select: categories => categories.find(category => category.id === categoryId) ?? null
    })
    const subCategory = categoryQuery.data?.subCategories?.find(subCategory => subCategory.id === subCategoryId) ?? null

    const categoryName = plural ? categoryQuery.data?.pluralName : categoryQuery.data?.name
    const subCategoryName = plural ? subCategory?.pluralName : subCategory?.name

    return <NameAndSubtitle
        name={categoryName}
        subtitle={subCategoryName}
        {...props}
    />
}