import { SelectConfig } from "@marketpartner/backend-api"
import { Grid, TextField } from "@mui/material"
import { ChoicesConfig } from "src/forms/elements/types/ChoicesConfig"
import { FormElementConfigComponent } from "src/forms/elements/types/formElementConfigTypes"
import { StandardFields } from "src/forms/elements/types/StandardFields"

export const SelectConfigComponent: FormElementConfigComponent<SelectConfig> = ({
    config,
    updateConfigProperty
}) => {

    return <>
        <StandardFields config={config} updateConfigProperty={updateConfigProperty}/>
        <Grid item xs={12}>
            <TextField
                label="Default value"
                fullWidth
                value={config.defaultValue}
                onChange={(e) => updateConfigProperty('defaultValue', e.target.value)}
            />
        </Grid>
        <Grid item xs={12}>
            <ChoicesConfig
                config={config}
                updateConfigProperty={updateConfigProperty}
                headerText="Select options"
            />
        </Grid>
    </>
}