import { Card, CardActionArea, CardActionAreaProps, CardContent, CardHeader, Container, Typography } from "@mui/material"
import { ElementType, FC, MouseEventHandler, ReactNode } from "react"
import { Link } from "react-router-dom"
import { ContextMenuButton } from "src/common/menus/ContextMenuButton"


export const contentCardWidth = 175
export const contentCardHeight = 240

export type ContentCardProps = {
    primaryText?: string
    secondaryTexts?: string[]
    avatar?: ReactNode
    link?: string
    onClick?: MouseEventHandler<HTMLElement>
    onClickMenu?: MouseEventHandler<HTMLButtonElement>
    opacity?: number
}

export const ContentCard: FC<ContentCardProps> = ({
    primaryText = "",
    secondaryTexts = [],
    avatar,
    link,
    onClick,
    onClickMenu,
    opacity = 1,
}) => {

    const actionAreaLinkProps: CardActionAreaProps<ElementType<any>> = link ? {
        component: Link,
        to: link,
    } : {
        component: "div",
    }

    return <Card elevation={0} sx={{
        width: contentCardWidth,
        height: contentCardHeight,
    }}>
        <CardActionArea
            {...actionAreaLinkProps}
            onClick={onClick}
            sx={{ height: "100%" }}
        >
            <CardHeader
                action={<ContextMenuButton
                    onMouseDown={e => e.stopPropagation()}
                    onClick={e => {
                        onClickMenu?.(e)
                        e.preventDefault()
                        e.stopPropagation()
                    }}
                    sx={{ visibility: onClickMenu ? "visible" : "hidden" }}
                />}
                sx={{ pb: 0 }}
            />
            <CardContent sx={{ textAlign: "center", pt: 0 }}>
                <Container sx={{ mb: 1, opacity }}>
                    {avatar}
                </Container>
                <Typography sx={{
                    opacity,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}>
                    {primaryText}
                </Typography>
                {secondaryTexts.map((text, idx) => <Typography
                    key={idx}
                    variant="body2"
                    color="text.secondary"
                    children={text}
                    sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                />)}
            </CardContent>
        </CardActionArea>
    </Card>
}