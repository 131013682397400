import { filterFormFields, FormElementConfig } from "@marketpartner/backend-api"
import { Checkbox, List, ListItem, ListItemButton, ListItemButtonProps, ListItemIcon, ListItemText } from "@mui/material"
import { FC, useEffect, useMemo } from "react"


export type SelectableElementsListProps = {
    existingElements: FormElementConfig[]
    elements: FormElementConfig[]
    selectedIdxs: number[]
    onChangeSelectedIdxs: (selectedIdxs: number[]) => void
}

export const SelectableElementsList: FC<SelectableElementsListProps> = ({
    existingElements,
    elements,
    selectedIdxs,
    onChangeSelectedIdxs,
}) => {
    const availableIndexes = useMemo(() => {
        const idxs: number[] = []
        const existingNames = filterFormFields(existingElements).map(field => field.name)
        elements.forEach((element, idx) => {
            if (!("name" in element) || !existingNames.includes(element.name)) {
                idxs.push(idx)
            }
        })
        return idxs
    }, [existingElements, elements])

    useEffect(() => {
        onChangeSelectedIdxs(availableIndexes)
    }, [onChangeSelectedIdxs, availableIndexes])

    const allSelected = selectedIdxs.length === availableIndexes.length
    const noneSelected = selectedIdxs.length === 0

    const toggleAll = () => {
        if (!allSelected) {
            onChangeSelectedIdxs(availableIndexes)
        } else {
            onChangeSelectedIdxs([])
        }
    }

    const toggle = (idx: number) => {
        const ids = new Set(selectedIdxs)
        if (ids.has(idx)) {
            ids.delete(idx)
        } else {
            ids.add(idx)
        }
        onChangeSelectedIdxs(Array.from(ids))
    }

    return <List dense>
        <ListItemButton onClick={toggleAll} sx={{
            position: "sticky",
            top: 0,
            backgroundColor: theme => theme.palette.background.default,
            zIndex: theme => theme.zIndex.appBar,
            borderWidth: "0 0 1px",
            borderColor: theme => theme.palette.divider,
            borderStyle: "solid",
        }}>
            <ListItem disablePadding>
                <ListItemIcon>
                    <Checkbox
                        color="default"
                        edge="start"
                        checked={allSelected}
                        indeterminate={!allSelected && !noneSelected}
                        disableRipple
                    />
                </ListItemIcon>
                <ListItemText secondary="Select all/none" />
            </ListItem>
        </ListItemButton>
        {elements.map((element, idx) => <SelectableElement
            key={idx}
            config={element}
            isSelected={selectedIdxs.includes(idx)}
            onClick={() => toggle(idx)}
            disabled={!availableIndexes.includes(idx)}
        />)}
    </List>
}

type SelectableElementProps = Partial<ListItemButtonProps> & {
    config: FormElementConfig
    isSelected: boolean
}

const SelectableElement: FC<SelectableElementProps> = ({
    config,
    isSelected,
    ...props
}) => {
    let primaryText = ""
    if ("name" in config) {
        primaryText = config.name
    } else if ("text" in config) {
        primaryText = config.text.substring(0, 30)
        if (config.text.length > 30) {
            primaryText += "..."
        }
    }

    return <ListItemButton {...props}>
        <ListItemIcon>
            <Checkbox edge="start" checked={isSelected} disableRipple />
        </ListItemIcon>
        <ListItemText 
            primary={primaryText}
            secondary={config.type}
            sx={{
                display: "flex",
                justifyContent: "space-between",
            }}
        />
    </ListItemButton>
}