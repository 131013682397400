import { BackendOptions, buildBackend } from "@marketpartner/backend-api";
import { buildBackendHooks } from "@marketpartner/mp-common-react";
import { StoredCredentials } from "src/login/stored-credentials";

const apiOverrides: Record<string, string> = {
    "localhost:3000": "https://dev.marketpartner.app",
    "localhost:3001": "https://marketpartner.app",
}

const backendOptions: BackendOptions = {
    baseUrl: apiOverrides[window.location.host],
    credentialsProvider: async () => {
        const accessToken = StoredCredentials.getAccessToken()
        const cognitoTokens = await StoredCredentials.getCognitoTokens()

        if (cognitoTokens?.accessToken) {
            return {
                type: "AdminCognitoJwt",
                jwt: cognitoTokens.accessToken.toString(),
            }
        } else if (accessToken) {
            return {
                type: "AccessToken",
                accessToken,
            }
        }
    }
}

export const asyncBackend = buildBackend(backendOptions)

export const backend = buildBackendHooks(backendOptions)