import { SxProps, Tab, TabProps, Tabs, TabsProps } from "@mui/material"
import { FC, ReactNode } from "react"
import { Navigate, useParams, useRoutes } from "react-router"
import { Link } from "react-router-dom"
import { BarAndFlex } from "src/common/layout/BarAndFlex"

export type TabDefinition = Partial<TabProps<typeof Link>> & {
    path: string
    label: ReactNode
    element: ReactNode
    hidden?: boolean
    scrollable?: boolean
}

export type RoutedTabsProps = Partial<TabsProps> & {
    tabs: TabDefinition[]
}

export const RoutedTabs: FC<RoutedTabsProps> = ({
    tabs,
    ...props
}) => {
    const filteredTabs = tabs.filter(it => !it.hidden)
    const tabLayoutElement = useRoutes([
        {
            path: ":tabPath/*",
            element: <RoutedTabsLayout tabs={filteredTabs} {...props} />
        }
    ])

    if (tabLayoutElement) {
        return tabLayoutElement
    }

    if (filteredTabs[0]?.path) {
        return <Navigate replace to={filteredTabs[0].path} />
    }

    return <></>
}

const RoutedTabsLayout: FC<RoutedTabsProps> = ({
    tabs,
    ...props
}) => {
    const tabPath = useParams().tabPath!

    const tab = tabs.find(it => it.path === tabPath)

    return <BarAndFlex
        barContent={
            <Tabs
                value={tab?.path ?? tabs[0].path}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                {...props}
            >
                {tabs.map(({ path, element, scrollable, ...props }) =>
                    <Tab key={path} value={path} component={Link} to={`../${path}`} replace {...props} />
                )}
            </Tabs>
        }
        flexStyle={{
            overflowY: tab?.scrollable ? "auto" : undefined,
        } as SxProps}
    >
        {tab?.element}
    </BarAndFlex>
}