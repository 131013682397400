import { SearchBox } from "@marketpartner/mp-common-mui"
import { useSearch } from "@marketpartner/mp-common-react"
import { Box } from "@mui/material"
import { CreateUserButton } from "src/access/users/CreateUserButton"
import { UsersGrid } from "src/access/users/UsersGrid"
import { backend } from "src/common/api"
import { BarAndFlex } from "src/common/layout/BarAndFlex"
import { Bar } from "src/common/layout/Bar"
import { Spacer } from "src/common/layout/Spacer"
import { Loading } from "src/common/loading/Loading"


export const Users = () => {
    const usersQuery = backend.adminUsers.useGetAll([])
    const [searchText, setSearchText, filteredUsersQuery] = useSearch(
        usersQuery,
        user => [
            user.name,
        ]
    )

    return <BarAndFlex barContent={
        <Bar spacing={1} p={2}>
            <SearchBox searchText={searchText} setSearchText={setSearchText} />
            <Spacer />
            <Box>
                <CreateUserButton />
            </Box>
        </Bar>
    }>
        <Loading request={filteredUsersQuery} render={filteredUsers =>
            <UsersGrid users={filteredUsers} />
        } />
    </BarAndFlex>
}