import { SearchBox } from "@marketpartner/mp-common-mui"
import { useSearch, useUrlState } from "@marketpartner/mp-common-react"
import { RegistrationTokensGrid } from "src/access/tokens/registration-tokens/RegistrationTokensGrid"
import { backend } from "src/common/api"
import { BarAndFlex } from "src/common/layout/BarAndFlex"
import { Bar } from "src/common/layout/Bar"
import { ClientAndEventSelector } from "src/events/ClientAndEventSelector"


export const RegistrationTokens = () => {
    const [clientId, setClientId] = useUrlState("clientId")
    const [eventId, setEventId] = useUrlState("eventId")
    const tokensQuery = backend.accessTokens.useGetEventRegistrationTokens([clientId!, eventId!], {
        enabled: Boolean(clientId && eventId),
    })
    const [searchText, setSearchText, filteredTokens] = useSearch(
        tokensQuery,
        token => [
            token.id,
            token.token,
            token.name.toLowerCase(),
            token.registrationId,
        ]
    )

    function changeClientId(newClientId: string | null) {
        setClientId(newClientId ?? "")
        setEventId("")
        setSearchText("")
    }

    function changeEventId(newEventId: string | null) {
        setEventId(newEventId ?? "")
        setSearchText("")
    }

    return <BarAndFlex barContent={
        <Bar alignItems="top" my={2}>
            <ClientAndEventSelector
                clientId={clientId}
                onChangeClientId={changeClientId}
                eventId={eventId}
                onChangeEventId={changeEventId}
                sx={{ mx: 2 }}
                size="small"
            />
            <SearchBox
                searchText={searchText}
                setSearchText={setSearchText}
                disabled={!clientId || !eventId}
            />
        </Bar>
    }>
        <RegistrationTokensGrid clientId={clientId} eventId={eventId} tokensQuery={filteredTokens} />
    </BarAndFlex>
}