import { AdminUser } from '@marketpartner/backend-api'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { IconButton, Menu } from '@mui/material'
import { FC, useRef, useState } from 'react'
import { ResetAdminPasswordMenuItem } from 'src/access/users/ResetAdminPasswordMenuItem'

export type AdminUserMenuButtonProps = {
    user: AdminUser
}

export const AdminUserMenuButton: FC<AdminUserMenuButtonProps> = ({
    user
}) => {
    const [menuOpen, setMenuOpen] = useState(false)
    const buttonRef = useRef<HTMLButtonElement | null>(null)

    const closeMenu = () => setMenuOpen(false)

    return <>
        <IconButton onClick={() => setMenuOpen(!menuOpen)} ref={buttonRef}>
            <MoreHorizIcon />
        </IconButton>
        <Menu anchorEl={buttonRef.current} keepMounted open={menuOpen} onClose={closeMenu}>
            <ResetAdminPasswordMenuItem user={user} closeMenu={closeMenu} />
        </Menu>
    </>
}
