import { AttachedRole } from "@marketpartner/backend-api"
import { ListItemText } from "@mui/material"
import { FC } from "react"
import { Link } from "react-router-dom"
import { link } from "src/common/routing/routes"

export function roleText(
    { role, clientId, eventId }: AttachedRole
) {
    if (eventId) {
        return `${role} for ${eventId} (${clientId})`
    }
    if (clientId) {
        return `${role} for ${clientId}`
    }
    return role
}

export type RoleTextProps = {
    attachedRole: AttachedRole
}

export const RoleText: FC<RoleTextProps> = ({
    attachedRole
}) => {
    return <ListItemText
        primary={attachedRole.role}
        secondary={attachedRole.clientId && getRestrictionText(attachedRole)}
    />
}

function getRestrictionText({
    clientId,
    eventId
}: AttachedRole) {
    if (!clientId) {
        return <></>
    }
    if (!eventId) {
        return <Link style={{ color: "inherit" }} to={link.toClient(clientId)}>{clientId}</Link>
    }
    return <>
        <Link style={{ color: "inherit" }} to={link.toEvent(clientId, eventId)}>{eventId}</Link> ({clientId})
    </>
}