import background from "src/login/background--dark.jpeg"

// Shared between token login screen & Amplify login
// as amplify auth doesn't appear to provide a way to
// specify a wrapper component that only gets displayed
// when not logged-in.
export const loginFormContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    minHeight: '100vh',
    width: '100%',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    overflow: 'auto',
    flexDirection: 'column',
    padding: '20px 0'
} as const
