import { AgendaItemType, BreakoutTrackItem, Permission, StructuredBreakoutTrack, StructuredBreakoutTrackItem } from "@marketpartner/backend-api"
import { Add } from "@mui/icons-material"
import { Divider, IconButton, Stack, SxProps } from "@mui/material"
import { DateTime } from "luxon"
import { FC, Fragment } from "react"
import { useClient } from "src/clients/client-context"
import { EditableAgendaItem } from "src/content/agendas/EditableAgendaItem"
import { EditableAgendaHeading } from "src/content/agendas/headings/EditableAgendaHeading"
import { EditableSession } from "src/content/agendas/sessions/EditableSession"
import { useAddBreakoutTrackItemMenu } from "src/content/agendas/tracks/AddBreakoutTrackItemMenu"
import { useEvent } from "src/events/event-context"
import { ifHas } from "src/login/IfHas"


export type EditableTrackProps = {
    track: StructuredBreakoutTrack
    start: DateTime
    sx?: SxProps
}

export const EditableTrack: FC<EditableTrackProps> = ({
    track,
    start,
    sx,
}) => {
    const client = useClient()!
    const event = useEvent()!
    const addMenu = useAddBreakoutTrackItemMenu()

    return <EditableAgendaItem
        item={track}
        headerText={track.name || <i>Track {track.trackNumber}</i>}
        sx={{ ...sx }}
    >
        <Stack sx={{
            flexGrow: 1,
            justifyContent: "center"
        }}>
            {track.items.map((item, idx) => <Fragment key={item.id}>
                {idx !== 0 && <Divider />}
                <EditableBreakoutTrackItem item={item} />
            </Fragment>)}
            {ifHas(Permission.EditEventContent, client.id, event.id, <IconButton
                onClick={e => addMenu.open({
                    agendaId: track.agendaId,
                    trackId: track.id,
                    anchorEl: e.currentTarget,
                    suggestedStart: endOf(track.items) ?? start,
                })}
                children={<Add />}
                sx={{ alignSelf: "center" }}
            />)}
        </Stack>
    </EditableAgendaItem>
}

type EditableBreakoutTrackItemProps = {
    item: StructuredBreakoutTrackItem
}

const EditableBreakoutTrackItem: FC<EditableBreakoutTrackItemProps> = ({
    item
}) => {
    switch (item.type) {
        case AgendaItemType.Session: return <EditableSession session={item} />
        case AgendaItemType.Heading: return <EditableAgendaHeading heading={item} />
    }
}

const endOf = (
    items: BreakoutTrackItem[]
): DateTime | undefined => DateTime.max(
    ...items.map(it => "end" in it ? it.end : it.start)
)