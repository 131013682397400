import { LocalIntervalList, Location } from "@marketpartner/backend-api";
import { Grid, TextField } from "@mui/material";
import { FC, useState } from "react";
import { DialogForm, ExtendableDialogFormProps } from "src/common/dialogs/DialogForm";
import { IdField } from "src/common/form-inputs/IdField";
import { NumberTextField } from "src/common/form-inputs/NumberTextField";

export type LocationFormData = {
    id: string
    name: string
    capacity: number
    localMeetingAvailability: LocalIntervalList
}

export type LocationFormProps = ExtendableDialogFormProps<LocationFormData> & {
    location?: Location
}

export const LocationForm: FC<LocationFormProps> = ({
    location,
    ...props
}) => {
    const [id, setId] = useState(location?.id ?? "")
    const [name, setName] = useState(location?.name ?? "")
    const [capacity, setCapacity] = useState(location?.capacity ?? 0)

    return <DialogForm
        formData={{
            id,
            name,
            capacity,
            localMeetingAvailability: location?.localMeetingAvailability ?? [],
        }}
        isValid={Boolean(id && name)}
        {...props}
    >
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    value={name}
                    onChange={e => setName(e.target.value)}
                    label="Name"
                    required
                    fullWidth
                />
            </Grid>
            {!location && <Grid item xs={12}>
                <IdField
                    nameValue={name}
                    idValue={id}
                    onChangeId={setId}
                    maxLength={255}
                />
            </Grid>}
            <Grid item xs={12}>
                <NumberTextField
                    value={capacity}
                    onChange={setCapacity}
                    label="Capacity (0 = unlimited)"
                    fullWidth
                />
            </Grid>
        </Grid>
    </DialogForm>
}