import { ScheduledEmail, Scope } from "@marketpartner/backend-api";
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { ScheduledEmailForm } from "src/email/scheduling/ScheduledEmailForm";
import { useEvent } from "src/events/event-context";


export type EditScheduledEmailDialogProps = LockableDialogProps & {
    email: ScheduledEmail
}

const EditScheduledEmailDialog: FC<EditScheduledEmailDialogProps> = ({
    email,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!

    const updateEmailMutation = backend.scheduledEmails.useUpdate(withNotification({
        errorMessage: "Error updating scheduled email"
    }))

    return <LockableDialog
        {...props}
        maxWidth="sm"
        fullWidth
    >
        <DialogTitle>Edit scheduled email</DialogTitle>
        <ScheduledEmailForm
            actionName="Save"
            onSubmit={formData => updateEmailMutation.mutateAsync([client.id, event.id, email.id, formData])}
            scheduledEmail={email}
        />
    </LockableDialog>
}

export const useEditScheduledEmailDialog = createPopupHook({
    element: EditScheduledEmailDialog,
    scope: Scope.Event
})