import { Star, StarBorder } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { withNotification } from "src/common/notifications/with-notification"
import { useEvent } from "src/events/event-context"

export type HighlightResponseButtonProps = {
    formResponseId: number
    formId: number
    isHighlighted: boolean
}

export const HighlightResponseButton: FC<HighlightResponseButtonProps> = ({
    isHighlighted,
    formId,
    formResponseId
}) => {
    const client = useClient()!
    const event = useEvent()!

    const updateMutation = backend.formResponses.useUpdate(withNotification({
        successMessage: 'Response updated',
        errorMessage: 'Request failed',
    }))

    return <>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <IconButton
                color="primary"
                onClick={() => updateMutation.mutate([client.id, event.id, formId, formResponseId, {
                    isHighlighted: !isHighlighted
                }])}
                disabled={updateMutation.isPending}
            >
                {isHighlighted ? <Star /> : <StarBorder />}
            </IconButton>
        </div>
    </>
}