import { DynamicEmailTemplate, DynamicEmailTemplateId, EmailTemplateType, Scope } from "@marketpartner/backend-api";
import { filterType } from "@marketpartner/mp-common";
import { Collapse, DialogTitle, Grid } from "@mui/material";
import { FC, useState } from "react";
import { useClient } from "src/clients/client-context";
import { ClientSelector } from "src/clients/ClientSelector";
import { backend } from "src/common/api";
import { DialogForm } from "src/common/dialogs/DialogForm";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { LoadedAutocomplete } from "src/common/form-inputs/LoadedAutocomplete";
import { withNotification } from "src/common/notifications/with-notification";


export type CopyDynamicEmailTemplateDialogProps = LockableDialogProps

const CopyDynamicEmailTemplateDialog: FC<CopyDynamicEmailTemplateDialogProps> = ({
    ...props
}) => {
    return <LockableDialog
        maxWidth="xs"
        fullWidth
        {...props}
    >
        <DialogTitle>Copy email template</DialogTitle>
        <CopyDynamicEmailTemplateDialogContent />
    </LockableDialog>
}

const CopyDynamicEmailTemplateDialogContent: FC = () => {
    const client = useClient()!

    const [sourceClientId, setSourceClientId] = useState<string | null>(null)
    const [templateId, setTemplateId] = useState<DynamicEmailTemplateId | null>(null)
    const [template, setTemplate] = useState<DynamicEmailTemplate | null>(null)

    const createMutation = backend.emailTemplates.useCreateDynamicEmailTemplate(withNotification({
        errorMessage: "Error copying email template",
    }))
    const templatesQuery = backend.emailTemplates.useGetEmailTemplatesForClient([sourceClientId!], {
        enabled: Boolean(sourceClientId),
        select: templates => filterType(templates, EmailTemplateType.Dynamic as const),
    })

    const handleSelectClientId = (clientId: string | null) => {
        setSourceClientId(clientId)
        setTemplateId(null)
        setTemplate(null)
    }

    return <DialogForm
        formData={{}}
        actionName="Copy"
        onSubmit={() => createMutation.mutateAsync([client.id, template!])}
        isValid={Boolean(sourceClientId && template)}
    >
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ClientSelector
                    clientId={sourceClientId}
                    onSelectClientId={handleSelectClientId}
                    required
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <Collapse in={Boolean(sourceClientId)}>
                    <LoadedAutocomplete
                        fetchRequest={templatesQuery}
                        selectedId={templateId}
                        onSelectId={setTemplateId}
                        onSelectLoadedItem={setTemplate}
                        label="Template"
                        getSecondaryText={template => template.staticTemplateId}
                        fullWidth
                    />
                </Collapse>
            </Grid>
        </Grid>
    </DialogForm>
}

export const useCopyDynamicEmailTemplateDialog = createPopupHook({
    element: CopyDynamicEmailTemplateDialog,
    scope: Scope.Client,
})