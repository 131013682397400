import { ComputedColumn, LocalDateTime, Permission, RegistrationFieldVisibility } from '@marketpartner/backend-api';
import AddIcon from '@mui/icons-material/Add';
import { DialogTitle } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router';
import { useClient } from 'src/clients/client-context';
import { backend } from 'src/common/api';
import { DialogButton } from "src/common/dialogs/DialogButton";
import { withNotification } from 'src/common/notifications/with-notification';
import { link } from 'src/common/routing/routes';
import { EventForm, EventFormData } from "src/events/EventForm";
import { ifHas } from 'src/login/IfHas';

export const CreateEventButton: FC = () => {
    const client = useClient()!
    const navigate = useNavigate()
    const createMutation = backend.events.useCreateEvent(withNotification({
        onSuccess: (_, [_clientId, eventDetails]) => navigate(link.toEvent(client.id, eventDetails.id)),
        successMessage: "Created event",
        errorMessage: "Error creating event"
    }))

    return ifHas(Permission.AdministerSystem, client.id, undefined,
        <DialogButton
            icon={<AddIcon />}
            text="Create event"
            variant="contained"
            color="primary"
        >
            <DialogTitle>Create event</DialogTitle>
            <EventForm
                actionName="Create"
                onSubmit={formData => createMutation.mutateAsync([client.id, addDefaultComputedColumns(client.id, formData)])}
            />
        </DialogButton>
    )
}

const getDefaultComputedColumns: Record<string, (startTime: LocalDateTime, endTime: LocalDateTime) => ComputedColumn[]> = {
    big: (localStartTime: LocalDateTime, localEndTime: LocalDateTime) => [{"id": "Attended", "type": "Boolean", "visibility": RegistrationFieldVisibility.AdminOnly, "expressions": {"name": "", "type": "Group", "operator": "OR", "expressions": [{"name": "Virtual attendance", "type": "Group", "operator": "AND", "expressions": [{"type": "TimedActivityDuration", "lookups": [{"id": "WatchStream:main-stream", "localEndIso": localEndTime.toISO(), "localStartIso": localStartTime.toISO()}], "targetDurationMinutes": 15}]}, {"name": "In person attendance", "type": "Group", "operator": "AND", "expressions": [{"type": "ActionCount", "lookups": [{"id": "Attend:event", "localEndIso": null, "localStartIso": null}], "targetCount": 1}]}]}}]
} as const

const addDefaultComputedColumns = (clientId: string, formData: EventFormData): EventFormData & { computedColumns?: ComputedColumn[] } => {
    const computedColumns = getDefaultComputedColumns[clientId]?.(
        LocalDateTime.fromZonedDateTime(formData.startTime.setZone(formData.timezone)),
        LocalDateTime.fromZonedDateTime(formData.endTime.setZone(formData.timezone))
    )
    return {
        ...formData,
        computedColumns
    }
}