import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { FC } from "react";
import { DialogButton, DialogButtonProps } from "src/common/dialogs/DialogButton";
import { useDialogContext } from "src/common/dialogs/LockableDialog";

export type RollbackButtonProps = Partial<DialogButtonProps> & {
    action: () => void
}

export const RollbackButton: FC<RollbackButtonProps> = ({
    action,
    ...props
}) => {
    return <DialogButton
        color="error"
        {...props}
    >
        <DialogTitle>Confirm database rollback</DialogTitle>
        <RollbackContent action={action} />
    </DialogButton>
}

type RollbackContentProps = {
    action: () => void
}

// This can't use ConfirmDialog, because the action already uses useUpdate, and we don't want to show two success/error messages
const RollbackContent: FC<RollbackContentProps> = ({
    action
}) => {
    const dialog = useDialogContext()

    function doAction() {
        action()
        dialog.close()
    }

    return <>
        <DialogContent>
            <DialogContentText>
                This could result in permanent loss of client data.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => dialog.close()} color="inherit">Cancel</Button>
            <Button color="error" variant="contained" onClick={doAction}>Confirm</Button>
        </DialogActions>
    </>
}