import { Timezone } from "@marketpartner/backend-api"
import { Stack, SxProps } from "@mui/material"
import { DateTime } from "luxon"
import { FC, useMemo } from "react"
import { Bar } from "src/common/layout/Bar"
import { DurationField, DurationFieldDuration } from "src/common/time/DurationField"
import { LuxonDatePicker, LuxonDatePickerProps, LuxonTimePicker, LuxonTimePickerProps } from "src/common/time/LuxonDateTimePickers"


export type LuxonDayTimeRangePickerProps = {
    start: DateTime
    end: DateTime
    onChangeStart: (start: DateTime) => void
    onChangeEnd: (end: DateTime) => void
    timezone: Timezone
    dayPickerProps?: Partial<LuxonDatePickerProps>
    timePickerProps?: Partial<LuxonTimePickerProps>
    startTimePickerProps?: Partial<LuxonTimePickerProps>
    endTimePickerProps?: Partial<LuxonTimePickerProps>
    spacing?: number
    sx?: SxProps
}

export const LuxonDayTimeRangePicker: FC<LuxonDayTimeRangePickerProps> = ({
    start,
    end,
    onChangeStart,
    onChangeEnd,
    timezone,
    dayPickerProps,
    timePickerProps,
    startTimePickerProps,
    endTimePickerProps,
    spacing = 2,
    sx,
}) => {
    const duration = useMemo(
        () => end.diff(start, ["hours", "minutes"]).toObject(),
        [start, end]
    )

    const handleChangeDay = (newDay: DateTime | null) => {
        if (!newDay) {
            return
        }
        onChangeStart(copyDayFromTo(newDay, start))
        onChangeEnd(copyDayFromTo(newDay, end))
    }

    const handleDurationChange = (newDuration: DurationFieldDuration) => {
        let newEnd = start.plus(newDuration)
        if (!start.hasSame(newEnd, "day")) {
            newEnd = start.startOf("day").plus({ hours: 23, minutes: 59 })
        }
        onChangeEnd(newEnd)
    }

    const mergedTimePickerProps = {
        timezone,
        required: true,
        hideTimezoneLabel: true,
        ...timePickerProps,
        sx: {
            width: 140,
            ...timePickerProps?.sx,
        }
    }

    return <Stack spacing={spacing} sx={sx}>
        <LuxonDatePicker
            timezone={timezone}
            value={start}
            onChange={handleChangeDay}
            label="Day"
            required
            {...dayPickerProps}
            sx={{ maxWidth: 200 }}
        />
        <Bar spacing={spacing}>
            <LuxonTimePicker
                value={start}
                onChange={start => start && onChangeStart(start)}
                label="Start"
                {...mergedTimePickerProps}
                {...startTimePickerProps}
            />
            <DurationField
                value={duration as DurationFieldDuration}
                onChange={handleDurationChange}
            />
            <LuxonTimePicker
                value={end}
                onChange={end => end && onChangeEnd(end)}
                label="End"
                {...mergedTimePickerProps}
                {...endTimePickerProps}
            />
        </Bar>
    </Stack>
}

const copyDayFromTo = (source: DateTime, target: DateTime) => target.set({
    year: source.year,
    month: source.month,
    day: source.day,
})