import { BooleanExpression } from "@marketpartner/backend-api";


export const isValidExpressionRecursive = (expression: BooleanExpression<any>): boolean => {
    const error = getExpressionValidationError(expression)
    if (!error && expression.type === "Group") {
        return expression.expressions.every(isValidExpressionRecursive)
    }
    return !error
}

export const getExpressionValidationError = (expression: BooleanExpression<any>): string | undefined => {
    if (expression.type === "Group") {
        if (expression.expressions.length === 0) {
            return "Group must contain at least one condition"
        }
    }
}