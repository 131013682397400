import { getErrorString } from "@marketpartner/mp-common"
import { Button, Fade, Stack, SxProps, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { UseQueryResult } from "@tanstack/react-query"
import { FC, ReactElement, ReactNode } from "react"
import { SwitchTransition } from "react-transition-group"
import { LoadingSpinner } from "src/common/loading/LoadingSpinner"
import { center, fullSize } from "src/common/styles"

export type BaseLoadingProps = {
    renderErrorCode?: (errorCode: number) => ReactNode
    sizing?: "full-size" | "fit-content"
    displaySpinnerOnReload?: boolean
    sx?: SxProps
}

export type SingleLoadingProps<Data> = BaseLoadingProps & {
    request: UseQueryResult<Data>
    render: (data: Exclude<Data, undefined>) => ReactNode
}

export type MultiLoadingProps<Data extends readonly [...unknown[]]> = BaseLoadingProps & {
    readonly requests: {
        readonly [Idx in keyof Data]: UseQueryResult<Data[Idx]>
    }
    render: (
        data: Data
    ) => ReactNode
}

export function Loading<Data>(props: SingleLoadingProps<Data>): ReactElement
export function Loading<Data extends readonly [...unknown[]]>(props: MultiLoadingProps<Data>): ReactElement
export function Loading({
    renderErrorCode = () => undefined,
    sizing = "full-size",
    displaySpinnerOnReload = false,
    sx,
    render,
    ...requestOrRequests
}: SingleLoadingProps<any> | MultiLoadingProps<any>): ReactElement {
    const singleRequest = "request" in requestOrRequests
    const requests = 'request' in requestOrRequests ? [requestOrRequests.request] : requestOrRequests.requests
    const data = requests.map(request => request.data)
    const dataAvailable = data.every(it => it !== undefined)

    const containerStyle = sizing === "fit-content" ? {
    } : fullSize
    const contentStyle = sizing === "fit-content" ? {
        py: 2,
    } : center

    const renderInTransition = (key: string, content: ReactNode) => <SwitchTransition>
        <Fade key={key} unmountOnExit mountOnEnter>
            <Box sx={{ ...containerStyle, ...sx }}>
                {content}
            </Box>
        </Fade>
    </SwitchTransition>

    const renderSpinner = () => renderInTransition('spinner', <LoadingSpinner sx={contentStyle} />)
    const renderData = () => renderInTransition('data', render(singleRequest ? data[0] : data as any))
    const renderErrorCodeContent = (content: ReactNode) => renderInTransition('error-code-content', content)
    const renderError = () => renderInTransition('error', <ErrorContent
        errorMessage={requests.map(request => getErrorString(request.error)).join('. \n')}
        sx={contentStyle}
        reload={() => requests.forEach(request => request.refetch())}
    />)

    const errorCodes = requests
        .map(request => (request.error as any)?.response?.status)
        .filter(Boolean)
    const errorCodeContent = errorCodes.length ?
        renderErrorCode(Math.max(...errorCodes)) :
        undefined

    const anyPending = requests.some(request => request.isPending)
    const anyFetching = requests.some(request => request.isFetching)

    // Display a spinner if we are missing data
    if (anyPending || (displaySpinnerOnReload && anyFetching)) {
        return renderSpinner()
    }

    // If there's an error, render that
    if (errorCodeContent) {
        return renderErrorCodeContent(errorCodeContent)
    }
    if (requests.some(request => request.error)) {
        return renderError()
    }

    // If a request returns undefined, this is considered to mean "can't load yet"
    if (dataAvailable) {
        return renderData()
    }

    return renderSpinner()
}

type ErrorContentProps = {
    errorMessage: string | undefined
    sx: SxProps
    reload: () => void
}

const ErrorContent: FC<ErrorContentProps> = ({
    errorMessage,
    sx,
    reload,
}) => {
    return <Stack sx={{ ...sx, alignItems: "center" }}>
        <Typography sx={{ color: "text.secondary" }}>
            An error occured
        </Typography>
        <Typography sx={{ color: "text.secondary", fontStyle: "italic" }}>
            {errorMessage}
        </Typography>
        <Button color="inherit" onClick={reload} sx={{ m: 1 }}>
            Try again
        </Button>
    </Stack>
}