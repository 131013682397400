import { filterFormFields } from "@marketpartner/backend-api";
import _ from "lodash";
import { FormElementCheck } from "src/forms/elements/checks/form-element-checks";



export const duplicateElementCheck: FormElementCheck = elements => {
    const duplicateNames = _(filterFormFields(elements))
        .countBy(it => it.name)
        .pickBy(count => count > 1)
        .keys()
        .value()

    if (!duplicateNames.length) {
        return
    }

    return duplicateNames.map(name => ({
        renderIssueContent: () => <>Duplicate form field: <b>{name}</b></>,
        level: "error",
        elementIds: elements
            .filter(config => "name" in config && config.name === name)
            .map((_, idx) => idx)
    }))
}