import { EventFeed } from "@marketpartner/backend-api"
import { Lock } from "@mui/icons-material"
import LockOpen from "@mui/icons-material/LockOpen"
import { Fade, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { DateTime } from "luxon"
import { FC } from "react"
import { BasicProperties, BasicProperty } from "src/common/layout/BasicProperties"
import { Bar } from "src/common/layout/Bar"
import { EventDateTimeText } from "src/common/time/DateTimeText"

type EventFeedDetailsProps = {
    feed?: EventFeed,
    sx?: any
}

export const EventFeedDetails: FC<EventFeedDetailsProps> = ({
    feed,
    sx
}) => {
    const isOpen = !feed?.end || feed.end > DateTime.utc()
    return <BasicProperties sx={sx}>
        <BasicProperty name="Require approval" value={feed?.requireApproval} />
        <BasicProperty name="Status" value={<FeedStatus isOpen={isOpen} />} />
        <FeedEndProperty isOpen={isOpen} end={feed?.end} />
    </BasicProperties>
}

type FeedStatusProps = {
    isOpen: boolean
}

const FeedStatus: FC<FeedStatusProps> = ({
    isOpen
}) => {
    const icon = isOpen ?
        <LockOpen color="success" fontSize="small" /> :
        <Lock color="error" fontSize="small" />
    const text = isOpen ? "Open" : "Closed"
    return <Bar spacing={0.5}>
        {icon}
        <Typography>{text}</Typography>
    </Bar>
}

type FeedEndPropertyProps = {
    isOpen: boolean,
    end?: DateTime | null
}

const FeedEndProperty: FC<FeedEndPropertyProps> = ({
    isOpen,
    end
}) => {
    const text = isOpen ? "Closes at" : "Closed at"
    return <Fade in={Boolean(end)}>
        <Box>
            <BasicProperty name={text} value={<EventDateTimeText dateTime={end} />} />
        </Box>
    </Fade>
}
