import { AgendaItemType, Breakout, Scope } from "@marketpartner/backend-api";
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { BreakoutForm } from "src/content/agendas/breakouts/BreakoutForm";
import { useEvent } from "src/events/event-context";


type EditBreakoutDialogProps = LockableDialogProps & {
    breakout: Breakout
}

const EditBreakoutDialog: FC<EditBreakoutDialogProps> = ({
    breakout,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const updatebreakoutMutation = backend.agendaItems.useUpdate(withNotification({
        successMessage: "Updated breakout",
        errorMessage: "Error updating breakout",
    }))

    return <LockableDialog {...props}>
        <DialogTitle>
            Edit breakout
        </DialogTitle>
        <BreakoutForm
            onSubmit={formData => updatebreakoutMutation.mutateAsync([client.id, event.id, breakout.agendaId, breakout.id, {
                type: AgendaItemType.Breakout,
                ...formData,
            }])}
            breakout={breakout}
            actionName="Save"
        />
    </LockableDialog>
}

export const useEditBreakoutDialog = createPopupHook({
    scope: Scope.Event,
    element: EditBreakoutDialog,
})
