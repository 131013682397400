import { Permission, StandardForm } from "@marketpartner/backend-api"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { DeleteButton } from "src/common/dialogs/DeleteButton"
import { useEvent } from "src/events/event-context"
import { ifHas } from "src/login/IfHas"

export type DeleteFormButtonProps = {
    form: StandardForm
    onDelete: () => void
}

export const DeleteFormButton: FC<DeleteFormButtonProps> = ({ form, onDelete }) => {
    const client = useClient()!
    const event = useEvent()!
    const deleteMutation = backend.forms.useDelete({
        onSuccess: onDelete
    })

    return ifHas(Permission.DeleteForms, client.id, event.id,
        <DeleteButton
            buttonText="Delete"
            itemName={form.name}
            text={`This will permanently delete this form and all associated data including form responses.`}
            confirmText={`permanently delete ${form.name}`}
            deleteAction={() => deleteMutation.mutateAsync([client.id, event.id, form.id])}
        />
    )
}