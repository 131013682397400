import { FormControlLabel, FormControlLabelProps, Switch, SwitchProps } from "@mui/material";
import { useAtom, useAtomValue } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { FC, ReactElement } from "react";

const devHostnames = [
    "localhost",
    "dev.marketpartner.app",
]
const isDev = devHostnames.includes(window.location.hostname)

const featureToggle = atomWithStorage("feature-toggle", false)

export const useFeatureToggleEnabled = () => {
    const toggleManuallyEnabled = useAtomValue(featureToggle) && isDev

    return toggleManuallyEnabled
}

export type FeatureToggleSwitchProps = Partial<FormControlLabelProps> & {
    switchProps?: SwitchProps
}

export const FeatureToggleSwitch: FC<FeatureToggleSwitchProps> = ({
    switchProps,
    ...props
}) => {
    const [enabled, setEnabled] = useAtom(featureToggle)

    if (!isDev) {
        return <></>
    }

    return <FormControlLabel
        control={<Switch
            checked={enabled}
            onChange={(_, checked) => setEnabled(checked)}
            {...switchProps}
        />}
        label="New features"
        {...props}
    />
}

export type FeatureToggleProps = {
    newComponent?: ReactElement
    legacyComponent?: ReactElement
}

export const FeatureToggle: FC<FeatureToggleProps> = ({
    newComponent,
    legacyComponent,
}) => {
    const enabled = useFeatureToggleEnabled()

    if (enabled) {
        return newComponent ?? <></>
    }

    return legacyComponent ?? <></>
}