import { CircularProgress } from "@mui/material";
import { UseQueryResult } from "@tanstack/react-query";
import { TabDefinition } from "src/common/routing/RoutedTabs";


export const useLoadedRoutedTabs = <Data,>(
    query: UseQueryResult<Data>,
    buildTabs: (data: Data) => TabDefinition[]
): TabDefinition[] => {
    if (!query.data) {
        return [{
            path: "",
            label: <CircularProgress size={16} />,
            element: <></>,
            disabled: true,
        }]
    }
    return buildTabs(query.data)
}