import { FormFieldValue, ObjectFieldValueType, toCsvValue } from "@marketpartner/backend-api"
import { GridCellModes, GridColDef, GridValidRowModel, GridValueGetterParams } from "@mui/x-data-grid-pro"
import { saveAs } from "file-saver"
import { DateTime } from "luxon"
import * as XLSX from "xlsx"

export type XlsxOptions = {
    additionalTimezone?: string
    dateFormat: string
}

const defaultXlsxOptions: XlsxOptions = {
    additionalTimezone: undefined, // If set, dates will be output in both UTC and in this specified timezone
    dateFormat: "yyyy-MM-dd HH:mm:ss",
}

// IMPORTANT: This should have a generic parameter to indicate the type of the row, but 
// adding it causes tsc to run out of memory at time of writing.

export type ExportColumnDefinition<R extends GridValidRowModel = any, V = any, F = V> = GridColDef<R, V, F> & {
    excludeFromCsv?: boolean
    csvHeaderName?: string
    getCsvValue?: GridColDef<R, V, F>["valueGetter"]
}

type Row = Record<string, unknown>
type ExcelRow = Record<string, unknown>

/**
 * Generates and downloads an .xslx file based on DataGrid column definitions.
 * Uses the column `field` (or `valueGetter` if defined) to extract cell values.
 * The header can be overridden by adding csvHeaderName to the column definitions.
 */
export function exportToXlsx(
    baseFileName: string,
    sheetName: string,
    rows: Row[],
    columns: ExportColumnDefinition[],
    options: Partial<XlsxOptions>
) {
    const fullOptions = {
        ...defaultXlsxOptions,
        ...options,
    }
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(
        workbook,
        XLSX.utils.json_to_sheet(buildSpreadsheetRows(rows, columns, fullOptions)),
        sheetName
    )
    const fileContents = XLSX.write(workbook, { type: "array" })

    const today = DateTime.now().toISODate()
    saveAs(
        new Blob([fileContents], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }),
        `${baseFileName}-${today}.xlsx`
    )
}

function buildSpreadsheetRows(sourceRows: Row[], columns: ExportColumnDefinition[], options: XlsxOptions) {
    return sourceRows.map(sourceRow => buildSpreadsheetRow(sourceRow, columns, options))
}

function buildSpreadsheetRow(sourceRow: Row, columns: ExportColumnDefinition[], options: XlsxOptions) {
    const excelRow = {}
    for (const column of columns) {
        if (!column.excludeFromCsv) {
            addToSpreadsheetRow(sourceRow, excelRow, column, options)
        }
    }
    return excelRow
}

function addToSpreadsheetRow(sourceRow: Row, excelRow: ExcelRow, column: ExportColumnDefinition, options: XlsxOptions) {
    let value = extractValue(sourceRow, column)
    if (value === undefined || value === null) {
        return
    }
    if (Array.isArray(value)) {
        value = convertToSpreadSheetString(value)
    }

    const key = column.csvHeaderName ?? column.headerName ?? column.field
    if (value instanceof DateTime) {
        addDateToSpreadsheetRow(excelRow, key, value, options)
    } else {
        excelRow[key] = value
    }
}

function addDateToSpreadsheetRow(excelRow: ExcelRow, key: string, date: DateTime, options: XlsxOptions) {
    const utcString = date.toUTC().toFormat(options.dateFormat)
    excelRow[key] = utcString
    if (options.additionalTimezone) {
        const tzString = date.setZone(options.additionalTimezone).toFormat(options.dateFormat)
        excelRow[`${key} (${options.additionalTimezone})`] = tzString
    }
}

function extractValue(sourceRow: Row, column: ExportColumnDefinition) {
    // Best effort at emulating the parameters to the grid column value getter
    const getterParams: GridValueGetterParams = {
        cellMode: GridCellModes.View,
        colDef: column as any,
        field: column.field,
        hasFocus: false,
        row: sourceRow,
        tabIndex: 0,
        value: sourceRow[column.field],

        api: undefined!,
        id: undefined!,
        rowNode: undefined!,
    }
    if (column.getCsvValue) {
        return column.getCsvValue(getterParams)
    }
    if (column.valueGetter) {
        return column.valueGetter(getterParams)
    }
    return sourceRow[column.field]
}

export function convertToSpreadSheetString(value: unknown): string {
    if (value === undefined || value === null) {
        return ""
    }
    if (typeof value === 'string') {
        return value
    }
    if (typeof value === 'number') {
        return value.toString()
    }
    if (Array.isArray(value)) {
        return value.map(convertToSpreadSheetString).join(",")
    }
    if (value instanceof DateTime) {
        return value.toISO()
    }
    if (typeof value === "object" && "type" in value && Object.values(ObjectFieldValueType).includes(value.type as any)) {
        return toCsvValue(value as FormFieldValue)
    }
    return value.valueOf().toString()
}
