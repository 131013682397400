import { RegistrationCategory } from "@marketpartner/backend-api";
import { useParallelMutation, UseParallelMutationOptions } from "@marketpartner/mp-common-react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { useEvent } from "src/events/event-context";


export type UseCopyCategoriesOptions = Partial<UseParallelMutationOptions<void, RegistrationCategory>>

export const useCopyCategories = ({
    ...options
}: UseCopyCategoriesOptions = {}) => {
    const client = useClient()!
    const event = useEvent()!
    const createMutation = backend.registrationCategories.useCreate({
        disableInvalidation: true,
    })
    const invalidate = backend.registrationCategories.useInvalidate()

    return useParallelMutation({
        processItem: async (category: RegistrationCategory) => createMutation.mutateAsync([
            client.id,
            event.id,
            category,
        ]),
        ...options,
        onSettled: async (data, error, variables, context) => {
            await invalidate()
            await options.onSettled?.(data, error, variables, context)
        }
    })
}