import { Typography } from "@mui/material";
import { DataGridPro, GridColDef, GridRowIdGetter, GridRowParams, useGridApiRef } from "@mui/x-data-grid-pro";
import { FC, useCallback } from "react";
import { ClientDomainsGrid } from "src/app-templates/domains/ClientDomainsGrid";
import { DomainSummary, useDomainSummaries } from "src/app-templates/domains/useDomainSummaries";
import { Bar } from "src/common/layout/Bar";
import { GridDetailPanel } from "src/common/layout/GridDetailPanel";
import { HoverLink } from "src/common/routing/HoverLink";
import { link } from "src/common/routing/routes";

const columns: GridColDef<DomainSummary>[] = [{
    field: "domainName",
    headerName: "Domain name",
    flex: 1,
    minWidth: 200,
}, {
    field: "clientDomainCount",
    headerName: "Clients",
    width: 100,
}, {
    field: "deploymentCount",
    headerName: "Deployments",
    width: 110,
    renderCell: ({ row }) => <Bar spacing={1}>
        <Typography>
            {row.deploymentCount}
        </Typography>
        <HoverLink to={link.toAllAppsList(row.domainName)} text="View" />
    </Bar>
}]

const getRowId: GridRowIdGetter<DomainSummary> = row => row.domainName

const getDetailPanelContent = ({ row }: GridRowParams<DomainSummary>) => {
    return <GridDetailPanel>
        <ClientDomainsGrid domainName={row.domainName} sx={{ maxWidth: 800 }} />
    </GridDetailPanel>
}

const getDetailPanelHeight = () => "auto" as const

export const DomainsGrid: FC = () => {
    const domains = useDomainSummaries()
    const apiRef = useGridApiRef()

    const handleRowClick = useCallback(({ row }: GridRowParams<DomainSummary>) => {
        apiRef.current.toggleDetailPanel(row.domainName)
    }, [apiRef])

    return <DataGridPro
        rows={domains.domains}
        columns={columns}
        getRowId={getRowId}
        loading={domains.isLoading}
        apiRef={apiRef}
        onRowClick={handleRowClick}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={getDetailPanelHeight}
        disableColumnMenu
        disableRowSelectionOnClick
    />
}