import { Typography } from "@mui/material"
import { DateTime } from "luxon"
import { cloneElement, FC } from "react"
import { Bar } from "src/common/layout/Bar"
import { activeStatusColors, ActiveStatusDetails, getGenericActiveStatus } from "src/common/statuses/active-status-details"


export type ActiveStatusProps = {
    details: ActiveStatusDetails
    start: DateTime | null
    end: DateTime | null
}

export const ActiveStatus: FC<ActiveStatusProps> = ({
    details,
    start,
    end,
}) => {
    const status = getGenericActiveStatus(start, end)

    return <Bar spacing={0.5}>
        {cloneElement(details[status].icon, {
            color: activeStatusColors[status]
        })}
        <Typography>
            {details[status].label}
        </Typography>
    </Bar>
}