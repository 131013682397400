import { WrappedMutateOptions } from "@marketpartner/mp-common-react"
import { MutateOptions, MutationOptions } from "@tanstack/react-query"
import { enqueueSnackbar } from 'notistack'

export type NotifiedWrappedMutateOptions<TData, TVariables, TContext> = WrappedMutateOptions<TData, TVariables, TContext> & {
    successMessage?: string | ((data: TData, variables: TVariables, context: TContext | undefined) => string)
    errorMessage?: string | ((error: unknown, variables: TVariables, context: TContext | undefined) => string)
}
export type NotifiedMutateOptions<TData, TVariables, TContext> = MutationOptions<TData, TVariables, TContext> & {
    successMessage?: string | ((data: TData, variables: TVariables, context: TContext | undefined) => string)
    errorMessage?: string | ((error: unknown, variables: TVariables, context: TContext | undefined) => string)
}


export function withNotification<TData, TVariables, TContext>(
    options: NotifiedWrappedMutateOptions<TData, TVariables, TContext>
): WrappedMutateOptions<TData, TVariables, TContext>
export function withNotification<TData, TVariables, TContext>(
    options: NotifiedMutateOptions<TData, TVariables, TContext>
): MutationOptions<TData, TVariables, TContext>
export function withNotification<TData, TVariables, TContext>(
    options: NotifiedWrappedMutateOptions<TData, TVariables, TContext> | NotifiedMutateOptions<TData, TVariables, TContext>
): WrappedMutateOptions<TData, TVariables, TContext> | MutateOptions<TData, TVariables, TContext> {
    const {
        onSuccess,
        onError,
        successMessage,
        errorMessage,
        ...mutateOptions
    } = options

    return {

        onSuccess: (data: any, variables: any, context: any) => {
            if (successMessage) {
                const message = typeof successMessage === "string" ?
                    successMessage :
                    successMessage(data, variables, context)
                enqueueSnackbar(message, {
                    variant: "success"
                })
            }
            return onSuccess?.(data, variables, context)
        },

        onError: (error: any, variables: any, context: any) => {
            if (errorMessage) {
                const message = typeof errorMessage === "string" ?
                    errorMessage :
                    errorMessage(error, variables, context)
                enqueueSnackbar(message, {
                    variant: "error"
                })
            }

            return onError?.(error, variables, context)
        },

        ...mutateOptions
    }
}