import { FormElementConfig } from "@marketpartner/backend-api"
import { LoadingButton } from "@mui/lab"
import { useMutation } from "@tanstack/react-query"
import { FC, useState } from "react"
import { DialogStepper } from "src/common/dialogs/DialogStepper"
import { useDialogContext } from "src/common/dialogs/LockableDialog"
import { CopyElementsSourceSelector } from "src/forms/elements/copying/CopyElementsSourceSelector"
import { SelectableElementsList } from "src/forms/elements/copying/SelectableElementsList"
import { FormElementSource, FormElementSourceType } from "src/forms/elements/copying/useFormElementSources"
import { useEditableFormElementsContext } from "src/forms/elements/EditableFormElementsContext"


export type CopyElementsDialogContentProps = {
    existingElements: FormElementConfig[]
    defaultSourceType: FormElementSourceType
}

export const CopyElementsDialogContent: FC<CopyElementsDialogContentProps> = ({
    existingElements,
    defaultSourceType,
}) => {
    const dialog = useDialogContext()
    const [source, setSource] = useState<FormElementSource>()
    const [elementIdxs, setElementIdxs] = useState<number[]>([])
    const { onCreateElements, defaultMetadata } = useEditableFormElementsContext()

    const copyElementsMutation = useMutation({
        mutationFn: async (elements: FormElementConfig[]) => {
            dialog.disableClose()
            await onCreateElements(elements)
        },
        onSuccess: () => {
            dialog.enableClose()
            dialog.close(true)
        },
        onError: dialog.enableClose,
    })

    const doCopyElements = () => {
        const elements = source!.elements
            .filter((_, idx) => elementIdxs.includes(idx))
            .map(element => ({
                ...element,
                metadata: {
                    ...defaultMetadata,
                    ...element.metadata,
                }
            }))
        copyElementsMutation.mutate(elements)
    }

    const stepIdx = !source ? 0 : 1
    return <DialogStepper
        title="Copy form elements"
        stepIdx={stepIdx}
        steps={[
            {
                label: "Select source",
                content: <CopyElementsSourceSelector onSelectSource={setSource} defaultSourceType={defaultSourceType} />,
            },
            {
                label: "Select elements",
                content: source && <SelectableElementsList
                    existingElements={existingElements}
                    elements={source.elements}
                    selectedIdxs={elementIdxs}
                    onChangeSelectedIdxs={setElementIdxs}
                />,
                back: () => setSource(undefined),
                nextAction: <LoadingButton
                    variant="contained"
                    disabled={elementIdxs.length === 0}
                    onClick={doCopyElements}
                    loading={copyElementsMutation.isPending}
                >
                    Copy ({elementIdxs.length} selected)
                </LoadingButton>
            }
        ]}
    />
}