import { Box, LinearProgress, SxProps, Typography } from "@mui/material"
import { FC } from "react"
import { Bar } from "src/common/layout/Bar"


export type ProgressWithLabelProps = {
    percentage: number
    sx?: SxProps
}

export const ProgressWithLabel: FC<ProgressWithLabelProps> = ({ percentage, sx }) => {
    if (!percentage) {
        percentage = 0
    }
    return <Bar sx={sx}>
        <Box width="100%" mr={1}>
            <LinearProgress variant="determinate" value={percentage ?? 0} />
        </Box>
        <Box minWidth={35}>
            <Typography variant="body2" color="text.secondary">{`${Math.round(percentage ?? 0)}%`}</Typography>
        </Box>
    </Bar>
}