import { Scope } from "@marketpartner/backend-api";
import { DialogTitle, Grid } from "@mui/material";
import { FC, useState } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { CurrencySelector } from "src/common/currency/CurrencySelector";
import { DialogForm } from "src/common/dialogs/DialogForm";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { useEvent } from "src/events/event-context";
import { StripeInstanceSelector } from "src/payment/stripe/StripeInstanceSelector";



export type EditEventPaymentDetailsDialogProps = LockableDialogProps

const EditEventPaymentDetailsDialog: FC<EditEventPaymentDetailsDialogProps> = ({
    ...props
}) => {
    return <LockableDialog
        maxWidth="xs"
        fullWidth
        {...props}
    >
        <DialogTitle>Event payment details</DialogTitle>
        <EventPaymentDetailsForm />
    </LockableDialog>
}

const EventPaymentDetailsForm: FC = () => {
    const client = useClient()!
    const event = useEvent()!

    const [currencyCode, setCurrencyCode] = useState(event.currencyCode)
    const [stripeInstanceId, setStripeInstanceId] = useState(event.stripeInstanceId)

    const updateEventMutation = backend.events.useUpdateEvent(withNotification({
        errorMessage: "Error updating payment details"
    }))

    return <DialogForm
        actionName="Save"
        formData={{}}
        onSubmit={() => updateEventMutation.mutateAsync([client.id, event.id, {
            currencyCode,
            stripeInstanceId,
        }])}
    >
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <CurrencySelector
                    onChange={setCurrencyCode}
                    value={currencyCode}
                    label="Currency"
                />
            </Grid>
            <Grid item xs={12}>
                <StripeInstanceSelector
                    selectedId={stripeInstanceId}
                    onSelectId={setStripeInstanceId}
                    fullWidth
                />
            </Grid>
        </Grid>
    </DialogForm>
}

export const useEditEventPaymentDetailsDialog = createPopupHook({
    scope: Scope.Event,
    element: EditEventPaymentDetailsDialog,
})