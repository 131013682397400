import { Breakout, Scope } from "@marketpartner/backend-api";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { ConfirmDeleteDialog } from "src/common/dialogs/ConfirmDeleteDialog";
import { LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { useEvent } from "src/events/event-context";


type DeleteBreakoutDialogProps = LockableDialogProps & {
    breakout: Breakout
}

const DeleteBreakoutDialog: FC<DeleteBreakoutDialogProps> = ({
    breakout,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const deletion = backend.agendaItems.useDelete()

    return <ConfirmDeleteDialog
        itemName={breakout.name || "Breakout"}
        formProps={{
            action: () => deletion.mutateAsync([client.id, event.id, breakout.agendaId, breakout.id]),
            text: <></>,
        }}
        {...props}
    />
}

export const useDeleteBreakoutDialog = createPopupHook({
    scope: Scope.Event,
    element: DeleteBreakoutDialog,
})
