import { ClientAppTemplate } from "@marketpartner/backend-api"
import { FC } from "react"
import { DeleteButton, DeleteButtonProps } from "src/common/dialogs/DeleteButton"
import { backend } from "src/common/api"


export type DeleteClientAppTemplateButtonProps = Partial<DeleteButtonProps> & {
    template: ClientAppTemplate
}

export const DeleteClientAppTemplateButton: FC<DeleteClientAppTemplateButtonProps> = ({
    template,
    ...props
}) => {
    const deleteMutation = backend.clientAppTemplates.useDelete()

    return <DeleteButton
        deleteAction={() => deleteMutation.mutateAsync([
            template.clientId,
            template.id,
        ])}
        itemName={template.name}
        text={<>Confirm deletion of <b>{template.name}</b> for client <b>{template.clientId}</b>?</>}
        {...props}
    />
}