import { EventFeed } from "@marketpartner/backend-api"
import { useMpFeedItems } from "@marketpartner/react-feed"
import { Box } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { asyncBackend } from "src/common/api"
import { BarAndFlex } from "src/common/layout/BarAndFlex"
import { fullSize } from "src/common/styles"
import { useEvent } from "src/events/event-context"
import { FeedItemList } from "src/feeds/items/FeedItemList"
import { FeedDisplayContextProvider } from "src/feeds/items/options/feed-display-context"
import { FeedDisplayOptionsBar } from "src/feeds/items/options/FeedDisplayOptionsBar"
import { FeedItemSelectionContextProvider } from "src/feeds/items/selection/feed-item-selection-context"
import { FeedItemBottomBar } from "src/feeds/items/selection/FeedItemBottomBar"

const refreshFrequencySeconds = 5

export type EventFeedAdminProps = {
    feed: EventFeed
}

export const EventFeedAdmin: FC<EventFeedAdminProps> = ({
    feed,
}) => {
    const client = useClient()!
    const event = useEvent()!

    const items = useMpFeedItems(
        client.id,
        event.id,
        feed,
        null,
        undefined,
        asyncBackend,
        refreshFrequencySeconds,
    )

    return <FeedItemSelectionContextProvider>
        <FeedDisplayContextProvider>
            <BarAndFlex direction="column" barContent={
                <FeedDisplayOptionsBar />
            }>
                <BarAndFlex direction="column-reverse" barContent={
                    <FeedItemBottomBar items={items} />
                }>
                    <Box sx={{
                        ...fullSize,
                        overflow: "auto",
                        backgroundColor: theme => theme.palette.grey["100"]
                    }}>
                        <FeedItemList items={items} />
                    </Box>
                </BarAndFlex>
            </BarAndFlex>
        </FeedDisplayContextProvider>
    </FeedItemSelectionContextProvider>
}