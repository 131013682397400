import { AppTemplateDeployment, AppTemplateDeploymentPermission, Permission } from "@marketpartner/backend-api";
import { Link, Stack, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { UseQueryResult } from "@tanstack/react-query";
import { FC, useMemo } from "react";
import { Link as RouterLink } from 'react-router-dom';
import { DeploymentNomenclature } from "src/app-templates/DeploymentNomenclature";
import { DeleteAppDeploymentButton } from "src/app-templates/deployments/DeleteAppDeploymentButton";
import { DeploymentLocation } from "src/app-templates/deployments/DeploymentLocation";
import { EditAppDeploymentButton } from "src/app-templates/deployments/EditAppDeploymentButton";
import { useClient } from "src/clients/client-context";
import { QueryDataGrid, QueryDataGridProps } from "src/common/grid/QueryDataGrid";
import { Bar } from "src/common/layout/Bar";
import { link } from "src/common/routing/routes";
import { useEvent } from "src/events/event-context";
import { useLoginContext } from "src/login/LoginContext";

export type AppDeploymentsGridProps = Partial<QueryDataGridProps<AppTemplateDeployment>> & {
    queryOrRows: UseQueryResult<AppTemplateDeployment[]>
    nomenclature: DeploymentNomenclature
    includeScope?: boolean
}

const getColumns = (
    nomenclature: DeploymentNomenclature
): GridColDef<AppTemplateDeployment>[] => [{
    field: "client",
    headerName: "Client",
    valueGetter: ({ row }) => row.scope.clientId,
    width: 150,
}, {
    field: "event",
    headerName: "Event",
    valueGetter: ({ row }) => row.scope.eventId,
    renderCell: ({ row }) => row.scope.eventId && <Link
        component={RouterLink}
        to={link.toEventApps(row.scope.clientId!, row.scope.eventId!)}
        color="inherit"
    >{row.scope.eventId}</Link>,
    width: 150,
}, {
    field: "location",
    headerName: nomenclature,
    valueGetter: ({ row }) => `https://${row.domainName}/${row.path}`,
    flex: 1,
    minWidth: 350,
    maxWidth: 600,
    renderCell: ({ row }) => <DeploymentLocation deployment={row} />
}, {
    field: "template",
    valueGetter: ({ row }) => `${row.appTemplateId}@${row.appTemplateVersion}`,
    headerName: "Template",
    renderCell: ({ row }) => <TemplateDetails deployment={row} />,
    width: 300,
}, {
    field: "editPermission",
    headerName: "Edit permission",
    renderCell: ({ value }) => value === AppTemplateDeploymentPermission.AdminOnly ? "Admin only" : "Client",
    width: 130,
}, {
    field: "actions",
    headerName: "Actions",
    renderCell: ({ row }) => <Actions deployment={row} />
}]

const getInitialState = (includeScope: boolean, isAdmin: boolean): GridInitialStatePro => ({
    columns: {
        columnVisibilityModel: {
            "client": includeScope,
            "event": includeScope,
            "template": isAdmin,
            "editPermission": isAdmin,
        }
    },
    sorting: {
        sortModel: [{
            field: "location",
            sort: "asc"
        }]
    }
})

export const AppDeploymentsGrid: FC<AppDeploymentsGridProps> = ({
    includeScope = false,
    nomenclature,
    ...props
}) => {
    const login = useLoginContext()
    const client = useClient()
    const event = useEvent()

    const isAdmin = login.hasPermission(Permission.AppAdmin, client?.id, event?.id)

    const initialState = useMemo(
        () => getInitialState(includeScope, isAdmin),
        [includeScope, isAdmin]
    )
    const columns = useMemo(
        () => getColumns(nomenclature),
        [nomenclature]
    )

    return <QueryDataGrid
        columns={columns}
        initialState={initialState}
        disableRowSelectionOnClick
        disableColumnMenu
        {...props}
    />
}

type TemplateDetailsProps = {
    deployment: AppTemplateDeployment
}

const TemplateDetails: FC<TemplateDetailsProps> = ({
    deployment,
}) => <Stack>
        <Typography>
            {deployment.appTemplateId} @ {deployment.appTemplateVersion}
        </Typography>
        {deployment.clientAppTemplateId && <Typography variant="body2" color="text.secondary">
            Created from <Link
                component={RouterLink}
                to={link.toClientAppTemplates(deployment.scope.clientId!)}
                color="inherit"
            >{deployment.clientAppTemplateId}</Link>
        </Typography>}
    </Stack>


type ActionsProps = {
    deployment: AppTemplateDeployment
}

const Actions: FC<ActionsProps> = ({
    deployment
}) => {
    return <Bar>
        <EditAppDeploymentButton deployment={deployment} size="medium" />
        <DeleteAppDeploymentButton deployment={deployment} size="medium" />
    </Bar>
}