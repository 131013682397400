import { ContentType, Scope } from "@marketpartner/backend-api";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { ConfirmDeleteDialog } from "src/common/dialogs/ConfirmDeleteDialog";
import { LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { useEvent } from "src/events/event-context";


export type DeleteContentTypeDialogProps = LockableDialogProps & {
    type: ContentType
}

const DeleteContentTypeDialog: FC<DeleteContentTypeDialogProps> = ({
    type,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const deleteMutation = backend.contentTypes.useDeleteType(withNotification({
        errorMessage: "Error deleting content type",
    }))

    return <ConfirmDeleteDialog
        itemName={`${type.name} content type`}
        formProps={{
            action: () => deleteMutation.mutateAsync([client.id, event.id, type.id]),
            text: `This will permanently delete all ${type.pluralName}.`,
            confirmText: `Delete all ${type.pluralName}`,
        }}
        {...props}
    />
}

export const useDeleteContentTypeDialog = createPopupHook({
    element: DeleteContentTypeDialog,
    scope: Scope.Event,
})