import { FlatEpContact } from "@marketpartner/backend-api"
import { Avatar, Stack, Typography } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { link } from "src/common/routing/routes"
import { EventPartnerCategorizedGrid } from "src/content/event-partner/EventPartnerCategorizedGrid"
import { EventPartnerContent } from "src/content/event-partner/EventPartnerContent"
import { useEvent } from "src/events/event-context"


export const EventPartnerSpeakers: FC = () => {
    const client = useClient()!
    const event = useEvent()!
    const speakersQuery = backend.content.useGetEventPartnerSpeakers([client.id, event.id])

    return <EventPartnerContent
        name="speakers"
        link={link.toEpSpeakers(event.eventPartnerId!, event.epEventToken!)}
        request={speakersQuery}
        render={speakers => <EventPartnerCategorizedGrid
            data={speakers}
            renderItem={speaker => <Speaker speaker={speaker} />}
        />}
    />
}

type SpeakerProps = {
    speaker: FlatEpContact
}

const Speaker: FC<SpeakerProps> = ({
    speaker
}) => {
    const name = [speaker.prefix, speaker.first_name, speaker.last_name, speaker.suffix]
        .filter(it => Boolean(it))
        .join(" ")
    return <Stack sx={{ width: 200, alignItems: "center" }}>
        <Avatar
            src={speaker.profile_photo_cropped}
            alt={name}
            sx={{ mb: 1, width: 75, height: 75, border: 1, borderColor: "grey.300" }}
        />
        <Typography sx={{ textAlign: "center" }}>{name}</Typography>
        <Typography variant="body2" sx={{ color: "text.secondary", textAlign: "center" }}>
            {speaker.job_title}
        </Typography>
    </Stack>
}
