import { Agenda } from "@marketpartner/backend-api"
import { Grid, TextField } from "@mui/material"
import { FC, useState } from "react"
import { DialogForm, ExtendableDialogFormProps } from "src/common/dialogs/DialogForm"
import { IdField } from "src/common/form-inputs/IdField"


export type AgendaFormData = {
    id: string
    name: string
}

export type AgendaFormProps = ExtendableDialogFormProps<AgendaFormData> & {
    agenda?: Agenda
}

export const AgendaForm: FC<AgendaFormProps> = ({
    agenda,
    ...props
}) => {
    const [id, setId] = useState(agenda?.id ?? "")
    const [name, setName] = useState(agenda?.name ?? "")

    return <DialogForm
        formData={{
            id,
            name,
        }}
        {...props}
    >
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
                <TextField
                    value={name}
                    onChange={e => setName(e.target.value)}
                    label="Name"
                    fullWidth
                />
            </Grid>
            {!agenda && <Grid item xs={12}>
                <IdField
                    idValue={id}
                    onChangeId={setId}
                    nameValue={name}
                    maxLength={100}
                />
            </Grid>}
        </Grid>
    </DialogForm>
}