import { AgendaItemId, StructuredAgendaItem, StructuredBreakoutTrack } from "@marketpartner/backend-api"
import { ArrowDownward, ArrowUpward } from "@mui/icons-material"
import { LoadingButton, LoadingButtonProps } from "@mui/lab"
import { ListItemText, MenuItem } from "@mui/material"
import { FC, ReactNode, useState } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { Bar } from "src/common/layout/Bar"
import { withNotification } from "src/common/notifications/with-notification"
import { useEvent } from "src/events/event-context"

type SwappableItem = StructuredAgendaItem | StructuredBreakoutTrack

export type MoveAgendaItemButtonsProps = {
    item: SwappableItem
    upText?: string
    upIcon?: ReactNode
    downText?: string
    downIcon?: ReactNode
    onMove: () => void
}

export const MoveAgendaItemButtons: FC<MoveAgendaItemButtonsProps> = ({
    item,
    upText = "Up",
    upIcon = <ArrowUpward />,
    downText = "Down",
    downIcon = <ArrowDownward />,
    onMove,
}) => {
    const [disabled, setDisabled] = useState(false)

    return <Bar>
        <MoveAgendaItemButton
            agendaId={item.agendaId}
            firstItemId={item.previousItemId}
            secondItemId={item.id}
            startIcon={upIcon}
            text={upText}
            disabled={disabled}
            setDisabled={setDisabled}
            onMove={onMove}
            sx={{ width: 0.5, pl: 2 }}
        />
        <MoveAgendaItemButton
            agendaId={item.agendaId}
            firstItemId={item.id}
            secondItemId={item.nextItemId}
            endIcon={downIcon}
            text={downText}
            disabled={disabled}
            setDisabled={setDisabled}
            onMove={onMove}
            sx={{ width: 0.5, pr: 2 }}
        />
    </Bar>
}

type MoveAgendaItemButtonProps = Partial<LoadingButtonProps<typeof MenuItem>> & {
    agendaId: string
    firstItemId: AgendaItemId | undefined
    secondItemId: AgendaItemId | undefined
    text: ReactNode
    setDisabled: (disabled: boolean) => void
    onMove: () => void
}

const MoveAgendaItemButton: FC<MoveAgendaItemButtonProps> = ({
    agendaId,
    firstItemId,
    secondItemId,
    text,
    disabled,
    setDisabled,
    onMove,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const swapMutation = backend.agendaItems.useSwap(withNotification({
        onSettled: () => setDisabled(false),
        onSuccess: onMove,
        errorMessage: "Error swapping items",
    }))

    return <LoadingButton
        component={MenuItem}
        onClick={e => {
            e.stopPropagation()
            setDisabled(true)
            swapMutation.mutate([client.id, event.id, agendaId, {
                firstItemId: firstItemId!,
                secondItemId: secondItemId!,
            }])
        }}
        color="inherit"
        disabled={disabled || !Boolean(firstItemId && secondItemId)}
        loading={swapMutation.isPending}
        {...props}
        sx={{
            textTransform: "none",
            textAlign: "center",
            ...props.sx
        }}
    >
        <ListItemText>{text}</ListItemText>
    </LoadingButton>
}