import { Schedule, LockOpen, Lock } from "@mui/icons-material";
import { ActiveStatusDetails } from "src/common/statuses/active-status-details";


export const ticketTypeActiveStatusDetails: ActiveStatusDetails = {
    pre: {
        label: "Scheduled",
        icon: <Schedule />,
    },
    active: {
        label: "Available",
        icon: <LockOpen />,
    },
    post: {
        label: "Closed",
        icon: <Lock />
    }
}