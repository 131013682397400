import { AgendaHeading } from "@marketpartner/backend-api"
import { Grid, TextField } from "@mui/material"
import { DateTime } from "luxon"
import { FC, useState } from "react"
import { DialogForm, ExtendableDialogFormProps } from "src/common/dialogs/DialogForm"
import { LuxonDateTimePicker } from "src/common/time/LuxonDateTimePickers"
import { useEvent } from "src/events/event-context"


export type AgendaHeadingFormData = {
    start: DateTime
    name: string
}

export type AgendaHeadingFormProps = ExtendableDialogFormProps<AgendaHeadingFormData> & {
    heading?: AgendaHeading
    suggestedStart?: DateTime
}

export const AgendaHeadingForm: FC<AgendaHeadingFormProps> = ({
    heading,
    suggestedStart,
    ...props
}) => {
    const event = useEvent()!
    const defaultStart = heading?.start ?? suggestedStart ?? event.startTime

    const [name, setName] = useState(heading?.name ?? "")
    const [start, setStart] = useState(defaultStart)

    return <DialogForm
        formData={{
            name,
            start
        }}
        {...props}
        isValid={name.length > 0}
    >
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
                <TextField
                    value={name}
                    onChange={e => setName(e.target.value)}
                    label="Text"
                    fullWidth
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <LuxonDateTimePicker
                    value={start}
                    onChange={start => start && setStart(start)}
                    timezone={event.timezone}
                    label="Time"
                    required
                />
            </Grid>
        </Grid>
    </DialogForm>
}