import { Registration, RegistrationApprovalState } from '@marketpartner/backend-api'
import { Box, Fade, SxProps, Typography } from "@mui/material"
import { FC, ReactNode } from 'react'
import { Bar, BarProps } from 'src/common/layout/Bar'
import { registrationStatusColors, RegistrationStatusIcon } from 'src/registrations/RegistrationStatusIcon'

export type RegistrationTotalsProps = {
    registrations: Registration[]
    selectedStatus: RegistrationApprovalState | undefined
    onSelectStatus: (status: RegistrationApprovalState | undefined) => void
    sx?: SxProps
}

export const RegistrationTotals: FC<RegistrationTotalsProps> = ({
    registrations,
    selectedStatus,
    onSelectStatus,
    sx
}) => {
    const statusTotal = (status: RegistrationApprovalState) => <Total
        label={<RegistrationStatusIcon status={status} fontSize="small" />}
        value={registrations.filter(it => it.approvalState === status).length}
        color={registrationStatusColors[status]}
        deselected={selectedStatus !== undefined && selectedStatus !== status}
        onSelect={() => onSelectStatus(status)}
    />

    return <Box sx={sx}>
        <Fade in={registrations.length !== 0}>
            <Bar spacing={1}>
                <Total
                    label={<b>Total:</b>}
                    value={registrations.length}
                    deselected={Boolean(selectedStatus)}
                    onSelect={() => onSelectStatus(undefined)}
                />
                {statusTotal(RegistrationApprovalState.PendingApproval)}
                {statusTotal(RegistrationApprovalState.Approved)}
                {statusTotal(RegistrationApprovalState.Rejected)}
                {statusTotal(RegistrationApprovalState.Cancelled)}
            </Bar>
        </Fade>
    </Box>
}

type TotalProps = {
    label: ReactNode
    value: ReactNode
    color?: BarProps['color']
    deselected: boolean
    onSelect: () => void
}

const Total: FC<TotalProps> = ({
    label,
    value,
    color,
    deselected,
    onSelect,
}) => {
    return <Bar
        spacing={0.5}
        onClick={onSelect}
        color={color}
        sx={{
            opacity: deselected ? 0.5 : 1,
            transition: "opacity 0.25s",
            "&:hover": {
                cursor: "pointer",
            },
        }}
    >
        {label}
        <Typography>{value}</Typography>
    </Bar>
}