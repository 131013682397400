import { ConfiguredEmailTemplate, RegistrationEmailType, Scope } from "@marketpartner/backend-api";
import { ListItemText, ListSubheader, MenuItem } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { createPopupHook } from "src/common/dialogs/Popup";
import { LoadingMenuItem } from "src/common/menus/LoadingMenuItem";
import { LockableMenu, LockableMenuProps } from "src/common/menus/LockableMenu";
import { withNotification } from "src/common/notifications/with-notification";
import { useEditConfiguredTemplateDialog } from "src/email/templates/EditConfiguredEmailTemplateDialog";
import { emailTriggerLabels } from "src/email/triggers/EmailTriggersGrid";
import { useEvent } from "src/events/event-context";


export type EmailTriggerContextMenuProps = LockableMenuProps & {
    triggerKey: RegistrationEmailType
}

const EmailTriggerContextMenu: FC<EmailTriggerContextMenuProps> = ({
    triggerKey,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!

    const categoriesQuery = backend.registrationCategories.useGetAll([client.id, event.id], {
        select: categories => categories
            .filter(category => category.triggeredEmailTemplateOverrides[triggerKey] === null)
    })

    const updateCategoryMutation = backend.registrationCategories.useUpdate(withNotification({
        errorMessage: "Error updating email template"
    }))

    const editTemplateDialog = useEditConfiguredTemplateDialog()

    const handleOverrideTemplate = (
        categoryId: string,
        template: ConfiguredEmailTemplate | null
    ) => updateCategoryMutation.mutateAsync([client.id, event.id, categoryId, {
        triggeredEmailTemplateOverrides: {
            [triggerKey]: template === null ? {
                type: "DoNotSend"
            } : template
        }
    }])

    return <LockableMenu closeOnClick {...props}>
        <ListSubheader children="Override template" />
        {categoriesQuery.data?.map(category => <LoadingMenuItem
            key={category.id}
            onClick={() => editTemplateDialog.open({
                configuredTemplate: null,
                editLabel: emailTriggerLabels[triggerKey],
                onChange: template => handleOverrideTemplate(category.id, template)
            })}
        >
            <ListItemText primary={category.name} />
        </LoadingMenuItem>)
        }
        {categoriesQuery.data?.length === 0 && <MenuItem disabled>
            <ListItemText primary="All categories overriden" />
        </MenuItem>}
    </LockableMenu >
}

export const useEmailTriggerContextMenu = createPopupHook({
    element: EmailTriggerContextMenu,
    scope: Scope.Event,
})