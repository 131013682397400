import { ContentCategory, isCustomContentCategory, Permission, StandardContentCategoryId } from "@marketpartner/backend-api";
import { Edit } from "@mui/icons-material";
import { Button, Paper } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { Page } from "src/common/layout/Page";
import { RoutedTabs } from "src/common/routing/RoutedTabs";
import { useLoadedRoutedTabs } from "src/common/routing/useLoadedRoutedTabs";
import { useRestrictedRoutedTabs } from "src/common/routing/useRestrictedRoutedTabs";
import { fullSize } from "src/common/styles";
import { Agendas } from "src/content/agendas/Agendas";
import { useContentCategoriesDialog } from "src/content/categories/ContentCategoriesDialog";
import { EditableContentCategory } from "src/content/categories/EditableContentCategory";
import { LegacyContentPage } from "src/content/LegacyContentPage";
import { Locations } from "src/content/locations/Locations";
import { EditableSpeakers } from "src/content/speakers/EditableSpeakers";
import { ContentTypeIcon } from "src/content/types/ContentTypeIcon";
import { useContentTypesDialog } from "src/content/types/ContentTypesDialog";
import { useEvent } from "src/events/event-context";
import { ifHas } from "src/login/IfHas";

export type ContentPageProps = {
    isContentEditorUi?: boolean
}

export const ContentPage: FC<ContentPageProps> = ({
    isContentEditorUi = false
}) => {
    const client = useClient()!
    const event = useEvent()!
    const categoriesQuery = backend.contentCategories.useGetCategories([client.id, event.id])
    const categoriesDialog = useContentCategoriesDialog()
    const typesDialog = useContentTypesDialog()

    const customCategoryTabs = useLoadedRoutedTabs(categoriesQuery, categories => categories.map(category => ({
        path: category.id,
        label: category.pluralName,
        element: getCategoryTabContent(category),
    })))

    const addTab = useRestrictedRoutedTabs(Permission.EditEventContent, client.id, event.id, [{
        path: "_edit_",
        label: <Edit />,
        element: <></>,
        onClick: e => {
            categoriesDialog.open({})
            return e.preventDefault()
        },
    }])

    if (event.contentProvider === "EventPartner") {
        return <LegacyContentPage isContentEditorUi={isContentEditorUi} />
    }

    return <Page
        title="Content"
        back={{
            hideIf: isContentEditorUi
        }}
        actions={ifHas(Permission.EditEventDetails, client.id, event.id, <Button
            children="Content types"
            variant="contained"
            startIcon={<ContentTypeIcon />}
            onClick={() => typesDialog.open({})}
        />)}
    >
        <Paper sx={{ ...fullSize }}>
            <RoutedTabs tabs={[
                ...customCategoryTabs,
                {
                    path: "locations",
                    label: "Locations",
                    element: <Locations />
                },
                ...addTab,
            ]} />
        </Paper>
    </Page>
}

const getCategoryTabContent = (category: ContentCategory) => {
    if (isCustomContentCategory(category)) {
        return <EditableContentCategory
            key={category.id}
            category={category}
        />
    }
    switch (category.id) {
        case StandardContentCategoryId.Agenda:
            return <Agendas />
        case StandardContentCategoryId.Speaker:
            return <EditableSpeakers />
        default:
            return <>(Not implemented yet)</>
    }
}