import { fullName } from "@marketpartner/mp-common"
import { SearchBox } from "@marketpartner/mp-common-mui"
import { useSearchCallback } from "@marketpartner/mp-common-react"
import { SxProps } from "@mui/material"
import { FC } from "react"
import { Bar } from "src/common/layout/Bar"
import { BarAndFlex } from "src/common/layout/BarAndFlex"
import { MeetingRequestsGrid } from "src/networking/meetings/requests/MeetingRequestsGrid"
import { JoinedMeetingRequest, useJoinedMeetingRequestsQuery } from "src/networking/meetings/requests/useJoinedMeetingRequestsQuery"


export type MeetingRequestsProps = {
    sx?: SxProps
}

const requestSearchFields = (request: JoinedMeetingRequest) => [
    fullName(request.requester),
    fullName(request.respondant),
    request.requester.email,
    request.respondant.email,
    request.location?.name ?? "",
]

export const MeetingRequests: FC<MeetingRequestsProps> = ({ sx }) => {
    const meetingsQuery = useJoinedMeetingRequestsQuery()

    const [searchText, setSearchText, searchRows] = useSearchCallback(requestSearchFields)

    return <BarAndFlex barContent={<Bar p={2}>
        <SearchBox searchText={searchText} setSearchText={setSearchText} />
    </Bar>}>
        <MeetingRequestsGrid
            queryOrRows={meetingsQuery}
            filterRows={searchRows}
            sx={sx}
        />
    </BarAndFlex>
}