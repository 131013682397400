import { Undo } from "@mui/icons-material";
import { Button, TextField, TextFieldProps } from "@mui/material";
import { FC, useEffect, useState } from "react";


export type DerivedTextFieldProps = Omit<TextFieldProps, "onChange"> & {
    sourceValue: string
    deriveValue: (sourceValue: string) => string
    value: string
    onChange: (value: string) => void
}

/**
 * A text field that derives a default value from another field.
 * 
 * The value can be changed by the user, but will be reset to the 
 * derived value if the default button is clicked.
 */
export const DerivedTextField: FC<DerivedTextFieldProps> = ({
    sourceValue,
    deriveValue,
    value,
    onChange,
    ...props
}) => {
    const derivedValue = deriveValue(sourceValue)
    const [customValue, setCustomValue] = useState<string | undefined>(
        derivedValue === value || value === '' ? undefined : value
    )

    const handleChange = (newValue: string) => {
        setCustomValue(newValue === derivedValue ? undefined : newValue)
        onChange(newValue)
    }

    useEffect(() => {
        const derivedValue = deriveValue(sourceValue)
        if (!customValue) {
            onChange(derivedValue)
        }
    }, [sourceValue, customValue, onChange, deriveValue, value])

    return <TextField
        {...props}
        value={customValue ?? derivedValue}
        onChange={e => handleChange(e.target.value)}
        InputProps={{
            ...props.InputProps,
            endAdornment: <Button
                onClick={() => setCustomValue(undefined)}
                disabled={customValue === undefined}
                color="inherit"
                startIcon={<Undo />}
            >Default</Button>
        }}
    />
}
