import { ClientDomainName } from "@marketpartner/backend-api"
import _ from "lodash"
import { useMemo } from "react"
import { backend } from "src/common/api"


export type ClientDomainSummary = ClientDomainName & {
    deploymentCount: number | undefined
}

export type UseClientDomainSummaries = {
    isLoading: boolean
    clientDomains: ClientDomainSummary[]
}

export const useClientDomainSummaries = (domainName: string): UseClientDomainSummaries => {
    const clientDomainsQuery = backend.domainNames.useGetAllClientDomains([], {
        select: domains => domains.filter(it => it.domainName === domainName)
    })
    const clientDeploymentCountsQuery = backend.appTemplateDeployments.useGetGlobalDeployments([{ includeSubscopes: true }], {
        select: deployments => _(deployments)
            .filter(it => it.domainName === domainName)
            .countBy(it => it.scope.clientId)
            .value()
    })

    const clientDomains = useMemo((): ClientDomainSummary[] => {
        return (clientDomainsQuery.data ?? []).map(clientDomain => ({
            ...clientDomain,
            deploymentCount: clientDeploymentCountsQuery.data ?
                clientDeploymentCountsQuery.data[clientDomain.clientId] ?? 0 :
                undefined
        }))
    }, [clientDomainsQuery.data, clientDeploymentCountsQuery])

    return useMemo(() => ({
        isLoading: clientDomainsQuery.isLoading,
        clientDomains,
    }), [clientDomainsQuery.isLoading, clientDomains])
}