import { Paper } from "@mui/material";
import { FC } from "react";
import { Navigate, useParams } from "react-router";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { Loading } from "src/common/loading/Loading";
import { Page } from "src/common/layout/Page";
import { RoutedTabs } from "src/common/routing/RoutedTabs";
import { link } from "src/common/routing/routes";
import { fullSize } from "src/common/styles";
import { useEvent } from "src/events/event-context";
import { ProfileSettingsTab } from "src/registrations/configuration/categories/profile-settings/ProfileSettingsTab";
import { EditableProfileForm } from "src/registrations/configuration/categories/profile-form/EditableProfileForm";
import { EditableRegistrationCategoryForm } from "src/registrations/configuration/categories/reg-form/EditableRegistrationCategoryForm";


export const RegistrationCategoryFormsPage: FC = () => {
    const client = useClient()!
    const event = useEvent()!
    const categoryId = useParams().categoryId

    const categoryQuery = backend.registrationCategories.useGetAll([client.id, event.id], {
        select: categories => categories.find(it => it.id === categoryId) ?? null
    })

    return <Loading request={categoryQuery} render={category => {
        if (category === null) {
            return <Navigate replace to={link.toRegistrationCategories(client.id, event.id)} />
        }

        return <Page
            title={category.name}
        >
            <Paper sx={{ ...fullSize }}>
                <RoutedTabs tabs={[{
                    path: "registration-form",
                    label: "Registration form",
                    element: <EditableRegistrationCategoryForm category={category} />
                }, {
                    path: "profile-form",
                    label: "Profile form",
                    element: <EditableProfileForm category={category} />
                }, {
                    path: "profile-settings",
                    label: "Profile settings",
                    element: <ProfileSettingsTab category={category} />
                }]} />
            </Paper>
        </Page>;
    }} />
}