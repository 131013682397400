import { Scope } from "@marketpartner/backend-api";
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { EditableDialogList } from "src/common/dialogs/EditableDialogList";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { useCreateAgendaDialog } from "src/content/agendas/CreateAgendaDialog";
import { useDeleteAgendaDialog } from "src/content/agendas/DeleteAgendaDialog";
import { useEditAgendaDialog } from "src/content/agendas/EditAgendaDialog";
import { useEvent } from "src/events/event-context";


export type AgendasDialogProps = LockableDialogProps

const AgendasDialog: FC<AgendasDialogProps> = (props) => {
    const client = useClient()!
    const event = useEvent()!
    const agendasQuery = backend.agendas.useGetAll([client.id, event.id])
    const createAgendaDialog = useCreateAgendaDialog()
    const editAgendaDialog = useEditAgendaDialog()
    const deleteAgendaDialog = useDeleteAgendaDialog()

    return <LockableDialog
        maxWidth="xs"
        fullWidth
        {...props}
    >
        <DialogTitle>Edit agendas</DialogTitle>
        <EditableDialogList
            itemsQuery={agendasQuery}
            getPrimaryText={agenda => agenda.name}
            getSecondaryText={agenda => agenda.id}
            addProps={{
                onClickAdd: () => createAgendaDialog.open({})
            }}
            contextMenuProps={agenda => ({
                onClickEdit: () => editAgendaDialog.open({ agenda }),
                onClickDelete: () => deleteAgendaDialog.open({ agenda }),
            })}
        />
    </LockableDialog>
}

export const useAgendasDialog = createPopupHook({
    element: AgendasDialog,
    scope: Scope.Event,
})
