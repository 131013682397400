import { PollResponse } from "@marketpartner/backend-api"
import { casualName } from "@marketpartner/mp-common"
import { Typography } from "@mui/material"
import { ColumnCell, getDynamicColumnDefinitions } from "src/common/grid/dynamic-columns"
import { ExportColumnDefinition } from "src/common/grid/grid-export"
import { EventDateTimeText } from "src/common/time/DateTimeText"
import { DeletePollResponseButton } from "src/polls/questions/responses/DeletePollResponseButton"
import { PollResponseStatusButtons } from "src/polls/questions/responses/PollResponseStatusButtons"

export function buildPollResponsesColumns(
    responses: PollResponse[],
    canDeleteResponses: boolean,
    canModerateResponses: boolean
): ExportColumnDefinition<PollResponse>[] {

    const deleteCol: ExportColumnDefinition<PollResponse> = {
        field: "actions",
        headerName: "Delete",
        sortable: false,
        renderCell: ({ row }) => <DeletePollResponseButton 
            pollId={row.pollId} 
            questionId={row.questionId} 
            id={row.id}
        />,
        excludeFromCsv: true
    }
    const statusCol: ExportColumnDefinition<PollResponse> = {
        field: "status",
        headerName: "Status",
        sortable: true,
        renderCell: ({ row }) => <PollResponseStatusButtons
            displayStatus={row.displayStatus}
            pollId={row.pollId}
            questionId={row.questionId}
            id={row.id}
        />,
        width: 150,
        excludeFromCsv: true
    }

    return [
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ row }) => `${row.lastName} ${row.firstName}`,
            getCsvValue: ({ row }) => casualName(row),
            renderCell: ({ row }) => casualName(row) === "Anonymous" ?
                <Typography color="text.secondary">Anonymous</Typography> :
                <Typography><ColumnCell value={casualName(row)} /></Typography>,
            excludeFromCsv: true
        },
        {
            field: "timeInserted",
            headerName: "Time inserted",
            flex: 1,
            minWidth: 100,
            renderCell: ({ value }) => <EventDateTimeText dateTime={value} />,
        },
        ...canModerateResponses ? [statusCol] : [],
        ...canDeleteResponses ? [deleteCol] : [],
        ...getDynamicColumnDefinitions(responses, "formData")

    ]
}