import { LoadedSelector, LoadedSelectorProps } from "@marketpartner/mp-common-mui"
import { FC } from "react"
import { backend } from "src/common/api"

export type DomainSelectProps = Partial<LoadedSelectorProps<any, any>> & {
    domainName: string | null
    onChangeDomainName: (domainName: string | null) => void
}

export const DomainSelect: FC<DomainSelectProps> = ({
    domainName,
    onChangeDomainName,
    ...props
}) => {
    const domainsQuery = backend.domainNames.useGetAllDomains([])

    return <LoadedSelector
        fetchRequest={domainsQuery}
        dataExtractor={domains => domains.map(it => ({
            id: it.domainName
        }))}
        selectedId={domainName}
        onSelectId={onChangeDomainName}
        required
        label="Domain"
        noRefresh
        {...props}
    />
}
