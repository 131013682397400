

export const fullSize = {
    height: "100%",
    width: "100%",
    position: "absolute",
} as const

export const center = {
    position: "absolute",
    top: "50%",
    left: "50%",
    msTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
} as const