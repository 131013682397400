import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { FC } from "react"
import { Bar } from "src/common/layout/Bar"
import { Spacer } from "src/common/layout/Spacer"
import { useEvent } from "src/events/event-context"
import { EditExtraFieldsButtons } from "src/events/extra-fields/EditExtraFieldsButton"

export const ExtraFields: FC = () => {
    const event = useEvent()!

    return <>
        <Bar spacing={2} sx={{ px: 2 }}>
            <Spacer />
            <EditExtraFieldsButtons />
        </Bar>
        {Object.keys(event.extraFields).length > 0 &&
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(event.extraFields).map(fieldKey => <TableRow key={fieldKey}>
                            <TableCell>{fieldKey}</TableCell>
                            <TableCell>{event.extraFields[fieldKey]}</TableCell>
                        </TableRow>)}
                    </TableBody>
                </Table>
            </TableContainer>
        }
    </>
}