import { ContentCategory, Scope } from "@marketpartner/backend-api";
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { ContentCategoryForm } from "src/content/categories/ContentCategoryForm";
import { useEvent } from "src/events/event-context";


export type EditContentCategoryDialogProps = LockableDialogProps & {
    category: ContentCategory
}

const EditContentCategoryDialog: FC<EditContentCategoryDialogProps> = ({
    category,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const updateMutation = backend.contentCategories.useUpdateCategory(withNotification({
        errorMessage: "Error updating content category",
    }))

    return <LockableDialog
        maxWidth="xs"
        {...props}
    >
        <DialogTitle>Edit content category</DialogTitle>
        <ContentCategoryForm
            category={category}
            actionName="Save"
            onSubmit={category => updateMutation.mutateAsync([client.id, event.id, category.id, category])}
        />
    </LockableDialog>
}

export const useEditContentCategoryDialog = createPopupHook({
    element: EditContentCategoryDialog,
    scope: Scope.Event,
})
