import { AdminAccessToken } from "@marketpartner/backend-api"
import { FC } from "react"
import { backend } from "src/common/api"
import { DeleteButton, DeleteButtonProps } from "src/common/dialogs/DeleteButton"

export type DeleteAdminTokenButtonProps = Partial<DeleteButtonProps> & {
    token: AdminAccessToken
}

export const DeleteAdminTokenButton: FC<DeleteAdminTokenButtonProps> = ({
    token,
    ...props
}) => {
    const deleteMutation = backend.accessTokens.useDeleteAccessToken()

    return <DeleteButton
        buttonText=""
        itemName="token"
        text="This will permanently delete this token"
        confirmText={`permanently delete ${token.name}`}
        deleteAction={() => deleteMutation.mutateAsync([token.id])}
        {...props}
    />
}