import { Box, ButtonBase, ClickAwayListener, Grow, Paper, Popper, Stack, Typography, useTheme } from '@mui/material'
import { DateTime } from 'luxon'
import { FC, useRef, useState } from 'react'
import { checkLuxonDateTime, formatDiffNow, localTimezone, toUtcDateTime, utcTimezone } from 'src/common/time/time-utils'
import { useEvent } from 'src/events/event-context'

type LocalizedDateTimeTextProps = Omit<DateTimeTextProps, "timezone">

export type EventDateTimeTextProps = LocalizedDateTimeTextProps

export const EventDateTimeText: FC<EventDateTimeTextProps> = (props) => {
    const event = useEvent()!
    return <DateTimeText {...props} timezone={event.timezone} />
}

export type LocalDateTimeTextProps = LocalizedDateTimeTextProps

export const LocalDateTimeText: FC<LocalDateTimeTextProps> = (props) => {
    return <DateTimeText {...props} timezone={localTimezone} />
}

export type UtcDateTimeTextProps = LocalizedDateTimeTextProps

export const UtcDateTimeText: FC<UtcDateTimeTextProps> = (props) => {
    return <DateTimeText {...props} timezone={utcTimezone} />
}

export type DateTimeTextProps = {
    dateTime: DateTime | null | undefined
    timezone: string
    includeSeconds?: boolean
    hideTimezone?: boolean
    hideDate?: boolean
}

export const DateTimeText: FC<DateTimeTextProps> = ({ 
    dateTime,
    timezone,
    includeSeconds = false,
    hideTimezone = false,
    hideDate = false,
}) => {
    const popupAnchor = useRef<HTMLElement>()
    const [isOpen, setIsOpen] = useState(false)

    if (!dateTime) {
        return <></>
    }
    checkLuxonDateTime(dateTime)
    dateTime = toUtcDateTime(dateTime)

    let format: Intl.DateTimeFormatOptions
    if (hideDate) {
        format = includeSeconds ? DateTime.TIME_WITH_SECONDS : DateTime.TIME_SIMPLE
    } else {
        format = includeSeconds ? DateTime.DATETIME_MED_WITH_SECONDS : DateTime.DATETIME_MED
    }
    const popupFormat = includeSeconds ? DateTime.DATETIME_MED_WITH_SECONDS : DateTime.DATETIME_MED

    return <ClickAwayListener onClickAway={() => setIsOpen(false)}>
        <Box component={ButtonBase} ref={popupAnchor} onClick={() => setIsOpen(!isOpen)} sx={{ textAlign: "inherit", '&:hover': { textDecoration: "underline gray dotted" } }}>
            <TimeAndTimezone 
                dateTime={dateTime}
                timezone={timezone}
                format={format}
                hideTimezone={hideTimezone}
            />
            <TimezonePopup 
                dateTime={dateTime}
                timezone={timezone}
                format={popupFormat}
                isOpen={isOpen}
                anchorEl={popupAnchor.current}
            />
        </Box>
    </ClickAwayListener>
}

type TimeAndTimezoneProps = {
    dateTime: DateTime
    timezone: string
    format: Intl.DateTimeFormatOptions
    hideTimezone?: boolean
    timezoneText?: string
}

const TimeAndTimezone: FC<TimeAndTimezoneProps> = ({ 
    dateTime,
    timezone,
    format,
    hideTimezone = false,
    timezoneText = timezone
}) => {
    return <Stack>
        <Typography variant="body1" color="inherit">
            {dateTime.setZone(timezone).toLocaleString(format)}
        </Typography>
        {!hideTimezone && <Typography variant='caption' color="textSecondary" >
            {timezoneText}
        </Typography>}
    </Stack>
}

type TimezonePopupProps = {
    dateTime: DateTime
    timezone: string
    format: Intl.DateTimeFormatOptions
    isOpen: boolean
    anchorEl: HTMLElement | undefined
}

const TimezonePopup: FC<TimezonePopupProps> = ({
    dateTime,
    timezone,
    format,
    isOpen,
    anchorEl,
}) => {
    const theme = useTheme()
    const includeLocal = timezone !== localTimezone
    const includeUtc = timezone !== utcTimezone

    return <Popper
        open={isOpen}
        anchorEl={anchorEl}
        placement='bottom-start'
        transition
        style={{ zIndex: theme.zIndex.tooltip }}
    >
        {({ TransitionProps }) => <Grow {...TransitionProps} timeout={200}>
            <Paper sx={{ mx: -1, my: 0.5, px: 1, py: 0.5 }} elevation={6}>
                {includeLocal && <TimeAndTimezone dateTime={dateTime} timezone={localTimezone} format={format} timezoneText={`Local (${localTimezone})`} />}
                {includeUtc && <TimeAndTimezone dateTime={dateTime} timezone={utcTimezone} format={format} timezoneText="UTC" />}
                {dateTime && <Typography textAlign="center" variant="body2" my={0.5} fontStyle="italic">
                    {formatDiffNow(dateTime, { capitalize: true })}
                </Typography>}
            </Paper>
        </Grow>}
    </Popper>
}