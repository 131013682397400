import { ActionCountCondition, ActivityType } from "@marketpartner/backend-api";
import { AdsClick } from "@mui/icons-material";
import { Grid, TextField } from "@mui/material";
import { BooleanConditionProperties } from "src/common/boolean-conditions/boolean-condition-properties";
import { ActivityLookupList } from "src/registrations/computed-columns/boolean-conditions/ActivityLookupList";
import { EditableActivityLookups } from "src/registrations/computed-columns/EditableActivityLookups";


export const actionCountConditionProperties: BooleanConditionProperties<ActionCountCondition> = {
    typeName: "Action count",
    iconComponent: AdsClick,
    getPrimaryText: condition => `Action count ≥ ${condition.targetCount}`,
    getSecondaryContent: condition => <ActivityLookupList lookups={condition.lookups} />,
    isValid: ({ targetCount, lookups }) => targetCount > 0 && lookups.length > 0 && lookups.every(lookup => lookup.id !== ""),
    createDefault: () => ({
        type: "ActionCount",
        targetCount: 0,
        lookups: [],
    }),
    renderForm: (condition, onChangeProperties) => <Grid container spacing={2}>
        <Grid item xs={12}>
            <TextField
                value={condition.targetCount}
                onChange={e => onChangeProperties({
                    targetCount: parseInt(e.target.value)
                })}
                type="number"
                inputProps={{
                    min: 1,
                    step: 1,
                }}
                label="Target count"
            />
        </Grid>
        <Grid item xs={12}>
            <EditableActivityLookups
                activityType={ActivityType.action}
                lookups={condition.lookups}
                onChangeLookups={lookups => onChangeProperties({ lookups })}
            />
        </Grid>
    </Grid>,
}