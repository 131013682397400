import { Registration, RegistrationType } from "@marketpartner/backend-api"
import { SxProps } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { SearchableSpeakersGrid } from "src/content/speakers/SearchableSpeakersGrid"

export type CopySpeakersSelectorProps = {
    sourceEventId: string
    sourceCategoryId: string
    selectedRegistrations: Registration[]
    onChangeSelectedRegistrations: (registrations: Registration[]) => void
    sx?: SxProps
}

export const CopySpeakersSelector: FC<CopySpeakersSelectorProps> = ({
    sourceEventId,
    sourceCategoryId,
    selectedRegistrations,
    onChangeSelectedRegistrations,
    sx,
}) => {
    const client = useClient()!
    const sourceRegistrationsQuery = backend.registrations.useGetAll([client.id, sourceEventId], {
        select: registrations => registrations.filter(r =>
            r.type === RegistrationType.Primary &&
            r.categoryId === sourceCategoryId
        ) as Registration[]
    })

    return <SearchableSpeakersGrid
        queryOrRows={sourceRegistrationsQuery}
        selectedRegistrations={selectedRegistrations}
        onChangeSelectedRegistrations={onChangeSelectedRegistrations}
    />
}
