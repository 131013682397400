import { Scope, SfmcConfiguration } from "@marketpartner/backend-api";
import { Edit } from "@mui/icons-material";
import { ToggleButton } from "@mui/lab";
import { Alert, DialogTitle, Grid, TextField } from "@mui/material";
import { FC, useState } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { DialogForm } from "src/common/dialogs/DialogForm";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { Bar } from "src/common/layout/Bar";
import { Loading } from "src/common/loading/Loading";
import { withNotification } from "src/common/notifications/with-notification";


export type SfmcConfigurationDialogProps = LockableDialogProps

const SfmcConfigurationDialog: FC<SfmcConfigurationDialogProps> = ({
    ...props
}) => {
    const client = useClient()!
    const configurationQuery = backend.salesforce.useFindSfmcConfiguration([client.id])

    return <LockableDialog maxWidth="sm" fullWidth {...props}>
        <DialogTitle>Configure Salesforce Marketing Cloud</DialogTitle>
        <Loading sizing="fit-content" request={configurationQuery} render={configuration =>
            <SfmcDialogContent configuration={configuration} />
        } />
    </LockableDialog>
}

type SfmcDialogContentProps = {
    configuration: SfmcConfiguration | null
}

const SfmcDialogContent: FC<SfmcDialogContentProps> = ({
    configuration
}) => {
    const client = useClient()!

    const isConfigured = configuration !== null
    const [subdomain, setSubdomain] = useState(configuration?.subdomain ?? "")
    const [accountId, setAccountId] = useState(configuration?.accountId ?? "")
    const [clientId, setClientId] = useState(configuration?.clientId ?? "")
    const [dataExtensionKey, setDataExtensionKey] = useState(configuration?.dataExtensionKey ?? "")
    const [clientSecret, setClientSecret] = useState<string | undefined>(isConfigured ? undefined : "")
    const editingClientSecret = clientSecret !== undefined

    const createConfigurationMutation = backend.salesforce.useCreateSfmcConfiguration(withNotification({
        errorMessage: "Error configuring Salesforce Marketing Cloud",
    }))
    const updateConfigurationMutation = backend.salesforce.useUpdateSfmcConfiguration(withNotification({
        errorMessage: "Error updating Salesforce Marketing Cloud configuration",
    }))

    return <>
        {!isConfigured && <Alert severity="info">
            Salesforce Marketing Cloud not configured
        </Alert>}
        <DialogForm
            actionName={isConfigured ? "Save changes" : "Create configuration"}
            formData={{
                subdomain,
                accountId,
                clientId,
                dataExtensionKey,
                clientSecret: clientSecret!,
            }}
            isValid={Boolean(subdomain && accountId && clientId && dataExtensionKey && (clientSecret || !editingClientSecret))}
            onSubmit={configuration => isConfigured ?
                updateConfigurationMutation.mutateAsync([client.id, configuration]) :
                createConfigurationMutation.mutateAsync([client.id, configuration])
            }
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        label="Subdomain"
                        value={subdomain}
                        onChange={e => setSubdomain(e.target.value)}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Account Id"
                        value={accountId}
                        onChange={e => setAccountId(e.target.value)}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Client Id"
                        value={clientId}
                        onChange={e => setClientId(e.target.value)}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Data Extension Key"
                        value={dataExtensionKey}
                        onChange={e => setDataExtensionKey(e.target.value)}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <Bar spacing={2}>
                        <TextField
                            label="Client Secret"
                            value={clientSecret ?? "(Encrypted value)"}
                            onChange={e => setClientSecret(e.target.value)}
                            disabled={!editingClientSecret}
                            required={editingClientSecret}
                            fullWidth
                        />
                        {isConfigured && <ToggleButton
                            children={<Edit />}
                            value
                            selected={editingClientSecret}
                            onChange={() => setClientSecret(editingClientSecret ? undefined : "")}
                        />}
                    </Bar>
                </Grid>
            </Grid>
        </DialogForm>
    </>
}

export const useSfmcConfigurationDialog = createPopupHook({
    element: SfmcConfigurationDialog,
    scope: Scope.Client,
})