import { RegistrationCategory, Scope } from "@marketpartner/backend-api";
import { DialogTitle, Grid, TextField } from "@mui/material";
import { FC, useState } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { DialogForm } from "src/common/dialogs/DialogForm";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { IdField } from "src/common/form-inputs/IdField";
import { PluralTextField } from "src/common/form-inputs/PluralTextField";
import { withNotification } from "src/common/notifications/with-notification";
import { useEvent } from "src/events/event-context";


type CloneCategoryDialogProps = LockableDialogProps & CloneCategoryFormProps

const CloneCategoryDialog: FC<CloneCategoryDialogProps> = ({
    category,
    ...props
}) => {

    return <LockableDialog maxWidth="sm" fullWidth {...props}>
        <DialogTitle>Clone {category.name} category</DialogTitle>
        <CloneCategoryForm category={category} />
    </LockableDialog>
}

type CloneCategoryFormProps = {
    category: RegistrationCategory
}

const CloneCategoryForm: FC<CloneCategoryFormProps> = ({
    category,
}) => {
    const client = useClient()!
    const event = useEvent()!
    const [id, setId] = useState("")
    const [name, setName] = useState("")
    const [pluralName, setPluralName] = useState("")

    const createMutation = backend.registrationCategories.useCreate(withNotification({
        successMessage: "Cloned category",
        errorMessage: "Error cloning category",
    }))

    return <DialogForm
        actionName="Clone"
        isValid={Boolean(id && name && pluralName)}
        formData={{
            ...category,
            id,
            name,
            pluralName,
            localStart: null,
            localEnd: null
        }}
        onSubmit={data => createMutation.mutateAsync([client.id, event.id, data])}
    >
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
                <TextField
                    label="Name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    fullWidth
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <PluralTextField
                    sourceValue={name}
                    value={pluralName}
                    onChange={setPluralName}
                    required
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <IdField
                    nameValue={name}
                    idValue={id}
                    onChangeId={setId}
                    maxLength={255}
                />
            </Grid>
        </Grid>
    </DialogForm>
}

export const useCloneCategoryDialog = createPopupHook({
    scope: Scope.Event,
    element: CloneCategoryDialog,
})