import { Scope } from "@marketpartner/backend-api";
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { LocationForm } from "src/content/locations/LocationForm";
import { useEvent } from "src/events/event-context";


export type CreateLocationDialogProps = LockableDialogProps

const CreateLocationDialog: FC<CreateLocationDialogProps> = (props) => {
    const client = useClient()!
    const event = useEvent()!

    const createMutation = backend.locations.useCreateLocation(withNotification({
        errorMessage: "Error creating location"
    }))

    return <LockableDialog maxWidth="xs" fullWidth {...props}>
        <DialogTitle>Create location</DialogTitle>
        <LocationForm
            actionName="Create"
            onSubmit={formData => createMutation.mutateAsync([client.id, event.id, formData])}
        />
    </LockableDialog>
}

export const useCreateLocationDialog = createPopupHook({
    element: CreateLocationDialog,
    scope: Scope.Event,
})