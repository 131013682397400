import { LocalDateTime } from "@marketpartner/backend-api";
import { FC } from "react";
import { ActiveStatusEditor, ActiveStatusEditorProps } from "src/common/statuses/ActiveStatusEditor";
import { useEvent } from "src/events/event-context";


export type EventActiveStatusEditorProps = Omit<ActiveStatusEditorProps, "timezone" | "start" | "end" | "setStart" | "setEnd"> & {
    localStart: LocalDateTime | null
    setLocalStart: (start: LocalDateTime | null) => void
    localEnd: LocalDateTime | null
    setLocalEnd: (start: LocalDateTime | null) => void
}

export const EventActiveStatusEditor: FC<EventActiveStatusEditorProps> = ({
    localStart,
    setLocalStart,
    localEnd,
    setLocalEnd,
    ...props
}) => {
    const event = useEvent()!
    return <ActiveStatusEditor
        start={localStart?.inZone(event.timezone) ?? null}
        end={localEnd?.inZone(event.timezone) ?? null}
        setStart={start => setLocalStart(start ? LocalDateTime.fromZonedDateTime(start) : null)}
        setEnd={end => setLocalEnd(end ? LocalDateTime.fromZonedDateTime(end) : null)}
        timezone={event.timezone}
        {...props}
    />
}