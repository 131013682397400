import { ClientMigrationScript, MigrationAction } from '@marketpartner/backend-api';
import CheckIcon from '@mui/icons-material/Check';
import UndoIcon from '@mui/icons-material/Undo';
import { Button, colors, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { FC } from 'react';
import { RollbackButton } from "src/clients/migration/RollbackButton";

export type MigrationsTableProps = {
    migrations: ClientMigrationScript[],
    doMigration: (action: MigrationAction, name: string) => void
    isMigrating: boolean
}

export const MigrationsTable: FC<MigrationsTableProps> = ({
    migrations,
    doMigration,
    isMigrating,
}) => {
    return <Table size="small">
        <TableHead>
            <TableRow>
                <TableCell></TableCell>
                <TableCell>Script</TableCell>
                <TableCell>Action</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {migrations.map(({ name, isApplied }) => isApplied ?
                <AppliedRow key={name} name={name} doMigration={doMigration} isMigrating={isMigrating} /> :
                <PendingRow key={name} name={name} doMigration={doMigration} isMigrating={isMigrating} />
            )}
        </TableBody>
    </Table>
}

type ScriptRowProps = {
    name: string,
    doMigration: (action: MigrationAction, name: string) => void
    isMigrating: boolean
}

const PendingRow: FC<ScriptRowProps> = ({
    name,
    doMigration,
    isMigrating
}) => {
    return <TableRow>
        <TableCell></TableCell>
        <TableCell>
            {name}
        </TableCell>
        <TableCell>
            <Button
                children="Apply"
                disabled={isMigrating}
                color="primary"
                onClick={() => doMigration(MigrationAction.up, name)}
            />
        </TableCell>
    </TableRow>
}

const AppliedRow: FC<ScriptRowProps> = ({
    name,
    doMigration,
    isMigrating
}) => {
    return <TableRow style={{ backgroundColor: colors.green[50] }}>
        <TableCell>
            <CheckIcon style={{ color: colors.green[500] }} />
        </TableCell>
        <TableCell>
            {name}
        </TableCell>
        <TableCell>
            <RollbackButton
                disabled={isMigrating}
                icon={<UndoIcon />}
                action={() => doMigration(MigrationAction.down, name)}
                text="Rollback"
            />
        </TableCell>
    </TableRow>
}