import { RegistrationCategoryBooleanCondition } from "@marketpartner/backend-api";
import { Checkbox, FormControlLabel, FormGroup, FormLabel } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { Loading } from "src/common/loading/Loading";
import { useEvent } from "src/events/event-context";


export type RegistrationCategoryConditionFormProps = {
    condition: RegistrationCategoryBooleanCondition
    onChangeProperties: (condition: Partial<RegistrationCategoryBooleanCondition>) => void
}

export const RegistrationCategoryConditionForm: FC<RegistrationCategoryConditionFormProps> = ({
    condition,
    onChangeProperties,
}) => {
    const client = useClient()!
    const event = useEvent()!
    const categoriesQuery = backend.registrationCategories.useGetAll([client.id, event.id])

    return <>
        <FormLabel component="legend">Category is one of the following:</FormLabel>
        <Loading request={categoriesQuery} sizing="fit-content" render={categories => <FormGroup>
            {categories.map(category => <FormControlLabel
                key={category.id}
                label={category.name}
                control={<Checkbox
                    checked={condition.categoryIds.includes(category.id)}
                    onChange={e => {
                        const categoryIds = e.target.checked ?
                            [...condition.categoryIds, category.id] :
                            condition.categoryIds.filter(id => id !== category.id)
                        onChangeProperties({ categoryIds })
                    }}
                />}
            />)}
        </FormGroup>} />
    </>
}
