import { MpFeedItems } from "@marketpartner/react-feed"
import { Cancel } from "@mui/icons-material"
import { Collapse, IconButton, Stack, Typography, useTheme } from "@mui/material"
import { FC } from "react"
import { Bar } from "src/common/layout/Bar"
import { Spacer } from "src/common/layout/Spacer"
import { DisplayStatusButtons } from "src/feeds/items/selection/DisplayStatusButtons"
import { useFeedItemSelection } from "src/feeds/items/selection/feed-item-selection-context"

export type FeedItemBottomBarProps = {
    items: MpFeedItems
}

export const FeedItemBottomBar: FC<FeedItemBottomBarProps> = ({
    items
}) => {
    const theme = useTheme()
    const { selectedIds, clearSelection } = useFeedItemSelection()

    return <Collapse in={selectedIds.size > 0}>
        <Bar spacing={1} p={1} borderColor={`1px solid ${theme.palette.divider}`}>
            <DisplayStatusButtons items={items} onChange={clearSelection} />
            <Spacer />
            <Stack sx={{ textAlign: "right" }}>
                <Typography>
                    {selectedIds.size < 2 ? "1 item selected" : `${selectedIds.size} items selected`}
                </Typography>
                {selectedIds.size === 1 && <Typography variant="body2" color="text.secondary">
                    (ctrl + click to select multiple)
                </Typography>}
            </Stack>
            <IconButton size="small" onClick={clearSelection}>
                <Cancel />
            </IconButton>
        </Bar>
    </Collapse>
}