import { Box, SxProps } from "@mui/system"
import { FC } from "react"
import { ClientSelector } from "src/clients/ClientSelector"
import { Bar } from "src/common/layout/Bar"
import { EventSelector } from "src/events/EventSelector"

export type ClientAndEventSelectorProps = {
    clientId: string | null
    onChangeClientId: (clientId: string | null) => void
    eventId: string | null
    onChangeEventId: (eventId: string | null) => void
    sx?: SxProps
    size?: "small" | "medium"
}

export const ClientAndEventSelector: FC<ClientAndEventSelectorProps> = ({
    clientId,
    onChangeClientId,
    eventId,
    onChangeEventId,
    sx,
    size
}) => {
    function changeClient(newClient: string | null) {
        onChangeEventId(null)
        onChangeClientId(newClient)
    }

    return <Bar
        alignItems="top"
        sx={sx}
    >
        <ClientSelector
            clientId={clientId}
            onSelectClientId={changeClient}
            size={size}
            sx={{ flexGrow: 1 }}
        />
        <Box sx={{ mx: 1 }} />
        <EventSelector
            clientId={clientId}
            selectedId={eventId}
            onSelectId={onChangeEventId}
            disabled={!clientId}
            size={size}
            sx={{ flexGrow: 1 }}
        />
    </Bar>
}