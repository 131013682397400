import { ContentEntity } from "@marketpartner/backend-api"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { link } from "src/common/routing/routes"
import { ContentCard, ContentCardProps } from "src/content/ContentCard"
import { getContentSecondaryText } from "src/content/entities/content-secondary-text"
import { ContentEntityAvatar } from "src/content/entities/ContentEntityAvatar"
import { useEvent } from "src/events/event-context"

export type ContentEntityCardProps = Partial<ContentCardProps> & {
    entity: ContentEntity
}

export const ContentEntityCard: FC<ContentEntityCardProps> = ({
    entity,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!

    return <ContentCard
        avatar={<ContentEntityAvatar entity={entity} />}
        primaryText={entity.fields.title}
        secondaryTexts={getContentSecondaryText(entity)}
        link={link.toContentEntity(client.id, event.id, entity.id)}
        {...props}
    />
}
