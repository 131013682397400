import { CreateRegistrationCategoryBody, Scope } from "@marketpartner/backend-api"
import { DialogTitle } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog"
import { createPopupHook } from "src/common/dialogs/Popup"
import { withNotification } from "src/common/notifications/with-notification"
import { useEvent } from "src/events/event-context"
import { RegistrationCategoryForm } from "src/registrations/configuration/categories/RegistrationCategoryForm"

export type NewRegistrationCategoryDialogProps = LockableDialogProps & {
    onCreate?: (category: CreateRegistrationCategoryBody) => void
}

const NewRegistrationCategoryDialog: FC<NewRegistrationCategoryDialogProps> = ({
    onCreate = () => { },
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const createMutation = backend.registrationCategories.useCreate(withNotification({
        successMessage: "Created category",
        errorMessage: "Error creating category",
        onSuccess: (_, [_clientId, _eventId, category]) => onCreate(category)
    }))

    return <LockableDialog maxWidth="sm" {...props}>
        <DialogTitle>Create registration category</DialogTitle>
        <RegistrationCategoryForm
            actionName="Create"
            onSubmit={categoryDetails => createMutation.mutateAsync([client.id, event.id, {
                ...categoryDetails,
                elements: [],
            }])}
        />
    </LockableDialog>
}

export const useNewRegistrationCategoryDialog = createPopupHook({
    element: NewRegistrationCategoryDialog,
    scope: Scope.Event,
})