import { ContentTypeId, contentTypeLookup } from "@marketpartner/backend-api"
import { Description, Link, PlayArrow } from "@mui/icons-material"
import { SvgIconProps } from "@mui/material"
import { cloneElement, FC, ReactElement } from "react"

const getTypeIcon = contentTypeLookup<ReactElement<SvgIconProps>>({
    "Link": <Link />,
    "Pdf": <Description />,
    "RemoteHostedPdf": <Description />,
    "RemoteHostedVideo": <PlayArrow />,
    "VideoLink": <PlayArrow />,
    "VimeoVideo": <PlayArrow />,
    "Custom": <Description />,
})

export type ContentTypeIconProps = Partial<SvgIconProps> & {
    typeId?: ContentTypeId
}

export const ContentTypeIcon: FC<ContentTypeIconProps> = ({
    typeId = "Custom:",
    ...props
}) => cloneElement(getTypeIcon(typeId), props)