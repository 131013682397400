import { Permission, Registration, RegistrationType, Scope } from "@marketpartner/backend-api";
import { Delete, Edit, PersonAdd } from "@mui/icons-material";
import { Divider, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { createPopupHook } from "src/common/dialogs/Popup";
import { LockableMenu, LockableMenuProps } from "src/common/menus/LockableMenu";
import { useEvent } from "src/events/event-context";
import { ifHas } from "src/login/IfHas";
import { useAddGuestDialog } from "src/registrations/actions/AddGuestDialog";
import { useDeleteRegistrationDialog } from "src/registrations/actions/DeleteRegistrationDialog";
import { useEditGuestDialog } from "src/registrations/actions/EditGuestDialog";
import { useEditPrimaryRegistrationDialog } from "src/registrations/actions/EditPrimaryRegistrationDialog";


export type RegistrationContextMenuProps = LockableMenuProps & {
    registration: Registration
}

const RegistrationContextMenu: FC<RegistrationContextMenuProps> = ({
    registration,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!

    const editPrimaryRegistrationDialog = useEditPrimaryRegistrationDialog()
    const editGuestDialog = useEditGuestDialog()
    const addGuestDialog = useAddGuestDialog()
    const deleteDialog = useDeleteRegistrationDialog()

    return <LockableMenu closeOnClick {...props}>
        <MenuItem onClick={() => registration.type === RegistrationType.Primary ?
            editPrimaryRegistrationDialog.open({ registration }) :
            editGuestDialog.open({ registration })
        }>
            <ListItemIcon><Edit color="primary" /></ListItemIcon>
            <ListItemText>Edit</ListItemText>
        </MenuItem>
        {registration.type === RegistrationType.Primary &&
            <MenuItem onClick={() => addGuestDialog.open({
                primaryRegistrationId: registration.id,
            })}>
                <ListItemIcon><PersonAdd /></ListItemIcon>
                <ListItemText>Add guest</ListItemText>
            </MenuItem>
        }
        {ifHas(Permission.ImportAndDeleteRegistrations, client.id, event.id, <>
            <Divider />
            <MenuItem onClick={() => deleteDialog.open({
                registration,
            })}>
                <ListItemIcon><Delete color="error" /></ListItemIcon>
                <ListItemText>Delete</ListItemText>
            </MenuItem>
        </>)}
    </LockableMenu>
}

export const useRegistrationContextMenu = createPopupHook({
    scope: Scope.Event,
    element: RegistrationContextMenu,
})