import { CreatePollQuestionBody, Scope } from "@marketpartner/backend-api"
import { DialogTitle } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog"
import { createPopupHook } from "src/common/dialogs/Popup"
import { withNotification } from "src/common/notifications/with-notification"
import { useEvent } from "src/events/event-context"
import { PollQuestionForm } from "src/polls/questions/PollQuestionForm"

type NewPollQuestionDialogProps = LockableDialogProps & {
    onCreate?: (pollQuestion: CreatePollQuestionBody) => void
    pollId: string
}

export const NewPollQuestionDialog: FC<NewPollQuestionDialogProps> = ({ 
    onCreate = () => {},
    pollId,
    ...props
 }) => {

    const client = useClient()!
    const event = useEvent()!

    const createMutation = backend.pollQuestions.useCreate(withNotification({
        successMessage: "Created poll question",
        errorMessage: "Error creating poll question",
        onSuccess: (_, [_clientId, _eventId, _pollId, pollQuestion]) => onCreate(pollQuestion)
    }))

    return <LockableDialog maxWidth="sm" {...props}>
        <DialogTitle>Create poll question</DialogTitle>
        <PollQuestionForm
            actionName="Create"
            onSubmit={pollQuestionDetails => createMutation.mutateAsync([client.id, event.id, pollId, pollQuestionDetails])}
        />
    </LockableDialog> 
}

export const useNewPollQuestionDialog = createPopupHook({
    element: NewPollQuestionDialog,
    scope: Scope.Event,
})