import { Permission, Registration, RegistrationApprovalState } from "@marketpartner/backend-api"
import DeleteForever from "@mui/icons-material/DeleteForever"
import { FormLabel, IconButton } from "@mui/material"
import { FC, useState } from "react"
import { useClient } from "src/clients/client-context"
import { LockableDialog } from "src/common/dialogs/LockableDialog"
import { Bar } from "src/common/layout/Bar"
import { useEvent } from "src/events/event-context"
import { ifHas } from "src/login/IfHas"
import { BulkApprovalDialog } from "src/registrations/bulk/BulkApprovalDialog"
import { BulkRegistrationApprovalState, BulkRegistrationState } from "src/registrations/bulk/useBulkApproval"
import { registrationStatusColors, RegistrationStatusIcon } from "src/registrations/RegistrationStatusIcon"

export type BulkApprovalButtonsProps = {
    selectedRegistrations: Registration[]
}

export const BulkApprovalButtons: FC<BulkApprovalButtonsProps> = ({
    selectedRegistrations,
}) => {
    const client = useClient()!
    const event = useEvent()!
    const [isOpen, setIsOpen] = useState(false)
    const [newStatus, setNewStatus] = useState<BulkRegistrationState | undefined>(undefined)

    const open = (status: BulkRegistrationState) => {
        setNewStatus(status)
        setIsOpen(true)
    }

    const bulkStatusButton = (status: BulkRegistrationApprovalState) => <IconButton
        onClick={() => open(status)}
        sx={{ color: registrationStatusColors[status] }}
    >
        <RegistrationStatusIcon status={status} variant="action" />
    </IconButton>

    const bulkDeleteButton = ifHas(Permission.ImportAndDeleteRegistrations, client.id, event.id,
        <IconButton color="error" onClick={() => open("Delete")}>
            <DeleteForever />
        </IconButton>
    ) ?? <></>

    return <>
        <Bar>
            <FormLabel>Approval:</FormLabel>
            {bulkStatusButton(RegistrationApprovalState.Approved)}
            {bulkStatusButton(RegistrationApprovalState.Rejected)}
            {bulkStatusButton(RegistrationApprovalState.Cancelled)}
            {bulkDeleteButton}
        </Bar>
        <LockableDialog open={isOpen} onClose={() => setIsOpen(false)}>
            <BulkApprovalDialog
                registrations={selectedRegistrations}
                newStatus={newStatus!}
            />
        </LockableDialog>
    </>
}