import { EventDetails, Permission } from '@marketpartner/backend-api';
import EditIcon from '@mui/icons-material/Edit';
import { DialogTitle } from '@mui/material';
import { FC } from 'react';
import { useClient } from 'src/clients/client-context';
import { backend } from 'src/common/api';
import { DialogButton } from "src/common/dialogs/DialogButton";
import { withNotification } from 'src/common/notifications/with-notification';
import { EventForm } from "src/events/EventForm";
import { ifHas } from 'src/login/IfHas';

export type EditEventButtonProps = {
    event: EventDetails
}

export const EditEventButton: FC<EditEventButtonProps> = ({
    event
}) => {
    const client = useClient()!
    const editMutation = backend.events.useUpdateEvent(withNotification({
        successMessage: "Event updated",
        errorMessage: "Error updating event",
    }))

    return ifHas(Permission.EditEventDetails, client.id, event.id,
        <DialogButton
            icon={<EditIcon />}
            text="Edit event"
            variant="contained"
            color="primary"
        >
            <DialogTitle>Edit event</DialogTitle>
            <EventForm
                actionName="Save"
                onSubmit={eventDetails => editMutation.mutateAsync([client.id, event.id, eventDetails])}
                event={event}
            />
        </DialogButton>
    )
}