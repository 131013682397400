import AddIcon from '@mui/icons-material/Add'
import { DialogTitle } from "@mui/material"
import { FC } from 'react'
import { useClient } from 'src/clients/client-context'
import { backend } from 'src/common/api'
import { DialogButton } from "src/common/dialogs/DialogButton"
import { withNotification } from 'src/common/notifications/with-notification'
import { EventPartnerInstanceForm } from 'src/content/event-partner/instances/EventPartnerInstanceForm'

export const AddEventPartnerInstanceButton: FC = () => {
    const client = useClient()!
    const addMutation = backend.content.useCreateEventPartnerInstance(withNotification({
        successMessage: (_, [_clientId, instanceDetails]) => `Added instance ${instanceDetails.id}`,
        errorMessage: "Error adding Event Partner instance"
    }))

    return <DialogButton
        text="Add instance"
        icon={<AddIcon />}
        color="primary"
        variant="contained"
        dialogProps={{
            maxWidth: "sm"
        }}
    >
        <DialogTitle>Add Event Partner instance</DialogTitle>
        <EventPartnerInstanceForm
            actionName="Add"
            onSubmit={instanceDetails => addMutation.mutateAsync([client.id, instanceDetails])}
        />
    </DialogButton>
}