import { AgendaHeading, Scope } from "@marketpartner/backend-api";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { ConfirmDeleteDialog } from "src/common/dialogs/ConfirmDeleteDialog";
import { LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { useEvent } from "src/events/event-context";


type DeleteAgendaHeadingDialogProps = LockableDialogProps & {
    heading: AgendaHeading
}

const DeleteAgendaHeadingDialog: FC<DeleteAgendaHeadingDialogProps> = ({
    heading,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const deletion = backend.agendaItems.useDelete()

    return <ConfirmDeleteDialog
        itemName="Heading"
        formProps={{
            action: () => deletion.mutateAsync([client.id, event.id, heading.agendaId, heading.id]),
            text: heading.name,
        }}
        {...props}
    />
}

export const useDeleteAgendaHeadingDialog = createPopupHook({
    scope: Scope.Event,
    element: DeleteAgendaHeadingDialog,
})
