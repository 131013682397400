import { CreateRegistrationCategoryBody, ProfileVisibilityLevel, RegistrationCategoryType, Role, Scope } from "@marketpartner/backend-api";
import { Add, CopyAll } from "@mui/icons-material";
import { Divider, ListItemIcon, ListItemText, MenuItem, MenuProps } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { createPopupHook } from "src/common/dialogs/Popup";
import { LoadingMenuItem } from "src/common/menus/LoadingMenuItem";
import { LockableMenu } from "src/common/menus/LockableMenu";
import { withNotification } from "src/common/notifications/with-notification";
import { useEvent } from "src/events/event-context";
import { useCopyCategoriesDialog } from "src/registrations/configuration/categories/copying/CopyCategoriesDialog";
import { useNewRegistrationCategoryDialog } from "src/registrations/configuration/categories/NewRegistrationCategoryDialog";

const defaultCategories: CreateRegistrationCategoryBody[] = [
    {
        id: "delegate",
        name: "Delegate",
        pluralName: "Delegates",
        role: Role.RegisteredUser,
        requiresApproval: false,
        type: RegistrationCategoryType.Delegate,
        defaultProfileFields: {
            direct_message_opt_in: false,
            profile_visible: false,
            allow_meeting_requests: false,
        },
        profileEditableByUser: true,
        profileVisibilityLevel: ProfileVisibilityLevel.RegisteredUsers,
    },
    {
        id: "speaker",
        name: "Speaker",
        pluralName: "Speakers",
        role: Role.RegisteredUser,
        requiresApproval: true,
        type: RegistrationCategoryType.Speaker,
        defaultProfileFields: {
            direct_message_opt_in: false,
            profile_visible: true,
            allow_meeting_requests: false,
        },
        profileEditableByUser: false,
        profileVisibilityLevel: ProfileVisibilityLevel.Public,
    },
    {
        id: "moderator",
        name: "Moderator",
        pluralName: "Moderators",
        role: Role.Moderator,
        requiresApproval: true,
        type: RegistrationCategoryType.Speaker,
        defaultProfileFields: {
            direct_message_opt_in: false,
            profile_visible: true,
            allow_meeting_requests: false,
        },
        profileEditableByUser: false,
        profileVisibilityLevel: ProfileVisibilityLevel.Public,
    }
]

export type NewRegistrationCategoryMenuProps = MenuProps & {
    onClose: () => void
    onCreate?: (category: CreateRegistrationCategoryBody) => void
}

const NewRegistrationCategoryMenu: FC<NewRegistrationCategoryMenuProps> = ({
    onClose,
    onCreate = () => { },
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const createDialog = useNewRegistrationCategoryDialog()
    const copyDialog = useCopyCategoriesDialog()

    const createMutation = backend.registrationCategories.useCreate(withNotification({
        onSuccess: (_, [_clientId, _eventId, category]) => onCreate(category),
        successMessage: "Created category",
        errorMessage: "Error creating category",
    }))
    const categoryIds = backend.registrationCategories.useGetAll([client.id, event.id], {
        select: categories => categories.map(category => category.id),
    })

    const availableDefaultCategories = defaultCategories
        .filter(category => !categoryIds.data?.includes(category.id))

    return <LockableMenu
        {...props}
        onClose={onClose}
        closeOnClick
    >
        <MenuItem onClick={() => createDialog.open({
            onCreate,
        })}>
            <ListItemIcon><Add /></ListItemIcon>
            <ListItemText>New category</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => copyDialog.open({})}>
            <ListItemIcon><CopyAll /></ListItemIcon>
            <ListItemText>Copy from event</ListItemText>
        </MenuItem>
        <Collapse in={!categoryIds.isLoading && availableDefaultCategories.length > 0}>
            <Divider />
            <MenuItem disabled>
                <ListItemText>Common categories</ListItemText>
            </MenuItem>
            {availableDefaultCategories.map(category => <LoadingMenuItem
                key={category.id}
                onClick={() => createMutation.mutateAsync([client.id, event.id, category])}
            >
                <ListItemText>{category.name}</ListItemText>
            </LoadingMenuItem>)}
        </Collapse>
    </LockableMenu>
}

export const useNewRegistrationCategoryMenu = createPopupHook({
    element: NewRegistrationCategoryMenu,
    scope: Scope.Event,
})