import { EventDetails, Permission } from "@marketpartner/backend-api"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { DeleteButton } from "src/common/dialogs/DeleteButton"
import { ifHas } from "src/login/IfHas"

export type DeleteEventButtonProps = {
    event: EventDetails
    onDelete?: () => void
}

export const DeleteEventButton: FC<DeleteEventButtonProps> = ({
    event,
    onDelete
}) => {
    const client = useClient()!
    const deleteMutation = backend.events.useDeleteEvent({
        onSuccess: () => onDelete?.(),
    })

    return ifHas(Permission.AdministerSystem, client.id, event.id,
        <DeleteButton
            buttonText="Delete event"
            itemName={event.name}
            text={`This will permanently delete all event content.`}
            confirmText={event.id}
            deleteAction={() => deleteMutation.mutateAsync([client.id, event.id])}
        />
    )
}