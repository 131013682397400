import AddIcon from '@mui/icons-material/Add';
import { DialogTitle } from '@mui/material';
import { FC } from 'react';
import { UserForm } from 'src/access/users/UserForm';
import { backend } from 'src/common/api';
import { DialogButton } from "src/common/dialogs/DialogButton";
import { withNotification } from 'src/common/notifications/with-notification';


export const CreateUserButton: FC = () => {
    const createMutation = backend.adminUsers.useCreate(withNotification({
        errorMessage: "Error creating user",
        successMessage: (_, [userDetails]) => `User invitation sent to ${userDetails.name} (${userDetails.email})`
    }))

    return <DialogButton
        icon={<AddIcon />}
        text="New user"
        color="primary"
        variant="contained"
    >
        <DialogTitle>Create new user</DialogTitle>
        <UserForm
            actionName="Invite"
            onSubmit={formData => createMutation.mutateAsync([formData])}
        />
    </DialogButton>
}