import { EventDetails, Permission, RegistrationCategory, RegistrationCategoryType } from "@marketpartner/backend-api"
import { Grid } from "@mui/material"
import { FC, useState } from "react"
import { useClient } from "src/clients/client-context"
import { EventSelector } from "src/events/EventSelector"
import { useLoginContext } from "src/login/LoginContext"
import { RegistrationCategoryCompatibility } from "src/registrations/actions/copy/registration-category-compatibility"
import { RegistrationCategoryCompatibilityIssues } from "src/registrations/actions/copy/RegistrationCategoryCompatibility"
import { RegistrationCategorySelector } from "src/registrations/configuration/categories/RegistrationCategorySelector"


export type CopySpeakersSourceCategorySelectorProps = {
    onChangeSourceEvent: (event: EventDetails) => void
    onChangeSourceCategory: (category: RegistrationCategory) => void
    compatibility: RegistrationCategoryCompatibility
}

export const CopySpeakersSourceCategorySelector: FC<CopySpeakersSourceCategorySelectorProps> = ({
    onChangeSourceEvent,
    onChangeSourceCategory,
    compatibility,
}) => {
    const client = useClient()!
    const [sourceEventId, setSourceEventId] = useState<string | null>(null)
    const [sourceCategoryId, setSourceCategoryId] = useState<string | null>(null)
    const login = useLoginContext()

    return <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
            <EventSelector
                selectedId={sourceEventId}
                onSelectId={setSourceEventId}
                onSelectLoadedItem={event => event && onChangeSourceEvent(event)}
                hideValue={event => !login.hasPermission(Permission.EditEventContent, client.id, event.id)}
                required
                omitSelf
                fullWidth
            />
        </Grid>
        {sourceEventId && <>
            <Grid item xs={12} sm={6}>
                <RegistrationCategorySelector
                    eventId={sourceEventId}
                    categoryId={sourceCategoryId}
                    onSelectCategoryId={setSourceCategoryId}
                    onSelectLoadedItem={category => category && onChangeSourceCategory(category)}
                    hideValue={category => category.type === RegistrationCategoryType.Delegate}
                    label="Source category"
                    error={compatibility.level === "error"}
                    required
                    fullWidth
                />
            </Grid>
        </>}

        <Grid item xs={12}>
            <RegistrationCategoryCompatibilityIssues
                enabled={Boolean(sourceCategoryId)}
                includeGuests={false}
                compatibility={compatibility}
            />
        </Grid>
    </Grid>
}
