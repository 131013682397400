import { Box, TextField, Typography } from "@mui/material"
import { FC, useState } from "react"
import { TagList } from "src/common/form-inputs/TagList"

export type EventTagEditorProps = {
    tags: string[]
    onChange: (tags: string[]) => void
}

export const EventTagEditor: FC<EventTagEditorProps> = ({
    tags,
    onChange
}) => {
    const [newTag, setNewTag] = useState("")
    const tagSet = new Set(tags)

    function addTag(tag: string) {
        if (tag.trim().length) {
            tagSet.add(tag.trim())
            onChange(Array.from(tagSet))
        }
    }

    function removeTag(tag: string) {
        tagSet.delete(tag)
        onChange(Array.from(tagSet))
    }

    return <Box>
        <Typography variant="h6" component="h4">
            Tags
        </Typography>
        <TextField
            value={newTag}
            onChange={e => setNewTag(e.target.value
                .replace(/['",\n]/g, "")
            )}
            onKeyDown={e => {
                if (e.key === "Enter" && !e.ctrlKey) {
                    addTag(newTag)
                    setNewTag("")
                    e.preventDefault()
                }
            }}
            label="Add new tag"
            helperText="Enter to confirm (commas and quotes not allowed)"
            fullWidth
        />
        <TagList tags={tags} onDelete={removeTag} sx={{ my: 1 }} />
    </Box>
}