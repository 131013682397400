import { RegistrationCategory, Scope } from "@marketpartner/backend-api"
import { ContentCopy, Delete, Edit } from "@mui/icons-material"
import { ListItemIcon, ListItemText, Menu, MenuItem, MenuProps } from "@mui/material"
import { FC } from "react"
import { createPopupHook } from "src/common/dialogs/Popup"
import { useCloneCategoryDialog } from "src/registrations/configuration/categories/copying/CloneCategoryDialog"
import { useDeleteRegistrationCategoryDialog } from "src/registrations/configuration/categories/DeleteRegistrationCategoryDialog"
import { useEditRegistrationCategoryDialog } from "src/registrations/configuration/categories/EditRegistrationCategoryDialog"


export type RegistrationCategoryContextMenuProps = MenuProps & {
    onClose: () => void
    category: RegistrationCategory
}

const RegistrationCategoryContextMenu: FC<RegistrationCategoryContextMenuProps> = ({
    category,
    ...props
}) => {
    const editDialog = useEditRegistrationCategoryDialog()
    const cloneDialog = useCloneCategoryDialog()
    const deleteDialog = useDeleteRegistrationCategoryDialog()

    return <Menu {...props} onClick={props.onClose}>
        <MenuItem onClick={() => editDialog.open({
            category,
        })}>
            <ListItemIcon><Edit color="primary" /></ListItemIcon>
            <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => cloneDialog.open({
            category,
        })}>
            <ListItemIcon><ContentCopy /></ListItemIcon>
            <ListItemText>Clone</ListItemText>
        </MenuItem>
        <MenuItem
            disabled={category.registrationCount > 0}
            onClick={() => deleteDialog.open({
                category,
            })}
        >
            <ListItemIcon><Delete color="error" /></ListItemIcon>
            <ListItemText>Delete</ListItemText>
        </MenuItem>
    </Menu>
}

export const useRegistrationCategoryContextMenu = createPopupHook({
    element: RegistrationCategoryContextMenu,
    scope: Scope.Event,
})