import { Client } from '@marketpartner/backend-api';
import { ExternalLink } from '@marketpartner/mp-common-mui';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, colors, DialogTitle, Typography } from '@mui/material';
import { FC } from 'react';
import { MigrationDialogContent } from 'src/clients/migration/MigrationDialogContent';
import { backend } from 'src/common/api';
import { DialogButton } from "src/common/dialogs/DialogButton";

export type MigrationButtonProps = {
    client: Client,
}

export const MigrationButton: FC<MigrationButtonProps> = ({
    client,
}) => {
    const latestScriptQuery = backend.clients.useGetMigrationScripts([], {
        select: scripts => scripts[scripts.length - 1]
    })
    
    const icon = client.migratedTo === latestScriptQuery.data ? <CheckIcon style={{color: colors.green[500]}}/> :
        latestScriptQuery.isLoading ? <></>:
        <WarningIcon style={{color: colors.orange[500]}}/>

    return <DialogButton
        text={client.migratedTo ?? "Migrate"}
        icon={icon}
        style={{textTransform: "none"}}
    >
        <DialogTitle>
            <Box display="flex" alignItems="center">
                <Box>
                    Manage <em>client_{client.id}</em> database
                </Box>
                <Box mx="auto"/>
                <Typography variant="body1">
                    <ExternalLink href="https://knexjs.org/#Migrations-latest">Help</ExternalLink>
                </Typography>
            </Box>
        </DialogTitle>
        <MigrationDialogContent client={client}/>
    </DialogButton>
}