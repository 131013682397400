import { AdjacentItemDetails, Session } from "@marketpartner/backend-api"
import { Stack, Typography } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { link } from "src/common/routing/routes"
import { EditableAgendaItem } from "src/content/agendas/EditableAgendaItem"
import { useEvent } from "src/events/event-context"


export type EditableSessionProps = {
    session: Session & AdjacentItemDetails
}

export const EditableSession: FC<EditableSessionProps> = ({
    session
}) => {
    const client = useClient()!
    const event = useEvent()!

    return <EditableAgendaItem
        item={session}
        headerText={session.name}
        linkTo={link.toSession(client.id, event.id, session.id)}
    >
        <Stack sx={{ alignItems: "center" }}>
            <Typography variant="subtitle2" color="text.secondary">{session.subtitle}</Typography>
        </Stack>
    </EditableAgendaItem>
}