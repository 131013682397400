import { FormType, StandardForm } from '@marketpartner/backend-api'
import { filterType } from '@marketpartner/mp-common'
import { Link } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-pro'
import { FC } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useClient } from "src/clients/client-context"
import { backend } from 'src/common/api'
import { QueryDataGrid } from 'src/common/grid/QueryDataGrid'
import { link } from "src/common/routing/routes"
import { EventDateTimeText } from 'src/common/time/DateTimeText'
import { useEvent } from "src/events/event-context"

const gridColumns: GridColDef<StandardForm>[] = [
    {
        field: "name",
        headerName: "Name",
        flex: 4,
        minWidth: 200,
        renderCell: (params) => <FormLink form={params.row} />
    },
    { field: "status", headerName: "Status", flex: 1, minWidth: 120 },
    { field: "start", headerName: "Active from", flex: 2, minWidth: 200, renderCell: ({ value }) => <EventDateTimeText dateTime={value} /> },
    { field: "end", headerName: "Active to", flex: 2, minWidth: 200, renderCell: ({ value }) => <EventDateTimeText dateTime={value} /> },
    { field: "responseCount", headerName: "Responses", flex: 1, minWidth: 100 }
]

export const FormsGrid: FC = () => {
    const client = useClient()!
    const event = useEvent()!
    const formsQuery = backend.forms.useGetAll([client.id, event.id], {
        select: forms => filterType(forms, FormType.Standard as const)
    })

    
    return <QueryDataGrid
        queryOrRows={formsQuery}
        columns={gridColumns}
        disableRowSelectionOnClick
    />
}

const FormLink: FC<{ form: StandardForm }> = ({ form }) => {
    const client = useClient()!
    const event = useEvent()!

    return <Link
        component={RouterLink}
        to={link.toForm(client.id, event.id, form.id)}
    >
        {form.name}
    </Link>
}