import { ClientAppTemplate, ClientAppTemplateScope } from "@marketpartner/backend-api"
import { LoadedSelector, LoadedSelectorProps } from "@marketpartner/mp-common-mui"
import { FC } from "react"
import { backend } from "src/common/api"


export type ClientAppTemplateSelectorProps = {
    clientId: string
    scope: ClientAppTemplateScope
    selectedId: string | null
    onSelectId: (id: string | null) => void
    onLoadSelectedTemplate?: (template: ClientAppTemplate | null) => void
} & Partial<LoadedSelectorProps<string, ClientAppTemplate>>

export const ClientAppTemplateSelector: FC<ClientAppTemplateSelectorProps> = ({
    clientId,
    scope,
    selectedId,
    onSelectId,
    onLoadSelectedTemplate,
    ...props
}) => {
    const query = backend.clientAppTemplates.useGetForClient([clientId], {
        select: templates => templates.filter(it => it.supportedScopes[scope])
    })
    return <LoadedSelector
        selectedId={selectedId}
        onSelectId={onSelectId}
        onSelectLoadedItem={onLoadSelectedTemplate}
        fetchRequest={query}
        noRefresh
        {...props}
    />
}