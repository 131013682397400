import { RegistrationAccessToken } from "@marketpartner/backend-api"
import { Link as MuiLink } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { UseQueryResult } from "@tanstack/react-query"
import { FC, useMemo } from "react"
import { Link } from "react-router-dom"
import { QueryDataGrid } from "src/common/grid/QueryDataGrid"
import { link } from "src/common/routing/routes"
import { LocalDateTimeText } from "src/common/time/DateTimeText"

const buildColumns = (
    clientId: string | null,
    eventId: string | null,
): GridColDef<RegistrationAccessToken>[] => [
        { field: "id", width: 70 },
        { field: "token", width: 150 },
        {
            field: "registrationId",
            minWidth: 200,
            flex: 2,
            renderCell: params => <MuiLink
                component={Link}
                to={link.toRegistrations(clientId ?? "", eventId ?? "", params.value)}
            >{params.value}</MuiLink>
        },
        { field: "name", minWidth: 200, flex: 2 },
        { field: "role", minWidth: 100, flex: 1 },
        { field: "lastUsed", minWidth: 200, flex: 2, renderCell: ({ value }) => <LocalDateTimeText dateTime={value} includeSeconds /> },
    ]

export type RegistrationTokensGridProps = {
    clientId: string | null
    eventId: string | null
    tokensQuery: UseQueryResult<RegistrationAccessToken[]>
}

export const RegistrationTokensGrid: FC<RegistrationTokensGridProps> = ({ 
    clientId,
    eventId,
    tokensQuery,
}) => {
    const columns = useMemo(() =>
        buildColumns(clientId, eventId),
        [clientId, eventId]
    )

    return <QueryDataGrid
        queryOrRows={tokensQuery}
        columns={columns}
        disableRowSelectionOnClick
    />
}