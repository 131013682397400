import { Scope } from "@marketpartner/backend-api";
import { Add, CopyAll } from "@mui/icons-material";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { FC } from "react";
import { createPopupHook } from "src/common/dialogs/Popup";
import { LockableMenu, LockableMenuProps } from "src/common/menus/LockableMenu";
import { useCopyRegistrationsDialog } from "src/registrations/actions/copy/CopyRegistrationsDialog";
import { useCreatePrimaryRegistrationDialog } from "src/registrations/actions/CreatePrimaryRegistrationDialog";


type AddRegistrationMenuProps = LockableMenuProps

const AddRegistrationMenu: FC<AddRegistrationMenuProps> = ({
    ...props
}) => {
    const createRegistrationDialog = useCreatePrimaryRegistrationDialog()
    const copyRegistrationsDialog = useCopyRegistrationsDialog()

    return <LockableMenu closeOnClick {...props}>
        <MenuItem onClick={() => createRegistrationDialog.open({})}>
            <ListItemIcon><Add /></ListItemIcon>
            <ListItemText>Create registration</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => copyRegistrationsDialog.open({})}>
            <ListItemIcon><CopyAll /></ListItemIcon>
            <ListItemText>Copy from event</ListItemText>
        </MenuItem>
    </LockableMenu>
}

export const useAddRegistrationMenu = createPopupHook({
    scope: Scope.Event,
    element: AddRegistrationMenu,
})