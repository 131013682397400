import { Permission } from "@marketpartner/backend-api"
import { useClientId } from "src/clients/client-context"
import { backend } from "src/common/api"
import { useEventId } from "src/events/event-context"
import { useLoginContext } from "src/login/LoginContext"


export type UseAppEnabledStatuses = {
    canDeploy: boolean
    canAccessAppPage: boolean
}

export const useAppEnabledStatuses = (): UseAppEnabledStatuses => {
    const clientId = useClientId()
    const eventId = useEventId()
    const login = useLoginContext()

    const clientTemplatesQuery = backend.clientAppTemplates.useGetForClient([clientId!], {
        enabled: Boolean(clientId),
        select: templates => templates.filter(it => it.supportedScopes.Event)
    })
    const clientDeploymentsQuery = backend.appTemplateDeployments.useGetEventDeployments([clientId!, eventId!], {
        enabled: Boolean(clientId && eventId)
    })
    const clientDomainsQuery = backend.domainNames.useGetAllClientDomainsForClient([clientId!], {
        enabled: Boolean(clientId)
    })

    if (!clientId || !eventId) {
        return {
            canAccessAppPage: false,
            canDeploy: false,
        }
    }

    const hasClientTemplate = (clientTemplatesQuery.data?.length ?? 0) > 0
    const hasClientDomain = (clientDomainsQuery.data?.length ?? 0) > 0
    const canDeploy = login.hasPermission(Permission.AppAdmin) || (hasClientDomain && hasClientTemplate)
    const hasEditableDeployment = (clientDeploymentsQuery.data?.length ?? 0) > 0

    return {
        canDeploy,
        canAccessAppPage: canDeploy || hasEditableDeployment
    }
}