import { RegistrationCategory } from "@marketpartner/backend-api"
import { Checkbox, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import { FC } from "react"
import { RegistrationCategoryTypeIcon } from "src/registrations/configuration/categories/RegistrationCategoryTypeIcon"


export type SelectableCategoriesListProps = {
    categories: RegistrationCategory[]
    selectedCategories: RegistrationCategory[]
    onChangeSelectedCategories: (selected: RegistrationCategory[]) => void
    excludedIds?: string[]
}

export const SelectableCategoriesList: FC<SelectableCategoriesListProps> = ({
    categories,
    selectedCategories,
    onChangeSelectedCategories,
    excludedIds = [],
}) => {
    const selectedIds = new Set(selectedCategories.map(category => category.id))
    const toggle = (category: RegistrationCategory) => {
        if (selectedIds.has(category.id)) {
            selectedIds.delete(category.id)
        } else {
            selectedIds.add(category.id)
        }
        onChangeSelectedCategories(categories.filter(category => selectedIds.has(category.id)))
    }

    return <List dense>
        {categories.map(category => {
            const selected = selectedCategories.includes(category)
            return <ListItemButton 
                key={category.id} 
                onClick={() => toggle(category)}
                disabled={excludedIds.includes(category.id)}
            >
                <ListItemIcon sx={{ alignItems: "center" }}>
                    <Checkbox
                        edge="start"
                        checked={selected}
                        disableRipple
                    />
                    <RegistrationCategoryTypeIcon type={category.type} />
                </ListItemIcon>
                <ListItemText
                    primary={category.name}
                    secondary={category.id}
                />
            </ListItemButton>
        })}
    </List>
}

