import { countBy, omitBy } from "lodash"
import { ExtractedFormField } from "src/registrations/import/pre-process/extract-form-fields"
import { normalizeColumn } from "src/registrations/import/pre-process/normalize-column"

export type FormFieldColumnMatches = Record<string, ExtractedFormField>

export const identifyFormFieldColumns = (
    csvColumns: string[],
    formFields: ExtractedFormField[],
): FormFieldColumnMatches => {
    const identified: FormFieldColumnMatches = {}

    csvColumns.forEach(csvColumn => {
        const match = findFormField(normalizeColumn(csvColumn), formFields)
        if (match) {
            identified[csvColumn] = match
        }
    })

    const fieldMatchCounts = countBy(Object.values(identified).map(it => it.name))
    return omitBy(identified, (field) => fieldMatchCounts[field.name] > 1)
}

const findFormField = (
    normalizedCsvColumn: string,
    formFields: ExtractedFormField[]
): ExtractedFormField | undefined => {
    const matches = formFields.filter(it => normalizeColumn(it.name) === normalizedCsvColumn)
    return matches.length === 1 ?
        matches[0] :
        undefined
}
