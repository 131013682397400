import { SxProps, TextField, Typography } from "@mui/material"
import { FC } from "react"
import { ClientDomainSelect } from "src/app-templates/domains/ClientDomainSelect"
import { DomainSelect } from "src/app-templates/domains/DomainSelect"
import { Bar } from "src/common/layout/Bar"


export type DeploymentLocationEditorProps = {
    clientId: string | null
    domainName: string | null
    onChangeDomainName: (domainName: string | null) => void
    path: string
    onChangePath: (path: string) => void
    sx?: SxProps
}

export const DeploymentLocationEditor: FC<DeploymentLocationEditorProps> = ({
    clientId,
    domainName,
    onChangeDomainName,
    path,
    onChangePath,
}) => {
    const handlePathChange = (newPath: string) => {
        onChangePath(newPath
            .toLowerCase()
            .replaceAll(/[./]+/g, "")
            .replaceAll(/[^a-z0-9-_]+/g, "-")
            .replaceAll(/([-_])([-_]+)/g, "$1")
        )
    }

    const select = clientId ?
        <ClientDomainSelect
            clientId={clientId}
            domainName={domainName}
            onChangeDomainName={onChangeDomainName}
            sx={{ flex: 1 }}
            fullWidth
        /> :
        <DomainSelect
            domainName={domainName}
            onChangeDomainName={onChangeDomainName}
            sx={{ flex: 1 }}
            fullWidth
        />

    return <Bar spacing={1}>
        {select}
        <Typography>/</Typography>
        <TextField
            label="Path"
            value={path}
            onChange={e => handlePathChange(e.target.value)}
            sx={{ flex: 1 }}
            required
        />
    </Bar>
}