import { ActivityLookup, LocalDateTime, Timezone } from "@marketpartner/backend-api"
import { Stack, Typography } from "@mui/material"
import { FC } from "react"
import { useEvent } from "src/events/event-context"


export type ActivityLookupListProps = {
    lookups: ActivityLookup[]
}

export const ActivityLookupList: FC<ActivityLookupListProps> = ({
    lookups
}) => {
    const event = useEvent()!

    return <Stack spacing={1}>
        {lookups.map((lookup, index) => <Stack key={index}>
            <Typography>{lookup.id}</Typography>
            <Typography variant="body2" color="text.secondary">{getTimeRange(lookup, event.timezone)}</Typography>
        </Stack>)}
    </Stack>
}

const getTimeRange = (lookup: ActivityLookup, timezone: Timezone): string => {
    const startText = lookup.localStartIso && LocalDateTime.fromISO(lookup.localStartIso).inZone(timezone).toLocaleString()
    const endText = lookup.localEndIso && LocalDateTime.fromISO(lookup.localEndIso).inZone(timezone).toLocaleString()
    if (!startText && !endText) {
        return 'Any time'
    }
    if (!endText) {
        return `After ${startText}`
    }
    if (!startText) {
        return `Before ${endText}`
    }
    return `${startText} ${endText}`
}