import { BooleanComputedColumn, ComputedColumnBooleanCondition } from "@marketpartner/backend-api";
import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { EditableBooleanExpressionGroup } from "src/common/boolean-conditions/EditableBooleanExpressionGroup";
import { actionCountConditionProperties } from "src/registrations/computed-columns/boolean-conditions/action-count-condition-properties";
import { timedActivityDurationConditionProperties } from "src/registrations/computed-columns/boolean-conditions/timed-activity-duration-condition-properties";


export type BooleanComputedColumnFormProps = {
    column: BooleanComputedColumn
    onChangeColumn: (column: BooleanComputedColumn) => void
}

export const BooleanComputedColumnForm: FC<BooleanComputedColumnFormProps> = ({
    column,
    onChangeColumn,
}) => {

    return <Stack spacing={1}>
        <Typography variant="h6">Conditions</Typography>
        <EditableBooleanExpressionGroup<ComputedColumnBooleanCondition>
            group={column.expressions}
            onChangeGroup={expressions => onChangeColumn({ ...column, expressions })}
            onDelete={() => { }}
            conditionPropertiesLookup={{
                ActionCount: actionCountConditionProperties,
                TimedActivityDuration: timedActivityDurationConditionProperties
            }}
            isRoot
        />
    </Stack>
}
