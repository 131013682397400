import { RegistrationCategory, StandardProfileFields } from "@marketpartner/backend-api";


export type ProfileSettingsLabels = Record<keyof StandardProfileFields | "profileVisibilityLevel" | "profileEditableByUser", string>

export const profileSettingsLabels = (
    category: RegistrationCategory
): ProfileSettingsLabels => ({
    profileVisibilityLevel: `${category.pluralName} visible to`,
    profile_visible: `Visible by default`,
    direct_message_opt_in: `Direct messaging by default`,
    allow_meeting_requests: `Meeting requests with ${category.pluralName} by default`,
    profileEditableByUser: `${category.pluralName} can edit their own profiles`,
})