import { AdminUser, StoredAttachedRole } from '@marketpartner/backend-api';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, ListItemText, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { sortBy } from 'lodash';
import { FC, memo, useMemo } from "react";
import { AdminUserMenuButton } from 'src/access/users/AdminUserMenuButton';
import { AttachRoleButton } from "src/access/users/AttachRoleButton";
import { DeleteUserButton } from "src/access/users/DeleteUserButton";
import { RemoveRoleButton } from "src/access/users/RemoveRoleButton";
import { RoleText } from "src/access/users/RoleText";
import { UserMfaStatus } from 'src/access/users/UserMfaStatus';
import { Bar } from 'src/common/layout/Bar';
import { fullSize } from 'src/common/styles';

export type UsersGridProps = {
    users: AdminUser[]
}

export const UsersGrid: FC<UsersGridProps> = memo(({ users }) => {
    const sortedUsers = useMemo(
        () => sortBy(users ?? [], user => tryExtractLastName(user.name)),
        [users]
    )

    return <TableContainer sx={{ ...fullSize }}>
        <Table stickyHeader>
            <TableHead>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>MFA set up?</TableCell>
                    <TableCell />
                </TableRow>
            </TableHead>
            <TableBody>
                {sortedUsers.map(user => <TableRow key={user.id}>
                    <TableCell>
                        <ListItemText
                            primary={user.name}
                            secondary={user.email}
                        />
                    </TableCell>
                    <TableCell>
                        <RolesCell user={user} />
                    </TableCell>
                    <TableCell>
                        {user.status}
                    </TableCell>
                    <TableCell style={{ width: "50px" }}>
                        <UserMfaStatus user={user} />
                    </TableCell>
                    <TableCell style={{ width: "50px" }}>
                        <Bar>
                            <AdminUserMenuButton user={user} />
                            <DeleteUserButton user={user} />
                        </Bar>
                    </TableCell>
                </TableRow>)}
            </TableBody>
        </Table>
    </TableContainer>
})

function tryExtractLastName(name: string) {
    const nameParts = name
        .replaceAll(/\s[(].*/g, "") // Removes any bracketed content after the name
        .trim()
        .split(" ")
    return nameParts[nameParts.length - 1]
}

type RolesCellProps = {
    user: AdminUser
}

const RolesCell: FC<RolesCellProps> = ({
    user
}) => {
    switch (user.roles.length) {
        case 0: return <NoRolesCell user={user} />
        case 1: return <SingleRoleCell user={user} attachedRole={user.roles[0]} showAttachButton />
        default: return <MultiRoleCell user={user} />
    }
}

type NoRolesCellProps = {
    user: AdminUser
}

const NoRolesCell: FC<NoRolesCellProps> = ({
    user
}) => {
    return <Box display="flex" alignItems="center">
        <Box flexGrow="0" display="flex" alignItems="center">
            <WarningIcon color="warning" />
            <b>No roles</b>
        </Box>
        <Box flexGrow="1" />
        <AttachRoleButton user={user} />
    </Box>
}

type SingleRoleCellProps = {
    user: AdminUser
    attachedRole: StoredAttachedRole
    showAttachButton?: boolean
}

const SingleRoleCell: FC<SingleRoleCellProps> = ({
    user,
    attachedRole,
    showAttachButton
}) => {
    return <Box display="flex" alignItems="center">
        <Box flexGrow="0">
            <RoleText attachedRole={attachedRole} />
        </Box>
        <Box flexGrow="1" />
        <RemoveRoleButton user={user} attachedRole={attachedRole} />
        {showAttachButton && <AttachRoleButton user={user} />}
    </Box>
}

type MultiRoleCellProps = {
    user: AdminUser
}

const MultiRoleCell: FC<MultiRoleCellProps> = ({
    user
}) => {
    return <Box>
        <Box display="flex" alignItems="center">
            <Box flexGrow="0">
                <b>{user.roles.length} roles</b>
            </Box>
            <Box flexGrow="1" />
            <AttachRoleButton user={user} />
        </Box>
        {user.roles.map(attachedRole =>
            <SingleRoleCell key={attachedRole.id} user={user} attachedRole={attachedRole} />
        )}
    </Box>
}