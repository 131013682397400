import { FormFieldChoice, FormFieldConfig, defaultFormFieldChoice } from "@marketpartner/backend-api"
import { Button, Grid, Stack, Typography } from "@mui/material"
import { cloneDeep } from "lodash"
import { FC, ReactNode } from "react"
import { ReorderButtons } from "src/common/reordering/ReorderButtons"
import { ReorderableStack } from "src/common/reordering/ReorderableStack"
import { useBulkReorder } from "src/common/reordering/useBulkReorder"
import { ChoiceConfig } from "src/forms/elements/types/ChoiceConfig"
import { PropertyUpdater } from "src/forms/elements/types/formElementConfigTypes"

type ConfigWithChoices = FormFieldConfig & { choices: FormFieldChoice[] }

type ChoiceWithId = FormFieldChoice & { 
    id: string 
    index: number
}

export type ChoicesConfigProps<Config extends ConfigWithChoices> = {
    config: Config
    updateConfigProperty: PropertyUpdater<Config>
    headerText: string
}

export const ChoicesConfig = <Config extends ConfigWithChoices>({
    config,
    updateConfigProperty,
    headerText,
}: ChoicesConfigProps<Config>) => {

    const reorder = useBulkReorder({
        items: config.choices,
        onReorder: items => updateConfigProperty('choices' as any, items)
    })

    function addChoice() {
        const newChoices = [...config.choices]
        newChoices.push(cloneDeep(defaultFormFieldChoice))
        updateConfigProperty('choices' as any, newChoices)
    }

    function deleteChoice(index: number) {
        const newChoices = [...config.choices]
        newChoices.splice(index, 1)
        updateConfigProperty('choices' as any, newChoices)
    }

    function editChoice<ChoiceProperty extends keyof FormFieldChoice>(index: number, property: ChoiceProperty, value: FormFieldChoice[ChoiceProperty]) {
        const newChoices = [...config.choices]
        const newChoice = { ...newChoices[index], [property]: value }
        newChoices[index] = newChoice
        updateConfigProperty('choices' as any, newChoices)
    }

    return <>
        <Grid container alignItems='center'>
            <Grid item xs>
                <Typography variant="h6" component="h3">
                    {headerText}
                </Typography>
            </Grid>
            <Grid item>
                <Grid container spacing={2}>
                    <Grid item>
                        <ReorderButtons reorder={reorder} />
                    </Grid>
                    { !reorder.isReordering && <Grid item>
                        <Button onClick={() => addChoice()}>Add new</Button>
                    </Grid> }
                </Grid>
            </Grid>
        </Grid>
        <Grid container>
            { reorder.isReordering
                ? <ReorderableStack
                    variant="stack"
                    sx={{width: '100%'}}
                    reorderEnabled={reorder.isReordering}
                    loading={reorder.isLoading}
                    items={reorder.orderedItems.map(mapChoiceToChoiceWithId)}
                    onReorder={items => reorder.reorderItems(items.map(mapChoiceWithIdToChoice))}
                    renderItem={choice => <ChoiceFieldsContainer
                        index={choice.index}
                        key={choice.index}
                    >
                        <ChoiceConfig
                            choice={choice}
                        />
                    </ChoiceFieldsContainer>}
                />
                : <Stack sx={{width: '100%'}}>
                    { config.choices.map((choice, index) => <ChoiceFieldsContainer
                        deleteChoice={deleteChoice}
                        index={index}
                        key={index}
                    >
                        <ChoiceConfig
                            choice={choice}
                            editChoice={(property, value) => {
                                editChoice(index, property, value)
                            }}
                        />
                    </ChoiceFieldsContainer>)}
                </Stack> }
        </Grid>
    </>
}

type ChoiceFieldsContainerProps = {
    index: number
    deleteChoice?: (index: number) => void
    children: ReactNode
}

const ChoiceFieldsContainer: FC<ChoiceFieldsContainerProps> = ({
    deleteChoice,
    index,
    children,
}) => {

    return <Grid item xs marginBottom={2}>
        <Grid item xs={8}>
            <Typography variant="subtitle1">
                Choice {index + 1}
            </Typography>
        </Grid>
        {deleteChoice && <Grid item xs={4}><Button color="error" onClick={() => deleteChoice(index)} >Remove</Button></Grid>}
        <Grid item xs>
            {children}
        </Grid>
    </Grid>
}

function mapChoiceToChoiceWithId(choice: FormFieldChoice, index: number): ChoiceWithId {
    return { 
        ...choice, 
        id: `${choice.label}-${choice.value}`,
        index
    }
}

function mapChoiceWithIdToChoice(choice: ChoiceWithId): FormFieldChoice {
    const { id, index, ...rest } = choice
    return rest
}