import { AppTemplateDeploymentPermission } from "@marketpartner/backend-api";
import { BasicRadioButtons, BasicRadioButtonsProps } from "@marketpartner/mp-common-mui";
import { FC } from "react";


export type AppTemplateDeploymentPermissionSelectProps = Omit<BasicRadioButtonsProps<AppTemplateDeploymentPermission>, "options">

export const AppTemplateDeploymentPermissionSelect: FC<AppTemplateDeploymentPermissionSelectProps> = (
    props
) => {
    return <BasicRadioButtons
        options={[{
            label: "Admin only",
            value: AppTemplateDeploymentPermission.AdminOnly,
        }, {
            label: "Client",
            value: AppTemplateDeploymentPermission.ClientAppAdmin,
        }]}
        {...props}
    />
}
