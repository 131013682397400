import { Timezone, timezoneList } from "@marketpartner/backend-api"
import { Autocomplete, AutocompleteProps, TextField, TextFieldProps } from "@mui/material"
import { FC } from "react"

export type TimezoneFieldProps = Partial<Omit<AutocompleteProps<Timezone, false, false, false>, "onChange">> & {
    onChange: (value: Timezone | null) => void
    inputProps?: Partial<TextFieldProps>
}

export const TimezoneField: FC<TimezoneFieldProps> = ({ 
    value,
    onChange,
    inputProps,
    ...props
}) => {
    const timezones = timezoneList
        .filter(tz => tz === "Etc/UTC" || !tz.startsWith("Etc/"))

    return <Autocomplete
        {...props}
        options={timezones}
        value={value}
        autoHighlight
        onChange={(_event, newValue) => onChange(newValue)}
        renderInput={params => <TextField {...inputProps} {...params} label="Timezone" />}
    />
}