import { AppTemplateDeploymentPermission, ClientAppTemplate, CreateClientAppTemplateBody } from "@marketpartner/backend-api";
import { Grid, TextField, TextFieldProps } from "@mui/material";
import { FC, useState } from "react";
import { AppTemplateDeploymentPermissionSelect } from "src/app-templates/AppTemplateDeploymentPermissionSelect";
import { ClientAppTemplateScopeSelector, useClientAppTemplateScopeSelector } from "src/app-templates/client-templates/ClientAppTemplateScopeSelector";
import { AppTemplateSelector } from "src/app-templates/templates/AppTemplateSelector";
import { backend } from "src/common/api";
import { DialogForm, ExtendableDialogFormProps } from "src/common/dialogs/DialogForm";

export type ClientAppTemplateFormData = CreateClientAppTemplateBody

export type ClientAppTemplateFormProps = ExtendableDialogFormProps<ClientAppTemplateFormData> & {
    onSubmit: (formData: ClientAppTemplateFormData) => Promise<unknown>
    clientAppTemplate?: ClientAppTemplate
}

export const ClientAppTemplateForm: FC<ClientAppTemplateFormProps> = ({
    clientAppTemplate,
    ...props
}) => {
    const [name, setName] = useState(clientAppTemplate?.name ?? "")
    const [appTemplateId, setAppTemplateId] = useState(clientAppTemplate?.appTemplateId ?? null)
    const [appTemplateVersion, setAppTemplateVersion] = useState(clientAppTemplate?.appTemplateVersion ?? null)
    const [deploymentPermission, setDeploymentPermission] = useState<AppTemplateDeploymentPermission>(clientAppTemplate?.deploymentPermission ?? AppTemplateDeploymentPermission.ClientAppAdmin)

    const metadataQuery = backend.appTemplates.useGetTemplateMetadata([appTemplateId!, appTemplateVersion!], {
        enabled: Boolean(appTemplateId && appTemplateVersion)
    })
    const scopeState = useClientAppTemplateScopeSelector(metadataQuery, clientAppTemplate?.supportedScopes)

    return <DialogForm
        formData={{
            name,
            appTemplateId: appTemplateId!,
            appTemplateVersion: appTemplateVersion!,
            supportedScopes: {
                Client: false,
                Event: true,
            },
            deploymentPermission,
            parameters: {},
            adminParameters: [],
        }}
        isValid={Boolean(appTemplateId && appTemplateVersion && name && !scopeState.error)}
        {...props}
    >
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
                <NameField value={name} onChange={e => setName(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
                <AppTemplateSelector
                    templateId={appTemplateId}
                    onChangeTemplateId={setAppTemplateId}
                    version={appTemplateVersion}
                    onChangeVersion={setAppTemplateVersion}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <AppTemplateDeploymentPermissionSelect
                    label="Deployment permission"
                    value={deploymentPermission}
                    onChange={setDeploymentPermission}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <ClientAppTemplateScopeSelector state={scopeState} />
            </Grid>
        </Grid>
    </DialogForm>
}

const NameField = ({ ...props }: TextFieldProps) => <TextField
    label="Name"
    fullWidth
    required
    {...props}
/>
