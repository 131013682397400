import { Add } from "@mui/icons-material"
import { Card, CardActionArea } from "@mui/material"
import { FC } from "react"
import { contentCardHeight, contentCardWidth } from "src/content/ContentCard"

export type AddContentCardProps = {
    onClick: () => void
}

export const AddContentCard: FC<AddContentCardProps> = ({
    onClick,
}) => {
    return <Card elevation={0} sx={{
        width: contentCardWidth,
        height: contentCardHeight,
    }}>
        <CardActionArea
            onClick={onClick}
            sx={{
                height: "100%",
                display: "flex",
                alignItems: "center",
            }}
            color="inherit"
        >
            <Add fontSize="large" sx={{
                color: theme => theme.palette.text.secondary,
            }} />
        </CardActionArea>
    </Card>
}