import { EventDetails } from "@marketpartner/backend-api"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { LoadedAutocomplete, LoadedAutocompleteProps } from "src/common/form-inputs/LoadedAutocomplete"
import { useEvent } from "src/events/event-context"

export type EventSelectorProps = Omit<LoadedAutocompleteProps<string, EventDetails>, "fetchRequest"> & {
    clientId?: string | null
    omitSelf?: boolean
}

export const EventSelector: FC<EventSelectorProps> = ({
    clientId,
    omitSelf = false,
    ...props
}) => {
    const client = useClient()
    const thisEvent = useEvent()
    clientId = clientId ?? client?.id
    const eventsQuery = backend.events.useGetEvents([clientId!], {
        enabled: Boolean(clientId),
        select: events => (events.filter(event =>
            !omitSelf ||
            !thisEvent ||
            event.id !== thisEvent.id
        ) as EventDetails[]).sort(sortByStartTime),
    })

    return <LoadedAutocomplete
        fetchRequest={eventsQuery}
        label="Event"
        {...props}
    />
}

function sortByStartTime(a: EventDetails, b: EventDetails) {
    return b.startTime.toMillis() - a.startTime.toMillis();
}