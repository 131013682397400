import { PollQuestion, Scope } from "@marketpartner/backend-api"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { ConfirmDeleteDialog } from "src/common/dialogs/ConfirmDeleteDialog"
import { LockableDialogProps } from "src/common/dialogs/LockableDialog"
import { createPopupHook } from "src/common/dialogs/Popup"
import { useEvent } from "src/events/event-context"

export type DeletePollQuestionDialogProps = LockableDialogProps & {
    pollQuestion: PollQuestion
    onDelete?: () => void
}
const DeletePollQuestionDialog: FC<DeletePollQuestionDialogProps> = ({
    pollQuestion,
    onDelete,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const deleteMutation = backend.pollQuestions.useDelete({
        onSuccess: () => {
            onDelete 
                ? onDelete() 
                : props.onClose()
        }
    })
    return <ConfirmDeleteDialog
        itemName={pollQuestion.name}
        formProps={{
            text: "This will not delete any responses",
            action: () => deleteMutation.mutateAsync([client.id, event.id, pollQuestion.pollId, pollQuestion.id]),
        }}
        {...props}
    />
}

export const useDeletePollQuestionDialog = createPopupHook({
    element: DeletePollQuestionDialog,
    scope: Scope.Event,
})