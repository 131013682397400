import { ContentSubCategory, CustomContentCategoryId, Scope } from "@marketpartner/backend-api";
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { ContentSubCategoryForm } from "src/content/sub-categories/ContentSubCategoryForm";
import { useEvent } from "src/events/event-context";


export type EditContentSubCategoryDialogProps = LockableDialogProps & {
    categoryId: CustomContentCategoryId
    subCategory: ContentSubCategory
}

const EditContentSubCategoryDialog: FC<EditContentSubCategoryDialogProps> = ({
    categoryId,
    subCategory,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const updateMutation = backend.contentCategories.useUpdateSubCategory(withNotification({
        errorMessage: "Error updating sub-category",
    }))

    return <LockableDialog
        maxWidth="xs"
        {...props}
    >
        <DialogTitle>Edit sub-category</DialogTitle>
        <ContentSubCategoryForm
            categoryId={categoryId}
            subCategory={subCategory}
            actionName="Save"
            onSubmit={subCategory => updateMutation.mutateAsync([client.id, event.id, subCategory.id, subCategory])}
        />
    </LockableDialog>
}

export const useEditContentSubCategoryDialog = createPopupHook({
    element: EditContentSubCategoryDialog,
    scope: Scope.Event,
})
