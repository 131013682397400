import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { IconButton, SxProps, Typography } from "@mui/material";
import { IconProps } from '@mui/material/Icon';
import { FC } from 'react';
import { Bar } from 'src/common/layout/Bar';
import { useLoginContext } from "src/login/LoginContext";

export type UserIndicatorProps = {
    size: IconProps['fontSize']
    sx?: SxProps
}

export const UserIndicator: FC<UserIndicatorProps> = ({
    size,
    sx,
}) => {
    const login = useLoginContext()

    return <Bar spacing={1} sx={sx}>
        <Typography>{login.name}</Typography>
        <IconButton onClick={login.logout} color="inherit">
            <ExitToAppIcon fontSize={size} />
        </IconButton>
    </Bar>
}