import { Permission } from '@marketpartner/backend-api'
import { AppRegistration, Apps, Diversity3, PermMedia } from '@mui/icons-material'
import Assignment from '@mui/icons-material/Assignment'
import EventIcon from '@mui/icons-material/Event'
import ListAltIcon from '@mui/icons-material/ListAlt'
import PeopleIcon from '@mui/icons-material/People'
import PersonIcon from '@mui/icons-material/Person'
import TimelineIcon from '@mui/icons-material/Timeline'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import { Box, Collapse, List, ListItem, ListItemIcon, ListItemText } from "@mui/material"
import { FC } from 'react'
import { Link, useLocation } from "react-router-dom"
import { useAppEnabledStatuses } from 'src/app-templates/deployments/useAppEnabledStatuses'
import { useClient, useClientId } from "src/clients/client-context"
import { link } from "src/common/routing/routes"
import { useEvent, useEventId } from "src/events/event-context"
import { ifHas } from "src/login/IfHas"
import { LoginContext, useLoginContext } from "src/login/LoginContext"

export type SideBarProps = {
    close?: () => void
}

export const SideBarContent: FC<SideBarProps> = ({
    close
}) => {
    const login = useLoginContext()

    return <List onClick={close} sx={{ maxWidth: 250 }}>
        {ifHas(Permission.AdministerSystem, undefined, undefined,
            <MenuOption text="Manage access" icon={<VpnKeyIcon />} path={link.toAccess()} includesSubRoutes />
        )}
        {ifHas(Permission.AppAdmin, undefined, undefined,
            <MenuOption text="App admin" icon={<AppRegistration />} path={link.toApps()} includesSubRoutes />
        )}
        {getClientAndEventMenu(login)}
    </List>
}

function getClientAndEventMenu(login: LoginContext) {
    if (login.singleEventId) {
        return <EventMenu clientId={login.singleClientId!} />
    }
    if (login.singleClientId) {
        return <ClientMenu />
    }
    return <ClientsMenu />
}

const ClientsMenu = () => {
    return <>
        <MenuOption text="Clients" icon={<PeopleIcon />} path={link.toClients()} />
        <ClientMenu padding={1} />
    </>
}

const ClientMenu = ({ padding = 0 }) => {
    const clientId = useClientId()
    const client = useClient()

    if (!clientId) {
        return <></>
    }

    return <>
        <MenuOption text={client?.name} icon={<PersonIcon />} path={link.toClient(clientId)} padding={padding} />
        <EventMenu clientId={clientId} padding={padding + 1} />
    </>
}

type EventMenuProps = {
    padding?: number
    clientId: string
}

const EventMenu: FC<EventMenuProps> = ({
    clientId,
    padding = 0,
}) => {
    const eventId = useEventId()
    const event = useEvent()
    const appEnabledStatuses = useAppEnabledStatuses()

    if (!eventId) {
        return <></>
    }

    return <>
        <MenuOption text={event?.name} icon={<EventIcon />} path={link.toEvent(clientId, eventId)} padding={padding} />
        {ifHas(Permission.ViewRegistrations, clientId, eventId,
            // Note: Must link to base registration route to ensure the menu entry is highlighted on other subroutes
            <MenuOption text="Registration" icon={<ListAltIcon />} path={link.toRegistrationPage(clientId, eventId)} padding={padding + 1} includesSubRoutes />
        )}
        {ifHas(Permission.ViewActivities, clientId, eventId,
            <MenuOption text="Activities" icon={<TimelineIcon />} path={link.toActivities(clientId, eventId)} padding={padding + 1} includesSubRoutes />
        )}
        {ifHas(Permission.ViewForms, clientId, eventId,
            <MenuOption text="Forms" icon={<Assignment />} path={link.toForms(clientId, eventId)} padding={padding + 1} includesSubRoutes />
        )}
        {ifHas(Permission.ViewEventContent, clientId, eventId,
            <MenuOption text="Content" icon={<PermMedia />} path={link.toContent(clientId, eventId)} padding={padding + 1} includesSubRoutes />
        )}
        {ifHas(Permission.ClientAppAdmin, undefined, undefined, <Collapse in={appEnabledStatuses.canAccessAppPage}>
            <MenuOption text="Apps" icon={<Apps />} path={link.toEventApps(clientId, eventId)} padding={padding + 1} />
        </Collapse>)}
        <MenuOption text="Networking" icon={<Diversity3 />} path={link.toNetworking(clientId, eventId)} padding={padding + 1} includesSubRoutes />
    </>
}

type MenuOptionProps = {
    text?: string,
    path: string,
    icon?: JSX.Element,
    padding?: number,
    includesSubRoutes?: boolean
}

const MenuOption: FC<MenuOptionProps> = ({
    text,
    path,
    icon,
    padding = 0,
    includesSubRoutes = false
}) => {
    const location = useLocation()
    const isSelected = includesSubRoutes ?
        location.pathname.startsWith(path) :
        location.pathname === path

    return <ListItem
        button
        component={Link}
        to={path}
        selected={isSelected}
    >
        <Box pl={padding * 2} display="flex" alignItems="center">
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
            <ListItemText primary={text} />
        </Box>
    </ListItem>
}