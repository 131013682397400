import { RegistrationCategoryBooleanCondition } from "@marketpartner/backend-api";
import { ListAlt } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { BooleanConditionProperties } from "src/common/boolean-conditions/boolean-condition-properties";
import { RegistrationCategoryConditionForm } from "src/email/scheduling/RegistrationCategoryConditionForm";
import { RegistrationCategoryText } from "src/registrations/configuration/categories/RegistrationCategoryText";


export const registrationCategoryConditionProperties: BooleanConditionProperties<RegistrationCategoryBooleanCondition> = {
    typeName: "Registration category",
    iconComponent: ListAlt,
    getPrimaryText: () => "Registration category is",
    getSecondaryContent: condition => <Stack>
        {condition.categoryIds.map(id => <RegistrationCategoryText key={id} categoryId={id} />)}
    </Stack>,
    isValid: ({ categoryIds }) => categoryIds.length > 0,
    createDefault: () => ({
        type: "RegistrationCategory",
        categoryIds: [],
    }),
    renderForm: (condition, onChangeProperties) => <RegistrationCategoryConditionForm
        condition={condition}
        onChangeProperties={onChangeProperties}
    />,
}