import { colors, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme } from "@mui/material";
import { FC } from "react";
import { ColumnMatchIcon } from "src/registrations/import/column-matching/ColumnMatchIcon";
import { ColumnMatchSelector } from "src/registrations/import/column-matching/ColumnMatchSelector";
import { UseColumnMatches } from "src/registrations/import/column-matching/useColumnMatches";
import { ColumnMatch } from "src/registrations/import/pre-process/auto-match-columns";

export type MatchCsvColumnsProps = {
    columnMatches: UseColumnMatches
    sx?: SxProps
}

export const MatchCsvColumns: FC<MatchCsvColumnsProps> = ({
    columnMatches,
    sx,
}) => {
    return <TableContainer sx={sx}>
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell />
                    <TableCell>
                        CSV column
                    </TableCell>
                    <TableCell>
                        Form field mapping
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {Object.entries(columnMatches.matches).map(([csvColumn, match]) =>
                    <TableRow key={csvColumn} sx={rowStyle(match)}>
                        <TableCell>
                            <ColumnMatchIcon match={match} />
                        </TableCell>
                        <TableCell>{csvColumn}</TableCell>
                        <TableCell>
                            <ColumnMatchSelector
                                match={match}
                                onIgnore={() => columnMatches.ignoreColumn(csvColumn)}
                                onMatch={formFieldName => columnMatches.setColumnMatch(csvColumn, formFieldName)}
                                formFields={columnMatches.fieldOptionsForMatch(match)}
                            />
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    </TableContainer>
}

const rowStyle = (match: ColumnMatch | null): SxProps<Theme> => {
    switch (match?.type) {
        case undefined: return {
            backgroundColor: colors.orange["50"],
        }
        case "ignored": return {
            backgroundColor: theme => theme.palette.grey[100],
            opacity: 0.5,
        }
        default: return {
            backgroundColor: colors.green["50"]
        }
    }
}