import { AdjacentItemDetails, StructuredBreakout } from "@marketpartner/backend-api"
import { Add, DeleteForever, Edit } from "@mui/icons-material"
import { Divider, ListItemIcon, ListItemText, Menu, MenuItem, MenuProps } from "@mui/material"
import { FC } from "react"
import { MoveAgendaItemButtons } from "src/content/agendas/MoveAgendaItemButtons"
import { useDeleteBreakoutDialog } from "src/content/agendas/breakouts/DeleteBreakoutDialog"
import { useEditBreakoutDialog } from "src/content/agendas/breakouts/EditBreakoutDialog"
import { useAddBreakoutTrackDialog } from "src/content/agendas/tracks/AddBreakoutTrackDialog"

export type BreakoutContextMenuProps = MenuProps & {
    breakout: StructuredBreakout & AdjacentItemDetails,
    onClose: () => void
}

export const BreakoutContextMenu: FC<BreakoutContextMenuProps> = ({
    breakout,
    ...props
}) => {
    const addTrackDialog = useAddBreakoutTrackDialog()
    const editBreakoutDialog = useEditBreakoutDialog()
    const deleteBreakoutDialog = useDeleteBreakoutDialog()

    const canDelete = breakout.tracks.flatMap(it => it.items).length === 0

    return <Menu {...props} onClick={props.onClose}>
        <MenuItem disabled>
            <ListItemText sx={{ textAlign: "center" }}>Breakout</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => addTrackDialog.open({
            agendaId: breakout.agendaId,
            breakoutId: breakout.id,
        })}>
            <ListItemIcon><Add /></ListItemIcon>
            <ListItemText>Add track</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => editBreakoutDialog.open({ breakout })}>
            <ListItemIcon><Edit color="primary" /></ListItemIcon>
            <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem disabled={!canDelete} onClick={() => deleteBreakoutDialog.open({ breakout })}>
            <ListItemIcon><DeleteForever color="error" /></ListItemIcon>
            <ListItemText>Delete{canDelete ? "" : " (must be empty)"}</ListItemText>
        </MenuItem>
        <Divider />
        <MoveAgendaItemButtons item={breakout} onMove={props.onClose} />
    </Menu>
}