import { UserActivityUsage } from "@marketpartner/backend-api"
import { PlayCircle, StopCircle } from "@mui/icons-material"
import { Stack, SxProps, Typography } from "@mui/material"
import { DateTime, Duration } from "luxon"
import { FC, useMemo } from "react"
import { ActivityName } from "src/activities/ActivityName"
import { buildTimelineEvents, UserTimelineEvent, UserTimelineEventType } from "src/activities/user-usage/user-timeline-event"
import { Bar } from "src/common/layout/Bar"
import { EventDateTimeText } from "src/common/time/DateTimeText"
import { groupByDay } from "src/common/time/group-by-day"
import { prettyDuration } from "src/common/time/pretty-duration"
import { useEvent } from "src/events/event-context"


export type UserTimelineProps = {
    usage: UserActivityUsage
    sx?: SxProps
}

export const UserTimeline: FC<UserTimelineProps> = ({
    usage,
    sx,
}) => {
    const { timezone } = useEvent()!
    const eventsByDay = useMemo(
        () => groupByDay(
            buildTimelineEvents(usage),
            event => event.time,
            timezone,
        ),
        [usage, timezone]
    )

    if (usage.actions.length === 0 && usage.timedActivities.length === 0) {
        return <Typography
            variant="h6"
            sx={{
                textAlign: "center",
                py: 3,
            }}
            children="No activity recorded"
        />
    }

    return <Stack spacing={2} sx={sx}>
        {eventsByDay.map(({ localDay, items }) => <DayTimeline
            key={localDay.toISO()}
            localDay={localDay}
            events={items}
        />)}
    </Stack>
}

type DayTimelineProps = {
    localDay: DateTime
    events: UserTimelineEvent[]
}

const DayTimeline: FC<DayTimelineProps> = ({
    localDay,
    events,
}) => {
    return <Stack spacing={1}>
        <Typography sx={{
            borderWidth: 0,
            borderStyle: "solid",
            borderColor: theme => theme.palette.divider,
            borderBottom: 1,
            fontWeight: "bold",
            position: "sticky",
            top: 0,
            backgroundColor: theme => theme.palette.background.paper,
            zIndex: theme => theme.zIndex.appBar - 1,
        }}>
            {localDay.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
        </Typography>
        <Stack spacing={0.5}>
            {events.map((event, idx) =>
                <TimelineEvent key={idx} event={event} />
            )}
        </Stack>
    </Stack>
}

type TimelineEventProps = {
    event: UserTimelineEvent
}

const TimelineEvent: FC<TimelineEventProps> = ({
    event
}) => {
    return <Bar>
        <Typography variant="body2" color="text.secondary" sx={{
            flex: "0 0 90px"
        }}>
            <EventDateTimeText dateTime={event.time} hideTimezone hideDate />
        </Typography>
        <ActivityName activityId={event.activityId} sx={{
            flex: "1 1"
        }} />
        <Stack sx={{
            flex: "0 0 60px",
            alignItems: "center"
        }}>
            {event.type === UserTimelineEventType.startTimedActivity && <TimedActivityStart duration={event.duration} />}
            {event.type === UserTimelineEventType.endTimedActivity && <TimedActivityEnd />}
        </Stack>
    </Bar>
}

type TimedActivityStartProps = {
    duration: Duration
}

const TimedActivityStart: FC<TimedActivityStartProps> = ({
    duration
}) => {
    return <Stack sx={{ alignItems: "center" }}>
        <PlayCircle />
        <Typography color="text.secondary" variant="body2">{prettyDuration(duration.as('seconds'))}</Typography>
    </Stack>
}

const TimedActivityEnd: FC = () => {
    return <StopCircle sx={{ color: "text.secondary" }} />
}