import { SearchBox } from "@marketpartner/mp-common-mui";
import { useSearchCallback } from "@marketpartner/mp-common-react";
import { FC } from "react";
import { SearchableGridLayout } from "src/common/grid/SearchableGridLayout";
import { Bar } from "src/common/layout/Bar";
import { SessionsGrid, SessionsGridProps, SessionWithAgendaName } from "src/content/agendas/sessions/SessionsGrid";


export type SearchableSessionsGridProps = Partial<SessionsGridProps>

export const SearchableSessionsGrid: FC<SearchableSessionsGridProps> = ({
    ...props
}) => {
    const [searchText, setSearchText, searchSessions] = useSearchCallback(searchParams, searchOptions)

    return <SearchableGridLayout
        searchControls={
            <Bar spacing={1}>
                <SearchBox searchText={searchText} setSearchText={setSearchText} />
            </Bar>
        }
        grid={<SessionsGrid
            filterRows={searchSessions}
            disableColumnMenu
            {...props}
        />}
    />
}

const searchParams = (session: SessionWithAgendaName) => [
    session.id,
    session.name,
    session.locationId,
    session.agendaName,
]

const searchOptions = {
    disableUrlState: true,
}