import { ComputedColumn, EventDetails, Scope } from "@marketpartner/backend-api";
import { DialogTitle } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { FC, useState } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { DialogForm } from "src/common/dialogs/DialogForm";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { QueryDataGrid } from "src/common/grid/QueryDataGrid";
import { BarAndFlex } from "src/common/layout/BarAndFlex";
import { withNotification } from "src/common/notifications/with-notification";
import { useEvent } from "src/events/event-context";
import { EventSelector } from "src/events/EventSelector";
import { ComputedColumnTypeIcon } from "src/registrations/computed-columns/ComputedColumnTypeIcon";


export type CopyComputedColumnsDialogProps = LockableDialogProps

const CopyComputedColumnsDialog: FC<CopyComputedColumnsDialogProps> = (props) => {
    return <LockableDialog
        maxWidth="sm"
        fullWidth
        {...props}
    >
        <DialogTitle>Copy computed columns</DialogTitle>
        <CopyComputedColumnsDialogContent />
    </LockableDialog>
}

const CopyComputedColumnsDialogContent: FC = () => {
    const client = useClient()!
    const event = useEvent()!

    const [sourceEventId, setSourceEventId] = useState<string | null>(null)
    const [selectedColumnIds, setSelectedColumnIds] = useState<string[]>([])

    const sourceEventColumnsQuery = backend.events.useGetEvents([client.id], {
        select: events => (events.find(event => event.id === sourceEventId) as EventDetails)?.computedColumns,
    })
    const updateEventMutation = backend.events.useUpdateEvent(withNotification({
        errorMessage: "Error copying computed columns"
    }))

    const availableColumnIds = sourceEventColumnsQuery.data
        ?.filter(column => !event.computedColumns.some(c => c.id === column.id))
        ?.map(column => column.id) ?? []
    const selectedColumns = sourceEventColumnsQuery.data
        ?.filter(column => selectedColumnIds.includes(column.id)) ?? []

    return <DialogForm
        actionName={`Copy (${selectedColumnIds.length} selected)`}
        formData={{}}
        isValid={selectedColumns.length > 0}
        onSubmit={() => updateEventMutation.mutateAsync([client.id, event.id, {
            computedColumns: [
                ...event.computedColumns,
                ...selectedColumns,
            ]
        }])}
    >
        <BarAndFlex sizing="fit-content" spacing={2} barContent={
            <EventSelector
                selectedId={sourceEventId}
                onSelectId={setSourceEventId}
                omitSelf
                fullWidth
            />
        }>
            <QueryDataGrid
                queryOrRows={sourceEventColumnsQuery}
                columns={gridColumns}
                checkboxSelection
                rowSelectionModel={selectedColumnIds}
                onRowSelectionModelChange={ids => setSelectedColumnIds(ids as string[])}
                isRowSelectable={({ row }) => availableColumnIds.includes(row.id)}
                disableColumnResize
                disableColumnMenu
                sx={{ minHeight: 300 }}
            />
        </BarAndFlex>
    </DialogForm>
}

const gridColumns: GridColDef<ComputedColumn>[] = [
    {
        field: "type",
        headerName: " ",
        width: 50,
        renderCell: ({ row }) => <ComputedColumnTypeIcon type={row.type} />,
        align: "center",
    },
    {
        field: "id",
        headerName: "Id",
        flex: 1,
    }
]

export const useCopyComputedColumnsDialog = createPopupHook({
    element: CopyComputedColumnsDialog,
    scope: Scope.Event,
})