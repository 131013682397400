import { LinkedRelatedContent, Registration, RelatedContent, RelatedContentCategory, RelatedContentId, RelatedContentType, reorderBody } from "@marketpartner/backend-api"
import { Typography } from "@mui/material"
import { FC, MouseEvent } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { StickyAccordian } from "src/common/layout/StickyAccordian"
import { withNotification } from "src/common/notifications/with-notification"
import { ReorderableStack } from "src/common/reordering/ReorderableStack"
import { ReorderButtons } from "src/common/reordering/ReorderButtons"
import { useBulkReorder } from "src/common/reordering/useBulkReorder"
import { AddContentCard } from "src/content/AddContentCard"
import { SessionCard } from "src/content/agendas/sessions/SessionCard"
import { ContentEntityCard } from "src/content/entities/ContentEntityCard"
import { useLinkContentDialog } from "src/content/relationships/LinkContentDialog"
import { useRelatedContentContextMenu } from "src/content/relationships/RelatedContentContextMenu"
import { useEvent } from "src/events/event-context"
import { ProfileCard } from "src/registrations/profiles/ProfileCard"


export type EditableRelatedContentCategoryProps = {
    category?: RelatedContentCategory
    relatedContent: LinkedRelatedContent[]
    relatedToType: RelatedContentType
    relatedToId: RelatedContentId
}

export const EditableRelatedContentCategory: FC<EditableRelatedContentCategoryProps> = ({
    category,
    relatedContent,
    relatedToType,
    relatedToId,
}) => {
    const client = useClient()!
    const event = useEvent()!

    const reorderMutation = backend.contentRelationships.useReorderRelationships(withNotification({
        errorMessage: "Error reordering related content."
    }))

    const reorder = useBulkReorder({
        items: relatedContent,
        onReorder: items => reorderMutation.mutateAsync([client.id, event.id, {
            relatedTo: {
                type: relatedToType,
                contentId: relatedToId,
            },
            ...reorderBody(items),
        }])
    })

    const linkContentDialog = useLinkContentDialog()
    const linkContextMenu = useRelatedContentContextMenu()

    const handleOpenContextMenu = (e: MouseEvent, relatedContent: RelatedContent) => linkContextMenu.open({
        relatedContent,
        anchorEl: e.currentTarget,
    })

    return <StickyAccordian
        headingContent={<Typography variant="h6">
            {category?.pluralName ?? "Uncategorized"} ({relatedContent.length})
        </Typography>}
        controls={<ReorderButtons
            reorder={reorder}
        />}
    >
        <ReorderableStack
            items={reorder.orderedItems}
            onReorder={reorder.reorderItems}
            variant="grid"
            reorderEnabled={reorder.isReordering}
            loading={reorder.isLoading}
            renderItem={relatedContent => {
                switch (relatedContent.type) {
                    case RelatedContentType.Entity: return <ContentEntityCard
                        entity={relatedContent.item}
                        onClickMenu={e => handleOpenContextMenu(e, relatedContent)}
                    />
                    case RelatedContentType.Speaker: return <ProfileCard
                        registration={relatedContent.item as Registration}
                        onClickMenu={e => handleOpenContextMenu(e, relatedContent)}
                    />
                    default: return <SessionCard
                        session={relatedContent.item}
                        onClickMenu={e => handleOpenContextMenu(e, relatedContent)}
                    />
                }
            }}
            addItem={<AddContentCard onClick={() => linkContentDialog.open({
                relatedToType,
                relatedToId,
                category,
            })} />}
        />
    </StickyAccordian>
}