import { FormControl, FormControlLabel, Radio, RadioGroup, SxProps } from "@mui/material";
import { FC } from "react";
import { FeedDisplayMode, useFeedDisplayOptions } from "src/feeds/items/options/feed-display-context";

export type FeedDisplayModeSelectorProps = {
    sx?: SxProps
}

export const FeedDisplayModeSelector: FC<FeedDisplayModeSelectorProps> = ({
    sx
}) => {
    const options = useFeedDisplayOptions()

    return <FormControl sx={sx}>
        <RadioGroup
            value={options.displayMode}
            onChange={e => options.setDisplayMode(e.target.value as FeedDisplayMode)}
            row
        >
            <FormControlLabel value={FeedDisplayMode.Threaded} control={<Radio />} label="Threaded" />
            <FormControlLabel value={FeedDisplayMode.Chronological} control={<Radio />} label="Chronological" />
            <FormControlLabel value={FeedDisplayMode.Pending} control={<Radio />} label="Pending only" />
        </RadioGroup>
    </FormControl>
}