import { NetworkingPeriod, Scope } from "@marketpartner/backend-api"
import { DialogTitle } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog"
import { createPopupHook } from "src/common/dialogs/Popup"
import { withNotification } from "src/common/notifications/with-notification"
import { useEvent } from "src/events/event-context"
import { NetworkingPeriodForm } from "src/networking/meetings/periods/NetworkingPeriodForm"


export type EditNetworkingPeriodDialogProps = LockableDialogProps & {
    period: NetworkingPeriod
}

const EditNetworkingPeriodDialog: FC<EditNetworkingPeriodDialogProps> = ({
    period,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!

    const updatePeriodMutation = backend.networkingPeriods.useUpdatePeriod(withNotification({
        errorMessage: "Error updating networking period"
    }))

    return <LockableDialog
        maxWidth="sm"
        fullWidth
        {...props}
    >
        <DialogTitle>Edit networking period</DialogTitle>
        <NetworkingPeriodForm
            actionName="Save"
            onSubmit={data => updatePeriodMutation.mutateAsync([client.id, event.id, period.id, data])}
            period={period}
        />
    </LockableDialog>
}

export const useEditNetworkingPeriodDialog = createPopupHook({
    element: EditNetworkingPeriodDialog,
    scope: Scope.Event,
})