import { Authenticator, Button, Flex, Heading, useAuthenticator, useTheme } from '@aws-amplify/ui-react'
import { ClassNames } from '@emotion/react'
import { I18n } from 'aws-amplify/utils'
import { FC, ReactNode } from 'react'
import { loginFormContainerStyle } from 'src/login/login-form-container-style'
import { LoginContextProvider } from 'src/login/LoginContext'
import { LoginLogo } from 'src/login/LoginLogo'

I18n.putVocabulariesForLanguage('en', {
    'Sign in': 'Log in',
})

export type AmplifyAuthenticatorProps = {
    switchToTokenLogin: () => void
    children: ReactNode
}

export const AmplifyAuthenticator: FC<AmplifyAuthenticatorProps> = ({
    switchToTokenLogin,
    children,
}) => {
    const amplifyTheme = useTheme()

    return <ClassNames>{({ css }) =>
        <Authenticator
            className={css(loginFormContainerStyle)}
            loginMechanisms={['email']}
            components={{
                Header: () => <LoginLogo />,
                SignIn: {
                    Header: () => <Heading
                        level={3}
                        marginTop={amplifyTheme.tokens.space.xl.value}
                        marginLeft={amplifyTheme.tokens.space.xl.value}
                        marginBottom={0}
                    >
                        Log in
                    </Heading>,
                    Footer: () => {
                        const { toForgotPassword } = useAuthenticator()
                        return <Flex 
                            gap={1}
                            justifyContent="center"
                            marginBottom={amplifyTheme.tokens.space.xl.value}
                        >
                            <Button
                                children="Forgot password"
                                onClick={toForgotPassword}
                                variation='link'
                                size='small'
                                style={{ fontWeight: "normal" }}
                            />
                            <Button
                                children="Log in with token"
                                onClick={switchToTokenLogin}
                                variation='link'
                                size='small'
                                style={{ fontWeight: "normal" }}
                            />
                        </Flex>
                    }
                }
            }}
            hideSignUp
            formFields={{
                signIn: {
                    "username": {
                        label: "Email Address"
                    },
                },
            }}
        >
            {({ signOut }) => <LoginContextProvider
                logout={() => signOut?.()}
                children={children}
            />}
        </Authenticator>
    }</ClassNames>
}