import { Spinning } from '@marketpartner/mp-common-mui'
import SyncIcon from '@mui/icons-material/Sync'
import { Button, SxProps } from "@mui/material"
import { FC, useEffect, useState } from 'react'
import { useClient } from 'src/clients/client-context'
import { backend } from 'src/common/api'
import { withNotification } from 'src/common/notifications/with-notification'
import { useEvent } from 'src/events/event-context'

const rateLimitSeconds = 60

export type RecalculateStatsButtonProps = {
    segmentId?: number
    sx?: SxProps
}

export const RecalculateStatsButton: FC<RecalculateStatsButtonProps> = ({
    segmentId,
    sx
}) => {
    const client = useClient()!
    const event = useEvent()!
    const [isRateLimited, setIsRateLimited] = useState(false)
    const calculation = backend.activityStatistics.useCalculateSegmentStatistics(withNotification({
        successMessage: "Statistics updated",
        errorMessage: "Failed to calculate statistics",
    }))

    useEffect(() => {
        if (isRateLimited) {
            const timer = setTimeout(() => {
                setIsRateLimited(false)
            }, rateLimitSeconds * 1000)
            return () => {
                setIsRateLimited(false) // Don't rate limit when changing segments
                clearTimeout(timer)
            }
        }
    }, [isRateLimited, segmentId])

    function recalculate() {
        calculation.mutate([client.id, event.id, segmentId!])
        setIsRateLimited(true)
    }

    const isRefreshing = !segmentId || calculation.isPending
    const isDisabled = isRefreshing || isRateLimited
    return <Button
        onClick={recalculate}
        disabled={isDisabled}
        startIcon={<Spinning isSpinning={isRefreshing} speed={calculation.isPending ? "2s" : "5s"} reverse>
            <SyncIcon />
        </Spinning>}
        color="inherit"
        sx={{
            width: "190px",
            ...sx,
        }}
    >
        {segmentId ? "Recalculate stats" : "Real-time"}
    </Button>
}
