import { TimeField, TimeFieldProps } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { FC, useMemo } from "react";

export type DurationFieldDuration = {
    hours: number
    minutes: number
}

export type DurationFieldProps = Omit<Partial<TimeFieldProps<DateTime>>, "value" | "onChange"> & {
    value: DurationFieldDuration
    onChange: (duration: DurationFieldDuration) => void
}

/**
 * Field for editing durations. Only provides bare minimum functionality 
 * required for agendas (edit hours & minutes only, value always required).
 */
export const DurationField: FC<DurationFieldProps> = ({
    value,
    onChange,
    ...props
}) => {
    const dateValue = useMemo(() => DateTime.fromObject({
        year: 2000,
        month: 1,
        day: 1,
        hour: value.hours,
        minute: value.minutes,
    }), [value])

    const handleChange = (dateValue: DateTime | null) => {
        if (!dateValue) {
            return
        }
        onChange({
            hours: dateValue.hour,
            minutes: dateValue.minute,
        })
    }

    return <TimeField
        value={dateValue}
        onChange={handleChange}
        label="Duration"
        format="H 'h' m 'm'"
        required
        {...props}
        sx={{
            width: 110,
            ...props.sx
        }}
    />
}