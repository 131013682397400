import { Lock, LockOpen, Schedule } from "@mui/icons-material"
import { ActiveStatusDetails } from "src/common/statuses/active-status-details"

export const registrationCategoryStatusDetails: ActiveStatusDetails = {
    pre: {
        label: "Scheduled",
        icon: <Schedule />,
    },
    active: {
        label: "Open",
        icon: <LockOpen />,
    },
    post: {
        label: "Closed",
        icon: <Lock />
    }
}