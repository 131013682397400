import { Registration } from "@marketpartner/backend-api";
import { ParsedRegistration } from "src/registrations/import/pre-process/parse-registrations";
import { RegistrationParseException } from "src/registrations/import/pre-process/registration-parse-exception";

export type MatchedRegistration = {
    parsed: ParsedRegistration
    existing?: Registration
}

export class DuplicateRegistrationMatchException extends RegistrationParseException {
    constructor(
        parsed: ParsedRegistration,
        matches: Registration[]
    ) {
        super(`Found ${matches.length} existing registrations that match line number ${parsed.lineNumber} based on id and email. There should be a maximum of one.`)
    }
}

export class UnknownPrimaryRegistrationIdException extends RegistrationParseException {
    constructor(
        parsedGuest: ParsedRegistration
    ) {
        super(`Primary registration id "${parsedGuest.primaryRegistrationId}" on line ${parsedGuest.lineNumber} does not match the id of any registration in the database or CSV file.`)
    }
}

export const matchRegistrations = (
    parsedRegistrations: ParsedRegistration[],
    existingRegistrations: Registration[],
): MatchedRegistration[] => {
    parsedRegistrations.forEach(parsed => {
        if (parsed.primaryRegistrationId) {
            const newPrimary = parsedRegistrations.find(it => it.id === parsed.primaryRegistrationId)
            const existingPrimary = existingRegistrations.find(it => it.id === parsed.primaryRegistrationId)
            if (!newPrimary && !existingPrimary) {
                throw new UnknownPrimaryRegistrationIdException(parsed)
            }
        }
    })
    
    return parsedRegistrations.map(parsed => ({
        parsed,
        existing: matchRegistration(parsed, existingRegistrations)
    }))
}

const matchRegistration = (
    parsed: ParsedRegistration,
    existingRegistrations: Registration[]
): Registration | undefined => {
    const matches = existingRegistrations.filter(existing => 
        existing.id === parsed.id ||
        (parsed.email && existing.email?.toLowerCase() === parsed.email?.toLowerCase())
    )
    if (matches.length > 1) {
        throw new DuplicateRegistrationMatchException(parsed, matches)
    }
    return matches[0]
}