import AddIcon from '@mui/icons-material/Add';
import { DialogTitle } from "@mui/material";
import { FC } from 'react';
import { useClient } from "src/clients/client-context";
import { backend } from 'src/common/api';
import { DialogButton } from "src/common/dialogs/DialogButton";
import { withNotification } from 'src/common/notifications/with-notification';
import { useEvent } from "src/events/event-context";
import { SegmentForm } from "src/events/segments/SegmentForm";

export const CreateSegmentButton: FC = () => {
    const client = useClient()!
    const event = useEvent()!
    const createMutation = backend.eventSegments.useCreate(withNotification({
        successMessage: "Created segment",
        errorMessage: "Error creating segment",
    }))

    return <DialogButton icon={<AddIcon />} text="Create" color="primary" variant="contained">
        <DialogTitle>
            Create segment
        </DialogTitle>
        <SegmentForm
            actionName="Create"
            onSubmit={segmentDetails => createMutation.mutateAsync([client.id, event.id, segmentDetails])}
        />
    </DialogButton>
}