import { Location, Scope } from "@marketpartner/backend-api";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { ConfirmDeleteDialog } from "src/common/dialogs/ConfirmDeleteDialog";
import { LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { useEvent } from "src/events/event-context";


export type DeleteLocationDialogProps = LockableDialogProps & {
    location: Location
}

const DeleteLocationDialog: FC<DeleteLocationDialogProps> = ({
    location,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!

    const deleteMutation = backend.locations.useDeleteLocation(withNotification({
        errorMessage: "Error deleting location"
    }))

    return <ConfirmDeleteDialog
        itemName={location.name}
        formProps={{
            action: () => deleteMutation.mutateAsync([client.id, event.id, location.id]),
            text: "This will fail if the location is booked for any meetings or sessions.",
        }}
        {...props}
    />
}

export const useDeleteLocationDialog = createPopupHook({
    element: DeleteLocationDialog,
    scope: Scope.Event,
})