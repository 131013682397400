import { EventDetails, Scope } from '@marketpartner/backend-api'
import { MenuItem, MenuProps } from "@mui/material"
import { FC } from 'react'
import { createPopupHook } from 'src/common/dialogs/Popup'
import { ExportColumnDefinition, exportToXlsx } from "src/common/grid/grid-export"
import { LockableMenu } from 'src/common/menus/LockableMenu'
import { useEvent } from "src/events/event-context"
import { CustomExport, doCustomExport } from 'src/registrations/custom-exports/useGetCustomExports'

type ExportMenuProps = MenuProps & {
    name: string
    data: Record<string, unknown>[]
    defaultColumns: ExportColumnDefinition[]
    onClose: () => void
    customExports?: CustomExport[]
}
const ExportMenu: FC<ExportMenuProps> = ({
    name,
    data,
    defaultColumns,
    customExports,
    ...props
}) => {
    const event = useEvent()!
    const handleDefaultExport = () => {
        doExport({ name, event, data, columns: defaultColumns })
    }
    const handleCustomExport = (customExport: CustomExport) => {
        
        doCustomExport({
            customExport,
            defaultColumns,
            data,
            doExport: (data, columns) => doExport({ name, event, data, columns}),
            event
        })
    }

    return <LockableMenu {...props} closeOnClick>
        <MenuItem onClick={handleDefaultExport}>
            Default export 
        </MenuItem>
        { Array.isArray(customExports) && customExports.map(customExport => <MenuItem key={customExport.name} onClick={() => handleCustomExport(customExport)}>
            { customExport.name }
        </MenuItem>)}

    </LockableMenu>
}

export const useExportMenu = createPopupHook({
    element: ExportMenu,
    scope: Scope.Event
})

export type ExportFunctionProps = {
    name: string
    event: EventDetails
    data: Record<string, unknown>[]
    columns: ExportColumnDefinition[]
}
export function doExport({name, event, data, columns}: ExportFunctionProps) {
    name = name.replaceAll(/[^a-zA-Z0-9_\- ]/g, "_")
    const filePrefix = event ? `${event.id}-${name}` : name
    if (data) {
        exportToXlsx(
            filePrefix,
            name,
            data,
            columns,
            { additionalTimezone: event?.timezone }
        )
    }
}