import { ActionUsage, ActivityType, TimedActivityUsage, UsageWithRegistration, WithRegistration } from "@marketpartner/backend-api"
import { lighten, SxProps, Theme, Typography } from "@mui/material"
import { DataGridPro, GridColDef, GridRowClassNameParams, GridSortItem } from "@mui/x-data-grid-pro"
import { DateTime } from "luxon"
import { FC, useMemo, useState } from "react"
import { prettyDuration } from "src/common/time/pretty-duration"
import { formatDiffNow } from "src/common/time/time-utils"
import { compareProperties } from "src/common/utility-functions/sort"
import { DeletedPrincipal } from "src/registrations/DeletedPrincipal"
import { NameAndEmail } from "src/registrations/NameAndEmail"
import { sortKey } from "src/registrations/registration-functions"

const onlineThresholdSeconds = 2 * 60

const regColumn: GridColDef<UsageWithRegistration> = {
    field: "registration",
    headerName: "User",
    renderCell: ({ row }) => row.registration ?
        <NameAndEmail registration={row.registration} /> :
        <DeletedPrincipal principal={row.principal} />,
    sortComparator: (reg1, reg2) => compareProperties(reg1, reg2,
        reg => sortKey(reg)
    ),
    flex: 3,
}

const actionColumns: GridColDef<WithRegistration<ActionUsage>>[] = [
    regColumn as any,
    {
        field: "count",
        headerName: "Count",
        flex: 1.5,
        valueGetter: ({ row }) => row,
        renderCell: ({ row }) => row.count,
        sortComparator: (row1, row2) => compareProperties<WithRegistration<ActionUsage>>(row1, row2,
            row => row.count,
            row => row.registration?.email ?? ""
        )
    },
    {
        field: "lastTime",
        headerName: "Last time",
        flex: 3,
        renderCell: ({ row }) => <>{formatDiffNow(row.lastTime, { capitalize: true })}</>,
    },
]
const defaultActionSort: GridSortItem[] = [{
    field: 'count',
    sort: 'desc'
}]

const timedActivityColumns: GridColDef<WithRegistration<TimedActivityUsage>>[] = [
    regColumn as any,
    {
        field: "totalDurationSeconds",
        headerName: "Total time",
        flex: 1.5,
        renderCell: ({ row }) => prettyDuration(row.totalDurationSeconds)
    },
    {
        field: "lastSeen",
        headerName: "Last session",
        flex: 2,
        renderCell: ({ row }) => {
            return <OnlineIndicator lastSeen={row.lastSeen} />
        },
        valueGetter: ({ row }) => row,
        sortComparator: (row1, row2) => compareProperties<WithRegistration<TimedActivityUsage>>(row1, row2,
            row => isOnline(row.lastSeen), // Display online users at top
            row => isOnline(row.lastSeen) ?
                row.registration?.email ?? "" : // Sort online users by email (deleted registrations last)
                row.lastSeen.toMillis() // Sort offline users by last seen
        ),
    },
]
const defaultTimedActivitySort: GridSortItem[] = [{
    field: 'lastSeen',
    sort: 'desc',
}]

type RowType = WithRegistration<ActionUsage | TimedActivityUsage>
const getRowId = (row: RowType) => row.principal
const getRowClassName = ({ row }: GridRowClassNameParams<RowType>) =>
    "lastSeen" in row && isOnline(row.lastSeen) ?
        "online-row" :
        ""
const sx: SxProps<Theme> = {
    "& .online-row": {
        backgroundColor: theme => lighten(theme.palette.success.main, 0.8),
        '&:hover': {
            backgroundColor: theme => `${lighten(theme.palette.success.main, 0.7)} !important`,
        },
    }
}

export type ActivityUsageGridProps = {
    activityType: ActivityType
    usage: RowType[]
}

export const ActivityUsageGrid: FC<ActivityUsageGridProps> = ({
    activityType,
    usage
}) => {
    const [sortModel, setSortModel] = useState(activityType === "action" ? defaultActionSort : defaultTimedActivitySort)

    const columns = useMemo(
        () => activityType === ActivityType.action ?
            actionColumns :
            timedActivityColumns,
        [activityType]
    )

    return <DataGridPro
        getRowId={getRowId}
        columns={columns as any}
        rows={usage}
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        getRowClassName={getRowClassName}
        autoHeight
        disableRowSelectionOnClick
        sx={sx}
    />
}

type OnlineIndicatorProps = {
    lastSeen: DateTime
}

const OnlineIndicator: FC<OnlineIndicatorProps> = ({
    lastSeen
}) => {
    if (isOnline(lastSeen)) {
        return <Typography variant="body2" fontWeight="bold" color="green">
            Now
        </Typography>
    }
    return <>{formatDiffNow(lastSeen, { capitalize: true })}</>
}

function isOnline(lastSeen: DateTime) {
    if (!lastSeen) {
        return false
    }
    return lastSeen.diffNow("seconds").negate().as("seconds") < onlineThresholdSeconds
}
