import { Session } from "@marketpartner/backend-api"
import { ClearableTextField } from "@marketpartner/mp-common-mui"
import { FormLabel, Grid, TextField, TextFieldProps } from "@mui/material"
import { DateTime } from "luxon"
import { FC, useState } from "react"
import { DialogForm, ExtendableDialogFormProps } from "src/common/dialogs/DialogForm"
import { TagEditor } from "src/common/form-inputs/TagEditor"
import { LuxonDayTimeRangePicker } from "src/common/time/LuxonDayTimeRangePicker"
import { LocationSelector } from "src/content/locations/LocationSelector"
import { useEvent } from "src/events/event-context"


export type SessionFormData = {
    name: string
    subtitle: string
    synopsisMd: string
    start: DateTime
    end: DateTime
    locationId: string | null
    tags: string[]
}

export type SessionFormProps = ExtendableDialogFormProps<SessionFormData> & {
    session?: Session
    suggestedStart?: DateTime
    suggestedEnd?: DateTime
}

export const SessionForm: FC<SessionFormProps> = ({
    session,
    suggestedStart,
    suggestedEnd,
    ...props
}) => {
    const event = useEvent()!
    const defaultStart = session?.start ?? suggestedStart ?? event.startTime
    const defaultEnd = session?.end ?? suggestedEnd ?? defaultStart.plus({ hours: 1 })

    const [name, setName] = useState(session?.name ?? "")
    const [subtitle, setSubtitle] = useState(session?.subtitle ?? "")
    const [synopsisMd, setSynopsisMd] = useState(session?.synopsisMd ?? "")
    const [start, setStart] = useState(defaultStart)
    const [end, setEnd] = useState(defaultEnd)
    const [locationId, setLocationId] = useState(session?.locationId ?? null)
    const [tags, setTags] = useState(session?.tags ?? [])

    return <DialogForm
        formData={{
            name,
            subtitle,
            synopsisMd,
            start,
            end,
            locationId,
            tags,
        }}
        {...props}
        isValid={name.length > 0 && start <= end}
    >
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
                <NameField value={name} onChange={e => setName(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
                <ClearableTextField label="Subtitle" value={subtitle} onChange={e => setSubtitle(e.target.value)} onClear={() => setSubtitle("")} fullWidth />
            </Grid>
            <Grid item xs={12}>
                <LuxonDayTimeRangePicker
                    start={start}
                    end={end}
                    onChangeStart={setStart}
                    onChangeEnd={setEnd}
                    timezone={event.timezone}
                />
            </Grid>
            <Grid item xs={12}>
                <ClearableTextField label="Synopsis (markdown)" value={synopsisMd} onChange={e => setSynopsisMd(e.target.value)} onClear={() => setSynopsisMd("")} fullWidth />
            </Grid>
            <Grid item xs={12}>
                <LocationSelector label="Location" selectedId={locationId} onSelectId={setLocationId} fullWidth />
            </Grid>
            <Grid item xs={12}>
                <FormLabel>
                    Tags
                </FormLabel>
                <TagEditor tags={tags} onChange={tags => setTags(tags)} />
            </Grid>
        </Grid>
    </DialogForm>
}

const NameField = (props: Partial<TextFieldProps>) => <TextField
    {...props}
    label="Name"
    fullWidth
    required
/>