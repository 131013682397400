import { Client } from '@marketpartner/backend-api'
import { Box, Link } from '@mui/material'
import { GridColDef, GridSortItem } from '@mui/x-data-grid-pro'
import { FC, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { MigrationButton } from 'src/clients/migration/MigrationButton'
import { backend } from 'src/common/api'
import { QueryDataGrid, QueryDataGridProps } from 'src/common/grid/QueryDataGrid'
import { link } from 'src/common/routing/routes'

export type ClientsGridProps = Partial<QueryDataGridProps<Client>>

const columns: GridColDef<Client>[] = [
    { field: "id", headerName: "Client id", flex: 3, minWidth: 140 },
    {
        field: "name",
        headerName: "Name",
        flex: 4,
        minWidth: 180,
        renderCell: ({ row }) => <Link
            component={RouterLink}
            to={link.toClient(row.id)}
        >
            {row.name}
        </Link>
    },
    {
        field: "migratedTo",
        headerName: "Latest DB migration script",
        flex: 4,
        minWidth: 400,
        renderCell: ({ row }) => <Box display="inline-block">
            <MigrationButton client={row} />
        </Box>
    }
]

export const ClientsGrid: FC<ClientsGridProps> = ({
    ...props
}) => {
    const clientsQuery = backend.clients.useGetAll([])

    const [sortModel, setSortModel] = useState<GridSortItem[]>([
        { field: "name", sort: "asc" }
    ])

    return <QueryDataGrid
        queryOrRows={clientsQuery}
        columns={columns}
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        disableRowSelectionOnClick
        {...props}
    />
}