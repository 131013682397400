import { FormElementConfig, FormElementMetadata } from "@marketpartner/backend-api"
import _ from "lodash"
import { ReactNode, useMemo } from "react"
import { duplicateElementCheck } from "src/forms/elements/checks/duplicate-element-check"

export type ElementIssueLevel = "error" | "warning"

export type FormElementIssue = {
    renderIssueContent: () => ReactNode
    level?: ElementIssueLevel
    elementIdxs?: number[]
}

export type FormElementCheck<Metadata extends FormElementMetadata = FormElementMetadata> = (
    elements: FormElementConfig<Metadata>[]
) => FormElementIssue | FormElementIssue[] | undefined | void

export const defaultElementChecks: FormElementCheck<FormElementMetadata>[] = [
    duplicateElementCheck,
]

export const useFormElementChecks = <Metadata extends FormElementMetadata>(
    elements: FormElementConfig<Metadata>[] | undefined,
    checks: FormElementCheck<Metadata>[],
): FormElementIssue[] => {
    const allChecks = useMemo(() => ([
        ...defaultElementChecks,
        ...checks,
    ]), [checks])

    const issues = useMemo(() => _(allChecks)
        .flatMap(check => {
            const issues = check(elements ?? [])
            return !issues ? undefined :
                Array.isArray(issues) ? issues :
                    [issues]
        })
        .compact()
        .value(),
        [allChecks, elements]
    )

    if (!elements) {
        return []
    }

    return issues
}