import { Checkbox, FormControlLabel } from "@mui/material"
import { FC } from "react"


export type RequiredFieldProps = {
    required: boolean
    setRequired: (required: boolean) => void
}

export const RequiredField: FC<RequiredFieldProps> = ({
    required,
    setRequired,
}) => {
    return <FormControlLabel
        label="Required"
        control={<Checkbox 
            checked={required}
            onChange={() => setRequired(!required)}
        />}
    />
}