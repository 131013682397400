import { AppTemplateDeployment } from "@marketpartner/backend-api"
import { useDynamicFormState } from "@marketpartner/mp-common-react"
import { Box, FormHelperText } from "@mui/material"
import { FC, useState } from "react"
import { AppDeploymentFormControls } from "src/app-templates/deployments/AppDeploymentFormControls"
import { AppTemplateSelector } from "src/app-templates/templates/AppTemplateSelector"
import { backend } from "src/common/api"
import { DialogForm } from "src/common/dialogs/DialogForm"
import { withNotification } from "src/common/notifications/with-notification"


export type EditAppDeploymentFormProps = {
    deployment: AppTemplateDeployment
}

export const EditAppDeploymentForm: FC<EditAppDeploymentFormProps> = ({
    deployment
}) => {
    const [appTemplateId, setAppTemplateId] = useState(deployment.appTemplateId)
    const [appTemplateVersion, setAppTemplateVersion] = useState<string | null>(deployment.appTemplateVersion)
    const [name, setName] = useState(deployment.name)
    const [domainName, setDomainName] = useState<string | null>(deployment.domainName)
    const [path, setPath] = useState(deployment.path)
    const [editPermission, setEditPermission] = useState(deployment.editPermission)
    const [adminParameters, setAdminParameters] = useState(deployment.adminParameters)

    const metadataRequest = backend.appTemplates.useGetTemplateMetadata([appTemplateId, appTemplateVersion!], {
        enabled: Boolean(appTemplateVersion)
    })

    const handleSetAppTemplateId = (id: string) => {
        setAppTemplateId(id)
        setAppTemplateVersion(null)
    }

    const parametersFormState = useDynamicFormState({
        elements: metadataRequest.data?.parameterList ?? [],
        requirement: "complete",
        initialValues: deployment.parameters
    })

    const updateEventDeploymentMutation = backend.appTemplateDeployments.useUpdateEventDeployment(withNotification({
        successMessage: "Updated app",
        errorMessage: "Error updating app",
    }))

    const scopeSupported = !metadataRequest.data || metadataRequest.data.supportedScopes[deployment.scope.type]

    return <DialogForm
        actionName="Save"
        onSubmit={() => updateEventDeploymentMutation.mutateAsync([
            deployment.scope.clientId!,
            deployment.scope.eventId!,
            deployment.id,
            {
                appTemplateId,
                appTemplateVersion: appTemplateVersion!,
                name,
                domainName: domainName!,
                path,
                editPermission,
                adminParameters,
                parameters: parametersFormState.values
            }
        ])}
        formData={{}}
        isValid={Boolean(scopeSupported && domainName && appTemplateVersion && parametersFormState.invalidFields.length === 0)}
    >
        <Box mb={2}>
            <AppTemplateSelector
                templateId={appTemplateId}
                onChangeTemplateId={id => id && handleSetAppTemplateId(id)}
                version={appTemplateVersion}
                onChangeVersion={version => version && setAppTemplateVersion(version)}
            />
            {!scopeSupported && <FormHelperText error>
                Template does not support deployment to {deployment.scope.type} scope
            </FormHelperText>}
        </Box>
        <AppDeploymentFormControls
            clientId={deployment.scope.clientId}
            eventId={deployment.scope.eventId}
            id={deployment.id}
            name={name}
            onChangeName={setName}
            domainName={domainName}
            onChangeDomainName={setDomainName}
            path={path}
            onChangePath={setPath}
            editPermission={editPermission}
            onChangeEditPermission={setEditPermission}
            adminParameters={adminParameters}
            onChangeAdminParameters={setAdminParameters}
            parametersFormState={parametersFormState}
        />
    </DialogForm>
}