import { commonContentFields, ContentType, FormElementConfig, standardContentFields } from "@marketpartner/backend-api"
import { Grid, TextField, Typography } from "@mui/material"
import { FC, useState } from "react"
import { DialogForm, ExtendableDialogFormProps } from "src/common/dialogs/DialogForm"
import { IdField } from "src/common/form-inputs/IdField"
import { PluralTextField } from "src/common/form-inputs/PluralTextField"
import { FormElementSourceType } from "src/forms/elements/copying/useFormElementSources"
import { EditableFormElements } from "src/forms/elements/EditableFormElements"


export type ContentTypeFormData = {
    id: string
    name: string
    pluralName: string
    formElements: FormElementConfig[]
}

export type ContentTypeFormProps = ExtendableDialogFormProps<ContentTypeFormData> & {
    type?: ContentType
}

export const ContentTypeForm: FC<ContentTypeFormProps> = ({
    type,
    ...props
}) => {
    const [id, setId] = useState(type?.id ?? "")
    const [name, setName] = useState(type?.name ?? "")
    const [pluralName, setPluralName] = useState(type?.pluralName ?? "")
    const [formElements, setFormElements] = useState(type?.formElements ?? [...standardContentFields])

    return <DialogForm
        formData={{
            id,
            name,
            pluralName,
            formElements,
        }}
        isValid={Boolean(id && name && pluralName)}
        {...props}
    >
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <TextField
                    label="Name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    fullWidth
                    required
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <PluralTextField
                    sourceValue={name}
                    value={pluralName}
                    onChange={setPluralName}
                    required
                    fullWidth
                />
            </Grid>
            {!type && <Grid item xs={12}>
                <IdField
                    nameValue={name}
                    idValue={id.replace(/^Custom:/, "")}
                    onChangeId={id => setId(`Custom:${id}`)}
                    maxLength={255}
                    inputProps={{
                        startAdornment: <Typography color="text.secondary">Custom:</Typography>,
                    }}
                />
            </Grid>}
            <Grid item xs={12}>
                <Typography variant="h6">Form elements</Typography>
                <EditableFormElements
                    elements={formElements}
                    onChangeElements={setFormElements}
                    canEdit={true}
                    defaultSourceType={FormElementSourceType.ContentTypes}
                    commonElements={commonContentFields}
                    sizing="fit-content"
                />
            </Grid>
        </Grid>
    </DialogForm>
}