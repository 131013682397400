import { Scope } from "@marketpartner/backend-api";
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { ContentTypeForm } from "src/content/types/ContentTypeForm";
import { useEvent } from "src/events/event-context";


export type CreateContentTypeDialogProps = LockableDialogProps

const CreateContentTypeDialog: FC<CreateContentTypeDialogProps> = (props) => {
    const client = useClient()!
    const event = useEvent()!
    const createMutation = backend.contentTypes.useCreateType(withNotification({
        errorMessage: "Error creating content type",
    }))

    return <LockableDialog
        maxWidth="md"
        fullWidth
        {...props}
    >
        <DialogTitle>Create content type</DialogTitle>
        <ContentTypeForm
            actionName="Create"
            onSubmit={contentType => createMutation.mutateAsync([client.id, event.id, contentType])}
        />
    </LockableDialog>
}

export const useCreateContentTypeDialog = createPopupHook({
    element: CreateContentTypeDialog,
    scope: Scope.Event,
})
