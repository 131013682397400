import { Session } from "@marketpartner/backend-api"
import { Stack, SxProps, Typography } from "@mui/material"
import { DateTime } from "luxon"
import { FC } from "react"
import { TagList } from "src/common/form-inputs/TagList"
import { LocationName } from "src/content/locations/LocationName"


export type SessionDetailsProps = {
    session: Session
    sx?: SxProps
}

export const SessionDetails: FC<SessionDetailsProps> = ({
    session,
    sx
}) => {
    return <Stack sx={sx} spacing={1}>
        <Stack spacing={1} sx={{ p: 2 }}>
            <TimeAndLocation session={session} />
            {session.subtitle && <Typography variant="h4">{session.subtitle}</Typography>}
            {session.synopsisHtml && <Typography
                component="div"
                dangerouslySetInnerHTML={{ __html: session.synopsisHtml }}
            />}
            <SessionTags tags={session.tags} />
        </Stack>
    </Stack>
}

type TimeAndLocationProps = {
    session: Session
}

const TimeAndLocation: FC<TimeAndLocationProps> = ({
    session
}) => {
    return <Typography color="text.secondary">
        {day(session.start)} {time(session.start)} - {time(session.end)}
        <LocationName prefix=" @ " locationId={session.locationId}/>
    </Typography>
}

const day = (dateTime: DateTime) => dateTime.toLocaleString(DateTime.DATE_FULL)
const time = (dateTime: DateTime) => dateTime.toLocaleString(DateTime.TIME_24_SIMPLE)


type SessionTagsProps = {
    tags: string[]
}

const SessionTags: FC<SessionTagsProps> = ({
    tags
}) => {
    if (!tags.length) {
        return <></>
    }

    return <Stack>
        <Typography variant="h6">Tags</Typography>
        <TagList tags={tags} />
    </Stack>
}