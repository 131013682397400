import { PollQuestion, Scope } from "@marketpartner/backend-api"
import { DialogTitle } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog"
import { createPopupHook } from "src/common/dialogs/Popup"
import { useEvent } from "src/events/event-context"
import { PollQuestionForm } from "src/polls/questions/PollQuestionForm"

type EditPollQuestionDialogProps = LockableDialogProps & {
    pollQuestion: PollQuestion
}
export const EditPollQuestionDialog: FC<EditPollQuestionDialogProps> = ({
    pollQuestion,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const updateMutation = backend.pollQuestions.useUpdate({
        onSuccess: () => {
            props.onClose()
        }
    })
    return <LockableDialog maxWidth="sm" {...props}>
        <DialogTitle>Edit {pollQuestion.name}</DialogTitle>
        <PollQuestionForm
            actionName="Save"
            onSubmit={pollQuestionDetails => updateMutation.mutateAsync([client.id, event.id, pollQuestion.pollId, pollQuestion.id, pollQuestionDetails])}
            pollQuestion={pollQuestion}
        />
    </LockableDialog>
}

export const useEditPollQuestionDialog = createPopupHook({
    element: EditPollQuestionDialog,
    scope: Scope.Event,
})