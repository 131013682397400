import { EventPartnerInstance } from "@marketpartner/backend-api"
import { Grid, TextField } from "@mui/material"
import { FC, useState } from "react"
import { DialogForm, ExtendableDialogFormProps } from "src/common/dialogs/DialogForm"

export type EventPartnerInstanceFormData = {
    id: string
    appToken: string
    accessToken: string
}

export type EventPartnerInstanceFormProps = ExtendableDialogFormProps<EventPartnerInstanceFormData> & {
    instance?: EventPartnerInstance
}

export const EventPartnerInstanceForm: FC<EventPartnerInstanceFormProps> = ({
    instance,
    ...props
}) => {
    const [id, setId] = useState(instance?.id ?? "")
    const [appToken, setAppToken] = useState(instance?.appToken ?? "")
    const [accessToken, setAccessToken] = useState(instance?.accessToken ?? "")

    return <DialogForm
        isValid={Boolean(id && appToken && accessToken)}
        formData={{
            id,
            appToken,
            accessToken
        }}
        {...props}
    >
        <Grid container spacing={2}>
            {!instance && <Grid item xs={12}>
                <TextField
                    label="Id"
                    value={id}
                    onChange={e => setId(e.target.value)}
                    InputProps={{
                        endAdornment: ".eventpartner.tech"
                    }}
                    fullWidth
                    required
                />
            </Grid>}
            <Grid item xs={12}>
                <TextField
                    label="App token"
                    value={appToken}
                    onChange={e => setAppToken(e.target.value)}
                    fullWidth
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Access token"
                    value={accessToken}
                    onChange={e => setAccessToken(e.target.value)}
                    fullWidth
                    required
                />
            </Grid>
        </Grid>
    </DialogForm>
}
