import { ComponentType, createContext, ReactNode, useCallback, useContext } from "react"
import { useImmer } from "use-immer"

export type LocalPopupProps = {
    open: boolean
    onClose: () => void
}

export type UseLocalPopup<Props extends LocalPopupProps> = {
    open: (params: Omit<Props, keyof LocalPopupProps>) => void
}

export type CreateLocalPopupOptions<Props extends LocalPopupProps> = {
    element: ComponentType<Props>
}

type LocalPopupContextValue = {
    register: <Props extends LocalPopupProps>(
        id: string,
        options: CreateLocalPopupOptions<Props>
    ) => UseLocalPopup<Props>
}

const context = createContext<LocalPopupContextValue>({
    register: () => {
        throw new Error("Can't register popup as there is no LocalPopupContext defined to render it.")
    }
})

type LocalPopupOptionsAndProps<Props extends LocalPopupProps> = {
    options: CreateLocalPopupOptions<Props>
    props: Props
}

export type LocalPopupContextProps = {
    children: ReactNode
}

/**
 * Localized store of popups. This allows popups to be opened programatically,
 * and rendered higher up the react tree in a custom location 
 * (e.g. inside any required contexts that might be required by the popup content).
 */
export const LocalPopupContext = ({
    children
}: LocalPopupContextProps) => {
    const [popupStates, setPopupStates] = useImmer<Record<string, LocalPopupOptionsAndProps<any>>>({})

    const register = useCallback<LocalPopupContextValue["register"]>(
        (id, options) => ({
            open: props => {
                setPopupStates(states => {
                    states[id] = {
                        options,
                        props: {
                            ...props,
                            open: true,
                            onClose: () => setPopupStates(prevStates => {
                                prevStates[id].props.open = false
                            })
                        }
                    }
                })
            },
        }),
        [setPopupStates]
    )

    return <context.Provider
        value={{
            register
        }}
        children={<>
            {Object.entries(popupStates).map(([id, { options, props }]) => {
                return props && <options.element key={id} {...props} />
            })}
            {children}
        </>}
    />
}

/**
 * Create a shared popup that can be opened programatically. This is similar to createPopupHook, but
 * the popup is rendered in a location of your choice. This allows react contexts to be available
 * inside the popup. If you don't need to use contexts, use createPopupHook instead as it is 
 * simpler to use.
 * 
 * Popups are rendered by <LocalPopupContext> which must be placed in an appropriate location
 * (i.e. inside any contexts that may be required by the popup content).
 * 
 * Please read the caveats on the createPopupHook documentation before using this function.
 */
export const createLocalPopupHook = <Props extends LocalPopupProps>(
    options: CreateLocalPopupOptions<Props>
): () => UseLocalPopup<Props> => {
    const id = Math.random().toString(36).substring(7)

    return () => useContext(context).register(id, options)
}

