import { RegistrationCategory, Scope } from "@marketpartner/backend-api";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { ConfirmDeleteDialog } from "src/common/dialogs/ConfirmDeleteDialog";
import { LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { useEvent } from "src/events/event-context";


export type DeleteRegistrationCategoryDialogProps = LockableDialogProps & {
    category: RegistrationCategory
}

const DeleteRegistrationCategoryDialog: FC<DeleteRegistrationCategoryDialogProps> = ({
    category,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const deleteMutation = backend.registrationCategories.useDelete()

    return <ConfirmDeleteDialog
        itemName={`${category.name} category`}
        formProps={{
            text: "This will not delete any registration data",
            action: () => deleteMutation.mutateAsync([client.id, event.id, category.id])
        }}
        {...props}
    />
}

export const useDeleteRegistrationCategoryDialog = createPopupHook({
    element: DeleteRegistrationCategoryDialog,
    scope: Scope.Event,
})