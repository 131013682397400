import { Paper } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { Bar } from "src/common/layout/Bar"
import { BarAndFlex } from "src/common/layout/BarAndFlex"
import { Spacer } from "src/common/layout/Spacer"
import { Loading } from "src/common/loading/Loading"
import { fullSize } from "src/common/styles"
import { useEvent } from "src/events/event-context"
import { EditEventFeedButton } from "src/feeds/EditEventFeedButton"
import { EventFeedAdmin } from "src/feeds/EventFeedAdmin"
import { EventFeedDetails } from "src/feeds/EventFeedDetails"
import { NoEventFeed } from "src/feeds/NoEventFeed"

export const EventFeed: FC = () => {
    const client = useClient()!
    const event = useEvent()!
    const feedQuery = backend.feeds.useGetEventFeed([client.id, event.id], {
        retry: false,
    })

    return <Paper sx={fullSize}>
        <Loading
            displaySpinnerOnReload // Try and fix crash when re-selecting tab
            request={feedQuery}
            renderErrorCode={code => code === 404 && <NoEventFeed />}
            render={feed => <BarAndFlex barContent={<Bar p={2} pb={0}>
                <EventFeedDetails feed={feedQuery.data} />
                <Spacer />
                <EditEventFeedButton feed={feed} />
            </Bar>}>
                <EventFeedAdmin feed={feed} />
            </BarAndFlex>}
        />
    </Paper>
}

