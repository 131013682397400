import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import { Dialog, DialogProps, IconButton } from "@mui/material"
import { createContext, FC, useContext, useEffect, useState } from "react"
import { useBreakpointDown } from 'src/common/hooks/useBreakpoint'

export type DialogContext = {
    close: (force?: boolean) => unknown
    enableClose: () => unknown
    disableClose: () => unknown
    closeDisabled: boolean
    fullScreen: boolean
    setFullScreen: (fullScreen: boolean) => unknown
}

const context = createContext<DialogContext>(undefined!)

export const useDialogContext = () => useContext(context)

export type LockableDialogProps = Omit<DialogProps, "onClose"> & {
    onClose: () => unknown
    allowFullScreen?: boolean
    disableClickaway?: boolean
}

// Dialog that allows child elements (e.g. submittable form) to prevent the dialog from being closed
// Provides a context that allows enabling/disabling closing, and direct access to the close function (for cancel buttons or for on submit etc.)
export const LockableDialog: FC<LockableDialogProps> = ({
    open,
    onClose,
    children,
    allowFullScreen = false,
    disableClickaway = false,
    ...dialogProps
}) => {
    const [closeDisabled, setCloseDisabled] = useState(false)
    const [fullScreen, setFullScreen] = useState(false)
    const smDown = useBreakpointDown("sm")

    const isFullscreen = smDown || fullScreen

    useEffect(() => {
        if (open) {
            setFullScreen(false)
        }
    }, [open])

    function enableClose() {
        setCloseDisabled(false)
    }

    function disableClose() {
        setCloseDisabled(true)
    }

    function close(force = false) {
        if (force || !closeDisabled) {
            onClose?.()
        }
    }

    return <Dialog maxWidth="lg" open={open} onClose={() => !disableClickaway && close()} fullScreen={isFullscreen} {...dialogProps}>
        {allowFullScreen && !smDown && <FullScreenButton fullScreen={fullScreen} setFullScreen={setFullScreen}/>}
        <context.Provider value={{
            close,
            enableClose,
            disableClose,
            closeDisabled,
            fullScreen,
            setFullScreen
        }}>
            {children}
        </context.Provider>
    </Dialog>
}

type FullScreenButtonProps = {
    fullScreen: boolean
    setFullScreen: (fullScreen: boolean) => unknown
}

const FullScreenButton: FC<FullScreenButtonProps> = ({fullScreen, setFullScreen}) => {
    return <IconButton
        onClick={() => setFullScreen(!fullScreen)}
        sx={{ position: "absolute", right: 16, top: 16}}
    >
        {fullScreen ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
    </IconButton>
}