import { ContentEntity } from "@marketpartner/backend-api"
import { ExternalLink } from "@marketpartner/mp-common-mui"
import { Edit } from "@mui/icons-material"
import { Stack, SxProps, Typography } from "@mui/material"
import IconButton from "@mui/material/IconButton/IconButton"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { Bar } from "src/common/layout/Bar"
import { Loading } from "src/common/loading/Loading"
import { useChangeContentEntityCategoryDialog } from "src/content/entities/ChangeContentEntityCategoryDialog"
import { ContentEntityAvatar } from "src/content/entities/ContentEntityAvatar"
import { useEvent } from "src/events/event-context"
import { FormDataTable } from "src/forms/elements/FormDataTable"

export type ContentEntityDetailsProps = {
    entity: ContentEntity
    sx?: SxProps
}

export const ContentEntityDetails: FC<ContentEntityDetailsProps> = ({
    entity,
    sx
}) => {
    const client = useClient()!
    const event = useEvent()!

    const typeQuery = backend.contentTypes.useGetTypes([client.id, event.id], {
        select: types => types.find(it => it.id === entity.typeId)!
    })
    const categoryQuery = backend.contentCategories.useGetCategories([client.id, event.id], {
        select: categories => categories.find(it => it.id === entity.categoryId)!
    })
    const subCategory = categoryQuery.data?.subCategories?.find(it => it.id === entity.subCategoryId)

    const changeCategoryDialog = useChangeContentEntityCategoryDialog()

    const {
        title,
        image,
        url,
        ...otherFields
    } = entity.fields

    return <Loading
        requests={[typeQuery, categoryQuery] as const}
        sx={sx}
        render={([type, category]) => <Stack>
            <Bar spacing={2} p={2}>
                <ContentEntityAvatar entity={entity} />
                <Stack>
                    <Typography variant="h5">{entity.fields.title} ({type.name})</Typography>
                    {url && <ExternalLink href={url as string} children={url as string} />}
                </Stack>
                <Stack sx={{ pl: 4 }}>
                    <Bar spacing={1}>
                        <Typography variant="h6">
                            Category
                        </Typography>
                        <IconButton
                            children={<Edit />}
                            onClick={() => changeCategoryDialog.open({ entity })}
                        />
                    </Bar>
                    <Typography>
                        <b>Category:</b> {category.name}
                    </Typography>
                    {subCategory && <Typography>
                        <b>Subcategory:</b> {subCategory.name}
                    </Typography>}
                </Stack>
            </Bar>
            {Object.keys(otherFields).length > 0 && <Stack>
                <Typography variant="h6" ml={2}>Other properties</Typography>
                <FormDataTable data={otherFields} elements={type.formElements} />
            </Stack>}
        </Stack>}
    />
}