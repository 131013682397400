import { invert } from "@marketpartner/mp-common"
import { ExtractedFormField } from "src/registrations/import/pre-process/extract-form-fields"
import { CoreColumn, identifyCoreColumns } from "src/registrations/import/pre-process/identify-core-columns"
import { FormFieldColumnMatches, identifyFormFieldColumns } from "src/registrations/import/pre-process/identify-form-field-columns"
import { identifyIgnoredColumns } from "src/registrations/import/pre-process/identify-ignored-columns"

export type IgnoredColumnMatch = {
    type: "ignored"
}

export type FormFieldColumnMatch = {
    type: "field"
    formField: ExtractedFormField
}

export type CoreColumnMatch = {
    type: "coreColumn"
    coreColumn: CoreColumn
}

export type ColumnMatch = IgnoredColumnMatch | FormFieldColumnMatch | CoreColumnMatch

export type ColumnMatches = Record<string, ColumnMatch | null>

export const autoMatchColumns = (
    csvColumns: string[],
    formFields: ExtractedFormField[]
): ColumnMatches => {
    const fieldColumns = identifyFormFieldColumns(csvColumns, formFields)
    const coreColumns = identifyCoreColumns(csvColumns)
    const ignoredColumns = identifyIgnoredColumns(csvColumns)

    return Object.fromEntries(csvColumns.map(csvColumn => [
        csvColumn,
        matchColumn(csvColumn, fieldColumns, invert(coreColumns), ignoredColumns)
    ]))
}

const matchColumn = (
    csvColumn: string,
    fieldColumns: FormFieldColumnMatches,
    coreColumns: Partial<Record<string, CoreColumn>>,
    ignoreColumns: string[],
): ColumnMatch | null => {
    const formField = fieldColumns[csvColumn]
    if (formField) {
        return {
            type: "field",
            formField,
        }
    }

    const coreColumn = coreColumns[csvColumn]
    if (coreColumn) {
        return {
            type: "coreColumn",
            coreColumn,
        }
    }

    if (ignoreColumns.includes(csvColumn)) {
        return {
            type: "ignored",
        }
    }
    
    return null
}