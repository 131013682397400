import { EventDetails, RegistrationCategory } from "@marketpartner/backend-api"
import { Checkbox, FormControlLabel, Grid } from "@mui/material"
import { FC, useState } from "react"
import { EventSelector } from "src/events/EventSelector"
import { RegistrationCategoryCompatibility } from "src/registrations/actions/copy/registration-category-compatibility"
import { RegistrationCategoryCompatibilityIssues } from "src/registrations/actions/copy/RegistrationCategoryCompatibility"
import { RegistrationCategorySelector } from "src/registrations/configuration/categories/RegistrationCategorySelector"


export type RegistrationTargetCategorySelectorProps = {
    copyGuests: boolean
    onChangeCopyGuests: (copyGuests: boolean) => void
    onChangeSourceEvent: (event: EventDetails) => void
    onChangeSourceCategory: (category: RegistrationCategory) => void
    onChangeTargetCategory: (category: RegistrationCategory) => void
    compatibility: RegistrationCategoryCompatibility
}

export const RegistrationTargetCategorySelector: FC<RegistrationTargetCategorySelectorProps> = ({
    copyGuests,
    onChangeCopyGuests,
    onChangeSourceEvent,
    onChangeSourceCategory,
    onChangeTargetCategory,
    compatibility,
}) => {
    const [sourceEventId, setSourceEventId] = useState<string | null>(null)
    const [sourceCategoryId, setSourceCategoryId] = useState<string | null>(null)
    const [targetCategoryId, setTargetCategoryId] = useState<string | null>(null)

    return <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
            <EventSelector
                selectedId={sourceEventId}
                onSelectId={setSourceEventId}
                onSelectLoadedItem={event => event && onChangeSourceEvent(event)}
                required
                omitSelf
                fullWidth
            />
        </Grid>
        <Grid item xs={12} sm={6} />
        {sourceEventId && <>
            <Grid item xs={12} sm={6}>
                <RegistrationCategorySelector
                    eventId={sourceEventId}
                    categoryId={sourceCategoryId}
                    onSelectCategoryId={setSourceCategoryId}
                    onSelectLoadedItem={category => category && onChangeSourceCategory(category)}
                    label="Source category"
                    error={compatibility.level === "error"}
                    required
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <RegistrationCategorySelector
                    categoryId={targetCategoryId}
                    onSelectCategoryId={setTargetCategoryId}
                    onSelectLoadedItem={category => category && onChangeTargetCategory(category)}
                    label="Target category"
                    error={compatibility.level === "error"}
                    required
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    label="Copy guests"
                    control={<Checkbox
                        checked={copyGuests}
                        onChange={e => onChangeCopyGuests(e.target.checked)}
                    />}
                />
            </Grid>
        </>}

        <Grid item xs={12}>
            <RegistrationCategoryCompatibilityIssues
                enabled={Boolean(sourceCategoryId && targetCategoryId)}
                includeGuests={copyGuests}
                compatibility={compatibility}
            />
        </Grid>
    </Grid>
}