import { AppTemplateDeploymentPermission, ClientAppTemplate } from "@marketpartner/backend-api"
import { GridColDef } from "@mui/x-data-grid-pro"
import { FC } from "react"
import { DeleteClientAppTemplateButton } from "src/app-templates/client-templates/DeleteClientAppTemplateButton"
import { EditClientAppTemplateButton } from "src/app-templates/client-templates/EditClientAppTemplateButton"
import { backend } from "src/common/api"
import { QueryDataGrid, QueryDataGridProps } from "src/common/grid/QueryDataGrid"
import { Bar } from "src/common/layout/Bar"


export type ClientAppTemplatesGridProps = Partial<QueryDataGridProps<ClientAppTemplate>> & {
    clientId?: string
}

const columns: GridColDef<ClientAppTemplate>[] = [{
    field: "clientId",
    headerName: "Client id",
    width: 150,
}, {
    field: "name",
    headerName: "Name",
    width: 200,
}, {
    field: "appTemplateId",
    headerName: "Template",
    width: 200,
}, {
    field: "appTemplateVersion",
    headerName: "Version",
    width: 75,
}, {
    field: "deploymentPermission",
    headerName: "Deployment",
    renderCell: ({ value }) => value === AppTemplateDeploymentPermission.AdminOnly ? "Admin only" : "Client",
    width: 130,
}, {
    field: "actions",
    headerName: "Actions",
    renderCell: ({ row }) => <Actions template={row} />
}, {
    field: "id",
    headerName: "Id",
    width: 200,
}]

export const ClientAppTemplatesGrid: FC<ClientAppTemplatesGridProps> = ({
    clientId,
    ...props
}) => {
    const query = backend.clientAppTemplates.useGetAll([], {
        select: templates => clientId ?
            templates.filter(it => it.clientId === clientId) :
            templates
    })

    return <QueryDataGrid
        queryOrRows={query}
        columns={columns}
        {...props}
        disableRowSelectionOnClick
        disableColumnMenu
    />
}

type ActionsProps = {
    template: ClientAppTemplate
}

const Actions: FC<ActionsProps> = ({
    template
}) => {
    return <Bar>
        <EditClientAppTemplateButton template={template} size="medium" />
        <DeleteClientAppTemplateButton template={template} size="medium" />
    </Bar>
}