import { Registration, RegistrationApprovalState } from '@marketpartner/backend-api';
import { casualName } from '@marketpartner/mp-common';
import { FC } from "react";
import { backend } from 'src/common/api';
import { withNotification } from 'src/common/notifications/with-notification';
import { useEvent } from 'src/events/event-context';
import { ApprovalActionButton } from 'src/registrations/approval/ApprovalActionButton';


export type RegistrationApprovalButtonsProps = {
    registration: Registration
}

export const RegistrationApprovalButtons: FC<RegistrationApprovalButtonsProps> = ({
    registration,
}) => {
    const event = useEvent()!
    const approveUpdate = backend.registrations.useApproveRegistration(withNotification({
        successMessage: `Access granted to ${casualName(registration)}`,
        errorMessage: `Error granting access to ${casualName(registration)}`,
    }))
    const rejectUpdate = backend.registrations.useRejectRegistration(withNotification({
        successMessage: `Rejected ${casualName(registration)}`,
        errorMessage: `Error rejecting ${casualName(registration)}`,
    }))
    const cancelUpdate = backend.registrations.useCancelRegistration(withNotification({
        successMessage: `Cancelled ${casualName(registration)}`,
        errorMessage: `Error cancelling ${casualName(registration)}`,
    }))

    return <>
        <ApprovalActionButton
            status={RegistrationApprovalState.Approved}
            actionName="Approve"
            mutation={approveUpdate}
            registration={registration}
            requireConfirmation={Boolean(event.triggeredEmailTemplates.registrationApproved)}
        />
        <ApprovalActionButton
            status={RegistrationApprovalState.Rejected}
            actionName="Reject"
            mutation={rejectUpdate}
            registration={registration}
            requireConfirmation
        />
        <ApprovalActionButton
            status={RegistrationApprovalState.Cancelled}
            actionName="Cancel"
            mutation={cancelUpdate}
            registration={registration}
            requireConfirmation
        />
    </>
}
