import { AgendaItemType, RelatedContentType } from "@marketpartner/backend-api";
import { filterType } from "@marketpartner/mp-common";
import { DeleteForever, Edit } from "@mui/icons-material";
import { Button, Paper } from "@mui/material";
import { FC } from "react";
import { useParams } from "react-router";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { Page } from "src/common/layout/Page";
import { Loading } from "src/common/loading/Loading";
import { RoutedTabs } from "src/common/routing/RoutedTabs";
import { fullSize } from "src/common/styles";
import { useDeleteSessionDialog } from "src/content/agendas/sessions/DeleteSessionDialog";
import { useEditSessionDialog } from "src/content/agendas/sessions/EditSessionDialog";
import { SessionDetails } from "src/content/agendas/sessions/SessionDetails";
import { relatedContentTab } from "src/content/relationships/relationship-tabs";
import { useEvent } from "src/events/event-context";

export const SessionPage: FC = () => {
    const client = useClient()!
    const event = useEvent()!
    const sessionId = useParams().sessionId

    const sessionQuery = backend.agendas.useGetAll([client.id, event.id], {
        select: agendas => filterType(
            agendas.flatMap(it => it.flatItems), AgendaItemType.Session as const
        ).find(it => it.id === sessionId) ?? null
    })
    const editSessionDialog = useEditSessionDialog()
    const deleteSessionDialog = useDeleteSessionDialog()

    return <Loading request={sessionQuery} render={session => <Page
        title={session?.name ?? "Session not found"}
        actions={session && <>
            <Button
                startIcon={<Edit />}
                color="primary"
                variant="contained"
                onClick={() => editSessionDialog.open({ session })}
            >
                Edit
            </Button>

            <Button
                startIcon={<DeleteForever />}
                color="error"
                onClick={() => deleteSessionDialog.open({ session })}
            >
                Delete
            </Button>
        </>}
    >
        {session && <Paper sx={{ ...fullSize }}>
            <RoutedTabs tabs={[
                {
                    element: <SessionDetails session={session} />,
                    label: "Details",
                    path: "details",
                    scrollable: true,
                },
                relatedContentTab(RelatedContentType.Session, session.id),
            ]} />
        </Paper>}
    </Page>} />
}