import { ContentSubCategory, ContentSubCategoryId, CustomContentCategoryId } from "@marketpartner/backend-api"
import { Grid, TextField, Typography } from "@mui/material"
import { FC, useState } from "react"
import { DialogForm, ExtendableDialogFormProps } from "src/common/dialogs/DialogForm"
import { IdField } from "src/common/form-inputs/IdField"
import { PluralTextField } from "src/common/form-inputs/PluralTextField"


export type ContentSubCategoryFormData = {
    categoryId: CustomContentCategoryId
    id: ContentSubCategoryId
    name: string
    pluralName: string
}

export type ContentSubCategoryFormProps = ExtendableDialogFormProps<ContentSubCategoryFormData> & {
    categoryId: CustomContentCategoryId
    subCategory?: ContentSubCategory
}

export const ContentSubCategoryForm: FC<ContentSubCategoryFormProps> = ({
    categoryId,
    subCategory,
    ...props
}) => {
    const [id, setId] = useState(subCategory?.id?.substring(categoryId.length + 1) ?? "")
    const [name, setName] = useState(subCategory?.name ?? "")
    const [pluralName, setPluralName] = useState(subCategory?.pluralName ?? "")

    return <DialogForm
        formData={{
            categoryId,
            id: `${categoryId}:${id}`,
            name,
            pluralName,
        }}
        isValid={Boolean(id && name && pluralName)}
        {...props}
    >
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    label="Name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    fullWidth
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <PluralTextField
                    sourceValue={name}
                    value={pluralName}
                    onChange={setPluralName}
                    required
                    fullWidth
                />
            </Grid>
            {!subCategory && <Grid item xs={12}>
                <IdField
                    nameValue={name}
                    idValue={id}
                    onChangeId={setId}
                    maxLength={255}
                    inputProps={{
                        startAdornment: <Typography color="text.secondary">{categoryId}:</Typography>
                    }}
                />
            </Grid>}
        </Grid>
    </DialogForm>
}