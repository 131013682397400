import { Scope } from "@marketpartner/backend-api";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { ConfirmDeleteDialog } from "src/common/dialogs/ConfirmDeleteDialog";
import { LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { useEvent } from "src/events/event-context";


export type DeleteComputedColumnDialogProps = LockableDialogProps & {
    columnIndex: number
}

const DeleteComputedColumnDialog: FC<DeleteComputedColumnDialogProps> = ({
    columnIndex,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const column = event.computedColumns[columnIndex]

    const updateEventMutation = backend.events.useUpdateEvent(withNotification({
        errorMessage: "Error deleting column"
    }))

    return <ConfirmDeleteDialog
        itemName={column?.id}
        formProps={{
            text: `Are you sure you want to delete ${column?.id}?`,
            action: () => updateEventMutation.mutateAsync([client.id, event.id, {
                computedColumns: event.computedColumns.filter((_, idx) => idx !== columnIndex)
            }]),
        }}
        {...props}
    />
}

export const useDeleteComputedColumnDialog = createPopupHook({
    element: DeleteComputedColumnDialog,
    scope: Scope.Event,
})