import { Poll, pollStatus } from "@marketpartner/backend-api"
import { GridColDef } from "@mui/x-data-grid-pro"
import { FC } from "react"
import { Link } from '@mui/material'
import { Link as RouterLink } from "react-router-dom"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { useEvent } from "src/events/event-context"
import { link } from "src/common/routing/routes"
import { EventDateTimeText } from "src/common/time/DateTimeText"
import { QueryDataGrid } from "src/common/grid/QueryDataGrid"

const gridColumns: GridColDef<Poll>[] = [
    {
        field: "id",
        headerName: "Id",
        flex: 4,
        minWidth: 100
    },
    {
        field: "name",
        headerName: "Name",
        flex: 4,
        minWidth: 200,
        renderCell: ({ row }) => <PollLink poll={row} />
    },
    {
        field: "status",
        headerName: "Status",
        flex: 1,
        minWidth: 120,
        renderCell: ({ row }) => {
            return pollStatus(row)
        }
    },
    { 
        field: "start", 
        headerName: "Active from", 
        flex: 2, 
        minWidth: 200, 
        renderCell: ({ value }) => <EventDateTimeText dateTime={value} /> 
    },
    { 
        field: "end", 
        headerName: "Active to", 
        flex: 2, 
        minWidth: 200, 
        renderCell: ({ value }) => <EventDateTimeText dateTime={value} /> 
    },
]

export const PollsGrid:FC = () => {
    const client = useClient()!
    const event = useEvent()!
    const pollsQuery = backend.polls.useGetAll([client.id, event.id])

    
    return <QueryDataGrid 
        queryOrRows={pollsQuery}
        columns={gridColumns}
        disableRowSelectionOnClick
    />
}

type PollLinkProps = {
    poll: Poll
}

const PollLink: FC<PollLinkProps> = ({ poll }) => {
    const client = useClient()!
    const event = useEvent()!
    return <Link
    component={RouterLink} 
    to={link.toPoll(client.id, event.id, poll.id as string)}
>
    { poll.name }
</Link>
}