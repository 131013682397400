import { MpFeedItem } from "@marketpartner/react-feed"
import { Stack, SxProps, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { memo } from "react"

export type FeedItemContentProps = {
    item: MpFeedItem,
    sx?: SxProps
}

export const FeedItemContent = memo<FeedItemContentProps>(({
    item,
    sx,
}) => {
    return <Stack sx={sx}>
        <Typography
            variant="body2"
            sx={{
                whiteSpace: "pre-wrap"
            }}
        >
            {item.messageMd}
        </Typography>
        {"imageUrl" in item && item.imageUrl && <Box>
            <img
                src={item.imageUrl}
                alt=""
                style={{
                    maxWidth: "100%",
                    maxHeight: "50vh"
                }}
            />
        </Box>}
    </Stack>
})