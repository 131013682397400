import { FeedItemDisplayStatus } from "@marketpartner/backend-api"
import { MpFeedItems } from "@marketpartner/react-feed"
import { Delete, Visibility, VisibilityOff } from "@mui/icons-material"
import { ToggleButton, ToggleButtonGroup } from "@mui/material"
import _ from "lodash"
import { FC } from "react"
import { useFeedItemSelection } from "src/feeds/items/selection/feed-item-selection-context"


export type DisplayStatusButtonsProps = {
    items: MpFeedItems
    onChange?: () => unknown
}

export const DisplayStatusButtons: FC<DisplayStatusButtonsProps> = ({
    items,
    onChange,
}) => {
    const { selectedIds } = useFeedItemSelection()
    const itemStatuses = _(items.itemList)
        .filter(item => selectedIds.has(item.id))
        .map("displayStatus")
        .uniq()
        .value()
    const selectedStatus = itemStatuses.length === 1 ? itemStatuses[0] : undefined

    const handleChange = (_: any, newStatus: FeedItemDisplayStatus) => {
        if (!newStatus) {
            return
        }
        selectedIds.forEach(itemId => {
            items.update(itemId, {
                displayStatus: newStatus
            })
        })
        onChange?.()
    }

    return <>
        <ToggleButtonGroup value={selectedStatus} onChange={handleChange} exclusive>
            <ToggleButton value={FeedItemDisplayStatus.Pending} size="small">
                <VisibilityOff />
            </ToggleButton>
            <ToggleButton value={FeedItemDisplayStatus.Public} size="small">
                <Visibility color="primary"/>
            </ToggleButton>
            <ToggleButton value={FeedItemDisplayStatus.Removed} size="small">
                <Delete color="error"/>
            </ToggleButton>
        </ToggleButtonGroup>
    </>
}

