import { filterFormFields, RegistrationCategory, RegistrationFieldVisibility } from "@marketpartner/backend-api"
import { Table, TableBody, TableRow, Typography } from "@mui/material"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import { FC, Fragment } from "react"

export type ProfileFieldsGridProps = {
    category: RegistrationCategory
}

export const ProfileFieldsGrid: FC<ProfileFieldsGridProps> = ({
    category,
}) => {
    const regFieldNames = filterFormFields(category.elements)
        .filter(it => it.metadata?.visibility === RegistrationFieldVisibility.Public)
        .map(it => it.name)
    const profileFieldNames = filterFormFields(category.profileElements).map(it => it.name)
    const defaultFieldNames = Object.keys(category.defaultProfileFields)
    const allFieldNames = Array.from(new Set([...defaultFieldNames, ...regFieldNames, ...profileFieldNames]))

    return <TableContainer>
        <Table>
            <TableHead>
                <TableCell>Field</TableCell>
                <TableCell>Value taken from</TableCell>
            </TableHead>
            <TableBody>
                {allFieldNames.map(fieldName => {
                    const sources = []
                    if (profileFieldNames.includes(fieldName)) {
                        sources.push("Profile form")
                    }
                    if (regFieldNames.includes(fieldName)) {
                        sources.push("Registration form")
                    }
                    if (defaultFieldNames.includes(fieldName)) {
                        sources.push(`Default value (${(category.defaultProfileFields as any)[fieldName]})`)
                    }
                    return <TableRow>
                        <TableCell>{fieldName}</TableCell>
                        <TableCell>
                            {sources.map((source, idx) => <Fragment key={idx}>
                                {idx > 0 && <Typography variant="body2" color="text.secondary">
                                    otherwise
                                </Typography>}
                                <Typography>
                                    {source}
                                </Typography>
                            </Fragment>)}
                        </TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>
    </TableContainer>
}

