import { ExpandLink, ExpandLinkProps } from "@marketpartner/mp-common-mui"
import { Typography } from "@mui/material"
import { memo } from "react"


export type FeedItemReplyCountProps = {
    count: number
    isExpanded: boolean
    onClick?: ExpandLinkProps["onClick"]
}

export const FeedItemReplyCount = memo<FeedItemReplyCountProps>(({
    count,
    isExpanded,
    onClick,
}) => {
    if (count === 0) {
        return <Typography color="action.disabled">
            No replies
        </Typography>
    }

    return <ExpandLink
        isExpanded={isExpanded}
        onClick={onClick}
        expandText={`Show replies (${count})`}
        collapseText={`Hide replies (${count})`}
    />
})