import { DialogTitle } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { FC } from "react";
import { ConfirmDialogForm, ConfirmDialogFormProps } from "src/common/dialogs/ConfirmDialogForm";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { withNotification } from "src/common/notifications/with-notification";


export type ConfirmDeleteDialogProps = LockableDialogProps & {
    itemName: string
    formProps: ConfirmDialogFormProps
}

export const ConfirmDeleteDialog: FC<ConfirmDeleteDialogProps> = ({
    itemName,
    formProps,
    ...props
}) => {
    const notifiedDeleteMutation = useMutation(withNotification({
        mutationFn: formProps.action,
        successMessage: `Deleted ${itemName}`,
        errorMessage: `Error deleting ${itemName}`,
    }))
    formProps = {
        ...formProps,
        action: () => notifiedDeleteMutation.mutateAsync(undefined),
    }

    return <LockableDialog
        {...props}
    >
        <DialogTitle>Confirm deletion of {itemName}</DialogTitle>
        <ConfirmDialogForm
            submitText="Delete"
            submitColor="error"
            {...formProps}
        />
    </LockableDialog>
}