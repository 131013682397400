import { Permission } from '@marketpartner/backend-api'
import { Spinning } from "@marketpartner/mp-common-mui"
import Refresh from '@mui/icons-material/Refresh'
import { Button, Paper, Typography } from "@mui/material"
import { FC } from 'react'
import { useClient } from "src/clients/client-context"
import { backend } from 'src/common/api'
import { withNotification } from 'src/common/notifications/with-notification'
import { Page } from "src/common/layout/Page"
import { RoutedTabs } from "src/common/routing/RoutedTabs"
import { center, fullSize } from "src/common/styles"
import { EventPartnerResources } from 'src/content/event-partner/EventPartnerResources'
import { EventPartnerSessions } from 'src/content/event-partner/EventPartnerSessions'
import { EventPartnerSpeakers } from 'src/content/event-partner/EventPartnerSpeakers'
import { EventPartnerSponsors } from 'src/content/event-partner/EventPartnerSponsors'
import { EditEventPartnerEventButton } from "src/content/event-partner/instances/EditEventPartnerEventButton"
import { useEvent } from "src/events/event-context"
import { ifHas } from 'src/login/IfHas'

export type LegacyContentPageProps = {
    isContentEditorUi?: boolean
}

export const LegacyContentPage: FC<LegacyContentPageProps> = ({
    isContentEditorUi = false
}) => {
    const event = useEvent()!
    const hasEventPartner = Boolean(event.eventPartnerId)

    return <Page
        title="Content"
        minContentSize={450}
        back={{ hideIf: isContentEditorUi }}
        actions={<>
            {hasEventPartner && <RefreshButton />}
            <EditEventPartnerEventButton
                color={hasEventPartner ? "inherit" : "primary"}
                variant={hasEventPartner ? "outlined" : "contained"}
            />
        </>}
    >
        <Paper sx={{ ...fullSize }}>
            {hasEventPartner ?
                <RoutedTabs tabs={[{
                    path: "sessions",
                    label: "Sessions",
                    element: <EventPartnerSessions />
                }, {
                    path: "speakers",
                    label: "Speakers",
                    element: <EventPartnerSpeakers />
                }, {
                    path: "sponsors",
                    label: "Sponsors",
                    element: <EventPartnerSponsors />
                }, {
                    path: "resources",
                    label: "Resources",
                    element: <EventPartnerResources />
                }]} /> :

                <Typography sx={{ ...center, color: "text.secondary", fontStyle: "italic" }}>
                    Event partner is not configured for this event
                </Typography>
            }
        </Paper>
    </Page>
}

const RefreshButton = () => {
    const client = useClient()!
    const event = useEvent()!

    const clearCacheMutation = backend.content.useClearEventPartnerCache(withNotification({
        successMessage: "Event Partner content refreshed",
        errorMessage: "Error refreshing Event Partner content"
    }))

    return ifHas(Permission.EditEventContent, client.id, event.id, <Button
        children="Refresh all"
        variant="contained"
        disabled={clearCacheMutation.isPending}
        startIcon={<Spinning isSpinning={clearCacheMutation.isPending} speed="2s"><Refresh /></Spinning>}
        onClick={() => clearCacheMutation.mutate([client.id, event.id])}
    />)
}