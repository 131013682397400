import { EventPartnerInstance } from '@marketpartner/backend-api'
import EditIcon from '@mui/icons-material/Edit'
import { DialogTitle } from "@mui/material"
import { FC } from 'react'
import { useClient } from 'src/clients/client-context'
import { backend } from 'src/common/api'
import { DialogButton } from "src/common/dialogs/DialogButton"
import { withNotification } from 'src/common/notifications/with-notification'
import { EventPartnerInstanceForm } from 'src/content/event-partner/instances/EventPartnerInstanceForm'


export type EditEventPartnerInstanceButtonProps = {
    instance: EventPartnerInstance
}

export const EditEventPartnerInstanceButton: FC<EditEventPartnerInstanceButtonProps> = ({
    instance
}) => {
    const client = useClient()!
    const editMutation = backend.content.useUpdateEventPartnerInstance(withNotification({
        successMessage: () => `Updated instance ${instance.id}`,
        errorMessage: "Error updating Event Partner instance"
    }))

    return <DialogButton
        icon={<EditIcon />}
        color="primary"
        dialogProps={{
            maxWidth: "sm"
        }}
    >
        <DialogTitle>Edit Event Partner instance</DialogTitle>
        <EventPartnerInstanceForm
            instance={instance}
            actionName="Save"
            onSubmit={instanceDetails => editMutation.mutateAsync([client.id, instance.id, instanceDetails])}
        />
    </DialogButton>
}