import { ActivityStatistics, EventSegment } from "@marketpartner/backend-api"
import { Button, DialogActions, Link, LinkProps } from "@mui/material"
import { FC, MouseEvent, useState } from "react"
import { ActivityUsersDialogContent } from "src/activities/usage/ActivityUsageDialogContent"
import { LockableDialog } from "src/common/dialogs/LockableDialog"

export type ActivityUsersLinkProps = Partial<LinkProps> & {
    activity: ActivityStatistics
    segment?: EventSegment
    count: number
}

export const ActivityUsersLink: FC<ActivityUsersLinkProps> = ({
    activity,
    segment,
    count,
    ...linkProps
}) => {
    const [isOpen, setIsOpen] = useState(false)

    function handleClick(e: MouseEvent) {
        e.preventDefault()
        setIsOpen(true)
    }

    return <>
        <Link href="#" underline="hover" onClick={handleClick} {...linkProps}>
            {count} {count === 1 ? "user" : "users"}
        </Link>
        <LockableDialog open={isOpen} onClose={() => setIsOpen(false)} maxWidth={false} allowFullScreen>
            <ActivityUsersDialogContent activity={activity} segment={segment} />
            <DialogActions>
                <Button color="inherit" onClick={() => setIsOpen(false)}>Close</Button>
            </DialogActions>
        </LockableDialog>
    </>
}