import { CustomContentCategoryId, Scope } from "@marketpartner/backend-api";
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { ContentSubCategoryForm } from "src/content/sub-categories/ContentSubCategoryForm";
import { useEvent } from "src/events/event-context";


export type CreateContentSubCategoryDialogProps = LockableDialogProps & {
    categoryId: CustomContentCategoryId
}

const CreateContentSubCategoryDialog: FC<CreateContentSubCategoryDialogProps> = ({
    categoryId,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const createMutation = backend.contentCategories.useCreateSubCategory(withNotification({
        errorMessage: "Error creating sub-category",
    }))

    return <LockableDialog
        maxWidth="sm"
        {...props}
    >
        <DialogTitle>Create sub-category</DialogTitle>
        <ContentSubCategoryForm
            actionName="Create"
            onSubmit={subCategory => createMutation.mutateAsync([client.id, event.id, subCategory])}
            categoryId={categoryId}
        />
    </LockableDialog>
}

export const useCreateContentSubCategoryDialog = createPopupHook({
    element: CreateContentSubCategoryDialog,
    scope: Scope.Event,
})
