import { Permission } from "@marketpartner/backend-api"
import { Paper } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { Page } from "src/common/layout/Page"
import { RoutedTabs } from "src/common/routing/RoutedTabs"
import { fullSize } from "src/common/styles"
import { useEvent } from "src/events/event-context"
import { EventFeed } from "src/feeds/EventFeed"
import { useLoginContext } from "src/login/LoginContext"
import { MeetingRequests } from "src/networking/meetings/requests/MeetingRequests"
import { EventMeetingSettings } from "src/networking/meetings/settings/EventMeetingSettings"
import { MessagingDetails } from "src/networking/messaging/MessagingDetails"


export const NetworkingPage: FC = () => {
    const client = useClient()!
    const event = useEvent()!
    const login = useLoginContext()

    return <Page
        title="Networking"
    >
        <Paper sx={fullSize}>
            <RoutedTabs tabs={[
                {
                    path: "messaging",
                    label: "Messaging",
                    element: <MessagingDetails />,
                    scrollable: true,
                },
                {
                    path: "meeting-requests",
                    label: "Meeting requests",
                    element: <MeetingRequests />,
                    hidden: !login.hasPermission(Permission.ViewMeetingRequests, client.id, event.id),
                },
                {
                    path: "meeting-settings",
                    label: "Meeting settings",
                    element: <EventMeetingSettings />,
                    scrollable: true,
                },
                {
                    path: "feed",
                    label: "Feed",
                    element: <EventFeed />,
                },
            ]} />
        </Paper>
    </Page>
}