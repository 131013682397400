import { FormUploadPrefix } from "@marketpartner/backend-api"
import { MuiFormElements } from "@marketpartner/mp-common-mui"
import { DynamicFormState } from "@marketpartner/mp-common-react"
import { FC } from "react"
import { asyncBackend } from "src/common/api"


export type DeploymentParametersEditorProps = {
    clientId: string | null
    eventId: string | null
    deploymentId: string
    formState: DynamicFormState
    adminParameters: string[]
}

export const DeploymentParametersEditor: FC<DeploymentParametersEditorProps> = ({
    clientId,
    eventId,
    deploymentId,
    formState,
}) => {

    const uploadPrefix: FormUploadPrefix = !clientId ? `apps/${deploymentId}` :
        !eventId ? `clients/${clientId}/apps/${deploymentId}` :
        `clients/${clientId}/events/${eventId}/apps/${deploymentId}`

    return <MuiFormElements
        state={formState}
        validationEnabled={true}
        backend={asyncBackend}
        uploadPrefix={uploadPrefix}
    />
}