import { Scope } from "@marketpartner/backend-api";
import DialogTitle from "@mui/material/DialogTitle";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { useEvent } from "src/events/event-context";
import { GuestForms } from "src/registrations/actions/GuestForms";


export type AddGuestDialogProps = LockableDialogProps & {
    primaryRegistrationId: string
}

const AddGuestDialog: FC<AddGuestDialogProps> = ({
    primaryRegistrationId,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const addGuestMutation = backend.registrations.useCreateGuestRegistration(withNotification({
        successMessage: "Added guest",
        errorMessage: "Error adding guest",
    }))

    return <LockableDialog fullWidth maxWidth="md" {...props}>
        <DialogTitle>Add guest</DialogTitle>
        <GuestForms
            actionName="Add guest"
            onSubmit={formData => addGuestMutation.mutateAsync([client.id, event.id, {
                primaryRegistrationId,
                ...formData,
            }])}
        />
    </LockableDialog>
}

export const useAddGuestDialog = createPopupHook({
    scope: Scope.Event,
    element: AddGuestDialog,
})