import { AppTemplate } from "@marketpartner/backend-api"
import { FC } from "react"
import { backend } from "src/common/api"
import { LoadedAutocomplete, LoadedAutocompleteProps } from "src/common/form-inputs/LoadedAutocomplete"


export type AppTemplateIdSelectorProps = Omit<LoadedAutocompleteProps<string, AppTemplate>, "fetchRequest">

export const AppTemplateIdSelector: FC<AppTemplateIdSelectorProps> = ({
    ...props
}) => {
    const templateRequest = backend.appTemplates.useGetAllTemplates([])

    return <LoadedAutocomplete
        label="Template"
        fetchRequest={templateRequest}
        placeholder="Select a template"
        {...props}
    />
}