import { RegistrationCategory } from "@marketpartner/backend-api";
import { LoadedSelector, LoadedSelectorProps } from "@marketpartner/mp-common-mui";
import { sortBy } from "lodash";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { useEvent } from "src/events/event-context";
import { categorySortKey } from "src/registrations/configuration/categories/category-sort-key";


export type RegistrationCategorySelectorProps = Partial<LoadedSelectorProps<string, RegistrationCategory>> & {
    eventId?: string
    categoryId: string | null
    onSelectCategoryId: (categoryId: string) => void
}

export const RegistrationCategorySelector: FC<RegistrationCategorySelectorProps> = ({
    eventId,
    categoryId,
    onSelectCategoryId,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const categoriesQuery = backend.registrationCategories.useGetAll([client.id, eventId ?? event.id], {
        select: categories => sortBy(categories, categorySortKey)
    })

    return <LoadedSelector
        label="Category"
        {...props}
        fetchRequest={categoriesQuery}
        selectedId={categoryId}
        onSelectId={id => id && onSelectCategoryId(id)}
        getSecondaryText={category => category.id}
        required
    />
}