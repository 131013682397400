import { CustomContentCategory, Scope } from "@marketpartner/backend-api";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { ConfirmDeleteDialog } from "src/common/dialogs/ConfirmDeleteDialog";
import { LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { useEvent } from "src/events/event-context";


export type DeleteContentCategoryDialogProps = LockableDialogProps & {
    category: CustomContentCategory
}

const DeleteContentCategoryDialog: FC<DeleteContentCategoryDialogProps> = ({
    category,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const deleteMutation = backend.contentCategories.useDeleteCategory(withNotification({
        errorMessage: "Error deleting content category",
    }))

    return <ConfirmDeleteDialog
        formProps={{
            action: () => deleteMutation.mutateAsync([client.id, event.id, category.id]),
            text: "This will permanently delete all content in this category.",
            confirmText: `Delete all ${category.pluralName}`,
        }}
        itemName={category.pluralName}
        {...props}
    />
}

export const useDeleteContentCategoryDialog = createPopupHook({
    element: DeleteContentCategoryDialog,
    scope: Scope.Event,
})
