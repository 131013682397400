import { ClientAppTemplate } from "@marketpartner/backend-api";
import { Edit } from "@mui/icons-material";
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { ClientAppTemplateForm } from "src/app-templates/client-templates/ClientAppTemplateForm";
import { backend } from "src/common/api";
import { DialogButton, DialogButtonProps } from "src/common/dialogs/DialogButton";
import { withNotification } from "src/common/notifications/with-notification";


export type EditClientAppTemplateButtonProps = Partial<DialogButtonProps> & {
    template: ClientAppTemplate
}

export const EditClientAppTemplateButton: FC<EditClientAppTemplateButtonProps> = ({
    template,
    ...props
}) => {
    const updateMutation = backend.clientAppTemplates.useUpdate(withNotification({
        successMessage: "Updated client template",
        errorMessage: "Error updating client template",
    }))

    return <DialogButton
        icon={<Edit />}
        color="primary"
        dialogProps={{
            maxWidth: "sm",
            fullWidth: true,
        }}
        {...props}
    >
        <DialogTitle>Edit template</DialogTitle>
        <ClientAppTemplateForm
            onSubmit={formData => updateMutation.mutateAsync([
                template.clientId,
                template.id,
                formData,
            ])}
            clientAppTemplate={template}
            actionName="Save"
        />
    </DialogButton >
}