import { Check, ImportExport } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import { Button, ButtonProps } from "@mui/material"
import { FC, forwardRef } from "react"
import { Bar, BarProps } from "src/common/layout/Bar"
import { UseBulkReorder } from "src/common/reordering/useBulkReorder"


export type ReorderButtonsProps = BarProps & {
    reorder: UseBulkReorder<any>
    reorderButtonProps?: Partial<ButtonProps>
    saveButtonProps?: Partial<ButtonProps>
    cancelButtonProps?: Partial<ButtonProps>
}

export const ReorderButtons: FC<ReorderButtonsProps> = forwardRef(({
    reorder,
    reorderButtonProps,
    saveButtonProps,
    cancelButtonProps,
    ...props
}, ref) => {
    return <Bar
        spacing={1}
        ref={ref}
        {...props}
    >
        {!reorder.isReordering ?
            <Button
                onClick={reorder.startReorder}
                children="Reorder"
                variant="outlined"
                startIcon={<ImportExport />}
                {...reorderButtonProps}
            /> :
            <>
                <LoadingButton
                    onClick={reorder.applyReorder}
                    loading={reorder.isLoading}
                    variant="contained"
                    startIcon={<Check />}
                    children="Save order"
                    {...saveButtonProps}
                />
                <Button
                    onClick={reorder.cancelReorder}
                    disabled={reorder.isLoading}
                    children="Cancel"
                    color="inherit"
                    {...cancelButtonProps}
                />
            </>
        }
    </Bar>
})