import { GuestRegistration, Scope } from "@marketpartner/backend-api"
import { DialogTitle } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog"
import { createPopupHook } from "src/common/dialogs/Popup"
import { withNotification } from "src/common/notifications/with-notification"
import { useEvent } from "src/events/event-context"
import { GuestForms } from "src/registrations/actions/GuestForms"


export type EditGuestDialogProps = LockableDialogProps & EditGuestDialogContentProps

const EditGuestDialog: FC<EditGuestDialogProps> = ({
    registration,
    ...props
}) => {
    return <LockableDialog fullWidth maxWidth="md" {...props}>
        <EditGuestDialogContent registration={registration} />
    </LockableDialog>
}

export type EditGuestDialogContentProps = {
    registration: GuestRegistration
}

const EditGuestDialogContent: FC<EditGuestDialogContentProps> = ({
    registration,
}) => {
    const client = useClient()!
    const event = useEvent()!
    const editMutation = backend.registrations.useUpdateRegistration(withNotification({
        successMessage: "Updated registration",
        errorMessage: "Error updating registration",
    }))

    return <>
        <DialogTitle children="Edit registration" />
        <GuestForms
            onSubmit={formData => editMutation.mutateAsync([client.id, event.id, {
                id: registration.id,
                ...formData,
            }])}
            registration={registration}
            actionName="Save"
        />
    </>
}

export const useEditGuestDialog = createPopupHook({
    scope: Scope.Event,
    element: EditGuestDialog,
})