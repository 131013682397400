import { Divider } from "@aws-amplify/ui-react";
import { BooleanExpression, Scope } from "@marketpartner/backend-api";
import { DataObject } from "@mui/icons-material";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { createElement } from "react";
import { BooleanConditionPropertiesLookup } from "src/common/boolean-conditions/boolean-condition-properties";
import { useEditConditionDialog } from "src/common/boolean-conditions/EditConditionDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { LockableMenu, LockableMenuProps } from "src/common/menus/LockableMenu";


export type AddBooleanConditionMenuProps = LockableMenuProps & {
    onAdd: (condition: BooleanExpression<any>) => void
    conditionRenderLookup: BooleanConditionPropertiesLookup<any>
}

const AddBooleanConditionMenu = ({
    onAdd,
    conditionRenderLookup,
    ...props
}: AddBooleanConditionMenuProps) => {
    const editConditionDialog = useEditConditionDialog()

    const addGroup = (operator: "AND" | "OR") => onAdd({
        type: "Group",
        name: "",
        expressions: [],
        operator,
    })

    return <LockableMenu closeOnClick {...props}>
        {Object.entries(conditionRenderLookup).map(([type, properties]) => <MenuItem
            key={type}
            onClick={() => editConditionDialog.open({
                condition: properties.createDefault(),
                properties,
                onChange: onAdd,
            })}
        >
            <ListItemIcon>{createElement(properties.iconComponent)}</ListItemIcon>
            <ListItemText>{properties.typeName}</ListItemText>
        </MenuItem>)}
        <Divider />
        <MenuItem onClick={() => addGroup("AND")}>
            <ListItemIcon><DataObject /></ListItemIcon>
            <ListItemText>Condition group (AND)</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => addGroup("OR")}>
            <ListItemIcon><DataObject /></ListItemIcon>
            <ListItemText>Condition group (OR)</ListItemText>
        </MenuItem>
    </LockableMenu>
}

export const useAddBooleanConditionMenu = createPopupHook({
    element: AddBooleanConditionMenu,
    scope: Scope.Event,
})
