import { Client } from "@marketpartner/backend-api"
import { Grid, TextField, TextFieldProps } from "@mui/material"
import { FC, useState } from "react"
import { DialogForm, ExtendableDialogFormProps } from "src/common/dialogs/DialogForm"
import { IdField } from "src/common/form-inputs/IdField"

export type ClientFormData = {
    id: string
    name: string
}

export type ClientFormProps = ExtendableDialogFormProps<ClientFormData> & {
    client?: Client
}

export const ClientForm: FC<ClientFormProps> = ({
    client,
    ...props
}) => {
    const [id, setId] = useState(client?.id ?? "")
    const [name, setName] = useState(client?.name ?? "")

    return <DialogForm
        formData={{
            id,
            name,
        }}
        {...props}
    >
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
                <NameField value={name} onChange={e => setName(e.target.value)} />
            </Grid>
            {!client && <Grid item xs={12}>
                <IdField nameValue={name} idValue={id} onChangeId={setId} maxLength={24} />
            </Grid>}
        </Grid>
    </DialogForm>
}

const NameField = ({ ...props }: TextFieldProps) => <TextField
    label="Name"
    fullWidth
    required
    {...props}
/>