import { Checkbox, FormControlLabel } from "@mui/material"
import { FC } from "react"
import { Bar } from "src/common/layout/Bar"
import { FeedDisplayMode, useFeedDisplayOptions } from "src/feeds/items/options/feed-display-context"
import { FeedDisplayModeSelector } from "src/feeds/items/options/FeedDisplayModeSelector"


export type FeedDisplayOptionsBarProps = {}

export const FeedDisplayOptionsBar: FC<FeedDisplayOptionsBarProps> = () => {
    const options = useFeedDisplayOptions()

    return <Bar spacing={2} py={0.5} px={2}>
        <FeedDisplayModeSelector />
        {options.displayMode !== FeedDisplayMode.Pending && <FormControlLabel
            checked={options.showDeletedItems}
            onChange={e => options.setShowDeletedItems(!options.showDeletedItems)}
            control={<Checkbox />}
            label="Show deleted items"
        />}
    </Bar>
}