import { ContentEntity, CustomContentCategoryId, isCustomContentCategory, Scope } from "@marketpartner/backend-api"
import { LoadedSelector } from "@marketpartner/mp-common-mui"
import { Collapse, DialogTitle, Grid } from "@mui/material"
import { FC, useState } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { DialogForm } from "src/common/dialogs/DialogForm"
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog"
import { createPopupHook } from "src/common/dialogs/Popup"
import { withNotification } from "src/common/notifications/with-notification"
import { useEvent } from "src/events/event-context"


export type ChangeContentEntityCategoryDialogProps = LockableDialogProps & ChangeContentEntityCategoryFormProps

const ChangeContentEntityCategoryDialog: FC<ChangeContentEntityCategoryDialogProps> = ({
    entity,
    ...props
}) => {

    return <LockableDialog
        maxWidth="xs"
        fullWidth
        {...props}
    >
        <DialogTitle>
            Re-categorize {entity.fields.title ?? "content"}
        </DialogTitle>
        <ChangeContentEntityCategoryForm entity={entity} />
    </LockableDialog>
}

type ChangeContentEntityCategoryFormProps = {
    entity: ContentEntity
}

const ChangeContentEntityCategoryForm: FC<ChangeContentEntityCategoryFormProps> = ({
    entity
}) => {
    const client = useClient()!
    const event = useEvent()!
    const [categoryId, setCategoryId] = useState(entity.categoryId)
    const [subCategoryId, setSubCategoryId] = useState(entity.subCategoryId)

    const categoriesQuery = backend.contentCategories.useGetCategories([client.id, event.id], {
        select: categories => categories.filter(it => isCustomContentCategory(it)),
    })
    const subCategoriesQuery = backend.contentCategories.useGetCategories([client.id, event.id], {
        select: categories => categories
            .find(it => it.id === categoryId)!
            .subCategories,
    })
    const updateEntityMutation = backend.contentEntities.useUpdateEntity(withNotification({
        errorMessage: "Error changing category"
    }))

    const category = categoriesQuery.data?.find(c => c.id === categoryId)

    const handleChangeCategory = (categoryId: CustomContentCategoryId) => {
        setCategoryId(categoryId)
        setSubCategoryId(null)
    }

    return <DialogForm
        actionName="Move"
        formData={{}}
        onSubmit={() => updateEntityMutation.mutateAsync([client.id, event.id, entity.id, {
            categoryId,
            subCategoryId,
        }])}
    >
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <LoadedSelector
                    label="Category"
                    fetchRequest={categoriesQuery}
                    selectedId={categoryId}
                    onSelectId={categoryId => categoryId && handleChangeCategory(categoryId as CustomContentCategoryId)}
                    fullWidth
                    required
                />
            </Grid>
            <Grid item xs={12}>
                {category && <Collapse in={Boolean(category)}>
                    <LoadedSelector
                        label="Subcategory"
                        fetchRequest={subCategoriesQuery}
                        selectedId={subCategoryId}
                        onSelectId={setSubCategoryId}
                        fullWidth
                    />
                </Collapse>}
            </Grid>
        </Grid>
    </DialogForm>
}

export const useChangeContentEntityCategoryDialog = createPopupHook({
    element: ChangeContentEntityCategoryDialog,
    scope: Scope.Event,
})