import { CurrencyAmount, LocalDateTime, TicketType, zeroCurrencyAmount } from "@marketpartner/backend-api"
import { Grid, TextField } from "@mui/material"
import { FC, useState } from "react"
import { EventCurrencyInput } from "src/common/currency/EventCurrencyInput"
import { DialogForm, ExtendableDialogFormProps } from "src/common/dialogs/DialogForm"
import { IdField } from "src/common/form-inputs/IdField"
import { NumberTextField } from "src/common/form-inputs/NumberTextField"
import { EventActiveStatusEditor } from "src/common/statuses/EventActiveStatusEditor"
import { ticketTypeActiveStatusDetails } from "src/payment/ticket-types/ticket-type-active-status-details"


export type TicketTypeFormData = {
    id: string
    name: string
    price: CurrencyAmount
    limit: number | null
    localStart: LocalDateTime | null
    localEnd: LocalDateTime | null
}

export type TicketTypeFormProps = ExtendableDialogFormProps<TicketTypeFormData> & {
    ticketType?: TicketType
}

export const TicketTypeForm: FC<TicketTypeFormProps> = ({
    ticketType,
    ...props
}) => {
    const [id, setId] = useState(ticketType?.id ?? "")
    const [name, setName] = useState(ticketType?.name ?? "")
    const [price, setPrice] = useState(ticketType?.price ?? zeroCurrencyAmount)
    const [limit, setLimit] = useState(ticketType?.limit ?? null)
    const [localStart, setLocalStart] = useState(ticketType?.localStart ?? null)
    const [localEnd, setLocalEnd] = useState(ticketType?.localEnd ?? null)

    return <DialogForm
        formData={{
            id,
            name,
            limit,
            price,
            localStart,
            localEnd,
        }}
        isValid={Boolean(id && name)}
        {...props}
    >
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    label="Name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    fullWidth
                    required
                />
            </Grid>
            {!ticketType && <Grid item xs={12}>
                <IdField
                    nameValue={name}
                    idValue={id}
                    onChangeId={setId}
                    maxLength={255}
                />
            </Grid>}
            <Grid item xs={12}>
                <EventCurrencyInput
                    label="Price"
                    value={price}
                    onChange={setPrice}
                    fullWidth
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <NumberTextField 
                    label="Limit"
                    value={limit ?? 0}
                    onChange={limit => setLimit(limit || null)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <EventActiveStatusEditor
                    details={ticketTypeActiveStatusDetails}
                    localStart={localStart}
                    setLocalStart={setLocalStart}
                    localEnd={localEnd}
                    setLocalEnd={setLocalEnd}
                />
            </Grid>
        </Grid>
    </DialogForm>
}