import { RegistrationApprovalState } from "@marketpartner/backend-api";
import { CheckCircleOutline, PendingOutlined, RemoveCircle, RemoveCircleOutline } from "@mui/icons-material";
import Cancel from "@mui/icons-material/Cancel";
import CancelOutlined from "@mui/icons-material/CancelOutlined";
import CheckCircle from "@mui/icons-material/CheckCircle";
import { SvgIconProps } from "@mui/material";
import { cloneElement, FC, ReactElement } from "react";

export const registrationStatusColors: Record<RegistrationApprovalState, string> = {
    PendingApproval: "grey.600",
    Approved: "success.main",
    Rejected: "error.main",
    Cancelled: "grey.500",
}

export type RegistrationIconVariant = "action" | "current"

const muiIcons: Record<
    RegistrationApprovalState,
    Record<
        RegistrationIconVariant,
        ReactElement<SvgIconProps>
    >
> = {
    PendingApproval: {
        action: <PendingOutlined />,
        current: <PendingOutlined />,
    },
    Approved: {
        action: <CheckCircleOutline />,
        current: <CheckCircle />,
    },
    Rejected: {
        action: <CancelOutlined />,
        current: <Cancel />,
    },
    Cancelled: {
        action: <RemoveCircleOutline />,
        current: <RemoveCircle />,
    },
}

const makeIcon = (
    status: RegistrationApprovalState
): FC<Partial<RegistrationStatusIconProps>> => (
    props
) => <RegistrationStatusIcon status={status} {...props} />

export const PendingApprovalIcon = makeIcon(RegistrationApprovalState.PendingApproval)
export const ApprovedIcon = makeIcon(RegistrationApprovalState.Approved)
export const RejectedIcon = makeIcon(RegistrationApprovalState.Rejected)
export const CancelledIcon = makeIcon(RegistrationApprovalState.Cancelled)

export type RegistrationStatusIconProps = SvgIconProps & {
    status: RegistrationApprovalState
    variant?: RegistrationIconVariant
}

export const RegistrationStatusIcon: FC<RegistrationStatusIconProps> = ({
    status,
    sx,
    variant = "current",
    ...props
}) => {
    return cloneElement(muiIcons[status][variant], {
        ...props,
        sx: {
            color: registrationStatusColors[status],
            ...sx,
        }
    })
}