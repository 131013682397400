import { AgendaItemType, BreakoutId, Scope } from "@marketpartner/backend-api";
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { BreakoutTrackForm } from "src/content/agendas/tracks/BreakoutTrackForm";
import { useEvent } from "src/events/event-context";


type AddBreakoutTrackDialogProps = LockableDialogProps & {
    agendaId: string
    breakoutId: BreakoutId
}

const AddBreakoutTrackDialog: FC<AddBreakoutTrackDialogProps> = ({
    agendaId,
    breakoutId,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const createTrackMutation = backend.agendaItems.useCreate(withNotification({
        successMessage: "Created track",
        errorMessage: "Error creating breakout track",
    }))

    return <LockableDialog {...props}>
        <DialogTitle>
            Add track
        </DialogTitle>
        <BreakoutTrackForm
            onSubmit={formData => createTrackMutation.mutateAsync([client.id, event.id, agendaId, {
                type: AgendaItemType.BreakoutTrack,
                breakoutId,
                ...formData,
            }])}
            actionName="Add"
        />
    </LockableDialog>
}

export const useAddBreakoutTrackDialog = createPopupHook({
    scope: Scope.Event,
    element: AddBreakoutTrackDialog,
})
