import { Typography, TypographyProps } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { useEvent } from "src/events/event-context";


export type RegistrationCategoryTextProps = Partial<TypographyProps> & {
    categoryId: string
}

export const RegistrationCategoryText: FC<RegistrationCategoryTextProps> = ({
    categoryId,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const categoryQuery = backend.registrationCategories.useGetAll([client.id, event.id], {
        select: categories => categories.find(category => category.id === categoryId)
    })

    return <Typography {...props}>
        {categoryQuery.data?.name ?? "&nbsp;"}
    </Typography>
}