import { AgendaHeading, AgendaItemType, LocalDateTime, Scope } from "@marketpartner/backend-api";
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { AgendaHeadingForm } from "src/content/agendas/headings/AgendaHeadingForm";
import { useEvent } from "src/events/event-context";


type EditAgendaHeadingDialogProps = LockableDialogProps & {
    heading: AgendaHeading
}

const EditAgendaHeadingDialog: FC<EditAgendaHeadingDialogProps> = ({
    heading,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const updateHeadingMutation = backend.agendaItems.useUpdate(withNotification({
        successMessage: "Updated heading",
        errorMessage: "Error updating heading",
    }))

    return <LockableDialog {...props}>
        <DialogTitle>
            Edit heading
        </DialogTitle>
        <AgendaHeadingForm
            onSubmit={formData => updateHeadingMutation.mutateAsync([client.id, event.id, heading.agendaId, heading.id, {
                type: AgendaItemType.Heading,
                ...formData,
                localStart: LocalDateTime.fromZonedDateTime(formData.start),
            }])}
            heading={heading}
            actionName="Save"
        />
    </LockableDialog>
}

export const useEditAgendaHeadingDialog = createPopupHook({
    scope: Scope.Event,
    element: EditAgendaHeadingDialog,
})
