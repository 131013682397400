import { Breakout } from "@marketpartner/backend-api"
import { ClearableTextField } from "@marketpartner/mp-common-mui"
import { Grid } from "@mui/material"
import { FC, useState } from "react"
import { DialogForm, ExtendableDialogFormProps } from "src/common/dialogs/DialogForm"


export type BreakoutFormData = {
    name: string
}

export type BreakoutFormProps = ExtendableDialogFormProps<BreakoutFormData> & {
    breakout?: Breakout
}

export const BreakoutForm: FC<BreakoutFormProps> = ({
    breakout,
    ...props
}) => {
    const [name, setName] = useState(breakout?.name ?? "")

    return <DialogForm
        formData={{
            name,
        }}
        {...props}
    >
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
                <ClearableTextField
                    value={name}
                    onChange={e => setName(e.target.value)}
                    onClear={() => setName("")}
                    label="Name"
                    fullWidth
                />
            </Grid>
        </Grid>
    </DialogForm>
}