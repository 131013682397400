import { EventDetails, FormFieldValue, GuestRegistration, PrimaryRegistration, RegistrationApprovalState, RegistrationType } from "@marketpartner/backend-api"
import { GridTreeNodeWithRender, GridValueGetterParams } from "@mui/x-data-grid-pro"
import { useMemo } from "react"
import { ExportColumnDefinition } from "src/common/grid/grid-export"
import { bbgDefaultSemrColumns, bbgDefaultSemrColumnsWithChinese, bbgJobTitleLabelColumn, bigSfmcColumns } from "src/registrations/custom-exports/customExports"

type BaseCustomExport = {
    name: string
    eventId: string | null
    columns: ExportColumnDefinition[]
    moveGuestsInline?: boolean
    maintainColumnOrder?: boolean
    showApprovedOnly?: boolean
}
type CustomColumnsPosition = "start" | "inline/end"
type CustomExportWithDefaultColumns = BaseCustomExport & {
    includeDefaultColumns: true
    customColumnsPosition: CustomColumnsPosition
}
type CustomExportWithoutDefaultColumns = BaseCustomExport & {
    includeDefaultColumns: false
}

export type CustomExport = CustomExportWithDefaultColumns | CustomExportWithoutDefaultColumns
export function useGetCustomExports(clientId: string, eventId: string): CustomExport[] {
    return useMemo(() => {

        return customExportsMap?.[clientId]
            ? customExportsMap[clientId]
                .filter(customExport => customExport.eventId === eventId || customExport.eventId === null)
                .map((customExport) => ({
                    ...customExport,
                    columns: customExport.columns.map(column => {
                        const valueGetter = column.valueGetter ?? defaultGetter
                        return {
                            ...column,
                            valueGetter: (params) => {
                                const value = valueGetter(params)
                                return customExport.maintainColumnOrder ? removeUndefined(value) : value
                            }
                        }
                    })
                }))
            : []
    }, [clientId, eventId])
}

type DoCustomExportProps = {
    customExport: CustomExport
    doExport: (data: Record<string,unknown>[], columns: ExportColumnDefinition[]) => void
    defaultColumns: ExportColumnDefinition[]
    data: Record<string,unknown>[]
    event: EventDetails
}
export function doCustomExport({
    customExport, 
    doExport, 
    defaultColumns,
    data,
    event
}: DoCustomExportProps) {
    const filteredDataWithEvent = data
        .filter(row => Boolean(customExport.showApprovedOnly) === false || row.approvalState === RegistrationApprovalState.Approved)
        .map(row => ({ ...row, event }))
    const filteredDataWithEventAndGuests = customExport.moveGuestsInline ? moveGuestsInline(filteredDataWithEvent) : filteredDataWithEvent
    const columns = customExport.includeDefaultColumns
        ? combineDefaultColumns(defaultColumns, customExport.columns, customExport.customColumnsPosition)
        : customExport.columns
    doExport(filteredDataWithEventAndGuests, columns)
}

function moveGuestsInline(data: Record<string, unknown>[]) {
    const guests = data.filter(row => row.type === RegistrationType.Guest) as GuestRegistration[]
    const primaryRegistrations = data.filter(row => row.type === RegistrationType.Primary) as PrimaryRegistration[]
    return primaryRegistrations.map(row => {
        const guestsForPrimary = guests.filter(guest => guest.primaryRegistrationId === row.id)
        return {
            ...row,
            guests: guestsForPrimary
        }
    })
}

function combineDefaultColumns(defaultColumns: ExportColumnDefinition[], customColumns: ExportColumnDefinition[], customColumnsPosition: CustomColumnsPosition): ExportColumnDefinition[] {
    if (customColumnsPosition === "inline/end") {
        return defaultColumns
            .map(col => {
                const customCol = customColumns.find(c => c.field === col.field)
                return customCol ?? col
            })
            .concat(customColumns.filter(c => !defaultColumns.find(d => d.field === c.field)))
    }
    //otherwise custom columns are at the start
    return customColumns
        .concat(defaultColumns.filter(d => !customColumns.find(c => c.field === d.field)))
}



const customExportsMap: Record<string, CustomExport[]> = {
    another_test: [
        {
            name: "Default export with job title labels",
            eventId: null,
            includeDefaultColumns: true,
            columns: [{...bbgJobTitleLabelColumn}],
            customColumnsPosition: "inline/end",
        }
    ],
    bbg: [
        {
            name: "Export for SEMR",
            eventId: null,
            includeDefaultColumns: true,
            columns: [...bbgDefaultSemrColumns],
            customColumnsPosition: "start",
            maintainColumnOrder: true
        },
        {
            name: "Export for SEMR (approved only)",
            eventId: null,
            includeDefaultColumns: true,
            columns: [...bbgDefaultSemrColumns],
            customColumnsPosition: "start",
            maintainColumnOrder: true,
            showApprovedOnly: true
        },
        {
            name: "Default export with job title labels",
            eventId: "gen_ai_can_it_deliver_on",
            includeDefaultColumns: true,
            columns: [{...bbgJobTitleLabelColumn}],
            customColumnsPosition: "inline/end",
        },
        {
            name: "Default export with job title labels",
            eventId: "esg_is_here_to_stay_5_in",
            includeDefaultColumns: true,
            columns: [{...bbgJobTitleLabelColumn}],
            customColumnsPosition: "inline/end",
        }
    ],
    bbg_fof: [
        {
            name: "Export for SEMR",
            eventId: null,
            includeDefaultColumns: true,
            columns: [...bbgDefaultSemrColumns],
            customColumnsPosition: "start",
            maintainColumnOrder: true
        },
        {
            name: "Export for SEMR (approved only)",
            eventId: null,
            includeDefaultColumns: true,
            columns: [...bbgDefaultSemrColumns],
            customColumnsPosition: "start",
            maintainColumnOrder: true,
            showApprovedOnly: true
        }
    ],
    bbg_fx: [
        {
            name: "Export for SEMR",
            eventId: null,
            includeDefaultColumns: true,
            columns: [...bbgDefaultSemrColumns],
            customColumnsPosition: "start",
            maintainColumnOrder: true
        },
        {
            name: "Export for SEMR (approved only)",
            eventId: null,
            includeDefaultColumns: true,
            columns: [...bbgDefaultSemrColumns],
            customColumnsPosition: "start",
            maintainColumnOrder: true,
            showApprovedOnly: true
        }
    ],
    bbg_risk_valuations: [
        {
            name: "Export for SEMR",
            eventId: null,
            includeDefaultColumns: true,
            columns: [...bbgDefaultSemrColumns],
            customColumnsPosition: "start",
            maintainColumnOrder: true
        },
        {
            name: "Export for SEMR (approved only)",
            eventId: null,
            includeDefaultColumns: true,
            columns: [...bbgDefaultSemrColumns],
            customColumnsPosition: "start",
            maintainColumnOrder: true,
            showApprovedOnly: true
        }
    ],
    bbg_thematic: [
        {
            name: "Export for SEMR",
            eventId: null,
            includeDefaultColumns: true,
            columns: [...bbgDefaultSemrColumns],
            customColumnsPosition: "start",
            maintainColumnOrder: true
        },
        {
            name: "Export for SEMR (approved only)",
            eventId: null,
            includeDefaultColumns: true,
            columns: [...bbgDefaultSemrColumns],
            customColumnsPosition: "start",
            maintainColumnOrder: true,
            showApprovedOnly: true
        }
    ],
    bbg_aolc: [
        {
            name: "Export for SEMR",
            eventId: null,
            includeDefaultColumns: true,
            columns: [...bbgDefaultSemrColumns],
            customColumnsPosition: "start",
            maintainColumnOrder: true
        },
        {
            name: "Export for SEMR (approved only)",
            eventId: null,
            includeDefaultColumns: true,
            columns: [...bbgDefaultSemrColumns],
            customColumnsPosition: "start",
            maintainColumnOrder: true,
            showApprovedOnly: true
        }
    ],
    bbg_cop28: [
        {
            name: "Export for SEMR",
            eventId: null,
            includeDefaultColumns: true,
            columns: [...bbgDefaultSemrColumns],
            customColumnsPosition: "start",
            maintainColumnOrder: true
        },
        {
            name: "Export for SEMR (approved only)",
            eventId: null,
            includeDefaultColumns: true,
            columns: [...bbgDefaultSemrColumns],
            customColumnsPosition: "start",
            maintainColumnOrder: true,
            showApprovedOnly: true
        }
    ],
    bbg_lftf: [
        {
            name: "Export for SEMR",
            eventId: null,
            includeDefaultColumns: true,
            columns: [...bbgDefaultSemrColumns],
            customColumnsPosition: "start",
            maintainColumnOrder: true
        },
        {
            name: "Export for SEMR (approved only)",
            eventId: null,
            includeDefaultColumns: true,
            columns: [...bbgDefaultSemrColumns],
            customColumnsPosition: "start",
            maintainColumnOrder: true,
            showApprovedOnly: true
        }
    ],
    bbg_buyside: [
        {
            name: "Export for SEMR",
            eventId: null,
            includeDefaultColumns: true,
            columns: [...bbgDefaultSemrColumns],
            customColumnsPosition: "start",
            maintainColumnOrder: true
        },
        {
            name: "Export for SEMR (approved only)",
            eventId: null,
            includeDefaultColumns: true,
            columns: [...bbgDefaultSemrColumns],
            customColumnsPosition: "start",
            maintainColumnOrder: true,
            showApprovedOnly: true
        }
    ],
    big: [
        {
            name: "Export for SFMC",
            eventId: null,
            includeDefaultColumns: false,
            columns: [...bigSfmcColumns],
        },
        {
            name: "Add inline guests",
            eventId: "btax_ifa_reception_2024",
            moveGuestsInline: true,
            includeDefaultColumns: true,
            maintainColumnOrder: true,
            columns: [
                { field: "guestFirstName", headerName: "GuestFirstName", valueGetter: ({ row }) => row.guests?.[0]?.firstName },
                { field: "guestLastName", headerName: "GuestLastName", valueGetter: ({ row }) => row.guests?.[0]?.lastName },
                { field: "guestToken", headerName: "GuestToken", valueGetter: ({ row }) => row.guests?.[0]?.accessToken },
            ],
            customColumnsPosition: "inline/end"
        }
    ],
    bnef: [
        {
            name: "Export for SEMR",
            eventId: null,
            includeDefaultColumns: true,
            columns: [...bbgDefaultSemrColumns],
            customColumnsPosition: "start",
            maintainColumnOrder: true
        },
        {
            name: "Export for SEMR (approved only)",
            eventId: null,
            includeDefaultColumns: true,
            columns: [...bbgDefaultSemrColumns],
            customColumnsPosition: "start",
            maintainColumnOrder: true,
            showApprovedOnly: true
        }, 
        {
            name: "Export for SEMR CN",
            eventId: "bnef_shanghai_2024",
            includeDefaultColumns: true,
            columns: [...bbgDefaultSemrColumnsWithChinese],
            customColumnsPosition: "start",
            maintainColumnOrder: true
        },
        {
            name: "Export for SEMR CN (approved only)",
            eventId: "bnef_shanghai_2024",
            includeDefaultColumns: true,
            columns: [...bbgDefaultSemrColumnsWithChinese],
            customColumnsPosition: "start",
            maintainColumnOrder: true,
            showApprovedOnly: true
        }
    ],
    bnef_forums_2024: [
        {
            name: "Export for SEMR",
            eventId: null,
            includeDefaultColumns: true,
            columns: [...bbgDefaultSemrColumns],
            customColumnsPosition: "start",
            maintainColumnOrder: true
        },
        {
            name: "Export for SEMR (approved only)",
            eventId: null,
            includeDefaultColumns: true,
            columns: [...bbgDefaultSemrColumns],
            customColumnsPosition: "start",
            maintainColumnOrder: true,
            showApprovedOnly: true
        }
    ],
    news_uk: [
        {
            name: "Add inline guests",
            eventId: "times_lux_chanel",
            moveGuestsInline: true,
            includeDefaultColumns: true,
            maintainColumnOrder: true,
            columns: [
                { field: "guestFirstName", headerName: "GuestFirstName", valueGetter: ({ row }) => row.guests?.[0]?.firstName },
                { field: "guestLastName", headerName: "GuestLastName", valueGetter: ({ row }) => row.guests?.[0]?.lastName },
                { field: "guestToken", headerName: "GuestToken", valueGetter: ({ row }) => row.guests?.[0]?.accessToken },
                { field: "guest2FirstName", headerName: "Guest2FirstName", valueGetter: ({ row }) => row.guests?.[1]?.firstName },
                { field: "guest2LastName", headerName: "Guest2LastName", valueGetter: ({ row }) => row.guests?.[1]?.lastName },
                { field: "guest2Token", headerName: "Guest2Token", valueGetter: ({ row }) => row.guests?.[1]?.accessToken },
            ],
            customColumnsPosition: "inline/end"
        }
    ],
    times_plus: [
        {
            name: "Times+ Inline Export",
            eventId: null,
            moveGuestsInline: true,
            includeDefaultColumns: true,
            columns: [
                { field: "guestName", headerName: "GuestName", valueGetter: ({ row }) => row.guests?.[0]?.firstName },
                { field: "guestToken", headerName: "GuestToken", valueGetter: ({ row }) => row.guests?.[0]?.accessToken },
            ],
            customColumnsPosition: "start"
        }
    ]
}

function defaultGetter(params: GridValueGetterParams<any, any, GridTreeNodeWithRender>): FormFieldValue {
    const { field, row } = params
    const indexes = field.split('.')
    let value = row
    for (const index of indexes) {
        if (value === undefined) return undefined
        value = value[index]
    }
    return value
}
function removeUndefined(value: FormFieldValue) {
    return value === undefined ? '' : value
}