import { FormFieldValue } from "@marketpartner/backend-api";
import { Stack, StackProps, SxProps, Typography } from "@mui/material";
import { FC, isValidElement, ReactElement, ReactNode } from "react";
import { Bar } from "src/common/layout/Bar";
import { RenderedFormFieldValue } from "src/forms/elements/RenderedFormFieldValue";

export type BasicPropertiesProps = {
    children: ReactNode
    sx?: SxProps
}

export const BasicProperties: FC<BasicPropertiesProps> = ({
    children,
    sx
}) => <Bar
    spacing={2}
    flexWrap="wrap"
    sx={sx}
>
        {children}
    </Bar>

export type BasicPropertyProps = StackProps & {
    name: string
    value: ReactElement | FormFieldValue | number | null
    hideNullish?: boolean
}

export const BasicProperty: FC<BasicPropertyProps> = ({
    name,
    value,
    hideNullish = false,
    sx,
    ...props
}) => {
    if (hideNullish && !value) {
        return <></>
    }

    const renderValue = (): ReactNode => {
        if (typeof value === 'object' && isValidElement(value)) {
            return <span>{value}</span>
        }
        if (value === null) {
            return <Typography color="text.secondary">Not set</Typography>
        }
        return <RenderedFormFieldValue hideNullish={hideNullish} value={value} />
    }

    return <Stack
        sx={{
            flexShrink: 0,
            ...sx,
        }}
        {...props}
    >
        <span><b>{name}</b></span>
        {renderValue()}
    </Stack>
}