import { PollResponseDisplayStatus } from "@marketpartner/backend-api"
import { Visibility, VisibilityOutlined, LockOutlined, VisibilityOff, VisibilityOffOutlined, Lock } from "@mui/icons-material"
import { IconProps, IconButton } from "@mui/material"
import { FC, ReactElement, cloneElement } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { withNotification } from "src/common/notifications/with-notification"
import { useEvent } from "src/events/event-context"
type PollResponseStatusButtonsProps = {
    pollId: string
    questionId: number
    id: number
    displayStatus: PollResponseDisplayStatus
}
export const PollResponseStatusButtons: FC<PollResponseStatusButtonsProps> = ({
    pollId,
    questionId,
    id,
    displayStatus,
}) => {

    const client = useClient()!
    const event = useEvent()!

    const updateMutation = backend.pollResponses.useUpdate(withNotification({
        successMessage: 'Response updated',
        errorMessage: 'Request failed',
    }))

    const statusButton = (
        status: PollResponseDisplayStatus,
        ActiveIcon: ReactElement<IconProps>,
        InactiveIcon: ReactElement<IconProps>,
    ) => <IconButton
        onClick={() => updateMutation.mutate([client.id, event.id, pollId, questionId, id, {
            displayStatus: status
        }])}
        disabled={updateMutation.isPending}
    >
        {displayStatus === status ?
            cloneElement(ActiveIcon, { color: !updateMutation.isPending ? "primary" : undefined }) :
            InactiveIcon
        }
    </IconButton>

    return <div style = {{display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center'}}>
        {statusButton(PollResponseDisplayStatus.Public, <Visibility />, <VisibilityOutlined />)}
        {statusButton(PollResponseDisplayStatus.Private, <Lock />, <LockOutlined />)}
        {statusButton(PollResponseDisplayStatus.Hidden, <VisibilityOff />, <VisibilityOffOutlined />)}
    </div>
}