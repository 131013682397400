import { ActivityType, getActivityId, splitActivityId } from "@marketpartner/backend-api";
import { Autocomplete, SxProps, TextField } from "@mui/material";
import { uniq } from "lodash";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { Bar } from "src/common/layout/Bar";
import { useEvent } from "src/events/event-context";


export type ActivityIdInputProps = {
    activityType: ActivityType
    activityId: string
    onChangeActivityId: (activityId: string) => void
    sx?: SxProps
}

export const ActivityIdInput: FC<ActivityIdInputProps> = ({
    activityType,
    activityId,
    onChangeActivityId,
    sx,
}) => {
    const client = useClient()!
    const event = useEvent()!

    const activityIdsQuery = backend.activities.useGetActivityList([client.id, event.id, activityType], {
        select: activityIds => activityIds.map(it => splitActivityId(it))
    })
    const activityIds = activityIdsQuery.data ?? []

    const { category, name, source } = splitActivityId(activityId)

    return <Bar spacing={1} sx={sx}>
        <Autocomplete
            inputValue={category}
            onInputChange={(_, value) => onChangeActivityId(getActivityId(value, name, source))}
            freeSolo
            options={uniq(activityIds.map(it => it.category))}
            renderInput={(params) => <TextField {...params} label="Category" />}
            sx={{ flexGrow: 1 }}
        />
        <Autocomplete
            inputValue={name}
            onInputChange={(_, value) => onChangeActivityId(getActivityId(category, value, source))}
            freeSolo
            options={uniq(activityIds
                .filter(it => it.category === category)
                .map(it => it.name)
            )}
            renderInput={(params) => <TextField {...params} label="Name" />}
            sx={{ flexGrow: 3 }}
        />
    </Bar>
}