import { ActionSegmentUsage, ActivityType, TimedActivitySegmentUsage, UsageWithRegistration, WithRegistration } from "@marketpartner/backend-api"
import { DataGridPro, GridColDef, GridSortItem } from "@mui/x-data-grid-pro"
import { FC, useMemo, useState } from "react"
import { prettyDuration } from "src/common/time/pretty-duration"
import { compareProperties } from "src/common/utility-functions/sort"
import { DeletedPrincipal } from "src/registrations/DeletedPrincipal"
import { NameAndEmail } from "src/registrations/NameAndEmail"
import { sortKey } from "src/registrations/registration-functions"

const regColumn: GridColDef<UsageWithRegistration> = {
    field: "registration",
    headerName: "User",
    renderCell: ({ row }) => row.registration ?
        <NameAndEmail registration={row.registration} /> :
        <DeletedPrincipal principal={row.principal} />,
    sortComparator: (reg1, reg2) => compareProperties(reg1, reg2,
        reg => sortKey(reg)
    ),
    flex: 3,
}

const actionColumns: GridColDef<WithRegistration<ActionSegmentUsage>>[] = [
    regColumn as any,
    {
        field: "count",
        headerName: "Count",
        flex: 1.5,
    },
]
const defaultActionSort: GridSortItem[] = [{
    field: 'count',
    sort: 'desc'
}]

const timedActivityColumns: GridColDef<WithRegistration<TimedActivitySegmentUsage>>[] = [
    regColumn as any,
    {
        field: "totalDurationSeconds",
        headerName: "Total time",
        flex: 1.5,
        renderCell: ({ row }) => prettyDuration(row.totalDurationSeconds)
    },
]
const defaultTimedActivitySort: GridSortItem[] = [{
    field: 'totalDurationSeconds',
    sort: 'desc',
}]

type RowType = WithRegistration<ActionSegmentUsage | TimedActivitySegmentUsage>
const getRowId = (row: RowType) => row.principal

export type ActivitySegmentUsageGridProps = {
    activityType: ActivityType
    usage: RowType[]
}

export const ActivitySegmentUsageGrid: FC<ActivitySegmentUsageGridProps> = ({
    activityType,
    usage
}) => {
    const [sortModel, setSortModel] = useState(activityType === "action" ? defaultActionSort : defaultTimedActivitySort)

    const columns = useMemo(
        () => activityType === ActivityType.action ?
            actionColumns :
            timedActivityColumns,
        [activityType]
    )

    return <DataGridPro
        getRowId={getRowId}
        columns={columns as any}
        rows={usage}
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        autoHeight
        disableRowSelectionOnClick
    />
}