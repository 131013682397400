import { EmailTemplate, EmailTemplateId, EmailTemplateType } from "@marketpartner/backend-api";
import { sortBy } from "lodash";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { LoadedAutocomplete, LoadedAutocompleteProps } from "src/common/form-inputs/LoadedAutocomplete";


export type EmailTemplateSelectorProps = Omit<LoadedAutocompleteProps<EmailTemplateId, EmailTemplate>, "fetchRequest">

export const EmailTemplateSelector: FC<EmailTemplateSelectorProps> = ({
    ...props
}) => {
    const client = useClient()!
    const templatesQuery = backend.emailTemplates.useGetEmailTemplatesForClient([client.id], {
        select: templates => sortBy(
            templates.filter(template =>
                template.type === EmailTemplateType.Static ||
                !template.hidden ||
                template.id === props.selectedId
            ),
            "type",
            "templateEngine",
            t => t.name.toLocaleLowerCase()
        )
    })

    return <LoadedAutocomplete
        fetchRequest={templatesQuery}
        sx={{ minWidth: 250 }}
        {...props}
    />
}