import { CurrencyCode, currencyList } from "@marketpartner/backend-api";
import { BasicSelect, BasicSelectProps } from "@marketpartner/mp-common-mui";
import { FC } from "react";
import { currencyText } from "src/common/currency/currency-text";


export type CurrencySelectorProps = Partial<BasicSelectProps<CurrencyCode>> & {
    value: CurrencyCode
    onChange: (value: CurrencyCode) => void
}

export const CurrencySelector: FC<CurrencySelectorProps> = ({
    ...props
}) => {
    return <BasicSelect
        options={currencyList.map(currency => ({
            value: currency.code,
            label: currencyText(currency),
        }))}
        {...props}
    />
}