import { EventDetails } from "@marketpartner/backend-api"
import { ExportColumnDefinition } from "src/common/grid/grid-export"
import { bbgJobTitles } from "src/registrations/custom-exports/bbgJobTitles"
import { getBbgCountryCode } from "src/registrations/custom-exports/getBbgCountryCode"

const bbgCountryColumn: ExportColumnDefinition = {
    field: "fields.country",
    headerName: "country",
    valueGetter: ({ row }) => {
        return getBbgCountryCode(row.fields.country)
    }
}
export const bbgJobTitleLabelColumn: ExportColumnDefinition = {
    field: "fields.job_title_label",
    headerName: "job_title_label",
    valueGetter: ({ row }) => {
        return bbgJobTitles.find(jobTitle => jobTitle.CODE === row.fields.job_title)?.label ?? row.fields.job_title
    }
}

export const bbgDefaultSemrColumns: ExportColumnDefinition[] = [
    { field: "firstName", headerName: "F Name", valueGetter: ({ row }) => (row.fields.first_name_en ?? row.firstName)?.substring(0, 30) },
    { field: "lastName", headerName: "L Name", valueGetter: ({ row }) => (row.fields.last_name_en ?? row.lastName)?.substring(0, 30) },
    { field: "fields.organisation", headerName: "Company", valueGetter: ({ row }) => {
        const organisation = row.fields.organisation_en ?? row.fields.organisation ?? row.fields.organization ?? row.fields.company ?? ""
        return removeAccents(organisation).substring(0, 40)
    }},
    { field: "fields.phone", headerName: "Phone" },
    { field: "email", headerName: "Email", valueGetter: ({ row }) => row.email?.substring(0, 50) },
    { field: "fields.prosp", headerName: "Pros", valueGetter: () => "" },
    { field: "fields.cont", headerName: "Cont", valueGetter: () => "" },
    { field: "fields.city", headerName: "City", valueGetter: ({ row }) => removeAccents(row.fields.city ?? "") },
    { field: "fields.state", headerName: "State" },
    { ...bbgCountryColumn },
    { field: "fields.notes", headerName: "Notes" },
    {
        field: "fields.contacted", headerName: "Req Demo", valueGetter: ({ row }) => {
            if (typeof row.fields.contacted === 'boolean') return row.fields.contacted ? "Y" : "N"
            if (typeof row.fields.bloombergnef === 'boolean') return row.fields.bloombergnef ? "Y" : "N"
            return row.fields.contacted === undefined ? '' : row.fields.contacted
        }
    },
    { field: "fields.job_role", headerName: "Job Role", valueGetter: ({ row }) => row.fields.job_role ?? row.fields.job_title },
    { field: "fields.industry", headerName: "Industry" },
]
export const bbgDefaultSemrColumnsWithChinese: ExportColumnDefinition[] = [
    ...bbgDefaultSemrColumns,
    { field: "fields.first_name_cn", headerName: "first_name_cn", valueGetter: ({ row }) => (row.fields.first_name_en ? row.fields.first_name : row.fields.first_name_cn)?.substring(0, 30) },
    { field: "fields.last_name_cn", headerName: "last_name_cn", valueGetter: ({ row }) => (row.fields.last_name_en ? row.fields.last_name : row.fields.last_name_cn)?.substring(0, 30) },
    { field: "fields.organisation_cn", headerName: "organisation_cn", valueGetter: ({ row }) => (row.fields.organisation_en ? row.fields.organisation : row.fields.organisation_cn)?.substring(0, 40) },
    { field: "fields.first_name_en", headerName: "first_name_en", valueGetter: ({ row }) => (row.fields.first_name_cn ? row.fields.first_name : row.fields.first_name_en)?.substring(0, 30) },
    { field: "fields.last_name_en", headerName: "last_name_en", valueGetter: ({ row }) => (row.fields.last_name_cn ? row.fields.last_name : row.fields.last_name_en)?.substring(0, 30) },
    { field: "fields.organisation_en", headerName: "organisation_en", valueGetter: ({ row }) => (row.fields.organisation_cn ? row.fields.organisation : row.fields.organisation_en)?.substring(0, 40) },
]
export const bigSfmcColumns: ExportColumnDefinition[] = [
    { field: "email", headerName: "email" },
    { field: "firstName", headerName: "first_name" },
    { field: "lastName", headerName: "last_name" },
    {
        field: "fields.status", headerName: "status", valueGetter: ({ row }) => {
            if (typeof row.computedValues?.Attended === "boolean") {
                return row.computedValues.Attended ? "A" : "R"
            }
        }
    },
    { field: "fields.company", headerName: "company" },
    { field: "fields.country", headerName: "country", valueGetter: ({ row }) => row.fields.country },
    { field: "fields.city", headerName: "city" },
    { field: "fields.state_or_province", headerName: "state_or_province" },
    { field: "fields.job_title", headerName: "job_title" },
    { field: "fields.industry", headerName: "industry" },
    { field: "fields.job_role", headerName: "job_role" },
    { field: "fields.sector", headerName: "sector" },
    { field: "fields.trackingcode", headerName: "trackingcode" },
    { field: "fields.business_unit", headerName: "business_unit" },
    { field: "fields.business_phone", headerName: "business_phone" },
    { field: "fields.submit_id", headerName: "submit_id", valueGetter: ({ row }) => row.fields.sfmc_submit_id ?? row.id },
    { field: "fields.organization_type", headerName: "organization_type" },
    { field: "fields.zip_or_postal_code", headerName: "zip_or_postal_code" },
    { field: "fields.hidden_field_asset_title", headerName: "hidden_field_asset_title" },
    { field: "fields.hidden_field_product_code", headerName: "hidden_field_product_code" },
    { field: "fields.hidden_field_marketing_activity_type", headerName: "hidden_field_marketing_activity_type" },
    { field: "fields.bgov_learn_more", headerName: "bgov_learn_more" },
    { field: "fields.dietary_preference", headerName: "dietary_preference" },
    { field: "fields.send_offers_and_promotions", headerName: "send_offers_and_promotions" },
    { field: "fields.what_is_keeping_you_up_at_night", headerName: "what_is_keeping_you_up_at_night" },
    { field: "fields.bbgsum", headerName: "bbgsum" },
    { field: "fields.sfmc_id", headerName: "sfmc_id" },
    { field: "fields.utm_campaign", headerName: "utm_campaign" },
    { field: "fields.utm_id", headerName: "utm_id" },
    { field: "fields.utm_medium", headerName: "utm_medium" },
    { field: "fields.utm_source", headerName: "utm_source" },
    { field: "fields.utm_term", headerName: "utm_term" },
    { field: "fields.hidden_field_interested_product", headerName: "hidden_field_interested_product" },
    { field: "fields.hidden_field_promo_code", headerName: "hidden_field_promo_code" },
    {
        field: "fields.hidden_field_event_date", headerName: "hidden_field_event_date", valueGetter: ({ row }) => {
            const event = row.event as EventDetails
            return event ? event.endTime.setZone(event.timezone).toISO() : ""
        }
    },

    { field: "fields.guest_email_address", headerName: "guest_email_address" },
    { field: "fields.guest_first_name", headerName: "guest_first_name" },
    { field: "fields.guest_job_title", headerName: "guest_job_title" },
    { field: "fields.guest_last_name", headerName: "guest_last_name" },
    { field: "fields.guest_organization_name", headerName: "guest_organization_name" },
    { field: "fields.guests_checkbox", headerName: "guests_checkbox" },

    { field: "fields.hidden_field_tracking_code", headerName: "hidden_field_tracking_code" },
    { field: "fields.hidden_field_event_time", headerName: "hidden_field_event_time" },
    { field: "fields.additional_notes", headerName: "additional_notes" },
    { field: "fields.entry_date", headerName: "entry_date" },
    { field: "fields.hidden_field_event_url", headerName: "hidden_field_event_url" },
    { field: "fields.hidden_field_sfdc_lead_source", headerName: "hidden_field_sfdc_lead_source" },
    { field: "fields.your_colleague_last_name", headerName: "your_colleague_last_name" },
    { field: "fields.hidden_field_event_location", headerName: "hidden_field_event_location" },
    { field: "fields.etl_date", headerName: "etl_date" },
    { field: "fields.your_colleague_first_name", headerName: "your_colleague_first_name" },
    { field: "fields.request_type", headerName: "request_type" },
    { field: "fields.hidden_field_semr_id", headerName: "hidden_field_semr_id" },
    { field: "fields.hidden_field_lead_source_orig", headerName: "hidden_field_lead_source_orig" },
    { field: "fields.your_colleague_email_address", headerName: "your_colleague_email_address" },
    { field: "fields.your_colleague_title", headerName: "your_colleague_title" },
    { field: "fields.hidden_field_mpam_id", headerName: "hidden_field_mpam_id" },
    { field: "fields.hidden_field_lead_source_recent", headerName: "hidden_field_lead_source_recent" },
    { field: "fields.your_colleague_organization_type", headerName: "your_colleague_organization_type" },
    { field: "fields.hidden_field_business_unit", headerName: "hidden_field_business_unit" },
    { field: "fields.your_colleague_company", headerName: "your_colleague_company" },
    { field: "fields.bna_offers_and_promotions", headerName: "bna_offers_and_promotions" },
    { field: "fields.hidden_field_sfdc_campaign", headerName: "hidden_field_sfdc_campaign" },
    { field: "fields.your_country", headerName: "your_country" },
    { field: "fields.hidden_field_ics_url", headerName: "hidden_field_ics_url" },
    { field: "fields.your_colleague_country", headerName: "your_colleague_country" },
    { field: "fields.register", headerName: "register" },
    { field: "fields.hidden_field_event_name", headerName: "hidden_field_event_name" },
    { field: "fields.hidden_field_campaign_name", headerName: "hidden_field_campaign_name" },
    { field: "fields.hidden_field_guid", headerName: "hidden_field_guid" },
    { field: "fields.hidden_field_form_name", headerName: "hidden_field_form_name" },
    { field: "fields.hidden_field_bget_id", headerName: "hidden_field_bget_id" },
    { field: "fields.hidden_field_campaign_id", headerName: "hidden_field_campaign_id" },
    { field: "fields.hidden_field_response_method", headerName: "hidden_field_response_method" },
]

function removeAccents(str: string) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}