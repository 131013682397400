import { Paper } from "@mui/material"
import { FC } from "react"
import { Page } from "src/common/layout/Page"
import { fullSize } from "src/common/styles"
import { CreateFormButton } from "src/forms/CreateFormButton"
import { FormsGrid } from "src/forms/FormsGrid"

export const FormsPage: FC = () => {
    return <Page
        title="Forms"
        actions={<CreateFormButton />}
    >
        <Paper sx={{ ...fullSize }}>
            <FormsGrid />
        </Paper>
    </Page>
}