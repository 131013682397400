import { EventDetails } from "@marketpartner/backend-api"
import { ClearableTextField } from "@marketpartner/mp-common-mui"
import { useSearchCallback } from "@marketpartner/mp-common-react"
import { FC } from "react"
import { Bar } from "src/common/layout/Bar"
import { BarAndFlex } from "src/common/layout/BarAndFlex"
import { Spacer } from "src/common/layout/Spacer"
import { CreateEventButton } from "src/events/CreateEventButton"
import { EventGrid } from "src/events/EventGrid"

const eventSearchFields = (event: EventDetails) => [
    event.id,
    event.name,
    event.tags.join(" ")
]

export const Events: FC = () => {
    const [searchText, setSearchText, searchRows] = useSearchCallback(eventSearchFields)

    return <BarAndFlex barContent={
        <Bar p={2}>
            <ClearableTextField 
                value={searchText ?? ""}
                onChange={e => setSearchText(e.target.value)}
                onClear={() => setSearchText("")}
                label="Search"
                size="small"
            />
            <Spacer />
            <CreateEventButton />
        </Bar>
    }>
        <EventGrid 
            filterRows={searchRows}
        />
    </BarAndFlex>
}
