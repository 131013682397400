import { Permission } from "@marketpartner/backend-api"
import { FC, ReactNode } from "react"
import { useLoginContext } from "src/login/LoginContext"


export const ifHas = (
    permission: Permission,
    clientId: string | undefined,
    eventId: string | undefined,
    content: ReactNode
) => {
    return <IfHas permission={permission} clientId={clientId} eventId={eventId} children={content} />
}

type IfHasProps = {
    permission: Permission
    clientId?: string
    eventId?: string
    children: ReactNode
}

const IfHas: FC<IfHasProps> = ({
    permission,
    clientId,
    eventId,
    children
}) => {
    const login = useLoginContext()

    if (!login.hasPermission(permission, clientId, eventId)) {
        return <></>
    }

    return <>{children}</>
}