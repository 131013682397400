import { slugify } from '@marketpartner/mp-common'
import UndoIcon from '@mui/icons-material/Undo'
import { Button, FormControl, FormHelperText, InputLabel, OutlinedInput, OutlinedInputProps } from "@mui/material"
import { FC, useEffect, useState } from "react"

export type IdFieldProps = {
    nameValue: string
    idValue: string
    onChangeId: (value: string) => void
    maxLength: number
    inputProps?: OutlinedInputProps
}

/**
 * ID input field, that gives a default value based on a name field.
 */
export const IdField: FC<IdFieldProps> = ({
    nameValue,
    idValue,
    onChangeId,
    maxLength,
    inputProps,
}) => {
    // This value is an explicit ID that someone has manually typed into the text field.
    // When the user hasn't modified the value, this is set to undefined, indicating that
    // the id should be automatically generated based on the nameValue.
    const [customIdOverride, setCustomIdOverride] = useState<string>()

    // Recalculate formatted id when relevant values change
    useEffect(() => {
        const idBase = customIdOverride === undefined ? nameValue : customIdOverride
        const formattedId = slugify(idBase, '_')
            .toLowerCase()
            .replace(/^[\d_]*/, "")  // Ignore leading digits or underscores
            .substring(0, maxLength) // Max length 24
        onChangeId(formattedId)
    }, [nameValue, customIdOverride, onChangeId, maxLength])

    // If both name and custom field are cleared, reset to using default id
    useEffect(() => {
        if (nameValue === "" && customIdOverride === "") {
            setCustomIdOverride(undefined)
        }
    }, [nameValue, customIdOverride])

    return <FormControl fullWidth variant="filled" required>
        <OutlinedInput
            value={idValue}
            onChange={e => setCustomIdOverride(e.target.value)}
            endAdornment={<Button
                children="Default"
                onClick={() => setCustomIdOverride(undefined)}
                disabled={customIdOverride === undefined}
                color="inherit"
                startIcon={<UndoIcon />}
            />}
            {...inputProps}
        />
        <InputLabel>ID</InputLabel>
        <FormHelperText>Max {maxLength} characters. Allows a-z, underscores, and numbers. Must start with a letter.</FormHelperText>
    </FormControl>
}