import { Tab, TabProps, Tabs, TabsProps } from "@mui/material"
import { FC, ReactNode, useLayoutEffect, useState } from "react"
import { BarAndFlex, BarAndFlexProps } from "src/common/layout/BarAndFlex"
import { LoginContext, useLoginContext } from "src/login/LoginContext"

export type BasicTab = Partial<TabProps> & {
    restrictedTo?: (login: LoginContext) => boolean
    render: () => ReactNode
}

export type BasicTabsProps = Partial<TabsProps> & {
    sizing?: BarAndFlexProps["sizing"]
    tabs: BasicTab[]
}

export const BasicTabs: FC<BasicTabsProps> = ({
    tabs,
    sizing = "full-size",
    sx,
    ...props
}) => {
    const login = useLoginContext()
    tabs = tabs.filter(it => !it.restrictedTo || it.restrictedTo(login))

    const [tabIdx, setTabIdx] = useState(0)

    useLayoutEffect(() => {
        if (tabIdx >= tabs.length) {
            setTabIdx(0)
        }
    }, [tabIdx, tabs])

    return <BarAndFlex
        sizing={sizing}
        sx={sx}
        flexStyle={{ overflowY: "auto" }}
        barContent={<Tabs
            value={tabIdx}
            indicatorColor="primary"
            textColor="primary"
            onChange={(_, newIdx) => setTabIdx(newIdx)} {...props}
            variant="scrollable"
            scrollButtons="auto"
        >
            {tabs.map((tab, idx) =>
                <Tab key={idx} value={idx} {...tab} />
            )}
        </Tabs>}
    >
        {tabs[tabIdx] && tabs[tabIdx].render()}
    </BarAndFlex>
}