import { Amplify } from "aws-amplify"
import { FC, ReactNode, useLayoutEffect, useState } from "react"
import { backend } from "src/common/api"
import { Loading } from "src/common/loading/Loading"
import { AmplifyAuthenticator } from "src/login/AmplifyAuthenticator"
import { LoginContextProvider } from "src/login/LoginContext"
import { StoredCredentials } from "src/login/stored-credentials"
import { TokenLoginForm } from "src/login/TokenLoginForm"


export type RequireLoginProps = {
    children: ReactNode
}

export const RequireLogin: FC<RequireLoginProps> = ({
    children
}) => {
    const [showTokenLoginForm, setShowTokenLoginForm] = useState(false)
    const accessToken = StoredCredentials.useAccessToken()
    const cognitoPoolQuery = backend.adminUsers.useGetPoolDetails([])

    useLayoutEffect(() => {
        const poolDetails = cognitoPoolQuery.data
        if (poolDetails) {
            Amplify.configure({
                Geo: {
                    LocationService: {
                        region: poolDetails.region,
                    },
                },
                Auth: {
                    Cognito: {
                        userPoolId: poolDetails.poolId,
                        userPoolClientId: poolDetails.clientId,
                    },
                }
            })
        }
    }, [cognitoPoolQuery.data])

    if (accessToken.value) {
        return <LoginContextProvider
            logout={() => accessToken.clear()}
            children={children}
        />
    }

    return <Loading
        request={cognitoPoolQuery}
        render={() => showTokenLoginForm ?
            <TokenLoginForm back={() => setShowTokenLoginForm(false)} /> :
            <AmplifyAuthenticator
                switchToTokenLogin={() => setShowTokenLoginForm(true)}
                children={children}
            />
        }
    />
}