import { Registration } from "@marketpartner/backend-api"
import { SearchBox } from "@marketpartner/mp-common-mui"
import { useSearchCallback } from "@marketpartner/mp-common-react"
import { FC } from "react"
import { SearchableGridLayout } from "src/common/grid/SearchableGridLayout"
import { Bar } from "src/common/layout/Bar"
import { SpeakersGrid, SpeakersGridProps } from "src/content/speakers/SpeakersGrid"
import { getRegistrationTreePath } from "src/registrations/BaseRegistrationsGrid"

export type SearchableSpeakersGridProps = SpeakersGridProps

export const SearchableSpeakersGrid: FC<SearchableSpeakersGridProps> = ({
    sx,
    ...props
}) => {
    const [searchText, setSearchText, searchRegistrations] = useSearchCallback(searchParams, searchOptions)

    return <SearchableGridLayout
        searchControls={
            <Bar spacing={1}>
                <SearchBox searchText={searchText} setSearchText={setSearchText} />
            </Bar>
        }
        grid={<SpeakersGrid
            filterRows={searchRegistrations}
            checkboxSelection
            disableColumnMenu
            {...props}
        />}
    />
}

const searchParams = (registration: Registration) => [
    registration.firstName,
    registration.lastName,
    registration.email,
]

const searchOptions = {
    disableUrlState: true,
    treeOptions: {
        getTreePath: getRegistrationTreePath,
        alwaysReturnChildrenOfMatch: true,
    }
}