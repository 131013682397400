import { createContext, FC, ReactNode, useCallback, useContext } from "react";
import { useImmer } from "use-immer";

export type FeedItemSelectionContext = {
    selectedIds: Set<string>
    toggleSelection: (id: string, useMultiSelect: boolean) => unknown
    clearSelection: () => unknown
}

const context = createContext<FeedItemSelectionContext>(undefined!)

export const useFeedItemSelection = () => useContext(context)

export type FeedItemSelectionContextProviderProps = {
    children: ReactNode
}

export const FeedItemSelectionContextProvider: FC<FeedItemSelectionContextProviderProps> = ({
    children
}) => {
    const [selectedIds, modifySelectedIds] = useImmer(new Set<string>())

    const toggleSelection = useCallback(
        (id: string, useMultiSelect: boolean) => modifySelectedIds(ids => {
            const selected = ids.has(id)
            if (!useMultiSelect) {
                ids.clear()
                if (!selected) {
                    ids.add(id)
                }
            } else {
                if (selected) {
                    ids.delete(id)
                } else {
                    ids.add(id)
                }
            }
        }),
        [modifySelectedIds]
    )

    const clearSelection = useCallback(
        () => modifySelectedIds(ids => ids.clear()),
        [modifySelectedIds]
    )

    return <context.Provider children={children} value={{
        selectedIds,
        toggleSelection,
        clearSelection,
    }}/>
}