import { ContentEntity } from "@marketpartner/backend-api";
import { GridColDef } from "@mui/x-data-grid-pro";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { QueryDataGrid, QueryDataGridProps } from "src/common/grid/QueryDataGrid";
import { Bar } from "src/common/layout/Bar";
import { getContentSecondaryText } from "src/content/entities/content-secondary-text";
import { ContentEntityAvatar } from "src/content/entities/ContentEntityAvatar";
import { ContentCategoryName } from "src/content/sub-categories/ContentCategoryName";
import { ContentTypeNameText } from "src/content/types/ContentTypeNameText";
import { useEvent } from "src/events/event-context";
import { NameAndSubtitle } from "src/registrations/NameAndSubtitle";


export type ContentEntitiesGridProps = Partial<QueryDataGridProps<ContentEntity>>

export const ContentEntitiesGrid = (
    props: ContentEntitiesGridProps
) => {
    const client = useClient()!
    const event = useEvent()!

    const entitiesQuery = backend.contentEntities.useGetEntities([client.id, event.id])

    return <QueryDataGrid
        queryOrRows={entitiesQuery}
        columns={columns}
        {...props}
    />
}

const columns: GridColDef<ContentEntity>[] = [
    {
        field: "type",
        headerName: "Type",
        valueGetter: ({ row }) => row.typeId,
        renderCell: ({ row }) => <ContentTypeNameText typeId={row.typeId} />,
        minWidth: 50,
        width: 100,
    },
    {
        field: "title",
        headerName: "Title",
        valueGetter: ({ row }) => row.fields.title,
        renderCell: ({ row }) => <Bar spacing={1}>
            <ContentEntityAvatar entity={row} size={24} />
            <NameAndSubtitle
                name={row.fields.title}
                subtitle={getContentSecondaryText(row)}
            />
        </Bar>,
        minWidth: 200,
        flex: 1,
    },
    {
        field: "category",
        headerName: "Category",
        valueGetter: ({ row }) => `${row.categoryId}:${row.subCategoryId ?? ""}`,
        renderCell: ({ row }) => <ContentCategoryName
            categoryId={row.categoryId}
            subCategoryId={row.subCategoryId}
        />,
        minWidth: 100,
        flex: 1,
    },
]
