import { ScheduledEmail, Scope } from "@marketpartner/backend-api";
import { Delete, Edit } from "@mui/icons-material";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { FC } from "react";
import { createPopupHook } from "src/common/dialogs/Popup";
import { LockableMenu, LockableMenuProps } from "src/common/menus/LockableMenu";
import { useDeleteScheduledEmailDialog } from "src/email/scheduling/DeleteScheduledEmailDialog";
import { useEditScheduledEmailDialog } from "src/email/scheduling/EditScheduledEmailDialog";


export type ScheduledEmailContextMenuProps = LockableMenuProps & {
    email: ScheduledEmail
}

const ScheduledEmailContextMenu: FC<ScheduledEmailContextMenuProps> = ({
    email,
    ...props
}) => {
    const editDialog = useEditScheduledEmailDialog()
    const deleteDialog = useDeleteScheduledEmailDialog()

    return <LockableMenu {...props} closeOnClick>
        <MenuItem onClick={() => editDialog.open({ email })}>
            <ListItemIcon><Edit color="primary" /></ListItemIcon>
            <ListItemText primary="Edit" />
        </MenuItem>
        <MenuItem onClick={() => deleteDialog.open({ email })}>
            <ListItemIcon><Delete color="error" /></ListItemIcon>
            <ListItemText primary="Delete" />
        </MenuItem>
    </LockableMenu>
}

export const useScheduledEmailContextMenu = createPopupHook({
    element: ScheduledEmailContextMenu,
    scope: Scope.Event,
})