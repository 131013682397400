import { AppTemplateDeploymentPermission, Permission } from "@marketpartner/backend-api"
import { DynamicFormState } from "@marketpartner/mp-common-react"
import { Grid, TextField } from "@mui/material"
import { FC } from "react"
import { AppTemplateDeploymentPermissionSelect } from "src/app-templates/AppTemplateDeploymentPermissionSelect"
import { DeploymentLocationEditor } from "src/app-templates/deployments/DeploymentLocationEditor"
import { DeploymentParametersEditor } from "src/app-templates/deployments/DeploymentParametersEditor"
import { useLoginContext } from "src/login/LoginContext"

export type AppDeploymentFormControlsProps = {
    clientId: string | null
    eventId: string | null
    id: string
    name: string
    onChangeName: (name: string) => void
    domainName: string | null
    onChangeDomainName: (domainName: string | null) => void
    path: string
    onChangePath: (path: string) => void
    editPermission: AppTemplateDeploymentPermission
    onChangeEditPermission: (editPermission: AppTemplateDeploymentPermission) => void
    adminParameters: string[]
    onChangeAdminParameters: (adminParameters: string[]) => void
    parametersFormState: DynamicFormState
}

export const AppDeploymentFormControls: FC<AppDeploymentFormControlsProps> = ({
    clientId,
    eventId,
    id,
    name,
    onChangeName,
    domainName,
    onChangeDomainName,
    path,
    onChangePath,
    editPermission,
    onChangeEditPermission,
    adminParameters,
    parametersFormState,
}) => {
    const login = useLoginContext()
    const isAdmin = login.hasPermission(Permission.AppAdmin)

    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <DeploymentLocationEditor
                clientId={clientId}
                domainName={domainName}
                onChangeDomainName={onChangeDomainName}
                path={path}
                onChangePath={onChangePath}
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                label="Name"
                value={name}
                onChange={e => onChangeName(e.target.value)}
                fullWidth
                required
            />
        </Grid>
        {isAdmin && <Grid item xs={4}>
            <AppTemplateDeploymentPermissionSelect
                label="Edit permission"
                value={editPermission}
                onChange={onChangeEditPermission}
                fullWidth
            />
        </Grid>}
        <Grid item xs={12}>
            <DeploymentParametersEditor
                clientId={clientId}
                eventId={eventId}
                deploymentId={id}
                adminParameters={adminParameters}
                formState={parametersFormState}
            />
        </Grid>
    </Grid>
}