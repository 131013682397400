import { EventDetails, filterFormFields, FormElementConfig, RegistrationCategory } from "@marketpartner/backend-api";
import { useMemo } from "react";
import { useEvent } from "src/events/event-context";

export type RegistrationCategoryFieldStatus = "required" | "optional" | "missing"

export type RegistrationCategoryCompatibilityIssue = {
    fieldName: string
    level: "warning" | "error"
    message: string
}

export type RegistrationCategoryCompatibility = {
    level?: "warning" | "error"
    regFormIssues: RegistrationCategoryCompatibilityIssue[]
    profileFormIssues: RegistrationCategoryCompatibilityIssue[]
    guestFormIssues: RegistrationCategoryCompatibilityIssue[]
}

export const useRegistrationCategoryCompatibility = (
    sourceEvent: EventDetails | null,
    sourceCategory: RegistrationCategory | null,
    targetCategory: RegistrationCategory | null,
    copyGuests: boolean,
): RegistrationCategoryCompatibility => {
    const event = useEvent()!

    return useMemo(
        () => checkRegistrationCategoryCompatibility(sourceEvent, event, sourceCategory, targetCategory, copyGuests),
        [sourceEvent, event, sourceCategory, targetCategory, copyGuests],
    )
}

export const checkRegistrationCategoryCompatibility = (
    sourceEvent: EventDetails | null,
    targetEvent: EventDetails | null,
    sourceCategory: RegistrationCategory | null,
    targetCategory: RegistrationCategory | null,
    copyGuests: boolean,
): RegistrationCategoryCompatibility => {
    const regFormIssues = compareForms(
        sourceCategory?.elements ?? [],
        targetCategory?.elements ?? [],
        "category",
    )
    const profileFormIssues = compareForms(
        sourceCategory?.profileElements ?? [],
        targetCategory?.profileElements ?? [],
        "category",
    )
    const guestFormIssues = copyGuests ? compareForms(
        sourceEvent?.guestElements ?? [],
        targetEvent?.guestElements ?? [],
        "event",
    ) : []

    let level: "warning" | "error" | undefined
    if (regFormIssues.length || profileFormIssues.length || guestFormIssues.length) {
        level = [...regFormIssues, ...profileFormIssues, ...guestFormIssues]
            .some(issue => issue.level === "error") ? "error" : "warning"
    }

    return {
        level,
        regFormIssues,
        profileFormIssues,
        guestFormIssues,
    }
}

const compareForms = (
    sourceElements: FormElementConfig[],
    targetElements: FormElementConfig[],
    configType: "event" | "category"
): RegistrationCategoryCompatibilityIssue[] => {
    const issues: RegistrationCategoryCompatibilityIssue[] = []
    const sourceFields = filterFormFields(sourceElements)
    const targetFields = filterFormFields(targetElements)

    for (const sourceField of sourceFields) {
        const targetField = targetFields.find(field => field.name === sourceField.name)

        if (!targetField) {
            issues.push({
                fieldName: sourceField.name,
                level: "warning",
                message: `Missing in target ${configType}, values will not be copied.`,
            })
        } else if (sourceField.type !== targetField.type) {
            issues.push({
                fieldName: sourceField.name,
                level: "warning",
                message: `Source is of type ${sourceField.type} but target is of type ${targetField.type}. Copying may fail.`,
            })
        }
    }

    for (const targetField of targetFields) {
        const sourceField = sourceFields.find(field => field.name === targetField.name)

        if (targetField.required) {
            if (!sourceField) {
                issues.push({
                    fieldName: targetField.name,
                    level: "error",
                    message: `Required, but not present in source ${configType}.`,
                })
            } else if (!sourceField.required) {
                issues.push({
                    fieldName: targetField.name,
                    level: "error",
                    message: `Required, but not marked as required in source ${configType}.`,
                })
            }
        }
    }

    return issues
}