import { isCustomContentCategory, reorderBody, Scope } from "@marketpartner/backend-api";
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { EditableDialogList } from "src/common/dialogs/EditableDialogList";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { useCreateContentCategoryDialog } from "src/content/categories/CreateContentCategoryDialog";
import { useDeleteContentCategoryDialog } from "src/content/categories/DeleteContentCategoryDialog";
import { useEditContentCategoryDialog } from "src/content/categories/EditContentCategoryDialog";
import { useEvent } from "src/events/event-context";


export type ContentCategoriesDialogProps = LockableDialogProps

const ContentCategoriesDialog: FC<ContentCategoriesDialogProps> = (props) => {
    const client = useClient()!
    const event = useEvent()!
    const categoriesQuery = backend.contentCategories.useGetCategories([client.id, event.id])
    const reorderMutation = backend.contentCategories.useReorderCategories(withNotification({
        errorMessage: "Error reordering categories",
    }))
    const createCategoryDialog = useCreateContentCategoryDialog()
    const editCategoryDialog = useEditContentCategoryDialog()
    const deleteCategoryDialog = useDeleteContentCategoryDialog()

    return <LockableDialog
        maxWidth="xs"
        fullWidth
        {...props}
    >
        <DialogTitle>Custom content categories</DialogTitle>
        <EditableDialogList
            itemsQuery={categoriesQuery}
            getPrimaryText={category => category.pluralName}
            getSecondaryText={category => category.id}
            addProps={{
                onClickAdd: () => createCategoryDialog.open({})
            }}
            contextMenuProps={category => ({
                onClickEdit: () => editCategoryDialog.open({ category }),
                onClickDelete: isCustomContentCategory(category) ?
                    () => deleteCategoryDialog.open({ category }) :
                    undefined,
            })}
            reorderProps={{
                onReorder: items => reorderMutation.mutateAsync([client.id, event.id, reorderBody(items)])
            }}
        />
    </LockableDialog>
}

export const useContentCategoriesDialog = createPopupHook({
    element: ContentCategoriesDialog,
    scope: Scope.Event,
})