import { NetworkingProfile } from "@marketpartner/backend-api"
import { casualName } from "@marketpartner/mp-common"
import { ExternalLink } from "@marketpartner/mp-common-mui"
import { Check, Close } from "@mui/icons-material"
import { Avatar, AvatarProps, Box, Button, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { Bar } from "src/common/layout/Bar"
import { Spacer } from "src/common/layout/Spacer"
import { link } from "src/common/routing/routes"
import { LinkedIn } from "src/common/social/LinkedIn"
import { Twitter } from "src/common/social/Twitter"
import { useEvent } from "src/events/event-context"

export type LegacyNetworkingProfileContentProps = {
    profile: NetworkingProfile
    hideRegistrationLink: boolean
    onClose: () => unknown
}

export const LegacyNetworkingProfileContent: FC<LegacyNetworkingProfileContentProps> = ({
    profile,
    hideRegistrationLink,
    onClose,
}) => {
    const client = useClient()!
    const event = useEvent()!

    return <>
        <DialogTitle sx={{
            display: "flex",
            alignItems: "center",
        }}>
            <ProfileAvatar profile={profile} sx={{ mr: 2 }} />
            {casualName(profile)}
            {profile.linkedinUsername && <LinkedIn username={profile.linkedinUsername} sx={{ ml: 1 }} />}
            {profile.twitterUsername && <Twitter username={profile.twitterUsername} sx={{ ml: 1 }} />}
        </DialogTitle>
        <DialogContent>
            <ProfileDetails profile={profile} />
        </DialogContent>
        <DialogActions>
            {!hideRegistrationLink && <ExternalLink href={'#' + link.toRegistrations(client.id, event.id, profile.registrationId)}>
                Registration
            </ExternalLink>}
            <Spacer />
            <Button onClick={onClose} color="inherit">
                Close
            </Button>
        </DialogActions>
    </>
}

type ProfileAvatarProps = AvatarProps & {
    profile: NetworkingProfile
}

const ProfileAvatar: FC<ProfileAvatarProps> = ({
    profile,
    ...props
}) => {
    return <Avatar
        {...props}
        src={profile.avatarUrl ?? "none"}
        alt={casualName(profile)}
    />
}

const ProfileDetails = ({ profile }: {
    profile: NetworkingProfile
}) => <Stack spacing={2}>
        <JobDetails profile={profile} />
        {profile.bioMd && <Bio bioMd={profile.bioMd} />}
        <DmOptIn optIn={profile.directMessageOptIn} />
    </Stack>

const JobDetails = ({ profile }: {
    profile: NetworkingProfile
}) => <Box>
        <Typography>
            {profile.jobTitle}
        </Typography>
        <Typography variant="body2" color="text.secondary">
            {profile.company}
        </Typography>
    </Box>

const Bio = ({ bioMd }: {
    bioMd: string
}) => <Box>
        <Typography>
            Bio
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{
            whiteSpace: "pre"
        }}>
            {bioMd}
        </Typography>
    </Box>

const DmOptIn = ({ optIn }: {
    optIn: boolean
}) => <Bar spacing={1}>
        <Typography>
            Direct message opt-in:
        </Typography>
        {optIn ? <Check color="success" /> : <Close color="error" />}
    </Bar>
