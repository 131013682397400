import { StructuredBreakout } from "@marketpartner/backend-api"
import { FC, Fragment } from "react"
import { Bar } from "src/common/layout/Bar"
import { EditableAgendaItem } from "src/content/agendas/EditableAgendaItem"
import { EditableTrack } from "src/content/agendas/tracks/EditableTrack"


export type EditableBreakoutProps = {
    breakout: StructuredBreakout
}

export const EditableBreakout: FC<EditableBreakoutProps> = ({
    breakout
}) => {
    return <EditableAgendaItem
        item={breakout}
        headerText={breakout.name || <i>Breakout</i>}
    >
        <Bar
            spacing={{ xs: 1, sm: 2, md: 4 }}
            mt={2}
            alignSelf="stretch"
            overflow="auto"
        >
            {breakout.tracks.map(track => <Fragment key={track.id}>
                <EditableTrack
                    track={track}
                    start={breakout.start}
                    sx={{ flexGrow: 1 }}
                />
            </Fragment>)}
        </Bar>
    </EditableAgendaItem>
}

