import { Permission } from "@marketpartner/backend-api"
import { Checkbox, Collapse, FormControlLabel, FormHelperText } from "@mui/material"
import { FC } from "react"
import { ifHas } from "src/login/IfHas"


export type PublicAccessTokensSelectorProps = {
    enablePublicAccessTokens: boolean
    onChange: (enablePublicAccessTokens: boolean) => void
}

export const PublicAccessTokensSelector: FC<PublicAccessTokensSelectorProps> = ({
    enablePublicAccessTokens,
    onChange,
}) => {
    return ifHas(Permission.AdministerSystem, undefined, undefined, <>
        <FormControlLabel
            label="Allow event access without emailed link"
            control={<Checkbox
                checked={enablePublicAccessTokens}
                onChange={() => onChange(!enablePublicAccessTokens)}
            />}
        />
        <Collapse in={enablePublicAccessTokens}>
            <FormHelperText sx={{ color: theme => theme.palette.warning.main }}>
                Anyone may be able to access the event by using an approved guest's email address
            </FormHelperText>
        </Collapse>
    </>)
}