import { CreatePollBody, Scope } from "@marketpartner/backend-api"
import { DialogTitle } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog"
import { createPopupHook } from "src/common/dialogs/Popup"
import { withNotification } from "src/common/notifications/with-notification"
import { useEvent } from "src/events/event-context"
import { PollForm } from "src/polls/PollForm"

type NewPollDialogProps = LockableDialogProps & {
    onCreate?: (poll: CreatePollBody) => void
}
export const NewPollDialog: FC<NewPollDialogProps> = ({
    onCreate = () => { },
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!

    const createMutation = backend.polls.useCreate(withNotification({
        successMessage: "Created poll",
        errorMessage: "Error creating poll",
        onSuccess: (_, [_clientId, _eventId, poll]) => onCreate(poll)
    }))

    return <LockableDialog maxWidth="sm" {...props}>
        <DialogTitle>Create poll</DialogTitle>
        <PollForm
            actionName="Create"
            onSubmit={pollDetails => createMutation.mutateAsync([client.id, event.id, pollDetails])}
        />
    </LockableDialog>
}

export const useNewPollDialog = createPopupHook({
    element: NewPollDialog,
    scope: Scope.Event,
})