import { RegistrationApprovalState } from "@marketpartner/backend-api"
import { useParallelMutation, UseParallelMutationOptions } from "@marketpartner/mp-common-react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { useEvent } from "src/events/event-context"

export type BulkRegistrationApprovalState = Exclude<RegistrationApprovalState, RegistrationApprovalState.PendingApproval>
export type BulkRegistrationState = BulkRegistrationApprovalState | "Delete"

export type UseBulkApprovalOptions = Partial<UseParallelMutationOptions<void, string>> & {
    newStatus: BulkRegistrationState
}

export const useBulkApproval = ({
    newStatus,
    ...options
}: UseBulkApprovalOptions) => {
    const client = useClient()!
    const event = useEvent()!

    const approveMutation = backend.registrations.useApproveRegistration({
        disableInvalidation: true,
    })
    const rejectMutation = backend.registrations.useRejectRegistration({
        disableInvalidation: true,
    })
    const cancelMutation = backend.registrations.useCancelRegistration({
        disableInvalidation: true,
    })
    const deleteMutation = backend.registrations.useDeleteRegistration({
        disableInvalidation: true,
    })
    const getMutation = () => {
        switch (newStatus) {
            case RegistrationApprovalState.Approved:
                return approveMutation
            case RegistrationApprovalState.Rejected:
                return rejectMutation
            case RegistrationApprovalState.Cancelled:
                return cancelMutation
            case "Delete":
                return deleteMutation
        }
    }
    const invalidate = backend.registrations.useInvalidate()

    return useParallelMutation({
        processItem: async (id: string) => getMutation().mutateAsync([
            client.id,
            event.id,
            id,
        ]),
        onSettled: async (data, error, variables, context) => {
            await invalidate()
            await options.onSettled?.(data, error, variables, context)
        },
    })
}