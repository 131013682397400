import { RelatedContentCategory, Scope } from "@marketpartner/backend-api"
import { DialogTitle } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog"
import { createPopupHook } from "src/common/dialogs/Popup"
import { withNotification } from "src/common/notifications/with-notification"
import { RelatedContentCategoryForm } from "src/content/related-content-categories/RelatedContentCategoryForm"
import { useEvent } from "src/events/event-context"

export type EditRelatedContentCategoryDialogProps = LockableDialogProps & {
    category: RelatedContentCategory
}

const EditRelatedContentCategoryDialog: FC<EditRelatedContentCategoryDialogProps> = ({
    category,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!

    const updateCategoryMutation = backend.relatedContentCategories.useUpdateCategory(withNotification({
        errorMessage: "Error updating category"
    }))

    return <LockableDialog
        maxWidth="sm"
        fullWidth
        {...props}
    >
        <DialogTitle>Edit linked content category</DialogTitle>
        <RelatedContentCategoryForm
            actionName="Save"
            category={category}
            onSubmit={formData => updateCategoryMutation.mutateAsync([client.id, event.id, category.id, formData])}
        />
    </LockableDialog>
}

export const useEditRelatedContentCategoryDialog = createPopupHook({
    element: EditRelatedContentCategoryDialog,
    scope: Scope.Event,
})