import { compact, keyBy } from "lodash"
import { useLayoutEffect } from "react"
import { ColumnMatch, ColumnMatches } from "src/registrations/import/pre-process/auto-match-columns"
import { ExtractedFormField } from "src/registrations/import/pre-process/extract-form-fields"
import { useImmer } from "use-immer"

export type UseColumnMatches = {
    matches: ColumnMatches
    isComplete: boolean
    setColumnMatch: (csvColumn: string, formFieldName: string) => void
    ignoreColumn: (csvColumn: string) => void
    fieldOptionsForMatch: (match: ColumnMatch | null) => ExtractedFormField[]
    unmatchedCount: number
}

export const useColumnMatches = (
    autoMatchedColumns: ColumnMatches | undefined,
    formFields: ExtractedFormField[]
): UseColumnMatches => {
    const [matches, updateMatches] = useImmer<ColumnMatches>({})
    const fieldsByName = keyBy(formFields, "name")
    const matchedFields = compact(Object.values(matches)
        .map(match => match?.type === "field" ?
            match.formField :
            undefined
        )
    )
    const unmatchedFields = Object.values(fieldsByName)
        .filter(it => !matchedFields.includes(it))

    useLayoutEffect(() => {
        if (!autoMatchedColumns) {
            updateMatches({})
            return
        }

        updateMatches(autoMatchedColumns)
    }, [autoMatchedColumns, updateMatches])

    const setColumnMatch = (csvColumn: string, formFieldName: string) => {
        updateMatches(matches => {
            matches[csvColumn] = {
                type: "field",
                formField: fieldsByName[formFieldName],
            }
        })
    }

    const ignoreColumn = (csvColumn: string) => {
        updateMatches(matches => {
            matches[csvColumn] = {
                type: "ignored"
            }
        })
    }

    const fieldOptionsForMatch = (match: ColumnMatch | null) => {
        switch (match?.type) {
            case undefined: return unmatchedFields
            case "ignored": return unmatchedFields
            case "field": return [
                match.formField,
                ...unmatchedFields,
            ]
            default: return []
        }
    }

    const unmatchedCount = Object.values(matches).filter(it => it === null).length
    return {
        matches,
        isComplete: unmatchedCount === 0,
        setColumnMatch,
        ignoreColumn,
        fieldOptionsForMatch,
        unmatchedCount,
    }
}
