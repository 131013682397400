import { Delete, Edit } from "@mui/icons-material"
import { IconButton, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { FC } from "react"
import { Bar } from "src/common/layout/Bar"
import { useDeleteFormElementDialog } from "src/forms/elements/DeleteFormElementDialog"
import { useEditableFormElementsContext } from "src/forms/elements/EditableFormElementsContext"
import { useEditFormElementDialog } from "src/forms/elements/EditFormElementDialog"

export type FormElementsGridProps = {
    sx?: SxProps
}

export const FormElementsGrid: FC<FormElementsGridProps> = ({
    sx,
}) => {
    const { elements, canEdit, renderExtraControls, onChangeElementIdx, onDeleteElementIdx } = useEditableFormElementsContext()

    const editDialog = useEditFormElementDialog()
    const deleteDialog = useDeleteFormElementDialog()

    return <TableContainer sx={sx}>
        <Table size="small" stickyHeader>
            <TableHead>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Required</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {elements.map((element, idx) =>
                    <TableRow key={idx}>
                        <TableCell>
                            {"name" in element && element.name}
                        </TableCell>
                        <TableCell>
                            {element.type}
                        </TableCell>
                        <TableCell>
                            {'required' in element ? `${element.required ? 'Yes' : 'No'}` : 'N/a'}
                        </TableCell>
                        <TableCell>
                            {canEdit && <Bar justifyContent="flex-end">
                                {renderExtraControls?.(element)}
                                <IconButton color="primary" onClick={() => editDialog.open({
                                    element,
                                    onChangeElement: onChangeElementIdx(idx),
                                })}>
                                    <Edit />
                                </IconButton>
                                <IconButton color="error" onClick={() => deleteDialog.open({
                                    element,
                                    onDelete: onDeleteElementIdx(idx),
                                })}>
                                    <Delete />
                                </IconButton>
                            </Bar>}
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    </TableContainer>
}