import { ButtonBase, ButtonBaseProps, Typography } from "@mui/material"
import { Interval } from "luxon"
import { ElementType } from "react"

export type NetworkingTimeslotStyle = "green" | "default" | "disabled"

export type NetworkingTimeslotProps<C extends ElementType = 'button'> = ButtonBaseProps<C, { component?: C }> & {
    timeslot: Interval
    secondaryText?: string
    timeslotStyle: NetworkingTimeslotStyle
}

const styles: Record<NetworkingTimeslotStyle, ButtonBaseProps["sx"]> = {
    default: {},
    green: {
        backgroundColor: "lightgreen",
        borderColor: "text.secondary"
    },
    disabled: {
        color: "text.disabled",
        borderColor: "text.disabled",
    }
}

export const NetworkingTimeslot = <C extends ElementType = 'button'>({
    timeslot,
    secondaryText,
    timeslotStyle,
    ...props
}: NetworkingTimeslotProps<C>) => {
    const pointerEvents = props.onClick ? props.pointerEvents : 'none'

    return <ButtonBase {...props} sx={{
        py: 1,
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: 2,
        border: "1px solid black",
        pointerEvents,
        ...styles[timeslotStyle],
        ...props?.sx
    }}>
        <Typography variant="body1">{timeslot.start.toFormat("HH:mm")}</Typography>
        {secondaryText && <Typography variant="body2">{secondaryText}</Typography>}
    </ButtonBase>
}