import { NetworkingPeriod, Scope } from "@marketpartner/backend-api"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { ConfirmDeleteDialog } from "src/common/dialogs/ConfirmDeleteDialog"
import { LockableDialogProps } from "src/common/dialogs/LockableDialog"
import { createPopupHook } from "src/common/dialogs/Popup"
import { withNotification } from "src/common/notifications/with-notification"
import { useEvent } from "src/events/event-context"

export type DeleteNetworkingPeriodDialogProps = LockableDialogProps & {
    period: NetworkingPeriod
}

const DeleteNetworkingPeriodDialog: FC<DeleteNetworkingPeriodDialogProps> = ({
    period,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!

    const deleteMutation = backend.networkingPeriods.useDeletePeriod(withNotification({
        errorMessage: "Error deleting networking period"
    }))

    return <ConfirmDeleteDialog
        itemName={period.name}
        formProps={{
            action: () => deleteMutation.mutateAsync([client.id, event.id, period.id]),
            text: <>This will <b>not</b> remove any existing meeting/location availability. Availability must be manually cleared first.</>,
        }}
        {...props}
    />
}

export const useDeleteNetworkingPeriodDialog = createPopupHook({
    element: DeleteNetworkingPeriodDialog,
    scope: Scope.Event,
})