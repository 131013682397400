import { EventPartnerInstance } from "@marketpartner/backend-api"
import { LoadedSelector, LoadedSelectorProps } from "@marketpartner/mp-common-mui"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"

export type EventPartnerSelectProps = Omit<LoadedSelectorProps<string, EventPartnerInstance>, "fetchRequest">

export const EventPartnerSelect: FC<EventPartnerSelectProps> = ({
    ...props
}) => {
    const client = useClient()!
    const eventPartnerRequest = backend.content.useGetAllEventPartnerInstances([client.id])

    return <LoadedSelector
        label="Event Partner instance"
        fetchRequest={eventPartnerRequest}
        {...props}
    />
}