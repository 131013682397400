import { CustomContentCategoryId, reorderBody, Scope } from "@marketpartner/backend-api";
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { EditableDialogList } from "src/common/dialogs/EditableDialogList";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { useCreateContentSubCategoryDialog } from "src/content/sub-categories/CreateContentSubCategoryDialog";
import { useDeleteContentSubCategoryDialog } from "src/content/sub-categories/DeleteContentSubCategoryDialog";
import { useEditContentSubCategoryDialog } from "src/content/sub-categories/EditContentSubCategoryDialog";
import { useEvent } from "src/events/event-context";


export type ContentSubCategoriesDialogProps = LockableDialogProps & {
    categoryId: CustomContentCategoryId
}

const ContentSubCategoriesDialog: FC<ContentSubCategoriesDialogProps> = ({
    categoryId,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const subCategoriesQuery = backend.contentCategories.useGetCategories([client.id, event.id], {
        select: data => data
            .find(it => it.id === categoryId)!
            .subCategories
    })
    const reorderMutation = backend.contentCategories.useReorderSubCategories(withNotification({
        errorMessage: "Error reordering sub-categories",
    }))

    const createSubCategoryDialog = useCreateContentSubCategoryDialog()
    const editSubCategoryDialog = useEditContentSubCategoryDialog()
    const deleteSubCategoryDialog = useDeleteContentSubCategoryDialog()

    return <LockableDialog
        maxWidth="xs"
        fullWidth
        {...props}
    >
        <DialogTitle>Sub-categories</DialogTitle>
        <EditableDialogList
            itemsQuery={subCategoriesQuery}
            getPrimaryText={category => category.pluralName}
            getSecondaryText={category => category.id}
            addProps={{
                onClickAdd: () => createSubCategoryDialog.open({ categoryId })
            }}
            contextMenuProps={subCategory => ({
                onClickEdit: () => editSubCategoryDialog.open({ categoryId, subCategory }),
                onClickDelete: () => deleteSubCategoryDialog.open({ subCategory }),
            })}
            reorderProps={{
                onReorder: items => reorderMutation.mutateAsync([client.id, event.id, reorderBody(items)])
            }}
        />
    </LockableDialog>
}

export const useContentSubCategoriesDialog = createPopupHook({
    element: ContentSubCategoriesDialog,
    scope: Scope.Event,
})