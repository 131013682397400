import { BreakoutTrack, Scope } from "@marketpartner/backend-api";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { ConfirmDeleteDialog } from "src/common/dialogs/ConfirmDeleteDialog";
import { LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { useEvent } from "src/events/event-context";


type DeleteBreakoutTrackDialogProps = LockableDialogProps & {
    track: BreakoutTrack
}

const DeleteBreakoutTrackDialog: FC<DeleteBreakoutTrackDialogProps> = ({
    track,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const deletion = backend.agendaItems.useDelete()

    return <ConfirmDeleteDialog
        itemName={track.name || `Track ${track.order + 1}`}
        formProps={{
            action: () => deletion.mutateAsync([client.id, event.id, track.agendaId, track.id]),
            text: <></>,
        }}
        {...props}
    />
}

export const useDeleteBreakoutTrackDialog = createPopupHook({
    scope: Scope.Event,
    element: DeleteBreakoutTrackDialog,
})
