import { ImportRegistrationsResult } from "@marketpartner/backend-api"
import { Check, Clear } from "@mui/icons-material"
import { SxProps, Typography } from "@mui/material"
import { countBy } from "lodash"
import { FC } from "react"
import { Bar } from "src/common/layout/Bar"

export type ResultCountsProps = {
    results: ImportRegistrationsResult[]
    sx?: SxProps
}

export const ResultCounts: FC<ResultCountsProps> = ({
    results,
    sx,
}) => {

    const resultCounts = countBy(results, "type")

    return <Bar spacing={1} sx={sx}>
        <Check color="success" />
        <Typography>Imported: <b>{resultCounts["success"] ?? 0}</b></Typography>
        <Clear color="error" />
        <Typography>Errors: <b>{resultCounts["error"] ?? 0}</b> (displayed below)</Typography>
    </Bar>
}