import { findFormField, FormFieldType } from "@marketpartner/backend-api";
import { FormElementCheck } from "src/forms/elements/checks/form-element-checks";


export const elementTypeCheck = (
    name: string,
    type: FormFieldType
): FormElementCheck => (elements) => {
    const element = findFormField(elements, name)

    if (element && element.type !== type) {
        return {
            elementIdxs: [elements.indexOf(element)],
            renderIssueContent: () => <>
                <b>{name}</b> is usually of type <b>{type}</b> (currently {element.type})
            </>,
            level: "warning",
        }
    }
}