import { Fade, LinearProgress, Menu, MenuProps } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { createContext, FC, useCallback, useContext } from "react";


export type LockableMenuProps = MenuProps & {
    onClose: () => void
    loading?: boolean
    closeOnClick?: boolean
}

export type LockableMenuContext = {
    closeAfter: (promise: Promise<unknown>) => unknown
}

const context = createContext<LockableMenuContext>(undefined!)

export const useMenuContext = () => useContext(context)

export const LockableMenu: FC<LockableMenuProps> = ({
    onClose,
    loading = false,
    closeOnClick = false,
    children,
    ...props
}) => {
    const loadingMutation = useMutation({
        mutationFn: (actionPromise: Promise<unknown>) => actionPromise,
        onSuccess: onClose,
    })

    const isLoading = loadingMutation.isPending || loading

    const tryClose = useCallback(() => {
        if (!isLoading) {
            onClose()
        }
    }, [isLoading, onClose])

    return <context.Provider
        value={{
            closeAfter: loadingMutation.mutateAsync,
        }}
        children={<Menu
            {...props}
            onClose={tryClose}
            onClick={() => closeOnClick && tryClose()}
            sx={{
                pointerEvents: isLoading ? "none" : undefined,
                inert: isLoading ? "inert" : undefined,
                "& .MuiButtonBase-root, & svg": {
                    color: theme => isLoading ? theme.palette.text.disabled : undefined,
                }
            }}
        >
            {children}
            <Fade in={loadingMutation.isPending || loading}>
                <LinearProgress />
            </Fade>
        </Menu>}
    />
}
