import { ContentCategory, ContentEntity, ContentSubCategory, reorderBody } from "@marketpartner/backend-api"
import { Typography } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { StickyAccordian } from "src/common/layout/StickyAccordian"
import { withNotification } from "src/common/notifications/with-notification"
import { ReorderableStack } from "src/common/reordering/ReorderableStack"
import { ReorderButtons } from "src/common/reordering/ReorderButtons"
import { useBulkReorder } from "src/common/reordering/useBulkReorder"
import { AddContentCard } from "src/content/AddContentCard"
import { ContentEntityCard } from "src/content/entities/ContentEntityCard"
import { useContentEntityContextMenu } from "src/content/entities/ContentEntityContextMenu"
import { useCreateContentEntityDialog } from "src/content/entities/CreateContentEntityDialog"
import { useEvent } from "src/events/event-context"


export type SubCategoryContentProps = {
    category: ContentCategory
    subCategory?: ContentSubCategory
    entities: ContentEntity[]
}

export const SubCategoryContent: FC<SubCategoryContentProps> = ({
    category,
    subCategory,
    entities,
}) => {
    const client = useClient()!
    const event = useEvent()!

    const reorderMutation = backend.contentEntities.useReorderEntities(withNotification({
        errorMessage: "Error reordering content."
    }))

    const reorder = useBulkReorder({
        items: entities,
        onReorder: items => reorderMutation.mutateAsync([client.id, event.id, reorderBody(items)]),
    })

    const createEntityDialog = useCreateContentEntityDialog()
    const entityContextMenu = useContentEntityContextMenu()

    return <StickyAccordian
        headingContent={<Typography variant="h6">
            {subCategory?.pluralName ?? `Uncategorized ${category.pluralName}`} ({entities.length})
        </Typography>}
        controls={<ReorderButtons
            reorder={reorder}
            reorderButtonProps={{
                children: `Reorder ${subCategory?.pluralName ?? category.pluralName}`
            }}
        />}
    >
        <ReorderableStack
            variant="grid"
            reorderEnabled={reorder.isReordering}
            items={reorder.orderedItems}
            onReorder={reorder.reorderItems}
            loading={reorder.isLoading}
            renderItem={entity => <ContentEntityCard
                entity={entity}
                onClickMenu={e => entityContextMenu.open({
                    entity,
                    anchorEl: e.currentTarget,
                })}
            />}
            addItem={<AddContentCard onClick={() => createEntityDialog.open({
                category,
                subCategory,
            })} />}
            spacing={1}
            useFlexGap
        />
    </StickyAccordian >
}