import { Scope } from "@marketpartner/backend-api";
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { RelatedContentCategoryForm } from "src/content/related-content-categories/RelatedContentCategoryForm";
import { useEvent } from "src/events/event-context";


export type CreateRelatedContentCategoryDialogProps = LockableDialogProps

const CreateRelatedContentCategoryDialog: FC<CreateRelatedContentCategoryDialogProps> = (props) => {
    const client = useClient()!
    const event = useEvent()!

    const createCategoryMutation = backend.relatedContentCategories.useCreateCategory(withNotification({
        errorMessage: "Error creating category"
    }))

    return <LockableDialog
        maxWidth="sm"
        fullWidth
        {...props}
    >
        <DialogTitle>Create linked content category</DialogTitle>
        <RelatedContentCategoryForm
            actionName="Create"
            onSubmit={formData => createCategoryMutation.mutateAsync([client.id, event.id, formData])}
        />
    </LockableDialog>
}

export const useCreateRelatedContentCategoryDialog = createPopupHook({
    element: CreateRelatedContentCategoryDialog,
    scope: Scope.Event,
})