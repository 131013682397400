import { FC } from "react";
import { DerivedTextField, DerivedTextFieldProps } from "src/common/form-inputs/DerivedTextField";


export type PluralTextFieldProps = Omit<DerivedTextFieldProps, "deriveValue">

export const PluralTextField: FC<PluralTextFieldProps> = ({
    ...props
}) => {
    return <DerivedTextField
        {...props}
        deriveValue={singular => singular + "s"}
        label="Plural Name"
    />
}