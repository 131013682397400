import { AgendaItemType, Location, Scope } from "@marketpartner/backend-api";
import { DeleteForever, Edit } from "@mui/icons-material";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { createPopupHook } from "src/common/dialogs/Popup";
import { LockableMenu, LockableMenuProps } from "src/common/menus/LockableMenu";
import { useDeleteLocationDialog } from "src/content/locations/DeleteLocationDialog";
import { useEditLocationDialog } from "src/content/locations/EditLocationDialog";
import { useEvent } from "src/events/event-context";


export type LocationContextMenuProps = LockableMenuProps & {
    location: Location
}

const LocationContextMenu: FC<LocationContextMenuProps> = ({
    location,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const editDialog = useEditLocationDialog()
    const deleteDialog = useDeleteLocationDialog()

    const sessionsQuery = backend.agendas.useGetAll([client.id, event.id], {
        select: agendas => agendas
            .flatMap(agenda => agenda.flatItems)
            .filter(item => item.type === AgendaItemType.Session && item.locationId === location.id)
            .length,
    })

    const canDelete = location.meetingCount === 0 && sessionsQuery.data === 0

    return <LockableMenu
        closeOnClick
        {...props}
    >
        <MenuItem onClick={() => editDialog.open({ location })}>
            <ListItemIcon><Edit color="primary" /></ListItemIcon>
            <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => deleteDialog.open({ location })} disabled={!canDelete}>
            <ListItemIcon><DeleteForever color="error" /></ListItemIcon>
            <ListItemText>Delete{canDelete ? "" : " (must be unused)"}</ListItemText>
        </MenuItem>
    </LockableMenu>
}

export const useLocationContextMenu = createPopupHook({
    element: LocationContextMenu,
    scope: Scope.Event,
})