import { getErrorString } from '@marketpartner/mp-common';
import { Autocomplete, AutocompleteProps, Collapse, FormControl, FormControlProps, FormHelperText, MenuItem, TextField } from "@mui/material";
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { UseQueryResult } from '@tanstack/react-query';
import { ReactElement, ReactNode, useEffect } from 'react';

type Identifiable<Id extends string | number> = {
    id: Id
    name?: string
}

type HelperContentProp<T> = ReactNode | ((selectedItem: T | null) => ReactNode)

export type LoadedAutocompleteProps<
    Id extends string | number,
    T extends Identifiable<Id>
> = Omit<AutocompleteProps<T, false, false, false>, "onChange" | "value" | "options" | "renderInput"> &
    Omit<FormControlProps, "onChange" | "value"> &
    {
        defaultValue?: T | null
        label?: string
        fetchRequest: UseQueryResult<T[]>
        selectedId: Id | null
        onSelectId: (id: Id | null) => unknown
        onSelectLoadedItem?: (item: T | null) => unknown
        extraOptions?: ReactNode
        helperContent?: HelperContentProp<T>
        getSecondaryText?: (item: T) => string | undefined
        disableValue?: (value: T) => boolean
        hideValue?: (value: T) => boolean
    }

export const LoadedAutocomplete = <
    Id extends string | number,
    T extends Identifiable<Id>
>({
    label,
    fetchRequest,
    selectedId,
    onSelectId,
    onSelectLoadedItem,
    required,
    extraOptions,
    disabled,
    helperContent,
    getSecondaryText = item => item.id.toString(),
    disableValue,
    hideValue,
    sx,
    defaultValue,
    placeholder,
    ...props
}: LoadedAutocompleteProps<Id, T>): ReactElement => {
    const selectedItem = fetchRequest.data?.find(option => option.id === selectedId) ?? null

    // Trigger onSelectLoadedItem either when the loaded data changes, or when the selection changes
    useEffect(() => {
        onSelectLoadedItem?.(selectedItem)
    }, [selectedItem, onSelectLoadedItem])

    const isDisabled = disabled || fetchRequest.isLoading
    const fetchError = fetchRequest.error && getErrorString(fetchRequest.error)
    const itemMissing = selectedId && (selectedItem === undefined || selectedItem === null)
    //TODO: check this is working
    const itemMissingError = fetchRequest.data && itemMissing

    const StyledPaper = styled(Paper)(() => ({
        minWidth: '300px'
    }));


    return <FormControl
        error={Boolean(itemMissingError || fetchError)}
        sx={sx}
        required={required}
        {...props}
    >
        <Autocomplete<T, false, false, false>
            {...props}
            value={selectedItem}
            disabled={isDisabled}
            onChange={(_, option) => onSelectId(option?.id ?? null)}
            defaultValue={defaultValue}
            options={fetchRequest.data ?? []}
            renderOption={(props, option) => <MenuItem
                key={option.id}
                value={option.id}
                disabled={disableValue?.(option)}
                sx={{
                    display: hideValue?.(option) ? "none" : "flex",
                    flexDirection: "column",
                    alignItems: "flex-start !important",
                }}
                {...props}
            >
                <ListItemText
                    primary={option.name ?? option.id}
                    secondary={getSecondaryText(option)}
                />
            </MenuItem>}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={option => `${option.name ?? option.id} (${getSecondaryText(option)})`}
            renderInput={(params) => (
                <TextField {...params} label={label} placeholder={placeholder} />
            )}
            PaperComponent={(props) => <StyledPaper {...props} />}
        />
        <HelperContent
            label={selectedItem?.name ?? selectedItem?.id}
            fetchError={Boolean(fetchError)}
            itemMissing={Boolean(itemMissingError)}
            selectedOption={selectedItem}
            helperContent={helperContent}
        />
    </FormControl >
}

type HelperContentProps<T> = {
    label: ReactNode,
    fetchError: boolean
    itemMissing: boolean
    selectedOption: T | null
    helperContent?: HelperContentProp<T>
}

const HelperContent = <T,>({
    label,
    fetchError,
    itemMissing,
    selectedOption,
    helperContent
}: HelperContentProps<T>) => {
    let content: ReactNode | undefined = undefined
    if (fetchError) {
        content = <>Error loading values</>
    } else if (itemMissing) {
        content = <>{label} does not exist</>
    } else if (typeof helperContent === 'function') {
        content = helperContent(selectedOption)
    } else {
        content = helperContent
    }

    return <Collapse in={Boolean(content)}>
        <FormHelperText>
            {content ?? "&nbsp;"}
        </FormHelperText>
    </Collapse>
}