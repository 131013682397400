import { ScheduledEmail, Scope } from "@marketpartner/backend-api";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { ConfirmDeleteDialog } from "src/common/dialogs/ConfirmDeleteDialog";
import { LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { useEvent } from "src/events/event-context";


export type DeleteScheduledEmailDialogProps = LockableDialogProps & {
    email: ScheduledEmail
}

const DeleteScheduledEmailDialog: FC<DeleteScheduledEmailDialogProps> = ({
    email,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!

    const deleteMutation = backend.scheduledEmails.useDelete(withNotification({
        errorMessage: "Error deleting scheduled email"
    }))

    return <ConfirmDeleteDialog
        itemName="scheduled email"
        formProps={{
            action: () => deleteMutation.mutateAsync([client.id, event.id, email.id]),
            text: ""
        }}
        {...props}
    />
}

export const useDeleteScheduledEmailDialog = createPopupHook({
    element: DeleteScheduledEmailDialog,
    scope: Scope.Event
})