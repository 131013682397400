import { commonEventFields, defaultEventFieldElementMetadata, Scope } from "@marketpartner/backend-api"
import { DialogTitle } from "@mui/material"
import { FC, useState } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { DialogForm } from "src/common/dialogs/DialogForm"
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog"
import { createPopupHook } from "src/common/dialogs/Popup"
import { withNotification } from "src/common/notifications/with-notification"
import { useEvent } from "src/events/event-context"
import { EventFieldElementMetadataForm } from "src/events/fields/EventFieldElementMetadataForm"
import { EventFieldElementMetadataIcons } from "src/events/fields/EventFieldElementMetadataIcons"
import { FormElementSourceType } from "src/forms/elements/copying/useFormElementSources"
import { EditableFormElements } from "src/forms/elements/EditableFormElements"


export type EventFieldElementsDialogProps = LockableDialogProps & EventFieldElementsDialogContentProps

const EventFieldElementsDialog: FC<EventFieldElementsDialogProps> = ({
    ...props
}) => {
    return <LockableDialog
        maxWidth="sm"
        fullWidth
        {...props}
    >
        <DialogTitle>Edit event fields</DialogTitle>
        <EventFieldElementsDialogContent />
    </LockableDialog>
}

export type EventFieldElementsDialogContentProps = {}

const EventFieldElementsDialogContent: FC<EventFieldElementsDialogContentProps> = () => {
    const client = useClient()!
    const event = useEvent()!

    const [fieldElements, setFieldElements] = useState(event.fieldElements)

    const updateEventMutation = backend.events.useUpdateEvent(withNotification({
        errorMessage: "Error updating event fields"
    }))

    return <DialogForm
        actionName="Save"
        formData={{}}
        onSubmit={() => updateEventMutation.mutateAsync([client.id, event.id, {
            fieldElements,
        }])}
    >
        <EditableFormElements
            defaultSourceType={FormElementSourceType.EventFields}
            elements={fieldElements}
            onChangeElements={setFieldElements}
            commonElements={commonEventFields}
            defaultMetadata={defaultEventFieldElementMetadata()}
            renderMetadataControls={(config, onChange) => <EventFieldElementMetadataForm
                config={config}
                onChange={onChange}
            />}
            renderExtraControls={config => <EventFieldElementMetadataIcons metadata={config.metadata} />}
            sizing="fit-content"
            canEdit
        />
    </DialogForm>
}

export const useEventFieldElementsDialog = createPopupHook({
    element: EventFieldElementsDialog,
    scope: Scope.Event,
})