import { Theme as AmplifyTheme, ThemeProvider as AmplifyThemeProvider } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import { createTheme, CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material'
import { FC, ReactNode } from 'react'

const mpMuiBaseTheme = createTheme({})

const mpMuiPalette = createTheme(mpMuiBaseTheme, {
    palette: {
        primary: mpMuiBaseTheme.palette.augmentColor({
            color: {
                main: '#0000ff',
            },
            name: 'primary',
        })
    },
} as const)

const mpMuiTheme = createTheme(mpMuiPalette, {
    typography: {
        h1: {
            fontSize: '3rem',
        },
        h2: {
            fontSize: '2.4rem'
        },
        h3: {
            fontSize: '2.2rem'
        },
        h4: {
            fontSize: '1.8rem'
        },
        h5: {
            fontSize: '1.4rem'
        },
        h6: {
            fontSize: '1.2rem',
            color: mpMuiPalette.palette.text.secondary,
        },
        subtitle1: {
            color: mpMuiPalette.palette.text.secondary,
        },
    },
    components: {
        MuiDialogTitle: {
            defaultProps: {
                color: mpMuiPalette.palette.text.primary,
            },
        },
    },
})

const mpAmplifyTheme: AmplifyTheme = {
    name: "MarketPartner",
    tokens: {
        colors: {
            primary: {
                "10": mpMuiPalette.palette.primary.main,
                "20": mpMuiPalette.palette.primary.light,
                "30": mpMuiPalette.palette.primary.light,
                "40": mpMuiPalette.palette.primary.light,
                "50": mpMuiPalette.palette.primary.light,
                "60": mpMuiPalette.palette.primary.light,
                "70": mpMuiPalette.palette.primary.light,
                "80": mpMuiPalette.palette.primary.light,
                "90": mpMuiPalette.palette.primary.light,
                "100": mpMuiPalette.palette.primary.light,
            },
            secondary: {
                "10": mpMuiPalette.palette.secondary.main,
                "20": mpMuiPalette.palette.secondary.light,
                "30": mpMuiPalette.palette.secondary.light,
                "40": mpMuiPalette.palette.secondary.light,
                "50": mpMuiPalette.palette.secondary.light,
                "60": mpMuiPalette.palette.secondary.light,
                "70": mpMuiPalette.palette.secondary.light,
                "80": mpMuiPalette.palette.secondary.light,
                "90": mpMuiPalette.palette.secondary.light,
                "100": mpMuiPalette.palette.secondary.light,
            },
        },
        components: {
            authenticator: {
                router: {
                    borderColor: "white",
                }
            },
            button: {
                _hover: {
                    backgroundColor: "white",
                },
                link: {
                    _hover: {
                        backgroundColor: "white",
                    },
                    _active: {
                        backgroundColor: "white",
                    },
                },
            }
        }
    }
}

export type MpThemeProviderProps = {
    children: ReactNode
}

export const MpThemeProvider: FC<MpThemeProviderProps> = ({
    children
}) => {
    return <>
        <CssBaseline />
        <StyledEngineProvider injectFirst>
            <AmplifyThemeProvider theme={mpAmplifyTheme}>
                <ThemeProvider theme={mpMuiTheme} children={children} />
            </AmplifyThemeProvider>
        </StyledEngineProvider>
    </>
}