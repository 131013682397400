import DownloadIcon from '@mui/icons-material/Download'
import { Button } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { getDynamicColumnDefinitions } from "src/common/grid/dynamic-columns"
import { doExport, useExportMenu } from "src/common/grid/ExportMenu"
import { ExportColumnDefinition } from "src/common/grid/grid-export"
import { useEvent } from "src/events/event-context"
import { useGetCustomExports } from "src/registrations/custom-exports/useGetCustomExports"
import { RegistrationWithComputedColumns } from "src/registrations/RegistrationsGrid"


const extraColumns: ExportColumnDefinition[] = [
    { field: "type", headerName: "Type" },
    { field: "email", headerName: "Email" },
    { field: "title", headerName: "Title" },
    { field: "firstName", headerName: "First name" },
    { field: "lastName", headerName: "Last name" },
    { field: "primaryRegistrationId", headerName: "Primary registration id" },
]

export type RegistrationExportButtonProps = {
    registrations: RegistrationWithComputedColumns[]
    gridColumns: ExportColumnDefinition<RegistrationWithComputedColumns>[]
}

export const RegistrationExportButton: FC<RegistrationExportButtonProps> = ({
    registrations = [],
    gridColumns,
}) => {
    const client = useClient()!
    const event = useEvent()!
    const profilesQuery = backend.networking.useGetAll([client.id, event.id])

    const registrationsWithProfiles = registrations.map(registration => ({
        ...registration,
        networkingProfile: (profilesQuery.data?.find(it => it.registrationId === registration.id)),
    }))

    const exportMenu = useExportMenu()
    const customExports = useGetCustomExports(client.id, event.id)

    const defaultColumns = [
        ...extraColumns,
        ...gridColumns,
        ...getDynamicColumnDefinitions(registrationsWithProfiles, "networkingProfile", {
            headerPrefix: "profile-",
            filter: columnName => !["eventId", "registrationId"].includes(columnName)
        })
    ]

    return <Button
        color="inherit"
        onClick={e => customExports.length ? exportMenu.open({
            defaultColumns,
            data: registrationsWithProfiles,
            name: "Registrations",
            anchorEl: e.currentTarget,
            customExports
        }) : doExport({
            columns: defaultColumns,
            data: registrationsWithProfiles,
            name: "Registrations",
            event,
        })}
        endIcon={<DownloadIcon />}
    >
        Export
    </Button>

}