import { UserActivityUsage } from "@marketpartner/backend-api"
import { sortBy } from "lodash"
import { DateTime, Duration } from "luxon"

export enum UserTimelineEventType {
    action = "action",
    startTimedActivity = "startTimedActivity",
    endTimedActivity = "endTimedActivity",
}

export type UserTimelineEvent = {
    time: DateTime
} & TimelineEventDetails

export type TimelineEventDetails = {
    type: UserTimelineEventType.action
    activityId: string
} | {
    type: UserTimelineEventType.startTimedActivity
    activityId: string
    duration: Duration
} | {
    type: UserTimelineEventType.endTimedActivity
    activityId: string
    duration: Duration
}

export const buildTimelineEvents = (
    { actions, timedActivities }: UserActivityUsage
): UserTimelineEvent[] => {
    const events: UserTimelineEvent[] = []

    actions.forEach(({ activityId, times }) => {
        times.forEach(time => {
            events.push({
                type: UserTimelineEventType.action,
                activityId,
                time,
            })
        })
    })

    timedActivities.forEach(({ activityId, sessions }) => {
        sessions.forEach(({ start, end }) => {
            const duration = end.diff(start)
            events.push({
                type: UserTimelineEventType.startTimedActivity,
                activityId,
                time: start,
                duration,
            })
            events.push({
                type: UserTimelineEventType.endTimedActivity,
                activityId,
                time: end,
                duration,
            })
        })
    })

    return sortBy(events, "time")
}