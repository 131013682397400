import { ContentEntity, Scope } from "@marketpartner/backend-api";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { ConfirmDeleteDialog } from "src/common/dialogs/ConfirmDeleteDialog";
import { LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { useEvent } from "src/events/event-context";

export type DeleteContentEntityDialogProps = LockableDialogProps & {
    entity: ContentEntity
    onDelete?: () => void
}

const DeleteContentEntityDialog: FC<DeleteContentEntityDialogProps> = ({
    entity,
    onDelete,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const type = backend.contentTypes.useGetTypes([client.id, event.id], {
        select: types => types.find(type => type.id === entity.typeId)!,
    })

    const deleteMutation = backend.contentEntities.useDeleteEntity(withNotification({
        errorMessage: "Error deleting content",
        onSuccess: onDelete,
    }))

    return <ConfirmDeleteDialog
        itemName={type.data?.name ?? ""}
        formProps={{
            action: () => deleteMutation.mutateAsync([client.id, event.id, entity.id]),
            text: `Delete ${entity.fields.title}?`,
        }}
        {...props}
    />
}

export const useDeleteContentEntityDialog = createPopupHook({
    element: DeleteContentEntityDialog,
    scope: Scope.Event,
})