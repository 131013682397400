import { Scope, StripeInstance } from "@marketpartner/backend-api"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { ConfirmDeleteDialog } from "src/common/dialogs/ConfirmDeleteDialog"
import { LockableDialogProps } from "src/common/dialogs/LockableDialog"
import { createPopupHook } from "src/common/dialogs/Popup"
import { withNotification } from "src/common/notifications/with-notification"


export type DeleteStripeInstanceDialogProps = LockableDialogProps & {
    instance: StripeInstance
}

const DeleteStripeInstanceDialog: FC<DeleteStripeInstanceDialogProps> = ({
    instance,
    ...props
}) => {
    const client = useClient()!

    const deleteInstanceMutation = backend.stripeInstanceApi.useDeleteStripeInstance(withNotification({
        errorMessage: "Error deleting Stripe instance",
    }))

    return <ConfirmDeleteDialog
        {...props}
        itemName={instance.name}
        formProps={{
            action: () => deleteInstanceMutation.mutateAsync([client.id, instance.id]),
            text: "This will disable Stripe functionality on any events that use this instance. The instance cannot be created without re-entering the API keys.",
            confirmText: instance.name
        }}
    />
}

export const useDeleteStripeInstanceDialog = createPopupHook({
    scope: Scope.Client,
    element: DeleteStripeInstanceDialog,
})