import { Permission, RegistrationCategory, RegistrationCategoryType } from "@marketpartner/backend-api"
import DialogTitle, { DialogTitleProps } from "@mui/material/DialogTitle"
import { FC, useState } from "react"
import { useClient } from "src/clients/client-context"
import { Spacer } from "src/common/layout/Spacer"
import { useEvent } from "src/events/event-context"
import { useLoginContext } from "src/login/LoginContext"
import { RegistrationCategorySelector } from "src/registrations/configuration/categories/RegistrationCategorySelector"


export type PrimaryRegistrationDialogTitleProps = DialogTitleProps & {
    initialCategoryId?: string
    onSelectCategory: (category: RegistrationCategory) => void
}

export const PrimaryRegistrationDialogTitle: FC<PrimaryRegistrationDialogTitleProps> = ({
    initialCategoryId,
    onSelectCategory,
    children,
    sx,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const [categoryId, setCategoryId] = useState<string | null>(initialCategoryId ?? null)
    const login = useLoginContext()
    const editSpeakersOnly = !login.hasPermission(Permission.EditRegistrations, client.id, event.id)

    return <DialogTitle
        {...props}
        sx={{ display: "flex", alignItems: "center", ...sx }}
    >
        {children}
        <Spacer />
        <RegistrationCategorySelector
            categoryId={categoryId}
            onSelectCategoryId={setCategoryId}
            onSelectLoadedItem={category => category && onSelectCategory(category)}
            hideValue={category => editSpeakersOnly && category.type === RegistrationCategoryType.Delegate}
            size="small"
            sx={{ minWidth: 200 }}
        />
    </DialogTitle>
}