import { AppTemplateDeploymentPermission, ClientAppTemplate, ClientAppTemplateScope } from "@marketpartner/backend-api"
import { useDynamicFormState } from "@marketpartner/mp-common-react"
import { LoadingButton } from "@mui/lab"
import { Button, Collapse, FormHelperText, Stack } from "@mui/material"
import { FC, useMemo, useState } from "react"
import { AppDeploymentFormControls } from "src/app-templates/deployments/AppDeploymentFormControls"
import { NewClientDeploymentTemplateSelector } from "src/app-templates/deployments/client/NewClientDeploymentTemplateSelector"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { DialogStepper } from "src/common/dialogs/DialogStepper"
import { useDialogContext } from "src/common/dialogs/LockableDialog"
import { withNotification } from "src/common/notifications/with-notification"
import { useEvent } from "src/events/event-context"
import { v4 as uuidV4 } from 'uuid'


export type NewClientDeploymentStepperProps = {
    scope: ClientAppTemplateScope
}

export const NewClientDeploymentStepper: FC<NewClientDeploymentStepperProps> = ({
    scope
}) => {
    const client = useClient()!
    const event = useEvent()
    const dialog = useDialogContext()

    const [clientAppTemplateId, setClientAppTemplateId] = useState<string>()
    const [clientAppTemplate, setClientAppTemplate] = useState<ClientAppTemplate>()
    const [appTemplateId, setAppTemplateId] = useState<string>()
    const [appTemplateVersion, setAppTemplateVersion] = useState<string>()
    const [domainName, setDomainName] = useState<string | null>(null)
    const [path, setPath] = useState("")
    const [name, setName] = useState("")
    const [editPermission, setEditPermission] = useState(AppTemplateDeploymentPermission.AdminOnly)
    const [adminParameters, setAdminParameters] = useState<string[]>([])
    const id = useMemo(uuidV4, [])

    const [confirmedTemplate, setConfirmedTemplate] = useState(false)

    const metadataRequest = backend.appTemplates.useGetTemplateMetadata([appTemplateId!, appTemplateVersion!], {
        enabled: Boolean(appTemplateId && appTemplateVersion)
    })
    const parametersFormState = useDynamicFormState({
        elements: metadataRequest.data?.parameterList ?? [],
        requirement: "complete",
        initialValues: clientAppTemplate?.parameters ?? {}
    })

    const deployToEventMutation = backend.appTemplateDeployments.useDeployToEvent(withNotification({
        successMessage: "Deployed app",
        errorMessage: "Error deploying app",
    }))
    const scopeNotSupported = metadataRequest.data && !metadataRequest.data.supportedScopes[scope]

    const canConfirmTemplate = !metadataRequest.isLoading &&
        appTemplateId &&
        appTemplateVersion &&
        !scopeNotSupported &&
        (clientAppTemplate || !clientAppTemplateId)

    const canCreate = Boolean(domainName && path && name && parametersFormState.invalidFields.length === 0)

    const confirmTemplate = () => {
        parametersFormState.reset()
        if (clientAppTemplate) {
            setName(clientAppTemplate.name)
            setEditPermission(clientAppTemplate.deploymentPermission)
            setAdminParameters(clientAppTemplate.adminParameters)
        }
        setConfirmedTemplate(true)
    }

    const create = () => {
        dialog.disableClose()

        const templateId = clientAppTemplateId ? {
            clientAppTemplateId
        } : {
            appTemplateId: appTemplateId!,
            appTemplateVersion: appTemplateVersion!,
        }

        deployToEventMutation.mutate([client.id, event!.id, {
            ...templateId,
            id,
            name,
            domainName: domainName!,
            path,
            editPermission,
            parameters: parametersFormState.values,
            adminParameters,
        }], {
            onSuccess: () => {
                dialog.enableClose()
                dialog.close()
            },
            onError: () => {
                dialog.enableClose()
            }
        })
    }

    const stepIdx = !confirmedTemplate ? 0 : 1

    return <DialogStepper
        title="Create app"
        stepIdx={stepIdx}
        steps={[{
            label: "Select template",
            content: <Stack>
                <NewClientDeploymentTemplateSelector
                    scope={scope}
                    clientTemplateId={clientAppTemplateId}
                    onChangeClientTemplateId={setClientAppTemplateId}
                    onLoadSelectedTemplate={setClientAppTemplate}
                    appTemplateId={appTemplateId}
                    onChangeAppTemplateId={setAppTemplateId}
                    appTemplateVersion={appTemplateVersion}
                    onChangeAppTemplateVersion={setAppTemplateVersion}
                />
                <Collapse in={scopeNotSupported}>
                    <FormHelperText error>
                        Template does not support deployment to {scope} scope
                    </FormHelperText>
                </Collapse>
            </Stack>,
            nextAction: <Button
                color="primary"
                disabled={!canConfirmTemplate}
                onClick={confirmTemplate}
            >
                Next
            </Button>
        }, {
            label: "Configure parameters",
            content: <AppDeploymentFormControls
                clientId={client.id}
                eventId={event?.id ?? null}
                id={id}
                name={name}
                onChangeName={setName}
                domainName={domainName}
                onChangeDomainName={setDomainName}
                path={path}
                onChangePath={setPath}
                editPermission={editPermission}
                onChangeEditPermission={setEditPermission}
                adminParameters={adminParameters}
                onChangeAdminParameters={setAdminParameters}
                parametersFormState={parametersFormState}
            />,
            nextAction: <LoadingButton
                disabled={!canCreate}
                onClick={create}
                loading={deployToEventMutation.isPending}
            >
                Create
            </LoadingButton>
        }]}
    />
}