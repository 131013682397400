import { MultiUploadConfig } from "@marketpartner/backend-api";
import { Grid } from "@mui/material";
import { FormElementConfigComponent } from "src/forms/elements/types/formElementConfigTypes";
import { StandardFields } from "src/forms/elements/types/StandardFields";
import { UploadTypeSelector } from "src/forms/elements/types/UploadTypeSelector";

export const MultiUploadConfigComponent: FormElementConfigComponent<MultiUploadConfig> = ({
    config,
    updateConfigProperty,
}) => {
    return <>
        <StandardFields config={config} updateConfigProperty={updateConfigProperty} />
        <Grid item xs={12}>
            <UploadTypeSelector value={config.uploadType} onChange={type => updateConfigProperty("uploadType", type)} />
        </Grid>
    </>
}