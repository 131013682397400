import { Client, MigrationAction } from "@marketpartner/backend-api";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Box, Button, DialogActions, DialogContent } from "@mui/material";
import Alert from '@mui/material/Alert';
import { FC } from "react";
import { MigrationsTable } from 'src/clients/migration/MigrationsTable';
import { RollbackButton } from "src/clients/migration/RollbackButton";
import { backend } from "src/common/api";
import { useDialogContext } from "src/common/dialogs/LockableDialog";
import { Loading } from 'src/common/loading/Loading';
import { withNotification } from "src/common/notifications/with-notification";

export type MigrationDialogContentProps = {
    client: Client
}

export const MigrationDialogContent: FC<MigrationDialogContentProps> = ({ client }) => {
    const dialog = useDialogContext()
    const migrationsQuery = backend.clients.useGetClientMigrationScripts([client.id], {
        select: migrations => [...migrations].reverse()
    })

    const migrateMutation = backend.clients.useMigrateSchema(withNotification({
        onMutate: () => dialog.disableClose(),
        successMessage: (_result, [_clientId, { action, name }]) => `Successfully performed migration: ${action} ${name ?? ""}`,
        errorMessage: `Error performing migration`,
        onSettled: () => dialog.enableClose(),
    }))

    const doMigration = (
        action: MigrationAction,
        name?: string,
    ) => migrateMutation.mutate([client.id, { action, name }])

    return <>
        {migrationsQuery.data && (migrationsQuery.data[0].isApplied ?
            <Alert severity="success">Database is up-to-date</Alert> :
            <Alert severity="warning">Database requires updating</Alert>
        )}
        <DialogContent>
            <Loading request={migrationsQuery} sizing="fit-content" render={migrations => <>
                <MigrationsTable migrations={migrations} doMigration={doMigration} isMigrating={migrateMutation.isPending} />
            </>} />
        </DialogContent>
        <DialogActions>
            <Box mx="auto" />
            <Button
                children="Unlock"
                startIcon={<LockOpenIcon />}
                variant="outlined"
                color="inherit"
                onClick={() => doMigration(MigrationAction.unlock)}
                disabled={migrateMutation.isPending}
            />
            <RollbackButton
                text="Down"
                icon={<ExpandMoreIcon />}
                variant="outlined"
                action={() => doMigration(MigrationAction.down)}
                disabled={migrateMutation.isPending}
            />
            <Button
                children="Up"
                endIcon={<ExpandLessIcon />}
                variant="outlined"
                color="primary"
                onClick={() => doMigration(MigrationAction.up)}
                disabled={migrateMutation.isPending}
            />
            <Button
                children="Latest"
                endIcon={<ArrowUpwardIcon />}
                variant="contained"
                color="primary"
                onClick={() => doMigration(MigrationAction.latest)}
                disabled={migrateMutation.isPending}
            />
            <Box mx="auto" />
            <Button
                children="Close"
                color="inherit"
                onClick={() => dialog.close()}
                disabled={migrateMutation.isPending}
            />
        </DialogActions>
    </>
}