import { StructuredAgendaItem } from "@marketpartner/backend-api"
import { Stack, SxProps, Typography } from "@mui/material"
import { DateTime } from "luxon"
import { FC } from "react"


export type AgendaItemTimesProps = {
    item: StructuredAgendaItem
    sx?: SxProps
}

export const AgendaItemTimes: FC<AgendaItemTimesProps> = ({
    item,
    sx,
}) => {

    const formattedTime = (time: DateTime, overlaps: boolean) => <Typography
        color={overlaps ? "error" : "text.secondary"}
        variant="body2"
    >
        {time.toLocaleString(DateTime.TIME_SIMPLE)}
    </Typography>

    return <Stack sx={{ ...sx }}>
        {formattedTime(item.start, item.startOverlaps)}
        {"end" in item && formattedTime(item.end, item.endOverlaps)}
    </Stack>
}
