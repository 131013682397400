import { Box, Paper, PaperProps } from "@mui/material";
import { FC } from "react";


export type GridDetailPanelProps = PaperProps

export const GridDetailPanel: FC<GridDetailPanelProps> = ({
    sx,
    ...props
}) => <Box
    sx={{
        px: 2,
        py: 2,
        backgroundColor: theme => theme.palette.grey[100],
    }}
>
        <Paper sx={{
            px: 1,
            py: 1,
            ...sx
        }} {...props} />
    </Box>