
export class RegistrationParseException extends Error {
    constructor(
        public readonly message: string,
        public readonly cause?: unknown,
    ) {
        super(message, {
            cause
        })
    }
}