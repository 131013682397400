import { TextConfig } from "@marketpartner/backend-api"
import { Grid, TextField } from "@mui/material"
import { FormElementConfigComponent } from "src/forms/elements/types/formElementConfigTypes"


export const TextConfigComponent: FormElementConfigComponent<TextConfig> = ({
    config,
    updateConfigProperty
}) => {
    return <>
        <Grid item xs={12}>
            <TextField
                label="Text"
                multiline
                minRows={3}
                fullWidth
                value={config.text}
                onChange={(e) => updateConfigProperty('text', e.target.value)}
            />
        </Grid>
    </>
}