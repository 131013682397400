import { normalizeColumn } from "src/registrations/import/pre-process/normalize-column"

const ignoredColumns = [
    "Approval state", "Approval",
    "Actions",
    "Registered at", "Registered",
    "Attended",
    "Token",
    'profile-id',
    'profile-firstName',
    'profile-lastName',
    'profile-directMessageOptIn',
    'profile-avatarUrl',
].map(normalizeColumn)

const ignoredPatterns = [
    /^Registered (?:at )?\([a-zA-Z0-9_+/-]+\)$/,
    /^profile-[a-zA-Z0-9]+$/
]

export const identifyIgnoredColumns = (
    columns: string[]
): string[] => {
    return columns.filter(it =>
        ignoredColumns.includes(normalizeColumn(it)) ||
        ignoredPatterns.some(pattern => it.match(pattern))
    )
}