import { ContentEntityId, Permission, RelatedContentType } from "@marketpartner/backend-api";
import { DeleteForever, Edit } from "@mui/icons-material";
import { Button, Paper } from "@mui/material";
import { FC } from "react";
import { useNavigate, useParams } from "react-router";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { Page } from "src/common/layout/Page";
import { Loading } from "src/common/loading/Loading";
import { RoutedTabs } from "src/common/routing/RoutedTabs";
import { fullSize } from "src/common/styles";
import { ContentEntityDetails } from "src/content/entities/ContentEntityDetails";
import { useDeleteContentEntityDialog } from "src/content/entities/DeleteContentEntityDialog";
import { useEditContentEntityDialog } from "src/content/entities/EditContentEntityDialog";
import { relatedContentTab } from "src/content/relationships/relationship-tabs";
import { useEvent } from "src/events/event-context";
import { ifHas } from "src/login/IfHas";

export type ContentEntityPageProps = {}

export const ContentEntityPage: FC<ContentEntityPageProps> = () => {
    const client = useClient()!
    const event = useEvent()!
    const entityId = useParams().entityId as ContentEntityId
    const navigate = useNavigate()

    const entityQuery = backend.contentEntities.useGetEntities([client.id, event.id], {
        select: entities => entities.find(entity => entity.id === entityId) ?? null
    })

    const editDialog = useEditContentEntityDialog()
    const deleteDialog = useDeleteContentEntityDialog()

    return <Loading request={entityQuery} render={entity => {
        if (!entity) {
            return <></>
        }

        return <Page
            title={entity.fields.title}
            actions={ifHas(Permission.EditEventContent, client.id, event.id, <>
                <Button
                    startIcon={<Edit />}
                    children="Edit"
                    color="primary"
                    variant="contained"
                    onClick={() => editDialog.open({ entity })}
                />
                <Button
                    startIcon={<DeleteForever />}
                    children="Delete"
                    color="error"
                    onClick={() => deleteDialog.open({
                        entity,
                        onDelete: () => navigate(-1)
                    })} />
            </>)}
        >
            <Paper sx={{ ...fullSize }}>
                <RoutedTabs
                    tabs={[
                        {
                            label: "Details",
                            path: "details",
                            element: <ContentEntityDetails entity={entity} />,
                            scrollable: true,
                        },
                        relatedContentTab(RelatedContentType.Entity, entityId),
                    ]}
                />
            </Paper>
        </Page>
    }} />
}