import { DynamicEmailTemplate, Scope } from "@marketpartner/backend-api"
import { DialogTitle } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog"
import { createPopupHook } from "src/common/dialogs/Popup"
import { withNotification } from "src/common/notifications/with-notification"
import { DynamicEmailTemplateForm } from "src/email/templates/DynamicEmailTemplateForm"


export type EditDynamicEmailTemplateDialogProps = LockableDialogProps & {
    template: DynamicEmailTemplate
}

const EditDynamicEmailTemplateDialog: FC<EditDynamicEmailTemplateDialogProps> = ({
    template,
    ...props
}) => {
    const client = useClient()!

    const updateTemplateMutation = backend.emailTemplates.useUpdateDynamicEmailTemplate(withNotification({
        errorMessage: "Error updating dynamic email template"
    }))

    return <LockableDialog
        maxWidth="md"
        fullWidth
        {...props}
    >
        <DialogTitle>Configure email template</DialogTitle>
        <DynamicEmailTemplateForm
            actionName="Save"
            template={template}
            onSubmit={template => updateTemplateMutation.mutateAsync([client.id, template.id, template])}
        />
    </LockableDialog>
}

export const useEditDynamicEmailTemplateDialog = createPopupHook({
    element: EditDynamicEmailTemplateDialog,
    scope: Scope.Client,
})