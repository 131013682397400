import { RelatedContentCategory, Scope } from "@marketpartner/backend-api"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { ConfirmDeleteDialog } from "src/common/dialogs/ConfirmDeleteDialog"
import { LockableDialogProps } from "src/common/dialogs/LockableDialog"
import { createPopupHook } from "src/common/dialogs/Popup"
import { useEvent } from "src/events/event-context"


export type DeleteRelatedContentCategoryDialogProps = LockableDialogProps & {
    category: RelatedContentCategory
}

const DeleteRelatedContentCategoryDialog: FC<DeleteRelatedContentCategoryDialogProps> = ({
    category,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!

    const deleteMutation = backend.relatedContentCategories.useDeleteCategory()

    return <ConfirmDeleteDialog
        itemName={`category ${category.name}`}
        formProps={{
            text: "Any content under this category will remain linked, but will become uncategorized.",
            action: () => deleteMutation.mutateAsync([client.id, event.id, category.id]),
        }}
        {...props}
    />
}

export const useDeleteRelatedContentCategoryDialog = createPopupHook({
    element: DeleteRelatedContentCategoryDialog,
    scope: Scope.Event,
})