import { filterFormFields, Form, FormFieldType, FormType, TestClass } from "@marketpartner/backend-api"
import _ from "lodash"


export type ExtractedFormField = {
    name: string
    categories: string[]
    type: FormFieldType
}

export class TestExtractedFormField extends TestClass implements ExtractedFormField {
    categories = ["delegate"]
    type = FormFieldType.TextField

    constructor(
        public name: string
    ) {
        super()
    }
}

export const extractFormFields = (
    forms: Form[]
): ExtractedFormField[] => {
    return _(forms)
        .filter(it => it.type !== FormType.Standard)
        .flatMap(form => filterFormFields(form.elements).map(element => ({
            name: element.elementConfig.name,
            config: element.elementConfig,
            category: getCategoryForForm(form),
        })))
        .groupBy("name")
        .entries()
        .map(([name, elements]) => ({
            name,
            categories: elements.map(field => field.category),
            type: elements[0].config.type,
        }))
        .value()
}

const getCategoryForForm = (form: Form) => form.type === FormType.Registration ?
    form.registrationCategoryId :
    "guest"
