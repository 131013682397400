import { FormType, StandardForm } from "@marketpartner/backend-api"
import { filterType } from "@marketpartner/mp-common"
import { LoadedSelector } from "@marketpartner/mp-common-mui"
import { Stack, Typography } from "@mui/material"
import { UseQueryResult } from "@tanstack/react-query"
import { FC, useState } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { BarAndFlex } from "src/common/layout/BarAndFlex"
import { Loading } from "src/common/loading/Loading"
import { Page } from "src/common/layout/Page"
import { useEvent } from "src/events/event-context"
import { FormResponses } from "src/forms/responses/FormResponses"


export const ModerationPage: FC = () => {
    const client = useClient()!
    const event = useEvent()!
    const formsQuery = backend.forms.useGetAll([client.id, event.id], {
        select: forms => filterType(forms, FormType.Standard as const)
            .filter(form => form.isModerated)
    })

    return <Page title="Moderation" back={{ hideIf: true }} minContentSize={450}>
        <Loading request={formsQuery} render={forms => forms.length === 0 ?
            <NoResponses /> :
            <ModerationPageContent formsQuery={formsQuery} />
        } />
    </Page>
}

type ModerationPageContentProps = {
    formsQuery: UseQueryResult<StandardForm[]>
}

const ModerationPageContent: FC<ModerationPageContentProps> = ({ formsQuery }) => {
    const [form, setForm] = useState(formsQuery.data?.[0] ?? null)
    const [formId, setFormId] = useState(form?.id ?? null)

    return <BarAndFlex barContent={
        formsQuery.data!.length > 1 && <LoadedSelector<number, StandardForm>
            fetchRequest={formsQuery}
            selectedId={formId}
            onSelectId={setFormId}
            onSelectLoadedItem={setForm}
            size="small"
            required
        />
    }>
        {form ?
            <FormResponses form={form} /> :
            <NoResponses />}
    </BarAndFlex>
}


const NoResponses = () => {
    return <Stack justifyContent="center" alignItems="center" sx={{ minHeight: 200 }}>
        <Typography>
            No moderated forms currently configured for this event
        </Typography>
    </Stack>
}