import { EventSegment } from "@marketpartner/backend-api"
import { Paper, Stack } from "@mui/material"
import { useState } from "react"
import { ActivityStatsGrid } from "src/activities/ActivityStatsGrid"
import { EventStats } from "src/activities/EventStats"
import { ExportActivitiesButton } from "src/activities/ExportActivitiesButton"
import { RecalculateStatsButton } from 'src/activities/RecalculateStatsButton'
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { Loading } from "src/common/loading/Loading"
import { Page } from "src/common/layout/Page"
import { useEvent } from "src/events/event-context"
import { SegmentSelector } from "src/events/segments/SegmentSelector"


export const EventActivitiesPage = () => {
    const client = useClient()!
    const event = useEvent()!
    const [segmentId, setSegmentId] = useState<number | null>(null)
    const [segment, setSegment] = useState<EventSegment | null>(null)
    const statsQuery = backend.activityStatistics.useGetEventStatistics([client.id, event.id, {
        segmentId: segmentId ?? undefined
    }], {
        select: stats => stats.filter(it => it.source === undefined),
        refetchInterval: segmentId ? undefined : 5000,
    })

    return <Page
        title="Activities"
        actions={<>
            <ExportActivitiesButton sx={{ mr: 2, alignSelf: "end" }} />
            <SegmentSelector segmentId={segmentId} onSelectSegmentId={setSegmentId} onSelectLoadedSegment={setSegment} />
            <RecalculateStatsButton segmentId={segmentId ?? undefined} sx={{ alignSelf: "end" }} />
        </>}
    >
        <Loading request={statsQuery} render={stats => <>
            <Stack spacing={4}>
                <Paper>
                    <EventStats stats={stats} segment={segment ?? undefined} />
                </Paper>
                <Paper>
                    <ActivityStatsGrid stats={stats} segment={segment ?? undefined} />
                </Paper>
            </Stack>
        </>} />

    </Page>
}

