import { Scope } from "@marketpartner/backend-api";
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { StripeInstanceForm } from "src/payment/stripe/StripeInstanceForm";


export type CreateStripInstanceDialogProps = LockableDialogProps

const CreateStripInstanceDialog: FC<CreateStripInstanceDialogProps> = ({
    ...props
}) => {
    const client = useClient()!

    const createInstanceMutation = backend.stripeInstanceApi.useCreateStripeInstance(withNotification({
        errorMessage: "Error creating Stripe instance",
    }))

    return <LockableDialog
        maxWidth="sm"
        fullWidth
        {...props}
    >
        <DialogTitle>Create Stripe instance</DialogTitle>
        <StripeInstanceForm
            actionName="Create"
            onSubmit={formData => createInstanceMutation.mutateAsync([client.id, formData])}
        />
    </LockableDialog>
}

export const useCreateStripeInstanceDialog = createPopupHook({
    scope: Scope.Client,
    element: CreateStripInstanceDialog,
})