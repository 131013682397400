import { AdminUser } from "@marketpartner/backend-api"
import { LoadingButton } from "@mui/lab"
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { FC, useState } from "react"
import { backend } from "src/common/api"
import { useDialogContext } from "src/common/dialogs/LockableDialog"
import { withNotification } from "src/common/notifications/with-notification"

export type ResetAdminPasswordDialogProps = {
    user: AdminUser
}

export const ResetAdminPasswordDialogContent: FC<ResetAdminPasswordDialogProps> = ({
    user
}) => {
    const dialog = useDialogContext()
    const [password, setPassword] = useState("")
    const passwordReset = backend.adminUsers.useSetTemporaryPassword(withNotification({
        onSuccess: response => {
            setPassword(response.password)
        },
        onSettled: dialog.enableClose,
        errorMessage: "Failed to reset password",
    }))

    function startReset() {
        dialog.disableClose()
        passwordReset.mutate([user.id])
    }

    return <>
        <DialogTitle>Reset password for {user.name}</DialogTitle>
        <DialogContent>
            {password ?
                <DialogContentText>
                    New temporary password: <b>{password}</b>
                </DialogContentText> :
                <DialogContentText>
                    This will reset the user's password to a random temporary password.<br />
                    <b>It does not notify the user</b>.<br />
                    You will need to manually send them the new password.
                </DialogContentText>
            }
        </DialogContent>
        <DialogActions>
            <Button
                children={password ? "Close" : "Cancel"}
                color="inherit"
                onClick={() => dialog.close()}
            />
            {!password && <LoadingButton
                variant="contained"
                onClick={startReset}
                loading={passwordReset.isPending}
            >Reset password</LoadingButton>
            }
        </DialogActions>
    </>
}