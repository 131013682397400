import { Timezone } from "@marketpartner/backend-api"
import { DateTime, Duration } from "luxon"


export const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone as Timezone

export const utcTimezone = "Etc/UTC"


export type FormatDiffNowOptions = {
    capitalize?: boolean
}

export function formatDiffNow(
    dateTime: DateTime,
    { capitalize = false }: FormatDiffNowOptions = {}
) {
    const duration = dateTime.diffNow("seconds")
    
    let string
    if (duration.as("seconds") < 0) {
        string = `${formatDuration(duration.negate())} ago`
    } else {
        string = `in ${formatDuration(duration)}`
    }

    if (capitalize) {
        string = string.charAt(0).toUpperCase() + string.slice(1)
    }

    return string
}

export function formatDuration(duration: Duration) {
    const seconds = duration.as("seconds")
    const minutes = duration.as("minutes")
    const hours = duration.as("hours")
    const days = duration.as("days")
    const months = duration.as("months")
    const years = duration.as("years")

    if (seconds < 30) {
        return "less than a minute"
    }
    if (seconds < 90) {
        return "1 minute"
    }
    if (minutes < 60) {
        return `${Math.round(minutes)} minutes`
    }
    if (minutes < 90) {
        return "about 1 hour"
    }
    if (hours < 24) {
        return `about ${Math.round(hours)} hours`
    }
    if (hours < 48) {
        return "1 day"
    }
    if (days < 30) {
        return `${Math.round(days)} days`
    }
    if (days < 45) {
        return "about 1 month"
    }
    if (months < 12) {
        return `about ${Math.round(months)} months`
    }
    if (months < 15) {
        return "about 1 year"
    }
    if (months < 24) {
        return "almost 2 years"
    }
    return `over ${Math.floor(years)} years`
}

export function checkLuxonDateTime(dateTime: any) {
    if (dateTime && !(dateTime instanceof DateTime)) {
        if (window.location.hostname === "localhost") {
            const typeDescription = dateTime?.constructor ? dateTime.constructor.name : typeof dateTime
            console.warn(`Warning: A date was detected that has not been converted to a Luxon DateTime (type: ${typeDescription}). All dates returned from API calls should be converted to Luxon DateTimes using toUtcDateTime() in order to prevent subtle bugs (e.g. date comparisons, timezone issues).`)
        }
    }
}

/**
 * Accepts "any" type of date time, and safely converts it to a UTC luxon date.
 * 
 * This includes:
 * - JS date
 * - ISO String
 * - Millisecond value
 * - Luxon DateTime
 * 
 * If the argument is null/undefined, it returns that value
 */
export function toUtcDateTime(dateTime: undefined): undefined
export function toUtcDateTime(dateTime: null): null
export function toUtcDateTime(dateTime: unknown): DateTime
export function toUtcDateTime(dateTime: unknown) {
    if (dateTime === null || dateTime === undefined) {
        return dateTime
    }

    if (typeof dateTime === "string") {
        return DateTime.fromISO(dateTime).toUTC()
    }
    if (typeof dateTime === "number") {
        return DateTime.fromMillis(dateTime).toUTC()
    }
    if (dateTime instanceof DateTime) {
        return dateTime.toUTC()
    }
    if (dateTime instanceof Date) {
        return DateTime.fromJSDate(dateTime).toUTC()
    }
    throw new Error(`Unparsable DateTime argument (${dateTime})`)
}