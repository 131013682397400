import { EventDetails, Registration, RegistrationCategory, Scope } from "@marketpartner/backend-api";
import { Warning } from "@mui/icons-material";
import { Button, DialogTitle } from "@mui/material";
import { FC, useState } from "react";
import { DialogStepper } from "src/common/dialogs/DialogStepper";
import { LockableDialog, LockableDialogProps, useDialogContext } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { ProgressWithLabel } from "src/common/loading/ProgressWithLabel";
import { CopyRegistrationsResults } from "src/registrations/actions/copy/CopyRegistrationResults";
import { RegistrationTargetCategorySelector } from "src/registrations/actions/copy/CopyRegistrationsCategorySelector";
import { CopyRegistrationsSelector } from "src/registrations/actions/copy/CopyRegistrationsSelector";
import { useRegistrationCategoryCompatibility } from "src/registrations/actions/copy/registration-category-compatibility";
import { useCopyRegistrations } from "src/registrations/actions/copy/useCopyRegistrations";


type CopyRegistrationsDialogProps = LockableDialogProps & CopyRegistrationsDialogContentProps

const CopyRegistrationsDialog: FC<CopyRegistrationsDialogProps> = ({
    ...props
}) => {
    return <LockableDialog
        maxWidth="sm"
        fullWidth
        allowFullScreen
        disableClickaway
        {...props}
    >
        <CopyRegistrationsDialogContent />
    </LockableDialog>
}

type CopyRegistrationsDialogContentProps = {
}

const CopyRegistrationsDialogContent: FC<CopyRegistrationsDialogContentProps> = () => {
    const [sourceEvent, setSourceEvent] = useState<EventDetails | null>(null)
    const [sourceCategory, setSourceCategory] = useState<RegistrationCategory | null>(null)
    const [targetCategory, setTargetCategory] = useState<RegistrationCategory | null>(null)
    const [copyGuests, setCopyGuests] = useState(false)
    const compatibility = useRegistrationCategoryCompatibility(sourceEvent, sourceCategory, targetCategory, copyGuests)
    const [selectedRegistrations, setSelectedRegistrations] = useState<Registration[]>([])

    const [stepIdx, setStepIdx] = useState(0)
    const next = () => setStepIdx(idx => idx + 1)

    const dialog = useDialogContext()
    const copyRegistrations = useCopyRegistrations({
        sourceEventId: sourceEvent?.id!,
        targetCategoryId: targetCategory?.id!,
        copyGuests,
        onMutate: () => {
            next()
            dialog.disableClose()
        },
        onSettled: () => {
            next()
            dialog.enableClose()
        },
    })

    const srcText = sourceCategory ? sourceCategory.pluralName : "registrations"
    const targetTxt = targetCategory && targetCategory.pluralName !== sourceCategory?.pluralName ? ` to ${targetCategory.pluralName}` : ""

    return <>
        <DialogTitle>
            Copy {srcText}{targetTxt}
        </DialogTitle>
        <DialogStepper
            stepIdx={stepIdx}
            steps={[{
                label: "Categories",
                content: <RegistrationTargetCategorySelector
                    copyGuests={copyGuests}
                    onChangeCopyGuests={setCopyGuests}
                    onChangeSourceEvent={setSourceEvent}
                    onChangeSourceCategory={setSourceCategory}
                    onChangeTargetCategory={setTargetCategory}
                    compatibility={compatibility}
                />,
                nextAction: <Button
                    children="Next"
                    variant="contained"
                    onClick={next}
                    startIcon={targetCategory && compatibility.level === "warning" && <Warning />}
                    color={targetCategory && compatibility.level === "warning" ? "warning" : "primary"}
                    disabled={!sourceEvent || !sourceCategory || !targetCategory || compatibility.level === "error"}
                />,
            }, {
                label: "Select registrations",
                content: <CopyRegistrationsSelector
                    sourceEventId={sourceEvent?.id!}
                    sourceCategoryId={sourceCategory?.id!}
                    selectedRegistrations={selectedRegistrations}
                    onChangeSelectedRegistrations={setSelectedRegistrations}
                    copyGuests={copyGuests}
                    sx={{
                        minHeight: 300,
                    }}
                />,
                contentProps: {
                    sx: {
                        display: "flex",
                        flexDirection: "column",
                    }
                },
                nextAction: <Button
                    children={`Copy ${selectedRegistrations.length} ${srcText}`}
                    variant="contained"
                    disabled={selectedRegistrations.length === 0}
                    onClick={() => copyRegistrations.mutate(selectedRegistrations.map(r => r.id))}
                />,
            }, {
                label: "Copy",
                content: <ProgressWithLabel
                    percentage={copyRegistrations.progress * 100}
                />,
            }, {
                label: "Results",
                content: <CopyRegistrationsResults
                    registrations={selectedRegistrations}
                    results={copyRegistrations.data?.results?.flat()!}
                    sx={{ height: "initial" }}
                />,
                contentProps: {
                    sx: {
                        overflow: "hidden",
                        display: "flex",
                    }
                },
            }]}
        />
    </>
}

export const useCopyRegistrationsDialog = createPopupHook({
    scope: Scope.Event,
    element: CopyRegistrationsDialog,
})