import { AgendaItemType, Session } from "@marketpartner/backend-api";
import { filterType } from "@marketpartner/mp-common";
import { GridColDef } from "@mui/x-data-grid-pro";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { QueryDataGrid, QueryDataGridProps } from "src/common/grid/QueryDataGrid";
import { EventDateTimeText } from "src/common/time/DateTimeText";
import { LocationName } from "src/content/locations/LocationName";
import { useEvent } from "src/events/event-context";
import { NameAndSubtitle } from "src/registrations/NameAndSubtitle";


export type SessionWithAgendaName = Session & {
    agendaName: string
}

export type SessionsGridProps = Partial<QueryDataGridProps<SessionWithAgendaName>>

export const SessionsGrid = (
    props: SessionsGridProps
) => {
    const client = useClient()!
    const event = useEvent()!

    const sessionsQuery = backend.agendas.useGetAll([client.id, event.id], {
        select: agendas => filterType(
            agendas.flatMap(agenda => agenda
                .flatItems.map(item => ({
                    ...item,
                    agendaName: agenda.name,
                }))
            ),
            AgendaItemType.Session as const
        ) as SessionWithAgendaName[],
    })

    return <QueryDataGrid
        queryOrRows={sessionsQuery}
        columns={columns}
        {...props}
    />
}

const columns: GridColDef<SessionWithAgendaName>[] = [
    {
        field: "name",
        headerName: "Name",
        valueGetter: ({ row }) => row.name,
        renderCell: ({ row }) => <NameAndSubtitle
            name={row.name}
            subtitle={row.agendaName}
        />,
        minWidth: 50,
        width: 100,
        flex: 1,
    },
    {
        field: "locationId",
        headerName: "Location",
        renderCell: ({ row }) => <LocationName locationId={row.locationId} noLink />,
        minWidth: 50,
        width: 100,
    },
    {
        field: "start",
        headerName: "Start",
        valueGetter: ({ row }) => row.start,
        renderCell: ({ row }) => <EventDateTimeText dateTime={row.start} />,
        minWidth: 175,
    },
]
