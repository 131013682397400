import { ContentSubCategory, Scope } from "@marketpartner/backend-api";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { ConfirmDeleteDialog } from "src/common/dialogs/ConfirmDeleteDialog";
import { LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { useEvent } from "src/events/event-context";


export type DeleteContentSubCategoryDialogProps = LockableDialogProps & {
    subCategory: ContentSubCategory
}

const DeleteContentSubCategoryDialog: FC<DeleteContentSubCategoryDialogProps> = ({
    subCategory,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const deleteMutation = backend.contentCategories.useDeleteSubCategory(withNotification({
        errorMessage: "Error deleting sub-category",
    }))

    return <ConfirmDeleteDialog
        formProps={{
            action: () => deleteMutation.mutateAsync([client.id, event.id, subCategory.id]),
            text: <>Content will <b>not</b> be deleted, but <i>will</i> be moved out of this sub-category.</>,
        }}
        itemName={subCategory.pluralName}
        {...props}
    />
}

export const useDeleteContentSubCategoryDialog = createPopupHook({
    element: DeleteContentSubCategoryDialog,
    scope: Scope.Event,
})
