import { Duration } from "luxon"

const oneMinute = 60
const oneHour = 60 * 60

export function prettyDuration(duration: Duration): string
export function prettyDuration(durationInSeconds: number): string
export function prettyDuration(duration: Duration | number) {
    const durationInSeconds = duration instanceof Duration ?
        duration.as("seconds") :
        duration

    if (durationInSeconds < oneMinute) {
        return `${Math.floor(durationInSeconds)}s`
    }
    if (durationInSeconds < oneHour) {
        const mins = Math.floor(durationInSeconds / oneMinute)
        const seconds = Math.floor(durationInSeconds - (mins * 60))
        return `${mins}m ${seconds}s`
    }
    const hours = Math.floor(durationInSeconds / oneHour)
    const remainderSeconds = durationInSeconds - (hours * oneHour)
    const mins = Math.floor(remainderSeconds / oneMinute)
    return `${hours}h ${mins}m`
}