import { MeetingRequestStatus } from "@marketpartner/backend-api";
import { Cancel, CheckCircle, PendingOutlined, RemoveCircle, SvgIconComponent } from "@mui/icons-material";
import { SvgIconProps } from "@mui/material";
import { createElement } from "react";


export type MeetingRequestStatusIconProps = Partial<SvgIconProps> & {
    status: MeetingRequestStatus
}

const components: Record<MeetingRequestStatus, SvgIconComponent> = {
    Pending: PendingOutlined,
    Accepted: CheckCircle,
    Declined: Cancel,
    Cancelled: RemoveCircle,
}
const colors: Record<MeetingRequestStatus, string> = {
    Pending: "grey.600",
    Accepted: "success.main",
    Declined: "error.main",
    Cancelled: "grey.500",
}

export const MeetingRequestStatusIcon = ({ status, ...props }: MeetingRequestStatusIconProps) => {
    return createElement(components[status], { 
        ...props,
        sx: {
            color: colors[status],
            ...props.sx,
        }
    })
}