import { ContentType, Scope } from "@marketpartner/backend-api"
import { DialogTitle } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog"
import { createPopupHook } from "src/common/dialogs/Popup"
import { withNotification } from "src/common/notifications/with-notification"
import { ContentTypeForm } from "src/content/types/ContentTypeForm"
import { useEvent } from "src/events/event-context"


export type EditContentTypeDialogProps = LockableDialogProps & {
    type: ContentType
}

const EditContentTypeDialog: FC<EditContentTypeDialogProps> = ({
    type,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const updateMutation = backend.contentTypes.useUpdateType(withNotification({
        errorMessage: "Error updating content type",
    }))

    return <LockableDialog
        maxWidth="md"
        fullWidth
        {...props}
    >
        <DialogTitle>Edit content type</DialogTitle>
        <ContentTypeForm
            type={type}
            actionName="Save"
            onSubmit={contentType => updateMutation.mutateAsync([client.id, event.id, type.id, contentType])}
        />
    </LockableDialog>
}

export const useEditContentTypeDialog = createPopupHook({
    element: EditContentTypeDialog,
    scope: Scope.Event,
})