import { casualName } from "@marketpartner/mp-common"
import { MpFeedItem } from "@marketpartner/react-feed"
import { Typography } from "@mui/material"
import { memo } from "react"
import { Bar } from "src/common/layout/Bar"
import { LegacyNetworkingProfileAvatar } from "src/legacy-networking/LegacyNetworkingProfileAvatar"


export type FeedItemUserProps = {
    item: MpFeedItem
}

export const FeedItemUser = memo<FeedItemUserProps>(({
    item,
}) => {

    return <Bar spacing={1}>
        <LegacyNetworkingProfileAvatar
            namedEntity={item}
            registrationId={item.registrationId}
            fallbackAvatarUrl={item.avatarUrl}
        />
        <Typography>
            {casualName(item)}
        </Typography>
    </Bar>
})