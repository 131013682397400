import { DialogTitle } from "@mui/material"
import { FC } from "react"
import { createLocalPopupHook } from "src/common/dialogs/LocalPopup"
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog"
import { useEditableFormElementsContext } from "src/forms/elements/EditableFormElementsContext"
import { FormElementForm } from "src/forms/elements/FormElementForm"


export type CreateFormElementDialogProps = LockableDialogProps

const CreateFormElementDialog: FC<CreateFormElementDialogProps> = ({
    ...props
}) => {
    const { onCreateElements } = useEditableFormElementsContext()

    return <LockableDialog {...props}>
        <DialogTitle>Create form element</DialogTitle>
        <FormElementForm
            onSubmit={element => onCreateElements([element])}
            actionName="Create" />
    </LockableDialog>
}


export const useCreateFormElementDialog = createLocalPopupHook({
    element: CreateFormElementDialog,
})