import { Permission } from "@marketpartner/backend-api";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button, DialogTitle, Grid, TextField } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from 'src/common/api';
import { DialogButton } from "src/common/dialogs/DialogButton";
import { DialogForm, ExtendableDialogFormProps } from "src/common/dialogs/DialogForm";
import { withNotification } from 'src/common/notifications/with-notification';
import { useEvent } from 'src/events/event-context';
import { ifHas } from "src/login/IfHas";
import { useImmer } from "use-immer";

export const EditExtraFieldsButtons = () => {
    const client = useClient()!
    const event = useEvent()!
    const updateEventMutation = backend.events.useUpdateEvent(withNotification({
        successMessage: `Updated ${event.name}`,
        errorMessage: `Error updating ${event.name}`
    }))

    return ifHas(Permission.EditExtraFields, client.id, event.id,
        <DialogButton
            text={Object.keys(event.extraFields).length ? `Edit fields` : `Add fields`}
            icon={Object.keys(event.extraFields).length ? <EditIcon /> : <AddIcon />}
            color="primary"
            variant="contained"
        >
            <DialogTitle>Edit custom fields</DialogTitle>
            <ExtraFieldsForm
                actionName="Save"
                onSubmit={eventDetails => updateEventMutation.mutateAsync([client.id, event.id, eventDetails])}
            />
        </DialogButton>
    )
}

type ExtraFieldsFormData = {
    extraFields: Record<string, string>
}

type ExtraFieldsFormProps = ExtendableDialogFormProps<ExtraFieldsFormData>

const ExtraFieldsForm: FC<ExtraFieldsFormProps> = ({
    ...props
}) => {
    const event = useEvent()!
    const [extraFields, setExtraFields] = useImmer(Object.entries(event.extraFields))

    const updateKey = (idx: number, key: string) => setExtraFields(fields => {
        fields[idx][0] = key
    })
    const updateValue = (idx: number, value: string) => setExtraFields(fields => {
        fields[idx][1] = value
    })
    const deleteRow = (idx: number) => setExtraFields(fields => {
        fields.splice(idx, 1)
    })
    const addRow = () => setExtraFields(fields => {
        fields.push(['', ''])
    })

    return <DialogForm
        formData={{
            extraFields: Object.fromEntries(extraFields)
        }}
        {...props}
    >
        <Grid container>
            {extraFields.map((extraField, i) => <Grid container key={i}>
                <Grid item xs={3} sx={{ padding: '10px' }}>
                    <TextField
                        sx={{ width: '100%' }}
                        value={extraField[0]}
                        onChange={e => updateKey(i, e.target.value)}
                        required
                    />
                </Grid>
                <Grid item xs={9} sx={{ padding: '10px' }}>
                    <TextField
                        sx={{ width: 'calc(100% - 75px)', marginRight: '10px' }}
                        value={extraField[1]}
                        onChange={e => updateValue(i, e.target.value)}
                        required
                    />
                    <Button
                        color="error"
                        onClick={() => deleteRow(i)}
                        startIcon={<DeleteIcon />}
                        variant="text"
                        size="small"
                        sx={{ height: '100%' }}
                    ></Button>
                </Grid>
            </Grid>)}
            <Grid item xs={12} sx={{ textAlign: 'right', padding: '10px' }}>
                <Button
                    color="primary"
                    onClick={addRow}
                    startIcon={<AddIcon />}
                    variant="contained"
                >Add row</Button>
            </Grid>
        </Grid>
    </DialogForm>
}