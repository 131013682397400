import { Permission } from "@marketpartner/backend-api"
import { ExternalLink, ExternalLinkProps } from "@marketpartner/mp-common-mui"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { useEvent } from "src/events/event-context"
import { useLoginContext } from "src/login/LoginContext"

export type EventPartnerEditLinkProps = Partial<ExternalLinkProps> & {
    hideIfDenied?: boolean
}

export const EventPartnerEditLink: FC<EventPartnerEditLinkProps> = ({
    children,
    hideIfDenied,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const login = useLoginContext()

    if (login.hasPermission(Permission.EditEventContent, client.id, event.id)) {
        return <ExternalLink children={children} {...props} />
    }
    if (hideIfDenied) {
        return <></>
    }
    return <>{children}</>
}