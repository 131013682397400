import { findFormField } from "@marketpartner/backend-api"
import { FormElementCheck } from "src/forms/elements/checks/form-element-checks"


export type EmailCheckOptions = {
    required: boolean
}

export const emailElementCheck = ({
    required
}: EmailCheckOptions): FormElementCheck => elements => {
    const emailField = findFormField(elements, "email")

    if (!emailField) {
        if (!required) {
            return
        }
        return {
            renderIssueContent: () => <>Form must have an <b>email</b> field</>,
            level: "error"
        }
    }
    const emailIdx = elements.indexOf(emailField)

    if (required && !emailField.required) {
        return {
            renderIssueContent: () => <><b>email</b> field must be required</>,
            level: "error",
            elementIdxs: [emailIdx]
        }
    }
}
