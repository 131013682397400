import { RegistrationCategory } from "@marketpartner/backend-api"
import { Edit } from "@mui/icons-material"
import { Stack, Typography } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import { FC } from "react"
import { Bar } from "src/common/layout/Bar"
import { BasicProperties, BasicProperty } from "src/common/layout/BasicProperties"
import { fullSize } from "src/common/styles"
import { profileSettingsLabels } from "src/registrations/configuration/categories/profile-settings/profile-settings-labels"
import { ProfileFieldsGrid } from "src/registrations/configuration/categories/profile-settings/ProfileFieldsGrid"
import { useProfileSettingsDialog } from "src/registrations/configuration/categories/profile-settings/ProfileSettingsDialog"


export type ProfileSettingsTabProps = {
    category: RegistrationCategory
}

export const ProfileSettingsTab: FC<ProfileSettingsTabProps> = ({
    category,
}) => {
    const standardProfileFieldsDialog = useProfileSettingsDialog()
    const labels = profileSettingsLabels(category)

    return <Stack spacing={2} sx={{ ...fullSize, overflowY: "auto" }}>
        <Stack>
            <Bar spacing={1} mx={2}>
                <Typography variant="h6">
                    Settings
                </Typography>
                <IconButton
                    onClick={() => standardProfileFieldsDialog.open({ category })}
                    color="primary"
                    children={<Edit />}
                />
            </Bar>
            <BasicProperties sx={{ mx: 2 }}>
                <BasicProperty name={labels.profileVisibilityLevel} value={category.profileVisibilityLevel} />
                <BasicProperty name={labels.profile_visible} value={category.defaultProfileFields.profile_visible} />
                <BasicProperty name={labels.direct_message_opt_in} value={category.defaultProfileFields.direct_message_opt_in} />
                <BasicProperty name={labels.allow_meeting_requests} value={category.defaultProfileFields.allow_meeting_requests} />
                <BasicProperty name={labels.profileEditableByUser} value={category.profileEditableByUser} />
            </BasicProperties>
        </Stack>
        <Stack>
            <Typography variant="h6" sx={{ mx: 2 }}>
                Profile field summary
            </Typography>
            <ProfileFieldsGrid category={category} />
        </Stack>
    </Stack>
}