import { Registration } from "@marketpartner/backend-api"
import { casualName, initials } from "@marketpartner/mp-common"
import Link from "@mui/material/Link"
import { FC } from "react"
import { Link as RouterLink } from 'react-router-dom'
import { useClient } from "src/clients/client-context"
import { link } from "src/common/routing/routes"
import { ContentAvatar, ContentAvatarProps } from "src/content/ContentAvatar"
import { useEvent } from "src/events/event-context"

export type ProfileAvatarProps = ContentAvatarProps & {
    registration: Registration
    linkToProfile?: boolean
}

export const ProfileAvatar: FC<ProfileAvatarProps> = ({
    registration,
    sx,
    linkToProfile = false,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!

    const avatarContent = <ContentAvatar
        src={registration.profile.profile_picture?.absoluteUrl}
        alt={casualName(registration.profile)}
        children={initials(registration.profile)}
        {...props}
    />

    if (linkToProfile) {
        return <Link
            component={RouterLink}
            to={link.toRegistrationProfile(client.id, event.id, registration.id)}
            underline="none"
            children={avatarContent}
        />
    }

    return avatarContent
}