import { Scope, StandardContentTypeId, standardContentTypes } from "@marketpartner/backend-api";
import { Divider, ListItemIcon, ListItemText, ListSubheader, MenuItem } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { createPopupHook } from "src/common/dialogs/Popup";
import { LoadingMenuItem } from "src/common/menus/LoadingMenuItem";
import { LockableMenu, LockableMenuProps } from "src/common/menus/LockableMenu";
import { withNotification } from "src/common/notifications/with-notification";
import { ContentTypeIcon } from "src/content/types/ContentTypeIcon";
import { useCreateContentTypeDialog } from "src/content/types/CreateContentTypeDialog";
import { useEvent } from "src/events/event-context";


export type AddContentTypeMenuProps = LockableMenuProps & {
    missingStdTypeIds: StandardContentTypeId[]
}

const AddContentTypeMenu: FC<AddContentTypeMenuProps> = ({
    missingStdTypeIds,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!

    const createMutation = backend.contentTypes.useCreateType(withNotification({
        errorMessage: "Error creating content type",
    }))

    const createDialog = useCreateContentTypeDialog()

    return <LockableMenu
        closeOnClick
        {...props}
    >
        <MenuItem onClick={() => createDialog.open({})}>
            <ListItemIcon><ContentTypeIcon /></ListItemIcon>
            <ListItemText primary="Custom content type" />
        </MenuItem>
        <Divider />
        <ListSubheader>Standard content types</ListSubheader>
        {missingStdTypeIds.map(typeId => <LoadingMenuItem
            key={typeId}
            onClick={() => createMutation.mutateAsync([client.id, event.id, standardContentTypes[typeId] as any])}
        >
            <ListItemIcon><ContentTypeIcon typeId={typeId} /></ListItemIcon>
            <ListItemText primary={standardContentTypes[typeId].name} />
        </LoadingMenuItem>)}
    </LockableMenu>
}

export const useAddContentTypeMenu = createPopupHook({
    element: AddContentTypeMenu,
    scope: Scope.Event,
})