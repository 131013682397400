import { filterFormFields, FormElementConfig, FormElementMetadata, isFormField } from "@marketpartner/backend-api"
import { Add } from "@mui/icons-material"
import { Button, Collapse, FormControlLabel, Switch } from "@mui/material"
import { Bar } from "src/common/layout/Bar"
import { Spacer } from "src/common/layout/Spacer"
import { ReorderButtons } from "src/common/reordering/ReorderButtons"
import { useAddFormElementMenu } from "src/forms/elements/AddFormElementMenu"
import { elementTypeCheck } from "src/forms/elements/checks/element-type-check"
import { useFormElementChecks } from "src/forms/elements/checks/form-element-checks"
import { FormElementChecks } from "src/forms/elements/checks/FormElementChecks"
import { EditableFormElementsProps } from "src/forms/elements/EditableFormElements"
import { useEditableFormElementsContext } from "src/forms/elements/EditableFormElementsContext"


export type EditableFormElementsToolbarProps<Metadata extends FormElementMetadata> = EditableFormElementsProps<Metadata> & {
    showGrid: boolean
    setShowGrid: (show: boolean) => void
    onResetForm: () => void
}

export const EditableFormElementsToolbar = <Metadata extends FormElementMetadata>({
    showGrid,
    setShowGrid,
    onResetForm,
    elements,
    defaultSourceType,
    commonElements = [],
    elementChecks = [],
}: EditableFormElementsToolbarProps<Metadata>) => {
    const { canEdit, reorder } = useEditableFormElementsContext()

    const addElementMenu = useAddFormElementMenu()

    const issues = useFormElementChecks(elements, [
        ...filterFormFields(commonElements as FormElementConfig[]).map(it => elementTypeCheck(it.name, it.type)),
        ...elementChecks
    ])

    const formElementNames = filterFormFields(elements).map(it => it.name)
    const missingCommonElements = (commonElements as FormElementConfig[])
        .filter(it => !isFormField(it) || !formElementNames.includes(it.name))

    return <Bar spacing={1} py={1} px={2}>
        <FormElementChecks issues={issues} />
        <Spacer />
        {canEdit && <Collapse orientation="horizontal" in={!showGrid}>
            <ReorderButtons reorder={reorder} />
        </Collapse>}
        {!reorder.isReordering && <>
            {canEdit && <Button
                onClick={e => addElementMenu.open({
                    anchorEl: e.currentTarget,
                    commonElements: missingCommonElements,
                    defaultSourceType,
                    existingElements: elements,
                })}
                children="Add"
                variant="contained"
                startIcon={<Add />}
            />}
            <Collapse orientation="horizontal" in={!showGrid}>
                <Button
                    onClick={onResetForm}
                    children="Reset form"
                    color="inherit"
                    sx={{ whiteSpace: "nowrap" }}
                />
            </Collapse>
            <FormControlLabel label="Grid" control={<Switch
                checked={showGrid}
                onChange={() => setShowGrid(!showGrid)}
            />} />
        </>}
    </Bar>
}