import { LocalDateTime, Location, MeetingCalendar } from "@marketpartner/backend-api"
import { Checkbox, FormControlLabel } from "@mui/material"
import { Stack } from "@mui/system"
import { DateTime, Interval } from "luxon"
import { FC, useState } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { DialogForm, ExtendableDialogFormProps } from "src/common/dialogs/DialogForm"
import { LoadedAutocomplete } from "src/common/form-inputs/LoadedAutocomplete"
import { useEvent } from "src/events/event-context"
import { NetworkingTimeslot } from "src/networking/meetings/periods/NetworkingTimeslot"
import { NetworkingTimeslots } from "src/networking/meetings/periods/NetworkingTimeslots"


export type ScheduleMeetingFormData = {
    localStart: LocalDateTime
    localEnd: LocalDateTime
    locationId: string | null
}

export type ScheduleMeetingFormProps = ExtendableDialogFormProps<ScheduleMeetingFormData> & {
    requesterId: string
    respondantId: string
    existingStart?: DateTime
    existingEnd?: DateTime
    existingLocationId?: string | null
}

export const ScheduleMeetingForm: FC<ScheduleMeetingFormProps> = ({
    requesterId,
    respondantId,
    existingStart,
    existingEnd,
    existingLocationId = null,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const existingInterval = existingStart && existingEnd && Interval.fromDateTimes(existingStart, existingEnd)
    const filterAvailableLocations = (locations: Location[], timeslot: Interval) => locations.filter(location =>
        location.bookableAvailability.engulfs(timeslot) ||
        (location.id === existingLocationId && existingInterval && timeslot.equals(existingInterval))
    )

    const [requireLocation, setRequireLocation] = useState(existingLocationId !== null)
    const [selectedTimeslot, setSelectedTimeslot] = useState(existingInterval)
    const [locationId, setLocationId] = useState(existingLocationId)

    const requesterCalendarQuery = backend.meetings.useGetMeetingCalendar<MeetingCalendar>([client.id, event.id, requesterId])
    const respondantCalendarQuery = backend.meetings.useGetMeetingCalendar<MeetingCalendar>([client.id, event.id, respondantId])
    const allLocationsQuery = backend.locations.useGetLocations([client.id, event.id])
    const availableLocationsQuery = backend.locations.useGetLocations([client.id, event.id], {
        select: locations => selectedTimeslot ?
            filterAvailableLocations(locations, selectedTimeslot) :
            []
    })
    const availableLocationsForTimeslot = (timeslot: Interval) => filterAvailableLocations(allLocationsQuery.data ?? [], timeslot)

    const participantsAreAvailable = (timeslot: Interval) => {
        if (!requesterCalendarQuery.data || !respondantCalendarQuery.data) {
            return false
        }
        let requesterMeetingTimes = requesterCalendarQuery.data.existingMeetingTimes
        let respondantAvailability = respondantCalendarQuery.data.bookableAvailability

        if (existingInterval) {
            requesterMeetingTimes = requesterMeetingTimes.subtract(existingInterval)
            respondantAvailability = respondantAvailability.add(existingInterval)
        }

        return !requesterMeetingTimes.overlaps(timeslot) && respondantAvailability.engulfs(timeslot)
    }

    const handleSelectTimeslot = (timeslot: Interval) => {
        if (locationId && !availableLocationsForTimeslot(timeslot).some(it => it.id === locationId)) {
            setLocationId(null)
        }
        setSelectedTimeslot(timeslot)
    }

    return <DialogForm
        formData={{
            localStart: LocalDateTime.fromZonedDateTime(selectedTimeslot!.start),
            localEnd: LocalDateTime.fromZonedDateTime(selectedTimeslot!.end),
            locationId,
        }}
        isValid={Boolean(
            selectedTimeslot &&
            (!requireLocation || locationId)
        )}
        {...props}
        dialogContentStyle={{ pt: 0 }}
        footerControls={<LoadedAutocomplete
            fetchRequest={availableLocationsQuery}
            label="Location"
            selectedId={locationId}
            onSelectId={setLocationId}
            size="small"
            sx={{ flex: 1 }}
        />}
    >
        <Stack>
            <FormControlLabel
                label="Require location"
                control={<Checkbox checked={requireLocation} onClick={() => setRequireLocation(!requireLocation)} />}
            />
            <NetworkingTimeslots
                renderTimeslot={timeslot => {
                    const locationCount = availableLocationsForTimeslot(timeslot).length
                    const available = participantsAreAvailable(timeslot) &&
                        (locationCount > 0 || !requireLocation)

                    return <NetworkingTimeslot
                        timeslot={timeslot}
                        timeslotStyle={(selectedTimeslot && timeslot.equals(selectedTimeslot)) ? "green" :
                            available ? "default" :
                                "disabled"
                        }
                        onClick={available ? () => handleSelectTimeslot(timeslot) : undefined}
                        secondaryText={`${locationCount} location${locationCount === 1 ? "" : "s"}`}
                    />;
                }}
            />
        </Stack>
    </DialogForm>
}