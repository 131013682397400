import { Agenda, Scope } from "@marketpartner/backend-api"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { ConfirmDeleteDialog } from "src/common/dialogs/ConfirmDeleteDialog"
import { LockableDialogProps } from "src/common/dialogs/LockableDialog"
import { createPopupHook } from "src/common/dialogs/Popup"
import { useEvent } from "src/events/event-context"


type DeleteAgendaDialogProps = LockableDialogProps & {
    agenda: Agenda
}

const DeleteAgendaDialog: FC<DeleteAgendaDialogProps> = ({
    agenda,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const deleteAgendaMutation = backend.agendas.useDelete()

    return <ConfirmDeleteDialog
        itemName={agenda.name}
        formProps={{
            action: () => deleteAgendaMutation.mutateAsync([client.id, event.id, agenda.id]),
            text: `This will delete all associated sessions & bookings`,
            confirmText: agenda.name,
        }}
        {...props}
    />
}

export const useDeleteAgendaDialog = createPopupHook({
    scope: Scope.Event,
    element: DeleteAgendaDialog,
})
