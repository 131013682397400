import { MfaSecretCode } from "@marketpartner/backend-api"
import { LoadingButton } from "@mui/lab"
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, TextField, Typography } from "@mui/material"
import QRCode from "qrcode.react"
import { FC, ReactNode, useState } from "react"
import { backend } from "src/common/api"
import { useDialogContext } from "src/common/dialogs/LockableDialog"
import { getEnvironment } from "src/common/environment"
import { withNotification } from "src/common/notifications/with-notification"
import { useLoginContext } from "src/login/LoginContext"

export type MfaSetupDialogContentProps = {
    preamble: ReactNode
    closeText?: string
}

export const MfaSetupDialogContent: FC<MfaSetupDialogContentProps> = ({
    preamble,
    closeText = "close"
}) => {
    const dialog = useDialogContext()
    const [secret, setSecret] = useState<MfaSecretCode>()
    const [verificationCode, setVerificationCode] = useState("")
    const [isInvalidCode, setIsInvalidCode] = useState(false)
    const requestSecretMutation = backend.adminUsers.useRequestMfaSecretCode(withNotification({
        onSuccess: setSecret,
        errorMessage: "Failed to get secret MFA code"
    }))
    const verifyMfaCodeMutation = backend.adminUsers.useVerifyMfaCode(withNotification({
        onSuccess: () => dialog.close(),
        onError: () => setIsInvalidCode(true),
        successMessage: "MFA setup complete",
        errorMessage: "Invalid MFA code"
    }))

    const updateVerificationCode = (code: string) => {
        setIsInvalidCode(false)
        setVerificationCode(code
            .replace(/[^0-9]/, "")
        )
    }

    const submitText = !secret ? "Start set up" : "Verify MFA code"
    const submitAction = !secret ?
        () => requestSecretMutation.mutate([]) :
        () => verifyMfaCodeMutation.mutate([{ code: verificationCode }])
    const isLoading = requestSecretMutation.isPending || verifyMfaCodeMutation.isPending
    const disableSubmit = secret && verificationCode.length !== 6

    return <>
        <DialogTitle>Setup multi-factor authentication</DialogTitle>
        <form onSubmit={e => {
            submitAction()
            e.preventDefault()
        }}>
            <DialogContent>
                {!secret ?
                    <DialogContentText component="section">{preamble}</DialogContentText> :
                    <MfaVerification
                        secret={secret}
                        verificationCode={verificationCode}
                        setVerificationCode={updateVerificationCode}
                        isInvalid={isInvalidCode}
                    />
                }
            </DialogContent>
            <DialogActions>
                <Button
                    children={closeText}
                    color="inherit"
                    onClick={() => dialog.close()}
                />

                <LoadingButton
                    children={submitText}
                    type="submit"
                    variant="contained"
                    loading={isLoading}
                    disabled={disableSubmit}
                />
            </DialogActions>
        </form>
    </>
}

type MfaVerificationProps = {
    secret: MfaSecretCode
    verificationCode: string
    setVerificationCode: (code: string) => void
    isInvalid: boolean
}

const MfaVerification: FC<MfaVerificationProps> = ({
    secret,
    verificationCode,
    setVerificationCode,
    isInvalid
}) => {
    const login = useLoginContext()

    return <Stack alignItems="center" spacing={2}>
        <Typography>
            <b>Step 1.</b> Scan the QR code using your authenticator app
        </Typography>
        <QRCode value={getMfaUri(login.name, secret.code)} renderAs="svg" />
        <Typography>
            <b>Step 2.</b> Enter a verification code to complete the setup
        </Typography>
        <TextField
            value={verificationCode}
            onChange={e => setVerificationCode(e.target.value)}
            label="Verification code"
            placeholder="123456"
            error={isInvalid}
            helperText={isInvalid ? "Invalid code" : " "}
        />
    </Stack>
}

function getMfaUri(name: string, code: string) {
    const issuer = getEnvironment() === "dev" ? "Market Partner DEV" : "Market Partner"
    return `otpauth://totp/${name}?secret=${code}&issuer=${issuer}`
}
