import { EventDetails, FormElementConfig, FormType, RegistrationCategory } from "@marketpartner/backend-api"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"


export enum FormElementSourceType {
    RegistrationCategories = "Registration categories",
    Forms = "Forms",
    PollQuestions = "Poll questions",
    ContentTypes = "Content types",
    EventFields = "Event fields",
}

export type FormElementSource = {
    name: string
    elements: FormElementConfig[]
}

export type FormElementSources = Record<FormElementSourceType, FormElementSource[]>

export type UseFormElementSources = {
    sources: FormElementSources
    isLoading: boolean
}

/**
 * When new dynamic form elements are added to a new type of entity, make sure to add a query
 * here to enable copying elements from those entities.
 */
export const useFormElementSources = (eventId: string) => {
    const client = useClient()!
    const categoriesQuery = backend.registrationCategories.useGetAll([client.id, eventId], {
        placeholderData: [],
    })
    const eventQuery = backend.events.useGetEvent([client.id, eventId])
    const formsQuery = backend.forms.useGetAll([client.id, eventId], {
        select: forms => forms.filter(form => form.type === FormType.Standard),
        placeholderData: [],
    })
    const pollsQuery = backend.polls.useGetAll([client.id, eventId], {
        placeholderData: [],
    })
    const contentTypesQuery = backend.contentTypes.useGetTypes([client.id, eventId], {
        placeholderData: [],
    })
    const eventsQuery = backend.events.useGetEvents<EventDetails[]>([client.id], {
        placeholderData: [],
    })
    const allQueries = [categoriesQuery, eventQuery, formsQuery, pollsQuery, contentTypesQuery, eventsQuery]

    const registrationSources = (
        category: RegistrationCategory
    ): FormElementSource[] => [
            {
                name: `${category.name} registration`,
                elements: category.elements,
            },
            {
                name: `${category.name} profile`,
                elements: category.profileElements,
            }
        ]

    const guestRegistrationSources = !eventQuery.data ? [] : [
        {
            name: "Guest registration",
            elements: eventQuery.data.guestElements,
        },
        {
            name: "Guest profile",
            elements: eventQuery.data.guestProfileElements,
        }
    ]

    const sources: FormElementSources = {
        "Registration categories": [
            ...categoriesQuery.data!.flatMap(category => registrationSources(category)),
            ...guestRegistrationSources,
        ],
        "Forms": formsQuery.data!.map(form => ({
            name: form.name,
            elements: form.elementList,
        })),
        "Poll questions": pollsQuery.data!.flatMap(poll => poll.questions.map(question => ({
            name: question.name,
            elements: question.elements,
        }))),
        "Content types": contentTypesQuery.data!.map(type => ({
            name: type.name,
            elements: type.formElements,
        })),
        "Event fields": eventsQuery.data!.map(event => ({
            name: event.name,
            elements: event.fieldElements,
        })),
    }

    return {
        sources,
        isLoading: allQueries.some(query => query.isLoading),
    }
}