import { Permission, RegistrationApprovalState, RegistrationType } from "@marketpartner/backend-api"
import { ClearableTextField } from "@marketpartner/mp-common-mui"
import { useSearchCallback, useUrlState } from "@marketpartner/mp-common-react"
import { Add, ViewColumn } from "@mui/icons-material"
import { Box, Button, SxProps } from "@mui/material"
import { FC, useCallback, useMemo } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { Bar } from "src/common/layout/Bar"
import { BarAndFlex } from "src/common/layout/BarAndFlex"
import { useEvent } from "src/events/event-context"
import { ifHas } from "src/login/IfHas"
import { useAddRegistrationMenu } from "src/registrations/actions/AddRegistrationMenu"
import { AlterApprovalDialog } from "src/registrations/approval/UpdateApprovalDialog"
import { getRegistrationTreePath } from "src/registrations/BaseRegistrationsGrid"
import { useEditComputedColumnsDialog } from "src/registrations/computed-columns/EditComputedColumnsDialog"
import { ImportRegistrationsButton } from "src/registrations/import/ImportRegistrationsButton"
import { RegistrationExportButton } from "src/registrations/RegistrationExportButton"
import { buildRegistrationColumns, RegistrationsGrid, RegistrationWithComputedColumns } from "src/registrations/RegistrationsGrid"
import { RegistrationTotals } from "src/registrations/RegistrationTotals"

const registrationSearchFields = (reg: RegistrationWithComputedColumns) => [
    reg.id,
    reg.email,
    reg.firstName,
    reg.lastName,
    reg.accessToken,
    reg.type === RegistrationType.Primary ? reg.categoryId : reg.primaryRegistrationId,
    ...Object.values(reg.fields)
]
const registrationSearchOptions = {
    treeOptions: {
        getTreePath: getRegistrationTreePath,
        alwaysReturnChildrenOfMatch: true
    }
} as const

export type RegistrationsProps = {
    sx?: SxProps
}

export const Registrations: FC<RegistrationsProps> = ({
    sx
}) => {
    const client = useClient()!
    const event = useEvent()!
    const addRegistrationMenu = useAddRegistrationMenu()
    const computedColumnsMenu = useEditComputedColumnsDialog()

    const computedColumnValuesQuery = backend.computedColumns.useGetAllComputedColumnValues([client.id, event.id])
    const registrationsQuery = backend.registrations.useGetAll([client.id, event.id], {
        select: registrations => registrations.map(reg => ({
            ...reg,
            computedValues: computedColumnValuesQuery.data?.[reg.id] ?? {}
        }) as RegistrationWithComputedColumns)
    })
    const registrations = useMemo(
        () => registrationsQuery.data ?? [],
        [registrationsQuery.data]
    )

    const [selectedStatus, setSelectedStatus] = useUrlState("status", { disableDebounce: true })
    const validSelectedStatus = Object.values(RegistrationApprovalState).includes(selectedStatus as any) ?
        selectedStatus as RegistrationApprovalState :
        undefined

    const [searchText, setSearchText, searchRows] = useSearchCallback(registrationSearchFields, registrationSearchOptions)

    const filterRows = useCallback(
        (rows: RegistrationWithComputedColumns[]) => searchRows(
            rows.filter(it => !validSelectedStatus || it.approvalState === validSelectedStatus)
        ),
        [searchRows, validSelectedStatus]
    )

    const columns = useMemo(
        () => buildRegistrationColumns(registrations),
        [registrations]
    )

    return <BarAndFlex sx={sx} barContent={
        <Bar spacing={1.5} m={2}>
            <ClearableTextField
                value={searchText ?? ""}
                onChange={e => setSearchText(e.target.value)}
                onClear={() => setSearchText("")}
                label="Search"
                size="small"
            />
            <RegistrationTotals
                registrations={registrations}
                selectedStatus={validSelectedStatus}
                onSelectStatus={status => setSelectedStatus(status ?? "")}
            />
            <Box mx="auto !important" />
            <RegistrationExportButton registrations={registrations} gridColumns={columns} />
            <ImportRegistrationsButton />
            {ifHas(Permission.ImportAndDeleteRegistrations, client.id, event.id, <Button
                children="Add"
                variant="contained"
                startIcon={<Add />}
                onClick={e => addRegistrationMenu.open({
                    anchorEl: e.currentTarget,
                })}
            />)}
            {ifHas(Permission.EditEventDetails, client.id, event.id, <Button
                children="Computed columns"
                startIcon={<ViewColumn />}
                onClick={() => computedColumnsMenu.open({})}
            />)}
        </Bar>
    }>
        <AlterApprovalDialog>
            <RegistrationsGrid
                queryOrRows={registrationsQuery}
                filterRows={filterRows}
                columns={columns}
            />
        </AlterApprovalDialog>
    </BarAndFlex>
}
