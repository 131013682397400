import { ActionSegmentUsage, ActionUsage, ActivityStatistics, ActivityType, EventSegment, TimedActivitySegmentUsage, TimedActivityUsage, WithRegistration } from "@marketpartner/backend-api"
import { SearchBox } from "@marketpartner/mp-common-mui"
import { useSearch } from "@marketpartner/mp-common-react"
import BarChartIcon from '@mui/icons-material/BarChart'
import PeopleIcon from '@mui/icons-material/People'
import { DialogContent, DialogTitle, Stack, Tab, Tabs, Typography } from "@mui/material"
import { FC, useState } from "react"
import { ActivitySegmentUsageGrid } from "src/activities/usage/ActivitySegmentUsageGrid"
import { ActivityUsageGraph } from "src/activities/usage/ActivityUsageGraph"
import { ActivityUsageGrid } from "src/activities/usage/ActivityUsageGrid"
import { useClient } from "src/clients/client-context"
import { backend } from 'src/common/api'
import { useDialogContext } from 'src/common/dialogs/LockableDialog'
import { Loading } from "src/common/loading/Loading"
import { useEvent } from "src/events/event-context"


export type ActivityUsersDialogContentProps = {
    activity: ActivityStatistics
    segment?: EventSegment
}

export const ActivityUsersDialogContent: FC<ActivityUsersDialogContentProps> = ({
    activity,
    segment,
}) => {
    const { activityType, activityId } = activity

    const title = activityId === "online:online" ?
        "Online" :
        `${activity.category} ${activity.name}`

    return <>
        <DialogTitle>
            {title}
            {<Typography component="span" variant="subtitle1"> ({segment?.name ?? "All time"})</Typography>}
        </DialogTitle>
        <DialogContent sx={{ minWidth: "600px" }}>
            {activityType === ActivityType.timed ?
                <TimedActivityContent activityId={activityId} segment={segment} /> :
                <UsageList activityType={activityType} activityId={activityId} segment={segment} />
            }
        </DialogContent>
    </>
}

type TimedActivityContentProps = {
    activityId: string
    segment?: EventSegment
}

const TimedActivityContent: FC<TimedActivityContentProps> = ({
    activityId,
    segment,
}) => {
    const [tab, setTab] = useState("users")

    return <Stack spacing={4} sx={{ height: "100%" }}>
        <Tabs value={tab} onChange={(_, newTab) => setTab(newTab)} variant='fullWidth'>
            <Tab value="users" icon={<PeopleIcon />} iconPosition="start" label="Users" sx={{ minHeight: 0 }} />
            <Tab value="usage" icon={<BarChartIcon />} iconPosition="start" label="Usage" sx={{ minHeight: 0 }} />
        </Tabs>
        {tab === "users" && <UsageList activityType={ActivityType.timed} activityId={activityId} segment={segment} />}
        {tab === "usage" && <LoadingActivityUsageGraph activityId={activityId} segment={segment} />}
    </Stack>
}

type LoadingActivityUsageGraphProps = {
    activityId: string
    segment?: EventSegment
}

const LoadingActivityUsageGraph: FC<LoadingActivityUsageGraphProps> = ({
    activityId,
    segment,
}) => {
    const client = useClient()!
    const event = useEvent()!
    const dialog = useDialogContext()
    const historicalUsageQuery = backend.activityTimeseries.useGetHistoricalActivityUsage([client.id, event.id, activityId], {
        refetchInterval: 60_000
    })

    const width = dialog.fullScreen ? "100%" : "800px"
    const height = dialog.fullScreen ? "400px" : "300px"

    return <Loading request={historicalUsageQuery} sizing="fit-content" render={historicalUsage =>
        <ActivityUsageGraph usage={historicalUsage} segment={segment} sx={{ width, height }} />
    } />
}

type UsageListProps = {
    activityType: ActivityType
    activityId: string
    segment?: EventSegment
}

const UsageList: FC<UsageListProps> = ({
    activityType,
    activityId,
    segment,
}) => {
    const client = useClient()!
    const event = useEvent()!
    const usageQuery = backend.activities.useGetActivityUsage([client.id, event.id, activityType, activityId, segment?.id], {
        refetchInterval: segment ? 0 : 5_000
    })
    const [searchText, setSearchText, filteredUsage] = useSearch(
        usageQuery,
        extractSearchValues,
        { disableUrlState: true }
    )

    return <Loading
        request={filteredUsage}
        sizing="fit-content"
        render={usage => <Stack spacing={1} sx={{ pt: 1 }}>
            <SearchBox
                searchText={searchText}
                setSearchText={setSearchText}
                onClear={() => setSearchText("")}
                size="small"
            />
            {segment ?
                <ActivitySegmentUsageGrid
                    activityType={activityType}
                    usage={usage as WithRegistration<ActionSegmentUsage | TimedActivitySegmentUsage>[]}
                /> :
                <ActivityUsageGrid
                    activityType={activityType}
                    usage={usage as WithRegistration<ActionUsage | TimedActivityUsage>[]}
                />}
        </Stack>} />
}

const extractSearchValues = <Usage extends WithRegistration<unknown>>({ registration }: Usage): any[] => {
    if (!registration) {
        return []
    }
    return [
        registration.title,
        registration.firstName,
        registration.lastName,
        registration.email,
    ]
}