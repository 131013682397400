import { FormElementConfig, FormElementMetadata } from "@marketpartner/backend-api"
import { FormElementState } from "@marketpartner/mp-common-react"
import { createContext, FC, ReactNode, useContext } from "react"
import { UseBulkReorder } from "src/common/reordering/useBulkReorder"


export type EditableFormElementsContext = {
    canEdit: boolean
    elements: FormElementConfig[]
    onChangeElementIdx: (idx: number) => (config: FormElementConfig) => Promise<unknown>
    onDeleteElementIdx: (idx: number) => () => Promise<unknown>
    onCreateElements: (newElements: FormElementConfig[]) => Promise<unknown>
    renderMetadataControls?: (
        config: FormElementConfig<any>,
        onChange: (config: FormElementConfig<any>) => void,
    ) => ReactNode
    renderExtraControls?: (config: FormElementConfig<any>) => ReactNode
    defaultMetadata?: FormElementMetadata
    reorder: UseBulkReorder<FormElementState<any>>
}

const context = createContext<EditableFormElementsContext>(undefined!)

export const useEditableFormElementsContext = () => useContext(context)

export type EditableFormElementsContextProviderProps = EditableFormElementsContext & {
    children: ReactNode
}

export const EditableFormElementsContextProvider: FC<EditableFormElementsContextProviderProps> = ({
    children,
    ...contextValue
}) => <context.Provider
        value={contextValue}
        children={children}
    />