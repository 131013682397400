import { FormElementMetadata } from "@marketpartner/backend-api"
import { muiElementComponents } from "@marketpartner/mp-common-mui"
import { DynamicFormElement, FormElementState } from "@marketpartner/mp-common-react"
import { Box, Collapse } from "@mui/material"
import { Bar } from "src/common/layout/Bar"
import { ContextMenuButton } from "src/common/menus/ContextMenuButton"
import { DeleteFormElementDialogProps } from "src/forms/elements/DeleteFormElementDialog"
import { useEditableFormElementsContext } from "src/forms/elements/EditableFormElementsContext"
import { EditFormElementDialogProps } from "src/forms/elements/EditFormElementDialog"
import { useFormElementContextMenu } from "src/forms/elements/FormElementContextMenu"


export type EditableFormElementProps<Metadata extends FormElementMetadata> = {
    state: FormElementState<Metadata>
    onChangeElement: EditFormElementDialogProps["onChangeElement"]
    onDelete: DeleteFormElementDialogProps["onDelete"]
    disableEdit: boolean
}

export const EditableFormElement = <Metadata extends FormElementMetadata = FormElementMetadata>({
    state,
    onChangeElement,
    onDelete,
    disableEdit,
}: EditableFormElementProps<Metadata>) => {
    const { canEdit, renderExtraControls } = useEditableFormElementsContext()
    const menu = useFormElementContextMenu()

    return <Bar spacing={1}>
        <Box sx={{ flexGrow: 1 }}>
            <DynamicFormElement
                state={state as FormElementState<FormElementMetadata>}
                elementComponents={muiElementComponents}
            />
        </Box>
        {renderExtraControls?.(state.config)}
        {canEdit && <Collapse in={!disableEdit} orientation="horizontal">
            <ContextMenuButton onClick={e => menu.open({
                element: state.config,
                onChangeElement,
                onDelete,
                anchorEl: e.currentTarget,
            })} />
        </Collapse>}
    </Bar>
}