import { CircularProgress, CircularProgressProps, Stack, Typography } from "@mui/material"
import { FC } from "react"

export type LoadingSpinnerProps = CircularProgressProps

export const LoadingSpinner: FC<LoadingSpinnerProps> = ({
    sx,
    ...props
}) => {
    return <Stack alignItems="center" sx={{ ...sx }}>
        <CircularProgress {...props} />
        <Typography>Loading...</Typography>
    </Stack>
}