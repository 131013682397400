import { ActivityType, TimedActivityDurationCondition } from "@marketpartner/backend-api";
import { AccessTime } from "@mui/icons-material";
import { Grid, TextField } from "@mui/material";
import { BooleanConditionProperties } from "src/common/boolean-conditions/boolean-condition-properties";
import { ActivityLookupList } from "src/registrations/computed-columns/boolean-conditions/ActivityLookupList";
import { EditableActivityLookups } from "src/registrations/computed-columns/EditableActivityLookups";


export const timedActivityDurationConditionProperties: BooleanConditionProperties<TimedActivityDurationCondition> = {
    typeName: "Timed activity duration",
    iconComponent: AccessTime,
    getPrimaryText: condition => `Total activity duration ≥ ${condition.targetDurationMinutes} m`,
    getSecondaryContent: condition => <ActivityLookupList lookups={condition.lookups} />,
    isValid: ({ targetDurationMinutes, lookups }) => targetDurationMinutes > 0 && lookups.length > 0 && lookups.every(lookup => lookup.id !== ""),
    createDefault: () => ({
        type: "TimedActivityDuration",
        targetDurationMinutes: 0,
        lookups: [],
    }),
    renderForm: (condition, onChangeProperties) => <Grid container spacing={2}>
        <Grid item xs={12}>
            <TextField
                value={condition.targetDurationMinutes}
                onChange={e => onChangeProperties({
                    targetDurationMinutes: parseInt(e.target.value)
                })}
                type="number"
                inputProps={{
                    min: 1,
                    step: 1,
                }}
                label="Target duration (minutes)"
            />
        </Grid>
        <Grid item xs={12}>
            <EditableActivityLookups
                activityType={ActivityType.timed}
                lookups={condition.lookups}
                onChangeLookups={lookups => onChangeProperties({ lookups })}
            />
        </Grid>
    </Grid>,
}