import { Permission } from '@marketpartner/backend-api';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Stack, Typography } from "@mui/material";
import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { Bar } from 'src/common/layout/Bar';
import { BarAndFlex } from 'src/common/layout/BarAndFlex';
import { fullSize } from 'src/common/styles';
import { useLoginContext } from "src/login/LoginContext";

export type PageProps = {
    title: string
    subtitle?: string
    back?: {
        hideIf?: boolean
        permission?: Permission
    }
    actions?: ReactNode
    minContentSize?: number
    children: ReactNode
}

export const Page: FC<PageProps> = ({
    title,
    subtitle,
    back,
    actions,
    minContentSize = 300,
    children
}) => {
    const login = useLoginContext()
    const navigate = useNavigate()

    const showBack = !back || (
        (!back.permission || login.hasPermission(back.permission)) &&
        !back.hideIf
    )

    return <BarAndFlex
        flexStyle={{ minHeight: 0 }}
        barContent={
            <Stack sx={{ px: 3, pt: 2, pb: 1, }}>
                <Bar>
                    {showBack && <Button
                        onClick={() => navigate(-1)}
                        variant="text"
                        startIcon={<ArrowBackIcon />}
                        color="inherit"
                        children="Back"
                    />}
                </Bar>
                <Bar flexWrap="wrap">
                    <Stack>
                        <Typography variant="h2" children={title} />
                        {subtitle && <Typography variant="subtitle1" children={subtitle} />}
                    </Stack>
                    <Box mx="auto" />
                    <Bar spacing={1} flexWrap="wrap">
                        {actions}
                    </Bar>
                </Bar>
            </Stack>
        }
    >
        <Stack sx={{ ...fullSize, overflow: "auto" }}>
            <Box sx={{ flexGrow: 1, flexShrink: 1, m: 3, position: "relative", minHeight: minContentSize }}>
                {children}
            </Box>
        </Stack>
    </BarAndFlex>
}