import { EventFeed, FeedType, Permission } from "@marketpartner/backend-api"
import Edit from "@mui/icons-material/Edit"
import { DialogTitle } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { DialogButton, DialogButtonProps } from "src/common/dialogs/DialogButton"
import { useEvent } from "src/events/event-context"
import { EventFeedForm } from "src/feeds/EventFeedForm"
import { ifHas } from "src/login/IfHas"

export type EditEventFeedButtonProps = Partial<DialogButtonProps> & {
    feed: EventFeed
}

export const EditEventFeedButton: FC<EditEventFeedButtonProps> = ({
    feed,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const updateFeedMutation = backend.feeds.useUpdateFeed()

    return ifHas(Permission.EditFeeds, client.id, event.id, <DialogButton
        icon={<Edit />}
        text="Edit"
        color="primary"
        variant="contained"
        {...props}
    >
        <DialogTitle>Edit feed</DialogTitle>
        <EventFeedForm
            actionName="Update"
            feed={feed}
            onSubmit={formData => updateFeedMutation.mutateAsync([client.id, event.id, feed.id, {
                type: FeedType.Event,
                ...formData,
            }])}
        />
    </DialogButton>)
}