import { BreakoutTrack } from "@marketpartner/backend-api"
import { ClearableTextField } from "@marketpartner/mp-common-mui"
import { Grid } from "@mui/material"
import { DateTime } from "luxon"
import { FC, useState } from "react"
import { DialogForm, ExtendableDialogFormProps } from "src/common/dialogs/DialogForm"


export type BreakoutTrackFormData = {
    name: string
}

export type BreakoutTrackFormProps = ExtendableDialogFormProps<BreakoutTrackFormData> & {
    track?: BreakoutTrack
    suggestedStart?: DateTime
}

export const BreakoutTrackForm: FC<BreakoutTrackFormProps> = ({
    track,
    suggestedStart,
    ...props
}) => {
    const [name, setName] = useState(track?.name ?? "")

    return <DialogForm
        formData={{
            name,
        }}
        {...props}
    >
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
                <ClearableTextField
                    value={name}
                    onChange={e => setName(e.target.value)}
                    onClear={() => setName("")}
                    label="Name"
                    placeholder="Track"
                    fullWidth
                />
            </Grid>
        </Grid>
    </DialogForm>
}