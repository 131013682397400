import { DialogContentText, TextField } from "@mui/material"
import { FC, ReactNode, useState } from "react"
import { DialogForm, ExtendableDialogFormProps } from "src/common/dialogs/DialogForm"

export type ConfirmDialogFormProps = Omit<ExtendableDialogFormProps, "onSubmit" | "actionName"> & {
    action: () => Promise<unknown>
    text: ReactNode,
    confirmText?: string
    submitText?: string
}

export const ConfirmDialogForm: FC<ConfirmDialogFormProps> = ({
    action,
    text,
    confirmText,
    submitText = "confirm",
    ...formProps
}) => {
    const [confirmedText, setConfirmedText] = useState("")

    return <DialogForm
        {...formProps}
        formData={{}}
        onSubmit={action}
        actionName={submitText}
        isValid={!confirmText || confirmedText === confirmText}
    >
        <DialogContentText component="section">
            {text}
            {confirmText && <>
                <p>
                    Type <b>{confirmText}</b> below to confirm.
                </p>
                <TextField
                    onChange={e => setConfirmedText(e.target.value)}
                    value={confirmedText}
                    fullWidth
                />
            </>}
        </DialogContentText>
    </DialogForm>
}
