import { ActionStatistics, ActivityAndUserStatistics, ActivityType, EventSegment, TimedActivityStatistics } from "@marketpartner/backend-api"
import { Box, Fade, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { FC } from "react"
import { ActivitiesApi } from "src/activities/activities-api"
import { ActivityUsersLink } from "src/activities/usage/ActivityUsersLink"
import { Bar } from "src/common/layout/Bar"
import { ProgressWithLabel } from "src/common/loading/ProgressWithLabel"
import { prettyDuration } from "src/common/time/pretty-duration"

const ignoredActivities = [
    ActivitiesApi.onlineActivityId,
    ActivitiesApi.physicalAttendanceId,
]
export type ActivityStatsGridProps = {
    stats: ActivityAndUserStatistics[]
    segment?: EventSegment
}

export const ActivityStatsGrid: FC<ActivityStatsGridProps> = ({
    stats,
    segment
}) => {
    const onlineStats = stats.filter(it => it.activityId === ActivitiesApi.onlineActivityId)[0]
    const userCount = onlineStats?.totalUsers ?? 0

    return <Box>
        <Typography variant="h5" sx={{ m: 2 }}>Activities</Typography>
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Category</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Participation</TableCell>
                        <TableCell>Total</TableCell>
                        <TableCell>Average per user</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {stats
                        .filter(activityStats => !ignoredActivities.includes(activityStats.activityId))
                        .map(activityStats =>
                            activityStats.activityType === ActivityType.timed ?
                                <TimedActivityStats key={`timed:${activityStats.activityId}`} stats={activityStats} userCount={userCount} segment={segment} /> :
                                <ActionStats key={`action:${activityStats.activityId}`} stats={activityStats} userCount={userCount} segment={segment} />
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </Box>
}

type TimedActivityStatsProps = {
    stats: ActivityAndUserStatistics & TimedActivityStatistics
    userCount: number
    segment?: EventSegment
}

const TimedActivityStats: FC<TimedActivityStatsProps> = ({
    stats,
    userCount,
    segment
}) => {

    return <TableRow>
        <TableCell>
            {stats.category}
        </TableCell>
        <TableCell>
            {stats.name}
        </TableCell>
        <TableCell>
            <ActivityUsers stats={stats} totalUsers={userCount} segment={segment} />
            <Bar spacing={1}>
                <span>Peak: {stats.peakUserCount ?? 0}</span>
                <Fade in={!Boolean(segment)}>
                    <span>Active: {stats.currentUserCount}</span>
                </Fade>
            </Bar>
        </TableCell>
        <TableCell>
            {prettyDuration(stats.totalDuration)}
        </TableCell>
        <TableCell>
            <Typography variant="body1">
                {prettyDuration(stats.averageDurationPerUser)}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                {stats.averageSessionsPerUser} sessions of {prettyDuration(stats.averageDurationPerSession)}
            </Typography>
        </TableCell>
    </TableRow>
}

type ActionStatisticsProps = {
    stats: ActivityAndUserStatistics & ActionStatistics
    userCount: number
    segment?: EventSegment
}

const ActionStats: FC<ActionStatisticsProps> = ({
    stats,
    userCount,
    segment
}) => {
    return <TableRow>
        <TableCell>
            {stats.category}
        </TableCell>
        <TableCell>
            {stats.name}
        </TableCell>
        <TableCell>
            <ActivityUsers stats={stats} totalUsers={userCount} segment={segment} />
        </TableCell>
        <TableCell>
            {stats.totalCount}
        </TableCell>
        <TableCell>
            {stats.averageCountPerUser}
        </TableCell>
    </TableRow>
}

type ActivityUsersProps = {
    stats: ActivityAndUserStatistics
    totalUsers: number
    segment?: EventSegment
}

const ActivityUsers: FC<ActivityUsersProps> = ({
    stats,
    totalUsers,
    segment
}) => {
    return <Stack>
        <ActivityUsersLink activity={stats} count={stats.totalUsers} segment={segment} />
        <ProgressWithLabel percentage={totalUsers ? (stats.totalUsers / totalUsers * 100) : 0} />
    </Stack>
}
