import { FormUploadType } from "@marketpartner/backend-api"
import { FormControl, InputLabel, ListItemText, MenuItem, Select } from "@mui/material"
import { FC } from "react"


export type UploadTypeSelectorProps = {
    value: FormUploadType
    onChange: (value: FormUploadType) => void
}

const descriptions: Record<FormUploadType, string> = {
    Avatar: "Small profile picture (400x400)",
    Image: "Other image",
    Pdf: "PDF document",
}

export const UploadTypeSelector: FC<UploadTypeSelectorProps> = ({
    value,
    onChange,
}) => {
    return <FormControl sx={{ width: 300 }}>
        <InputLabel>Upload type</InputLabel>
        <Select
            label="Upload type"
            value={value}
            onChange={(e) => { onChange(e.target.value as FormUploadType) }}
            size="small"
        >
            {Object.values(FormUploadType).map(type =>
                <MenuItem key={type} value={type} dense>
                    <ListItemText primary={type} secondary={descriptions[type]} />
                </MenuItem>
            )}
        </Select>
    </FormControl>
}