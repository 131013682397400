import { Scope, TicketType } from "@marketpartner/backend-api"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { ConfirmDeleteDialog } from "src/common/dialogs/ConfirmDeleteDialog"
import { LockableDialogProps } from "src/common/dialogs/LockableDialog"
import { createPopupHook } from "src/common/dialogs/Popup"
import { withNotification } from "src/common/notifications/with-notification"
import { useEvent } from "src/events/event-context"


export type DeleteTicketTypeDialogProps = LockableDialogProps & {
    ticketType: TicketType
}

const DeleteTicketTypeDialog: FC<DeleteTicketTypeDialogProps> = ({
    ticketType,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!

    const deleteMutation = backend.ticketTypes.useDelete(withNotification({
        errorMessage: "Error deleting ticket type",
    }))

    return <ConfirmDeleteDialog
        {...props}
        itemName={ticketType.name}
        formProps={{
            action: () => deleteMutation.mutateAsync([client.id, event.id, ticketType.id]),
            text: "",
        }}
    />
}

export const useDeleteTicketTypeDialog = createPopupHook({
    element: DeleteTicketTypeDialog,
    scope: Scope.Event,
})