import { ButtonProps } from "@aws-amplify/ui-react"
import { Scope } from "@marketpartner/backend-api"
import { Add, Delete, Edit } from "@mui/icons-material"
import { Button, DialogActions, DialogContent, List, ListItem, ListItemIcon, ListItemText, MenuItem, MenuItemProps, Typography } from "@mui/material"
import { useMutation, UseQueryResult } from "@tanstack/react-query"
import { FC, ReactNode } from "react"
import { useDialogContext } from "src/common/dialogs/LockableDialog"
import { createPopupHook } from "src/common/dialogs/Popup"
import { Spacer } from "src/common/layout/Spacer"
import { Loading } from "src/common/loading/Loading"
import { ContextMenuButton } from "src/common/menus/ContextMenuButton"
import { LoadingMenuItem } from "src/common/menus/LoadingMenuItem"
import { LockableMenu, LockableMenuProps } from "src/common/menus/LockableMenu"
import { ReorderableItem, ReorderableStack } from "src/common/reordering/ReorderableStack"
import { ReorderButtons } from "src/common/reordering/ReorderButtons"
import { useBulkReorder } from "src/common/reordering/useBulkReorder"

export type EditableDialogListAddProps = {
    onClickAdd: ButtonProps["onClick"]
}

export type EditableDialogListContextMenuProps = {
    onClickEdit?: () => void
    onClickDelete?: () => void | Promise<unknown>
    editMenuItemProps?: Partial<MenuItemProps>
    deleteMenuItemProps?: Partial<MenuItemProps>
}

export type EditableDialogListReorderProps<Item> = {
    onReorder: (items: Item[]) => Promise<unknown>
}

export type EditableDialogListProps<Item extends ReorderableItem> = {
    itemsQuery: UseQueryResult<Item[]>
    getPrimaryText: (item: Item) => ReactNode
    getSecondaryText?: (item: Item) => ReactNode
    getTeritaryText?: (item: Item) => ReactNode
    getIcon?: (item: Item) => ReactNode

    reorderProps?: EditableDialogListReorderProps<Item>
    addProps?: EditableDialogListAddProps
    contextMenuProps?: (item: Item, idx: number) => EditableDialogListContextMenuProps | undefined
}

export const EditableDialogList = <Item extends ReorderableItem>({
    itemsQuery,
    getPrimaryText,
    getSecondaryText,
    getTeritaryText,
    getIcon,
    reorderProps,
    contextMenuProps,
    addProps,
}: EditableDialogListProps<Item>) => {
    const dialog = useDialogContext()
    const itemContextMenu = useItemContextMenu()

    const reorderMutation = useMutation({
        mutationFn: reorderProps?.onReorder,
        onMutate: () => dialog.disableClose(),
        onSettled: () => dialog.enableClose(),
    })

    const reorder = useBulkReorder({
        items: itemsQuery.data ?? [],
        onReorder: reorderMutation.mutateAsync,
    })

    return <>
        <DialogContent>
            <Loading request={itemsQuery} sizing="fit-content" render={() => <List>
                {!reorder.orderedItems.length && <Typography textAlign="center" color="text.secondary">
                    No items
                </Typography>}
                <ReorderableStack
                    items={reorder.orderedItems}
                    onReorder={reorder.reorderItems}
                    renderItem={(item, idx) => <ListItem disablePadding>
                        {getIcon && <ListItemIcon>
                            {getIcon(item)}
                        </ListItemIcon>}
                        <ListItemText
                            primary={getPrimaryText(item)}
                            secondary={getSecondaryText?.(item)}
                        />
                        {getTeritaryText?.(item)}
                        {contextMenuProps?.(item, idx) && <ContextMenuButton onClick={e => itemContextMenu.open({
                            anchorEl: e.currentTarget,
                            ...contextMenuProps?.(item, idx),
                        })} />}
                    </ListItem>}
                    reorderEnabled={reorder.isReordering}
                    loading={reorder.isLoading}
                    addItem={addProps && <Button
                        children="Add"
                        onClick={addProps.onClickAdd}
                        startIcon={<Add />}
                        color="inherit"
                        sx={{ mx: "auto", px: 2 }}
                    />}
                />
            </List>} />
        </DialogContent>
        <DialogActions>
            {reorderProps && <ReorderButtons reorder={reorder} />}
            <Spacer />
            <Button
                children="Close"
                onClick={() => dialog.close()}
                color="inherit"
                disabled={dialog.closeDisabled}
            />
        </DialogActions>
    </>
}

const ItemContextMenu: FC<LockableMenuProps & EditableDialogListContextMenuProps> = ({
    onClickEdit,
    onClickDelete,
    editMenuItemProps,
    deleteMenuItemProps,
    ...props
}) => {
    return <LockableMenu closeOnClick {...props}>
        {onClickEdit && <MenuItem onClick={onClickEdit} {...editMenuItemProps}>
            <ListItemIcon><Edit color="primary" /></ListItemIcon>
            <ListItemText>Edit</ListItemText>
        </MenuItem>}
        {onClickDelete && <LoadingMenuItem onClick={onClickDelete} {...deleteMenuItemProps}>
            <ListItemIcon><Delete color="error" /></ListItemIcon>
            <ListItemText>Delete</ListItemText>
        </LoadingMenuItem>}
    </LockableMenu>
}

const useItemContextMenu = createPopupHook({
    element: ItemContextMenu,
    scope: Scope.Global,
})