import { Permission } from "@marketpartner/backend-api"
import { DeleteForever, Edit } from "@mui/icons-material"
import { Button, Paper } from "@mui/material"
import { FC } from "react"
import { useNavigate, useParams } from "react-router"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { BarAndFlex } from "src/common/layout/BarAndFlex"
import { BasicProperties, BasicProperty } from "src/common/layout/BasicProperties"
import { Loading } from "src/common/loading/Loading"
import { Page } from "src/common/layout/Page"
import { link } from "src/common/routing/routes"
import { ActiveStatus } from "src/common/statuses/ActiveStatus"
import { EventDateTimeText } from "src/common/time/DateTimeText"
import { useEvent } from "src/events/event-context"
import { ifHas } from "src/login/IfHas"
import { useDeletePollDialog } from "src/polls/DeletePollDialog"
import { useEditPollDialog } from "src/polls/EditPollDialog"
import { pollStatusDetails } from "src/polls/pollStatusDetails"
import { PollQuestions } from "src/polls/questions/PollQuestions"

export const PollPage: FC = () => {

    const client = useClient()!
    const event = useEvent()!
    const params = useParams()
    const pollId = params.pollId!
    const navigate = useNavigate()

    const editPollDialog = useEditPollDialog()
    const deletePollDialog = useDeletePollDialog()

    const pollsQuery = backend.polls.useGet([client.id, event.id, pollId])
    return <Loading
        request={pollsQuery}
        render={poll => <Page
            title={poll.name}
            actions={ifHas(Permission.EditPolls, client.id, event.id, <>
                <Button
                    startIcon={<Edit />}
                    color="primary"
                    variant="contained"
                    onClick={() => editPollDialog.open({ poll })}
                >Edit</Button>
                <Button
                    startIcon={<DeleteForever />}
                    color="error"
                    onClick={() => deletePollDialog.open({ poll, onDelete: () => navigate(link.toPolls(client.id, event.id)) })}
                >Delete</Button>
            </>)}
            minContentSize={550}
        >
            <BarAndFlex
                barContent={<Paper
                    sx={{ p: 2, mb: 2 }}
                >
                    <BasicProperties>
                        <BasicProperty name="Status" value={<ActiveStatus details={pollStatusDetails} start={poll.start} end={poll.end} />} />
                        {poll.start && <BasicProperty name="Active from" value={<EventDateTimeText dateTime={poll.start} />} />}
                        {poll.end && <BasicProperty name="Active to" value={<EventDateTimeText dateTime={poll.end} />} />}
                        <BasicProperty name="Anonymous responses" value={poll.allowAnonymousResponses} />
                    </BasicProperties>
                </Paper>}
            >
                <PollQuestions />
            </BarAndFlex>
        </Page>}
    />

}