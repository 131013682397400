import { Stack, Typography } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { TagList } from "src/common/form-inputs/TagList"
import { Bar } from "src/common/layout/Bar"
import { BasicProperty } from "src/common/layout/BasicProperties"
import { Spacer } from "src/common/layout/Spacer"
import { Loading } from "src/common/loading/Loading"
import { useEvent } from "src/events/event-context"
import { EditScanningConfigurationButton } from "src/scanning/EditScanningConfigurationButton"


export type ScanningConfigurationProps = {}

export const ScanningConfigurationDetails: FC<ScanningConfigurationProps> = () => {
    const client = useClient()!
    const event = useEvent()!
    const configurationQuery = backend.scanning.useGetConfiguration([client.id, event.id])

    return <Loading request={configurationQuery} sizing="fit-content" render={configuration => <Stack spacing={2}>
        <Bar>
            <Typography variant="h5">Configuration</Typography>
            <Spacer />
            <EditScanningConfigurationButton configuration={configuration} />
        </Bar>
        <BasicProperty
            name="Unlock code"
            value={configuration.unlockCode}
        />
        <BasicProperty
            name="Custom token field"
            value={configuration.customTokenField}
        />
        <BasicProperty
            name="Print format"
            value={configuration.printFormat ?
                <Typography sx={{ wordWrap: "break-word" }}>{configuration.printFormat}</Typography> :
                <Typography color="text.secondary">None</Typography>}
        />
        <BasicProperty
            name="Session names"
            value={<TagList
                tags={configuration.sessionNames}
                emptyPlaceholder="<none>"
            />}
        />
    </Stack>} />
}