import { AppTemplateVersion } from "@marketpartner/backend-api"
import { LoadedSelector, LoadedSelectorProps } from "@marketpartner/mp-common-mui"
import { FC } from "react"
import { backend } from "src/common/api"


export type AppVersionSelectorProps = & Partial<LoadedSelectorProps<string, AppTemplateVersion>> & {
    templateId: string | null
    version: string | null
    onChangeVersion: (version: string | null) => void
}

export const AppVersionSelector: FC<AppVersionSelectorProps> = ({
    templateId,
    version,
    onChangeVersion,
    ...props
}) => {
    const versionRequest = backend.appTemplates.useGetTemplateVersions([templateId!], {
        enabled: Boolean(templateId),
        select: data => data.sort(sortVersions)
    })

    if (!templateId) {
        return <></>
    }

    return <LoadedSelector
        {...props}
        label="Version"
        fetchRequest={versionRequest}
        selectedId={version}
        onSelectId={onChangeVersion}
        nullLabel="Select a version"
    />
}

function sortVersions(a: AppTemplateVersion, b: AppTemplateVersion): number {
    const aUpper = a.id.toUpperCase();
    const bUpper = b.id.toUpperCase();

    const aUsesVersionStructure = usesVersionStructure(aUpper);
    const bUsesVersionStructure = usesVersionStructure(bUpper);

    if (aUsesVersionStructure && bUsesVersionStructure) {
        const aVersion = parseInt(aUpper.slice(1), 10);
        const bVersion = parseInt(bUpper.slice(1), 10);
        return bVersion - aVersion; // Descending order for numeric versions
    }

    if (aUsesVersionStructure) return -1;
    if (bUsesVersionStructure) return 1;

    return aUpper.localeCompare(bUpper); // Case-insensitive alphabetical comparison
}

function usesVersionStructure(id: string): boolean {
    return /^V(\d+)$/.test(id);
}