import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { DeleteButton } from "src/common/dialogs/DeleteButton"
import { backend } from "src/common/api"
import { useEvent } from "src/events/event-context"

type DeletePollResponseButtonProps = {
    pollId: string,
    questionId: number,
    id: number
}

export const DeletePollResponseButton: FC<DeletePollResponseButtonProps> = ({
    pollId,
    questionId,
    id
}) => {
    const client = useClient()!
    const event = useEvent()!

    const deleteMutation = backend.pollResponses.useDelete()
    return <DeleteButton
        buttonText=""
        itemName="response"
        text="This will permanently delete this poll response"
        deleteAction={() => deleteMutation.mutateAsync([client.id, event.id, pollId, questionId, id])}
    />
}