import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material"
import { Box } from '@mui/system'
import { FC, ReactNode } from 'react'
import { Page } from "src/common/layout/Page"
import { RotateApiKeyContent } from 'src/sysadmin/RotateApiKeyContent'
import { RotateRedisAccessTokenContent } from 'src/sysadmin/RotateRedisToken'


export const SysadminPage: FC = () => {
    return <Page
        title="System Administration"
    >
        <Box sx={{ maxWidth: 750 }}>
            <Expandable title="Rotate API key">
                <RotateApiKeyContent />
            </Expandable>
            <Expandable title="Rotate Redis access token">
                <RotateRedisAccessTokenContent />
            </Expandable>
        </Box>
    </Page>
}

type ExpandableProps = {
    title: string
    children: ReactNode
}

const Expandable: FC<ExpandableProps> = ({ 
    title,
    children
}) => <Accordion disableGutters>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">{title}</Typography>
    </AccordionSummary>
    <AccordionDetails>
        {children}
    </AccordionDetails>
</Accordion>
