import { ExpandLess, ExpandMore } from "@mui/icons-material"
import { Box, Collapse, Fade } from "@mui/material"
import { FC, ReactNode, useState } from "react"
import { Bar } from "src/common/layout/Bar"
import { Spacer } from "src/common/layout/Spacer"
import { StickyHeadingAndContent } from "src/common/layout/StickyHeadingAndContent"


export type StickyAccordianProps = {
    headingContent: ReactNode
    defaultCollapsed?: boolean
    controls?: ReactNode
    children: ReactNode
}

export const StickyAccordian: FC<StickyAccordianProps> = ({
    headingContent,
    defaultCollapsed = false,
    controls,
    children,
}) => {
    const [collapsed, setCollapsed] = useState(defaultCollapsed)

    return <StickyHeadingAndContent
        headingContent={<Bar
            spacing={2}
            onClick={() => setCollapsed(!collapsed)}
            sx={{ cursor: "pointer" }}
        >
            <Box color="text.secondary">
                {collapsed ? <ExpandMore /> : <ExpandLess />}
            </Box>
            {headingContent}
            <Spacer />
            <Fade in={!collapsed}>
                <Box children={controls} onClick={e => e.stopPropagation()} />
            </Fade>
        </Bar>}
    >
        <Collapse in={!collapsed}>
            <Box children={children} />
        </Collapse>
    </StickyHeadingAndContent>
}