import { RegistrationFieldBooleanCondition } from "@marketpartner/backend-api";
import { Assignment } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { BooleanConditionProperties } from "src/common/boolean-conditions/boolean-condition-properties";
import { RegistrationFieldConditionForm } from "src/email/scheduling/RegistrationFieldConditionForm";


export const registrationFieldConditionProperties: BooleanConditionProperties<RegistrationFieldBooleanCondition> = {
    typeName: "Registration field",
    iconComponent: Assignment,
    getPrimaryText: condition => `Registration field ${condition.fieldId} is`,
    getSecondaryContent: condition => <Stack>
        {condition.values.map(value => <Typography key={value} children={value} />)}
    </Stack>,
    isValid: ({ fieldId, values }) => Boolean(fieldId) && values.length > 0,
    createDefault: () => ({
        type: "RegistrationField",
        fieldId: "",
        values: [],
    }),
    renderForm: (condition, onChangeProperties) => <RegistrationFieldConditionForm 
        condition={condition}
        onChangeProperties={onChangeProperties}
    />,
}