import { StructuredBreakoutTrack } from "@marketpartner/backend-api"
import { ArrowBack, ArrowForward, DeleteForever, Edit } from "@mui/icons-material"
import { Divider, ListItemIcon, ListItemText, Menu, MenuItem, MenuProps } from "@mui/material"
import { FC } from "react"
import { MoveAgendaItemButtons } from "src/content/agendas/MoveAgendaItemButtons"
import { useDeleteBreakoutTrackDialog } from "src/content/agendas/tracks/DeleteBreakoutDialog"
import { useEditBreakoutTrackDialog } from "src/content/agendas/tracks/EditBreakoutTrackDialog"

export type BreakoutTrackContextMenuProps = MenuProps & {
    track: StructuredBreakoutTrack,
    onClose: () => void
}

export const BreakoutTrackContextMenu: FC<BreakoutTrackContextMenuProps> = ({
    track,
    ...props
}) => {
    const editTrackDialog = useEditBreakoutTrackDialog()
    const deleteTrackDialog = useDeleteBreakoutTrackDialog()

    const canDelete = track.items.length === 0

    return <Menu {...props} onClick={props.onClose}>
        <MenuItem disabled>
            <ListItemText sx={{ textAlign: "center" }}>Track</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => editTrackDialog.open({ track })}>
            <ListItemIcon><Edit color="primary" /></ListItemIcon>
            <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem disabled={!canDelete} onClick={() => deleteTrackDialog.open({ track })}>
            <ListItemIcon><DeleteForever color="error" /></ListItemIcon>
            <ListItemText>Delete{canDelete ? "" : " (must be empty)"}</ListItemText>
        </MenuItem>
        <Divider />
        <MoveAgendaItemButtons
            item={track}
            upIcon={<ArrowBack />}
            upText="Left"
            downIcon={<ArrowForward />}
            downText="Right"
            onMove={props.onClose}
        />
    </Menu>
}