import { PollQuestion } from "@marketpartner/backend-api"
import { Grid, TextField, TextFieldProps } from "@mui/material"
import { FC, useState } from "react"
import { DialogForm, ExtendableDialogFormProps } from "src/common/dialogs/DialogForm"

export type PollQuestionFormData = {
    name: string
}

type PollQuestionFormProps = ExtendableDialogFormProps<PollQuestionFormData> & {
    pollQuestion?: PollQuestion
}
export const PollQuestionForm: FC<PollQuestionFormProps> = ({
    pollQuestion,
    ...props
}) => {

    const [name, setName] = useState(pollQuestion?.name ?? "")

    return <DialogForm
        formData={{
            name,
        }}
        {...props}
    >
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
                <NameField 
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
            </Grid>
        </Grid>
    </DialogForm>
}

const NameField = ({ ...props }: TextFieldProps) => <TextField
    label="Name"
    fullWidth
    required
    {...props}
/>