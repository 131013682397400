import { Error, Warning } from "@mui/icons-material"
import { Stack, SxProps, Typography } from "@mui/material"
import { FC } from "react"
import { Bar } from "src/common/layout/Bar"
import { FormElementIssue } from "src/forms/elements/checks/form-element-checks"


export type FormElementChecksProps = {
    issues: FormElementIssue[]
    sx?: SxProps
}

export const FormElementChecks: FC<FormElementChecksProps> = ({
    issues,
    sx,
}) => {

    return <Stack
        spacing={0.5}
        sx={{
            alignItems: "start",
            ...sx
        }}
    >
        {issues.map((issue, idx) => <Issue key={idx} issue={issue} />)}
    </Stack>
}

type IssueProps = {
    issue: FormElementIssue
}

const Issue: FC<IssueProps> = ({
    issue
}) => {
    const {
        level = "warning",
        renderIssueContent,
    } = issue

    return <Bar
        spacing={1}
        color={theme => theme.palette[level].main}
    >
        <IssueIcon level={level} />
        <Typography variant="body2">
            {renderIssueContent()}
        </Typography>
    </Bar>
}

type IssueIconProps = {
    level: NonNullable<FormElementIssue["level"]>
}

const IssueIcon: FC<IssueIconProps> = ({
    level
}) => {
    switch (level) {
        case "warning": return <Warning />
        case "error": return <Error />
    }
}