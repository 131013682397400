import { Location, Permission } from "@marketpartner/backend-api"
import { SxProps } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { useEvent } from "src/events/event-context"
import { useLoginContext } from "src/login/LoginContext"
import { EditableAvailability } from "src/networking/meetings/availability/EditableAvailability"


export type LocationAvailabiltyProps = {
    location: Location
    sx?: SxProps
}

export const LocationAvailability: FC<LocationAvailabiltyProps> = ({
    location,
    sx,
}) => {
    const client = useClient()!
    const event = useEvent()!
    const login = useLoginContext()

    const updateLocationMutation = backend.locations.useUpdateLocation()

    return <EditableAvailability
        canEdit={login.hasPermission(Permission.EditEventDetails, client.id, event.id)}
        initialAvailability={location.meetingAvailability}
        onChangeAvailability={availability => updateLocationMutation.mutateAsync([client.id, event.id, location.id, {
            localMeetingAvailability: availability.toLocalIntervalList(),
        }])}
        sx={sx}
    />
}