import { ContentEntity, Scope } from "@marketpartner/backend-api";
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { ContentEntityForm } from "src/content/entities/ContentEntityForm";
import { useEvent } from "src/events/event-context";


export type EditContentEntityDialogProps = LockableDialogProps & {
    entity: ContentEntity
}

const EditContentEntityDialog: FC<EditContentEntityDialogProps> = ({
    entity,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const updateEntityMutation = backend.contentEntities.useUpdateEntity(withNotification({
        errorMessage: "Failed to update content",
    }))

    return <LockableDialog
        maxWidth="sm"
        fullWidth
        {...props}
    >
        <DialogTitle>
            Update {entity.fields.title ?? "content"}
        </DialogTitle>
        <ContentEntityForm
            actionName="Save"
            onSubmit={async formData => updateEntityMutation.mutateAsync([client.id, event.id, entity.id, {
                ...formData,
            }])}
            entity={entity}
        />
    </LockableDialog>
}

export const useEditContentEntityDialog = createPopupHook({
    element: EditContentEntityDialog,
    scope: Scope.Event,
})