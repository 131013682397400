import { ActivityType, ComputedColumnType } from "@marketpartner/backend-api";
import { DataObject } from "@mui/icons-material";
import { SvgIconProps } from "@mui/material";
import { FC } from "react";
import { ActivityTypeIcon } from "src/activities/ActivityTypeIcon";


export type ComputedColumnTypeIconProps = Partial<SvgIconProps> & {
    type: ComputedColumnType
}

export const ComputedColumnTypeIcon: FC<ComputedColumnTypeIconProps> = ({
    type,
    ...props
}) => {
    switch (type) {
        case "ActionCount": return <ActivityTypeIcon type={ActivityType.action} {...props} />
        case "TimedActivityDuration": return <ActivityTypeIcon type={ActivityType.timed} {...props} />
        case "Boolean": return <DataObject {...props} />
    }
}