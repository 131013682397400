import { Permission } from "@marketpartner/backend-api";
import { useMemo } from "react";
import { TabDefinition } from "src/common/routing/RoutedTabs";
import { useLoginContext } from "src/login/LoginContext";


export const useRestrictedRoutedTabs = (
    permission: Permission,
    clientId: string | undefined,
    eventId: string | undefined,
    tabDefinitions: TabDefinition[]
): TabDefinition[] => {
    const login = useLoginContext()
    const hasPermission = login.hasPermission(permission, clientId, eventId)

    return useMemo(() => {
        return hasPermission ? tabDefinitions : []
    }, [hasPermission, tabDefinitions])
}