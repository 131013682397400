import { RegistrationCategory, Scope } from "@marketpartner/backend-api";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { useEvent } from "src/events/event-context";
import { RegistrationCategoryForm } from "src/registrations/configuration/categories/RegistrationCategoryForm";


export type EditRegistrationCategoryDialogProps = LockableDialogProps & {
    category: RegistrationCategory
}

const EditRegistrationCategoryDialog: FC<EditRegistrationCategoryDialogProps> = ({
    category,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const updateMutation = backend.registrationCategories.useUpdate(withNotification({
        successMessage: "Updated category",
        errorMessage: "Error updating category",
    }))

    return <LockableDialog maxWidth="sm" {...props}>
        <RegistrationCategoryForm
            actionName="Save"
            category={category}
            onSubmit={category => updateMutation.mutateAsync([client.id, event.id, category.id, category])}
        />
    </LockableDialog>
}

export const useEditRegistrationCategoryDialog = createPopupHook({
    element: EditRegistrationCategoryDialog,
    scope: Scope.Event,
})