import { RegistrationElementMetadata, RegistrationFieldVisibility } from "@marketpartner/backend-api"
import { Public, Visibility, VisibilityOff } from "@mui/icons-material"
import { SvgIconProps } from "@mui/material"
import { FC } from "react"
import { Editable } from "src/common/icons/Editable"
import { Bar, BarProps } from "src/common/layout/Bar"


export type RegistrationElementEditabilityIconProps = Partial<SvgIconProps> & {
    userEditable: boolean | undefined
}

export const RegistrationElementEditabilityIcon: FC<RegistrationElementEditabilityIconProps> = ({
    userEditable,
    ...props
}) => <Editable
        editable={userEditable ?? false}
        {...props}
    />



export type RegistrationFieldVisibilityIconProps = Partial<SvgIconProps> & {
    elementVisibility: RegistrationFieldVisibility | undefined
}

export const RegistrationFieldVisibilityIcon = ({
    elementVisibility,
    ...props
}: RegistrationFieldVisibilityIconProps) => {
    switch (elementVisibility) {
        case RegistrationFieldVisibility.Public:
            return <Public />
        case RegistrationFieldVisibility.VisibleToUser:
            return <Visibility {...props} />
        default:
            return <VisibilityOff color="disabled" {...props} />
    }
}


export type RegistrationElementMetadataIconsProps = Partial<BarProps> & {
    metadata: RegistrationElementMetadata | undefined
    iconProps?: Partial<SvgIconProps>
}

export const RegistrationElementMetadataIcons: FC<RegistrationElementMetadataIconsProps> = ({
    metadata,
    iconProps,
    ...props
}) => {
    return <Bar spacing={1} {...props}>
        <RegistrationFieldVisibilityIcon elementVisibility={metadata?.visibility} {...iconProps} />
        <RegistrationElementEditabilityIcon userEditable={metadata?.userEditable} {...iconProps} />
    </Bar>
}