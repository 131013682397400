import { EventSegment, Permission } from "@marketpartner/backend-api"
import { LoadedSelector } from "@marketpartner/mp-common-mui"
import { Divider, MenuItem } from "@mui/material"
import { FC, useState } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { LockableDialog } from "src/common/dialogs/LockableDialog"
import { useEvent } from "src/events/event-context"
import { SegmentsDialogContent } from "src/events/segments/SegmentsDialogContent"
import { useLoginContext } from "src/login/LoginContext"

export type SegmentSelectorProps = {
    segmentId: number | null,
    onSelectSegmentId: (segmentId: number | null) => void,
    onSelectLoadedSegment: (segment: EventSegment | null) => void,
}

export const SegmentSelector: FC<SegmentSelectorProps> = ({
    segmentId,
    onSelectSegmentId,
    onSelectLoadedSegment,
}) => {
    const login = useLoginContext()
    const client = useClient()!
    const event = useEvent()!
    const segmentsQuery = backend.eventSegments.useGetAll([client.id, event.id])
    const [dialogIsOpen, setDialogIsOpen] = useState(false)

    return <>
        <LoadedSelector
            fetchRequest={segmentsQuery}
            name="segment"
            label="Event segment"
            selectedId={segmentId}
            onSelectId={onSelectSegmentId}
            onSelectLoadedItem={onSelectLoadedSegment}
            nullLabel="All time"
            variant="standard"
            noRefresh
            extraOptions={login.hasPermission(Permission.EditEventDetails, client.id, event.id) && [
                <Divider key="divider" />,
                <MenuItem
                    key="manage-segments"
                    children="Manage segments"
                    onClick={e => {
                        e.preventDefault()
                        setDialogIsOpen(true)
                    }}
                />
            ]}
            sx={{ minWidth: "250px" }}
        />
        <LockableDialog open={dialogIsOpen} onClose={() => setDialogIsOpen(false)}>
            <SegmentsDialogContent />
        </LockableDialog>
    </>
}