import { normalizeColumn } from "src/registrations/import/pre-process/normalize-column"
import { RegistrationParseException } from "src/registrations/import/pre-process/registration-parse-exception"


const coreColumnList = [
    "id",
    "email",
    "type",
    "categoryId",
    "primaryRegistrationId",
] as const

export type CoreColumn = typeof coreColumnList[number]

const normalizedColumnAliases: Record<CoreColumn, string[]> = {
    id: ["id"],
    email: ["email", "emailaddress"],
    categoryId: ["category", "categoryid"],
    primaryRegistrationId: ["primaryregistration", "primaryregistrationid"],
    type: ["type"],
}

export type CoreColumnMapping = Partial<Record<CoreColumn, string>>

export class DuplicateCoreColumnException extends RegistrationParseException {
    constructor(
        coreColumn: CoreColumn,
        duplicates: string[]
    ) {
        super(`CSV file contains more than one column that looks like ${coreColumn} (${duplicates.join(", ")}). There must only be one`)
    }
}

export const identifyCoreColumns = (
    csvColumns: string[]
): CoreColumnMapping => {
    return Object.fromEntries(
        coreColumnList.map(coreColumn => [
            coreColumn,
            findSingleMatch(csvColumns, coreColumn)
        ])
    )
}

const findSingleMatch = (
    csvColumns: string[],
    coreColumn: CoreColumn,
): string | undefined => {
    const aliases = normalizedColumnAliases[coreColumn]
    const matches = csvColumns.filter(it => aliases.includes(normalizeColumn(it)))
    if (matches.length > 1) {
        throw new DuplicateCoreColumnException(coreColumn, matches)
    }
    return matches[0]
}
