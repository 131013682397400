import { PollQuestion } from "@marketpartner/backend-api"
import { Star, StarBorder } from "@mui/icons-material"
import { Box, IconButton } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { withNotification } from "src/common/notifications/with-notification"
import { useEvent } from "src/events/event-context"
type HighlightPollQuestionButtonProps = {
    pollQuestion: PollQuestion
}
export const HighlightPollQuestionButton: FC<HighlightPollQuestionButtonProps> = ({
    pollQuestion
}) => {

    const client = useClient()!
    const event = useEvent()!

    const pollQuery = backend.polls.useGet([client.id, event.id, pollQuestion.pollId])
    const isHighlighted = pollQuery.data?.activeQuestionId === pollQuestion.id

    const updateMutation = backend.polls.useUpdate(withNotification({
        successMessage: 'Active question selected',
        errorMessage: 'Request failed',
    }))

    return <Box>
        <IconButton
            color="primary"
            onClick={() => updateMutation.mutate([client.id, event.id, pollQuestion.pollId, {
                activeQuestionId: pollQuestion.id
            }])}
            disabled={updateMutation.isPending}
            >
                { isHighlighted ? <Star /> : <StarBorder />}
            </IconButton>
    </Box>
}