import { FormElementConfig } from "@marketpartner/backend-api";
import { CopyAll, Create } from "@mui/icons-material";
import { ListItemIcon, ListItemText, MenuItem, MenuProps } from "@mui/material";
import Divider from "@mui/material/Divider";
import { FC } from "react";
import { createLocalPopupHook } from "src/common/dialogs/LocalPopup";
import { LockableMenu, useMenuContext } from "src/common/menus/LockableMenu";
import { useCopyElementsDialog } from "src/forms/elements/copying/CopyElementsDialog";
import { FormElementSourceType } from "src/forms/elements/copying/useFormElementSources";
import { useCreateFormElementDialog } from "src/forms/elements/CreateFormElementDialog";
import { useEditableFormElementsContext } from "src/forms/elements/EditableFormElementsContext";


export type AddFormElementMenuProps = MenuProps & {
    existingElements: FormElementConfig[]
    defaultSourceType: FormElementSourceType | "DisableCopy"
    onClose: () => void
    commonElements?: FormElementConfig[]
}

const AddFormElementMenu: FC<AddFormElementMenuProps> = ({
    commonElements = [],
    defaultSourceType,
    existingElements,
    ...props
}) => {
    const createDialog = useCreateFormElementDialog()
    const copyDialog = useCopyElementsDialog()

    return <LockableMenu {...props} onClick={props.onClose} closeOnClick>
        <MenuItem onClick={() => createDialog.open({})}>
            <ListItemIcon><Create color="primary" /></ListItemIcon>
            <ListItemText>Create element</ListItemText>
        </MenuItem>
        {defaultSourceType !== "DisableCopy" && <MenuItem onClick={() => copyDialog.open({ defaultSourceType, existingElements })}>
            <ListItemIcon><CopyAll /></ListItemIcon>
            <ListItemText>Copy from form</ListItemText>
        </MenuItem>}
        <CommonElements commonElements={commonElements} />
    </LockableMenu>
}

type CommonElementsProps = Pick<AddFormElementMenuProps, "commonElements">

const CommonElements: FC<CommonElementsProps> = ({
    commonElements = [],
}) => {
    const menuContext = useMenuContext()
    const { onCreateElements, defaultMetadata } = useEditableFormElementsContext()

    if (commonElements.length === 0) {
        return <></>
    }

    return <>
        <Divider />
        <MenuItem disabled>
            <ListItemText>Common elements</ListItemText>
        </MenuItem>
        {commonElements.map((it, idx) => <MenuItem
            key={idx}
            onClick={e => {
                menuContext.closeAfter(onCreateElements([{
                    ...it,
                    metadata: {
                        ...defaultMetadata,
                        ...it.metadata,
                    }
                }]))
                e.stopPropagation()
            }}
        >
            <ListItemText>{"name" in it ? it.name : ""}</ListItemText>
        </MenuItem>)}
    </>
}

export const useAddFormElementMenu = createLocalPopupHook({
    element: AddFormElementMenu,
})