import { PrimaryRegistration, RegistrationCategory, Scope } from "@marketpartner/backend-api"
import { getErrorString } from "@marketpartner/mp-common"
import { FC, useState } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog"
import { createPopupHook } from "src/common/dialogs/Popup"
import { withNotification } from "src/common/notifications/with-notification"
import { useEvent } from "src/events/event-context"
import { PrimaryRegistrationDialogTitle } from "src/registrations/actions/PrimaryRegistrationDialogTitle"
import { PrimaryRegistrationForms } from "src/registrations/actions/PrimaryRegistrationForms"

export type EditPrimaryRegistrationDialogProps = LockableDialogProps & EditPrimaryRegistrationDialogContentProps

const EditPrimaryRegistrationDialog: FC<EditPrimaryRegistrationDialogProps> = ({
    registration,
    ...props
}) => {
    return <LockableDialog fullWidth maxWidth="md" {...props}>
        <EditPrimaryRegistrationDialogContent registration={registration} />
    </LockableDialog>
}

type EditPrimaryRegistrationDialogContentProps = {
    registration: PrimaryRegistration
}

const EditPrimaryRegistrationDialogContent: FC<EditPrimaryRegistrationDialogContentProps> = ({
    registration,
}) => {
    const client = useClient()!
    const event = useEvent()!
    const [category, setCategory] = useState<RegistrationCategory | null>(null)
    const editMutation = backend.registrations.useUpdateRegistration(withNotification({
        successMessage: "Updated registration",
        errorMessage: e => getErrorString(e),
    }))

    return <>
        <PrimaryRegistrationDialogTitle
            initialCategoryId={registration.categoryId}
            onSelectCategory={setCategory}
            children="Edit registration"
        />
        {category && <PrimaryRegistrationForms
            onSubmit={formData => editMutation.mutateAsync([client.id, event.id, {
                id: registration.id,
                ...formData,
            }])}
            registration={registration}
            category={category}
            actionName="Save"
        />}
    </>
}

export const useEditPrimaryRegistrationDialog = createPopupHook({
    scope: Scope.Event,
    element: EditPrimaryRegistrationDialog,
})