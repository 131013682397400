import { filterFormFields, isFormField } from "@marketpartner/backend-api";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { LoadedAutocomplete, LoadedAutocompleteProps } from "src/common/form-inputs/LoadedAutocomplete";
import { useEvent } from "src/events/event-context";


export type RegistrationFieldSelectorProps = Omit<LoadedAutocompleteProps<string, RegistrationFieldSelectorOption>, "selectedId" | "onSelectId" | "fetchRequest"> & {
    selectedFieldId: string | null
    onSelectFieldId: (fieldId: string | null) => void
}

type RegistrationFieldSelectorOption = {
    id: string
    categoryNames: string[]
}

export const RegistrationFieldSelector: FC<RegistrationFieldSelectorProps> = ({
    selectedFieldId,
    onSelectFieldId,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const fieldsQuery = backend.registrationCategories.useGetAll<RegistrationFieldSelectorOption[]>([client.id, event.id], {
        select: categories => categories
            .flatMap(category => filterFormFields(category.elements))
            .map(field => ({
                id: field.name,
                categoryNames: categories
                    .filter(category => category.elements.some(element => isFormField(element) && element.name === field.name))
                    .map(category => category.name),
            })),
    })

    return <LoadedAutocomplete
        fetchRequest={fieldsQuery}
        selectedId={selectedFieldId}
        onSelectId={onSelectFieldId}
        getOptionLabel={field => field.id}
        getSecondaryText={field => field.categoryNames.join(", ")}
        {...props}
    />
}
