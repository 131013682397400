import { commonRegistrationFields, isFormField, Permission } from "@marketpartner/backend-api"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { withNotification } from "src/common/notifications/with-notification"
import { useEvent } from "src/events/event-context"
import { emailElementCheck } from "src/forms/elements/checks/email-element-check"
import { nameElementCheck } from "src/forms/elements/checks/name-element-check"
import { FormElementSourceType } from "src/forms/elements/copying/useFormElementSources"
import { EditableFormElements } from "src/forms/elements/EditableFormElements"
import { useLoginContext } from "src/login/LoginContext"
import { RegistrationElementMetadataForm } from "src/registrations/configuration/categories/reg-form/RegistrationElementMetadataForm"
import { RegistrationElementMetadataIcons } from "src/registrations/configuration/categories/reg-form/RegistrationElementMetadataIcons"

export const GuestConfiguration: FC = () => {
    const client = useClient()!
    const event = useEvent()!
    const login = useLoginContext()

    const updateMutation = backend.events.useUpdateEvent(withNotification({
        successMessage: "Updated guest form",
        errorMessage: "Error updating guest form",
    }))

    return <EditableFormElements
        canEdit={login.hasPermission(Permission.EditEventDetails, client.id, event.id)}
        elements={event.guestElements}
        onChangeElements={elementList => updateMutation.mutateAsync([client.id, event.id, {
            guestElements: elementList,
        }])}
        renderExtraControls={config => isFormField(config) ? <RegistrationElementMetadataIcons 
            metadata={config.metadata}
            sx={{ mx: 1 }}
        /> : undefined}
        renderMetadataControls={(config, onChange) => <RegistrationElementMetadataForm
            config={config}
            onChange={onChange}
        />}
        defaultSourceType={FormElementSourceType.RegistrationCategories}
        commonElements={commonRegistrationFields}
        elementChecks={[
            emailElementCheck({
                required: false,
            }),
            nameElementCheck,
        ]}
        sizing="fit-content"
    />
}
