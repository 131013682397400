import { AuthTokens, fetchAuthSession } from "@aws-amplify/auth";
import { atom, useAtom } from "jotai";

const accessTokenKey = "access-token"

// Note: Can't use atomWithStorage as it serializes using JSON, which
// wouldn't work for existing values for logged-in users.
const _accessTokenAtom = atom(localStorage.getItem(accessTokenKey))
const accessTokenAtom = atom(
    (get) => get(_accessTokenAtom),
    (_get, set, newValue: string | null) => {
        if (newValue === null) {
            localStorage.removeItem(accessTokenKey)
        } else {
            localStorage.setItem(accessTokenKey, newValue)
        }
        set(_accessTokenAtom, newValue)
    }
)

export const StoredCredentials = {

    useAccessToken: () => {
        const [accessToken, setAccessToken] = useAtom(accessTokenAtom)
        return {
            value: accessToken || null,
            setValue: (accessToken: string) => setAccessToken(accessToken),
            clear: () => setAccessToken(null)
        }
    },

    getAccessToken: () => {
        return localStorage.getItem(accessTokenKey)
    },

    getCognitoTokens: async (): Promise<AuthTokens | undefined> => {
        try {
            const session = await fetchAuthSession()
            return session.tokens
        } catch (e) {
        }
    }
}