import { Scope } from "@marketpartner/backend-api";
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { ScheduledEmailForm } from "src/email/scheduling/ScheduledEmailForm";
import { useEvent } from "src/events/event-context";


export type NewScheduledEmailDialogProps = LockableDialogProps

const NewScheduledEmailDialog: FC<NewScheduledEmailDialogProps> = ({
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!

    const createEmailMutation = backend.scheduledEmails.useCreate(withNotification({
        errorMessage: "Error scheduling email"
    }))

    return <LockableDialog
        {...props}
        maxWidth="sm"
        fullWidth
    >
        <DialogTitle>New scheduled email</DialogTitle>
        <ScheduledEmailForm
            actionName="Schedule"
            onSubmit={formData => createEmailMutation.mutateAsync([client.id, event.id, formData])}
        />
    </LockableDialog>
}

export const useNewScheduledEmailDialog = createPopupHook({
    element: NewScheduledEmailDialog,
    scope: Scope.Event
})