import { useSessionStorage } from "@marketpartner/mp-common-react"
import { Typography } from "@mui/material"
import { LockableDialog } from "src/common/dialogs/LockableDialog"
import { MfaSetupDialogContent } from "src/login/mfa/MfaSetupDialogContent"
import { Permission } from "@marketpartner/backend-api"
import { useLoginContext } from "src/login/LoginContext"
import { FC } from "react"


export const AdminMfaCheck: FC = () => {
    const login = useLoginContext()
    const [showMfaSetup, setShowMfaSetup] = useSessionStorage(
        "showMfaSetup",
        (login.hasPermission(Permission.AdministerSystem) && !login.mfaEnabled).toString()
    )

    return <LockableDialog
        open={showMfaSetup !== "false"}
        onClose={() => setShowMfaSetup("false")}
        disableClickaway
    >
        <MfaSetupDialogContent
            preamble={<>
                <Typography>
                    You do not have multi-factor authentication enabled.
                </Typography>
                <Typography>
                    Do you want to set up an authenticator app now (e.g. Google Authenticator)?
                </Typography>
                <Typography variant="body2" sx={{ mt: 2 }}>
                    Note: This will disable any existing MFA devices
                </Typography>
            </>}
            closeText="Skip for now"
        />
    </LockableDialog>
}