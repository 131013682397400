import { FeedType, Permission } from "@marketpartner/backend-api"
import Add from "@mui/icons-material/Add"
import { DialogTitle, Stack, Typography } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { DialogButton } from "src/common/dialogs/DialogButton"
import { withNotification } from "src/common/notifications/with-notification"
import { center } from "src/common/styles"
import { useEvent } from "src/events/event-context"
import { EventFeedForm } from "src/feeds/EventFeedForm"
import { ifHas } from "src/login/IfHas"


export const NoEventFeed: FC = () => {
    return <Stack spacing={2} sx={{ ...center, alignItems: "center", width: "100%" }}>
        <Typography variant="h5" sx={{ textAlign: "center" }}>
            This event does not have a feed configured yet
        </Typography>
        <CreateEventFeedButton />
    </Stack>
}

export const CreateEventFeedButton: FC = () => {
    const client = useClient()!
    const event = useEvent()!
    const createFeedMutation = backend.feeds.useCreateFeed(withNotification({
        successMessage: "Event feed created",
        errorMessage: "Error creating event feed",
    }))

    return ifHas(Permission.EditFeeds, client.id, event.id, <DialogButton
        icon={<Add />}
        text="Create feed now"
        color="primary"
        size="large"
        sx={{ px: 4 }}
    >
        <DialogTitle>Create event feed</DialogTitle>
        <EventFeedForm
            actionName="Create"
            onSubmit={feedDetails => createFeedMutation.mutateAsync([client.id, event.id, {
                type: FeedType.Event,
                ...feedDetails,
            }])}
        />
    </DialogButton>)
}