import { FormElementConfig} from "@marketpartner/backend-api"
import { FC } from "react"
import { ConfirmDeleteDialog } from "src/common/dialogs/ConfirmDeleteDialog"
import { createLocalPopupHook } from "src/common/dialogs/LocalPopup"
import { LockableDialogProps } from "src/common/dialogs/LockableDialog"

export type DeleteFormElementDialogProps = LockableDialogProps & {
    element: FormElementConfig
    onDelete: () => Promise<unknown>
}

const DeleteFormElementDialog: FC<DeleteFormElementDialogProps> = ({
    element,
    onDelete,
    ...props
}) => {
    return <ConfirmDeleteDialog
        itemName={"name" in element ? element.name : `${element.type} element`}
        formProps={{
            action: onDelete,
            text: <></>
        }}
        {...props}
    />
}

export const useDeleteFormElementDialog = createLocalPopupHook({
    element: DeleteFormElementDialog,
})