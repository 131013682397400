import { Poll, Scope } from "@marketpartner/backend-api"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { ConfirmDeleteDialog } from "src/common/dialogs/ConfirmDeleteDialog"
import { LockableDialogProps } from "src/common/dialogs/LockableDialog"
import { createPopupHook } from "src/common/dialogs/Popup"
import { useEvent } from "src/events/event-context"
type DeletePollDialogProps = LockableDialogProps & {
    poll: Poll
    onDelete: () => void
}
export const DeletePollDialog: FC<DeletePollDialogProps> = ({
    poll,
    onDelete,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const deleteMutation = backend.polls.useDelete({
        onSuccess: onDelete
    })
    return <ConfirmDeleteDialog
        itemName={poll.name}
        formProps={{
            action: () => deleteMutation.mutateAsync([client.id, event.id, poll.id]),
            text: <>This will delete all associated responses</>,
            confirmText: poll.name,
        }}
        {...props}
    />
}

export const useDeletePollDialog = createPopupHook({
    element: DeletePollDialog,
    scope: Scope.Event,
})