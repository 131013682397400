import { Scope } from "@marketpartner/backend-api"
import { WritableAtom, atom, useSetAtom } from "jotai"
import { ComponentType, useMemo } from "react"
import { useRegisterPopup } from "src/common/dialogs/PopupRegister"

export type PopupProps = {
    open: boolean
    onClose: () => void
}

export type UsePopup<Props extends PopupProps> = {
    open: (params: Omit<Props, keyof PopupProps>) => void
}

export type CreatePopupOptions<Props extends PopupProps> = {
    scope: Scope
    element: ComponentType<Props>
}

export type PopupStateAtom<Props extends PopupProps> = WritableAtom<Props | undefined, [Props | undefined], void>

/**
 * Create a shared "popup" that can be shared in multiple places across the app. The popup can be anything
 * that has `open` and `onClose` props (for example, a dialog or menu). The popup can be opened 
 * programatically in response to events (e.g. onClick) using the hook that this function returns.
 * 
 * **Important**: As the popups are shared across the app, the component lives near the top of the react tree
 * far from the component that opens it. This means local react contexts will not be available inside the popup.
 * If this is required, consider using LocalPopup instead.
 * 
 * **Important**: Props are provided to the popup component **at the time of opening**, and are stored until the popup
 * is closed. This means there is no way for updated prop values to be passed to the popup as long as it
 * is open. If changes to backend data need to be reflected in the popup while it is open, the data
 * should not be passed by props as it will become stale; instead, the appropriate ids should be passed through
 * props, and the data retrieved *inside* the popup using react-query.
 * 
 * As a general rule of thumb:
 * 
 * - If displaying a menu, passing data down is fine, the menu is likely very transient.
 * - If displaying a create/edit dialog, passing data is usually fine as it is only used for pre-populating the form.
 * - If the popup is a dialog for *viewing* data, pass ids and fetch data inside the dialog using react-query to
 *   ensure it doesn't become stale.
 */
export const createPopupHook = <Props extends PopupProps>(
    options: CreatePopupOptions<Props>
): () => UsePopup<Props> => {
    const stateAtom = atom<Props | undefined>(undefined)

    return () => {
        const setState = useSetAtom(stateAtom)
        useRegisterPopup<Props>(options, stateAtom)

        return useMemo(() => ({
            open: (customProps) => setState({
                ...customProps,
                open: true,
                onClose: () => setState(prevState => prevState && ({
                    ...prevState,
                    open: false,
                })),
            } as Props),
        }), [setState])
    }
}
