import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { DialogTitle } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { FC, ReactNode } from 'react'
import { ConfirmDialogForm } from "src/common/dialogs/ConfirmDialogForm"
import { DialogButton, DialogButtonProps } from "src/common/dialogs/DialogButton"
import { withNotification } from 'src/common/notifications/with-notification'

export type DeleteButtonProps = Omit<DialogButtonProps, "text" | "icon" | "children"> & {
    itemName: string
    deleteAction: () => Promise<unknown>
    text: ReactNode
    confirmText?: string
    buttonText?: string
    buttonIcon?: ReactNode
}

export const DeleteButton: FC<DeleteButtonProps> = ({
    itemName,
    deleteAction,
    text,
    confirmText,
    buttonIcon = <DeleteForeverIcon />,
    buttonText,
    ...buttonProps
}) => {
    const notifiedDeleteMutation = useMutation(withNotification({
        mutationFn: deleteAction,
        successMessage: `Deleted ${itemName}`,
        errorMessage: `Error deleting ${itemName}`,
    }))

    return <DialogButton
        text={buttonText}
        icon={buttonIcon}
        color="error"
        {...buttonProps}
    >
        <DialogTitle>Confirm deletion of {itemName}</DialogTitle>
        <ConfirmDialogForm
            action={() => notifiedDeleteMutation.mutateAsync(undefined)}
            text={text}
            confirmText={confirmText}
            submitText="Delete"
            submitColor="error"
        />
    </DialogButton>
}