import { AppTemplateDeploymentPermission } from "@marketpartner/backend-api"
import { Add } from "@mui/icons-material"
import { Button, Stack, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { FC, useState } from "react"
import { AppTemplateDeploymentPermissionSelect } from "src/app-templates/AppTemplateDeploymentPermissionSelect"
import { ClientDomainSummary, useClientDomainSummaries } from "src/app-templates/domains/useClientDomainSummaries"
import { ClientSelector } from "src/clients/ClientSelector"
import { backend } from "src/common/api"
import { DeleteButton } from "src/common/dialogs/DeleteButton"
import { Bar } from "src/common/layout/Bar"
import { withNotification } from "src/common/notifications/with-notification"
import { HoverLink } from "src/common/routing/HoverLink"
import { link } from "src/common/routing/routes"

export type ClientDomainsGridProps = {
    domainName: string
    sx?: SxProps
}

export const ClientDomainsGrid: FC<ClientDomainsGridProps> = ({
    domainName,
    sx,
}) => {
    const clientDomains = useClientDomainSummaries(domainName)

    return <Stack spacing={3} sx={sx}>
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableCell>Client id</TableCell>
                    <TableCell>Deployment permission</TableCell>
                    <TableCell>Deployments</TableCell>
                    <TableCell>Actions</TableCell>
                </TableHead>
                <TableBody>
                    {clientDomains.clientDomains.map(it => <ClientDomainRow key={it.clientId} clientDomain={it} />)}
                    {!clientDomains.isLoading && clientDomains.clientDomains.length === 0 && <TableRow>
                        <TableCell colSpan={200} align="center">No clients</TableCell>
                    </TableRow>}
                    <NewClientDomainRow domainName={domainName} />
                </TableBody>
            </Table>
        </TableContainer>
    </Stack>
}

type ClientDomainRowProps = {
    clientDomain: ClientDomainSummary
}

const ClientDomainRow: FC<ClientDomainRowProps> = ({
    clientDomain
}) => {
    const deleteMutation = backend.domainNames.useDeleteClientDomain(withNotification({
        errorMessage: "Error deleting client domain"
    }))

    return <TableRow>
        <TableCell>{clientDomain.clientId}</TableCell>
        <TableCell>
            {clientDomain.deploymentPermission === AppTemplateDeploymentPermission.AdminOnly ? "Admin only" : "Client"}
        </TableCell>
        <TableCell>
            <Bar spacing={1}>
                <Typography>
                    {clientDomain.deploymentCount}
                </Typography>
                <HoverLink to={link.toAllAppsList(`${clientDomain.clientId} ${clientDomain.domainName}`)} text="View" />
            </Bar>
        </TableCell>
        <TableCell>
            <DeleteButton
                deleteAction={() => deleteMutation.mutateAsync([clientDomain.clientId, clientDomain.domainName])}
                itemName="client domain access"
                text={`Remove access to ${clientDomain.domainName} from ${clientDomain.clientId}?`}
            />
        </TableCell>
    </TableRow>
}

type NewClientDomainFormProps = {
    domainName: string
}

const NewClientDomainRow: FC<NewClientDomainFormProps> = ({
    domainName
}) => {
    const [clientId, setClientId] = useState<string | null>(null)
    const [deploymentPermission, setDeploymentPermission] = useState(AppTemplateDeploymentPermission.AdminOnly)
    const addClientDomainMutation = backend.domainNames.useCreateClientDomain(withNotification({
        successMessage: "Added client domain",
        errorMessage: "Error adding client domain",
    }))

    const isValid = Boolean(clientId)

    const handleAdd = async () => {
        addClientDomainMutation.mutateAsync([clientId!, { deploymentPermission, domainName }], {
            onSuccess: () => setClientId(null)
        })
    }

    return <TableRow>
        <TableCell>
            <ClientSelector
                clientId={clientId}
                onSelectClientId={setClientId}
                required={false}
                size="small"
                sx={{
                    minWidth: 200
                }}
                disabled={addClientDomainMutation.isPending}
            />
        </TableCell>
        <TableCell colSpan={2}>
            <AppTemplateDeploymentPermissionSelect
                value={deploymentPermission}
                onChange={setDeploymentPermission}
                radioGroupProps={{
                    row: true
                }}
                radioProps={{
                    size: "small"
                }}
                disabled={addClientDomainMutation.isPending}
            />
        </TableCell>
        <TableCell>
            <Button
                disabled={!isValid || addClientDomainMutation.isPending}
                onClick={handleAdd}
                startIcon={<Add />}
                variant="contained"
                color="primary"
            >
                Add
            </Button>
        </TableCell>
    </TableRow>
}