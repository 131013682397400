import { RegistrationType, TestClass } from "@marketpartner/backend-api"
import { mapValues } from "lodash"
import { CoreColumn, CoreColumnMapping, identifyCoreColumns } from "src/registrations/import/pre-process/identify-core-columns"
import { RegistrationParseException } from "src/registrations/import/pre-process/registration-parse-exception"

const csvLineOffset = 2 // Zero'th registration is the second csv line

export type ParsedRegistration = Partial<Record<CoreColumn, string>> & {
    lineNumber: number
    type?: RegistrationType,
    fields: Record<string, string>
}

export class TestParsedRegistration extends TestClass implements ParsedRegistration {
    lineNumber = 1
    fields = {}
    id?: string
    email?: string
    type?: RegistrationType
    categoryId?: string
    primaryRegistrationId?: string
}

export class InvalidRegistrationTypeException extends RegistrationParseException {
    constructor(lineNumber: number, typeString: string) {
        super(`Line number ${lineNumber} has an invalid registration type ${typeString}. Value values are: ${Object.values(RegistrationType).join(", ")}`)
    }
}

export const parseRegistrations = (
    columns: string[],
    rows: Record<string, string>[],
): ParsedRegistration[] => {
    const coreColumnMapping = identifyCoreColumns(columns)
    return rows.map((row, idx) =>
        parseRegistration(row, coreColumnMapping, idx + csvLineOffset)
    )
}

const parseRegistration = (
    row: Record<string, string>,
    coreColumns: CoreColumnMapping,
    lineNumber: number,
): ParsedRegistration => {
    const trimmedRow = mapValues(row, trim)

    const parsedStringValues = {
        ...mapValues(coreColumns, csvColumn => csvColumn && trimmedRow[csvColumn]),
        lineNumber,
        fields: trimmedRow as Record<string, string>
    }

    return {
        ...parsedStringValues,
        type: parseType(parsedStringValues.type, lineNumber)
    }
}

const trim = (csvValue: string | undefined): string | undefined => {
    const trimmed = csvValue?.trim()
    return trimmed || undefined
}

const parseType = (
    typeString: string | undefined,
    lineNumber: number,
): RegistrationType | undefined => {
    if (!typeString) {
        return undefined
    }
    const matchedType = Object.values(RegistrationType)
        .find(type => typeString.toLowerCase() === type.toLowerCase())

    if (!matchedType) {
        throw new InvalidRegistrationTypeException(lineNumber, typeString)
    }

    return matchedType
}