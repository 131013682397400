import { IntervalSet } from "@marketpartner/backend-api"
import { Check, Clear } from "@mui/icons-material"
import { Button, SxProps } from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { Interval } from "luxon"
import { FC, useState } from "react"
import { Bar } from "src/common/layout/Bar"
import { NetworkingTimeslot } from "src/networking/meetings/periods/NetworkingTimeslot"
import { NetworkingTimeslots } from "src/networking/meetings/periods/NetworkingTimeslots"


export type EditableDefaultAvailabilityProps = {
    initialAvailability: IntervalSet
    onChangeAvailability: (availability: IntervalSet) => Promise<unknown>
    canEdit: boolean
    sx?: SxProps
}

export const EditableAvailability: FC<EditableDefaultAvailabilityProps> = ({
    initialAvailability,
    onChangeAvailability,
    canEdit,
    sx,
}) => {
    const [availability, setAvailability] = useState(initialAvailability)

    const updateMutation = useMutation({
        mutationFn: onChangeAvailability,
    })

    const updateAvailability = (interval: Interval, available: boolean) => {
        const newAvailability = available ?
            availability.add(interval) :
            availability.subtract(interval)
        setAvailability(newAvailability)
        updateMutation.mutate(newAvailability)
    }

    return <NetworkingTimeslots
        periodControls={period => canEdit && <Bar spacing={2}>
            <Button
                children="Entire period"
                color="success"
                startIcon={<Check />}
                onClick={() => updateAvailability(Interval.fromDateTimes(period.start, period.end), true)}
            />
            <Button
                children="Clear"
                startIcon={<Clear />}
                color="inherit"
                onClick={() => updateAvailability(Interval.fromDateTimes(period.start, period.end), false)}
            />
        </Bar>}
        renderTimeslot={timeslot => {
            const isAvailable = availability.engulfs(timeslot)
            return <NetworkingTimeslot
                timeslot={timeslot}
                timeslotStyle={isAvailable ? "green" : "disabled"}
                onClick={canEdit ? () => updateAvailability(timeslot, !isAvailable) : undefined}
            />
        }}
        sx={sx}
    />
}
