import { RelatedContentId, RelatedContentType } from "@marketpartner/backend-api";
import { useRelatedContent } from "@marketpartner/mp-common-react";
import { Box, Fade } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { Bar } from "src/common/layout/Bar";
import { TabDefinition } from "src/common/routing/RoutedTabs";
import { EditableRelatedContent } from "src/content/relationships/EditableRelatedContent";
import { useEvent } from "src/events/event-context";

export const relatedContentTab = (
    relatedToType: RelatedContentType,
    relatedToId: RelatedContentId,
): TabDefinition => ({
    label: <RelationshipTabLabel
        relatedToType={relatedToType}
        relatedToId={relatedToId}
    />,
    path: `linked-content`,
    element: <EditableRelatedContent
        key="linked-content"
        relatedToType={relatedToType}
        relatedToId={relatedToId}
    />,
    scrollable: true,
})

type RelationshipTabLabelProps = {
    relatedToType: RelatedContentType
    relatedToId: RelatedContentId
}

const RelationshipTabLabel: FC<RelationshipTabLabelProps> = ({
    relatedToType,
    relatedToId,
}) => {
    const client = useClient()!
    const event = useEvent()!
    const relatedContentCountQuery = useRelatedContent(backend, client.id, event.id, relatedToType, relatedToId, {
        select: relatedContent => relatedContent.length
    })

    return <Bar spacing={0.5}>
        <Box>Linked content</Box>
        <Fade in={relatedContentCountQuery.data !== undefined}>
            <Box>({relatedContentCountQuery.data})</Box>
        </Fade>
    </Bar>
}