import { AdminUser, StoredAttachedRole } from '@marketpartner/backend-api';
import RemoveIcon from '@mui/icons-material/Remove';
import { FC } from 'react';
import { roleText } from "src/access/users/RoleText";
import { backend } from 'src/common/api';
import { DeleteButton } from "src/common/dialogs/DeleteButton";

export type RemoveRoleButtonProps = {
    user: AdminUser
    attachedRole: StoredAttachedRole
}

export const RemoveRoleButton: FC<RemoveRoleButtonProps> = ({
    user,
    attachedRole
}) => {
    const removeMutation = backend.adminUsers.useRemoveUserRole()

    return <DeleteButton
        buttonIcon={<RemoveIcon fontSize="small" />}
        deleteAction={() => removeMutation.mutateAsync([user.id, attachedRole.id])}
        itemName="role"
        text={<>Remove <b>{roleText(attachedRole)}</b> from <b>{user.name}</b></>}
    />
}