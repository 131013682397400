import { FC, ReactNode } from "react"
import { BarAndFlex, BarAndFlexProps } from "src/common/layout/BarAndFlex"


export type SearchableGridLayoutProps = Partial<BarAndFlexProps> & {
    searchControls: ReactNode
    grid: ReactNode
}

export const SearchableGridLayout: FC<SearchableGridLayoutProps> = ({
    searchControls,
    grid,
    ...props
}) => <BarAndFlex
        sizing="fit-content"
        spacing={2}
        flexStyle={{
            display: "flex",
            flexDirection: "column",
        }}
        barContent={searchControls}
        children={grid}
        {...props}
    />