import { ImportRegistrationsResult, RegistrationType } from "@marketpartner/backend-api";
import { filterType } from "@marketpartner/mp-common";
import { PreparedPrimaryRegistration, PreparedRegistration } from "src/registrations/import/preview/prepare-for-import";


export const updatePrimaryRegistrationIds = (
    registrations: PreparedRegistration[],
    insertPrimariesResult: ImportRegistrationsResult[]
): PreparedRegistration[] => {
    const updated: PreparedRegistration[] = []
    const primaries = filterType(registrations, RegistrationType.Primary as const)
    const guests = filterType(registrations, RegistrationType.Guest as const)
    const idMapping = buildIdMapping(primaries, insertPrimariesResult)

    primaries.forEach(primary => updated.push(primary))

    guests.forEach(guest => updated.push({
        ...guest,
        primaryRegistrationId: idMapping[guest.primaryRegistrationId] ?? guest.primaryRegistrationId
    }))

    return updated
}

// Examine matched csv rows & insertion results to build a mapping from csv ids, to db ids
const buildIdMapping = (
    primaryRegistrations: PreparedPrimaryRegistration[],
    insertPrimariesResult: ImportRegistrationsResult[]
): Record<string, string> => {
    const mapping: Record<string, string> = {}

    // Map csv ids to db ids for rows that were matched with a db record
    primaryRegistrations.forEach(({ parsedId, existingRegistration }) => {
        if (parsedId && existingRegistration) {
            mapping[parsedId] = existingRegistration.id
        }
    })

    // Map csv ids to db ids for rows that were inserted
    insertPrimariesResult.forEach(result => {
        if (result.type === "success" && result.parsedId) {
            mapping[result.parsedId] = result.id
        }
    })

    return mapping
}