import { SignatureConfig } from "@marketpartner/backend-api"
import { FormElementConfigComponent } from "src/forms/elements/types/formElementConfigTypes"
import { StandardFields } from "src/forms/elements/types/StandardFields"

export const SignatureConfigComponent: FormElementConfigComponent<SignatureConfig> = ({
    config,
    updateConfigProperty
}) => {

    return <>
        <StandardFields config={config} updateConfigProperty={updateConfigProperty}/>
    </>
}