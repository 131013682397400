import { emailElementCheck } from "src/forms/elements/checks/email-element-check";
import { FormElementCheck } from "src/forms/elements/checks/form-element-checks";
import { nameElementCheck } from "src/forms/elements/checks/name-element-check";


export const registrationFormChecks: FormElementCheck[] = [
    emailElementCheck({ 
        required: true,
    }),
    nameElementCheck,
]