import { ActivityType, ComputedColumn, ComputedColumnType, RegistrationFieldVisibility } from "@marketpartner/backend-api"
import { OmitFromAll } from "@marketpartner/mp-common"
import { BasicSelect } from "@marketpartner/mp-common-mui"
import { Grid, TextField } from "@mui/material"
import { FC, useState } from "react"
import { DialogForm, ExtendableDialogFormProps } from "src/common/dialogs/DialogForm"
import { BooleanComputedColumnForm } from "src/registrations/computed-columns/boolean-conditions/BooleanComputedColumnForm"
import { isValidComputedColumn } from "src/registrations/computed-columns/column-validity"
import { EditableActivityLookups } from "src/registrations/computed-columns/EditableActivityLookups"


export type ComputedColumnFormProps = ExtendableDialogFormProps<ComputedColumn> & {
    existingColumn?: ComputedColumn
}

const columnTypeOptions: Record<ComputedColumnType, string> = {
    Boolean: "Boolean (Yes/No)",
    ActionCount: "Action count",
    TimedActivityDuration: "Timed activity duration",
}

const defaultColumns: {
    [Type in ComputedColumnType]: OmitFromAll<ComputedColumn, "id" | "visibility"> & { type: Type }
} = {
    Boolean: {
        type: "Boolean",
        expressions: {
            type: "Group",
            name: "",
            operator: "OR",
            expressions: [],
        },
    },
    ActionCount: {
        type: "ActionCount",
        lookups: [{
            id: "",
            localStartIso: null,
            localEndIso: null,
        }],
    },
    TimedActivityDuration: {
        type: "TimedActivityDuration",
        lookups: [{
            id: "",
            localStartIso: null,
            localEndIso: null,
        }],
    }
}

export const ComputedColumnForm: FC<ComputedColumnFormProps> = ({
    existingColumn,
    ...props
}) => {
    const [column, setColumn] = useState<ComputedColumn>(existingColumn ?? {
        id: "",
        visibility: RegistrationFieldVisibility.AdminOnly,
        ...defaultColumns["Boolean"]
    })

    return <DialogForm
        formData={column}
        isValid={isValidComputedColumn(column)}
        {...props}
    >
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    label="Name"
                    value={column.id}
                    onChange={e => setColumn(config => ({ ...config, id: e.target.value }))}
                    fullWidth
                    required
                />
            </Grid>
            {!existingColumn && <Grid item xs={12}>
                <BasicSelect
                    label="Column type"
                    value={column.type}
                    onChange={type => setColumn({
                        ...column,
                        ...defaultColumns[type],
                    })}
                    options={columnTypeOptions}
                />
            </Grid>}
            <Grid item xs={12}>
                {column.type === "Boolean" && <BooleanComputedColumnForm
                    column={column}
                    onChangeColumn={column => setColumn(column)}
                />}
                {(column.type === "ActionCount" || column.type === "TimedActivityDuration") && <EditableActivityLookups
                    activityType={column.type === "ActionCount" ? ActivityType.action : ActivityType.timed}
                    lookups={column.lookups}
                    onChangeLookups={lookups => setColumn(config => ({ ...config, lookups }))}
                />}
            </Grid>
        </Grid>
    </DialogForm>
}