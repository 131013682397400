import { FlatEpCompany } from "@marketpartner/backend-api"
import { Avatar, Stack, Typography } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { link } from "src/common/routing/routes"
import { EventPartnerCategorizedGrid } from "src/content/event-partner/EventPartnerCategorizedGrid"
import { EventPartnerContent } from "src/content/event-partner/EventPartnerContent"
import { useEvent } from "src/events/event-context"

export const EventPartnerSponsors: FC = () => {
    const client = useClient()!
    const event = useEvent()!
    const sponsorsQuery = backend.content.useGetEventPartnerSponsors([client.id, event.id])

    return <EventPartnerContent
        name="sponsors"
        link={link.toEpSponsors(event.eventPartnerId!, event.epEventToken!)}
        request={sponsorsQuery}
        render={sponsors => <EventPartnerCategorizedGrid
            data={sponsors}
            renderItem={sponsor => <Sponsor sponsor={sponsor} />}
        />}
    />
}

type SponsorProps = {
    sponsor: FlatEpCompany
}

const Sponsor: FC<SponsorProps> = ({
    sponsor
}) => {
    return <Stack sx={{ width: 200, alignItems: "center" }}>
        <Avatar
            src={sponsor.logo_cropped}
            alt={sponsor.name}
            sx={{ mb: 1, width: 75, height: 75, border: 1, borderColor: "grey.300" }}
        />
        <Typography sx={{ textAlign: "center" }}>{sponsor.name}</Typography>
    </Stack>
}