import { LocalDateTime, Poll } from "@marketpartner/backend-api"
import { Checkbox } from "@mui/material"
import { FormControlLabel, Grid, TextField, TextFieldProps } from "@mui/material"
import { FC, useState } from "react"
import { DialogForm, ExtendableDialogFormProps } from "src/common/dialogs/DialogForm"
import { IdField } from "src/common/form-inputs/IdField"
import { ActiveStatusEditor } from "src/common/statuses/ActiveStatusEditor"
import { useEvent } from "src/events/event-context"
import { pollStatusDetails } from "src/polls/pollStatusDetails"

export type PollFormData = {
    name: string
    id: string
    allowAnonymousResponses: boolean
    localStart: LocalDateTime | null
    localEnd: LocalDateTime | null
}

type PollFormProps = ExtendableDialogFormProps<PollFormData> & {
    poll?: Poll
}
export const PollForm: FC<PollFormProps> = ({
    poll,
    ...props
}) => {

    const [name, setName] = useState(poll?.name ?? "")
    const [id, setId] = useState(poll?.id ?? "")
    const [allowAnonymousResponses, setAllowAnonymousResponses] = useState(poll?.allowAnonymousResponses ?? false)
    const [start, setStart] = useState(poll?.start ?? null)
    const [end, setEnd] = useState(poll?.end ?? null)
    const event = useEvent()!

    return <DialogForm
        formData={{
            name,
            id,
            allowAnonymousResponses,
            activeQuestionId: null,//TODO: remove once backend api updated in serverless backend
            localStart: start && LocalDateTime.fromZonedDateTime(start),
            localEnd: end && LocalDateTime.fromZonedDateTime(end)
        }}
        {...props}

    >
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
                <NameField 
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
            </Grid>
            {!poll && <Grid item xs={12}>
                <IdField 
                    nameValue={name}
                    idValue={id}
                    onChangeId={setId}
                    maxLength={32}
                />
            </Grid>}
            <Grid item xs={6}>
                <FormControlLabel 
                    label="Allow anonymous responses?"
                    control={<Checkbox 
                        color="primary"
                        checked={allowAnonymousResponses}
                        onChange={e => setAllowAnonymousResponses(e.target.checked)}
                    />}
                />
            </Grid>
            <Grid item xs={12}>
                <ActiveStatusEditor
                    details={pollStatusDetails}
                    start={start}
                    setStart={setStart}
                    end={end}
                    setEnd={setEnd}
                    timezone={event.timezone}
                />
            </Grid>
        </Grid>

    </DialogForm>
}

const NameField = ({ ...props }: TextFieldProps) => <TextField
    label="Name"
    fullWidth
    required
    {...props}
/>