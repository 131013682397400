import { parse } from 'papaparse'

export type ParsedCsv = {
    columns: string[]
    rows: Record<string, string>[]
}

export const CsvParser = {

    parse: (
        csvString: string,
        ignoreColumns: string[] = []
    ): ParsedCsv => {
        const { data, errors, meta } = parse(csvString, {
            header: true,
            skipEmptyLines: true,
        })
        if (errors.length) {
            errors.slice(0, 10).forEach(console.error)
            if (errors.length > 10) {
                console.error(`Not showing ${errors.length - 10} more errors`)
            }
            throw new Error("Error parsing file. Please make sure it is a valid .csv file.")
        }
        data.forEach(row => {
            ignoreColumns.forEach(ignoreCol => {
                delete (row as any)[ignoreCol]
            })
        })

        return {
            columns: meta.fields?.filter(it => !ignoreColumns.includes(it)) ?? [],
            rows: data as any,
        }
    }
}
