import { AdminMeetingRequest, Location, Registration } from "@marketpartner/backend-api";
import { joinToOne } from "@marketpartner/mp-common";
import { useCombinedQueries } from "@marketpartner/mp-common-react";
import { UseQueryResult } from "@tanstack/react-query";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { useEvent } from "src/events/event-context";


export type JoinedMeetingRequest = AdminMeetingRequest & {
    requester: Registration
    respondant: Registration
    location: Location | null
}

export const useJoinedMeetingRequestsQuery = (): UseQueryResult<JoinedMeetingRequest[]> => {
    const client = useClient()!
    const event = useEvent()!

    const meetingRequestsQuery = backend.meetings.useGetMeetingRequests([client.id, event.id])
    const registrationsQuery = backend.registrations.useGetAll([client.id, event.id])
    const locationsQuery = backend.locations.useGetLocations([client.id, event.id])

    return useCombinedQueries({
        queries: [meetingRequestsQuery, registrationsQuery, locationsQuery] as const,
        combine: (meetingRequests, registrations, locations) => joinToOne(
            joinToOne(
                joinToOne(meetingRequests,
                    registrations, "requesterId", "id", "requester", "exclude",
                ),
                registrations, "respondantId", "id", "respondant", "exclude",
            ),
            locations, "locationId", "id", "location", "null",
        )
    })
}