import { Permission } from '@marketpartner/backend-api'
import { ClearableTextField } from '@marketpartner/mp-common-mui'
import { Settings } from '@mui/icons-material'
import { Collapse, DialogTitle, Grid } from '@mui/material'
import { FC, useState } from 'react'
import { useClient } from 'src/clients/client-context'
import { backend } from 'src/common/api'
import { DialogButton, DialogButtonProps } from "src/common/dialogs/DialogButton"
import { DialogForm } from 'src/common/dialogs/DialogForm'
import { Bar } from 'src/common/layout/Bar'
import { withNotification } from 'src/common/notifications/with-notification'
import { EventPartnerLink } from 'src/content/event-partner/instances/EventPartnerLink'
import { EventPartnerSelect } from 'src/content/event-partner/instances/EventPartnerSelect'
import { useEvent } from 'src/events/event-context'
import { ifHas } from 'src/login/IfHas'

export type EditEventPartnerEventButtonProps = Partial<DialogButtonProps>

export const EditEventPartnerEventButton = ({
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!

    return ifHas(Permission.AdministerSystem, client.id, event.id, <DialogButton
        text="Event Partner"
        icon={<Settings />}
        color="primary"
        dialogProps={{
            maxWidth: "sm",
            fullWidth: true,
        }}
        {...props}
    >
        <DialogTitle>Configure Event Partner</DialogTitle>
        <EventPartnerEventForm />
    </DialogButton>)
}

const EventPartnerEventForm: FC = () => {
    const client = useClient()!
    const event = useEvent()!
    const [eventPartnerId, setEventPartnerId] = useState(event.eventPartnerId)
    const [eventToken, setEventToken] = useState(event.epEventToken)

    const updateEventMutation = backend.events.useUpdateEvent(withNotification({
        successMessage: `Updated Event Partner configuration`,
        errorMessage: "Error updating Event Partner configuration",
    }))

    return <DialogForm
        formData={{
            eventPartnerId,
            epEventToken: eventToken,
        }}
        onSubmit={formData => updateEventMutation.mutateAsync([client.id, event.id, formData])}
        actionName="Save"
    >
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Bar spacing={2}>
                    <EventPartnerSelect selectedId={eventPartnerId} onSelectId={setEventPartnerId} fullWidth />
                    <EventPartnerLink id={eventPartnerId} />
                </Bar>
            </Grid>
            <Grid item xs={12}>
                <Collapse in={Boolean(eventPartnerId)}>
                    <ClearableTextField
                        label="Event token"
                        value={eventToken ?? ""}
                        onChange={e => setEventToken(e.target.value.trim() || null)}
                        onClear={() => setEventToken(null)}
                        required={Boolean(eventPartnerId)}
                        fullWidth
                    />
                </Collapse>
            </Grid>
        </Grid>
    </DialogForm>
}
