import { Box, Stack } from "@mui/material"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { Loading } from "src/common/loading/Loading"
import { useEvent } from "src/events/event-context"
import { ScanningConfigurationDetails } from "src/scanning/ScanningConfigurationDetails"
import { ScanningUrl } from "src/scanning/ScanningUrl"


export const ScanningDetails = () => {
    const client = useClient()!
    const event = useEvent()!
    const tokenQuery = backend.scanning.useGetToken([client.id, event.id])

    return <Box sx={{ p: 2 }}>
        <Loading
            request={tokenQuery}
            sizing="fit-content"
            render={tokenDetails => <Stack spacing={2}>
                <ScanningUrl tokenDetails={tokenDetails} />
                <ScanningConfigurationDetails />
            </Stack>}
            renderErrorCode={code => code === 404 && <ScanningUrl />}
        />
    </Box>
}
