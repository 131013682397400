import { Edit } from "@mui/icons-material";
import { Button, Paper, Typography } from "@mui/material";
import { FC } from "react";
import { useParams } from "react-router";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { Page } from "src/common/layout/Page";
import { Loading } from "src/common/loading/Loading";
import { BasicTabs } from "src/common/routing/BasicTabs";
import { fullSize } from "src/common/styles";
import { useEditLocationDialog } from "src/content/locations/EditLocationDialog";
import { LocationAvailability } from "src/content/locations/LocationAvailability";
import { useEvent } from "src/events/event-context";


export const LocationPage: FC = () => {
    const client = useClient()!
    const event = useEvent()!
    const { locationId } = useParams()

    const locationQuery = backend.locations.useGetLocations([client.id, event.id], {
        select: locations => locations.find(location => location.id === locationId)
    })
    const editLocationDialog = useEditLocationDialog()

    return <Loading request={locationQuery} render={location => !location ?
        <Typography>Location not found</Typography> :
        <Page
            title={location.name}
            subtitle={location.capacity ? `Capacity: ${location.capacity}` : `Unlimited capacity`}
            actions={<Button
                children="Edit"
                startIcon={<Edit />}
                variant="contained"
                onClick={() => editLocationDialog.open({ location })}
            />}
        >
            <Paper sx={fullSize}>
                <BasicTabs tabs={[
                    //{ label: "Calendar", render: () => <Typography>Calendar</Typography> },
                    { label: "Meeting availability", render: () => <LocationAvailability location={location} sx={{ m: 2 }} /> },
                ]} />
            </Paper>
        </Page>
    } />
}