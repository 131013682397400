import { Edit, LocalActivity } from "@mui/icons-material"
import { Button } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { BasicProperties, BasicProperty } from "src/common/layout/BasicProperties"
import { Spacer } from "src/common/layout/Spacer"
import { useEvent } from "src/events/event-context"
import { useEditEventPaymentDetailsDialog } from "src/payment/EditEventPaymentDetailsDialog"
import { useTicketTypesDialog } from "src/payment/ticket-types/TicketTypesDialog"


export type EventPaymentDetailsProps = {}

export const EventPaymentDetails: FC<EventPaymentDetailsProps> = () => {
    const client = useClient()!
    const event = useEvent()!

    const stripeInstanceQuery = backend.stripeInstanceApi.useGetStripeInstances([client.id], {
        select: instances => instances.find(it => it.id === event.stripeInstanceId)
    })

    const editDialog = useEditEventPaymentDetailsDialog()
    const ticketTypesDialog = useTicketTypesDialog()

    return <BasicProperties sx={{ p: 2 }}>
        <BasicProperty name="Currency" value={event.currencyCode} />
        <BasicProperty name="Stripe Instance" value={stripeInstanceQuery.data?.name ?? "None"} />
        <Spacer />
        <Button
            children="Edit"
            startIcon={<Edit />}
            variant="contained"
            onClick={() => editDialog.open({})}
        />
        <Button
            children="Ticket types"
            startIcon={<LocalActivity />}
            onClick={() => ticketTypesDialog.open({})}
        />
    </BasicProperties>
}