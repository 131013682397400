import { Timezone } from "@marketpartner/backend-api"
import { ClearableTextField } from "@marketpartner/mp-common-mui"
import { TextFieldProps } from "@mui/material"
import { DatePicker, DatePickerProps, DateTimePicker, DateTimePickerProps, TimePicker, TimePickerProps } from "@mui/x-date-pickers"
import { DateTime } from "luxon"
import { ComponentProps, ComponentType, FC, ReactNode, useState } from "react"


export const LuxonDateTimePicker = enhancePickerComponent<DateTimePickerProps<DateTime>>(DateTimePicker)
export type LuxonDateTimePickerProps = ComponentProps<typeof LuxonDateTimePicker>

export const LuxonDatePicker = enhancePickerComponent<DatePickerProps<DateTime>>(DatePicker)
export type LuxonDatePickerProps = ComponentProps<typeof LuxonDatePicker>

export const LuxonTimePicker = enhancePickerComponent<TimePickerProps<DateTime>>(TimePicker)
export type LuxonTimePickerProps = ComponentProps<typeof LuxonTimePicker>


export type EnhancedPickerComponentProps = {
    timezone: Timezone
    required?: boolean
    fullWidth?: boolean
    hideTimezoneLabel?: boolean
}

type RequiredPickerComponentProps = {
    onChange?: (time: DateTime | null, context: any) => void
    slots?: {
        textField?: ComponentType<TextFieldProps>
    }
    slotProps?: {
        textField?: any
    }
    label?: ReactNode
}

function enhancePickerComponent<Props extends RequiredPickerComponentProps>(
    Component: ComponentType<Props>
): FC<EnhancedPickerComponentProps & Props> {

    return ({
        onChange,
        required,
        fullWidth,
        timezone,
        hideTimezoneLabel = false,
        ...props
    }) => {
        const [error, setError] = useState<string | null>(null)

        return <Component
            onChange={onChange}
            onError={setError}
            timezone={timezone}
            {...props as any}
            slots={{
                ...props.slots,
                textField: ClearableTextField
            }}
            slotProps={{
                ...props.slotProps,
                textField: {
                    required,
                    fullWidth,
                    helperText: error,
                    label: hideTimezoneLabel ? props.label : `${props.label} (${timezone})`,
                    ...props.slotProps?.textField,
                    onClearValue: () => onChange?.(null, undefined!),
                }
            }}
        />
    }
}

