import { Box, Chip, SxProps } from "@mui/material"
import { FC, ReactNode } from "react"

export type TagListProps = {
    tags: string[]
    onDelete?: (tag: string) => void
    emptyPlaceholder?: ReactNode
    sx?: SxProps
}

export const TagList: FC<TagListProps> = ({
    tags,
    onDelete,
    emptyPlaceholder,
    sx,
}) => {
    if (!tags.length) {
        return <Box sx={sx} children={emptyPlaceholder} />
    }

    return <Box sx={{
        display: "flex",
        flexWrap: "wrap",
        ...sx,
    }}>
        {tags.map(tag => <Box key={tag} m={0.5}>
            <Chip label={tag} onDelete={onDelete && (() => onDelete(tag))} />
        </Box>)}
    </Box>
}