import { ContentEntityId, Registration, RegistrationCategoryType, RelatedContentCategory, RelatedContentId, RelatedContentType, Scope } from "@marketpartner/backend-api";
import { DialogTitle, Tab, Tabs } from "@mui/material";
import { FC, useState } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { DialogForm } from "src/common/dialogs/DialogForm";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { BarAndFlex } from "src/common/layout/BarAndFlex";
import { withNotification } from "src/common/notifications/with-notification";
import { SearchableSessionsGrid } from "src/content/agendas/sessions/SearchableSessionsGrid";
import { SearchableContentEntitiesGrid } from "src/content/entities/SearchableContentEntitiesGrid";
import { SearchableSpeakersGrid } from "src/content/speakers/SearchableSpeakersGrid";
import { useEvent } from "src/events/event-context";


export type LinkContentDialogProps = LockableDialogProps & LinkContentDialogContentProps

const LinkContentDialog: FC<LinkContentDialogProps> = ({
    relatedToType,
    relatedToId,
    category,
    ...props
}) => {
    return <LockableDialog
        maxWidth="md"
        fullWidth
        allowFullScreen
        {...props}
    >
        <DialogTitle>Link content</DialogTitle>
        <LinkContentDialogContent relatedToType={relatedToType} relatedToId={relatedToId} category={category} />
    </LockableDialog>
}

export type LinkContentDialogContentProps = {
    relatedToType: RelatedContentType
    relatedToId: RelatedContentId
    category?: RelatedContentCategory
}

const LinkContentDialogContent: FC<LinkContentDialogContentProps> = ({
    relatedToType,
    relatedToId,
    category,
}) => {
    const client = useClient()!
    const event = useEvent()!

    const createRelationshipMutation = backend.contentRelationships.useCreateRelationship(withNotification({
        errorMessage: "Error linking content",
    }))

    const speakersQuery = backend.registrations.useGetAll<Registration[]>([client.id, event.id, {
        categoryType: RegistrationCategoryType.Speaker
    }])

    const [type, setType] = useState<RelatedContentType>(RelatedContentType.Session)
    const [contentId, setContentId] = useState<RelatedContentId | null>(null)

    const onChangeType = (newType: RelatedContentType) => {
        setType(newType)
        setContentId(null)
    }

    return <DialogForm
        onSubmit={() => createRelationshipMutation.mutateAsync([client.id, event.id, {
            self: {
                type: relatedToType,
                contentId: relatedToId,
            },
            other: {
                type,
                contentId: contentId!,
                categoryId: category?.id,
            }
        }])}
        actionName="Link"
        formData={{}}
        isValid={contentId !== null}
    >
        <BarAndFlex
            sizing="fit-content"
            spacing={1}
            barContent={<Tabs
                value={type}
                onChange={(_, newType) => onChangeType(newType)}
                indicatorColor="primary"
                textColor="primary"
            >
                <Tab label="Sessions" value={RelatedContentType.Session} />
                <Tab label="Speakers" value={RelatedContentType.Speaker} />
                <Tab label="Other content" value={RelatedContentType.Entity} />
            </Tabs>}
        >
            {type === RelatedContentType.Session && <SearchableSessionsGrid
                rowSelectionModel={contentId ? [contentId] : []}
                onRowSelectionModelChange={newSelection => setContentId((newSelection[0] ?? null) as RelatedContentId | null)}
                disableMultipleRowSelection
            />}
            {type === RelatedContentType.Speaker && <SearchableSpeakersGrid
                queryOrRows={speakersQuery}
                selectedRegistrations={speakersQuery.data?.filter(it => it.id === contentId)}
                onChangeSelectedRegistrations={speakers => setContentId(speakers[0]?.id ?? null)}
                disableMultipleRowSelection
                checkboxSelection={false}
                disableRowSelectionOnClick={false}
            />}
            {type === RelatedContentType.Entity && <SearchableContentEntitiesGrid
                rowSelectionModel={contentId ? [contentId] : []}
                onRowSelectionModelChange={newSelection => setContentId((newSelection[0] ?? null) as ContentEntityId | null)}
                disableMultipleRowSelection
            />}
        </BarAndFlex>
    </DialogForm>
}

export const useLinkContentDialog = createPopupHook({
    element: LinkContentDialog,
    scope: Scope.Event,
})