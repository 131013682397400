import { findFormField, Registration, RegistrationCategory, RegistrationType } from "@marketpartner/backend-api"
import { Stack, SxProps, Typography } from "@mui/material"
import Link from "@mui/material/Link"
import { FC } from "react"
import { Link as RouterLink } from "react-router-dom"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { BasicProperties, BasicProperty } from "src/common/layout/BasicProperties"
import { Loading } from "src/common/loading/Loading"
import { link } from "src/common/routing/routes"
import { EventDateTimeText } from "src/common/time/DateTimeText"
import { useEvent } from "src/events/event-context"
import { FormDataTable } from "src/forms/elements/FormDataTable"
import { RegistrationElementMetadataIcons } from "src/registrations/configuration/categories/reg-form/RegistrationElementMetadataIcons"


export type RegistrationDetailsProps = {
    registration: Registration
    sx?: SxProps
}

export const RegistrationDetails: FC<RegistrationDetailsProps> = ({
    registration,
    sx
}) => {
    const client = useClient()!
    const event = useEvent()!
    const categoryQuery = backend.registrationCategories.useGetAll([client.id, event.id], {
        select: categories => categories.find(it =>
            registration.type === RegistrationType.Primary &&
            it.id === registration.categoryId
        ) ?? null,
    })

    return <Loading
        request={categoryQuery}
        sx={sx}
        render={category => <Stack>
            <BasicProperties sx={{ p: 2 }}>
                <BasicProperty name="ID" value={registration.id} />
                <BasicProperty name="Category" value={category?.name ?? "Guest"} />
                <BasicProperty name="Status" value={registration.approvalState} />
                <BasicProperty name="Registered at" value={<EventDateTimeText dateTime={registration.registeredAt} />} />
                {registration.type === RegistrationType.Guest && <BasicProperty name="Guest of" value={
                    <Link
                        component={RouterLink}
                        to={link.toRegistration(client.id, event.id, registration.primaryRegistrationId)}
                        children={registration.primaryRegistrationId}
                    />
                } />}
                <BasicProperty name="Access token" value={registration.accessToken} />
            </BasicProperties>
            <Stack>
                <Typography variant="h6" ml={2}>
                    Registration form data
                </Typography>
                <FormDataTable
                    data={registration.fields}
                    elements={category?.elements ?? event.guestElements}
                    extraLeftColumns={[
                        {
                            headerName: "Visibility",
                            width: 70,
                            renderCell: name => <RegFieldVisibilityIcon name={name} category={category ?? undefined} />,
                        },
                    ]}
                />
            </Stack>
        </Stack>}
    />
}

type RegFieldVisibilityIconProps = {
    name: string
    category?: RegistrationCategory
}

const RegFieldVisibilityIcon: FC<RegFieldVisibilityIconProps> = ({
    name,
    category,
}) => {
    const event = useEvent()!
    const elements = category?.elements ?? event.guestElements
    const config = findFormField(elements, name)

    return <RegistrationElementMetadataIcons metadata={config?.metadata} />
}