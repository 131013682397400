import { EventSegment } from "@marketpartner/backend-api";
import { toUtcDateTime } from "@marketpartner/mp-common";
import { Grid, TextField, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { FC, useState } from "react";
import { DialogForm, ExtendableDialogFormProps } from "src/common/dialogs/DialogForm";
import { LuxonDateTimePicker } from "src/common/time/LuxonDateTimePickers";
import { useEvent } from "src/events/event-context";

export type SegmentFormData = {
    name: string,
    startTime: DateTime | null,
    endTime: DateTime | null,
}

export type SegmentFormProps = ExtendableDialogFormProps<SegmentFormData> & {
    segment?: EventSegment
}

export const SegmentForm: FC<SegmentFormProps> = ({
    segment,
    ...props
}) => {
    const event = useEvent()!
    const [name, setName] = useState(segment?.name ?? "")
    const [startTime, setStartTime] = useState(segment?.startTime ?? null)
    const [endTime, setEndTime] = useState(segment?.endTime ?? null)

    return <DialogForm
        isValid={ Boolean(name) }
        formData={{
            name,
            startTime: toUtcDateTime(startTime),
            endTime: toUtcDateTime(endTime),
        }}
        {...props}
    >
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    label="Name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    fullWidth
                    required
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <LuxonDateTimePicker
                    label="Start"
                    value={startTime}
                    onChange={setStartTime}
                    timezone={event.timezone}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <LuxonDateTimePicker
                    label="End"
                    value={endTime}
                    onChange={setEndTime}
                    timezone={event.timezone}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2" color="text.secondary">Leave start or end blank for an open ended segment.</Typography>
            </Grid>
        </Grid>
    </DialogForm>
}
