import { Stack, StackProps } from "@mui/material";
import { FC, forwardRef } from "react";


export type BarProps = StackProps

/**
 * Horizontal equivalent of Stack with most common default
 * properties (alignItems: center).
 */
export const Bar: FC<BarProps> = forwardRef((props, ref) => <Stack
    direction="row"
    alignItems="center"
    {...props}
    ref={ref}
/>)