import { AppTemplateDeployment } from "@marketpartner/backend-api"
import { ExternalLink } from "@marketpartner/mp-common-mui"
import { Stack, Typography } from "@mui/material"
import { FC } from "react"


export type DeploymentLocationProps = {
    deployment: AppTemplateDeployment
}

export const DeploymentLocation: FC<DeploymentLocationProps> = ({
    deployment
}) => {
    const url = `https://${deployment.domainName}/${deployment.path}/`
    return <Stack>
        <Typography>
            {deployment.name}
        </Typography>
        <ExternalLink
            href={url}
            variant="body2"
        >{url}</ExternalLink>
    </Stack >
}