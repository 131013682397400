import { FeedItemDisplayStatus } from "@marketpartner/backend-api";
import { Delete, VisibilityOff } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { FC, memo, ReactNode } from "react";
import { Bar } from "src/common/layout/Bar";

export type FeedItemDisplayStatusIconProps = {
    status: FeedItemDisplayStatus
}

export const FeedItemDisplayStatusIcon = memo<FeedItemDisplayStatusIconProps>(({
    status
}) => {
    switch (status) {
        case FeedItemDisplayStatus.Pending: return <StatusIcon icon={<VisibilityOff fontSize="small" />} text="Pending approval" />
        case FeedItemDisplayStatus.Removed: return <StatusIcon icon={<Delete fontSize="small" color="error" />} text="Deleted" />
        default: return <></>
    }
})

type StatusIconProps = {
    icon: ReactNode
    text: string
}

const StatusIcon: FC<StatusIconProps> = ({
    icon,
    text
}) => {
    return <Bar spacing={1}>
        {icon}
        <Typography variant="body2">
            {text}
        </Typography>
    </Bar>
}