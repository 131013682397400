import { Permission, StandardForm } from "@marketpartner/backend-api";
import EditIcon from '@mui/icons-material/Edit';
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from 'src/common/api';
import { DialogButton } from "src/common/dialogs/DialogButton";
import { withNotification } from "src/common/notifications/with-notification";
import { useEvent } from "src/events/event-context";
import { FormForm } from "src/forms/FormForm";
import { ifHas } from "src/login/IfHas";

export type EditFormButtonProps = {
    form: StandardForm
}

export const EditFormButton: FC<EditFormButtonProps> = ({ form }) => {
    const client = useClient()!
    const event = useEvent()!
    const editMutation = backend.forms.useUpdate(withNotification({
        successMessage: "Updated form",
        errorMessage: "Error updating form",
    }))

    return ifHas(Permission.EditForms, client.id, event.id,
        <DialogButton
            icon={<EditIcon />}
            text="Edit"
            variant="contained"
            color="primary"
        >
            <DialogTitle>Edit form</DialogTitle>
            <FormForm
                actionName="Save"
                onSubmit={formDetails => editMutation.mutateAsync([client.id, event.id, form.id, formDetails])}
                form={form}
            />
        </DialogButton>
    )
}