import { Registration, RegistrationApprovalState } from "@marketpartner/backend-api"
import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material"
import { UseMutationResult } from "@tanstack/react-query"
import { FC, useCallback } from "react"
import { useClient } from "src/clients/client-context"
import { useEvent } from "src/events/event-context"
import { useAlterApprovalDialog } from "src/registrations/approval/UpdateApprovalDialog"
import { registrationStatusColors, RegistrationStatusIcon } from "src/registrations/RegistrationStatusIcon"


export type ApprovalActionButtonProps = {
    status: RegistrationApprovalState
    actionName: string
    mutation: UseMutationResult<unknown, unknown, [string, string, string]>
    registration: Registration
    requireConfirmation?: boolean
}

export const ApprovalActionButton: FC<ApprovalActionButtonProps> = ({
    status,
    actionName,
    mutation,
    registration,
    requireConfirmation = false,
}) => {
    const client = useClient()!
    const event = useEvent()!
    const { showConfirmDialog } = useAlterApprovalDialog()

    const handleClick = useCallback(() => {
        if (requireConfirmation) {
            showConfirmDialog(
                registration, 
                () => mutation.mutateAsync([client.id, event.id, registration.id]),
                status
            )
        } else {
            mutation.mutateAsync([client.id, event.id, registration.id])
        }
    }, [requireConfirmation, registration, showConfirmDialog, mutation, client.id, event.id, status])

    if (mutation.isPending) {
        return <Box p="7px" display="inline-flex">
            <CircularProgress size={20} />
        </Box>
    }

    if (registration.approvalState === status) {
        return <Box p="5px" display="inline-flex">
            <RegistrationStatusIcon status={status} />
        </Box>
    }

    return <Tooltip title={actionName}>
        <IconButton onClick={handleClick} size="small" sx={{
            color: registrationStatusColors[status]
        }}>
            <RegistrationStatusIcon status={status} variant="action" />
        </IconButton>
    </Tooltip>
}