import { MarkdownEditorConfig } from "@marketpartner/backend-api"
import { Grid, TextField } from "@mui/material"
import { FormElementConfigComponent } from "src/forms/elements/types/formElementConfigTypes"
import { StandardFields } from "src/forms/elements/types/StandardFields"

export const MarkdownEditorConfigComponent: FormElementConfigComponent<MarkdownEditorConfig> = ({
    config,
    updateConfigProperty
}) => {
    return <>
        <StandardFields config={config} updateConfigProperty={updateConfigProperty}/>
        <Grid item xs={12}>
            <TextField
                label="Placeholder"
                fullWidth
                value={config.placeholder}
                onChange={(e) => { updateConfigProperty('placeholder', e.target.value) }}
            />
        </Grid>
    </>
}