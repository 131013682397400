import { EventFieldEditPermission, EventFieldElementMetadata, EventFieldVisibility } from "@marketpartner/backend-api"
import { Public, Visibility } from "@mui/icons-material"
import { SvgIconProps } from "@mui/material"
import { FC } from "react"
import { Editable } from "src/common/icons/Editable"
import { Bar, BarProps } from "src/common/layout/Bar"


export type EventFieldEditPermissionIconProps = Partial<SvgIconProps> & {
    permission: EventFieldEditPermission | undefined
}

export const EventFieldEditPermissionIcon: FC<EventFieldEditPermissionIconProps> = ({
    permission,
    ...props
}) => <Editable
        editable={permission === EventFieldEditPermission.EventAdmins}
        {...props}
    />



export type EventFieldVisibilityIconProps = Partial<SvgIconProps> & {
    elementVisibility: EventFieldVisibility | undefined
}

export const EventFieldVisibilityIcon = ({
    elementVisibility,
    ...props
}: EventFieldVisibilityIconProps) => {
    switch (elementVisibility) {
        case EventFieldVisibility.Public:
            return <Public />
        default:
            return <Visibility {...props} />
    }
}


export type EventFieldElementMetadataIconsProps = Partial<BarProps> & {
    metadata: EventFieldElementMetadata | undefined
    iconProps?: Partial<SvgIconProps>
}

export const EventFieldElementMetadataIcons: FC<EventFieldElementMetadataIconsProps> = ({
    metadata,
    iconProps,
    ...props
}) => {
    return <Bar spacing={1} {...props}>
        <EventFieldVisibilityIcon elementVisibility={metadata?.visibility} {...iconProps} />
        <EventFieldEditPermissionIcon permission={metadata?.editPermission} {...iconProps} />
    </Bar>
}