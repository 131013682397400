import { MeetingRequestStatus } from "@marketpartner/backend-api";
import { nameSortKey } from "@marketpartner/mp-common";
import { GridColDef } from "@mui/x-data-grid-pro";
import { FC } from "react";
import { QueryDataGrid, QueryDataGridProps } from "src/common/grid/QueryDataGrid";
import { Bar } from "src/common/layout/Bar";
import { EventDayTimeRangeText } from "src/common/time/DayTimeRangeText";
import { LocationName } from "src/content/locations/LocationName";
import { MeetingRequestContextMenuButton } from "src/networking/meetings/requests/MeetingRequestContextMenu";
import { MeetingRequestStatusIcon } from "src/networking/meetings/requests/MeetingRequestStatus";
import { JoinedMeetingRequest } from "src/networking/meetings/requests/useJoinedMeetingRequestsQuery";
import { NameAndEmail } from "src/registrations/NameAndEmail";


export type MeetingRequestsGridProps = Omit<QueryDataGridProps<JoinedMeetingRequest>, "columns">

export const MeetingRequestsGrid: FC<MeetingRequestsGridProps> = ({
    ...props
}) => {
    return <QueryDataGrid
        columns={columns}
        getRowId={getRowId}
        disableRowSelectionOnClick
        disableColumnMenu
        {...props}
    />
}

const getRowId = (row: JoinedMeetingRequest) => row.meetingId

const columns: GridColDef<JoinedMeetingRequest>[] = [
    {
        field: "requester",
        sortComparator: (a, b) => nameSortKey(a).localeCompare(nameSortKey(b)),
        headerName: "Requester",
        renderCell: ({ value }) => <NameAndEmail registration={value} linkToRegistration />,
        minWidth: 200,
        flex: 1,
    },
    {
        field: "respondant",
        sortComparator: (a, b) => nameSortKey(a).localeCompare(nameSortKey(b)),
        headerName: "Respondant",
        renderCell: ({ value }) => <NameAndEmail registration={value} linkToRegistration />,
        minWidth: 200,
        flex: 1,
    },
    {
        field: "location",
        headerName: "Location",
        renderCell: ({ value }) => <LocationName location={value} />,
        minWidth: 200,
        flex: 1,
    },
    {
        field: "start",
        headerName: "Time",
        renderCell: ({ row }) => <EventDayTimeRangeText start={row.start} end={row.end} hideTimezone />,
        width: 125,
    },
    {
        field: "status",
        sortComparator: (a: MeetingRequestStatus, b: MeetingRequestStatus) => statusSortOrder[a] - statusSortOrder[b],
        headerName: "Status",
        renderCell: ({ value, row }) => <Bar spacing={1}>
            <MeetingRequestStatusIcon status={value} />
            <MeetingRequestContextMenuButton request={row} />
        </Bar>,
        width: 95,
    },
]

const statusSortOrder: Record<MeetingRequestStatus, number> = {
    Pending: 1,
    Accepted: 2,
    Declined: 3,
    Cancelled: 4,
}