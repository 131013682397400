import { EventPartnerInstance } from '@marketpartner/backend-api'
import { FC } from 'react'
import { useClient } from 'src/clients/client-context'
import { backend } from 'src/common/api'
import { DeleteButton } from "src/common/dialogs/DeleteButton"

export type DeleteEventPartnerInstanceButtonProps = {
    instance: EventPartnerInstance
}

export const DeleteEventPartnerInstanceButton: FC<DeleteEventPartnerInstanceButtonProps> = ({
    instance
}) => {
    const client = useClient()!
    const deleteMutation = backend.content.useDeleteEventPartnerInstance()

    return <DeleteButton
        text={`This will disable Event Partner APIs for all events that use this instance.`}
        confirmText={instance.id}
        deleteAction={() => deleteMutation.mutateAsync([client.id, instance.id])}
        itemName={instance.id}
    />
}