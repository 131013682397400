import { AgendaItemType, Scope, StructuredAgendaItem, StructuredBreakoutTrack } from "@marketpartner/backend-api"
import { MenuProps } from "@mui/material"
import { FC } from "react"
import { createPopupHook } from "src/common/dialogs/Popup"
import { BreakoutContextMenu } from "src/content/agendas/breakouts/BreakoutContextMenu"
import { AgendaHeadingContextMenu } from "src/content/agendas/headings/AgendaHeadingContextMenu"
import { SessionContextMenu } from "src/content/agendas/sessions/SessionContextMenu"
import { BreakoutTrackContextMenu } from "src/content/agendas/tracks/BreakoutTrackContextMenu"


type AgendaItemContextMenuProps = MenuProps & {
    item: StructuredAgendaItem | StructuredBreakoutTrack
    onClose: () => void
}

const AgendaItemContextMenu: FC<AgendaItemContextMenuProps> = ({
    item,
    ...props
}) => {
    switch (item.type) {
        case AgendaItemType.Session: return <SessionContextMenu session={item} {...props}/>
        case AgendaItemType.Heading: return <AgendaHeadingContextMenu heading={item} {...props}/>
        case AgendaItemType.Breakout: return <BreakoutContextMenu breakout={item} {...props}/>
        case AgendaItemType.BreakoutTrack: return <BreakoutTrackContextMenu track={item} {...props}/>
    }
}

export const useAgendaItemContextMenu = createPopupHook({
    scope: Scope.Event,
    element: AgendaItemContextMenu,
})