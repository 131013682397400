import { Permission } from '@marketpartner/backend-api';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { DialogTitle, Paper } from '@mui/material';
import { FC } from 'react';
import { ClientsGrid } from "src/clients/ClientsGrid";
import { CreateClientButton } from "src/clients/CreateClientButton";
import { backend } from 'src/common/api';
import { ConfirmDialogForm } from 'src/common/dialogs/ConfirmDialogForm';
import { DialogButton } from "src/common/dialogs/DialogButton";
import { withNotification } from 'src/common/notifications/with-notification';
import { Page } from "src/common/layout/Page";
import { fullSize } from 'src/common/styles';
import { ifHas } from 'src/login/IfHas';

export const ClientsPage: FC = () => {

    return <Page
        title="Clients"
        actions={<>
            <MigrateAllButton />
            <CreateClientButton />
        </>}
    >
        <Paper sx={{ ...fullSize }}>
            <ClientsGrid />
        </Paper>
    </Page>

}

const MigrateAllButton: FC = () => {
    const migrateAllMutation = backend.clients.useMigrateAll(withNotification({
        successMessage: "Successfully migrated all databases",
        errorMessage: "Error migrating one or more databases",
    }))

    return ifHas(Permission.AdministerSystem, undefined, undefined,
        <DialogButton text="Migrate all" icon={<ArrowUpwardIcon />}>
            <DialogTitle>Migrate all client databases</DialogTitle>
            <ConfirmDialogForm
                action={() => migrateAllMutation.mutateAsync([])}
                text="This will migrate all out-of-date client databases to the latest version"
                submitText="Migrate all"
            />
        </DialogButton>
    )
}