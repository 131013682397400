import { Permission, StripeInstance } from "@marketpartner/backend-api";
import { LoadedSelector, LoadedSelectorProps } from "@marketpartner/mp-common-mui";
import { Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { Bar } from "src/common/layout/Bar";
import { ifHas } from "src/login/IfHas";
import { useStripeInstancesDialog } from "src/payment/stripe/StripeInstancesDialog";


export type StripeInstanceSelectorProps = Omit<LoadedSelectorProps<number, StripeInstance>, "fetchRequest">

export const StripeInstanceSelector: FC<StripeInstanceSelectorProps> = ({
    ...props
}) => {
    const client = useClient()!

    const instancesQuery = backend.stripeInstanceApi.useGetStripeInstances([client.id])

    const instancesDialog = useStripeInstancesDialog()

    return <Bar spacing={2} sx={{ width: props.fullWidth ? "100%" : "fit-content" }}>
        <LoadedSelector
            fetchRequest={instancesQuery}
            label="Stripe instance"
            {...props}
        />
        {ifHas(Permission.EditClientDetails, client.id, undefined, <IconButton
            children={<Edit />}
            color="primary"
            onClick={() => instancesDialog.open({})}
        />)}
    </Bar>
}