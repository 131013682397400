import { AgendaItemType, BreakoutTrack, Scope } from "@marketpartner/backend-api";
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { BreakoutTrackForm } from "src/content/agendas/tracks/BreakoutTrackForm";
import { useEvent } from "src/events/event-context";


type EditBreakoutTrackDialogProps = LockableDialogProps & {
    track: BreakoutTrack
}

const EditBreakoutTrackDialog: FC<EditBreakoutTrackDialogProps> = ({
    track,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const updateTrackMutation = backend.agendaItems.useUpdate(withNotification({
        successMessage: "Updated breakout track",
        errorMessage: "Error updating breakout track",
    }))

    return <LockableDialog {...props}>
        <DialogTitle>
            Edit track
        </DialogTitle>
        <BreakoutTrackForm
            onSubmit={formData => updateTrackMutation.mutateAsync([client.id, event.id, track.agendaId, track.id, {
                type: AgendaItemType.BreakoutTrack,
                ...formData,
            }])}
            track={track}
            actionName="Save"
        />
    </LockableDialog>
}

export const useEditBreakoutTrackDialog = createPopupHook({
    scope: Scope.Event,
    element: EditBreakoutTrackDialog,
})
