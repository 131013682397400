import { Scope } from "@marketpartner/backend-api"
import { CallSplit, Groups, Title } from "@mui/icons-material"
import { ListItemIcon, ListItemText, Menu, MenuItem, MenuProps } from "@mui/material"
import { DateTime } from "luxon"
import { FC } from "react"
import { createPopupHook } from "src/common/dialogs/Popup"
import { useAddBreakoutDialog } from "src/content/agendas/breakouts/AddBreakoutDialog"
import { useAddAgendaHeadingDialog } from "src/content/agendas/headings/AddAgendaHeadingDialog"
import { useAddSessionDialog } from "src/content/agendas/sessions/AddSessionDialog"


type AddAgendaItemMenuProps = MenuProps & {
    agendaId: string
    suggestedStart: DateTime
    onClose: () => void
}

const AddAgendaItemMenu: FC<AddAgendaItemMenuProps> = ({
    agendaId,
    suggestedStart,
    ...props
}) => {
    const addSessionDialog = useAddSessionDialog()
    const addBreakoutDialog = useAddBreakoutDialog()
    const addHeadingDialog = useAddAgendaHeadingDialog()

    return <Menu {...props} onClick={props.onClose}>
        <MenuItem onClick={() => addSessionDialog.open({ agendaId, trackId: null, suggestedStart })}>
            <ListItemIcon><Groups /></ListItemIcon>
            <ListItemText>Session</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => addBreakoutDialog.open({ agendaId, start: suggestedStart })}>
            <ListItemIcon><CallSplit /></ListItemIcon>
            <ListItemText>Breakout</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => addHeadingDialog.open({ agendaId, trackId: null, suggestedStart })}>
            <ListItemIcon><Title /></ListItemIcon>
            <ListItemText>Heading</ListItemText>
        </MenuItem>
    </Menu>
}

export const useAddAgendaItemMenu = createPopupHook({
    scope: Scope.Event,
    element: AddAgendaItemMenu,
})