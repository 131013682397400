import { Scope } from "@marketpartner/backend-api"
import { useAtom, useAtomValue, useSetAtom } from "jotai"
import { atomWithImmer } from "jotai-immer"
import { FC, memo, useEffect, useLayoutEffect } from "react"
import { useClientId } from "src/clients/client-context"
import { CreatePopupOptions, PopupProps, PopupStateAtom } from "src/common/dialogs/Popup"
import { useEventId } from "src/events/event-context"

// This file provides a central registry of all popups, and a component that renders
// all popups at global/client/event level. This eliminates the need for every popup
// to have it's own context/state/renderer.

type OptionsAndStateAtom<Props extends PopupProps> = {
    options: CreatePopupOptions<Props>,
    stateAtom: PopupStateAtom<Props>,
}

type PopupRegister = Record<string, OptionsAndStateAtom<any>>

const registers = {
    Global: atomWithImmer<PopupRegister>({}),
    Client: atomWithImmer<PopupRegister>({}),
    Event: atomWithImmer<PopupRegister>({}),
}

export const useRegisterPopup = <Props extends PopupProps>(
    options: CreatePopupOptions<Props>,
    stateAtom: PopupStateAtom<Props>,
) => {
    const setPopups = useSetAtom(registers[options.scope])

    useEffect(() => {
        setPopups(popups => {
            if (!popups[stateAtom.toString()]) {
                popups[stateAtom.toString()] = {
                    options,
                    stateAtom,
                }
            }
        })
    }, [setPopups, options, stateAtom])
}

export type RenderPopupsProps = {
    scope: Scope
}

export const RenderPopups: FC<RenderPopupsProps> = ({
    scope
}) => {
    const clientId = useClientId()
    const eventId = useEventId()
    const popups = useAtomValue(registers[scope])

    // Seems like *sometimes* a client/event popup renders after navigating to a global context.
    // The layout effect in RenderPopup should prevent this, but maybe there's a race condition.
    // Render nothing to be on the safe side.
    if (scope === Scope.Event && !eventId) {
        return <></>
    }
    if (scope === Scope.Client && !clientId) {
        return <></>
    }

    return <>
        {Object.values(popups).map(({
            options,
            stateAtom
        }) => <RenderPopup
                key={stateAtom.toString()}
                options={options}
                stateAtom={stateAtom}
            />)}
    </>
}

const RenderPopup = memo(<Props extends PopupProps>({
    options,
    stateAtom
}: OptionsAndStateAtom<Props>) => {
    const [state, setState] = useAtom(stateAtom)
    const clientId = useClientId()
    const eventId = useEventId()

    // Reset event popups if the event changes
    // This prevents rendering of a popup using params from a different event
    useLayoutEffect(() => {
        if (options.scope === Scope.Event) {
            setState(undefined)
        }
    }, [options.scope, setState, eventId])

    // Reset client and event popups if the client changes
    // This prevents rendering of a popup using params from a different client
    useLayoutEffect(() => {
        if (options.scope !== Scope.Global) {
            setState(undefined)
        }
    }, [options.scope, setState, clientId])


    if (state === undefined) {
        return <></>
    }

    return <options.element {...state} />
})
