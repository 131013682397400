import { LocalDate, LocalDateTime } from "@marketpartner/backend-api";
import { Typography, TypographyProps } from "@mui/material";
import { DateTime } from "luxon";
import { FC } from "react";


export type DayHeadingProps = Partial<TypographyProps> & {
    day: LocalDate | LocalDateTime | DateTime
}

export const DayHeading: FC<DayHeadingProps> = ({
    day,
    ...props
}) => {
    return <Typography
        variant="h6"
        {...props}
    >
        {day.toLocaleString(DateTime.DATE_HUGE)}
    </Typography>
}