import { Permission, StructuredAgendaItem, StructuredBreakoutTrack } from "@marketpartner/backend-api"
import { Box, Link, Stack, SxProps, Typography } from "@mui/material"
import { FC, ReactNode } from "react"
import { Link as RouterLink } from 'react-router-dom'
import { useClient } from "src/clients/client-context"
import { Bar } from "src/common/layout/Bar"
import { ContextMenuButton } from "src/common/menus/ContextMenuButton"
import { useAgendaItemContextMenu } from "src/content/agendas/AgendaItemContextMenu"
import { AgendaItemTimes } from "src/content/agendas/AgendaItemTimes"
import { useEvent } from "src/events/event-context"
import { ifHas } from "src/login/IfHas"


export type EditableAgendaItemProps<Item extends StructuredAgendaItem | StructuredBreakoutTrack> = {
    item: Item
    headerText?: ReactNode
    linkTo?: string
    children?: ReactNode
    sx?: SxProps
}

const timeAndMenuWidth = 75

export const EditableAgendaItem = <Item extends StructuredAgendaItem | StructuredBreakoutTrack>({
    item,
    headerText,
    linkTo,
    children,
    sx,
}: EditableAgendaItemProps<Item>) => {
    const client = useClient()!
    const event = useEvent()!
    const menu = useAgendaItemContextMenu()

    return <Stack sx={{
        ...sx,
        py: 1,
        alignItems: "stretch",
        justifyContent: "stretch",
        minWidth: 250,
    }}>
        <Bar flex="none">

            <Box sx={{ width: timeAndMenuWidth, flex: "none" }}>
                {"start" in item && <AgendaItemTimes item={item} />}
            </Box>

            <ItemHeader headerText={headerText} linkTo={linkTo} sx={{ mx: "auto" }} />

            <Bar width={timeAndMenuWidth} justifyContent="flex-end">
                {ifHas(Permission.EditEventContent, client.id, event.id, <ContextMenuButton size="small" onClick={e => menu.open({
                    item,
                    anchorEl: e.currentTarget
                })} />)}
            </Bar>

        </Bar>

        {children}

    </Stack >
}

type ItemHeaderProps = {
    headerText?: ReactNode
    linkTo?: string
    sx?: SxProps
}

const ItemHeader: FC<ItemHeaderProps> = ({
    headerText,
    linkTo,
    sx,
}) => {
    if (linkTo) {
        return <Link
            component={RouterLink}
            variant="subtitle1"
            to={linkTo}
            underline="hover"
            sx={{
                color: "inherit",
                ...sx
            }}
        >
            {headerText}
        </Link>
    }
    return <Typography variant="subtitle1" sx={sx}>
        {headerText || <>&nbsp;</>}
    </Typography>
}