import { useUrlState } from "@marketpartner/mp-common-react";
import { FC } from "react";
import { ClientAppTemplatesGrid } from "src/app-templates/client-templates/ClientAppTemplatesGrid";
import { CreateClientAppTemplateButton } from "src/app-templates/client-templates/CreateClientAppTemplateButton";
import { ClientSelector } from "src/clients/ClientSelector";
import { BarAndFlex } from "src/common/layout/BarAndFlex";
import { Bar } from "src/common/layout/Bar";
import { Spacer } from "src/common/layout/Spacer";


export const ClientAppTemplates: FC = () => {
    const [clientId, setClientId] = useUrlState("clientId")

    return <BarAndFlex barContent={
        <Bar m={2}>
            <ClientSelector
                clientId={clientId}
                onSelectClientId={clientId => setClientId(clientId ?? "")}
                required={false}
                size="small"
                nullLabel="All"
                noRefresh
                sx={{ minWidth: 200 }}
            />
            <Spacer />
            <CreateClientAppTemplateButton clientId={clientId!} disabled={!clientId} />
        </Bar>
    }>
        <ClientAppTemplatesGrid clientId={clientId ?? undefined} />
    </BarAndFlex>
}