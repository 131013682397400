import { Client, Permission } from '@marketpartner/backend-api';
import EditIcon from '@mui/icons-material/Edit';
import { DialogTitle } from '@mui/material';
import { FC } from 'react';
import { ClientForm } from 'src/clients/ClientForm';
import { backend } from 'src/common/api';
import { DialogButton, DialogButtonProps } from "src/common/dialogs/DialogButton";
import { withNotification } from 'src/common/notifications/with-notification';
import { ifHas } from 'src/login/IfHas';

export type EditClientButtonProps = Partial<DialogButtonProps> & {
    client: Client
}

export const EditClientButton: FC<EditClientButtonProps> = ({
    client,
    ...props
}) => {
    const updateMutation = backend.clients.useUpdate(withNotification({
        successMessage: "Updated client",
        errorMessage: "Error updating client",
    }))

    return ifHas(Permission.EditClientDetails, client.id, undefined,
        <DialogButton
            icon={<EditIcon />}
            text="Edit"
            color="primary"
            variant="contained"
        >
            <DialogTitle>Edit client</DialogTitle>
            <ClientForm
                actionName="Save"
                onSubmit={clientDetails => updateMutation.mutateAsync([client.id, clientDetails])}
                client={client}
            />
        </DialogButton>
    )
}
