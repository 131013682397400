import { CurrentAuthorization, Permission } from "@marketpartner/backend-api"
import { createContext, FC, ReactNode, useContext } from "react"
import { useNavigate } from "react-router"
import { backend } from "src/common/api"
import { Loading } from "src/common/loading/Loading"
import { AccessDenied } from "src/login/AccessDenied"
import { hasPermissionForContext } from "src/login/permissions-checks"

export type LoginContext = CurrentAuthorization & {
    logout: () => void
    hasPermission: (permission: Permission, clientId?: string, eventId?: string) => boolean
}

const loginContext = createContext<LoginContext>(undefined!)

/**
 * Hook providing current login state
 */
export function useLoginContext() {
    return useContext(loginContext)
}

export type LoginContextProviderProps = {
    logout: () => void
    children: ReactNode
}

export const LoginContextProvider: FC<LoginContextProviderProps> = ({
    logout,
    children
}) => {
    const authorizationQuery = backend.authorization.useGetCurrentAuthorization([])
    const navigate = useNavigate()

    async function handleLogout() {
        logout()
        navigate("/")
    }

    if (authorizationQuery.error) {
        return <AccessDenied doLogout={handleLogout} />
    }

    return <Loading
        request={authorizationQuery}
        render={authorization => <loginContext.Provider
            children={!hasPermissionForContext(authorization.permissions, Permission.AccessAdminConsole, undefined, undefined) ?
                <AccessDenied doLogout={handleLogout} /> :
                children
            }
            value={{
                ...authorization,
                logout: handleLogout,
                hasPermission: (permission, clientId, eventId) => hasPermissionForContext(authorization.permissions, permission, clientId, eventId),
            }}
        />}
    />
}
