import { AdjacentItemDetails, Session } from "@marketpartner/backend-api"
import { DeleteForever, Edit } from "@mui/icons-material"
import { Divider, ListItemIcon, ListItemText, Menu, MenuItem, MenuProps } from "@mui/material"
import { FC } from "react"
import { MoveAgendaItemButtons } from "src/content/agendas/MoveAgendaItemButtons"
import { useDeleteSessionDialog } from "src/content/agendas/sessions/DeleteSessionDialog"
import { useEditSessionDialog } from "src/content/agendas/sessions/EditSessionDialog"


export type SessionContextMenuProps = MenuProps & {
    session: Session & AdjacentItemDetails,
    onClose: () => void
}

export const SessionContextMenu: FC<SessionContextMenuProps> = ({
    session,
    ...props
}) => {
    const editSessionDialog = useEditSessionDialog()
    const deleteSessionDialog = useDeleteSessionDialog()

    return <Menu {...props} onClick={props.onClose}>
        <MenuItem disabled>
            <ListItemText sx={{ textAlign: "center" }}>Session</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => editSessionDialog.open({ session })}>
            <ListItemIcon><Edit color="primary" /></ListItemIcon>
            <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => deleteSessionDialog.open({ session })}>
            <ListItemIcon><DeleteForever color="error" /></ListItemIcon>
            <ListItemText>Delete</ListItemText>
        </MenuItem>
        <Divider />
        <MoveAgendaItemButtons item={session} onMove={props.onClose} />
    </Menu>
}