import { Registration } from "@marketpartner/backend-api"
import { GridColDef } from "@mui/x-data-grid-pro"
import { FC, useMemo } from "react"
import { BaseRegistrationsGrid, BaseRegistrationsGridProps } from "src/registrations/BaseRegistrationsGrid"
import { NameAndEmail } from "src/registrations/NameAndEmail"
import { ProfileAvatar } from "src/registrations/profiles/ProfileAvatar"


export type SpeakersGridProps = Omit<BaseRegistrationsGridProps, "columns">

export const SpeakersGrid: FC<SpeakersGridProps> = ({
    ...props
}) => {
    const columns = useMemo((): GridColDef<Registration>[] => [
        {
            field: "profile",
            headerName: "Profile",
            renderCell: ({ row }) => <ProfileAvatar registration={row} size={40} />,
            align: "center",
            width: 80,
            sortable: false,
        },
        {
            field: "name",
            headerName: "Name",
            valueGetter: ({ row }) => `${row.lastName}_${row.firstName}`,
            renderCell: ({ row }) => <NameAndEmail registration={row} />,
            minWidth: 300,
        },
    ], [])

    return <BaseRegistrationsGrid
        columns={columns}
        {...props}
    />
}


