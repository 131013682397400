import { AdminAccessToken } from "@marketpartner/backend-api"
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro"
import { DeleteAdminTokenButton } from "src/access/tokens/admin-tokens/DeleteAdminTokenButton"
import { backend } from "src/common/api"
import { Loading } from "src/common/loading/Loading"
import { LocalDateTimeText } from "src/common/time/DateTimeText"

const columns: GridColDef<AdminAccessToken>[] = [
    { field: "id", width: 70 },
    { field: "token", width: 150 },
    { field: "name", minWidth: 200, flex: 2 },
    { field: "description", minWidth: 100, flex: 4 },
    { field: "role", minWidth: 100, flex: 1 },
    { field: "clientId", minWidth: 150, flex: 1.5 },
    { field: "eventId", minWidth: 150, flex: 1.5 },
    { field: "expires", minWidth: 200, flex: 2, renderCell: ({ value }) => <LocalDateTimeText dateTime={value} includeSeconds /> },
    { field: "lastUsed", minWidth: 200, flex: 2, renderCell: ({ value }) => <LocalDateTimeText dateTime={value} includeSeconds /> },
    {
        field: "actions",
        headerName: "",
        renderCell: ({ row }) => <DeleteAdminTokenButton token={row} />
    }
]

export const AdminTokensGrid = () => {
    const tokensQuery = backend.accessTokens.useGetAdminAccessTokens([])

    return <Loading request={tokensQuery} render={tokens => <DataGridPro
        columns={columns}
        rows={tokens}
        disableRowSelectionOnClick
    />} />
}