import { Stack, Typography } from "@mui/material"
import { FC } from "react"


export type DeletedPrincipalProps = {
    principal: string
}

export const DeletedPrincipal: FC<DeletedPrincipalProps> = ({
    principal
}) => <Stack>
    <Typography color="text.secondary">
        {principal}
    </Typography>
    <Typography variant="body2" color="text.secondary">
        (Deleted)
    </Typography>
</Stack>