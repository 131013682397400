import { NetworkingPeriod, Scope } from "@marketpartner/backend-api";
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { useEvent } from "src/events/event-context";
import { NetworkingPeriodForm } from "src/networking/meetings/periods/NetworkingPeriodForm";


export type CreateNetworkingPeriodDialogProps = LockableDialogProps & {
    networkingPeriods: NetworkingPeriod[]
}

const CreateNetworkingPeriodDialog: FC<CreateNetworkingPeriodDialogProps> = ({
    networkingPeriods,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!

    const createPeriodMutation = backend.networkingPeriods.useCreatePeriod(withNotification({
        errorMessage: "Error creating networking period"
    }))
    const suggestedStart = networkingPeriods[networkingPeriods.length - 1]?.start?.plus({ days: 1 })
    const suggestedEnd = suggestedStart?.plus({ hours: 8 })

    return <LockableDialog
        maxWidth="sm"
        fullWidth
        {...props}
    >
        <DialogTitle>Create networking period</DialogTitle>
        <NetworkingPeriodForm
            actionName="Create"
            onSubmit={data => createPeriodMutation.mutateAsync([client.id, event.id, data])}
            suggestedStart={suggestedStart}
            suggestedEnd={suggestedEnd}
        />
    </LockableDialog>
}

export const useCreateNetworkingPeriodDialog = createPopupHook({
    element: CreateNetworkingPeriodDialog,
    scope: Scope.Event,
})