import { RegistrationCategory, RegistrationEmailType, Scope } from "@marketpartner/backend-api";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { ConfirmDeleteDialog } from "src/common/dialogs/ConfirmDeleteDialog";
import { LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { emailTriggerLabels } from "src/email/triggers/EmailTriggersGrid";
import { useEvent } from "src/events/event-context";


export type ConfirmDeleteEmailTriggerOverrideDialogProps = LockableDialogProps & {
    category: RegistrationCategory
    triggerKey: RegistrationEmailType
}

const ConfirmDeleteEmailTriggerOverrideDialog: FC<ConfirmDeleteEmailTriggerOverrideDialogProps> = ({
    category,
    triggerKey,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!

    const updateCategoryMutation = backend.registrationCategories.useUpdate(withNotification({
        errorMessage: "Error deleting template override"
    }))

    return <ConfirmDeleteDialog
        itemName="email template override"
        formProps={{
            text: `This will remove the ${emailTriggerLabels[triggerKey]} template override for the ${category.name} category.`,
            action: () => updateCategoryMutation.mutateAsync([client.id, event.id, category.id, {
                triggeredEmailTemplateOverrides: {
                    [triggerKey]: null
                }
            }])
        }}
        {...props}
    />
}

export const useConfirmDeleteEmailTriggerOverrideDialog = createPopupHook({
    element: ConfirmDeleteEmailTriggerOverrideDialog,
    scope: Scope.Event,
})