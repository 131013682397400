import { EventSegment, Permission } from "@marketpartner/backend-api";
import EditIcon from '@mui/icons-material/Edit';
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from 'src/common/api';
import { DialogButton } from "src/common/dialogs/DialogButton";
import { withNotification } from 'src/common/notifications/with-notification';
import { useEvent } from "src/events/event-context";
import { SegmentForm } from "src/events/segments/SegmentForm";
import { ifHas } from "src/login/IfHas";

export type EditSegmentButtonProps = {
    segment: EventSegment
}

export const EditSegmentButton: FC<EditSegmentButtonProps> = ({ segment }) => {
    const client = useClient()!
    const event = useEvent()!
    const updateSegmentMutation = backend.eventSegments.useUpdate(withNotification({
        successMessage: "Updated segment",
        errorMessage: "Error updating segment",
    }))

    return ifHas(Permission.EditEventDetails, client.id, event.id,
        <DialogButton icon={<EditIcon fontSize="small" />} color="primary" size="small">
            <DialogTitle>
                Edit segment
            </DialogTitle>
            <SegmentForm
                actionName="Save"
                onSubmit={segmentDetails => updateSegmentMutation.mutateAsync([client.id, event.id, segment.id, segmentDetails])}
                segment={segment}
            />
        </DialogButton>
    )
}