import { Permission } from '@marketpartner/backend-api'
import UploadIcon from '@mui/icons-material/Upload'
import { useClient } from 'src/clients/client-context'
import { DialogButton, DialogButtonProps } from 'src/common/dialogs/DialogButton'
import { useEvent } from 'src/events/event-context'
import { ifHas } from 'src/login/IfHas'
import { ImportRegistrationsDialog } from 'src/registrations/import/ImportRegistrationsDialog'

export type ImportRegistrationsButtonProps = Partial<DialogButtonProps>

export const ImportRegistrationsButton = ({ ...props }) => {
    const client = useClient()!
    const event = useEvent()!

    return ifHas(Permission.ImportAndDeleteRegistrations, client.id, event.id,
        <DialogButton
            color="inherit"
            endIcon={<UploadIcon />}
            text="Import"
            dialogProps={{
                fullScreen: true
            }}
            {...props}
        >
            <ImportRegistrationsDialog />
        </DialogButton>
    )
}