import { RelatedContentCategory } from "@marketpartner/backend-api"
import { Grid, TextField } from "@mui/material"
import { FC, useState } from "react"
import { DialogForm, ExtendableDialogFormProps } from "src/common/dialogs/DialogForm"
import { IdField } from "src/common/form-inputs/IdField"
import { PluralTextField } from "src/common/form-inputs/PluralTextField"


export type RelatedContentCategoryFormData = {
    id: string
    name: string
    pluralName: string
}

export type RelatedContentCategoryFormProps = ExtendableDialogFormProps<RelatedContentCategoryFormData> & {
    category?: RelatedContentCategory
}

export const RelatedContentCategoryForm: FC<RelatedContentCategoryFormProps> = ({
    category,
    ...props
}) => {
    const [id, setId] = useState(category?.id ?? "")
    const [name, setName] = useState(category?.name ?? "")
    const [pluralName, setPluralName] = useState(category?.pluralName ?? "")

    return <DialogForm
        formData={{
            id,
            name,
            pluralName,
        }}
        isValid={Boolean(id && name && pluralName)}
        {...props}
    >
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    label="Name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    fullWidth
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <PluralTextField
                    sourceValue={name}
                    value={pluralName}
                    onChange={setPluralName}
                    required
                    fullWidth
                />
            </Grid>
            {!category && <Grid item xs={12}>
                <IdField
                    nameValue={name}
                    idValue={id}
                    onChangeId={setId}
                    maxLength={255}
                />
            </Grid>}
        </Grid>
    </DialogForm>
}