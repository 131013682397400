import MenuIcon from '@mui/icons-material/Menu';
import { Drawer, Hidden, IconButton, Paper } from "@mui/material";
import { FC, ReactNode, useState } from "react";
import { BarAndFlex } from 'src/common/layout/BarAndFlex';
import { customUiRoles } from "src/common/routing/custom-ui-roles";
import { useLoginContext } from "src/login/LoginContext";
import { SideBarContent } from "src/SideBar";
import { TopBar } from "src/TopBar";

export type AppLayoutProps = {
    children: ReactNode
}

export const AppLayout: FC<AppLayoutProps> = ({ children }) => {
    const login = useLoginContext()
    const [sideBarOpen, setSideBarOpen] = useState(false)

    function openSideBar() {
        setSideBarOpen(true)
    }

    function closeSideBar() {
        setSideBarOpen(false)
    }

    const customUi = login.singleRole && customUiRoles[login.singleRole]
    const hideSidebar = customUi && !customUi.showSidebar

    return <BarAndFlex
        barContent={
            <TopBar
                menuIcon={<Hidden mdUp>
                    <IconButton onClick={openSideBar} color="inherit" size="medium">
                        <MenuIcon fontSize="small" />
                    </IconButton>
                </Hidden>}
            />
        }
        flexStyle={{ minHeight: 0 }}
    >
        <BarAndFlex
            direction="row"
            barContent={!hideSidebar && <DynamicSideBar open={sideBarOpen} onClose={closeSideBar} />}
            flexStyle={{ minWidth: 0 }}
        >
            {children}
        </BarAndFlex>
    </BarAndFlex>
}

type DynamicSideBarProps = {
    open: boolean,
    onClose: () => void
}

const DynamicSideBar: FC<DynamicSideBarProps> = ({ open, onClose }) => {
    return <>
        <Hidden mdDown>
            <Paper elevation={3} square sx={{ height: "100%", overflow: "auto" }}>
                <SideBarContent />
            </Paper>
        </Hidden>
        <Hidden mdUp>
            <Drawer
                open={open}
                onClose={onClose}
            >
                <SideBarContent close={onClose} />
            </Drawer>
        </Hidden>
    </>
}