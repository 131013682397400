import { Scope } from "@marketpartner/backend-api";
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { useEvent } from "src/events/event-context";
import { TicketTypeForm } from "src/payment/ticket-types/TicketTypeForm";


export type CreateTicketTypeDialogProps = LockableDialogProps

const CreateTicketTypeDialog: FC<CreateTicketTypeDialogProps> = ({
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!

    const createTicketTypeMutation = backend.ticketTypes.useCreate(withNotification({
        errorMessage: "Error creating ticket type",
    }))

    return <LockableDialog
        maxWidth="sm"
        fullWidth
        {...props}
    >
        <DialogTitle>Create ticket type</DialogTitle>
        <TicketTypeForm
            actionName="Create"
            onSubmit={formData => createTicketTypeMutation.mutateAsync([client.id, event.id, formData])}
        />
    </LockableDialog>
}

export const useCreateTicketTypeDialog = createPopupHook({
    element: CreateTicketTypeDialog,
    scope: Scope.Event,
})