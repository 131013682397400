import { Error } from "@mui/icons-material"
import { SxProps, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import { FC } from "react"
import { Bar } from "src/common/layout/Bar"


export type SelectCsvFileTextProps = {
    error?: string
    sx?: SxProps
}

export const SelectCsvFileText: FC<SelectCsvFileTextProps> = ({
    error,
    sx,
}) => {
    return <Stack sx={sx}>
        File must be in .csv format, and must include:
        <ul>
            <li>An <b>Email</b> and <b>Category</b> column when importing <i>new primary</i> registrations.</li>
            <li>An <b>Email</b> or <b>ID</b> column when updating <i>existing</i> registrations.</li>
        </ul>
        {error && <Bar spacing={1} justifyContent="center">
            <Error color="error" />
            <Typography color="error">{error}</Typography>
        </Bar>}
    </Stack>
}