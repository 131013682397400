import { Box, Stack, StackProps, SxProps } from "@mui/material"
import { FC, ReactNode, forwardRef } from "react"
import { fullSize } from "src/common/styles"

export type BarAndFlexProps = Partial<StackProps> & {
    barContent: ReactNode
    sizing?: "full-size" | "fit-content"
    flexStyle?: SxProps
}

/**
 * Displays a bar and some content next to each other.
 * The bar is sized to fit it's contents, while the main content takes up the remaining space.
 */
export const BarAndFlex: FC<BarAndFlexProps> = forwardRef(({
    barContent,
    sx,
    sizing = "full-size",
    flexStyle,
    children,
    ...stackProps
}, ref) => {
    const containerStyle = sizing === "fit-content" ? {
        maxHeight: "100%",
    } : fullSize

    return <Stack ref={ref} {...stackProps} sx={{
        ...containerStyle,
        ...sx
    }}>
        <Box children={barContent} sx={{
            flexGrow: 0,
            flexShrink: 0
        }} />
        <Box children={children} sx={{
            flexGrow: 1,
            flexShrink: 1,
            position: "relative",
            overflow: "hidden",
            overflowY: sizing === "fit-content" ? "auto" : undefined,
            ...flexStyle,
        }} />
    </Stack>
})