import { DomainName } from "@marketpartner/backend-api"
import { countBy } from "lodash"
import { useMemo } from "react"
import { backend } from "src/common/api"


export type DomainSummary = DomainName & {
    clientDomainCount: number | undefined
    deploymentCount: number | undefined
}

export type UseDomainSummaries = {
    isLoading: boolean
    domains: DomainSummary[]
}

export const useDomainSummaries = (): UseDomainSummaries => {
    const domainsQuery = backend.domainNames.useGetAllDomains([])
    const clientDomainCountsQuery = backend.domainNames.useGetAllClientDomains([], {
        select: domains => countBy(domains, "domainName")
    })
    const deploymentCountsQuery = backend.appTemplateDeployments.useGetGlobalDeployments([{ includeSubscopes: true }], {
        select: deployments => countBy(deployments, "domainName")
    })

    const domains = useMemo((): DomainSummary[] => {
        return (domainsQuery.data ?? []).map(domain => ({
            ...domain,
            clientDomainCount: clientDomainCountsQuery.data ?
                clientDomainCountsQuery.data[domain.domainName] ?? 0 :
                undefined,
            deploymentCount: deploymentCountsQuery.data ?
                deploymentCountsQuery.data[domain.domainName] ?? 0 :
                undefined
        }))
    }, [domainsQuery.data, clientDomainCountsQuery.data, deploymentCountsQuery.data])

    return useMemo(() => ({
        isLoading: domainsQuery.isLoading,
        domains
    }), [domainsQuery.isLoading, domains])
}