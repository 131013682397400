import { FormStatus, formStatus, Permission, RegistrationCategory } from "@marketpartner/backend-api";
import { Assignment } from "@mui/icons-material";
import { IconButton, IconButtonProps } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/system";
import { GridColDef, GridColumnVisibilityModel, GridSortModel } from "@mui/x-data-grid-pro";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { QueryDataGrid, QueryDataGridProps } from "src/common/grid/QueryDataGrid";
import { Bar } from "src/common/layout/Bar";
import { ContextMenuButton } from "src/common/menus/ContextMenuButton";
import { link } from "src/common/routing/routes";
import { ActiveStatus } from "src/common/statuses/ActiveStatus";
import { EventDateTimeText } from "src/common/time/DateTimeText";
import { useEvent } from "src/events/event-context";
import { ifHas } from "src/login/IfHas";
import { categorySortKey } from "src/registrations/configuration/categories/category-sort-key";
import { registrationCategoryStatusDetails } from "src/registrations/configuration/categories/registration-category-status-details";
import { useRegistrationCategoryContextMenu } from "src/registrations/configuration/categories/RegistrationCategoryContextMenu";
import { RegistrationCategoryTypeIcon } from "src/registrations/configuration/categories/RegistrationCategoryTypeIcon";
import { NameAndSubtitle } from "src/registrations/NameAndSubtitle";

export type RegistrationCategoriesGridProps = Partial<QueryDataGridProps<RegistrationCategory>>

const columns: GridColDef<RegistrationCategory>[] = [{
    field: 'id',
    headerName: 'Id',
}, {
    field: 'name',
    headerName: 'Name',
    renderCell: ({ row }) => <Bar spacing={1}>
        <RegistrationCategoryTypeIcon type={row.type} />
        <NameAndSubtitle name={row.name} subtitle={row.id} />
    </Bar>,
    sortable: false,
    width: 190,
}, {
    field: 'requiresApproval',
    headerName: 'Require approval',
    width: 80,
    renderCell: ({ value }) => value ? 'Yes' : 'No',
    sortable: false,
}, {
    field: 'role',
    headerName: 'Role',
    width: 130,
    sortable: false,
}, {
    field: 'status',
    headerName: 'Status',
    renderCell: ({ row }) => <Stack>
        <ActiveStatus
            details={registrationCategoryStatusDetails}
            start={row.start}
            end={row.end}
        />
        {formStatus(row) === FormStatus.Scheduled ?
            <EventDateTimeText dateTime={row.start} hideTimezone /> :
            <Typography variant="body2" color="text.secondary">
                {row.registrationCount} registered
            </Typography>
        }
    </Stack>,
    width: 170,
}, {
    field: 'order',
    headerName: 'Order',
    valueGetter: ({ row }) => categorySortKey(row),
    sortable: false,
    width: 140,
}, {
    field: 'actions',
    headerName: '',
    sortable: false,
    renderCell: ({ row }) => <Bar>
        <CategoryFormButton category={row} />
        <CategoryContextMenuButton category={row} />
    </Bar>,
    width: 100,
}]

const visibilityModel: GridColumnVisibilityModel = {
    id: false,
    order: false,
}

const sortModel: GridSortModel = [{
    field: 'order',
    sort: 'asc',
}]

export const RegistrationCategoriesGrid: FC<RegistrationCategoriesGridProps> = ({
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const categoriesQuery = backend.registrationCategories.useGetAll([client.id, event.id])

    return <QueryDataGrid
        queryOrRows={categoriesQuery}
        columns={columns}
        columnVisibilityModel={visibilityModel}
        disableRowSelectionOnClick
        disableColumnMenu
        {...props}
        sortModel={props.sortModel ?? sortModel}

        sx={{
            '& .MuiDataGrid-columnHeaderTitle': {
                whiteSpace: 'break-spaces',
                lineHeight: 1,
            },
            '&.MuiDataGrid-root .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainer': {
                pl: 1,
            },
        }}
    />
}

type CategoryContextMenuButtonProps = {
    category: RegistrationCategory
}

const CategoryContextMenuButton: FC<CategoryContextMenuButtonProps> = ({
    category,
}) => {
    const client = useClient()!
    const event = useEvent()!
    const menu = useRegistrationCategoryContextMenu()

    return ifHas(Permission.EditEventDetails, client.id, event.id, <ContextMenuButton
        onClick={e => menu.open({
            category,
            anchorEl: e.currentTarget,
        })}
    />)
}

type CategoryFormButtonProps = IconButtonProps<"a"> & {
    category: RegistrationCategory
}

const CategoryFormButton: FC<CategoryFormButtonProps> = ({
    category,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!

    return <IconButton
        href={`#${link.toRegistrationCategoryForms(client.id, event.id, category.id)}`}
        {...props}
    >
        <Assignment />
    </IconButton>
}