import { Registration, RegistrationType } from "@marketpartner/backend-api"
import { GridRowParams, GridRowSelectionModel, GRID_TREE_DATA_GROUPING_FIELD } from "@mui/x-data-grid-pro"
import { useCallback, useMemo } from "react"
import { QueryDataGrid, QueryDataGridProps, useRows } from "src/common/grid/QueryDataGrid"

const initialState = {
    columns: {
        columnVisibilityModel: {
            [GRID_TREE_DATA_GROUPING_FIELD]: false
        }
    }
}

export type BaseRegistrationsGridProps<R extends Registration = Registration> = QueryDataGridProps<R> & {
    selectedRegistrations?: Registration[]
    onChangeSelectedRegistrations?: (registrations: Registration[]) => void
}

/**
 * Basic wrapper component for registration grids. This handles:
 * 
 * - Displaying guests beneath the primary registration
 * - Ensuring that guests are selected when their primary registration is selected
 */
export const BaseRegistrationsGrid = <R extends Registration = Registration>({
    queryOrRows,
    selectedRegistrations,
    onChangeSelectedRegistrations,
    ...props
}: BaseRegistrationsGridProps<R>) => {
    const registrations = useRows(queryOrRows)

    const changeSelection = useCallback((newSelection: GridRowSelectionModel) => {
        const ids = new Set(newSelection as string[])

        // Include all related guests
        registrations
            .filter(it => it.type === RegistrationType.Guest && ids.has(it.primaryRegistrationId))
            .forEach(it => ids.add(it.id))

        // Remove all orphaned guests
        registrations
            .filter(it => it.type === RegistrationType.Guest && !ids.has(it.primaryRegistrationId))
            .forEach(it => ids.delete(it.id))

        onChangeSelectedRegistrations?.(registrations.filter(it => ids.has(it.id)))
    }, [registrations, onChangeSelectedRegistrations])

    const selectedIds = useMemo(
        () => selectedRegistrations?.map(it => it.id),
        [selectedRegistrations]
    )

    return <QueryDataGrid
        queryOrRows={queryOrRows}
        treeData
        initialState={initialState}
        getTreeDataPath={getRegistrationTreePath}
        groupingColDef={groupingColDef}
        defaultGroupingExpansionDepth={1}
        rowSelectionModel={selectedIds}
        onRowSelectionModelChange={changeSelection}
        isRowSelectable={canSelect}
        disableRowSelectionOnClick
        {...props}
    />
}

const canSelect = ({ row }: GridRowParams<Registration>) => row.type === RegistrationType.Primary

export const getRegistrationTreePath = (
    reg: Registration
) => reg.type === RegistrationType.Primary ?
        [reg.id] :
        [reg.primaryRegistrationId, reg.id]

const groupingColDef = {
    headerName: "",
    valueGetter: () => "",
    width: 20,
}
