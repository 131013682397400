import { Button, Flex, Heading, TextField } from "@aws-amplify/ui-react"
import { Box } from "@mui/material"
import { FC, useState } from "react"
import { loginFormContainerStyle } from "src/login/login-form-container-style"
import { LoginLogo } from "src/login/LoginLogo"
import { StoredCredentials } from "src/login/stored-credentials"

export type TokenLoginFormProps = {
    back: () => void
}

export const TokenLoginForm: FC<TokenLoginFormProps> = ({
    back,
}) => {
    const [tokenString, setTokenString] = useState("")
    const accessToken = StoredCredentials.useAccessToken()

    const handleSubmit = () => accessToken.setValue(tokenString)

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && tokenString) {
            accessToken.setValue(tokenString)
        }
    }

    return <Box style={loginFormContainerStyle}>
        <LoginLogo />
        <div data-amplify-authenticator>
            <div data-amplify-container>
                <div data-amplify-router>
                    <div data-amplify-form>
                        <Flex direction="column">
                            <Heading level={3}>Log in</Heading>
                            <TextField
                                label="Token"
                                placeholder="Enter your token"
                                value={tokenString}
                                onChange={e => setTokenString(e.target.value)}
                                onKeyDown={handleKeyDown}
                                required
                            />
                            <Button
                                children="Log in"
                                variation="primary"
                                onClick={handleSubmit}
                                disabled={tokenString === ""}

                            />
                            <Flex justifyContent="center">
                                <Button
                                    children="Back to main login"
                                    onClick={back}
                                    variation='link'
                                    size='small'
                                    style={{ fontWeight: "normal" }}
                                />
                            </Flex>
                        </Flex>
                    </div>
                </div>
            </div>
        </div>
    </Box>
}