import { MpFeedItems } from "@marketpartner/react-feed"
import { FC, memo } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { LoadingSpinner } from "src/common/loading/LoadingSpinner"
import { FeedItem } from "src/feeds/items/FeedItem"
import { HierarchicalFeedItemComponent } from "src/feeds/items/HierarchicalFeedItem"
import { FeedDisplayMode, useFeedDisplayOptions } from "src/feeds/items/options/feed-display-context"

export type FeedItemListProps = {
    items: MpFeedItems
}

export const FeedItemList = memo<FeedItemListProps>(({
    items
}) => {

    return <InfiniteScroll
        loadMore={items.loadOlderItems}
        hasMore={items.hasOlderItems}
        useWindow={false}
        loader={<ScrollLoader key="Loader" />}
    >
        <Items items={items}/>
    </InfiniteScroll >
})

const Items: FC<FeedItemListProps> = ({
    items
}) => {
    const { displayMode } = useFeedDisplayOptions()

    if (displayMode === FeedDisplayMode.Threaded) {
        return <>
            {items.itemHierarchy
                .map(hItem => <HierarchicalFeedItemComponent
                    key={hItem.id}
                    hItem={hItem}
                />)}
        </>
    }

    return <>
        {items.itemList
            .map(item => <FeedItem
                key={item.id}
                item={item}
            />)}
    </>
}

const ScrollLoader: FC = () => <LoadingSpinner sx={{
    justifyContent: "center",
    margin: "50px 0 20%",
}} />