import { Link, LinkProps } from "@mui/material"
import { FC } from "react"
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'

export type HoverLinkProps = Partial<LinkProps> & Partial<RouterLinkProps> & {
    to: RouterLinkProps["to"]
    text: string
}

export const HoverLink: FC<HoverLinkProps> = ({
    sx,
    text,
    ...props
}) => {

    return <Link
        component={RouterLink}
        color="inherit"
        underline="none"
        sx={{
            ...sx,
            color: theme => theme.palette.action.disabled,
            "&:hover": {
                opacity: 1,
                color: theme => theme.palette.text.primary,
            },
        }}
        {...props}
    >
        {text}
    </Link>
}