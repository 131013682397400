import { Registration } from "@marketpartner/backend-api"
import { GridColDef } from "@mui/x-data-grid-pro"
import { FC, useMemo } from "react"
import { getDynamicColumnDefinitions } from "src/common/grid/dynamic-columns"
import { useRows } from "src/common/grid/QueryDataGrid"
import { BaseRegistrationsGrid, BaseRegistrationsGridProps } from "src/registrations/BaseRegistrationsGrid"
import { NameAndEmail } from "src/registrations/NameAndEmail"
import { RegistrationStatusIcon } from "src/registrations/RegistrationStatusIcon"


export type CopyRegistrationsGridProps = Omit<BaseRegistrationsGridProps, "columns"> & {
    sourceEventId: string
    sourceCategoryId: string
}

const ignoredColumns = ["title", "first_name", "last_name", "email"]

export const CopyRegistrationsGrid: FC<CopyRegistrationsGridProps> = ({
    sourceEventId,
    sourceCategoryId,
    queryOrRows,
    ...props
}) => {
    const registrations = useRows(queryOrRows)

    const columns = useMemo((): GridColDef<Registration>[] => [
        {
            field: "approvalState",
            headerName: " ",
            renderCell: ({ value }) => <RegistrationStatusIcon status={value} />,
            resizable: false,
            width: 44,
        },
        {
            field: "name",
            headerName: "Name",
            valueGetter: ({ row }) => `${row.lastName}_${row.firstName}`,
            renderCell: ({ row }) => <NameAndEmail registration={row} />,
            minWidth: 300,
        },
        ...getDynamicColumnDefinitions(registrations, "fields", {
            filter: (name) => !ignoredColumns.includes(name),
        }),
    ], [registrations])

    return <BaseRegistrationsGrid
        queryOrRows={queryOrRows}
        columns={columns}
        checkboxSelection
        disableColumnMenu
        {...props}
    />
}


