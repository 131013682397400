import { casualName, Named } from "@marketpartner/mp-common"
import { Avatar } from "@mui/material"
import { FC, MouseEvent } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { useEvent } from "src/events/event-context"
import { useLegacyNetworkingProfilePopup } from "src/legacy-networking/LegacyNetworkingProfilePopup"


export type LegacyNetworkingProfileAvatarProps = {
    namedEntity: Named
    registrationId?: string | null
    fallbackAvatarUrl?: string | null
    hideIfNoProfile?: boolean
    hideRegistrationLink?: boolean
}

export const LegacyNetworkingProfileAvatar: FC<LegacyNetworkingProfileAvatarProps> = ({
    namedEntity,
    registrationId,
    fallbackAvatarUrl,
    hideIfNoProfile = false,
    hideRegistrationLink,
}) => {
    const client = useClient()
    const event = useEvent()
    const profileRequest = backend.networking.useGetAll([client!.id, event!.id], {
        enabled: Boolean(client && event && registrationId),
        select: profiles => profiles.find(it => it.registrationId === registrationId),
    })
    const profilePopup = useLegacyNetworkingProfilePopup()
    const profile = profileRequest.data

    const handleClick = (e: MouseEvent) => {
        if (!profile) {
            return
        }
        e.stopPropagation()
        profilePopup.open({
            registrationId: profile.registrationId,
            hideRegistrationLink,
        })
    }

    if (!profile && hideIfNoProfile) {
        return <></>
    }

    return <Avatar
        src={profile?.avatarUrl ?? fallbackAvatarUrl ?? "none"}
        alt={casualName(profile ?? namedEntity)}
        onClick={handleClick}
        sx={{
            "&:hover": {
                cursor: profile ? "pointer" : undefined
            }
        }}
    />
}