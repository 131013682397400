import { EventSegment } from "@marketpartner/backend-api"
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { FC } from "react"
import { Bar } from "src/common/layout/Bar"
import { EventDateTimeText } from "src/common/time/DateTimeText"
import { DeleteSegmentButton } from "src/events/segments/DeleteSegmentButton"
import { EditSegmentButton } from "src/events/segments/EditSegmentButton"


export type EventSegmentsGridProps = {
    segments: EventSegment[]
}

export const EventSegmentsGrid: FC<EventSegmentsGridProps> = ({
    segments
}) => <Table stickyHeader>
        <TableHead>
            <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Start (UTC)</TableCell>
                <TableCell>End (UTC)</TableCell>
                <TableCell />
            </TableRow>
        </TableHead>
        <TableBody>
            {segments.length ?
                segments.map(segment => <SegmentRow key={segment.id} segment={segment} />) :
                <TableRow><TableCell colSpan={4}>No segments configured</TableCell></TableRow>
            }
        </TableBody>
    </Table>

type SegmentRowProps = {
    segment: EventSegment
}

const SegmentRow: FC<SegmentRowProps> = ({
    segment
}) => {
    return <TableRow>
        <TableCell>{segment.name}</TableCell>
        <TableCell>{segment.startTime ? <EventDateTimeText hideTimezone dateTime={segment.startTime} /> : "-"}</TableCell>
        <TableCell>{segment.endTime ? <EventDateTimeText hideTimezone dateTime={segment.endTime} /> : "-"}</TableCell>
        <TableCell>
            <Bar>
                <EditSegmentButton segment={segment} />
                <DeleteSegmentButton segment={segment} />
            </Bar>
        </TableCell>
    </TableRow>
}
