import { Box } from "@mui/system"
import { FC } from "react"
import logo from "src/common/mp-logo-white.svg"


export const LoginLogo: FC = () => <Box sx={{ display: 'flex', width: "100%" }}>
    <img src={logo} alt="" style={{
        width: '300px',
        height: '40px',
        margin: '20px auto',
    }} />
</Box>