type CountryWithCodes = {
  BbgCountry: string | undefined;
  BbgCountryCode: string | undefined;
  MpaCountry: string;
  MpaCode: string;
};
export const bbgCountryCodes: CountryWithCodes[] = [
  {
    "BbgCountry": "Afghanistan",
    "BbgCountryCode": "AF",
    "MpaCountry": "Afghanistan",
    "MpaCode": "Afghanistan"
  },
  {
    "BbgCountry": "Albania",
    "BbgCountryCode": "ALB",
    "MpaCountry": "Albania",
    "MpaCode": "Albania"
  },
  {
    "BbgCountry": "Algeria",
    "BbgCountryCode": "DZ",
    "MpaCountry": "Algeria",
    "MpaCode": "Algeria"
  },
  {
    "BbgCountry": undefined,
    "BbgCountryCode": undefined,
    "MpaCountry": "Alland Islands",
    "MpaCode": "Alland Islands"
  },
  {
    "BbgCountry": "American Samoa",
    "BbgCountryCode": "AS",
    "MpaCountry": "American Samoa",
    "MpaCode": "American Samoa"
  },
  {
    "BbgCountry": "Andorra",
    "BbgCountryCode": "AND",
    "MpaCountry": "Andorra",
    "MpaCode": "Andorra"
  },
  {
    "BbgCountry": "Angola",
    "BbgCountryCode": "AN",
    "MpaCountry": "Angola",
    "MpaCode": "Angola"
  },
  {
    "BbgCountry": "Anguilla",
    "BbgCountryCode": "AGU",
    "MpaCountry": "Anguilla",
    "MpaCode": "Anguilla"
  },
  {
    "BbgCountry": "Antigua",
    "BbgCountryCode": "AK",
    "MpaCountry": "Antigua and Barbuda",
    "MpaCode": "Antigua and Barbuda"
  },
  {
    "BbgCountry": "Argentina",
    "BbgCountryCode": "AR",
    "MpaCountry": "Argentina",
    "MpaCode": "Argentina"
  },
  {
    "BbgCountry": "Armenia",
    "BbgCountryCode": "ARM",
    "MpaCountry": "Armenia",
    "MpaCode": "Armenia"
  },
  {
    "BbgCountry": undefined,
    "BbgCountryCode": undefined,
    "MpaCountry": "Aruba",
    "MpaCode": "Aruba"
  },
  {
    "BbgCountry": "Australia",
    "BbgCountryCode": "AL",
    "MpaCountry": "Australia",
    "MpaCode": "Australia"
  },
  {
    "BbgCountry": "Austria",
    "BbgCountryCode": "A",
    "MpaCountry": "Austria",
    "MpaCode": "Austria"
  },
  {
    "BbgCountry": "Azerbaijan",
    "BbgCountryCode": "AZ",
    "MpaCountry": "Azerbaijan",
    "MpaCode": "Azerbaijan"
  },
  {
    "BbgCountry": "Bahamas",
    "BbgCountryCode": "BS",
    "MpaCountry": "Bahamas",
    "MpaCode": "Bahamas"
  },
  {
    "BbgCountry": "Bahrain",
    "BbgCountryCode": "BH",
    "MpaCountry": "Bahrain",
    "MpaCode": "Bahrain"
  },
  {
    "BbgCountry": "Bangladesh",
    "BbgCountryCode": "BJ",
    "MpaCountry": "Bangladesh",
    "MpaCode": "Bangladesh"
  },
  {
    "BbgCountry": "Barbados",
    "BbgCountryCode": "WB",
    "MpaCountry": "Barbados",
    "MpaCode": "Barbados"
  },
  {
    "BbgCountry": "Belarus",
    "BbgCountryCode": "BYE",
    "MpaCountry": "Belarus",
    "MpaCode": "Belarus"
  },
  {
    "BbgCountry": "Belgium",
    "BbgCountryCode": "B",
    "MpaCountry": "Belgium",
    "MpaCode": "Belgium"
  },
  {
    "BbgCountry": "Belize",
    "BbgCountryCode": "BZ",
    "MpaCountry": "Belize",
    "MpaCode": "Belize"
  },
  {
    "BbgCountry": "Benin",
    "BbgCountryCode": "BEN",
    "MpaCountry": "Benin",
    "MpaCode": "Benin"
  },
  {
    "BbgCountry": "Bermuda",
    "BbgCountryCode": "BMD",
    "MpaCountry": "Bermuda",
    "MpaCode": "Bermuda"
  },
  {
    "BbgCountry": "Bhutan",
    "BbgCountryCode": "BT",
    "MpaCountry": "Bhutan",
    "MpaCode": "Bhutan"
  },
  {
    "BbgCountry": "Bolivia",
    "BbgCountryCode": "BV",
    "MpaCountry": "Bolivia",
    "MpaCode": "Bolivia"
  },
  {
    "BbgCountry": "Bosnia",
    "BbgCountryCode": "BOS",
    "MpaCountry": "Bosnia and Herzegovina",
    "MpaCode": "Bosnia and Herzegovina"
  },
  {
    "BbgCountry": "Botswana",
    "BbgCountryCode": "BD",
    "MpaCountry": "Botswana",
    "MpaCode": "Botswana"
  },
  {
    "BbgCountry": "Brazil",
    "BbgCountryCode": "BR",
    "MpaCountry": "Brazil",
    "MpaCode": "Brazil"
  },
  {
    "BbgCountry": undefined,
    "BbgCountryCode": undefined,
    "MpaCountry": "British Indian Ocean Territory",
    "MpaCode": "British Indian Ocean Territory"
  },
  {
    "BbgCountry": "British Virgin Islands",
    "BbgCountryCode": "VB",
    "MpaCountry": "British Virgin Islands",
    "MpaCode": "British Virgin Islands"
  },
  {
    "BbgCountry": "Brunei",
    "BbgCountryCode": "BU",
    "MpaCountry": "Brunei Darussalam",
    "MpaCode": "Brunei Darussalam"
  },
  {
    "BbgCountry": "Bulgaria",
    "BbgCountryCode": "BG",
    "MpaCountry": "Bulgaria",
    "MpaCode": "Bulgaria"
  },
  {
    "BbgCountry": "Burkina Faso",
    "BbgCountryCode": "BKF",
    "MpaCountry": "Burkina Faso",
    "MpaCode": "Burkina Faso"
  },
  {
    "BbgCountry": "Burundi",
    "BbgCountryCode": "BUR",
    "MpaCountry": "Burundi",
    "MpaCode": "Burundi"
  },
  {
    "BbgCountry": "Cambodia",
    "BbgCountryCode": "CAM",
    "MpaCountry": "Cambodia",
    "MpaCode": "Cambodia"
  },
  {
    "BbgCountry": "Cameroon",
    "BbgCountryCode": "CMR",
    "MpaCountry": "Cameroon",
    "MpaCode": "Cameroon"
  },
  {
    "BbgCountry": "Canada",
    "BbgCountryCode": "CND",
    "MpaCountry": "Canada",
    "MpaCode": "Canada"
  },
  {
    "BbgCountry": "Cape Verde",
    "BbgCountryCode": "CV",
    "MpaCountry": "Cape Verde",
    "MpaCode": "Cape Verde"
  },
  {
    "BbgCountry": "Cayman Island",
    "BbgCountryCode": "CP",
    "MpaCountry": "Cayman Islands",
    "MpaCode": "Cayman Islands"
  },
  {
    "BbgCountry": "Central African Republic",
    "BbgCountryCode": "RC",
    "MpaCountry": "Central African Republic",
    "MpaCode": "Central African Republic"
  },
  {
    "BbgCountry": "Chad",
    "BbgCountryCode": "CF",
    "MpaCountry": "Chad",
    "MpaCode": "Chad"
  },
  {
    "BbgCountry": "Chile",
    "BbgCountryCode": "CK",
    "MpaCountry": "Chile",
    "MpaCode": "Chile"
  },
  {
    "BbgCountry": "China",
    "BbgCountryCode": "CN",
    "MpaCountry": "China",
    "MpaCode": "China"
  },
  {
    "BbgCountry": undefined,
    "BbgCountryCode": undefined,
    "MpaCountry": "Christmas Island",
    "MpaCode": "Christmas Island"
  },
  {
    "BbgCountry": undefined,
    "BbgCountryCode": undefined,
    "MpaCountry": "Cocos (Keeling) Islands",
    "MpaCode": "Cocos (Keeling) Islands"
  },
  {
    "BbgCountry": "Colombia",
    "BbgCountryCode": "CO",
    "MpaCountry": "Colombia",
    "MpaCode": "Colombia"
  },
  {
    "BbgCountry": "Comoros",
    "BbgCountryCode": "KM",
    "MpaCountry": "Comoros",
    "MpaCode": "Comoros"
  },
  {
    "BbgCountry": "Democratic Republic of Congo",
    "BbgCountryCode": "KG",
    "MpaCountry": "Congo, Democratic Republic of the",
    "MpaCode": "Congo, Democratic Republic of the"
  },
  {
    "BbgCountry": "Congo - Brazzaville",
    "BbgCountryCode": "CG",
    "MpaCountry": "Congo, Republic of the",
    "MpaCode": "Congo, Republic of the"
  },
  {
    "BbgCountry": undefined,
    "BbgCountryCode": undefined,
    "MpaCountry": "Cook Islands",
    "MpaCode": "Cook Islands"
  },
  {
    "BbgCountry": "Costa Rica",
    "BbgCountryCode": "CR",
    "MpaCountry": "Costa Rica",
    "MpaCode": "Costa Rica"
  },
  {
    "BbgCountry": "Ivory Coast",
    "BbgCountryCode": "CI",
    "MpaCountry": "Cote d'Ivoire",
    "MpaCode": "Cote d'Ivoire"
  },
  {
    "BbgCountry": "Croatia",
    "BbgCountryCode": "CRO",
    "MpaCountry": "Croatia",
    "MpaCode": "Croatia"
  },
  {
    "BbgCountry": "Cuba",
    "BbgCountryCode": "CU",
    "MpaCountry": "Cuba",
    "MpaCode": "Cuba"
  },
  {
    "BbgCountry": "Curacao",
    "BbgCountryCode": "CW",
    "MpaCountry": "Curacao",
    "MpaCode": "Curacao"
  },
  {
    "BbgCountry": "Cyprus",
    "BbgCountryCode": "CY",
    "MpaCountry": "Cyprus",
    "MpaCode": "Cyprus"
  },
  {
    "BbgCountry": "Czech Republic",
    "BbgCountryCode": "CC",
    "MpaCountry": "Czech Republic",
    "MpaCode": "Czech Republic"
  },
  {
    "BbgCountry": "Denmark",
    "BbgCountryCode": "DK",
    "MpaCountry": "Denmark",
    "MpaCode": "Denmark"
  },
  {
    "BbgCountry": "Djibouti",
    "BbgCountryCode": "DJ",
    "MpaCountry": "Djibouti",
    "MpaCode": "Djibouti"
  },
  {
    "BbgCountry": "Dominica",
    "BbgCountryCode": "DMC",
    "MpaCountry": "Dominica",
    "MpaCode": "Dominica"
  },
  {
    "BbgCountry": "Dominican Republic",
    "BbgCountryCode": "DR",
    "MpaCountry": "Dominican Republic",
    "MpaCode": "Dominican Republic"
  },
  {
    "BbgCountry": "Ecuador",
    "BbgCountryCode": "ED",
    "MpaCountry": "Ecuador",
    "MpaCode": "Ecuador"
  },
  {
    "BbgCountry": "Egypt",
    "BbgCountryCode": "UN",
    "MpaCountry": "Egypt",
    "MpaCode": "Egypt"
  },
  {
    "BbgCountry": "El Salvador",
    "BbgCountryCode": "SR",
    "MpaCountry": "El Salvador",
    "MpaCode": "El Salvador"
  },
  {
    "BbgCountry": "Equatorial Guinea",
    "BbgCountryCode": "GQ",
    "MpaCountry": "Equatorial Guinea",
    "MpaCode": "Equatorial Guinea"
  },
  {
    "BbgCountry": "Eritrea",
    "BbgCountryCode": "ER",
    "MpaCountry": "Eritrea",
    "MpaCode": "Eritrea"
  },
  {
    "BbgCountry": "Estonia",
    "BbgCountryCode": "EST",
    "MpaCountry": "Estonia",
    "MpaCode": "Estonia"
  },
  {
    "BbgCountry": "Ethiopia",
    "BbgCountryCode": "ET",
    "MpaCountry": "Ethiopia",
    "MpaCode": "Ethiopia"
  },
  {
    "BbgCountry": "Falkland Islands",
    "BbgCountryCode": "FK",
    "MpaCountry": "Falkland Islands (Malvinas)",
    "MpaCode": "Falkland Islands (Malvinas)"
  },
  {
    "BbgCountry": "Faroe Islands",
    "BbgCountryCode": "FO",
    "MpaCountry": "Faroe Islands",
    "MpaCode": "Faroe Islands"
  },
  {
    "BbgCountry": "Fiji",
    "BbgCountryCode": "FJ",
    "MpaCountry": "Fiji",
    "MpaCode": "Fiji"
  },
  {
    "BbgCountry": "Finland",
    "BbgCountryCode": "SF",
    "MpaCountry": "Finland",
    "MpaCode": "Finland"
  },
  {
    "BbgCountry": "France",
    "BbgCountryCode": "F",
    "MpaCountry": "France",
    "MpaCode": "France"
  },
  {
    "BbgCountry": "French Guiana",
    "BbgCountryCode": "FG",
    "MpaCountry": "French Guiana",
    "MpaCode": "French Guiana"
  },
  {
    "BbgCountry": "French Polynesia",
    "BbgCountryCode": "FP",
    "MpaCountry": "French Polynesia",
    "MpaCode": "French Polynesia"
  },
  {
    "BbgCountry": "Gabon",
    "BbgCountryCode": "GO",
    "MpaCountry": "Gabon",
    "MpaCode": "Gabon"
  },
  {
    "BbgCountry": "Gambia",
    "BbgCountryCode": "GMB",
    "MpaCountry": "Gambia",
    "MpaCode": "Gambia"
  },
  {
    "BbgCountry": "Georgia",
    "BbgCountryCode": "GE",
    "MpaCountry": "Georgia",
    "MpaCode": "Georgia"
  },
  {
    "BbgCountry": "Germany",
    "BbgCountryCode": "D",
    "MpaCountry": "Germany",
    "MpaCode": "Germany"
  },
  {
    "BbgCountry": "Republic of Ghana",
    "BbgCountryCode": "GH",
    "MpaCountry": "Ghana",
    "MpaCode": "Ghana"
  },
  {
    "BbgCountry": "Gibraltar",
    "BbgCountryCode": "GK",
    "MpaCountry": "Gibraltar",
    "MpaCode": "Gibraltar"
  },
  {
    "BbgCountry": "Greece",
    "BbgCountryCode": "GR",
    "MpaCountry": "Greece",
    "MpaCode": "Greece"
  },
  {
    "BbgCountry": "Greenland",
    "BbgCountryCode": "GD",
    "MpaCountry": "Greenland",
    "MpaCode": "Greenland"
  },
  {
    "BbgCountry": "Grenada",
    "BbgCountryCode": "GA",
    "MpaCountry": "Grenada",
    "MpaCode": "Grenada"
  },
  {
    "BbgCountry": "Guadeloupe",
    "BbgCountryCode": "GL",
    "MpaCountry": "Guadeloupe",
    "MpaCode": "Guadeloupe"
  },
  {
    "BbgCountry": "Guam",
    "BbgCountryCode": "GM",
    "MpaCountry": "Guam",
    "MpaCode": "Guam"
  },
  {
    "BbgCountry": "Guatemala",
    "BbgCountryCode": "GU",
    "MpaCountry": "Guatemala",
    "MpaCode": "Guatemala"
  },
  {
    "BbgCountry": "Guernsey, Channel Islands",
    "BbgCountryCode": "GBG",
    "MpaCountry": "Guernsey",
    "MpaCode": "Guernsey"
  },
  {
    "BbgCountry": "Guinea",
    "BbgCountryCode": "GN",
    "MpaCountry": "Guinea",
    "MpaCode": "Guinea"
  },
  {
    "BbgCountry": "Guinea-Bissau",
    "BbgCountryCode": "GW",
    "MpaCountry": "Guinea-Bissau",
    "MpaCode": "Guinea-Bissau"
  },
  {
    "BbgCountry": "Guyana",
    "BbgCountryCode": "GY",
    "MpaCountry": "Guyana",
    "MpaCode": "Guyana"
  },
  {
    "BbgCountry": "Haiti",
    "BbgCountryCode": "HI",
    "MpaCountry": "Haiti",
    "MpaCode": "Haiti"
  },
  {
    "BbgCountry": "Vatican City",
    "BbgCountryCode": "VA",
    "MpaCountry": "Holy See (Vatican City State)",
    "MpaCode": "Holy See (Vatican City State)"
  },
  {
    "BbgCountry": "Honduras",
    "BbgCountryCode": "HO",
    "MpaCountry": "Honduras",
    "MpaCode": "Honduras"
  },
  {
    "BbgCountry": "Hong Kong",
    "BbgCountryCode": "HK",
    "MpaCountry": "Hong Kong",
    "MpaCode": "Hong Kong"
  },
  {
    "BbgCountry": "Hong Kong",
    "BbgCountryCode": "HK",
    "MpaCountry": "Hong Kong, China",
    "MpaCode": "Hong Kong, China"
  },
  {
    "BbgCountry": "Hungary",
    "BbgCountryCode": "HY",
    "MpaCountry": "Hungary",
    "MpaCode": "Hungary"
  },
  {
    "BbgCountry": "Iceland",
    "BbgCountryCode": "IC",
    "MpaCountry": "Iceland",
    "MpaCode": "Iceland"
  },
  {
    "BbgCountry": "India",
    "BbgCountryCode": "IN",
    "MpaCountry": "India",
    "MpaCode": "India"
  },
  {
    "BbgCountry": "Indonesia",
    "BbgCountryCode": "IA",
    "MpaCountry": "Indonesia",
    "MpaCode": "Indonesia"
  },
  {
    "BbgCountry": "Iran",
    "BbgCountryCode": "IR",
    "MpaCountry": "Iran, Islamic Republic of",
    "MpaCode": "Iran, Islamic Republic of"
  },
  {
    "BbgCountry": "Iraq",
    "BbgCountryCode": "IQ",
    "MpaCountry": "Iraq",
    "MpaCode": "Iraq"
  },
  {
    "BbgCountry": "Ireland",
    "BbgCountryCode": "IRL",
    "MpaCountry": "Ireland",
    "MpaCode": "Ireland"
  },
  {
    "BbgCountry": undefined,
    "BbgCountryCode": undefined,
    "MpaCountry": "Isle of Man",
    "MpaCode": "Isle of Man"
  },
  {
    "BbgCountry": "Israel",
    "BbgCountryCode": "IS",
    "MpaCountry": "Israel",
    "MpaCode": "Israel"
  },
  {
    "BbgCountry": "Italy",
    "BbgCountryCode": "I",
    "MpaCountry": "Italy",
    "MpaCode": "Italy"
  },
  {
    "BbgCountry": "Jamaica",
    "BbgCountryCode": "JA",
    "MpaCountry": "Jamaica",
    "MpaCode": "Jamaica"
  },
  {
    "BbgCountry": "Japan",
    "BbgCountryCode": "J",
    "MpaCountry": "Japan",
    "MpaCode": "Japan"
  },
  {
    "BbgCountry": "Jersey, Channel Islands",
    "BbgCountryCode": "GBJ",
    "MpaCountry": "Jersey",
    "MpaCode": "Jersey"
  },
  {
    "BbgCountry": "Jordan",
    "BbgCountryCode": "JO",
    "MpaCountry": "Jordan",
    "MpaCode": "Jordan"
  },
  {
    "BbgCountry": "Kazakhstan",
    "BbgCountryCode": "KAZ",
    "MpaCountry": "Kazakhstan",
    "MpaCode": "Kazakhstan"
  },
  {
    "BbgCountry": "Kenya",
    "BbgCountryCode": "KE",
    "MpaCountry": "Kenya",
    "MpaCode": "Kenya"
  },
  {
    "BbgCountry": undefined,
    "BbgCountryCode": undefined,
    "MpaCountry": "Kiribati",
    "MpaCode": "Kiribati"
  },
  {
    "BbgCountry": "North Korea",
    "BbgCountryCode": "KP",
    "MpaCountry": "Korea, Democratic People's Republic of",
    "MpaCode": "Korea, Democratic People's Republic of"
  },
  {
    "BbgCountry": "South Korea",
    "BbgCountryCode": "KS",
    "MpaCountry": "Korea, Republic of",
    "MpaCode": "Korea, Republic of"
  },
  {
    "BbgCountry": "Kosovo",
    "BbgCountryCode": "KV",
    "MpaCountry": "Kosovo",
    "MpaCode": "Kosovo"
  },
  {
    "BbgCountry": "Kuwait",
    "BbgCountryCode": "K",
    "MpaCountry": "Kuwait",
    "MpaCode": "Kuwait"
  },
  {
    "BbgCountry": "Kyrgyzstan",
    "BbgCountryCode": "KY",
    "MpaCountry": "Kyrgyzstan",
    "MpaCode": "Kyrgyzstan"
  },
  {
    "BbgCountry": "Laos",
    "BbgCountryCode": "LS",
    "MpaCountry": "Lao People's Democratic Republic",
    "MpaCode": "Lao People's Democratic Republic"
  },
  {
    "BbgCountry": "Latvia",
    "BbgCountryCode": "LAT",
    "MpaCountry": "Latvia",
    "MpaCode": "Latvia"
  },
  {
    "BbgCountry": "Lebanon",
    "BbgCountryCode": "LE",
    "MpaCountry": "Lebanon",
    "MpaCode": "Lebanon"
  },
  {
    "BbgCountry": "Lesotho",
    "BbgCountryCode": "LST",
    "MpaCountry": "Lesotho",
    "MpaCode": "Lesotho"
  },
  {
    "BbgCountry": "Liberia",
    "BbgCountryCode": "LI",
    "MpaCountry": "Liberia",
    "MpaCode": "Liberia"
  },
  {
    "BbgCountry": "Libya",
    "BbgCountryCode": "LY",
    "MpaCountry": "Libya",
    "MpaCode": "Libya"
  },
  {
    "BbgCountry": "Liechtenstein",
    "BbgCountryCode": "LIE",
    "MpaCountry": "Liechtenstein",
    "MpaCode": "Liechtenstein"
  },
  {
    "BbgCountry": "Lithuania",
    "BbgCountryCode": "LIT",
    "MpaCountry": "Lithuania",
    "MpaCode": "Lithuania"
  },
  {
    "BbgCountry": "Luxembourg",
    "BbgCountryCode": "L",
    "MpaCountry": "Luxembourg",
    "MpaCode": "Luxembourg"
  },
  {
    "BbgCountry": "Macau",
    "BbgCountryCode": "OM",
    "MpaCountry": "Macao",
    "MpaCode": "Macao"
  },
  {
    "BbgCountry": "Macedonia",
    "BbgCountryCode": "MAC",
    "MpaCountry": "Macedonia, the Former Yugoslav Republic of",
    "MpaCode": "Macedonia, the Former Yugoslav Republic of"
  },
  {
    "BbgCountry": "Madagascar",
    "BbgCountryCode": "MG",
    "MpaCountry": "Madagascar",
    "MpaCode": "Madagascar"
  },
  {
    "BbgCountry": "Malawi",
    "BbgCountryCode": "MI",
    "MpaCountry": "Malawi",
    "MpaCode": "Malawi"
  },
  {
    "BbgCountry": "Malaysia",
    "BbgCountryCode": "MA",
    "MpaCountry": "Malaysia",
    "MpaCode": "Malaysia"
  },
  {
    "BbgCountry": "Maldives",
    "BbgCountryCode": "MV",
    "MpaCountry": "Maldives",
    "MpaCode": "Maldives"
  },
  {
    "BbgCountry": "Mali",
    "BbgCountryCode": "ML",
    "MpaCountry": "Mali",
    "MpaCode": "Mali"
  },
  {
    "BbgCountry": "Malta",
    "BbgCountryCode": "MW",
    "MpaCountry": "Malta",
    "MpaCode": "Malta"
  },
  {
    "BbgCountry": "Marshall Islands",
    "BbgCountryCode": "MS",
    "MpaCountry": "Marshall Islands",
    "MpaCode": "Marshall Islands"
  },
  {
    "BbgCountry": "Martinique",
    "BbgCountryCode": "MR",
    "MpaCountry": "Martinique",
    "MpaCode": "Martinique"
  },
  {
    "BbgCountry": "Mauritania",
    "BbgCountryCode": "MAU",
    "MpaCountry": "Mauritania",
    "MpaCode": "Mauritania"
  },
  {
    "BbgCountry": "Mauritius",
    "BbgCountryCode": "IW",
    "MpaCountry": "Mauritius",
    "MpaCode": "Mauritius"
  },
  {
    "BbgCountry": undefined,
    "BbgCountryCode": undefined,
    "MpaCountry": "Mayotte",
    "MpaCode": "Mayotte"
  },
  {
    "BbgCountry": "Mexico",
    "BbgCountryCode": "MX",
    "MpaCountry": "Mexico",
    "MpaCode": "Mexico"
  },
  {
    "BbgCountry": "Micronesia",
    "BbgCountryCode": "FM",
    "MpaCountry": "Micronesia, Federated States of",
    "MpaCode": "Micronesia, Federated States of"
  },
  {
    "BbgCountry": "Moldova",
    "BbgCountryCode": "MOL",
    "MpaCountry": "Moldova, Republic of",
    "MpaCode": "Moldova, Republic of"
  },
  {
    "BbgCountry": "Monaco",
    "BbgCountryCode": "MC",
    "MpaCountry": "Monaco",
    "MpaCode": "Monaco"
  },
  {
    "BbgCountry": "Mongolia",
    "BbgCountryCode": "MH",
    "MpaCountry": "Mongolia",
    "MpaCode": "Mongolia"
  },
  {
    "BbgCountry": "Montenegro",
    "BbgCountryCode": "MON",
    "MpaCountry": "Montenegro",
    "MpaCode": "Montenegro"
  },
  {
    "BbgCountry": "Montserrat",
    "BbgCountryCode": "MK",
    "MpaCountry": "Montserrat",
    "MpaCode": "Montserrat"
  },
  {
    "BbgCountry": "Morocco",
    "BbgCountryCode": "MZ",
    "MpaCountry": "Morocco",
    "MpaCode": "Morocco"
  },
  {
    "BbgCountry": "Mozambique",
    "BbgCountryCode": "MO",
    "MpaCountry": "Mozambique",
    "MpaCode": "Mozambique"
  },
  {
    "BbgCountry": "Myanmar",
    "BbgCountryCode": "MY",
    "MpaCountry": "Myanmar",
    "MpaCode": "Myanmar"
  },
  {
    "BbgCountry": "Namibia",
    "BbgCountryCode": "WK",
    "MpaCountry": "Namibia",
    "MpaCode": "Namibia"
  },
  {
    "BbgCountry": undefined,
    "BbgCountryCode": undefined,
    "MpaCountry": "Nauru",
    "MpaCode": "Nauru"
  },
  {
    "BbgCountry": "Nepal",
    "BbgCountryCode": "NP",
    "MpaCountry": "Nepal",
    "MpaCode": "Nepal"
  },
  {
    "BbgCountry": "Netherlands",
    "BbgCountryCode": "NL",
    "MpaCountry": "Netherlands",
    "MpaCode": "Netherlands"
  },
  {
    "BbgCountry": "New Caledonia",
    "BbgCountryCode": "NM",
    "MpaCountry": "New Caledonia",
    "MpaCode": "New Caledonia"
  },
  {
    "BbgCountry": "New Zealand",
    "BbgCountryCode": "NZ",
    "MpaCountry": "New Zealand",
    "MpaCode": "New Zealand"
  },
  {
    "BbgCountry": "Nicaragua",
    "BbgCountryCode": "NU",
    "MpaCountry": "Nicaragua",
    "MpaCode": "Nicaragua"
  },
  {
    "BbgCountry": "Niger",
    "BbgCountryCode": "NGR",
    "MpaCountry": "Niger",
    "MpaCode": "Niger"
  },
  {
    "BbgCountry": "Nigeria",
    "BbgCountryCode": "NG",
    "MpaCountry": "Nigeria",
    "MpaCode": "Nigeria"
  },
  {
    "BbgCountry": undefined,
    "BbgCountryCode": undefined,
    "MpaCountry": "Niue",
    "MpaCode": "Niue"
  },
  {
    "BbgCountry": undefined,
    "BbgCountryCode": undefined,
    "MpaCountry": "Norfolk Island",
    "MpaCode": "Norfolk Island"
  },
  {
    "BbgCountry": "Northern Mariana Islands",
    "BbgCountryCode": "NMI",
    "MpaCountry": "Northern Mariana Islands",
    "MpaCode": "Northern Mariana Islands"
  },
  {
    "BbgCountry": "Norway",
    "BbgCountryCode": "N",
    "MpaCountry": "Norway",
    "MpaCode": "Norway"
  },
  {
    "BbgCountry": "Oman",
    "BbgCountryCode": "MB",
    "MpaCountry": "Oman",
    "MpaCode": "Oman"
  },
  {
    "BbgCountry": "Pakistan",
    "BbgCountryCode": "PK",
    "MpaCountry": "Pakistan",
    "MpaCode": "Pakistan"
  },
  {
    "BbgCountry": "Palau",
    "BbgCountryCode": "KF",
    "MpaCountry": "Palau",
    "MpaCode": "Palau"
  },
  {
    "BbgCountry": undefined,
    "BbgCountryCode": undefined,
    "MpaCountry": "Palestine, State of",
    "MpaCode": "Palestine, State of"
  },
  {
    "BbgCountry": "Panama",
    "BbgCountryCode": "PA",
    "MpaCountry": "Panama",
    "MpaCode": "Panama"
  },
  {
    "BbgCountry": "Papua New Guinea",
    "BbgCountryCode": "PNG",
    "MpaCountry": "Papua New Guinea",
    "MpaCode": "Papua New Guinea"
  },
  {
    "BbgCountry": "Paraguay",
    "BbgCountryCode": "PY",
    "MpaCountry": "Paraguay",
    "MpaCode": "Paraguay"
  },
  {
    "BbgCountry": "Peru",
    "BbgCountryCode": "PE",
    "MpaCountry": "Peru",
    "MpaCode": "Peru"
  },
  {
    "BbgCountry": "Philippines",
    "BbgCountryCode": "PH",
    "MpaCountry": "Philippines",
    "MpaCode": "Philippines"
  },
  {
    "BbgCountry": "Poland",
    "BbgCountryCode": "PL",
    "MpaCountry": "Poland",
    "MpaCode": "Poland"
  },
  {
    "BbgCountry": "Portugal",
    "BbgCountryCode": "P",
    "MpaCountry": "Portugal",
    "MpaCode": "Portugal"
  },
  {
    "BbgCountry": undefined,
    "BbgCountryCode": undefined,
    "MpaCountry": "Puerto Rico",
    "MpaCode": "Puerto Rico"
  },
  {
    "BbgCountry": "Qatar",
    "BbgCountryCode": "DH",
    "MpaCountry": "Qatar",
    "MpaCode": "Qatar"
  },
  {
    "BbgCountry": "Reunion Island",
    "BbgCountryCode": "REU",
    "MpaCountry": "Reunion",
    "MpaCode": "Reunion"
  },
  {
    "BbgCountry": "Romania",
    "BbgCountryCode": "RU",
    "MpaCountry": "Romania",
    "MpaCode": "Romania"
  },
  {
    "BbgCountry": "Russia",
    "BbgCountryCode": "SU",
    "MpaCountry": "Russian Federation",
    "MpaCode": "Russian Federation"
  },
  {
    "BbgCountry": "Rwanda",
    "BbgCountryCode": "RW",
    "MpaCountry": "Rwanda",
    "MpaCode": "Rwanda"
  },
  {
    "BbgCountry": "Saint Barthelemy",
    "BbgCountryCode": "BL",
    "MpaCountry": "Saint Barthelemy",
    "MpaCode": "Saint Barthelemy"
  },
  {
    "BbgCountry": undefined,
    "BbgCountryCode": undefined,
    "MpaCountry": "Saint Helena",
    "MpaCode": "Saint Helena"
  },
  {
    "BbgCountry": "Saint Kitts and Nevis",
    "BbgCountryCode": "KC",
    "MpaCountry": "Saint Kitts and Nevis",
    "MpaCode": "Saint Kitts and Nevis"
  },
  {
    "BbgCountry": "Saint Lucia",
    "BbgCountryCode": "LC",
    "MpaCountry": "Saint Lucia",
    "MpaCode": "Saint Lucia"
  },
  {
    "BbgCountry": undefined,
    "BbgCountryCode": undefined,
    "MpaCountry": "Saint Martin (French part)",
    "MpaCode": "Saint Martin (French part)"
  },
  {
    "BbgCountry": "St. Pierre and Miquelon",
    "BbgCountryCode": "QN",
    "MpaCountry": "Saint Pierre and Miquelon",
    "MpaCode": "Saint Pierre and Miquelon"
  },
  {
    "BbgCountry": "Saint Vincent and the Grenadines",
    "BbgCountryCode": "VQ",
    "MpaCountry": "Saint Vincent and the Grenadines",
    "MpaCode": "Saint Vincent and the Grenadines"
  },
  {
    "BbgCountry": "Samoa (Western)",
    "BbgCountryCode": "SX",
    "MpaCountry": "Samoa",
    "MpaCode": "Samoa"
  },
  {
    "BbgCountry": "San Marino",
    "BbgCountryCode": "SM",
    "MpaCountry": "San Marino",
    "MpaCode": "San Marino"
  },
  {
    "BbgCountry": "Sao Tome and Principe",
    "BbgCountryCode": "ST",
    "MpaCountry": "Sao Tome and Principe",
    "MpaCode": "Sao Tome and Principe"
  },
  {
    "BbgCountry": "Saudi Arabia",
    "BbgCountryCode": "SA",
    "MpaCountry": "Saudi Arabia",
    "MpaCode": "Saudi Arabia"
  },
  {
    "BbgCountry": "Senegal",
    "BbgCountryCode": "SG",
    "MpaCountry": "Senegal",
    "MpaCode": "Senegal"
  },
  {
    "BbgCountry": "Serbia",
    "BbgCountryCode": "SER",
    "MpaCountry": "Serbia",
    "MpaCode": "Serbia"
  },
  {
    "BbgCountry": "Seychelles",
    "BbgCountryCode": "SEY",
    "MpaCountry": "Seychelles",
    "MpaCode": "Seychelles"
  },
  {
    "BbgCountry": "Sierra-Leone",
    "BbgCountryCode": "SRL",
    "MpaCountry": "Sierra Leone",
    "MpaCode": "Sierra Leone"
  },
  {
    "BbgCountry": "Singapore",
    "BbgCountryCode": "SP",
    "MpaCountry": "Singapore",
    "MpaCode": "Singapore"
  },
  {
    "BbgCountry": "Sint Maarten",
    "BbgCountryCode": "SXM",
    "MpaCountry": "Sint Maarten (Dutch part)",
    "MpaCode": "Sint Maarten (Dutch part)"
  },
  {
    "BbgCountry": "Slovakia",
    "BbgCountryCode": "SLK",
    "MpaCountry": "Slovakia",
    "MpaCode": "Slovakia"
  },
  {
    "BbgCountry": "Slovenia",
    "BbgCountryCode": "SLN",
    "MpaCountry": "Slovenia",
    "MpaCode": "Slovenia"
  },
  {
    "BbgCountry": "Solomon Islands",
    "BbgCountryCode": "HQ",
    "MpaCountry": "Solomon Islands",
    "MpaCode": "Solomon Islands"
  },
  {
    "BbgCountry": "Somalia",
    "BbgCountryCode": "SO",
    "MpaCountry": "Somalia",
    "MpaCode": "Somalia"
  },
  {
    "BbgCountry": "South Africa",
    "BbgCountryCode": "SOA",
    "MpaCountry": "South Africa",
    "MpaCode": "South Africa"
  },
  {
    "BbgCountry": "South Sudan",
    "BbgCountryCode": "SS",
    "MpaCountry": "South Sudan",
    "MpaCode": "South Sudan"
  },
  {
    "BbgCountry": "Spain",
    "BbgCountryCode": "E",
    "MpaCountry": "Spain",
    "MpaCode": "Spain"
  },
  {
    "BbgCountry": "Sri Lanka",
    "BbgCountryCode": "CE",
    "MpaCountry": "Sri Lanka",
    "MpaCode": "Sri Lanka"
  },
  {
    "BbgCountry": "Sudan",
    "BbgCountryCode": "SD",
    "MpaCountry": "Sudan",
    "MpaCode": "Sudan"
  },
  {
    "BbgCountry": "Suriname",
    "BbgCountryCode": "SUR",
    "MpaCountry": "Suriname",
    "MpaCode": "Suriname"
  },
  {
    "BbgCountry": undefined,
    "BbgCountryCode": undefined,
    "MpaCountry": "Svalbard and Jan Mayen",
    "MpaCode": "Svalbard and Jan Mayen"
  },
  {
    "BbgCountry": "Eswatini",
    "BbgCountryCode": "SWZ",
    "MpaCountry": "Swaziland",
    "MpaCode": "Swaziland"
  },
  {
    "BbgCountry": "Sweden",
    "BbgCountryCode": "S",
    "MpaCountry": "Sweden",
    "MpaCode": "Sweden"
  },
  {
    "BbgCountry": "Switzerland",
    "BbgCountryCode": "CH",
    "MpaCountry": "Switzerland",
    "MpaCode": "Switzerland"
  },
  {
    "BbgCountry": "Syria",
    "BbgCountryCode": "SY",
    "MpaCountry": "Syrian Arab Republic",
    "MpaCode": "Syrian Arab Republic"
  },
  {
    "BbgCountry": "Taiwan",
    "BbgCountryCode": "TI",
    "MpaCountry": "Taiwan, Republic of China",
    "MpaCode": "Taiwan, Republic of China"
  },
  {
    "BbgCountry": "Tajikistan",
    "BbgCountryCode": "TJ",
    "MpaCountry": "Tajikistan",
    "MpaCode": "Tajikistan"
  },
  {
    "BbgCountry": "Thailand",
    "BbgCountryCode": "TH",
    "MpaCountry": "Thailand",
    "MpaCode": "Thailand"
  },
  {
    "BbgCountry": "East Timor",
    "BbgCountryCode": "TP",
    "MpaCountry": "Timor-Leste",
    "MpaCode": "Timor-Leste"
  },
  {
    "BbgCountry": "Togo",
    "BbgCountryCode": "TG",
    "MpaCountry": "Togo",
    "MpaCode": "Togo"
  },
  {
    "BbgCountry": undefined,
    "BbgCountryCode": undefined,
    "MpaCountry": "Tokelau",
    "MpaCode": "Tokelau"
  },
  {
    "BbgCountry": "Tonga",
    "BbgCountryCode": "TS",
    "MpaCountry": "Tonga",
    "MpaCode": "Tonga"
  },
  {
    "BbgCountry": "Trinidad And Tobago",
    "BbgCountryCode": "WG",
    "MpaCountry": "Trinidad and Tobago",
    "MpaCode": "Trinidad and Tobago"
  },
  {
    "BbgCountry": "Tunisia",
    "BbgCountryCode": "TN",
    "MpaCountry": "Tunisia",
    "MpaCode": "Tunisia"
  },
  {
    "BbgCountry": "Turkey",
    "BbgCountryCode": "TR",
    "MpaCountry": "Turkey",
    "MpaCode": "Turkey"
  },
  {
    "BbgCountry": "Turkmenistan",
    "BbgCountryCode": "TM",
    "MpaCountry": "Turkmenistan",
    "MpaCode": "Turkmenistan"
  },
  {
    "BbgCountry": "Turks And Caicos",
    "BbgCountryCode": "TCA",
    "MpaCountry": "Turks and Caicos Islands",
    "MpaCode": "Turks and Caicos Islands"
  },
  {
    "BbgCountry": undefined,
    "BbgCountryCode": undefined,
    "MpaCountry": "Tuvalu",
    "MpaCode": "Tuvalu"
  },
  {
    "BbgCountry": "Uganda",
    "BbgCountryCode": "UG",
    "MpaCountry": "Uganda",
    "MpaCode": "Uganda"
  },
  {
    "BbgCountry": "Ukraine",
    "BbgCountryCode": "UKR",
    "MpaCountry": "Ukraine",
    "MpaCode": "Ukraine"
  },
  {
    "BbgCountry": "United Arab Emirates",
    "BbgCountryCode": "EM",
    "MpaCountry": "United Arab Emirates",
    "MpaCode": "United Arab Emirates"
  },
  {
    "BbgCountry": "United Kingdom",
    "BbgCountryCode": "BI",
    "MpaCountry": "United Kingdom",
    "MpaCode": "United Kingdom"
  },
  {
    "BbgCountry": "Tanzania",
    "BbgCountryCode": "TA",
    "MpaCountry": "United Republic of Tanzania",
    "MpaCode": "United Republic of Tanzania"
  },
  {
    "BbgCountry": "United States",
    "BbgCountryCode": "US",
    "MpaCountry": "United States",
    "MpaCode": "United States"
  },
  {
    "BbgCountry": "Uruguay",
    "BbgCountryCode": "UY",
    "MpaCountry": "Uruguay",
    "MpaCode": "Uruguay"
  },
  {
    "BbgCountry": undefined,
    "BbgCountryCode": undefined,
    "MpaCountry": "US Virgin Islands",
    "MpaCode": "US Virgin Islands"
  },
  {
    "BbgCountry": "Uzbekistan",
    "BbgCountryCode": "UZB",
    "MpaCountry": "Uzbekistan",
    "MpaCode": "Uzbekistan"
  },
  {
    "BbgCountry": "Vanuatu",
    "BbgCountryCode": "VTU",
    "MpaCountry": "Vanuatu",
    "MpaCode": "Vanuatu"
  },
  {
    "BbgCountry": "Venezuela",
    "BbgCountryCode": "VC",
    "MpaCountry": "Venezuela",
    "MpaCode": "Venezuela"
  },
  {
    "BbgCountry": "Vietnam",
    "BbgCountryCode": "VT",
    "MpaCountry": "Vietnam",
    "MpaCode": "Vietnam"
  },
  {
    "BbgCountry": undefined,
    "BbgCountryCode": undefined,
    "MpaCountry": "Wallis and Futuna",
    "MpaCode": "Wallis and Futuna"
  },
  {
    "BbgCountry": undefined,
    "BbgCountryCode": undefined,
    "MpaCountry": "Western Sahara",
    "MpaCode": "Western Sahara"
  },
  {
    "BbgCountry": "Yemen",
    "BbgCountryCode": "YE",
    "MpaCountry": "Yemen",
    "MpaCode": "Yemen"
  },
  {
    "BbgCountry": "Zambia",
    "BbgCountryCode": "ZA",
    "MpaCountry": "Zambia",
    "MpaCode": "Zambia"
  },
  {
    "BbgCountry": "Zimbabwe",
    "BbgCountryCode": "ZW",
    "MpaCountry": "Zimbabwe",
    "MpaCode": "Zimbabwe"
  }
]
