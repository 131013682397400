import { splitActivityId } from "@marketpartner/backend-api"
import { Stack, SxProps, Typography } from "@mui/material"
import { FC } from "react"

export type ActivityNameProps = {
    activityId: string
    hideSource?: boolean
    sx?: SxProps
}

export const ActivityName: FC<ActivityNameProps> = ({
    activityId,
    hideSource = false,
    sx,
}) => {
    const { category, name, source } = splitActivityId(activityId)
    return <Stack sx={sx}>
        <Typography>
            {category} {name}
        </Typography>
        {source && !hideSource && <Typography variant="body2" color="text.secondary">
            {source}
        </Typography>}
    </Stack>
}