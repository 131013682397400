import { ThumbUp } from "@mui/icons-material"
import { useTheme } from "@mui/material"
import { memo } from "react"
import { Bar } from "src/common/layout/Bar"

export type FeedItemLikesProps = {
    likeCount: number
}

export const FeedItemLikes = memo<FeedItemLikesProps>(({
    likeCount
}) => {
    const theme = useTheme()
    const color = likeCount === 0 ?
        theme.palette.action.disabled :
        theme.palette.text.secondary

    return <Bar spacing={0.5} color={color}>
        <ThumbUp fontSize="inherit" />
        <span>{likeCount}</span>
    </Bar>
})