import { Scope } from "@marketpartner/backend-api"
import { DialogTitle } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog"
import { createPopupHook } from "src/common/dialogs/Popup"
import { Loading } from "src/common/loading/Loading"
import { useEvent } from "src/events/event-context"
import { LegacyNetworkingProfileContent } from "src/legacy-networking/LegacyNetworkingProfileContent"

type LegacyNetworkingProfilePopupProps = LockableDialogProps & {
    registrationId: string
    hideRegistrationLink?: boolean
}

const LegacyNetworkingProfilePopup: FC<LegacyNetworkingProfilePopupProps> = ({
    registrationId,
    hideRegistrationLink = false,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const profileRequest = backend.networking.useGetAll([client.id, event.id], {
        select: profiles => profiles.find(it => it.registrationId === registrationId),
    })

    return <LockableDialog
        {...props}
    >
        <Loading
            request={profileRequest}
            sizing="fit-content"
            render={profile => {
                return profile ?
                    <LegacyNetworkingProfileContent profile={profile} hideRegistrationLink={hideRegistrationLink} onClose={props.onClose} /> :
                    <DialogTitle>Profile not found</DialogTitle>
            }}
        />
    </LockableDialog>
}

export const useLegacyNetworkingProfilePopup = createPopupHook({
    scope: Scope.Event,
    element: LegacyNetworkingProfilePopup,
})