import { AdminMeetingRequest, MeetingRequestStatus, Scope } from "@marketpartner/backend-api";
import { DeleteForever, Schedule } from "@mui/icons-material";
import { Divider, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { createPopupHook } from "src/common/dialogs/Popup";
import { ContextMenuButton, ContextMenuButtonProps } from "src/common/menus/ContextMenuButton";
import { LoadingMenuItem } from "src/common/menus/LoadingMenuItem";
import { LockableMenu, LockableMenuProps } from "src/common/menus/LockableMenu";
import { withNotification } from "src/common/notifications/with-notification";
import { useEvent } from "src/events/event-context";
import { MeetingRequestStatusIcon } from "src/networking/meetings/requests/MeetingRequestStatus";
import { useRescheduleMeetingDialog } from "src/networking/meetings/scheduling/RescheduleMeetingDialog";


export type MeetingRequestContextMenuProps = LockableMenuProps & {
    request: AdminMeetingRequest
}

const MeetingRequestContextMenu: FC<MeetingRequestContextMenuProps> = ({
    request,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!

    const updateMutation = backend.meetings.useUpdateMeetingRequest(withNotification({
        errorMessage: "Error updating meeting request"
    }))
    const deleteMutation = backend.meetings.useDeleteMeetingRequest(withNotification({
        errorMessage: "Error deleting meeting request"
    }))

    const rescheduleDialog = useRescheduleMeetingDialog()

    const statusMenuItem = (
        status: MeetingRequestStatus,
        text: string,
    ) => <LoadingMenuItem
        disabled={request.status === status}
        onClick={() => updateMutation.mutateAsync([client.id, event.id, request.meetingId, { status }])}
    >
            <ListItemIcon><MeetingRequestStatusIcon status={status} /></ListItemIcon>
            <ListItemText>{text}</ListItemText>
        </LoadingMenuItem>

    return <LockableMenu {...props} closeOnClick>
        <MenuItem onClick={() => rescheduleDialog.open({ request })}>
            <ListItemIcon><Schedule /></ListItemIcon>
            <ListItemText>Reschedule/Relocate</ListItemText>
        </MenuItem>
        <Divider />
        {statusMenuItem(MeetingRequestStatus.Pending, "Set pending")}
        {statusMenuItem(MeetingRequestStatus.Accepted, "Accept")}
        {statusMenuItem(MeetingRequestStatus.Declined, "Decline")}
        {statusMenuItem(MeetingRequestStatus.Cancelled, "Cancel")}
        <Divider />
        <LoadingMenuItem onClick={() => deleteMutation.mutateAsync([client.id, event.id, request.meetingId])}>
            <ListItemIcon><DeleteForever color="error" /></ListItemIcon>
            <ListItemText>Delete</ListItemText>
        </LoadingMenuItem>
    </LockableMenu>
}

export const useMeetingRequestContextMenu = createPopupHook({
    element: MeetingRequestContextMenu,
    scope: Scope.Event,
})

export type MeetingRequestContextMenuButtonProps = Partial<ContextMenuButtonProps> & {
    request: AdminMeetingRequest
}

export const MeetingRequestContextMenuButton: FC<MeetingRequestContextMenuButtonProps> = ({
    request,
    ...props
}) => {
    const menu = useMeetingRequestContextMenu()

    return <ContextMenuButton {...props} onClick={e => menu.open({
        request,
        anchorEl: e.currentTarget,
    })} />
}