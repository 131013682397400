import { FormElementConfig, Permission, StandardForm } from "@marketpartner/backend-api"
import { Paper, Tab, Tabs } from "@mui/material"
import { useState } from "react"
import { useNavigate, useParams } from "react-router"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { BarAndFlex } from "src/common/layout/BarAndFlex"
import { BasicProperties, BasicProperty } from "src/common/layout/BasicProperties"
import { Loading } from "src/common/loading/Loading"
import { Page } from "src/common/layout/Page"
import { link } from "src/common/routing/routes"
import { ActiveStatus } from "src/common/statuses/ActiveStatus"
import { fullSize } from "src/common/styles"
import { EventDateTimeText } from "src/common/time/DateTimeText"
import { useEvent } from "src/events/event-context"
import { DeleteFormButton } from "src/forms/DeleteFormButton"
import { EditFormButton } from "src/forms/EditFormButton"
import { FormElementSourceType } from "src/forms/elements/copying/useFormElementSources"
import { EditableFormElements } from "src/forms/elements/EditableFormElements"
import { formStatusDetails } from "src/forms/form-status-details"
import { FormResponses } from "src/forms/responses/FormResponses"
import { useLoginContext } from "src/login/LoginContext"

export const FormPage = () => {
    const client = useClient()!
    const event = useEvent()!
    const navigate = useNavigate()
    const [tab, setTab] = useState('responses')
    const params = useParams()
    const formId = parseInt(params.formId!)
    const login = useLoginContext()

    const formsQuery = backend.forms.useGet([client.id, event.id, formId], {
        select: form => form as StandardForm
    })
    const updateFormMutation = backend.forms.useUpdate()

    const updateElements = (
        elements: FormElementConfig[]
    ) => updateFormMutation.mutateAsync([client.id, event.id, formId, {
        elementList: elements,
    }])

    return <Loading request={formsQuery} render={form => {
        return <Page
            title={form.name}
            actions={<>
                <EditFormButton form={form} />
                <DeleteFormButton form={form} onDelete={() => navigate(link.toForms(client.id, event.id))} />
            </>}
            minContentSize={550}
        >
            <BarAndFlex barContent={<Paper sx={{ p: 2, mb: 2 }}>
                <BasicProperties>
                    <BasicProperty name="Status" value={<ActiveStatus details={formStatusDetails} start={form.start} end={form.end} />} />
                    {form.start && <BasicProperty name="Active from" value={<EventDateTimeText dateTime={form.start} />} />}
                    {form.end && <BasicProperty name="Active to" value={<EventDateTimeText dateTime={form.end} />} />}
                    <BasicProperty name="Moderated" value={form.isModerated} />
                    <BasicProperty name="Anonymous responses" value={form.allowAnonymousResponses} />
                </BasicProperties>
            </Paper>}>
                <Paper sx={{ ...fullSize }}>
                    <BarAndFlex barContent={<Tabs
                        value={tab}
                        onChange={(_, value) => setTab(value)}
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab value="responses" label="Responses" />
                        <Tab value="elements" label="Elements" />
                    </Tabs>}>
                        {tab === 'responses' && <FormResponses form={form} />}
                        {tab === 'elements' && <EditableFormElements
                            canEdit={login.hasPermission(Permission.EditForms, client.id, event.id)}
                            elements={form.elementList}
                            onChangeElements={updateElements}
                            defaultSourceType={FormElementSourceType.Forms}
                            sizing="full-size"
                        />}
                    </BarAndFlex>
                </Paper>
            </BarAndFlex>
        </Page>
    }} />

}