import { Form, Registration } from "@marketpartner/backend-api"
import { autoMatchColumns, ColumnMatches } from "src/registrations/import/pre-process/auto-match-columns"
import { ExtractedFormField, extractFormFields } from "src/registrations/import/pre-process/extract-form-fields"
import { matchRegistrations } from "src/registrations/import/pre-process/match-registrations"
import { mergeCoreProperties } from "src/registrations/import/pre-process/merge-core-properties"
import { parseRegistrations } from "src/registrations/import/pre-process/parse-registrations"
import { validateCoreProperties, ValidMergedRegistration } from "src/registrations/import/pre-process/validate-core-properties"
import { CsvParser } from "src/registrations/import/pre-process/CsvParser"


export type PreprocessedCsv = {
    csvColumns: string[]
    formFields: ExtractedFormField[]
    rows: ValidMergedRegistration[]
    columnMatches: ColumnMatches
}

export const preprocessCsv = (
    csvContent: string,
    forms: Form[],
    registrations: Registration[],
): PreprocessedCsv => {
    const { columns, rows } = CsvParser.parse(csvContent)
    const formFields = extractFormFields(forms)
    const columnMatches = autoMatchColumns(columns, formFields)
    
    const parsedRegistrations = parseRegistrations(columns, rows)
    const matchedRegistrations = matchRegistrations(parsedRegistrations, registrations)
    const mergedRegistrations = mergeCoreProperties(matchedRegistrations)
    const validatedRegistrations = validateCoreProperties(mergedRegistrations)

    return {
        csvColumns: columns,
        formFields,
        rows: validatedRegistrations,
        columnMatches,
    }
}