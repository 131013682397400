import { StripeInstance } from "@marketpartner/backend-api";
import { CopyButton } from "@marketpartner/mp-common-mui";
import { Grid, TextField, Typography } from "@mui/material";
import { FC, useState } from "react";
import { useClient } from "src/clients/client-context";
import { DialogForm, ExtendableDialogFormProps } from "src/common/dialogs/DialogForm";
import { getEnvironment } from "src/common/environment";
import { Bar } from "src/common/layout/Bar";


export type StripeInstanceFormData = {
    name: string
    publishableKey: string
    secretKey: string
    endpointSecret: string
}

export type StripeInstanceFormProps = ExtendableDialogFormProps<StripeInstanceFormData> & {
    instance?: StripeInstance
}

export const StripeInstanceForm: FC<StripeInstanceFormProps> = ({
    instance,
    ...props
}) => {
    const client = useClient()!

    const [name, setName] = useState(instance?.name ?? client.name)
    const [publishableKey, setPublishableKey] = useState(instance?.publishableKey ?? "")
    const [secretKey, setSecretKey] = useState("")
    const [endpointSecret, setEndpointSecret] = useState("")

    return <DialogForm
        formData={{
            name,
            publishableKey,
            secretKey,
            endpointSecret,
        }}
        isValid={Boolean(name && publishableKey && (instance || secretKey))}
        {...props}
    >
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    label="Name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    fullWidth
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <Bar spacing={2}>
                    <TextField
                        label="Publishable key"
                        value={publishableKey}
                        onChange={e => setPublishableKey(e.target.value)}
                        disabled={Boolean(instance)}
                        fullWidth
                        required
                    />
                    {instance && <CopyButton value={publishableKey} />}
                </Bar>
            </Grid>
            {!instance && <Grid item xs={12}>
                <Typography variant="h6">Restricted key</Typography>
                <Typography variant="caption" color="text.secondary">
                    Create a <b>Restricted key</b> with <b>Write</b> permission on <b>PaymentIntents and Customers</b>. Paste it here.
                </Typography>
                <TextField
                    label="Restricted key"
                    value={secretKey}
                    onChange={e => setSecretKey(e.target.value)}
                    fullWidth
                    required
                    sx={{ mt: 1 }}
                />
            </Grid>}
            {!instance && <Grid item xs={12}>
                <Typography variant="h6">Webhook endpoint</Typography>
                <Typography variant="caption" color="text.secondary">
                    If it does not exist, create a webhook endpoint for:<br/>
                    <b style={{ whiteSpace: "nowrap" }}>{endpointUrl} <CopyButton value={endpointUrl} size="small" /></b>
                    <br />
                    Add all <b>PaymentIntent</b> events.<br />
                    Paste the <b>Signing secret</b> here.
                </Typography>
                <TextField
                    label="Endpoint signing secret"
                    value={endpointSecret}
                    onChange={e => setEndpointSecret(e.target.value)}
                    fullWidth
                    required
                    sx={{ mt: 1 }}
                />
            </Grid>}
        </Grid>
    </DialogForm>
}

const endpointUrl = `${getEnvironment() === 'dev' ? "https://dev.marketpartner.app" : "https://marketpartner.app"}/api/stripe-webhooks`