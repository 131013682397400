import { divideIntoTimeslots, NetworkingPeriod } from "@marketpartner/backend-api"
import { Box, Grid, SxProps, Typography } from "@mui/material"
import { Interval } from "luxon"
import { FC, ReactNode } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { Bar } from "src/common/layout/Bar"
import { Spacer } from "src/common/layout/Spacer"
import { StickyHeadingAndContent } from "src/common/layout/StickyHeadingAndContent"
import { Loading } from "src/common/loading/Loading"
import { useEvent } from "src/events/event-context"


export type NetworkingTimeslotsProps = {
    periodControls?: (period: NetworkingPeriod) => ReactNode
    renderTimeslot: (timeslot: Interval) => ReactNode
    sx?: SxProps
}

export const NetworkingTimeslots: FC<NetworkingTimeslotsProps> = ({
    periodControls,
    renderTimeslot,
}) => {
    const client = useClient()!
    const event = useEvent()!

    const periodsQuery = backend.networkingPeriods.useGetPeriods([client.id, event.id])

    return <Loading request={periodsQuery} sizing="fit-content" render={periods => periods.length > 0 ?
        periods.map(period =>
            <StickyHeadingAndContent
                key={period.id}
                headingContent={<Bar spacing={2}>
                    <Typography variant="h6">{period.name}</Typography>
                    <Spacer />
                    {periodControls?.(period)}
                </Bar>}
            >
                <Box p={2}>
                    <Grid container alignItems="stretch" maxWidth={750} mx="auto">
                        {divideIntoTimeslots(period).map((timeslot, idx) =>
                            <Grid xs={3} key={idx} p={0.5}>
                                {renderTimeslot(timeslot)}
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </StickyHeadingAndContent>
        ) :
        <Typography sx={{ py: 8, textAlign: "center" }}>
            No networking periods configured
        </Typography>
    } />
}