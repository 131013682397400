import { Registration, RegistrationType, TestClass } from "@marketpartner/backend-api";
import { AllPossibleKeysOf } from "@marketpartner/mp-common";
import { MatchedRegistration } from "src/registrations/import/pre-process/match-registrations";
import { ParsedRegistration } from "src/registrations/import/pre-process/parse-registrations";
import { RegistrationParseException } from "src/registrations/import/pre-process/registration-parse-exception";

export type MergedRegistration = Omit<ParsedRegistration, "id"> & {
    parsedId?: string
    existingRegistration?: Registration
    type: RegistrationType
}

export class TestMergedRegistration extends TestClass implements MergedRegistration {
    lineNumber = 2
    parsedId?: string
    existingRegistration?: Registration
    type = RegistrationType.Primary
    email?: string
    categoryId?: string
    primaryRegistrationId?: string
    fields: Record<string, string> = {}
}

export class InvalidPropertyChangeException extends RegistrationParseException {
    constructor(
        lineNumber: number,
        propertyName: string,
        newValue: unknown,
        oldValue: unknown,
    ) {
        super(`${propertyName} (${newValue}) on line number ${lineNumber} differs from the existing value ${oldValue}. Changing ${propertyName} is not supported.`)
    }
}

export const mergeCoreProperties = (
    registrations: MatchedRegistration[]
): MergedRegistration[] => {
    return registrations.map(merge)
}

const merge = (
    match: MatchedRegistration
) => {
    const { parsed, existing } = match

    return {
        lineNumber: parsed.lineNumber,
        parsedId: parsed.id,
        existingRegistration: existing,
        fields: parsed.fields,
        email: parsed.email ?? existing?.email ?? undefined,
        type: takeUnmodified(match, "type") ?? RegistrationType.Primary,
        categoryId: parsed.categoryId ?? (existing as any)?.categoryId,
        primaryRegistrationId: takeUnmodified(match, "primaryRegistrationId"),
    }
}

const takeUnmodified = <
    Property extends keyof ParsedRegistration & AllPossibleKeysOf<Registration>
>(
    { parsed, existing }: MatchedRegistration,
    property: Property
): ParsedRegistration[Property] => {
    const parsedValue = parsed[property]
    const existingValue = (existing as any)?.[property]

    if (parsedValue && existingValue && parsedValue !== existingValue) {
        throw new InvalidPropertyChangeException(parsed.lineNumber, property, parsedValue, existingValue)
    }

    return parsedValue ?? existingValue as any
}
