import { RegistrationType } from "@marketpartner/backend-api"
import { MergedRegistration, TestMergedRegistration } from "src/registrations/import/pre-process/merge-core-properties"
import { RegistrationParseException } from "src/registrations/import/pre-process/registration-parse-exception"

export type ValidMergedPrimaryRegistration = MergedRegistration & {
    type: RegistrationType.Primary
    email: string
    categoryId: string
}

export type ValidMergedGuestRegistration = MergedRegistration & {
    type: RegistrationType.Guest
    primaryRegistrationId: string
}

export type ValidMergedRegistration = ValidMergedPrimaryRegistration | ValidMergedGuestRegistration

export class TestValidMergedPrimaryRegistration extends TestMergedRegistration implements ValidMergedPrimaryRegistration {
    type = RegistrationType.Primary as const
    email = "email@example.com"
    categoryId = ""
}

export class TestValidMergedGuestRegistration extends TestMergedRegistration implements ValidMergedGuestRegistration {
    type = RegistrationType.Guest as const
    primaryRegistrationId = "primary reg id"
}

export const validateCoreProperties = (
    merged: MergedRegistration[]
): ValidMergedRegistration[] => {
    merged.forEach(validate)
    return merged as ValidMergedRegistration[]
}

const validate = (
    merged: MergedRegistration
) => {
    if (merged.type === RegistrationType.Primary) {
        if (!merged.email) {
            throw new RegistrationParseException(`Primary registration on line ${merged.lineNumber} does not have an email address`)
        }
        if (!merged.categoryId) {
            throw new RegistrationParseException(`Primary registration on line ${merged.lineNumber} does not have a category`)
        }
    } else {
        if (!merged.primaryRegistrationId) {
            throw new RegistrationParseException(`Guest registration on line ${merged.primaryRegistrationId} does not have a primary registration id`)
        }
    }
}