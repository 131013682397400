import { ContentEntity } from "@marketpartner/backend-api";
import { SearchBox } from "@marketpartner/mp-common-mui";
import { useSearchCallback } from "@marketpartner/mp-common-react";
import { FC } from "react";
import { SearchableGridLayout } from "src/common/grid/SearchableGridLayout";
import { Bar } from "src/common/layout/Bar";
import { ContentEntitiesGrid, ContentEntitiesGridProps } from "src/content/entities/ContentEntitiesGrid";


export type SearchableContentEntitiesGridProps = Partial<ContentEntitiesGridProps>

export const SearchableContentEntitiesGrid: FC<SearchableContentEntitiesGridProps> = ({
    ...props
}) => {
    const [searchText, setSearchText, searchEntities] = useSearchCallback(searchParams, searchOptions)

    return <SearchableGridLayout
        searchControls={
            <Bar spacing={1}>
                <SearchBox searchText={searchText} setSearchText={setSearchText} />
            </Bar>
        }
        grid={<ContentEntitiesGrid
            filterRows={searchEntities}
            disableColumnMenu
            {...props}
        />}
    />
}

const searchParams = (entity: ContentEntity) => [
    entity.fields.title,
    entity.categoryId,
    entity.subCategoryId,
    entity.typeId,
]

const searchOptions = {
    disableUrlState: true,
}