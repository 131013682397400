import { filterFormFields, FormData, FormElementConfig, FormElementMetadata, FormFieldConfig, FormFieldValue } from "@marketpartner/backend-api"
import { NoInfer } from "@marketpartner/mp-common"
import { SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { sortBy } from "lodash"
import { ReactNode } from "react"
import { RenderedFormFieldValue } from "src/forms/elements/RenderedFormFieldValue"

export type ExtraFormDataTableColumn<Metadata extends FormElementMetadata = FormElementMetadata> = {
    headerName: string
    width?: number
    renderCell: (
        name: string,
        value: FormFieldValue,
        config: FormFieldConfig<Metadata> | undefined,
    ) => ReactNode
}

export type FormDataTableProps<Metadata extends FormElementMetadata = FormElementMetadata> = {
    data: FormData
    /**
     * Providing a list of elements will allow the values to be ordered
     * in the same order as the form fields.
     */
    elements?: FormElementConfig<Metadata>[]
    extraLeftColumns?: NoInfer<ExtraFormDataTableColumn<Metadata>>[]
    extraRightColumns?: NoInfer<ExtraFormDataTableColumn<Metadata>>[]
    sx?: SxProps
}

export const FormDataTable = <Metadata extends FormElementMetadata = any>({
    data,
    elements = [],
    extraLeftColumns = [],
    extraRightColumns = [],
    sx,
}: FormDataTableProps<Metadata>) => {
    const elementNames = filterFormFields(elements).map(it => it.name)

    // Order field values in the same order as the form fields.
    // Place any "orphan" fields at the end.
    const fieldOrder = (name: string, idxInData: number) => {
        const elementIndex = elementNames.indexOf(name)
        return elementIndex === -1 ? elementNames.length + idxInData : elementIndex
    }

    const entries = sortBy(
        Object.entries(data),
        ([name], idx) => fieldOrder(name, idx)
    )

    return <TableContainer sx={sx}>
        <Table>
            <TableHead>
                <TableRow>
                    {extraLeftColumns.map(it =>
                        <TableCell width={it.width} key={`LEFT-${it.headerName}`}>{it.headerName}</TableCell>
                    )}
                    <TableCell>Field</TableCell>
                    <TableCell>Value</TableCell>
                    {extraRightColumns.map(it =>
                        <TableCell width={it.width} key={`RIGHT-${it.headerName}`}>{it.headerName}</TableCell>
                    )}
                </TableRow>
            </TableHead>
            <TableBody>
                {entries.map(([name, value]) => {
                    const fieldConfig = filterFormFields(elements)
                        .find(it => it.name === name)

                    return <TableRow key={name}>
                        {extraLeftColumns.map(it => <TableCell key={`LEFT-${it.headerName}`}>{it.renderCell(name, value, fieldConfig)}</TableCell>
                        )}
                        <TableCell>{name}</TableCell>
                        <TableCell>
                            <RenderedFormFieldValue value={value} />
                        </TableCell>
                        {extraRightColumns.map(it => <TableCell key={`RIGHT-${it.headerName}`}>{it.renderCell(name, value, fieldConfig)}</TableCell>
                        )}
                    </TableRow>
                })}
            </TableBody>
        </Table>
    </TableContainer>
}