import { AdjacentItemDetails, AgendaHeading } from "@marketpartner/backend-api"
import { FC } from "react"
import { EditableAgendaItem } from "src/content/agendas/EditableAgendaItem"


export type EditableAgendaHeadingProps = {
    heading: AgendaHeading & AdjacentItemDetails
}

export const EditableAgendaHeading: FC<EditableAgendaHeadingProps> = ({
    heading
}) => {
    return <EditableAgendaItem
        item={heading}
        headerText={heading.name}
    />
}