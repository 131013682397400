import { ExistingEnhancedUpload, FormFieldValue, FormUploadType, ObjectFieldValueType } from "@marketpartner/backend-api"
import { Avatar, Box, SxProps, Typography, TypographyProps } from "@mui/material"
import { FC } from "react"
import { Bar, BarProps } from "src/common/layout/Bar"


export type RenderedFormFieldValueProps = {
    value: FormFieldValue | number
    hideNullish?: boolean
    sx?: SxProps
    typographyProps?: TypographyProps
    multiValueStackProps?: BarProps
    uploadProps?: Partial<RenderedUploadProps>
}

export const RenderedFormFieldValue: FC<RenderedFormFieldValueProps> = ({
    value,
    hideNullish = false,
    sx,
    typographyProps,
    multiValueStackProps: multiValueBarProps,
    uploadProps,
}) => {

    const renderText = (value: string) => <Typography
        whiteSpace="pre-wrap"
        {...typographyProps}
        sx={{
            ...sx,
            ...(typographyProps as any)?.sx,
        }}
        children={value}
    />

    const textValue = (value: string | boolean | number) => {
        if (typeof value === "boolean") {
            return value ? "Yes" : "No"
        }
        return value.toString()
    }

    if (value === undefined) {
        return <></>
    } else if (typeof value === "boolean") {
        return renderText(textValue(value))
    } else if (hideNullish && !value) {
        return <></>
    } else if (typeof value === 'number') {
        return renderText(value.toLocaleString())
    } else if (typeof value === "string") {
        return renderText(value)
    } else if (Array.isArray(value)) {
        if (value.length === 0 || typeof value[0] !== "object") {
            return renderText(value.map(textValue).join(", "))
        }

        return <Bar
            spacing={1}
            {...multiValueBarProps}
        >
            {value.map((item, i) => <RenderedFormFieldValue
                key={i}
                value={item}
                hideNullish={hideNullish}
                sx={sx}
                typographyProps={typographyProps}
                multiValueStackProps={multiValueBarProps}
            />)}
        </Bar>
    }

    switch (value.type) {
        case ObjectFieldValueType.EnhancedUpload:
            return <RenderedUpload value={value} {...uploadProps} />
    }
}

export type RenderedUploadProps = {
    value: ExistingEnhancedUpload
    size?: number
    sx?: SxProps
}

const RenderedUpload: FC<RenderedUploadProps> = ({
    value,
    size = 100,
    sx,
}) => {

    switch (value.metadata.type) {
        case FormUploadType.Avatar: return <Avatar
            src={value.absoluteUrl}
            sx={{
                width: size,
                height: size,
                ...sx,
            }}
        />
        case FormUploadType.Image: return <Box
            component="img"
            src={value.absoluteUrl}
            alt={value.metadata.altText}
            sx={{
                maxWidth: size,
                maxHeight: size,
                ...sx,
            }}
        />
        case FormUploadType.Pdf: return <Box
            component="iframe"
            src={value.absoluteUrl}
            sx={{
                width: size,
                height: size,
                border: "none",
                ...sx,
            }}
        />
    }
}