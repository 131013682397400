import { AppBar, Box, Toolbar } from "@mui/material";
import { FC, ReactNode } from "react";
import { FeatureToggleSwitch } from "src/common/FeatureToggle";
import { useBreakpointDown } from "src/common/hooks/useBreakpoint";
import logo from "src/common/mp-logo-white.svg";
import { UserIndicator } from "src/login/UserIndicator";

export type TopBarProps = {
    menuIcon: ReactNode
}

export const TopBar: FC<TopBarProps> = ({
    menuIcon
}) => {
    const isCompact = useBreakpointDown("md")
    const logoSize = isCompact ? "15px" : "20px"
    const height = isCompact ? "48px" : "64px"
    const padding = isCompact ? 0 : 0.5
    const size = isCompact ? "small" : "medium"

    return <AppBar position="relative" sx={{ height, minHeight: height, p: padding }}>
        <Toolbar style={{ height: "100%", minHeight: 0 }}>
            {menuIcon}
            <img src={logo} alt="Market Partner logo" style={{ height: logoSize }} />
            <Box flexGrow={1} />
            <FeatureToggleSwitch 
                switchProps={{ color: "default" }} 
                labelPlacement="start"
                sx={{ mr: 1 }}
            />
            <UserIndicator size={size} />
        </Toolbar>
    </AppBar>
}