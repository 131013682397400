import Add from "@mui/icons-material/Add"
import Check from "@mui/icons-material/Check"
import { Box, Chip, IconButton, SxProps, TextField } from "@mui/material"
import { FC, KeyboardEventHandler, useCallback, useState } from "react"
import { Bar } from "src/common/layout/Bar"


export type TagEditorProps = {
    tags: string[]
    onChange: (tags: string[]) => void
    sx?: SxProps
}

export const TagEditor: FC<TagEditorProps> = ({
    tags,
    onChange,
    sx,
}) => {
    const onDelete = useCallback((idx: number) => {
        const newTags = tags.slice()
        newTags.splice(idx, 1)
        onChange(newTags)
    }, [tags, onChange])

    const onAdd = useCallback((tag: string) => {
        onChange([...tags, tag])
    }, [tags, onChange])

    return <Bar flexWrap="wrap" sx={sx}>
        {tags.map((tag, idx) => <Box key={tag} m={0.5}>
            <Chip label={tag} onDelete={() => onDelete(idx)} />
        </Box>)}
        <NewTagChip onAdd={onAdd} />
    </Bar>
}

type NewTagChipProps = {
    onAdd: (tag: string) => void
}

const NewTagChip: FC<NewTagChipProps> = ({
    onAdd,
}) => {
    const [isEditing, setIsEditing] = useState(false)
    const [value, setValue] = useState("")

    const onCancel = useCallback(() => {
        setIsEditing(false)
        setValue("")
    }, [])

    const onConfirm = useCallback(() => {
        onAdd(value)
        setValue("")
        setIsEditing(false)
    }, [onAdd, value])

    const onTextKeyPress: KeyboardEventHandler<HTMLInputElement> = useCallback(e => {
        if (e.key === "Escape") {
            onCancel()
            e.preventDefault()
            e.stopPropagation()
        }
        if (e.key === "Enter" && !e.ctrlKey) {
            onConfirm()
            e.preventDefault()
            e.stopPropagation()
        }
    }, [onConfirm, onCancel])

    if (!isEditing) {
        return <IconButton onClick={() => setIsEditing(true)}>
            <Add />
        </IconButton>
    }

    return <Chip
        label={<TextField
            inputRef={input => input && input.focus()}
            variant="standard"
            value={value}
            onChange={e => setValue(e.target.value)}
            InputProps={{
                onKeyDown: onTextKeyPress,
                endAdornment: <IconButton size="small" onClick={onConfirm}>
                    <Check fontSize="small" color="success" />
                </IconButton>,
            }}
        />}
        onDelete={onCancel}
        sx={{ my: 0.5 }}
    />
}