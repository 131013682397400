import { EventPartnerInstance } from "@marketpartner/backend-api"
import { GridColDef } from "@mui/x-data-grid-pro"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { QueryDataGrid } from "src/common/grid/QueryDataGrid"
import { Bar } from "src/common/layout/Bar"
import { DeleteEventPartnerInstanceButton } from "src/content/event-partner/instances/DeleteEventPartnerInstanceButton"
import { EditEventPartnerInstanceButton } from "src/content/event-partner/instances/EditEventPartnerInstanceButton"
import { EventPartnerLink } from "src/content/event-partner/instances/EventPartnerLink"

const columns: GridColDef<EventPartnerInstance>[] = [
    {
        field: "id",
        headerName: "Id",
        flex: 1,
        minWidth: 150,
        renderCell: ({ value }) => <EventPartnerLink id={value}>
            {value}
        </EventPartnerLink>,
    },
    {
        field: "appToken",
        headerName: "App token",
        flex: 2,
        minWidth: 265,
    },
    {
        field: "accessToken",
        headerName: "Access token",
        flex: 2,
        minWidth: 265,
    },
    {
        field: "actions",
        headerName: "",
        width: 150,
        renderCell: ({ row }) => <Bar>
            {<EditEventPartnerInstanceButton instance={row} />}
            <DeleteEventPartnerInstanceButton instance={row} />
        </Bar>
    },
]

export const EventPartnerInstancesGrid = () => {
    const client = useClient()!
    const instancesQuery = backend.content.useGetAllEventPartnerInstances([client.id])

    return <QueryDataGrid
        queryOrRows={instancesQuery}
        columns={columns}
        disableRowSelectionOnClick
    />
}