import { Registration, RegistrationApprovalState, RegistrationType } from "@marketpartner/backend-api"
import { SearchBox } from "@marketpartner/mp-common-mui"
import { useSearchCallback } from "@marketpartner/mp-common-react"
import { SxProps } from "@mui/material"
import { FC, useCallback, useState } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { Bar } from "src/common/layout/Bar"
import { BarAndFlex } from "src/common/layout/BarAndFlex"
import { useEvent } from "src/events/event-context"
import { CopyRegistrationsGrid } from "src/registrations/actions/copy/CopyRegistrationsGrid"
import { getRegistrationTreePath } from "src/registrations/BaseRegistrationsGrid"
import { RegistrationTotals } from "src/registrations/RegistrationTotals"


export type CopyRegistrationsSelectorProps = {
    sourceEventId: string
    sourceCategoryId: string
    selectedRegistrations: Registration[]
    onChangeSelectedRegistrations: (registrations: Registration[]) => void
    copyGuests: boolean
    sx?: SxProps
}

export const CopyRegistrationsSelector: FC<CopyRegistrationsSelectorProps> = ({
    sourceEventId,
    sourceCategoryId,
    selectedRegistrations,
    onChangeSelectedRegistrations,
    copyGuests,
    sx,
}) => {
    const client = useClient()!
    const event = useEvent()!
    const targetRegistrationsQuery = backend.registrations.useGetAll<Registration[]>([client.id, event.id])
    const sourceRegistrationsQuery = backend.registrations.useGetAll([client.id, sourceEventId], {
        enabled: Boolean(targetRegistrationsQuery.data),
        select: registrations => {
            const existingEmails = new Set(targetRegistrationsQuery.data!.map(r => r.email))
            const primaryRegistrations = (registrations as Registration[]).filter(r =>
                r.type === RegistrationType.Primary &&
                r.categoryId === sourceCategoryId &&
                (!r.email || !existingEmails.has(r.email))
            )
            const guests = copyGuests ? (registrations as Registration[]).filter(r =>
                r.type === RegistrationType.Guest &&
                primaryRegistrations.some(primary => primary.id === r.primaryRegistrationId)
            ) : []

            return [...primaryRegistrations, ...guests] as Registration[]
        }
    })

    const [searchText, setSearchText, searchRegistrations] = useSearchCallback(searchParams, searchOptions)
    const [state, setState] = useState<RegistrationApprovalState | undefined>(undefined)

    const filterRegistrations = useCallback((registrations: Registration[]) => searchRegistrations(registrations
        .filter(it => !state || it.approvalState === state)
    ), [searchRegistrations, state])

    return <BarAndFlex
        sx={sx}
        sizing="fit-content"
        spacing={2}
        flexStyle={{
            display: "flex",
            flexDirection: "column",
        }}
        barContent={
            <Bar spacing={1}>
                <SearchBox searchText={searchText} setSearchText={setSearchText} />
                <RegistrationTotals
                    registrations={sourceRegistrationsQuery.data ?? []}
                    selectedStatus={state}
                    onSelectStatus={setState}
                />
            </Bar>
        }
    >
        <CopyRegistrationsGrid
            queryOrRows={sourceRegistrationsQuery}
            sourceEventId={sourceEventId}
            sourceCategoryId={sourceCategoryId}
            selectedRegistrations={selectedRegistrations}
            onChangeSelectedRegistrations={onChangeSelectedRegistrations}
            filterRows={filterRegistrations}
        />
    </BarAndFlex>
}

const searchParams = (registration: Registration) => [
    registration.firstName,
    registration.lastName,
    registration.email,
]

const searchOptions = {
    disableUrlState: true,
    treeOptions: {
        getTreePath: getRegistrationTreePath,
        alwaysReturnChildrenOfMatch: true,
    }
}