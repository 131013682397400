import { Permission, Registration } from "@marketpartner/backend-api"
import { casualName } from "@marketpartner/mp-common"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { link } from "src/common/routing/routes"
import { ContentCard, ContentCardProps } from "src/content/ContentCard"
import { useEvent } from "src/events/event-context"
import { useLoginContext } from "src/login/LoginContext"
import { ProfileAvatar } from "src/registrations/profiles/ProfileAvatar"


export type ProfileCardProps = Partial<ContentCardProps> & {
    registration: Registration
}

export const ProfileCard: FC<ProfileCardProps> = ({
    registration,
    onClickMenu,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const login = useLoginContext()

    const profile = registration.profile

    return <ContentCard
        primaryText={casualName(profile)}
        secondaryTexts={[profile.job_title, profile.company].filter(Boolean) as string[]}
        link={link.toRegistrationProfile(client.id, event.id, registration.id)}
        onClickMenu={login.hasPermission(Permission.EditEventContent, client.id, event.id) ?
            onClickMenu : undefined
        }
        opacity={profile.profile_visible ? 1 : 0.3}
        avatar={<ProfileAvatar registration={registration} />}
        {...props}
    />
}