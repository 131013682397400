import { Link, Stack, StackProps, Typography, TypographyProps } from "@mui/material";
import { FC, ReactNode } from "react";
import { Link as RouterLink } from 'react-router-dom';

export type NameAndSubtitleProps = Partial<StackProps> & {
    name: ReactNode
    subtitle?: ReactNode
    nameProps?: Partial<TypographyProps>
    subtitleProps?: Partial<TypographyProps>
    link?: string
}

export const NameAndSubtitle: FC<NameAndSubtitleProps> = ({
    name,
    subtitle,
    nameProps,
    subtitleProps,
    link,
    ...props
}) => {
    const content = <Stack {...props}>
        <Typography {...nameProps}>
            {name}
        </Typography>
        {subtitle && <Typography variant="body2" color="text.secondary" {...subtitleProps}>
            {subtitle}
        </Typography>}
    </Stack>

    if (link) {
        return <Link
            component={RouterLink}
            to={link}
            underline="hover"
            color="text.primary"
            children={content}
        />
    }

    return content
}