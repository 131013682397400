import { AgendaItemType, Location, reorderBody } from "@marketpartner/backend-api";
import { Add } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { QueryDataGrid } from "src/common/grid/QueryDataGrid";
import { Bar } from "src/common/layout/Bar";
import { BarAndFlex } from "src/common/layout/BarAndFlex";
import { Spacer } from "src/common/layout/Spacer";
import { ContextMenuButton } from "src/common/menus/ContextMenuButton";
import { withNotification } from "src/common/notifications/with-notification";
import { ReorderButtons } from "src/common/reordering/ReorderButtons";
import { useBulkReorder } from "src/common/reordering/useBulkReorder";
import { useCreateLocationDialog } from "src/content/locations/CreateLocationDialog";
import { useLocationContextMenu } from "src/content/locations/LocationContextMenu";
import { LocationName } from "src/content/locations/LocationName";
import { useEvent } from "src/events/event-context";

export const Locations: FC = () => {
    const client = useClient()!
    const event = useEvent()!

    const locationsQuery = backend.locations.useGetLocations([client.id, event.id])
    const reorderMutation = backend.locations.useReorderLocations(withNotification({
        errorMessage: "Error reordering locations"
    }))

    const reorder = useBulkReorder({
        items: locationsQuery.data ?? [],
        onReorder: items => reorderMutation.mutateAsync([client.id, event.id, reorderBody(items)])
    })

    const createDialog = useCreateLocationDialog()

    return <BarAndFlex barContent={<Bar spacing={2} p={2}>
        <Spacer />
        <ReorderButtons reorder={reorder} />
        {!reorder.isReordering && <Button
            children="Add"
            startIcon={<Add />}
            variant="contained"
            onClick={() => createDialog.open({})}
        />}
    </Bar>}>
        <QueryDataGrid
            queryOrRows={reorder.orderedItems}
            columns={columns}
            rowReordering={reorder.isReordering}
            loading={locationsQuery.isPending || reorderMutation.isPending}
            onRowOrderChange={({ oldIndex, targetIndex }) => reorder.reorderItem(oldIndex, targetIndex)}
            sortModel={reorder.isReordering ? [] : undefined}
            disableColumnMenu
        />
    </BarAndFlex>
}

type LocationContextMenuButtonProps = {
    location: Location
}

const LocationContextMenuButton: FC<LocationContextMenuButtonProps> = ({
    location
}) => {
    const contextMenu = useLocationContextMenu()

    return <ContextMenuButton onClick={e => contextMenu.open({
        location,
        anchorEl: e.currentTarget,
    })} />
}

const columns: GridColDef<Location>[] = [
    {
        field: "id",
        headerName: "Id",
        valueGetter: ({ row }) => row.name,
        renderCell: ({ row }) => <LocationName location={row} />,
        flex: 1,
    },
    {
        field: "capacity",
        headerName: "Capacity",
        width: 100,
        renderCell: ({ row }) => row.capacity === 0 ?
            <Typography color="text.secondary" variant="body2">Unlimited</Typography> :
            row.capacity,
    },
    {
        field: "sessionCount",
        headerName: "Sessions",
        width: 100,
        renderCell: ({ row }) => <SessionCount locationId={row.id} />,
    },
    {
        field: "meetingCount",
        headerName: "Meetings",
        width: 100,
        renderCell: ({ row }) => row.localMeetingAvailability.length === 0 ?
            <Typography variant="body2" color="text.secondary">Disabled</Typography> :
            row.meetingCount,
    },
    {
        field: "menu",
        headerName: " ",
        width: 50,
        renderCell: ({ row }) => <LocationContextMenuButton location={row} />,
        sortable: false,
    }
]

type SessionCountProps = {
    locationId: string
}

const SessionCount: FC<SessionCountProps> = ({
    locationId,
}) => {
    const client = useClient()!
    const event = useEvent()!

    const sessionsQuery = backend.agendas.useGetAll([client.id, event.id], {
        select: agendas => agendas
            .flatMap(agenda => agenda.flatItems)
            .filter(item => item.type === AgendaItemType.Session && item.locationId === locationId)
            .length,
    })

    return <>{sessionsQuery.data}</>
}