import { Registration } from "@marketpartner/backend-api"
import { getErrorString, readFileAsString } from "@marketpartner/mp-common"
import { useMutation } from "@tanstack/react-query"
import { useCallback, useMemo, useState } from "react"
import { useClient } from "src/clients/client-context"
import { asyncBackend } from "src/common/api"
import { useEvent } from "src/events/event-context"
import { preprocessCsv, PreprocessedCsv } from "src/registrations/import/pre-process/pre-process-csv"
import { RegistrationParseException } from "src/registrations/import/pre-process/registration-parse-exception"

export type UseCsvPreprocessor = {
    isProcessing: boolean
    preprocessedCsv?: PreprocessedCsv
    preprocessingError?: string
    reset: () => void
    preprocessCsv: (file: File) => void
}

export const useCsvPreprocessor = (

): UseCsvPreprocessor => {
    const client = useClient()!
    const event = useEvent()!
    const [preprocessedCsv, setPreprocessedCsv] = useState<PreprocessedCsv>()
    const [preprocessingError, setPreprocessingError] = useState<string>()

    const preprocess = useMutation({
        mutationFn: async (file: File) => {
            const [content, forms, registrations] = await Promise.all([
                readFileAsString(file),
                asyncBackend.forms.getAll(client.id, event.id),
                asyncBackend.registrations.getAll(client.id, event.id) as Promise<Registration[]>,
            ])
            return preprocessCsv(content, forms, registrations)
        },
        onSuccess: setPreprocessedCsv,
        onError: error => {
            if (error instanceof RegistrationParseException) {
                setPreprocessingError(error.message)
            } else {
                setPreprocessingError(getErrorString(error))
            }
        },
    })

    const reset = useCallback(() => {
        setPreprocessedCsv(undefined)
        setPreprocessingError(undefined)
    }, [])

    const triggerPreprocessCsv = useCallback((file: File) => {
        reset()
        preprocess.mutate(file)
    }, [reset, preprocess])

    return useMemo(() => ({
        isProcessing: preprocess.isPending,
        preprocessedCsv,
        preprocessingError,
        preprocessCsv: triggerPreprocessCsv,
        reset,
    }), [preprocess.isPending, preprocessedCsv, preprocessingError, triggerPreprocessCsv, reset])
}