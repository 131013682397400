import ArrowBack from "@mui/icons-material/ArrowBack"
import { Box, Button, DialogActions, DialogContent, DialogContentProps, DialogTitle, Step, StepLabel, Stepper } from "@mui/material"
import { FC, ReactNode } from "react"
import { useDialogContext } from "src/common/dialogs/LockableDialog"

export type DialogStep = {
    label: string
    header?: ReactNode
    content: ReactNode
    contentProps?: DialogContentProps
    nextAction?: ReactNode
    back?: () => void
}

export type DialogStepperProps = {
    title?: ReactNode
    steps: DialogStep[]
    stepIdx: number
}

export const DialogStepper: FC<DialogStepperProps> = ({
    title,
    steps,
    stepIdx,
}) => {
    const dialog = useDialogContext()
    const step = steps[stepIdx]

    return <>
        {title && <DialogTitle>{title}</DialogTitle>}

        <Stepper activeStep={stepIdx} sx={{ px: 2, pb: 2 }}>
            {steps.map((step, index) => <Step key={index}>
                <StepLabel>{step.label}</StepLabel>
            </Step>)}
        </Stepper>

        {step.header}

        <DialogContent {...step.contentProps} sx={{ 
            pt: 1,
            ...step.contentProps?.sx,
        }}>
            {step.content}
        </DialogContent>

        <DialogActions>
            {step.back && <Button color="inherit" startIcon={<ArrowBack />} onClick={step.back} disabled={dialog.closeDisabled}>Back</Button>}
            <Box mx="auto" />
            <Button color="inherit" onClick={() => dialog.close()} disabled={dialog.closeDisabled}>Close</Button>
            {step.nextAction}
        </DialogActions>
    </>
}