import { Registration } from "@marketpartner/backend-api";
import { Autocomplete, AutocompleteProps, TextField } from "@mui/material";
import _ from "lodash";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { useEvent } from "src/events/event-context";


export type RegistrationFieldValueSelectorProps<
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined
> = Omit<AutocompleteProps<string, Multiple, DisableClearable, true>, "options" | "renderInput"> & {
    fieldId: string
    label?: string
}

export const RegistrationFieldValueSelector = <Multiple extends boolean | undefined, DisableClearable extends boolean | undefined>({
    fieldId,
    ...props
}: RegistrationFieldValueSelectorProps<Multiple, DisableClearable>) => {
    const client = useClient()!
    const event = useEvent()!
    const valuesQuery = backend.registrations.useGetAll([client.id, event.id], {
        select: registrations => _(registrations as Registration[])
            .map(registration => registration.fields[fieldId])
            .map(value => typeof value === 'string' ? value : undefined)
            .compact()
            .uniq()
            .value()
    })

    return <Autocomplete
        options={valuesQuery.data ?? []}
        loading={valuesQuery.isLoading}
        renderInput={(params) => <TextField {...params} label={props.label} />}
        freeSolo
        filterSelectedOptions
        {...props}
    />
}