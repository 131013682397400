import { AdminUser } from '@marketpartner/backend-api';
import CancelIcon from '@mui/icons-material/Cancel';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { FC } from 'react';
import { backend } from 'src/common/api';
import { ConfirmDialogForm } from 'src/common/dialogs/ConfirmDialogForm';
import { DialogButton } from 'src/common/dialogs/DialogButton';
import { Bar } from 'src/common/layout/Bar';
import { withNotification } from 'src/common/notifications/with-notification';

export type UserMfaStatusProps = {
    user: AdminUser
}

export const UserMfaStatus: FC<UserMfaStatusProps> = ({
    user,
}) => {
    const isAdmin = user.roles.some(role => role.role === "Admin")
    if (!isAdmin) {
        return <></>
    }

    const iconProps = {
        sx: { m: 1.5 }
    }
    return <Bar>
        {user.mfaEnabled ?
            <CheckCircleIcon {...iconProps} color="success" /> :
            <EnableMfaButton user={user} />
        }
        {user.mfaEnabled ?
            <DisableMfaButton user={user} /> :
            <CancelIcon {...iconProps} color="error" />
        }
    </Bar>
}

type MfaButtonProps = {
    user: AdminUser
}

const EnableMfaButton: FC<MfaButtonProps> = ({
    user
}) => {
    const enableMutation = backend.adminUsers.useEnableMfa(withNotification({
        successMessage: "MFA enabled",
        errorMessage: "Failed to enable MFA"
    }))

    return <DialogButton color="error" icon={<CheckCircleOutlinedIcon />}>
        <ConfirmDialogForm
            action={() => enableMutation.mutateAsync([user.id])}
            text={<>This will enable MFA for {user.name}'s <i>existing MFA device</i>.<br /><br />It will fail if they have no device set up.</>}
            submitText='Enable MFA'
        />
    </DialogButton>
}

const DisableMfaButton: FC<MfaButtonProps> = ({
    user
}) => {
    const disableMutation = backend.adminUsers.useDisableMfa(withNotification({
        successMessage: "MFA disabled",
        errorMessage: "Failed to disable MFA"
    }))

    return <DialogButton color="error" icon={<CancelOutlinedIcon />}>
        <ConfirmDialogForm
            action={() => disableMutation.mutateAsync([user.id])}
            text={`${user.name} will be prompted to set up MFA when they next access the console`}
            submitText='Disable MFA'
        />
    </DialogButton>
}