import { MenuItem, MenuItemProps } from "@mui/material";
import { FC } from "react";
import { useMenuContext } from "src/common/menus/LockableMenu";


export type LoadingMenuItemProps = Omit<MenuItemProps, "onClick"> & {
    onClick: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void | Promise<unknown>
}

export const LoadingMenuItem: FC<LoadingMenuItemProps> = ({
    onClick,
    ...props
}) => {
    const menuContext = useMenuContext()

    return <MenuItem
        onClick={e => {
            menuContext.closeAfter(Promise.resolve(onClick(e)))
            e.stopPropagation()
        }}
        {...props}
    />
}