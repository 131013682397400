import { FormFieldConfig } from "@marketpartner/backend-api";
import { Grid, TextField } from "@mui/material";
import { PropertyUpdater } from "src/forms/elements/types/formElementConfigTypes";
import { FormElementNameField } from "src/forms/elements/types/FormElementNameField";
import { RequiredField } from "src/forms/elements/types/RequiredField";


export type StandardFieldsProps<Config extends FormFieldConfig> = {
    config: Config
    updateConfigProperty: PropertyUpdater<Config>
}

export const StandardFields = <Config extends FormFieldConfig>({
    config,
    updateConfigProperty,
}: StandardFieldsProps<Config>) => {
    return <>
        <Grid item xs={12}>
            <RequiredField
                required={config.required}
                setRequired={(required) => updateConfigProperty("required" as any, required)}
            />
        </Grid>
        <Grid item xs={12}>
            <FormElementNameField
                name={config.name}
                setName={name => updateConfigProperty('name' as any, name)}
            />
        </Grid>
        {"label" in config && <Grid item xs={12}>
            <TextField
                label="Label"
                fullWidth
                value={config.label}
                onChange={(e) => updateConfigProperty('label' as any, e.target.value)}
            />
        </Grid>}
    </>
}