import { AdminUser, Role } from '@marketpartner/backend-api';
import AddIcon from '@mui/icons-material/Add';
import { DialogTitle } from "@mui/material";
import { FC, useState } from "react";
import { RoleSelector } from "src/access/RoleSelector";
import { backend } from 'src/common/api';
import { DialogButton } from "src/common/dialogs/DialogButton";
import { DialogForm } from "src/common/dialogs/DialogForm";
import { withNotification } from 'src/common/notifications/with-notification';

export type AttachRoleButtonProps = {
    user: AdminUser
}

export const AttachRoleButton: FC<AttachRoleButtonProps> = ({
    user
}) => {
    return <DialogButton
        icon={<AddIcon />}
        color="primary"
        dialogProps={{
            fullWidth: true,
            maxWidth: "sm",
        }}
    >
        <DialogTitle>Attach role to {user.name}</DialogTitle>
        <AttachRoleForm userId={user.id} />
    </DialogButton>
}

type AttachRoleFormProps = {
    userId: string
}

const AttachRoleForm: FC<AttachRoleFormProps> = ({
    userId
}) => {
    const [role, setRole] = useState<Role | null>(null)
    const [clientId, setClientId] = useState<string | null>(null)
    const [eventId, setEventId] = useState<string | null>(null)
    const attachRoleMutation = backend.adminUsers.useAttachUserRole(withNotification({
        successMessage: `Attached role ${role}`,
        errorMessage: "Error attaching role"
    }))

    return <DialogForm
        isValid={Boolean(role)}
        formData={{
            role: role!,
            clientId,
            eventId
        }}
        actionName="Attach"
        onSubmit={formData => attachRoleMutation.mutateAsync([userId, formData])}
    >
        <RoleSelector
            excludedRoles={[Role.RegisteredUser, Role.Scanner]}
            role={role}
            onChangeRole={setRole}
            clientId={clientId}
            onChangeClientId={setClientId}
            eventId={eventId}
            onChangeEventId={setEventId}
        />
    </DialogForm>
}