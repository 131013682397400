import { Backdrop, CircularProgress, useTheme } from "@mui/material";
import { FC, ReactNode } from "react";
import { LoadingSpinner } from "src/common/loading/LoadingSpinner";
import { center } from "src/common/styles";

export type EnsureLoadedProps = {
    isLoading: boolean
    isReloading: boolean
    children: ReactNode
}

/**
 * When reloading, displays a backdrop over the entire screen with a loading spinner.
 * This is used to prevent interaction with the page when the current event/client is edited/reloading.
 */
export const EnsureLoaded: FC<EnsureLoadedProps> = ({
    isLoading,
    isReloading,
    children
}) => {
    const theme = useTheme()
    if (isLoading) {
        return <LoadingSpinner sx={{ ...center }}/>
    }
    if (isReloading) {
        return <>
            {children}
            <Backdrop open sx={{
                zIndex: theme.zIndex.drawer + 1,
                color: '#fff',
            }}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        </>
    }
    return <>{children}</>
}