import { Delete, Edit } from "@mui/icons-material";
import { ListItemIcon, ListItemText, Menu, MenuItem, MenuProps } from "@mui/material";
import { FC } from "react";
import { createLocalPopupHook } from "src/common/dialogs/LocalPopup";
import { DeleteFormElementDialogProps, useDeleteFormElementDialog } from "src/forms/elements/DeleteFormElementDialog";
import { EditFormElementDialogProps, useEditFormElementDialog } from "src/forms/elements/EditFormElementDialog";


export type FormElementContextMenuProps = MenuProps &
    Pick<EditFormElementDialogProps, "element" | "onChangeElement"> &
    Pick<DeleteFormElementDialogProps, "element" | "onDelete"> & {
        onClose: () => void
    }

const FormElementContextMenu: FC<FormElementContextMenuProps> = ({
    element,
    onChangeElement,
    onDelete,
    ...props
}) => {
    const editDialog = useEditFormElementDialog()
    const deleteDialog = useDeleteFormElementDialog()

    return <Menu {...props} onClick={props.onClose}>
        <MenuItem onClick={() => editDialog.open({
            element,
            onChangeElement,
        })}>
            <ListItemIcon><Edit color="primary" /></ListItemIcon>
            <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => deleteDialog.open({
            element,
            onDelete,
        })}>
            <ListItemIcon><Delete color="error" /></ListItemIcon>
            <ListItemText>Delete</ListItemText>
        </MenuItem>
    </Menu>
}

export const useFormElementContextMenu = createLocalPopupHook({
    element: FormElementContextMenu,
})