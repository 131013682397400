import { Box, Chip, TextField } from "@mui/material"
import { FC, useState, ChangeEvent, KeyboardEvent } from "react"

export type DomainListProps = {
    values: string[]
    onChange: (values: string[]) => void
}

export const DomainList: FC<DomainListProps> = ({
    values,
    onChange
}) => {
    const [newDomain, setNewDomain] = useState("")
    const domainSet = new Set(values)

    function addTag(domain: string) {
        if (domain.length) {
            domainSet.add(domain)
            onChange(Array.from(domainSet))
        }
    }

    function removeDomain(domain: string) {
        domainSet.delete(domain)
        onChange(Array.from(domainSet))
    }

    function newDomainTextChanged(e: ChangeEvent<HTMLInputElement>) {
        setNewDomain(e.target.value
            .replace(/[\s@]/g, "")
        )
    }

    function newDomainKeyPress(e: KeyboardEvent<HTMLInputElement>) {
        if (e.key === "Enter" && !e.ctrlKey) {
            const domains = newDomain.split(",").map(it => it.trim())
            for (const domain of domains) {
                addTag(domain)
            }
            setNewDomain("")
            e.preventDefault()
        }
    }

    return <Box width="100%" display="flex" flexDirection="column" alignItems="flex-start">
        <Box pt={1} pb={0.5} width="100%">
            {values.map(value => <Box key={value} my={0.5}>
                <Chip
                    size="small"
                    label={value}
                    onDelete={() => removeDomain(value)}
                    style={{maxWidth: "100%"}}
                />
            </Box>)}
        </Box>
        <TextField
            value={newDomain}
            onChange={newDomainTextChanged}
            onKeyDown={newDomainKeyPress}
            label="Add new domains (comma separated)"
            helperText="Enter to confirm"
            fullWidth
        />
    </Box>
}