import { FormElementConfig } from "@marketpartner/backend-api";
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { createLocalPopupHook } from "src/common/dialogs/LocalPopup";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { FormElementForm } from "src/forms/elements/FormElementForm";

export type EditFormElementDialogProps = LockableDialogProps & {
    element: FormElementConfig
    onChangeElement: (element: FormElementConfig) => Promise<unknown>
}

const EditFormElementDialog: FC<EditFormElementDialogProps> = ({
    element,
    onChangeElement,
    ...props
}) => {
    return <LockableDialog {...props}>
        <DialogTitle>Edit form element</DialogTitle>
        <FormElementForm
            formElement={element}
            onSubmit={onChangeElement}
            actionName="Update"
        />
    </LockableDialog>
}

export const useEditFormElementDialog = createLocalPopupHook({
    element: EditFormElementDialog,
})