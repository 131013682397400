import { Scope, TicketType } from "@marketpartner/backend-api";
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { useEvent } from "src/events/event-context";
import { TicketTypeForm } from "src/payment/ticket-types/TicketTypeForm";


export type EditTicketTypeDialogProps = LockableDialogProps & {
    ticketType: TicketType
}

const EditTicketTypeDialog: FC<EditTicketTypeDialogProps> = ({
    ticketType,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!

    const updateTicketTypeMutation = backend.ticketTypes.useUpdate(withNotification({
        errorMessage: "Error updating ticket type",
    }))

    return <LockableDialog
        maxWidth="sm"
        fullWidth
        {...props}
    >
        <DialogTitle>Edit ticket type</DialogTitle>
        <TicketTypeForm
            actionName="Save"
            ticketType={ticketType}
            onSubmit={formData => updateTicketTypeMutation.mutateAsync([client.id, event.id, ticketType.id, formData])}
        />
    </LockableDialog>
}

export const useEditTicketTypeDialog = createPopupHook({
    element: EditTicketTypeDialog,
    scope: Scope.Event,
})