import { fullName } from "@marketpartner/mp-common"
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"
import { FC } from "react"
import { UserTimeline } from "src/activities/user-usage/UserTimeline"
import { UsageUser } from "src/activities/user-usage/UserUsagePopup"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { useDialogContext } from "src/common/dialogs/LockableDialog"
import { Loading } from "src/common/loading/Loading"
import { useEvent } from "src/events/event-context"

export type UserUsagePopupContentProps = {
    user: UsageUser
}

export const UserUsagePopupContent: FC<UserUsagePopupContentProps> = ({
    user
}) => {
    const dialog = useDialogContext()
    const client = useClient()!
    const event = useEvent()!
    const usageRequest = backend.activities.useGetUserActivityUsage([client.id, event.id, user.principal], {
        refetchInterval: 10_000
    })

    return <>
        <DialogTitle>
            <Typography variant="h4">{fullName(user)}</Typography>
            <Typography variant="subtitle1">{event.timezone}</Typography>
        </DialogTitle>
        <DialogContent>
            <Loading
                request={usageRequest}
                sizing="fit-content"
                render={usage =>
                    <UserTimeline usage={usage}/>
                }
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={() => dialog.close()} color="inherit">
                Close
            </Button>
        </DialogActions>
    </>
}