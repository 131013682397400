import { Permission } from "@marketpartner/backend-api";
import AddIcon from '@mui/icons-material/Add';
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { useNavigate } from 'react-router';
import { useClient } from "src/clients/client-context";
import { backend } from 'src/common/api';
import { DialogButton } from "src/common/dialogs/DialogButton";
import { withNotification } from "src/common/notifications/with-notification";
import { link } from "src/common/routing/routes";
import { useEvent } from "src/events/event-context";
import { FormForm } from "src/forms/FormForm";
import { ifHas } from "src/login/IfHas";

export type CreateFormButtonProps = {}

export const CreateFormButton: FC<CreateFormButtonProps> = () => {
    const client = useClient()!
    const event = useEvent()!
    const navigate = useNavigate()
    const createMutation = backend.forms.useCreate(withNotification({
        onSuccess: result => navigate(link.toForm(client.id, event.id, result.id)),
        successMessage: (_, formDetails) => `Created form ${formDetails[2].name}`,
        errorMessage: "Error creating event",
    }))

    return ifHas(Permission.EditForms, client.id, event.id,
        <DialogButton
            icon={< AddIcon />}
            text="Create form"
            variant="contained"
            color="primary"
        >
            <DialogTitle>Create form</DialogTitle>
            <FormForm
                actionName="Create"
                onSubmit={formDetails => createMutation.mutateAsync([client.id, event.id, {
                    ...formDetails,
                    elementList: []
                }])}
            />
        </DialogButton>
    )
}