import { Scope } from "@marketpartner/backend-api";
import { CopyAll, Edit } from "@mui/icons-material";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { FC } from "react";
import { createPopupHook } from "src/common/dialogs/Popup";
import { LockableMenu, LockableMenuProps } from "src/common/menus/LockableMenu";
import { useCopyDynamicEmailTemplateDialog } from "src/email/templates/CopyDynamicEmailTemplateDialog";
import { useCreateDynamicEmailTemplateDialog } from "src/email/templates/CreateDynamicEmailTemplateDialog";


export type AddDynamicEmailTemplateMenuProps = LockableMenuProps

const AddDynamicEmailTemplateMenu: FC<AddDynamicEmailTemplateMenuProps> = ({
    ...props
}) => {
    const createDialog = useCreateDynamicEmailTemplateDialog()
    const copyDialog = useCopyDynamicEmailTemplateDialog()

    return <LockableMenu {...props} closeOnClick >
        <MenuItem onClick={() => createDialog.open({})}>
            <ListItemIcon><Edit color="primary" /></ListItemIcon>
            <ListItemText primary="Create new template" />
        </MenuItem>
        <MenuItem onClick={() => copyDialog.open({})}>
            <ListItemIcon><CopyAll /></ListItemIcon>
            <ListItemText primary="Copy from client" />
        </MenuItem>
    </LockableMenu >
}

export const useAddDynamicEmailTemplateMenu = createPopupHook({
    element: AddDynamicEmailTemplateMenu,
    scope: Scope.Client,
})