import { AdminMeetingRequest, Scope } from "@marketpartner/backend-api";
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { useEvent } from "src/events/event-context";
import { ScheduleMeetingForm } from "src/networking/meetings/scheduling/ScheduleMeetingForm";


export type RescheduleMeetingDialogProps = LockableDialogProps & {
    request: AdminMeetingRequest
}

const RescheduleMeetingDialog: FC<RescheduleMeetingDialogProps> = ({
    request,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!

    const updateMeetingMutation = backend.meetings.useUpdateMeetingRequest(withNotification({
        errorMessage: "Error updating meeting",
    }))

    return <LockableDialog
        {...props}
    >
        <DialogTitle>Reschedule meeting</DialogTitle>
        <ScheduleMeetingForm
            actionName="Save"
            requesterId={request.requesterId}
            respondantId={request.respondantId}
            existingStart={request.start}
            existingEnd={request.end}
            existingLocationId={request.locationId}
            onSubmit={formData => updateMeetingMutation.mutateAsync([client.id, event.id, request.meetingId, formData])}
        />
    </LockableDialog>
}

export const useRescheduleMeetingDialog = createPopupHook({
    element: RescheduleMeetingDialog,
    scope: Scope.Event,
})
