import { AdminUser } from '@marketpartner/backend-api'
import { MenuItem } from '@mui/material'
import { FC, useState } from 'react'
import { ResetAdminPasswordDialogContent } from 'src/access/users/ResetAdminPasswordDialogContent'
import { LockableDialog } from 'src/common/dialogs/LockableDialog'

export type ResetAdminPasswordMenuItemProps = {
    user: AdminUser
    closeMenu: () => void
}

export const ResetAdminPasswordMenuItem: FC<ResetAdminPasswordMenuItemProps> = ({
    user,
    closeMenu
}) => {
    const [resetPasswordOpen, setResetPasswordOpen] = useState(false)

    const openResetPassword = () => {
        setResetPasswordOpen(true)
        closeMenu()
    }
    const closeResetPassword = () => {
        setResetPasswordOpen(false)
        closeMenu()
    }

    return <>
        <MenuItem onClick={openResetPassword}>Manual password reset</MenuItem>
        <LockableDialog open={resetPasswordOpen} onClose={closeResetPassword}>
            <ResetAdminPasswordDialogContent user={user} />
        </LockableDialog>
    </>
}

