import { ExternalLink } from "@marketpartner/mp-common-mui"
import { Grow } from "@mui/material"
import { Box } from "@mui/system"
import { FC, ReactNode } from "react"
import { link } from "src/common/routing/routes"

export type EventPartnerLinkProps = {
    id: string | null
    children?: ReactNode
}

export const EventPartnerLink: FC<EventPartnerLinkProps> = ({
    id,
    children = "Open"
}) => {
    return <Grow in={Boolean(id)}>
        <Box>
            <ExternalLink href={link.toEp(id!)}>
                {children}
            </ExternalLink>
        </Box>
    </Grow>
}