import { createContext, FC, ReactNode, useContext, useState } from "react";

export enum FeedDisplayMode {
    Threaded = "Threaded",
    Chronological = "Chronological",
    Pending = "Pending",
}

export type FeedDisplayContext = {
    displayMode: FeedDisplayMode
    setDisplayMode: (displayMode: FeedDisplayMode) => unknown
    showDeletedItems: boolean
    setShowDeletedItems: (showDeletedMessages: boolean) => unknown
}

const context = createContext<FeedDisplayContext>(undefined!)

export const useFeedDisplayOptions = () => useContext(context)

export type FeedDisplayContextProviderProps = {
    children: ReactNode
}

export const FeedDisplayContextProvider: FC<FeedDisplayContextProviderProps> = ({
    children
}) => {
    const [displayMode, setDisplayMode] = useState(FeedDisplayMode.Threaded)
    const [showDeletedItems, setShowDeletedItems] = useState(false)

    return <context.Provider children={children} value={{
        displayMode,
        setDisplayMode,
        showDeletedItems,
        setShowDeletedItems,
    }}/>
}