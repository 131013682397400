import { AppTemplateDeployment } from "@marketpartner/backend-api"
import { Edit } from "@mui/icons-material"
import { DialogTitle } from "@mui/material"
import { FC } from "react"
import { EditAppDeploymentForm } from "src/app-templates/deployments/EditAppDeploymentForm"
import { DialogButton, DialogButtonProps } from "src/common/dialogs/DialogButton"


export type EditAppDeploymentButtonProps = Partial<DialogButtonProps> & {
    deployment: AppTemplateDeployment
}

export const EditAppDeploymentButton: FC<EditAppDeploymentButtonProps> = ({
    deployment,
    ...props
}) => {
    return <DialogButton
        icon={<Edit />}
        color="primary"
        {...props}
        dialogProps={{
            maxWidth: "sm",
            fullWidth: true,
        }}
    >
        <DialogTitle>Edit {deployment.name}</DialogTitle>
        <EditAppDeploymentForm deployment={deployment}/>
    </DialogButton>
}