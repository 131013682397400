import { Scope } from "@marketpartner/backend-api";
import { Alert, DialogTitle } from "@mui/material";
import { Interval } from "luxon";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { EditableDialogList } from "src/common/dialogs/EditableDialogList";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { Bar } from "src/common/layout/Bar";
import { EventDateTimeText } from "src/common/time/DateTimeText";
import { useEvent } from "src/events/event-context";
import { useCreateNetworkingPeriodDialog } from "src/networking/meetings/periods/CreateNetworkingPeriodDialog";
import { useDeleteNetworkingPeriodDialog } from "src/networking/meetings/periods/DeleteNetworkingPeriodDialog";
import { useEditNetworkingPeriodDialog } from "src/networking/meetings/periods/EditNetworkingPeriodDialog";


export type NetworkingPeriodsDialogProps = LockableDialogProps

const NetworkingPeriodsDialog: FC<NetworkingPeriodsDialogProps> = (props) => {
    const client = useClient()!
    const event = useEvent()!

    const periodsQuery = backend.networkingPeriods.useGetPeriods([client.id, event.id])

    const createPeriodDialog = useCreateNetworkingPeriodDialog()
    const editPeriodDialog = useEditNetworkingPeriodDialog()
    const deletePeriodDialog = useDeleteNetworkingPeriodDialog()

    const intervals = (periodsQuery.data ?? [])
        .map(it => Interval.fromDateTimes(it.start, it.end))
    const overlapExists = intervals.some(interval =>
        intervals.some(other => interval !== other && interval.overlaps(other))
    )

    return <LockableDialog
        maxWidth="sm"
        fullWidth
        {...props}
    >
        <DialogTitle>Networking periods</DialogTitle>
        {overlapExists && <Alert severity="warning">
            Two or more networking periods overlap.
        </Alert>}
        <EditableDialogList
            itemsQuery={periodsQuery}
            getPrimaryText={period => period.name}
            getSecondaryText={period => `${period.timeslotDuration} m timeslots`}
            getTeritaryText={period => <Bar spacing={2}>
                <EventDateTimeText dateTime={period.start} hideTimezone />
                <EventDateTimeText dateTime={period.end} hideTimezone />
            </Bar>}
            addProps={{
                onClickAdd: () => createPeriodDialog.open({ networkingPeriods: periodsQuery.data ?? [] }),
            }}
            contextMenuProps={period => ({
                onClickEdit: () => editPeriodDialog.open({ period }),
                onClickDelete: () => deletePeriodDialog.open({ period }),
            })}
        />
    </LockableDialog>
}

export const useNetworkingPeriodsDialog = createPopupHook({
    element: NetworkingPeriodsDialog,
    scope: Scope.Event,
})