import { RegistrationCategory } from "@marketpartner/backend-api"
import { Collapse, Stack } from "@mui/material"
import { sortBy } from "lodash"
import { FC, useState } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { Loading } from "src/common/loading/Loading"
import { useEvent } from "src/events/event-context"
import { EventSelector } from "src/events/EventSelector"
import { categorySortKey } from "src/registrations/configuration/categories/category-sort-key"
import { SelectableCategoriesList } from "src/registrations/configuration/categories/copying/SelectableCategoriesList"


export type SourceCategoriesSelectorProps = {
    categories: RegistrationCategory[]
    onChangeCategories: (categories: RegistrationCategory[]) => void
}

export const SourceCategoriesSelector: FC<SourceCategoriesSelectorProps> = ({
    categories,
    onChangeCategories,
}) => {
    const client = useClient()!
    const event = useEvent()!
    const [sourceEventId, setSourceEventId] = useState<string | null>(null)
    const existingIdsQuery = backend.registrationCategories.useGetAll([client.id, event.id], {
        select: categories => categories.map(category => category.id),
    })
    const existingIds = existingIdsQuery.data ?? []
    const sourceCategoriesQuery = backend.registrationCategories.useGetAll([client.id, sourceEventId!], {
        select: categories => sortBy(categories, categorySortKey),
        enabled: Boolean(sourceEventId),
    })

    const handleEventIdChange = (eventId: string | null) => {
        if (eventId) {
            setSourceEventId(eventId)
            onChangeCategories([])
        }
    }

    return <Stack>
        <EventSelector
            selectedId={sourceEventId}
            onSelectId={handleEventIdChange}
            required
            omitSelf
            size="small"
            fullWidth
        />
        <Collapse in={Boolean(sourceEventId)}>
            <Loading request={sourceCategoriesQuery} sizing="fit-content" render={sourceCategories => <>
                <SelectableCategoriesList
                    categories={sourceCategories}
                    selectedCategories={categories}
                    onChangeSelectedCategories={onChangeCategories}
                    excludedIds={existingIds}
                />
            </>} />
        </Collapse>
    </Stack>
}