import { Registration } from '@marketpartner/backend-api'
import { fullName } from '@marketpartner/mp-common'
import { Stack, SxProps, Typography } from '@mui/material'
import React from 'react'
import { Bar } from 'src/common/layout/Bar'
import { FormDataTable } from 'src/forms/elements/FormDataTable'
import { ProfileAvatar } from 'src/registrations/profiles/ProfileAvatar'

export type ProfileDetailsProps = {
    registration: Registration
    sx?: SxProps
}

export const ProfileDetails: React.FC<ProfileDetailsProps> = ({
    registration,
    sx,
}) => {
    const {
        title,
        first_name,
        last_name,
        email,
        profile_picture,
        direct_message_opt_in,
        allow_meeting_requests,
        profile_visible,
        job_title,
        company,
        bio,
        ...otherProfileData
    } = registration.profile
    const name = fullName(registration.profile)

    return <Stack sx={{ ...sx }}>
        <Bar spacing={2} p={2}>
            <ProfileAvatar registration={registration} />
            <Stack>
                <Typography variant="h5">{name}</Typography>
                {email && <Typography variant="body2">{email}</Typography>}
                {job_title && <Typography variant="subtitle1">{job_title}</Typography>}
                {company && <Typography variant="subtitle1">{company}</Typography>}
            </Stack>
            <Stack sx={{ pl: 4 }}>
                <Typography variant="h6">
                    Networking preferences
                </Typography>
                <Typography>
                    <b>Profile visible:</b> {profile_visible ? "Yes" : "No"}
                </Typography>
                <Typography>
                    <b>Direct messaging:</b> {direct_message_opt_in ? "Yes" : "No"}
                </Typography>
                <Typography>
                    <b>Allow meeting requests:</b> {allow_meeting_requests ? "Yes" : "No"}
                </Typography>
            </Stack>
        </Bar>
        {bio && <Stack p={2}>
            <Typography variant="h6">Bio</Typography>
            <Typography whiteSpace="pre-wrap">{bio}</Typography>
        </Stack>}
        {Object.keys(otherProfileData).length > 0 && <Stack>
            <Typography variant="h6" ml={2}>Other properties</Typography>
            <FormDataTable data={otherProfileData} />
        </Stack>}
    </Stack>
}