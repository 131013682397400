import { ExternalLink } from "@marketpartner/mp-common-mui"
import { Stack, Typography } from "@mui/material"
import { FC } from "react"
import { backend } from "src/common/api"
import { ConfirmDialogForm } from "src/common/dialogs/ConfirmDialogForm"
import { DialogButton } from "src/common/dialogs/DialogButton"
import { withNotification } from "src/common/notifications/with-notification"


export const RotateApiKeyContent: FC = () => {
    const rotateMutation = backend.sysadmin.useRotateKey(withNotification({
        successMessage: "API key rotated",
        errorMessage: "Perform manual check of API key rotation.",
    }))

    return <Stack spacing={2}>
        <Typography>
            The API key is used to verify that all requests originate from CloudFront, and that API Gateway cannot be accessed directly.
            This ensures all requests are suject to the Web Application Firewall rules (which are applied to CloudFront, not API Gateway).
            The following steps are performed:
        </Typography>
        <ol>
            <li>A new key is generated and added to the <i>/backend/api-keys</i> parameter.
                The previous API key is retained to ensure the API remains accessible during rotation</li>
            <li>All CloudFront distributions that have an <i>x-api-key</i> header are updated to use the new key</li>
            <li>Old key(s) are removed from the <i>/backend/api-keys</i> parameter</li>
        </ol>
        <Typography>
            API key rotation requires modifications to CloudFront, which can take several minutes. The request will therefore always timeout/error.
            This does not mean key rotation has failed, but a manual check is required to ensure it completed successfully:
        </Typography>
        <ul>
            <li><ExternalLink href="https://eu-west-2.console.aws.amazon.com/lambda/home?region=eu-west-2#/functions/sysadmin-default-rotateKey?tab=monitoring">Check the logs</ExternalLink> for the <i>sysadmin-default-rotateKey</i> function and make sure it does not contain any errors</li>
            <li>After successful rotation, the <i>/backend/api-keys</i> parameter in AWS Systems Manager <ExternalLink href="https://eu-west-2.console.aws.amazon.com/systems-manager/parameters/backend/api-keys/description?region=eu-west-2&tab=Table">Parameter Store</ExternalLink> should contain a single key</li>
            <li>All <ExternalLink href="https://us-east-1.console.aws.amazon.com/cloudfront/v3/home?region=eu-west-2#/distributions">CloudFront instances</ExternalLink> should have the correct API key set in the <i>x-api-key</i> header</li>
            <li>API calls should work</li>
        </ul>
        <DialogButton
            text="Rotate API key"
            variant="contained"
            color="error"
        >
            <ConfirmDialogForm
                action={() => rotateMutation.mutateAsync(["api-key"])}
                confirmText="Rotate API key"
                submitColor="error"
                text=""
            />
        </DialogButton>
    </Stack>
}