import { getErrorString, sleep } from "@marketpartner/mp-common"
import { ExternalLink } from "@marketpartner/mp-common-mui"
import { CircularProgress, Collapse, Grow, Stack, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useMutation } from "@tanstack/react-query"
import { FC, useState } from "react"
import { backend } from "src/common/api"
import { ConfirmDialogForm } from "src/common/dialogs/ConfirmDialogForm"
import { DialogButton } from "src/common/dialogs/DialogButton"

const cacheInvalidationDelayMinutes = 1

export const RotateRedisAccessTokenContent: FC = () => {
    const [messages, setMessages] = useState<string[]>([])
    const rotateMutation = backend.sysadmin.useRotateKey()

    const removeOldToken = useMutation({
        mutationFn: () => rotateMutation.mutateAsync(["lru-cache-token", "disable-old-token"]),
        onMutate: () => addMessage("Removing old access token"),
        onError: error => addMessage(getErrorString(error)),
        onSuccess: () => addMessage("Done!"),
    })
    const waitForCacheInvalidation = useMutation({
        mutationFn: () => sleep(cacheInvalidationDelayMinutes * 60_000),
        onMutate: () => addMessage(`Waiting for ${cacheInvalidationDelayMinutes}m for lambda functions to use new token`),
        onError: error => addMessage(getErrorString(error)),
        onSuccess: () => removeOldToken.mutateAsync(),
    })
    const addNewToken = useMutation({
        mutationFn: () => rotateMutation.mutateAsync(["lru-cache-token", "set-new-token"]),
        onMutate: () => addMessage("Creating new access token"),
        onError: error => addMessage(getErrorString(error)),
        onSuccess: () => waitForCacheInvalidation.mutateAsync(),
    })

    function addMessage(message: string) {
        setMessages(messages => [...messages, message])
    }

    const isLoading = removeOldToken.isPending ||
        waitForCacheInvalidation.isPending ||
        addNewToken.isPending

    return <Stack spacing={2}>
        <Typography>
            This will perform the following steps (without service downtime, provided that no steps fail):
        </Typography>
        <ol>
            <li>Generate a new access token and set as an <b>addional</b> password on the Redis cluster, leaving the existing password intact to ensure no down-time</li>
            <li>Update the <i>/redis/lru-cache/auth-token</i> parameter in <ExternalLink href="https://eu-west-2.console.aws.amazon.com/systems-manager/parameters/redis/lru-cache/auth-token/description?region=eu-west-2&tab=Table">Parameter Store</ExternalLink></li>
            <li>Wait for <i>{cacheInvalidationDelayMinutes}m</i> to ensure no lambda functions have the old token value cached</li>
            <li>Remove the old password from the Redis cluster</li>
        </ol>
        <DialogButton
            variant="contained"
            color="error"
            text="Rotate Redis token"
        >
            <ConfirmDialogForm
                confirmText="Rotate Redis token"
                submitColor="error"
                action={async () => addNewToken.mutate()}
                text=""
            />
        </DialogButton>
        <Collapse in={Boolean(messages.length)}>
            <Stack spacing={1}>
                <Typography variant="h6">Output</Typography>
                <Box sx={{ border: 1, borderColor: "grey.500", backgroundColor: "grey.200" }}>
                    {messages.map((message, idx) => <Typography key={idx} sx={{ fontFamily: "monospace" }}>
                        {message}
                    </Typography>)}
                    <Grow in={isLoading} unmountOnExit>
                        <CircularProgress size={16} sx={{ mx: 1 }} />
                    </Grow>
                </Box>
                <ExternalLink href="https://eu-west-2.console.aws.amazon.com/lambda/home?region=eu-west-2#/functions/sysadmin-default-rotateKey?tab=monitoring">View lambda logs</ExternalLink>
            </Stack>
        </Collapse>
    </Stack>
}