import { EventSegment, Permission } from "@marketpartner/backend-api"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { DeleteButton } from "src/common/dialogs/DeleteButton"
import { useEvent } from "src/events/event-context"
import { ifHas } from "src/login/IfHas"

export type DeleteSegmentButtonProps = {
    segment: EventSegment
}

export const DeleteSegmentButton: FC<DeleteSegmentButtonProps> = ({
    segment
}) => {
    const client = useClient()!
    const event = useEvent()!
    const deleteMutation = backend.eventSegments.useDelete()

    return ifHas(Permission.EditEventDetails, client.id, event.id,
        <DeleteButton
            text=""
            itemName={segment.name}
            deleteAction={() => deleteMutation.mutateAsync([client.id, event.id, segment.id])}
            size="small"
        />
    )
}