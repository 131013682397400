import { Client } from "@marketpartner/backend-api"
import { LoadedSelector, LoadedSelectorProps } from "@marketpartner/mp-common-mui"
import { FC } from "react"
import { backend } from "src/common/api"

export type ClientSelectorProps = Partial<LoadedSelectorProps<string, Client>> & {
    clientId: string | null
    onSelectClientId: (clientId: string | null) => void
}

export const ClientSelector: FC<ClientSelectorProps> = ({ 
    clientId,
    onSelectClientId,
    ...props
}) => {
    const clientsQuery = backend.clients.useGetAll([])

    return <LoadedSelector
        fetchRequest={clientsQuery}
        label="Client"
        name="clientId"
        selectedId={clientId}
        onSelectId={onSelectClientId}
        {...props}
    />
}