import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { DeleteButton } from "src/common/dialogs/DeleteButton";
import { useEvent } from "src/events/event-context";

export type DeleteFormResponseButtonProps = {
    formId: number
    formResponseId: number
}

export const DeleteFormResponseButton: FC<DeleteFormResponseButtonProps> = ({ formId, formResponseId }) => {
    const client = useClient()!
    const event = useEvent()!

    const deleteMutation = backend.formResponses.useDelete()

    return <DeleteButton
        buttonText=""
        itemName="response"
        text="This will permanently delete this form element"
        deleteAction={() => deleteMutation.mutateAsync([client.id, event.id, formId, formResponseId])}
    />
}