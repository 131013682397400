import { Scope } from "@marketpartner/backend-api";
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { AgendaForm } from "src/content/agendas/AgendaForm";
import { useEvent } from "src/events/event-context";


type CreateAgendaDialogProps = LockableDialogProps

const CreateAgendaDialog: FC<CreateAgendaDialogProps> = ({
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const createAgendaMutation = backend.agendas.useCreate(withNotification({
        successMessage: "Created agenda",
        errorMessage: "Error creating agenda",
    }))

    return <LockableDialog {...props}>
        <DialogTitle>
            Create agenda
        </DialogTitle>
        <AgendaForm
            onSubmit={formData => createAgendaMutation.mutateAsync([client.id, event.id, {
                ...formData,
            }])}
            actionName="Create"
        />
    </LockableDialog>
}

export const useCreateAgendaDialog = createPopupHook({
    scope: Scope.Event,
    element: CreateAgendaDialog,
})
