import { FC } from "react";
import { ProgressWithLabel } from "src/common/loading/ProgressWithLabel";
import { UseImportMutation } from "src/registrations/import/import-process/useImportMutation";


export type ImportProgressProps = {
    importMutation: UseImportMutation
    registrationCount: number
}

export const ImportProgress: FC<ImportProgressProps> = ({
    importMutation,
    registrationCount,
}) => {
    return <ProgressWithLabel
        percentage={importMutation.processed / registrationCount * 100}
    />
}