import { RelatedContentId, RelatedContentType } from "@marketpartner/backend-api"
import { useRelatedContent } from "@marketpartner/mp-common-react"
import { Segment } from "@mui/icons-material"
import { Button, Stack } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { Bar } from "src/common/layout/Bar"
import { BarAndFlex } from "src/common/layout/BarAndFlex"
import { Spacer } from "src/common/layout/Spacer"
import { Loading } from "src/common/loading/Loading"
import { fullSize } from "src/common/styles"
import { EditableRelatedContentCategory } from "src/content/related-content-categories/EditableRelatedContentCategory"
import { useRelatedContentCategoriesDialog } from "src/content/related-content-categories/RelatedContentCategoriesDialog"
import { useEvent } from "src/events/event-context"


export type EditableRelatedContentProps = {
    relatedToType: RelatedContentType
    relatedToId: RelatedContentId
}

export const EditableRelatedContent: FC<EditableRelatedContentProps> = ({
    relatedToType,
    relatedToId,
}) => {
    const client = useClient()!
    const event = useEvent()!

    const relatedContentQuery = useRelatedContent(backend, client.id, event.id, relatedToType, relatedToId)
    const categoriesQuery = backend.relatedContentCategories.useGetAll([client.id, event.id])

    const categoriesDialog = useRelatedContentCategoriesDialog()

    return <Loading
        requests={[relatedContentQuery, categoriesQuery] as const}
        render={([relatedContent, categories]) => <BarAndFlex barContent={<Bar p={2}>
            <Spacer />
            <Button
                children="Link categories"
                startIcon={<Segment />}
                onClick={() => categoriesDialog.open({})}
            />
        </Bar>}>
            <Stack sx={{ ...fullSize, overflowY: "auto" }}>
                <EditableRelatedContentCategory
                    relatedContent={relatedContent.filter(it => it.categoryId === null)}
                    relatedToType={relatedToType}
                    relatedToId={relatedToId}
                />
                {categories.map(category =>
                    <EditableRelatedContentCategory
                        key={category.id}
                        category={category}
                        relatedContent={relatedContent.filter(it => it.categoryId === category.id)}
                        relatedToType={relatedToType}
                        relatedToId={relatedToId}
                    />
                )}
            </Stack>
        </BarAndFlex>}
    />
}