import { Link, SxProps } from "@mui/material"
import { FC } from "react"
import linkedIn from "src/common/social/linkedin.png"


export type LinkedInProps = {
    username: string
    sx?: SxProps
}

export const LinkedIn: FC<LinkedInProps> = ({
    username,
    sx,
}) => {
    return <Link 
        href={`https://linkedin.com/in/${username}`}
        target="_blank"
        sx={{ 
            display: "flex",
            alignItems: "center",
            ...sx,
        }}
    >
        <img
            src={linkedIn}
            alt="LinkedIn profile"
            style={{
                width: "24px",
                height: "24px",
            }}
        />
    </Link>
}