import { InputProps, TextField } from "@mui/material"
import { ChangeEvent, FC } from "react"

export type FormElementNameFieldProps = {
    name: string
    setName: (name: string) => void
    InputProps?: InputProps
}

export const FormElementNameField: FC<FormElementNameFieldProps> = ({ 
    name,
    setName,
    InputProps
}) => {

    function setValidateName(e: ChangeEvent<HTMLInputElement>) {
        const name = e.target.value
            .toLowerCase()
            .replace(/[^a-z0-9_]/g, "")
            .replace(/^[0-9_]/, "")
            .substring(0, 64)
        setName(name)
    }

    return <TextField
        label="name"
        helperText="Snake case (e.g. example_field)"
        value={name}
        onChange={setValidateName}
        fullWidth
        required
        InputProps={InputProps}
    />
}