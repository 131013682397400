import { RegistrationEmailType } from "@marketpartner/backend-api"
import Typography from "@mui/material/Typography"
import { FC, ReactNode } from "react"
import { useEvent } from "src/events/event-context"
import { orange } from "@mui/material/colors"
import { BulkRegistrationApprovalState } from "src/registrations/bulk/useBulkApproval"

export type EmailResendWarningProps = {
    newStatus: BulkRegistrationApprovalState
    userName?: string
}

type WarningDetails = {
    emailType: RegistrationEmailType
    message: ReactNode
}

const useGetWarningDetails = (newStatus: BulkRegistrationApprovalState, userName?: string) => {
    const warningDetails: Partial<Record<BulkRegistrationApprovalState, WarningDetails>> = {
        Approved: {
            emailType: RegistrationEmailType.registrationApproved,
            message: <WarningText>{ userName ? `Approving ${userName} will trigger an email to be sent to them.` : `This will send an approval email to the selected users.`}</WarningText>,
        },
        Rejected: {
            emailType: RegistrationEmailType.registrationRejected,
            message: <WarningText>{ userName ? `Rejecting ${userName} will trigger an email to be sent to them.` : `This will send a rejection email to the selected users.`}</WarningText>,
        },
        Cancelled: {
            emailType: RegistrationEmailType.registrationCancelled,
            message: <WarningText>{ userName ? `Cancelling ${userName} will trigger an email to be sent to them.` : `This will send a cancellation email to the selected users.`}</WarningText>,
        },
    }
    return warningDetails[newStatus]
}

export const EmailResendWarning: FC<EmailResendWarningProps> = ({
    newStatus,
    userName
}) => {
    const event = useEvent()!

    const details = useGetWarningDetails(newStatus, userName)
    if (!details || !event.triggeredEmailTemplates[details.emailType]) {
        return null
    }

    return <Typography
        variant="body2"
        children={details.message}
    />
}

export const WarningText: FC<{children: ReactNode}> = ({ children }) => {
    return <Typography 
        variant="body2" 
        style={{color: orange[500]}} 
        children={children} 
    />
}