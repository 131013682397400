import { GridFilterItem, GridFilterOperator, GridRowId } from "@mui/x-data-grid-pro";

/**
 * Allows custom filtering of grid rows by id.
 * IMPORTANT: In order for this to work, the idListFilterOperator 
 * needs to be added to the id column. QueryDataGrid handles all
 * this by default if a filteredIds list is provided.
 */
export const idListFilterItem = (
    field: string,
    ids: GridRowId[]
): GridFilterItem => ({
    field,
    operator: "in-id-set",
    value: new Set(ids)
})

export const idListFilterOperator: GridFilterOperator = {
    value: "in-id-set",
    label: "Custom filter",
    requiresFilterValue: false,
    getApplyFilterFn: (filterItem) => {
        const idSet = filterItem.value
        if (idSet && idSet instanceof Set) {
            return ({ id }) => idSet.has(id)
        }
        return null
    },
}