import { buildActiveStatusFunction } from "@marketpartner/mp-common"
import { IconProps } from "@mui/material"
import { ReactElement } from "react"

type SingleStatusDetails = {
    label: string
    icon: ReactElement<IconProps>
}

export type ActiveStatusDetails = Record<GenericActiveStatus, SingleStatusDetails>

export enum GenericActiveStatus {
    pre = "pre",
    active = "active",
    post = "post"
}

export const activeStatusColors = {
    pre: "inherit",
    active: "success",
    post: "error",
} as const

export const getGenericActiveStatus = buildActiveStatusFunction(
    GenericActiveStatus.pre,
    GenericActiveStatus.active,
    GenericActiveStatus.post,
    () => [null, null]
)
