import { Avatar, AvatarProps } from "@mui/material";
import { FC } from "react";


export type ContentAvatarProps = AvatarProps & {
    size?: number
}

export const ContentAvatar: FC<ContentAvatarProps> = ({
    size = 100,
    sx,
    ...props
}) => {
    return <Avatar
        sx={{
            width: size,
            height: size,
            fontSize: size * 0.4,
            ...sx,
        }}
        {...props}
    />
}