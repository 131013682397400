import { EventDetails, Scope } from "@marketpartner/backend-api";
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { EditableDialogList } from "src/common/dialogs/EditableDialogList";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { useEvent } from "src/events/event-context";
import { useAddComputedColumnMenu } from "src/registrations/computed-columns/AddComputedColumnMenu";
import { ComputedColumnTypeIcon } from "src/registrations/computed-columns/ComputedColumnTypeIcon";
import { useDeleteComputedColumnDialog } from "src/registrations/computed-columns/DeleteComputedColumnDialog";
import { useEditComputedColumnDialog } from "src/registrations/computed-columns/EditComputedColumnDialog";


export type EditComputedColumnsDialogProps = LockableDialogProps

const EditComputedColumnsDialog: FC<EditComputedColumnsDialogProps> = (props) => {
    const client = useClient()!
    const event = useEvent()!

    const columnsQuery = backend.events.useGetEvent([client.id, event.id], {
        select: event => (event as EventDetails).computedColumns,
    })
    const addMenu = useAddComputedColumnMenu()
    const editMenu = useEditComputedColumnDialog()
    const deleteDialog = useDeleteComputedColumnDialog()

    return <LockableDialog
        maxWidth="sm"
        fullWidth
        {...props}
    >
        <DialogTitle>Computed columns</DialogTitle>
        <EditableDialogList
            itemsQuery={columnsQuery}
            getPrimaryText={column => column.id}
            getIcon={column => <ComputedColumnTypeIcon type={column.type} />}
            contextMenuProps={(_column, idx) => ({
                onClickEdit: () => editMenu.open({ columnIndex: idx }),
                onClickDelete: () => deleteDialog.open({ columnIndex: idx }),
            })}
            addProps={{
                onClickAdd: e => addMenu.open({
                    anchorEl: e.currentTarget,
                })
            }}
        />
    </LockableDialog >
}

export const useEditComputedColumnsDialog = createPopupHook({
    element: EditComputedColumnsDialog,
    scope: Scope.Event,
})
