import { ExpandMore } from "@mui/icons-material"
import { Accordion, AccordionDetails, AccordionSummary, Box, List, ListItemText, Stack } from "@mui/material"
import ListItemButton from "@mui/material/ListItemButton"
import { FC, useState } from "react"
import { LoadingSpinner } from "src/common/loading/LoadingSpinner"
import { useEvent } from "src/events/event-context"
import { EventSelector } from "src/events/EventSelector"
import { FormElementSource, FormElementSourceType, useFormElementSources } from "src/forms/elements/copying/useFormElementSources"
import { useLoginContext } from "src/login/LoginContext"


export type CopyElementsSourceSelectorProps = {
    onSelectSource: (source: FormElementSource) => void
    defaultSourceType: FormElementSourceType
}

export const CopyElementsSourceSelector: FC<CopyElementsSourceSelectorProps> = ({
    onSelectSource,
    defaultSourceType,
}) => {
    const login = useLoginContext()
    const event = useEvent()!
    const [eventId, setEventId] = useState(event.id)

    return <Stack spacing={2}>
        {!login.singleEventId && <EventSelector
            selectedId={eventId}
            onSelectId={id => id && setEventId(id)}
            required
            size="small"
            fullWidth
        />}
        <SourceList eventId={eventId} onSelectSource={onSelectSource} defaultSourceType={defaultSourceType} />
    </Stack>
}

type SourceListProps = {
    eventId: string
    onSelectSource: (source: FormElementSource) => void
    defaultSourceType: FormElementSourceType
}

const SourceList: FC<SourceListProps> = ({
    eventId,
    onSelectSource,
    defaultSourceType,
}) => {
    //FIXME: There is a bug where all events fields elements are always shown regardless of the event selected
    const { isLoading, sources } = useFormElementSources(eventId)
    const [sourceType, setSourceType] = useState(defaultSourceType)

    if (isLoading) {
        return <LoadingSpinner />
    }

    return <Box>
        {Object.values(FormElementSourceType).map(type => <Accordion
            key={type}
            expanded={type === sourceType}
            onChange={() => setSourceType(type)}
            disableGutters
        >
            <AccordionSummary expandIcon={<ExpandMore />}>
                {type} ({sources[type].length})
            </AccordionSummary>
            <AccordionDetails>
                <List dense disablePadding>
                    {sources[type].map(source => <ListItemButton
                        key={source.name}
                        onClick={() => onSelectSource(source)}
                    >
                        <ListItemText
                            primary={source.name}
                            secondary={`${source.elements.length}`}
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        />
                    </ListItemButton>)}
                </List>
            </AccordionDetails>
        </Accordion>)}
    </Box>
}