import { Stack, SxProps, useTheme } from "@mui/material"
import Box from "@mui/material/Box"
import { FC, ReactNode } from "react"


export type GroupAndHeadingProps = {
    headingContent: ReactNode
    children: ReactNode
    sx?: SxProps
}

/**
 * Layout component for some content with a sticky header.
 */
export const StickyHeadingAndContent: FC<GroupAndHeadingProps> = ({
    headingContent,
    children,
    sx,
}) => {
    const theme = useTheme()

    return <Stack sx={{
        position: "relative",
        ...sx,
    }}>
        <Box
            position="sticky"
            top={0}
            py={1}
            px={2}
            bgcolor="grey.50"
            zIndex={theme.zIndex.appBar}
        >
            {headingContent}
        </Box>
        {children}
    </Stack>
}