import { FC } from "react";
import { createLocalPopupHook } from "src/common/dialogs/LocalPopup";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { CopyElementsDialogContent, CopyElementsDialogContentProps } from "src/forms/elements/copying/CopyElementsDialogContent";


export type CopyElementsDialogProps = LockableDialogProps & CopyElementsDialogContentProps

const CopyElementsDialog: FC<CopyElementsDialogProps> = ({
    existingElements,
    defaultSourceType,
    ...props
}) => <LockableDialog maxWidth="xs" fullWidth {...props}>
        <CopyElementsDialogContent
            existingElements={existingElements}
            defaultSourceType={defaultSourceType}
        />
    </LockableDialog>

export const useCopyElementsDialog = createLocalPopupHook({
    element: CopyElementsDialog,
})