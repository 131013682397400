import { FormElementConfig, FormElementMetadata, FormElementType, FormFieldType, getElementConfigDefaults } from "@marketpartner/backend-api"
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material"
import { cloneDeep } from "lodash"
import { createElement, useState } from "react"
import { DialogForm, ExtendableDialogFormProps } from "src/common/dialogs/DialogForm"
import { useEditableFormElementsContext } from "src/forms/elements/EditableFormElementsContext"
import { formElementConfigTypes } from "src/forms/elements/types/formElementConfigTypes"

export type FormElementFormProps<Metadata extends FormElementMetadata> = ExtendableDialogFormProps<FormElementConfig<Metadata>> & {
    formElement?: FormElementConfig<Metadata>
    defaultMetadata?: Metadata | undefined
}

export const FormElementForm = <Metadata extends FormElementMetadata = FormElementMetadata>({
    onSubmit,
    formElement,
    defaultMetadata,
    ...props
}: FormElementFormProps<Metadata>) => {
    const { renderMetadataControls } = useEditableFormElementsContext()

    const [config, setConfig] = useState<FormElementConfig<Metadata>>(
        formElement ?? { ...getElementConfigDefaults(FormFieldType.TextField, defaultMetadata) })

    function onSetFormElementType(newType: FormElementType) {
        const newDefaultElementConfig = getElementConfigDefaults(newType)
        setConfig({
            ...cloneDeep(newDefaultElementConfig),
            metadata: config.metadata,
        })
    }

    function updateConfigProperty(property: string, value: unknown) {
        setConfig({ ...config, [property]: value })
    }

    return <DialogForm
        formData={config}
        onSubmit={onSubmit}
        {...props}
    >
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel id="type-label">Type</InputLabel>
                    <Select
                        label="Type"
                        labelId="type-label"
                        value={config.type}
                        onChange={(e) => { onSetFormElementType(e.target.value as FormElementType) }}
                        >
                        {Object.entries(formElementConfigTypes)
                            .filter(([type, config]) => !config.deprecated || formElement?.type === type)
                            .map(([type, config]) =>
                                <MenuItem key={type} value={type}>
                                    {config.label} {config.deprecated ? "(deprecated)" : undefined}
                                </MenuItem>
                            )}
                    </Select>
                </FormControl>
            </Grid>
            {renderMetadataControls?.(config, setConfig)}
            {
                createElement(
                    formElementConfigTypes[config.type].configComponent as any,
                    { config, updateConfigProperty }
                )
            }
        </Grid>
    </DialogForm>
}