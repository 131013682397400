import { SearchBox } from "@marketpartner/mp-common-mui";
import { useSearch } from "@marketpartner/mp-common-react";
import { FC } from "react";
import { AppDeploymentsGrid } from "src/app-templates/deployments/AppDeploymentsGrid";
import { backend } from "src/common/api";
import { BarAndFlex } from "src/common/layout/BarAndFlex";
import { Bar } from "src/common/layout/Bar";


export const AllAppsTab: FC = () => {
    const deploymentsQuery = backend.appTemplateDeployments.useGetGlobalDeployments([{ includeSubscopes: true }])
    const [searchText, setSearchText, filteredDeployments] = useSearch(deploymentsQuery, deployment => [
        deployment.name,
        deployment.domainName,
        deployment.path,
        deployment.scope.clientId,
        deployment.scope.eventId,
        deployment.appTemplateId,
    ])

    return <BarAndFlex barContent={<Bar spacing={2} p={2}>
        <SearchBox searchText={searchText} setSearchText={setSearchText} onClear={() => setSearchText("")} />
    </Bar>}>
        <AppDeploymentsGrid
            queryOrRows={filteredDeployments}
            nomenclature="App/Site"
            includeScope
        />
    </BarAndFlex>
}