import { LocalDateTime, RegistrationCategory, RegistrationCategoryType, Role } from "@marketpartner/backend-api"
import { BasicSelect } from "@marketpartner/mp-common-mui"
import { Checkbox, CheckboxProps, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, SelectProps, TextField } from "@mui/material"
import { FC, useState } from "react"
import { DialogForm, ExtendableDialogFormProps } from "src/common/dialogs/DialogForm"
import { IdField } from "src/common/form-inputs/IdField"
import { PluralTextField } from "src/common/form-inputs/PluralTextField"
import { ActiveStatusEditor } from "src/common/statuses/ActiveStatusEditor"
import { useEvent } from "src/events/event-context"
import { registrationCategoryStatusDetails } from "src/registrations/configuration/categories/registration-category-status-details"

const roles = [Role.RegisteredUser, Role.Moderator] as const

export type RegistrationCategoryFormData = {
    id: string
    name: string
    pluralName: string
    role: Role
    requiresApproval: boolean
    type: RegistrationCategoryType
    localStart: LocalDateTime | null
    localEnd: LocalDateTime | null
}

export type RegistrationCategoryFormProps = ExtendableDialogFormProps<RegistrationCategoryFormData> & {
    category?: RegistrationCategory
}

export const RegistrationCategoryForm: FC<RegistrationCategoryFormProps> = ({
    category,
    ...props
}) => {
    const event = useEvent()!
    const [id, setId] = useState(category?.id ?? "")
    const [name, setName] = useState(category?.name ?? "")
    const [pluralName, setPluralName] = useState(category?.pluralName ?? "")
    const [role, setRole] = useState(category?.role ?? roles[0])
    const [requiresApproval, setRequiresApproval] = useState(category?.requiresApproval ?? true)
    const [type, setType] = useState(category?.type ?? RegistrationCategoryType.Delegate)
    const [start, setStart] = useState(category?.start ?? null)
    const [end, setEnd] = useState(category?.end ?? null)

    return <DialogForm
        formData={{
            id,
            name,
            pluralName,
            role,
            requiresApproval,
            type,
            localStart: start && LocalDateTime.fromZonedDateTime(start),
            localEnd: end && LocalDateTime.fromZonedDateTime(end),
        }}
        {...props}
    >
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
                <TextField
                    label="Name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    fullWidth
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <PluralTextField
                    sourceValue={name}
                    value={pluralName}
                    onChange={setPluralName}
                    required
                    fullWidth
                />
            </Grid>
            {!category && <Grid item xs={12}>
                <IdField
                    nameValue={name}
                    idValue={id}
                    onChangeId={setId}
                    maxLength={255}
                />
            </Grid>}
            <Grid item xs={12} sm={6}>
                <BasicSelect
                    label="Type"
                    value={type}
                    onChange={type => setType(type)}
                    options={[
                        { label: "Delegate", value: RegistrationCategoryType.Delegate },
                        { label: "Speaker", value: RegistrationCategoryType.Speaker },
                    ]}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <RoleSelector value={role} onChange={e => setRole(e.target.value as Role)} />
            </Grid>
            <Grid item xs={12}>
                <RequiresApproval checked={requiresApproval} onChange={e => setRequiresApproval(e.target.checked)} />
            </Grid>
            <Grid item xs={12}>
                <ActiveStatusEditor
                    details={registrationCategoryStatusDetails}
                    start={start}
                    setStart={setStart}
                    end={end}
                    setEnd={setEnd}
                    timezone={event.timezone}
                />
            </Grid>
        </Grid>
    </DialogForm>
}

const RequiresApproval = (props: CheckboxProps) => {
    return <FormControlLabel
        label="Always requires approval"
        control={<Checkbox
            color="primary"
            {...props}
        />}
    />
}

const RoleSelector = (props: SelectProps<Role>) => {
    return <FormControl fullWidth>
        <InputLabel id="role-label">Role</InputLabel>
        <Select
            name="role"
            label="Role"
            labelId="role-label"
            required
            {...props}
        >
            {roles.map(role => <MenuItem key={role} value={role}>
                {role}
            </MenuItem>
            )}
        </Select>
    </FormControl>
}