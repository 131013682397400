import { ActionStatistics, ActivityAndUserStatistics, ActivityUserCountStatistics, EventSegment, TimedActivityStatistics } from "@marketpartner/backend-api"
import { Box, Fade, Stack, SxProps } from "@mui/material"
import { FC, forwardRef, ReactNode } from "react"
import { ActivitiesApi } from "src/activities/activities-api"
import { ActivityUsersLink } from "src/activities/usage/ActivityUsersLink"
import { Bar } from "src/common/layout/Bar"
import { ProgressWithLabel } from "src/common/loading/ProgressWithLabel"
import { prettyDuration } from "src/common/time/pretty-duration"
import { useEvent } from "src/events/event-context"

export type EventStatsProps = {
    stats: ActivityAndUserStatistics[]
    segment?: EventSegment
}

export const EventStats: FC<EventStatsProps> = ({
    stats,
    segment,
}) => {
    const event = useEvent()!
    const onlineStats = stats.find(it => it.activityId === ActivitiesApi.onlineActivityId) as (TimedActivityStatistics & ActivityUserCountStatistics) | undefined
    const physicalStats = stats.find(it => it.activityId === ActivitiesApi.physicalAttendanceId) as ActionStatistics | undefined

    return <Bar m={2} spacing={4} flexWrap="wrap">
        <Attendance
            onlineActivity={onlineStats}
            physicalActivity={physicalStats}
            registered={event.registrationCount}
            segment={segment}
        />
        <EventStat name="Average time online" value={prettyDuration(onlineStats?.averageDurationPerUser ?? 0)} />
        <SessionStats onlineStats={onlineStats} />
        <UserCounts onlineStats={onlineStats} isSegment={Boolean(segment)} />
    </Bar>
}

type AttendanceProps = {
    onlineActivity?: TimedActivityStatistics
    physicalActivity?: ActionStatistics
    registered: number
    segment?: EventSegment
}

const Attendance: FC<AttendanceProps> = ({
    onlineActivity,
    physicalActivity,
    registered,
    segment
}) => {
    const attendedOnline = onlineActivity?.totalUsers ?? 0
    const attendedPhysical = physicalActivity?.totalUsers ?? 0
    return <Stack>
        <EventStat
            name="Total online attendance"
            value={onlineActivity ?
                <ActivityUsersLink activity={onlineActivity} count={attendedOnline} segment={segment} /> :
                0
            }
        />
        <ProgressWithLabel percentage={attendedOnline / registered * 100} />
        {physicalActivity && <>
            <EventStat
                name="Total physical attendance"
                value={<ActivityUsersLink activity={physicalActivity} count={attendedPhysical} segment={segment} />}
            />
            <ProgressWithLabel percentage={attendedPhysical / registered * 100} />
        </>}
    </Stack>
}

type SessionStatsProps = {
    onlineStats?: TimedActivityStatistics
}

const SessionStats: FC<SessionStatsProps> = ({
    onlineStats,
}) => {
    return <Stack>
        <EventStat name="Average sessions" value={onlineStats?.averageSessionsPerUser ?? 0} />
        <EventStat name="Duration" value={prettyDuration(onlineStats?.averageDurationPerSession ?? 0)} />
    </Stack>
}

type UserCountsProps = {
    onlineStats?: ActivityUserCountStatistics
    isSegment: boolean
}

const UserCounts: FC<UserCountsProps> = ({
    onlineStats,
    isSegment,
}) => {
    return <Stack>
        <EventStat name="Peak online users" value={onlineStats?.peakUserCount} />
        <Fade in={!isSegment}>
            <EventStat name="Currently online" value={onlineStats?.currentUserCount} />
        </Fade>
    </Stack>
}

type EventStatProps = {
    name: string
    value: ReactNode
    sx?: SxProps
}

const EventStat: FC<EventStatProps> = forwardRef(({
    name,
    value,
    sx
}, ref) => {
    return <Box ref={ref} sx={sx}>
        <b>{name}: </b>{value}
    </Box>
})
