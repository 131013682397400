import { LocalDateTime, NetworkingPeriod } from "@marketpartner/backend-api"
import { Collapse, Grid, TextField, Typography } from "@mui/material"
import { DateTime } from "luxon"
import { FC, useState } from "react"
import { DialogForm, ExtendableDialogFormProps } from "src/common/dialogs/DialogForm"
import { NumberTextField } from "src/common/form-inputs/NumberTextField"
import { LuxonDateTimePicker } from "src/common/time/LuxonDateTimePickers"
import { useEvent } from "src/events/event-context"


export type NetworkingPeriodFormData = {
    name: string
    localStart: LocalDateTime
    localEnd: LocalDateTime
    timeslotDuration: number
}

export type NetworkingPeriodFormProps = ExtendableDialogFormProps<NetworkingPeriodFormData> & {
    period?: NetworkingPeriod
    suggestedStart?: DateTime
    suggestedEnd?: DateTime
}

export const NetworkingPeriodForm: FC<NetworkingPeriodFormProps> = ({
    period,
    suggestedStart,
    suggestedEnd,
    ...props
}) => {
    const event = useEvent()!

    const [name, setName] = useState(period?.name ?? "")
    const [start, setStart] = useState(
        period?.start ??
        suggestedStart ??
        event.startTime.setZone(event.timezone)
    )
    const [end, setEnd] = useState(
        period?.end ??
        suggestedEnd ??
        event.endTime.setZone(event.timezone)
    )
    const [timeslotDuration, setTimeslotDuration] = useState(period?.timeslotDuration ?? 30)
    const perfectlyDivisible = end.diff(start, "minutes").minutes % timeslotDuration === 0

    return <DialogForm
        {...props}
        formData={{
            name,
            localStart: LocalDateTime.fromZonedDateTime(start),
            localEnd: LocalDateTime.fromZonedDateTime(end),
            timeslotDuration,
        }}
        isValid={
            Boolean(name) &&
            start < end &&
            timeslotDuration > 0 &&
            perfectlyDivisible
        }
    >
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    label="Name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    fullWidth
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <NumberTextField
                    label="Timeslot duration (minutes)"
                    value={timeslotDuration}
                    onChange={setTimeslotDuration}
                    required
                    sx={{ width: "50%" }}
                />
                <Collapse in={!perfectlyDivisible}>
                    <Typography variant="caption" color="error">
                        The period does not divide equally into timeslots with this duration
                    </Typography>
                </Collapse>
            </Grid>
            <Grid item xs={6}>
                <LuxonDateTimePicker
                    label="Start"
                    value={start}
                    onChange={start => start && setStart(start)}
                    timezone={event.timezone}
                    required
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <LuxonDateTimePicker
                    label="End"
                    value={end}
                    onChange={end => end && setEnd(end)}
                    timezone={event.timezone}
                    required
                    fullWidth
                />
            </Grid>
        </Grid>
    </DialogForm>
}