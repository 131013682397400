import { Location, Scope } from "@marketpartner/backend-api";
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { LocationForm } from "src/content/locations/LocationForm";
import { useEvent } from "src/events/event-context";


export type EditLocationDialogProps = LockableDialogProps & {
    location: Location
}

const EditLocationDialog: FC<EditLocationDialogProps> = ({
    location,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!

    const updateLocationMutation = backend.locations.useUpdateLocation(withNotification({
        errorMessage: "Error updating location"
    }))

    return <LockableDialog
        maxWidth="sm"
        fullWidth
        {...props}
    >
        <DialogTitle>Edit location</DialogTitle>
        <LocationForm
            location={location}
            actionName="Save"
            onSubmit={formData => updateLocationMutation.mutateAsync([client.id, event.id, location.id, formData])}
        />
    </LockableDialog>
}

export const useEditLocationDialog = createPopupHook({
    element: EditLocationDialog,
    scope: Scope.Event,
})