import { Permission, RegistrationCategory, Scope } from "@marketpartner/backend-api"
import { Assignment, CopyAll, Edit, ImportExport } from "@mui/icons-material"
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material"
import Divider from "@mui/material/Divider"
import { FC } from "react"
import { Link } from "react-router-dom"
import { useClient } from "src/clients/client-context"
import { createPopupHook } from "src/common/dialogs/Popup"
import { LockableMenu, LockableMenuProps } from "src/common/menus/LockableMenu"
import { link } from "src/common/routing/routes"
import { useCopySpeakersDialog } from "src/content/speakers/copy/CopySpeakersDialog"
import { useEvent } from "src/events/event-context"
import { ifHas } from "src/login/IfHas"
import { useEditRegistrationCategoryDialog } from "src/registrations/configuration/categories/EditRegistrationCategoryDialog"


export type SpeakerCategoryContextMenuProps = LockableMenuProps & {
    category: RegistrationCategory
    onStartReorder: () => void
}

const SpeakerCategoryContextMenu: FC<SpeakerCategoryContextMenuProps> = ({
    category,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const editDialog = useEditRegistrationCategoryDialog()
    const copySpeakersDialog = useCopySpeakersDialog()

    return <LockableMenu closeOnClick {...props}>
        <MenuItem onClick={props.onStartReorder}>
            <ListItemIcon><ImportExport color="primary" /></ListItemIcon>
            <ListItemText primary={`Reorder ${category.pluralName}`} />
        </MenuItem>
        <MenuItem onClick={() => copySpeakersDialog.open({
            targetCategory: category
        })}>
            <ListItemIcon><CopyAll /></ListItemIcon>
            <ListItemText primary="Copy from event" />
        </MenuItem>
        {ifHas(Permission.EditEventDetails, client.id, event.id, <>
            <Divider />
            <MenuItem onClick={() => editDialog.open({ category })}>
                <ListItemIcon><Edit color="primary" /></ListItemIcon>
                <ListItemText primary="Edit" />
            </MenuItem>
            <MenuItem component={Link} to={link.toRegistrationCategoryProfileSettings(client.id, event.id, category.id)}>
                <ListItemIcon><Assignment /></ListItemIcon>
                <ListItemText primary="Forms & profiles" />
            </MenuItem>
        </>)}
    </LockableMenu>
}

export const useSpeakerCategoryMenu = createPopupHook({
    element: SpeakerCategoryContextMenu,
    scope: Scope.Event,
})