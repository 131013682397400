import { ContentCategory, ContentSubCategory, Scope } from "@marketpartner/backend-api";
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { ContentEntityForm } from "src/content/entities/ContentEntityForm";
import { useEvent } from "src/events/event-context";


export type CreateContentEntityDialogProps = LockableDialogProps & {
    category: ContentCategory
    subCategory?: ContentSubCategory
}

const CreateContentEntityDialog: FC<CreateContentEntityDialogProps> = ({
    category,
    subCategory,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const createEntityMutation = backend.contentEntities.useCreateEntity(withNotification({
        errorMessage: "Failed to create content",
    }))

    return <LockableDialog
        maxWidth="sm"
        fullWidth
        {...props}
    >
        <DialogTitle>
            Create {category.name}{subCategory ? ` (${subCategory.name})` : ""}
        </DialogTitle>
        <ContentEntityForm
            actionName="Create"
            onSubmit={async formData => createEntityMutation.mutateAsync([client.id, event.id, {
                ...formData,
                categoryId: category.id,
                subCategoryId: subCategory?.id ?? null,
            }])}
        />
    </LockableDialog>
}

export const useCreateContentEntityDialog = createPopupHook({
    element: CreateContentEntityDialog,
    scope: Scope.Event,
})