import { Scope } from "@marketpartner/backend-api";
import { CopyAll, Edit } from "@mui/icons-material";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { FC } from "react";
import { createPopupHook } from "src/common/dialogs/Popup";
import { LockableMenu, LockableMenuProps } from "src/common/menus/LockableMenu";
import { useCopyComputedColumnsDialog } from "src/registrations/computed-columns/CopyComputedColumnsDialog";
import { useCreateComputedColumnDialog } from "src/registrations/computed-columns/CreateComputedColumnDialog";


export type AddComputedColumnMenuProps = LockableMenuProps

const AddComputedColumnMenu: FC<AddComputedColumnMenuProps> = ({
    ...props
}) => {
    const createColumnDialog = useCreateComputedColumnDialog()
    const copyColumnsDialog = useCopyComputedColumnsDialog()

    return <LockableMenu {...props} closeOnClick>
        <MenuItem onClick={() => createColumnDialog.open({})}>
            <ListItemIcon>
                <Edit />
            </ListItemIcon>
            <ListItemText>Create custom column</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => copyColumnsDialog.open({})}>
            <ListItemIcon>
                <CopyAll />
            </ListItemIcon>
            <ListItemText>Copy from event</ListItemText>
        </MenuItem>
    </LockableMenu>
}

export const useAddComputedColumnMenu = createPopupHook({
    element: AddComputedColumnMenu,
    scope: Scope.Event,
})