

/**
 * Compare two items by comparing multiple properties. For example:
 * 
 * // Order by age, then name
 * compare(person1, person2, p => p.age, p => p.name)
 */
export function compareProperties<T extends Record<string, unknown>>(
    item1: T,
    item2: T,
    ...propertyExtractors: ((item: T) => undefined | null | string | number | boolean)[]
) {
    for (const propertyExtractor of propertyExtractors) {
        const value1 = propertyExtractor(item1)
        const value2 = propertyExtractor(item2)
        if (!value1) {
            return value2 ? -1 : 0
        }
        if (!value2) {
            return value1 ? 1 : 0
        }
        if (value1 > value2) {
            return 1
        }
        if (value1 < value2) {
            return -1
        }
    }
    return 0
}