import { entityContentTypeLookup } from "@marketpartner/backend-api";

export const getContentSecondaryText = entityContentTypeLookup<string[]>({
    Link: link => [link.fields.url],
    Pdf: pdf => [pdf.fields.file.absoluteUrl],
    RemoteHostedPdf: pdf => [pdf.fields.url],
    RemoteHostedVideo: video => [video.fields.src],
    VideoLink: video => [video.fields.url],
    VimeoVideo: video => [video.fields.url],
    Custom: () => [],
})