import { commonProfileFields, Permission, RegistrationCategory } from "@marketpartner/backend-api"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { useEvent } from "src/events/event-context"
import { FormElementSourceType } from "src/forms/elements/copying/useFormElementSources"
import { EditableFormElements } from "src/forms/elements/EditableFormElements"
import { useLoginContext } from "src/login/LoginContext"


export type EditableProfileFormProps = {
    category: RegistrationCategory
}

export const EditableProfileForm: FC<EditableProfileFormProps> = ({
    category,
}) => {
    const client = useClient()!
    const event = useEvent()!
    const login = useLoginContext()

    const updateMutation = backend.registrationCategories.useUpdate()

    return <EditableFormElements
        canEdit={login.hasPermission(Permission.EditEventDetails, client.id, event.id)}
        elements={category.profileElements}
        onChangeElements={elements => updateMutation.mutateAsync([client.id, event.id, category.id, {
            profileElements: elements
        }])}
        defaultSourceType={FormElementSourceType.RegistrationCategories}
        commonElements={commonProfileFields}
        sizing="full-size"
    />
}