import { TextFieldConfig } from "@marketpartner/backend-api"
import { Grid, TextField } from "@mui/material"
import { FormElementConfigComponent } from "src/forms/elements/types/formElementConfigTypes"
import { StandardFields } from "src/forms/elements/types/StandardFields"

export const TextFieldConfigComponent: FormElementConfigComponent<TextFieldConfig> = ({
    config,
    updateConfigProperty
}) => {

    return <>
        <StandardFields config={config} updateConfigProperty={updateConfigProperty}/>
        <Grid item xs={12}>
            <TextField
                label="Default value"
                fullWidth
                value={config.defaultValue}
                onChange={(e) => updateConfigProperty('defaultValue', e.target.value)}
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                label="Placeholder"
                fullWidth
                value={config.placeholder}
                onChange={(e) => updateConfigProperty('placeholder', e.target.value)}
            />
        </Grid>
    </>
}