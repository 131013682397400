import { Box } from "@mui/system"
import { AdminTokensGrid } from "src/access/tokens/admin-tokens/AdminTokensGrid"
import { CreateTokenButton } from "src/access/tokens/admin-tokens/CreateTokenButton"
import { BarAndFlex } from "src/common/layout/BarAndFlex"
import { Bar } from "src/common/layout/Bar"

export const AdminTokens = () => {

    return <BarAndFlex barContent={
        <Bar p={2}>
            <Box sx={{ mx: "auto" }} />
            <CreateTokenButton />
        </Bar>
    }>
        <AdminTokensGrid />
    </BarAndFlex>
}