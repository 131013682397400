import { FlatEpSession } from "@marketpartner/backend-api"
import { Stack, SxProps, Typography } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { link } from "src/common/routing/routes"
import { EventPartnerContent } from "src/content/event-partner/EventPartnerContent"
import { EventPartnerGroupedItems } from "src/content/event-partner/EventPartnerGroupedItems"
import { useEvent } from "src/events/event-context"


export const EventPartnerSessions: FC = () => {
    const client = useClient()!
    const event = useEvent()!
    const sessionsQuery = backend.content.useGetEventPartnerSessions([client.id, event.id])

    return <EventPartnerContent
        name="sessions"
        link={link.toEpSessions(event.eventPartnerId!, event.epEventToken!)}
        request={sessionsQuery}
        render={sessions => <EventPartnerGroupedItems
            data={sessions}
            groupByProperty="day"
            renderItems={sessions => <Sessions sessions={sessions} />}
        />}
    />
}

type SessionsProps = {
    sessions: FlatEpSession[]
}

const Sessions: FC<SessionsProps> = ({ sessions }) => {
    const backgroundColors: Record<string, string> = {
        "Break": "grey.100",
        "Heading": "grey.300"
    }

    return <Stack>
        {sessions.map(session => <SessionItem
            key={session.entity_token}
            session={session}
            sx={{
                backgroundColor: backgroundColors[session.entity_category]
            }}
        />)}
    </Stack>
}

type SessionItemProps = {
    session: FlatEpSession
    sx?: SxProps
}

const SessionItem: FC<SessionItemProps> = ({
    session,
    sx
}) => {
    const startTime = session.start.substring(16, 11)
    const endTime = session.end.substring(16, 11)

    return <Stack spacing={1} sx={{
        p: 2,
        borderBottom: 1,
        borderColor: "grey.400",
        justifyContent: "stretch",
        ...sx,
    }}>
        <Typography variant="body2">
            {startTime} - {endTime}
        </Typography>
        {session.name}
    </Stack>
}