import { CopyRegistrationsResult, Registration, RegistrationType } from "@marketpartner/backend-api";
import { Check, Error } from "@mui/icons-material";
import { Stack, SxProps, Typography } from "@mui/material";
import { GridColDef, GridRowParams, GridSortModel } from "@mui/x-data-grid-pro";
import { keyBy } from "lodash";
import { FC, ReactNode, useCallback, useMemo } from "react";
import { BaseRegistrationsGrid } from "src/registrations/BaseRegistrationsGrid";
import { NameAndEmail } from "src/registrations/NameAndEmail";
import { sortKey } from "src/registrations/registration-functions";


export type CopyRegistrationsResultsProps = {
    registrations: Registration[]
    results: CopyRegistrationsResult[]
    sx?: SxProps
}

export const CopyRegistrationsResults: FC<CopyRegistrationsResultsProps> = ({
    registrations,
    results,
    sx,
}) => {
    const resultsById = useMemo(() => keyBy(results, "originalId"), [results])

    const columns = useMemo((): GridColDef<Registration>[] => {
        return [{
            field: "status",
            headerName: " ",
            renderCell: ({ value }) => value === "success" ?
                <Check color="success" /> :
                <Error color="error" />,
            valueGetter: ({ row }) => resultsById[row.id]?.errors?.length === 0 ? "success" : "error",
            sortable: false,
            resizable: false,
            width: 44,
        }, {
            field: "name",
            headerName: "Name",
            renderCell: ({ row }) => <NameAndEmail
                registration={row}
                sx={{ ml: row.type === RegistrationType.Guest ? 4 : 0 }} />,
            valueGetter: ({ row }) => sortKey(row),
            sortable: false,
            flex: 1,
        },];
    }, [resultsById])

    const getDetailsPanelContent = useCallback((
        { row }: GridRowParams<Registration>
    ): ReactNode => {
        const result = resultsById[row.id]
        if (!result || result.errors.length === 0) {
            return null
        }
        return <Stack>
            {result.errors.map((error, idx) => <Typography key={idx} color="error">{error}</Typography>)}
        </Stack>
    }, [resultsById])

    return <BaseRegistrationsGrid
        columns={columns}
        queryOrRows={registrations}
        getDetailPanelContent={getDetailsPanelContent}
        getDetailPanelHeight={getDetailPanelHeight}
        sortModel={sortModel}
        disableColumnMenu
        sx={sx}
    />
}

const sortModel: GridSortModel = [{
    field: "status",
    sort: "asc",
}, {
    field: "name",
    sort: "asc",
}]

const getDetailPanelHeight = () => "auto" as const