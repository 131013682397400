import { Permission } from "@marketpartner/backend-api"
import { Paper, Stack, Typography } from "@mui/material"
import { FC } from "react"
import { useNavigate } from "react-router"
import { useClient } from "src/clients/client-context"
import { TagList } from "src/common/form-inputs/TagList"
import { Bar } from "src/common/layout/Bar"
import { BasicProperties, BasicProperty } from "src/common/layout/BasicProperties"
import { Page } from "src/common/layout/Page"
import { Spacer } from "src/common/layout/Spacer"
import { BasicTabs } from "src/common/routing/BasicTabs"
import { link } from "src/common/routing/routes"
import { fullSize } from "src/common/styles"
import { EventDateTimeText } from "src/common/time/DateTimeText"
import { DeleteEventButton } from "src/events/DeleteEventButton"
import { EditEventButton } from "src/events/EditEventButton"
import { useEvent } from "src/events/event-context"
import { ExtraFields } from "src/events/extra-fields/ExtraFields"
import { EventFields } from "src/events/fields/EventFields"
import { useLoginContext } from "src/login/LoginContext"
import { EventPaymentDetails } from "src/payment/EventPaymentDetails"
import { ScanningDetails } from "src/scanning/ScanningDetails"

export const EventPage: FC = () => {
    const client = useClient()!
    const event = useEvent()!
    const login = useLoginContext()

    return <Page
        title={event.name}
        back={{
            hideIf: Boolean(login.singleEventId),
        }}
    >
        <Paper sx={{ ...fullSize }}>
            <BasicTabs tabs={[
                {
                    label: "Event Details",
                    render: () => <EventDetails />,
                },
                {
                    label: "Fields",
                    render: () => <EventFields />
                },
                {
                    label: "Custom Fields",
                    render: () => <ExtraFields />
                },
                {
                    label: "Scanning",
                    render: () => <ScanningDetails />
                },
                {
                    label: "Payment",
                    render: () => <EventPaymentDetails />,
                    restrictedTo: login => login.hasPermission(Permission.EditEventDetails, client.id, event.id)
                }
            ]} />
        </Paper>
    </Page>
}

const EventDetails: FC = () => {
    const client = useClient()!
    const event = useEvent()!
    const navigate = useNavigate()

    return <Stack>
        <Bar spacing={2} sx={{ px: 2 }}>
            <Spacer />
            <EditEventButton event={event} />
            <DeleteEventButton event={event} onDelete={() => navigate(link.toClient(client.id))} />
        </Bar>
        <BasicProperties sx={{ p: 2 }}>
            <BasicProperty name="Id" value={event.id} />
            <BasicProperty name="Name" value={event.name} />
            <BasicProperty name="Timezone" value={event.timezone} />
            <BasicProperty name="Start time" value={<EventDateTimeText dateTime={event.startTime} hideTimezone />} />
            <BasicProperty name="End time" value={<EventDateTimeText dateTime={event.endTime} hideTimezone />} />
        </BasicProperties>
        <Stack sx={{ p: 2 }}>
            <Typography variant="h6">
                Tags
            </Typography>
            <TagList tags={event.tags} sx={{ my: 1 }} />
        </Stack>
    </Stack>
}
