import { Paper } from "@mui/material"
import { FC } from "react"
import { AdminTokens } from "src/access/tokens/admin-tokens/AdminTokens"
import { RegistrationTokens } from "src/access/tokens/registration-tokens/RegistrationTokens"
import { Users } from "src/access/users/Users"
import { Page } from "src/common/layout/Page"
import { RoutedTabs } from "src/common/routing/RoutedTabs"
import { fullSize } from 'src/common/styles';


export const AccessPage: FC = () => {

    return <Page title="Manage access" minContentSize={400}>
        <Paper sx={{ ...fullSize, minHeight: "fit-content" }}>
            <RoutedTabs tabs={[
                { label:"Users", path:"users", element: <Users/> },
                { label:"Admin tokens", path:"admin-tokens", element: <AdminTokens/> },
                { label:"Registration tokens", path:"registration-tokens", element: <RegistrationTokens/> },
            ]}/>
        </Paper>
    </Page>
}