import { FormData, fromCsvValue, Registration, RegistrationType, TestClass } from "@marketpartner/backend-api"
import { pick } from "lodash"
import { ColumnMatch } from "src/registrations/import/pre-process/auto-match-columns"
import { ValidMergedRegistration } from "src/registrations/import/pre-process/validate-core-properties"

type BasePreparedRegistration = {
    lineNumber: number
    parsedId?: string
    existingRegistration?: Registration
    fields: FormData
    profileFields?: FormData | null
}

export type PreparedPrimaryRegistration = BasePreparedRegistration & {
    type: RegistrationType.Primary
    categoryId: string
}
export type PreparedGuest = BasePreparedRegistration & {
    type: RegistrationType.Guest
    primaryRegistrationId: string
}

export type PreparedRegistration = PreparedPrimaryRegistration | PreparedGuest

export class TestPreparedPrimaryRegistration extends TestClass implements PreparedPrimaryRegistration {
    type = RegistrationType.Primary as const
    lineNumber = 0
    parsedId ?: string
    existingRegistration ?: Registration
    fields: FormData = {}
    categoryId = "delegate"
}

export class TestPreparedGuest extends TestClass implements PreparedGuest {
    type = RegistrationType.Guest as const
    lineNumber = 0
    parsedId ?: string
    existingRegistration ?: Registration
    fields: FormData = {}
    primaryRegistrationId = "primary"
}

export const prepareForImport = (
    registrations: ValidMergedRegistration[],
    columnMapping: Record<string, ColumnMatch>
): PreparedRegistration[] => {
    return registrations.map(it => prepare(it, columnMapping))
}

const prepare = (
    registration: ValidMergedRegistration,
    columnMapping: Record<string, ColumnMatch>
): PreparedRegistration => {
    const commonProps = {
        ...pick(registration, "lineNumber", "parsedId", "existingRegistration"),
        fields: transformFields(registration.fields, columnMapping)
    }

    if (registration.type === RegistrationType.Primary) {
        return {
            ...commonProps,
            type: RegistrationType.Primary,
            categoryId: registration.categoryId
        }
    }
    return {
        ...commonProps,
        type: RegistrationType.Guest,
        primaryRegistrationId: registration.primaryRegistrationId,
    }
}

const transformFields = (
    fields: Record<string, string>,
    columnMapping: Record<string, ColumnMatch>
): FormData => {
    const transformed: FormData = {}
    Object.entries(fields).forEach(([csvColumn, value]) => {
        const mapping = columnMapping[csvColumn]
        if (mapping?.type === "field") {
            transformed[mapping.formField.name] = value && fromCsvValue(value, mapping.formField.type)
        }
    })
    return transformed
}
