import { Stack, Typography, useTheme } from "@mui/material"
import { groupBy } from "lodash"
import { FC, ReactNode } from "react"
import { center, fullSize } from "src/common/styles"

export type EventPartnerGroupedItemsProps<Item> = {
    data: Item[]
    groupByProperty: keyof Item
    renderItems: (items: Item[]) => ReactNode
}

export const EventPartnerGroupedItems = <Item,>({
    data,
    groupByProperty,
    renderItems
}: EventPartnerGroupedItemsProps<Item>) => {
    if (!data.length) {
        return <Typography sx={{ color: "text.secondary", ...center }}>
            No content
        </Typography>
    }

    const itemsByCategory = groupBy(data, groupByProperty)
    return <Stack sx={{ ...fullSize, overflow: "auto" }}>
        {Object.entries(itemsByCategory).map(([categoryTitle, items]) =>
            <ItemGroup
                key={categoryTitle}
                title={categoryTitle}
                items={renderItems(items)}
            />
        )}
    </Stack>
}

export type ItemGroupProps = {
    title: string
    items: ReactNode
}

export const ItemGroup: FC<ItemGroupProps> = ({
    title,
    items
}) => {
    const theme = useTheme()
    return <Stack>
        <Typography variant="h5" sx={{
            position: "sticky",
            top: 0,
            py: 1,
            px: 2,
            color: "text.secondary",
            backgroundColor: "grey.50",
            zIndex: theme.zIndex.appBar,
        }}>{title}</Typography>
        {items}
    </Stack>
}