import { arrayMove } from "@dnd-kit/sortable"
import { useMutation } from "@tanstack/react-query"
import { useCallback, useMemo, useState } from "react"

export type UseBulkReorderOptions<Item> = {
    items: Item[]
    onReorder: (items: Item[]) => void | Promise<unknown>
}

export type UseBulkReorder<Item> = {
    startReorder: () => void
    cancelReorder: () => void
    applyReorder: () => Promise<unknown>
    reorderItems: (items: Item[]) => void
    reorderItem: (oldIdx: number, newIdx: number) => void
    isReordering: boolean
    isLoading: boolean
    orderedItems: Item[]
}

/**
 * Often, re-orderable UI components (e.g. dnd-kit or mui grid) provide callbacks that
 * get called every time a single item is moved. This doesn't work well with UIs/APIs
 * that perform bulk reordering (e.g. a "save" button that saves the entire order).
 * 
 * This hook provides a bridge between the two by providing a way to start/stop a bulk
 * reordering process, and a way to apply the reordering in a single call.
 * 
 * Only a list of items and a (typically async) callback function are required.
 */
export const useBulkReorder = <Item>({
    items,
    onReorder,
}: UseBulkReorderOptions<Item>): UseBulkReorder<Item> => {
    const [reorderedItems, setReorderedItems] = useState<Item[] | undefined>(undefined)
    const isReordering = Boolean(reorderedItems)

    const reorderElementsMutation = useMutation({
        mutationFn: async () => {
            if (reorderedItems) {
                await Promise.resolve(onReorder(reorderedItems))
            }
        },
        onSuccess: () => setReorderedItems(undefined),
    })

    const startReorder = useCallback(() => setReorderedItems(items), [items])
    const cancelReorder = useCallback(() => setReorderedItems(undefined), [])
    const reorderItem = useCallback((
        oldIdx: number,
        newIdx: number
    ) => {
        setReorderedItems(items => items && arrayMove(items, oldIdx, newIdx))
    }, [])

    return useMemo(() => ({
        startReorder,
        cancelReorder,
        applyReorder: reorderElementsMutation.mutateAsync,
        reorderItems: setReorderedItems,
        reorderItem,
        isReordering,
        isLoading: reorderElementsMutation.isPending,
        orderedItems: reorderedItems ?? items,
    }), [startReorder, cancelReorder, reorderElementsMutation, reorderItem, isReordering, reorderedItems, items])
}