import { FormData, PrimaryRegistration, RegistrationCategory } from "@marketpartner/backend-api"
import { useDynamicFormState } from "@marketpartner/mp-common-react"
import { Collapse } from "@mui/material"
import { FC, useState } from "react"
import { useClient } from "src/clients/client-context"
import { DialogForm, ExtendableDialogFormProps } from "src/common/dialogs/DialogForm"
import { useEvent } from "src/events/event-context"
import { RegistrationForms } from "src/registrations/actions/RegistrationForms"

export type PrimaryRegistrationFormsData = {
    categoryId: string
    fields: FormData
    profileFields: FormData | null
}

export type PrimaryRegistrationFormsProps = ExtendableDialogFormProps<PrimaryRegistrationFormsData> & {
    registration?: PrimaryRegistration
    category: RegistrationCategory | null
}

export const PrimaryRegistrationForms: FC<PrimaryRegistrationFormsProps> = ({
    registration,
    category,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const [profileFormEnabled, setProfileFormEnabled] = useState(Boolean(registration?.profileFields))

    const fields = useDynamicFormState({
        elements: category?.elements ?? [],
        requirement: "complete",
        initialValues: registration?.fields,
    })
    const profileFields = useDynamicFormState({
        elements: category?.profileElements ?? [],
        requirement: "complete",
        initialValues: registration?.profileFields ?? undefined,
    })

    return <DialogForm
        formData={{
            categoryId: category?.id!,
            fields: fields.values,
            profileFields: profileFormEnabled ? profileFields.values : null,
        }}
        dialogContentStyle={{ py: 0 }}
        isValid={
            category !== null &&
            fields.invalidFields.length === 0 &&
            (!profileFormEnabled || profileFields.invalidFields.length === 0)
        }
        {...props}
    >
        <Collapse in={Boolean(category)}>
            <RegistrationForms
                fields={fields}
                profileFields={profileFields}
                uploadPrefix={`clients/${client.id}/events/${event.id}/registration/${category?.id}`}
                profileUploadPrefix={`clients/${client.id}/events/${event.id}/profiles/${category?.id}`}
                profileFormEnabled={profileFormEnabled}
                onChangeProfileFormEnabled={setProfileFormEnabled}
            />
        </Collapse>
    </DialogForm>
}