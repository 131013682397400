import { ContentEntityId, Permission, SessionId } from "@marketpartner/backend-api";
import { RouteObject } from "react-router";
import { AccessPage } from "src/access/AccessPage";
import { EventActivitiesPage } from "src/activities/EventActivitiesPage";
import { AppAdminPage } from "src/app-templates/AppAdminPage";
import { EventAppDeploymentsPage } from "src/app-templates/deployments/event/EventAppDeploymentsPage";
import { ClientPage } from "src/clients/ClientPage";
import { ClientsPage } from "src/clients/ClientsPage";
import { SessionPage } from "src/content/agendas/sessions/SessionPage";
import { ContentPage } from "src/content/ContentPage";
import { ContentEntityPage } from "src/content/entities/ContentEntityPage";
import { LocationPage } from "src/content/locations/LocationPage";
import { EventPage } from "src/events/EventPage";
import { FormPage } from "src/forms/FormPage";
import { FormsPage } from "src/forms/FormsPage";
import { ModerationPage } from "src/forms/ModerationPage";
import { LoginContext } from "src/login/LoginContext";
import { NetworkingPage } from "src/networking/NetworkingPage";
import { PollPage } from "src/polls/PollPage";
import { PollsPage } from "src/polls/PollsPage";
import { PollQuestionPage } from "src/polls/questions/PollQuestionPage";
import { RegistrationCategoryFormsPage } from "src/registrations/configuration/categories/RegistrationCategoryFormsPage";
import { RegistrationPage } from "src/registrations/RegistrationPage";
import { RegistrationsPage } from "src/registrations/RegistrationsPage";
import { SysadminPage } from "src/sysadmin/SysadminPage";

export type RestrictedRoute = RouteObject & {
    restrictedTo?: (login: LoginContext, clientId?: string, eventId?: string) => boolean
}

export const routes: RestrictedRoute[] = [
    {
        restrictedTo: (login) => login.hasPermission(Permission.AdministerSystem, undefined, undefined),
        path: "/access/*",
        element: <AccessPage />,
    },
    {
        restrictedTo: (login, clientId, eventId) => login.hasPermission(Permission.ViewActivities, clientId, eventId),
        path: "/clients/:clientId/events/:eventId/activities",
        element: <EventActivitiesPage />,
    },
    {
        restrictedTo: (login) => login.hasPermission(Permission.AppAdmin),
        path: "/apps/*",
        element: <AppAdminPage />,
    },
    {
        restrictedTo: (login) => !login.singleClientId,
        path: "/clients",
        element: <ClientsPage />,
    },
    {
        restrictedTo: (login) => !login.singleEventId,
        path: "/clients/:clientId",
        element: <ClientPage />,
    },
    {
        restrictedTo: (login, clientId, eventId) => login.hasPermission(Permission.ViewEventContent, clientId, eventId),
        path: "/clients/:clientId/events/:eventId/content/*",
        element: <ContentPage />,
    },
    {
        restrictedTo: (login, clientId, eventId) => login.hasPermission(Permission.ViewEventContent, clientId, eventId),
        path: "/clients/:clientId/events/:eventId/content/entities/:entityId/*",
        element: <ContentEntityPage />,
    },
    {
        restrictedTo: (login, clientId, eventId) => login.hasPermission(Permission.ViewEventContent, clientId, eventId),
        path: "/clients/:clientId/events/:eventId/content-editor/*",
        element: <ContentPage isContentEditorUi />,
    },
    {
        path: "/clients/:clientId/events/:eventId",
        element: <EventPage />,
    },
    {
        restrictedTo: (login) => login.hasPermission(Permission.ClientAppAdmin),
        path: "/clients/:clientId/events/:eventId/apps",
        element: <EventAppDeploymentsPage />,
    },
    {
        restrictedTo: (login, clientId, eventId) => login.hasPermission(Permission.ViewForms, clientId, eventId),
        path: "/clients/:clientId/events/:eventId/forms/:formId",
        element: <FormPage />,
    },
    {
        restrictedTo: (login, clientId, eventId) => login.hasPermission(Permission.ViewForms, clientId, eventId),
        path: "/clients/:clientId/events/:eventId/forms",
        element: <FormsPage />,
    },
    {
        restrictedTo: (login, clientId, eventId) => login.hasPermission(Permission.ViewEventContent, clientId, eventId),
        path: "/clients/:clientId/events/:eventId/content/locations/:locationId/*",
        element: <LocationPage />,
    },
    {
        restrictedTo: (login, clientId, eventId) => login.hasPermission(Permission.ViewForms, clientId, eventId),
        path: "/clients/:clientId/events/:eventId/moderation",
        element: <ModerationPage />,
    },
    {
        path: "/clients/:clientId/events/:eventId/networking/*",
        element: <NetworkingPage />,
    },
    {
        restrictedTo: (login, clientId, eventId) => login.hasPermission(Permission.ViewPolls, clientId, eventId,),
        path: "/clients/:clientId/events/:eventId/polls/:pollId",
        element: <PollPage />
    },
    {
        restrictedTo: (login, clientId, eventId) => login.hasPermission(Permission.ViewPolls, clientId, eventId,),
        path: "/clients/:clientId/events/:eventId/polls/:pollId/questions/:questionId/*",
        element: <PollQuestionPage />
    },
    {
        restrictedTo: (login, clientId, eventId) => login.hasPermission(Permission.ViewPolls, clientId, eventId,),
        path: "/clients/:clientId/events/:eventId/polls",
        element: <PollsPage />
    },
    {
        restrictedTo: (login, clientId, eventId) => login.hasPermission(Permission.ViewRegistrations, clientId, eventId),
        path: "/clients/:clientId/events/:eventId/registration/*",
        element: <RegistrationsPage />,
    },
    {
        path: "/clients/:clientId/events/:eventId/registration/view/:registrationId/*",
        element: <RegistrationPage />,
    },
    {
        restrictedTo: (login, clientId, eventId) => login.hasPermission(Permission.ViewEventDetails, clientId, eventId),
        path: "/clients/:clientId/events/:eventId/registration/categories/:categoryId/forms/*",
        element: <RegistrationCategoryFormsPage />,
    },
    {
        restrictedTo: (login, clientId, eventId) => login.hasPermission(Permission.ViewEventContent, clientId, eventId),
        path: "/clients/:clientId/events/:eventId/content/sessions/:sessionId/*",
        element: <SessionPage />,
    },
    {
        restrictedTo: login => login.hasPermission(Permission.AdministerSystem, undefined, undefined),
        path: "/sysadmin",
        element: <SysadminPage />,
    },
]

export const link = {
    toAccess: () => `/access`,
    toActivities: (clientId: string, eventId: string) => `/clients/${clientId}/events/${eventId}/activities`,
    toAdminUsers: () => `/access/users`,
    toAdminTokens: () => `/access/admin-tokens`,
    toAgendas: (clientId: string, eventId: string) => `/clients/${clientId}/events/${eventId}/content/agendas`,
    toAgenda: (clientId: string, eventId: string, agendaId: string) => `/clients/${clientId}/events/${eventId}/content/agendas/${agendaId}`,
    toAllAppsList: (search: string) => `/apps/apps${searchParams({ search })}`,
    toApps: () => `/apps`,
    toClients: () => `/clients`,
    toClient: (clientId: string) => `/clients/${clientId}`,
    toClientAppTemplates: (clientId: string) => `/apps/client-templates${searchParams({ clientId })}`,
    toContent: (clientId: string, eventId: string) => `/clients/${clientId}/events/${eventId}/content`,
    toContentEditor: (clientId: string, eventId: string) => `/clients/${clientId}/events/${eventId}/content-editor`,
    toContentEntity: (clientId: string, eventId: string, entityId: ContentEntityId) => `/clients/${clientId}/events/${eventId}/content/entities/${entityId}`,
    toEvent: (clientId: string, eventId: string) => `/clients/${clientId}/events/${eventId}`,
    toEventApps: (clientId: string, eventId: string) => `/clients/${clientId}/events/${eventId}/apps`,
    toFeed: (clientId: string, eventId: string) => `/clients/${clientId}/events/${eventId}/networking/feed`,
    toForm: (clientId: string, eventId: string, formId: number) => `/clients/${clientId}/events/${eventId}/forms/${formId}`,
    toForms: (clientId: string, eventId: string) => `/clients/${clientId}/events/${eventId}/forms`,
    toLocation: (clientId: string, eventId: string, locationId: string) => `/clients/${clientId}/events/${eventId}/content/locations/${locationId}`,
    toModeration: (clientId: string, eventId: string) => `/clients/${clientId}/events/${eventId}/moderation`,
    toNetworking: (clientId: string, eventId: string) => `/clients/${clientId}/events/${eventId}/networking`,
    toPoll: (clientId: string, eventId: string, pollId: string) => `/clients/${clientId}/events/${eventId}/polls/${pollId}`,
    toPollQuestion: (clientId: string, eventId: string, pollId: string, questionId: number) => `/clients/${clientId}/events/${eventId}/polls/${pollId}/questions/${questionId}`,
    toPollQuestionElements: (clientId: string, eventId: string, pollId: string, questionId: number) => `/clients/${clientId}/events/${eventId}/polls/${pollId}/questions/${questionId}/elements`,
    toPollQuestionResponses: (clientId: string, eventId: string, pollId: string, questionId: number) => `/clients/${clientId}/events/${eventId}/polls/${pollId}/questions/${questionId}/responses`,
    toPolls: (clientId: string, eventId: string) => `/clients/${clientId}/events/${eventId}/polls`,
    toRegistration: (clientId: string, eventId: string, registrationId: string) => `/clients/${clientId}/events/${eventId}/registration/view/${registrationId}`,
    toRegistrationActivity: (clientId: string, eventId: string, registrationId: string) => `/clients/${clientId}/events/${eventId}/registration/view/${registrationId}/activities`,
    toRegistrationProfile: (clientId: string, eventId: string, registrationId: string) => `/clients/${clientId}/events/${eventId}/registration/view/${registrationId}/profile`,
    toRegistrationPage: (clientId: string, eventId: string) => `/clients/${clientId}/events/${eventId}/registration`,
    toRegistrations: (clientId: string, eventId: string, search: string) => `/clients/${clientId}/events/${eventId}/registration/registrations${searchParams({ search })}`,
    toRegistrationCategories: (clientId: string, eventId: string) => `/clients/${clientId}/events/${eventId}/registration/categories`,
    toRegistrationCategoryForm: (clientId: string, eventId: string, categoryId: string) => `/clients/${clientId}/events/${eventId}/registration/categories/${categoryId}/form`,
    toRegistrationCategoryForms: (clientId: string, eventId: string, categoryId: string) => `/clients/${clientId}/events/${eventId}/registration/categories/${categoryId}/forms`,
    toRegistrationCategoryProfileSettings: (clientId: string, eventId: string, categoryId: string) => `/clients/${clientId}/events/${eventId}/registration/categories/${categoryId}/forms/profile-settings`,
    toRegistrationTokens: (clientId: string, eventId: string, search: string) => `/access/registration-tokens${searchParams({ clientId, eventId, search })}`,
    toSession: (clientId: string, eventId: string, sessionId: SessionId) => `/clients/${clientId}/events/${eventId}/content/sessions/${sessionId}`,

    toEp: (epId: string) => `https://${epId}.eventpartner.tech`,
    toEpResources: (epId: string, eventToken: string) => `https://${epId}.eventpartner.tech/#/event-resources/${eventToken}`,
    toEpResourceEdit: (epId: string, eventToken: string, resourceId: string) => `https://${epId}.eventpartner.tech/#/resource-edit/${resourceId}/${eventToken}`,
    toEpSessions: (epId: string, eventToken: string) => `https://${epId}.eventpartner.tech/#/event-sessions/${eventToken}`,
    toEpSessionEdit: (epId: string, eventToken: string, sessionId: string) => `https://${epId}.eventpartner.tech/#/session-edit/${sessionId}/${eventToken}`,
    toEpSessionSpeakers: (epId: string, eventToken: string, sessionId: string) => `https://${epId}.eventpartner.tech/#/session-speakers/${sessionId}/${eventToken}`,
    toEpSessionResources: (epId: string, eventToken: string, sessionId: string) => `https://${epId}.eventpartner.tech/#/session-resources/${sessionId}/${eventToken}`,
    toEpSpeakers: (epId: string, eventToken: string) => `https://${epId}.eventpartner.tech/#/event-speakers/${eventToken}`,
    toEpSponsors: (epId: string, eventToken: string) => `https://${epId}.eventpartner.tech/#/event-sponsors/${eventToken}`,
}

function searchParams(params = {}) {
    const filtered = Object.fromEntries(
        Object.entries(params).filter(it => it[1] !== undefined && it[1] !== null)
    ) as Record<string, string>
    if (Object.keys(filtered).length) {
        const search = new URLSearchParams(filtered)
        return `?${search}`
    }
    return ""
}