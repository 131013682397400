import { Registration } from "@marketpartner/backend-api";
import { casualName } from "@marketpartner/mp-common";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { link } from "src/common/routing/routes";
import { useEvent } from "src/events/event-context";
import { NameAndSubtitle, NameAndSubtitleProps } from "src/registrations/NameAndSubtitle";

export type NameAndEmailProps = Partial<NameAndSubtitleProps> & {
    registration: Registration
    linkToRegistration?: boolean
}

export const NameAndEmail: FC<NameAndEmailProps> = ({
    registration,
    linkToRegistration,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!

    return <NameAndSubtitle
        name={casualName(registration)}
        subtitle={registration.email ?? undefined}
        link={linkToRegistration ? link.toRegistration(client.id, event.id, registration.id) : undefined}
        {...props} />
}