import { Session } from "@marketpartner/backend-api"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { link } from "src/common/routing/routes"
import { ContentAvatar } from "src/content/ContentAvatar"
import { ContentCard, ContentCardProps } from "src/content/ContentCard"
import { useEvent } from "src/events/event-context"


export type SessionCardProps = Partial<ContentCardProps> & {
    session: Session
}

export const SessionCard: FC<SessionCardProps> = ({
    session,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!

    return <ContentCard
        avatar={<ContentAvatar
            alt={session.name}
            children={session.name[0]}
        />}
        primaryText={session.name}
        secondaryTexts={[session.subtitle]}
        link={link.toSession(client.id, event.id, session.id)}
        {...props}
    />
}