import { AppTemplateDeployment, Permission } from "@marketpartner/backend-api"
import { Typography } from "@mui/material"
import { FC } from "react"
import { DeleteButton, DeleteButtonProps } from "src/common/dialogs/DeleteButton"
import { backend } from "src/common/api"
import { ifHas } from "src/login/IfHas"


export type DeleteAppDeploymentButtonProps = {
    deployment: AppTemplateDeployment
} & Partial<DeleteButtonProps>

export const DeleteAppDeploymentButton: FC<DeleteAppDeploymentButtonProps> = ({
    deployment,
    ...props
}) => {
    const deleteEventDeploymentMutation = backend.appTemplateDeployments.useDeleteEventDeployment()

    return ifHas(Permission.AppAdmin, undefined, undefined, <DeleteButton
        deleteAction={() => deleteEventDeploymentMutation.mutateAsync([
            deployment.scope.clientId!,
            deployment.scope.eventId!,
            deployment.id,
        ])}
        itemName={deployment.name}
        text={<>
            <Typography>
                Confirm deletion of <b>{deployment.name}</b>,
                hosted at <b>{deployment.domainName}/{deployment.path}/</b>
            </Typography>
            <Typography>
                This will take effect immediately and cannot be undone.
            </Typography>
        </>}
        confirmText={deployment.name}
        {...props}
    />)
}