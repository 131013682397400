import { BooleanExpression, BooleanExpressionGroup } from "@marketpartner/backend-api"
import { Add } from "@mui/icons-material"
import { Button, Stack, Typography } from "@mui/material"
import { Fragment } from "react"
import { useAddBooleanConditionMenu } from "src/common/boolean-conditions/AddBooleanConditionMenu"
import { BooleanConditionPropertiesLookup, TypedBooleanCondition } from "src/common/boolean-conditions/boolean-condition-properties"
import { BooleanExpressionContainer } from "src/common/boolean-conditions/BooleanExpressionContainer"
import { BooleanExpressionGroupHeader } from "src/common/boolean-conditions/BooleanExpressionGroupHeader"
import { EditableBooleanExpression } from "src/common/boolean-conditions/EditableBooleanExpression"


export type EditableBooleanExpressionGroupProps<Condition extends TypedBooleanCondition> = {
    group: BooleanExpressionGroup<Condition>
    onChangeGroup: (group: BooleanExpressionGroup<Condition>) => void
    onDelete: () => void
    conditionPropertiesLookup: BooleanConditionPropertiesLookup<Condition>
    isRoot?: boolean
}

export const EditableBooleanExpressionGroup = <Condition extends TypedBooleanCondition>({
    group,
    onChangeGroup,
    onDelete,
    conditionPropertiesLookup,
    isRoot = false,
}: EditableBooleanExpressionGroupProps<Condition>) => {
    const addConditionMenu = useAddBooleanConditionMenu()

    const handleDelete = (idx: number) => onChangeGroup({
        ...group,
        expressions: group.expressions.filter((_, index) => index !== idx),
    })

    const handleChange = (idx: number, expression: BooleanExpression<any>) => onChangeGroup({
        ...group,
        expressions: group.expressions.map((e, index) => index === idx ? expression : e),
    })

    const onAdd = (expression: BooleanExpression<any>) => onChangeGroup({
        ...group,
        expressions: [...group.expressions, expression],
    })

    return <BooleanExpressionContainer expression={group} isRoot={isRoot}>
        <BooleanExpressionGroupHeader
            group={group}
            onChangeGroup={onChangeGroup}
            onDelete={onDelete}
            isRoot={isRoot}
        />
        <Stack spacing={2} sx={{ pl: 2, my: 2 }}>
            {group.expressions.length === 0 ?
                <Typography color="text.secondary" sx={{ alignSelf: "center" }}>Group is empty</Typography> :
                group.expressions.map((expression, index) => <Fragment key={index}>
                    {index > 0 && <Typography fontWeight="bold">
                        {group.operator}
                    </Typography>}
                    <EditableBooleanExpression
                        expression={expression}
                        onChange={expression => handleChange(index, expression)}
                        onDelete={() => handleDelete(index)}
                        conditionPropertiesLookup={conditionPropertiesLookup}
                    />
                </Fragment>)
            }
        </Stack>
        <Button
            children="Add condition"
            onClick={e => addConditionMenu.open({
                anchorEl: e.currentTarget,
                onAdd,
                conditionRenderLookup: conditionPropertiesLookup,
            })}
            startIcon={<Add />}
            color="inherit"
            sx={{ alignSelf: "flex-start" }}
        />
    </BooleanExpressionContainer>
}
