import { Scope } from "@marketpartner/backend-api";
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { useEvent } from "src/events/event-context";
import { ComputedColumnForm } from "src/registrations/computed-columns/ComputedColumnForm";


export type CreateComputedColumnDialogProps = LockableDialogProps

const CreateComputedColumnDialog: FC<CreateComputedColumnDialogProps> = (props) => {
    const client = useClient()!
    const event = useEvent()!

    const updateEventMutation = backend.events.useUpdateEvent(withNotification({
        errorMessage: "Error creating column"
    }))

    return <LockableDialog
        maxWidth="sm"
        fullWidth
        {...props}
    >
        <DialogTitle>Create column</DialogTitle>
        <ComputedColumnForm
            actionName="Create"
            onSubmit={column => updateEventMutation.mutateAsync([client.id, event.id, {
                computedColumns: [
                    ...event.computedColumns,
                    column,
                ]
            }])}
        />
    </LockableDialog>
}

export const useCreateComputedColumnDialog = createPopupHook({
    element: CreateComputedColumnDialog,
    scope: Scope.Event,
})