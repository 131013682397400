import { Permission, PollResponseDisplayStatus } from "@marketpartner/backend-api"

import { Checkbox, FormControlLabel, Paper } from "@mui/material"
import { FC, useMemo, useState } from "react"
import { useParams } from "react-router"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { QueryDataGrid } from "src/common/grid/QueryDataGrid"
import { Bar } from "src/common/layout/Bar"
import { BarAndFlex } from "src/common/layout/BarAndFlex"
import { fullSize } from "src/common/styles"
import { useEvent } from "src/events/event-context"
import { useLoginContext } from "src/login/LoginContext"
import { buildPollResponsesColumns } from "src/polls/questions/responses/buildPollResponsesColumns"

export const PollResponses:FC = () => {

    const client = useClient()!
    const event = useEvent()!
    const params = useParams()
    const pollId = params.pollId!
    const questionId = parseInt(params.questionId!)
    const login = useLoginContext()

    const [showHiddenResponses, setShowHiddenResponses] = useState(false)
    
    const pollResponsesQuery = backend.pollResponses.useGetForQuestion([client.id, event.id, pollId, questionId], {
        select: responses => responses.filter(it =>
            showHiddenResponses ||
            it.displayStatus !== PollResponseDisplayStatus.Hidden
        ),
        refetchInterval: 6000
    })
    
    const canDeleteResponses = login.hasPermission(Permission.EditPolls, client.id, event.id)
    const canModerateResponses = login.hasPermission(Permission.EditPolls, client.id, event.id) //TODO: add moderate permission
    const columns = useMemo(
        () => buildPollResponsesColumns(
            pollResponsesQuery.data ?? [],
            canDeleteResponses,
            canModerateResponses
        ),
        [pollResponsesQuery.data, canDeleteResponses, canModerateResponses]
    )

    return <BarAndFlex
        barContent={<Bar p={1}>
            <FormControlLabel
                label="Show hidden responses"
                control={<Checkbox
                    checked={showHiddenResponses}
                    onChange={e => setShowHiddenResponses(e.target.checked)}
                />}
            />
        </Bar>}
    >
        <Paper sx={{ ...fullSize }}>
            
            <QueryDataGrid
                columns={columns}
                queryOrRows={pollResponsesQuery}
            />
        </Paper>
    </BarAndFlex>
}