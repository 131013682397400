import { closestCenter, DndContext, DragEndEvent, DragOverlay, DragStartEvent, Modifiers, MouseSensor, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { arrayMove, rectSortingStrategy, SortableContext, SortingStrategy, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';
import { DragHandle, DragIndicator } from "@mui/icons-material";
import { Box, Collapse, Fade, Paper, Stack, StackProps } from "@mui/material";
import { FC, forwardRef, ReactNode, useCallback, useState } from "react";

export type ReorderableItem = {
    id: string | number
}

export type ReorderableStackVariant = "stack" | "grid"

export type ReorderableStackProps<Item extends ReorderableItem> = Partial<StackProps> & {
    reorderEnabled?: boolean
    loading?: boolean
    items: Item[]
    onReorder: (items: Item[]) => void
    renderItem: (item: Item, idx: number) => ReactNode
    addItem?: ReactNode
    variant?: ReorderableStackVariant
}

type ReorderableDragHandleLocation = "left" | "bottom"
type VariantProps = {
    modifiers: Modifiers
    strategy: SortingStrategy
    dragHandleLocation: ReorderableDragHandleLocation
    sx?: StackProps["sx"]
}
const variantPropsLookup: Record<ReorderableStackVariant, VariantProps> = {
    stack: {
        modifiers: [restrictToVerticalAxis, restrictToParentElement],
        dragHandleLocation: "left",
        strategy: verticalListSortingStrategy,
    },
    grid: {
        modifiers: [restrictToParentElement],
        dragHandleLocation: "bottom",
        strategy: rectSortingStrategy,
        sx: {
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "stretch",
        }
    },
}

export const ReorderableStack = <Item extends ReorderableItem>({
    reorderEnabled = true,
    loading = false,
    items,
    onReorder,
    renderItem,
    addItem,
    variant = "stack",
    sx,
    ...props
}: ReorderableStackProps<Item>) => {
    const [activeId, setActiveId] = useState<string | number | null>(null)
    const sensors = useSensors(useSensor(MouseSensor))
    const variantProps = variantPropsLookup[variant]

    const onDragStart = useCallback(({ active }: DragStartEvent) => {
        setActiveId(active.id)
    }, [])

    const onDragEnd = useCallback(({ active, over }: DragEndEvent) => {
        setActiveId(null)
        if (over && active.id !== over.id) {
            const oldIndex = items.findIndex(it => it.id === active.id)
            const newIndex = items.findIndex(it => it.id === over.id)

            onReorder(arrayMove(items, oldIndex, newIndex))
        }
    }, [items, onReorder])

    const activeItem = activeId !== null ? items.find(it => it.id === activeId) : null

    return <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        modifiers={variantProps.modifiers}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
    >
        <SortableContext
            items={items}
            strategy={variantProps.strategy}
            disabled={!reorderEnabled || loading}
        >
            <Stack
                sx={{
                    ...(variantProps.sx as any),
                    ...sx,
                }}
                {...props}
            >
                {items.map((item, idx) => <DraggableRow
                    key={item.id}
                    id={item.id}
                    reorderEnabled={reorderEnabled}
                    loading={loading}
                    dragHandleLocation={variantProps.dragHandleLocation}
                >
                    {renderItem(item, idx)}
                </DraggableRow>)}
                {addItem && <Fade in={!reorderEnabled}>
                    <Box sx={{ display: "flex" }}>{addItem}</Box>
                </Fade>}
            </Stack>
        </SortableContext>
        <DragOverlay>
            {activeItem && <Paper>
                <ReorderableRow
                    reorderEnabled={reorderEnabled}
                    loading={false}
                    dragHandleLocation={variantProps.dragHandleLocation}
                    sx={{
                        cursor: "grabbing",
                    }}
                >
                    {renderItem(activeItem, 0)}
                </ReorderableRow>
            </Paper>}
        </DragOverlay>
    </DndContext>
}

type ReorderableRowProps = StackProps & {
    reorderEnabled: boolean
    loading: boolean
    dragHandleLocation: ReorderableDragHandleLocation
}

const ReorderableRow = forwardRef<HTMLDivElement, ReorderableRowProps>(({
    reorderEnabled,
    loading,
    dragHandleLocation,
    children,
    sx,
    ...props
}, ref) => {

    return <Stack
        direction={dragHandleLocation === "left" ? "row" : "column-reverse"}
        ref={ref}
        {...props}
        sx={{
            alignItems: "center",
            cursor: !loading && reorderEnabled ? "grab" : undefined,
            ...sx,
        }}
    >
        <Collapse
            in={reorderEnabled}
            orientation={dragHandleLocation === "left" ? "horizontal" : "vertical"}
        >
            {dragHandleLocation === "left" ?
                <DragIndicator color={loading ? "disabled" : "inherit"} sx={{
                    mr: 1,
                    my: "auto",
                }} /> :
                <DragHandle color={loading ? "disabled" : "inherit"} sx={{
                    mx: "auto",
                }} />
            }
        </Collapse>
        <Box sx={{
            flexGrow: 1,
            alignSelf: "stretch",
            pointerEvents: reorderEnabled ? "none" : undefined,
            inert: reorderEnabled ? "inert" : undefined,
        }}>
            {children}
        </Box>
    </Stack>
})

type DraggableRowProps = {
    id: string | number
    reorderEnabled: boolean
    loading: boolean
    dragHandleLocation: ReorderableDragHandleLocation
    children: ReactNode
}

const DraggableRow: FC<DraggableRowProps> = ({
    id,
    ...props
}) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id })

    return <ReorderableRow
        {...props}
        {...attributes}
        {...listeners}
        ref={setNodeRef}
        sx={{
            transform: CSS.Transform.toString(transform),
            transition,
            opacity: isDragging ? 0 : undefined
        }}
    />
}