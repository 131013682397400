import { Registration } from "@marketpartner/backend-api"
import { createContext, FC, ReactNode, useContext, useMemo, useState } from "react"
import { UserUsagePopupContent } from "src/activities/user-usage/UserUsagePopupContent"
import { LockableDialog } from "src/common/dialogs/LockableDialog"

export type UsageUser = Registration

export type UserUsagePopupContext = {
    openUsage: (user: UsageUser) => void
    close: () => void
}

const context = createContext<UserUsagePopupContext>(undefined!)

export const useUserUsagePopup = () => useContext(context)

type PopupParameters = {
    user: UsageUser
}

export type UserUsagePopupContextProps = {
    children: ReactNode
}

export const UserUsagePopupContextProvider: FC<UserUsagePopupContextProps> = ({
    children
}) => {
    const [parameters, setParameters] = useState<PopupParameters>()

    return <context.Provider
        children={<>
            {children}
            <LockableDialog
                open={Boolean(parameters)}
                onClose={() => setParameters(undefined)}
                maxWidth="xs"
                fullWidth
            >
                {parameters && <UserUsagePopupContent user={parameters.user} />}
            </LockableDialog>
        </>}
        value={useMemo(() => ({
            openUsage: (user) => setParameters({ user }),
            close: () => setParameters(undefined)
        }), [])}
    />
}