import { AdminUser } from "@marketpartner/backend-api"
import { FC } from "react"
import { backend } from "src/common/api"
import { DeleteButton } from "src/common/dialogs/DeleteButton"

export type DeleteUserButtonProps = {
    user: AdminUser
}

export const DeleteUserButton: FC<DeleteUserButtonProps> = ({
    user
}) => {
    const deleteMutation = backend.adminUsers.useDelete()

    return <DeleteButton
        text=""
        deleteAction={() => deleteMutation.mutateAsync([user.id])}
        itemName={user.name}
        confirmText={user.email}
    />
}