import { ActivityType } from "@marketpartner/backend-api";
import { AccessTime, AdsClick } from "@mui/icons-material";
import { SvgIconProps } from "@mui/material";
import { FC } from "react";


export type ActivityTypeIconProps = Partial<SvgIconProps> & {
    type: ActivityType
}

export const ActivityTypeIcon: FC<ActivityTypeIconProps> = ({
    type,
    ...props
}) => {
    switch (type) {
        case ActivityType.action: return <AdsClick {...props} />
        case ActivityType.timed: return <AccessTime {...props} />
    }
}