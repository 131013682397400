import { Poll, Scope } from "@marketpartner/backend-api"
import { DialogTitle } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { withNotification } from "src/common/notifications/with-notification"
import { useEvent } from "src/events/event-context"
import { PollForm } from "src/polls/PollForm";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup"
type EditPollDialogProps = LockableDialogProps & {
    poll: Poll
}
export const EditPollDialog: FC<EditPollDialogProps> = ({
    poll,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const editMutation = backend.polls.useUpdate(withNotification({
        successMessage: "Updated poll",
        errorMessage: "Error updating poll"
    }))

    return <LockableDialog maxWidth="sm" {...props}>
        <DialogTitle>Edit {poll.name}</DialogTitle>
        <PollForm
            actionName="Save"
            onSubmit={pollDetails => editMutation.mutateAsync([client.id, event.id, poll.id, pollDetails])}
            poll={poll}
        />
    </LockableDialog>
}

export const useEditPollDialog = createPopupHook({
    element: EditPollDialog,
    scope: Scope.Event,
})