import { findFormField } from "@marketpartner/backend-api";
import { FormElementCheck } from "src/forms/elements/checks/form-element-checks";


export const nameElementCheck: FormElementCheck = elements => {
    const firstName = findFormField(elements, "first_name")
    const lastName = findFormField(elements, "last_name")

    if (!firstName && !lastName) {
        return {
            renderIssueContent: () => <>Form must have a <b>first_name</b> and/or <b>last_name</b> field</>,
            level: "error",
        }
    }

    const firstRequired = firstName?.required ?? false
    const lastRequired = lastName?.required ?? false
    if (!lastName && !firstRequired) {
        return {
            renderIssueContent: () => <><b>first_name</b> must be required</>,
            level: "error",
            elementIdxs: [elements.indexOf(firstName!)]
        }
    }

    if (!firstName && !lastRequired) {
        return {
            renderIssueContent: () => <><b>last_name</b> must be required</>,
            level: "error",
            elementIdxs: [elements.indexOf(lastName!)]
        }
    }

    if (!firstRequired && !lastRequired) {
        return {
            renderIssueContent: () => <><b>first_name</b> or <b>last_name</b> must be required</>,
            level: "error",
            elementIdxs: [
                elements.indexOf(firstName!),
                elements.indexOf(lastName!),
            ]
        }
    }
}