import { Scope, StandardContentTypeId } from "@marketpartner/backend-api";
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { EditableDialogList } from "src/common/dialogs/EditableDialogList";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { useAddContentTypeMenu } from "src/content/types/AddContentTypeMenu";
import { useCreateContentTypeDialog } from "src/content/types/CreateContentTypeDialog";
import { useDeleteContentTypeDialog } from "src/content/types/DeleteContentTypeDialog";
import { useEditContentTypeDialog } from "src/content/types/EditContentTypeDialog";
import { useEvent } from "src/events/event-context";

export type ContentTypesDialogProps = LockableDialogProps

const ContentTypesDialog: FC<ContentTypesDialogProps> = (props) => {
    const client = useClient()!
    const event = useEvent()!

    const typesQuery = backend.contentTypes.useGetTypes([client.id, event.id])

    const addTypeMenu = useAddContentTypeMenu()
    const createTypeDialog = useCreateContentTypeDialog()
    const editTypeDialog = useEditContentTypeDialog()
    const deleteTypeDialog = useDeleteContentTypeDialog()

    const missingStdTypeIds = Object.values(StandardContentTypeId)
        .filter(typeId => !typesQuery.data?.some(t => t.id === typeId))

    return <LockableDialog
        maxWidth="xs"
        fullWidth
        {...props}
    >
        <DialogTitle>Content types</DialogTitle>
        <EditableDialogList
            itemsQuery={typesQuery}
            getPrimaryText={type => type.name}
            getSecondaryText={type => type.id}
            contextMenuProps={type => ({
                onClickDelete: () => deleteTypeDialog.open({ type }),
                onClickEdit: () => editTypeDialog.open({ type }),
            })}
            addProps={{
                onClickAdd: e => missingStdTypeIds.length === 0 ?
                    createTypeDialog.open({}) :
                    addTypeMenu.open({
                        anchorEl: e.currentTarget,
                        missingStdTypeIds,
                    }),
            }}
        />
    </LockableDialog>
}

export const useContentTypesDialog = createPopupHook({
    element: ContentTypesDialog,
    scope: Scope.Event,
})