import { FormResponseDisplayStatus } from "@marketpartner/backend-api"
import { Lock, LockOutlined, Visibility, VisibilityOff, VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material"
import { IconButton, IconProps } from "@mui/material"
import { cloneElement, FC, ReactElement } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { withNotification } from "src/common/notifications/with-notification"
import { useEvent } from "src/events/event-context"


export type ResponseStatusButtonsProps = {
    formId: number
    formResponseId: number
    displayStatus: FormResponseDisplayStatus
}

export const ResponseStatusButtons: FC<ResponseStatusButtonsProps> = ({
    formId,
    formResponseId,
    displayStatus,
}) => {
    const client = useClient()!
    const event = useEvent()!

    const updateMutation = backend.formResponses.useUpdate(withNotification({
        successMessage: 'Response updated',
        errorMessage: 'Request failed',
    }))

    const statusButton = (
        status: FormResponseDisplayStatus,
        ActiveIcon: ReactElement<IconProps>,
        InactiveIcon: ReactElement<IconProps>,
    ) => <IconButton
        onClick={() => updateMutation.mutate([client.id, event.id, formId, formResponseId, {
            displayStatus: status
        }])}
        disabled={updateMutation.isPending}
    >
            {displayStatus === status ?
                cloneElement(ActiveIcon, { color: !updateMutation.isPending ? "primary" : undefined }) :
                InactiveIcon
            }
        </IconButton>

    return <>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center' }}>
            {statusButton(FormResponseDisplayStatus.Public, <Visibility />, <VisibilityOutlined />)}
            {statusButton(FormResponseDisplayStatus.Private, <Lock />, <LockOutlined />)}
            {statusButton(FormResponseDisplayStatus.Hidden, <VisibilityOff />, <VisibilityOffOutlined />)}
        </div>
    </>
}