import { CurrencyAmount, CurrencyCode, currencyToFloat, floatToCurrency } from "@marketpartner/backend-api";
import { TextField, TextFieldProps } from "@mui/material";
import { FC, useState } from "react";
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field';


export type MuiCurrencyInputProps = Omit<TextFieldProps, "value" | "onChange"> & {
    currencyCode: CurrencyCode
    value: CurrencyAmount
    onChange: (value: CurrencyAmount) => void
}

/**
 * Specialized MUI Text Field for currency input.
 * 
 * This component uses react-currency-input-field to handle currency formatting/behaviour.
 * This is due to its native support for using Intl.NumberFormat for currency formatting
 * based on the ISO currency code, which other components don't provide.
 * 
 * There are a couple of complexities with this integration:
 * 
 * - The underlying component uses a float value for the input, so values must be
 *   converted to/from a CurrencyAmount based on the currencies decimal places.
 * - Users of this component only care about the inteteger CurrencyAmount, however
 *   the underlying component (mostly) uses a string value for the state to cater
 *   for the intermediate situation where the user has typed a decimal point, but
 *   has not yet entered the decimal value. This component is therefore semi-controlled,
 *   and uses the underlying string value for rendering, except for when the 
 *   provided `value` prop differs (see determineRenderedInputValue).
 */
export const MuiCurrencyInput: FC<MuiCurrencyInputProps> = ({
    currencyCode,
    value,
    onChange,
    ...props
}) => {
    const [internalValue, setInternalValue] = useState<number | string>(currencyToFloat(value, currencyCode))
    const renderedValue = determineRenderedInputValue(internalValue, value, currencyCode)

    const handleChange: CurrencyInputProps["onValueChange"] = (strValue, _name, value) => {
        setInternalValue(strValue ?? 0)
        const floatValue = value?.float ?? 0
        onChange(floatToCurrency(floatValue, currencyCode))
    }

    const renderedDecimalPlaces = Intl.NumberFormat(
        undefined,
        { style: "currency", currency: currencyCode }
    ).resolvedOptions().maximumFractionDigits

    return <CurrencyInput
        customInput={TextField}
        intlConfig={{
            locale: Intl.NumberFormat().resolvedOptions().locale,
            currency: currencyCode,
        }}
        required
        value={renderedValue}
        onValueChange={handleChange}
        allowNegativeValue={false}

        // Never allow editing of 3rd decimal place for stripe compatibility.
        decimalsLimit={Math.min(renderedDecimalPlaces, 2)}

        {...props as any}
    />
}

/**
 * If the internal value has the same numeric value as the provided `value` prop, render the internal value.
 * This allows the user to type a trailing decimal point.
 * 
 * If the `value` prop differs from the internal value, render the `value` prop instead (this indicates that
 * the value was changed outside the component).
 */
const determineRenderedInputValue = (
    internalValue: number | string,
    value: CurrencyAmount,
    currencyCode: CurrencyCode
): number | string => {
    const internalFloatValue = typeof internalValue === "string" ? parseFloat(internalValue) : internalValue
    const internalAmount = floatToCurrency(internalFloatValue, currencyCode)
    if (internalAmount === value) {
        return internalValue
    }
    return currencyToFloat(value, currencyCode)
}