import { Location } from "@marketpartner/backend-api";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { LoadedAutocomplete, LoadedAutocompleteProps } from "src/common/form-inputs/LoadedAutocomplete";
import { useEvent } from "src/events/event-context";


export type LocationSelectorProps = Omit<LoadedAutocompleteProps<string, Location>, "fetchRequest">

export const LocationSelector: FC<LocationSelectorProps> = ({
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!

    const locationsQuery = backend.locations.useGetLocations([client.id, event.id])

    return <LoadedAutocomplete
        fetchRequest={locationsQuery}
        {...props}
    />
}