import { FC } from "react";
import { MuiCurrencyInput, MuiCurrencyInputProps } from "src/common/currency/MuiCurrencyInput";
import { useEvent } from "src/events/event-context";


export type EventCurrencyInputProps = Omit<MuiCurrencyInputProps, "currencyCode">

export const EventCurrencyInput: FC<EventCurrencyInputProps> = (props) => {
    const event = useEvent()!

    return <MuiCurrencyInput currencyCode={event.currencyCode} {...props} />
}