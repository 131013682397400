import { ContentEntity } from "@marketpartner/backend-api"
import { Badge, BadgeOwnProps, Box } from "@mui/material"
import { FC } from "react"
import { ContentAvatar, ContentAvatarProps } from "src/content/ContentAvatar"
import { ContentTypeIcon } from "src/content/types/ContentTypeIcon"


export type ContentEntityAvatarProps = Partial<ContentAvatarProps> & {
    entity: ContentEntity
    badgeProps?: Partial<BadgeOwnProps>
}

export const ContentEntityAvatar: FC<ContentEntityAvatarProps> = ({
    entity,
    badgeProps,
    ...props
}) => {
    const imageUrl = entity.fields.thumbnail_image?.absoluteUrl

    return <Badge
        badgeContent={<ContentTypeIcon typeId={entity.typeId} fontSize="small" />}
        color="primary"
        overlap="circular"
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        invisible={!imageUrl || !props.size || props.size < 75}
        {...badgeProps}
    >
        <ContentAvatar
            src={imageUrl}
            alt={entity.fields.title}
            children={imageUrl ? undefined : <ContentTypeIcon
                typeId={entity.typeId}
                fontSize="large"
            />}
        />
        {imageUrl !== undefined && <Box 
            sx={{
                position: "absolute", 
                bottom: 0, 
                right: 0, 
                padding: "5px", 
                background: "#fff", 
                borderRadius: "50%", 
                width: "35px", 
                height: "35px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <ContentTypeIcon typeId={entity.typeId} fontSize="small" /> 
        </Box>}
    </Badge>
}