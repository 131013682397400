import { Permission } from "@marketpartner/backend-api"
import { Assignment, Edit } from "@mui/icons-material"
import { Button, Stack } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { Bar } from "src/common/layout/Bar"
import { Spacer } from "src/common/layout/Spacer"
import { useEvent } from "src/events/event-context"
import { useEditEventFieldsDialog } from "src/events/fields/EditEventFieldsDialog"
import { EventFieldElementMetadataIcons } from "src/events/fields/EventFieldElementMetadataIcons"
import { useEventFieldElementsDialog } from "src/events/fields/EventFieldElementsDialog"
import { FormDataTable } from "src/forms/elements/FormDataTable"
import { ifHas } from "src/login/IfHas"


export type EventFieldsProps = {}

export const EventFields: FC<EventFieldsProps> = () => {
    const client = useClient()!
    const event = useEvent()!

    const editFieldsDialog = useEditEventFieldsDialog()
    const editElementsDialog = useEventFieldElementsDialog()

    return <Stack>
        <Bar spacing={2} sx={{ px: 2 }}>
            <Spacer />
            {ifHas(Permission.EditExtraFields, client.id, event.id, <Button
                children="Edit fields"
                variant="contained"
                startIcon={<Edit />}
                onClick={() => editFieldsDialog.open({})}
            />)}
            {ifHas(Permission.EditEventDetails, client.id, event.id, <Button
                children="Edit form"
                startIcon={<Assignment />}
                color="inherit"
                onClick={() => editElementsDialog.open({})}
            />)}
        </Bar>
        <FormDataTable
            data={event.fields}
            elements={event.fieldElements}
            extraLeftColumns={[{
                headerName: "Visibility",
                renderCell: (_name, _value, config) => <EventFieldElementMetadataIcons metadata={config?.metadata}/>,
                width: 100,
            }]}
        />
    </Stack>
}