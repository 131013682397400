import { ComputedColumn } from "@marketpartner/backend-api"
import { isValidExpressionRecursive } from "src/common/boolean-conditions/boolean-condition-validity"


export const isValidComputedColumn = (column: ComputedColumn): boolean => {
    if (!column.id) {
        return false
    }

    switch (column.type) {
        case "Boolean":
            return isValidExpressionRecursive(column.expressions)
        case "ActionCount":
        case "TimedActivityDuration":
            return column.lookups.every(lookup => lookup.id)
    }
}