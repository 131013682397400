import { MoreHoriz } from "@mui/icons-material";
import { IconButton, IconButtonProps, Theme } from "@mui/material";

export type ContextMenuButtonTone = "light" | "medium" | "dark"

export type ContextMenuButtonProps = Partial<IconButtonProps> & {
    size?: "small" | "medium" | "large",
    tone?: ContextMenuButtonTone,
}

const toneColors: Record<ContextMenuButtonTone, (theme: Theme) => string> = {
    light: theme => theme.palette.grey["300"],
    medium: theme => theme.palette.text.secondary,
    dark: theme => theme.palette.text.primary,
}

export const ContextMenuButton = ({
    size = "medium",
    tone = "light",
    ...props
}: ContextMenuButtonProps) => {
    return <IconButton
        size={size}
        sx={{
            color: theme => toneColors[tone](theme),
            transition: "color 0.15s",
            "&:hover": {
                color: theme => theme.palette.text.primary,
            },
            ...props.sx,
        }}
        {...props}
    >
        <MoreHoriz fontSize={size} />
    </IconButton>
}