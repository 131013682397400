import { Permission } from '@marketpartner/backend-api';
import AddIcon from '@mui/icons-material/Add';
import { DialogTitle } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router';
import { ClientForm } from 'src/clients/ClientForm';
import { backend } from 'src/common/api';
import { DialogButton, DialogButtonProps } from "src/common/dialogs/DialogButton";
import { withNotification } from 'src/common/notifications/with-notification';
import { link } from 'src/common/routing/routes';
import { ifHas } from 'src/login/IfHas';

export type CreateClientButtonProps = Partial<DialogButtonProps>

export const CreateClientButton: FC<CreateClientButtonProps> = ({
    ...props
}) => {
    const createMutation = backend.clients.useCreate(withNotification({
        successMessage: "Created client",
        errorMessage: "Error creating client",
        onSuccess: (_, [clientDetails]) => navigate(link.toClient(clientDetails.id))
    }))
    const navigate = useNavigate()

    return ifHas(Permission.AdministerSystem, undefined, undefined,
        <DialogButton
            icon={<AddIcon />}
            text="New client"
            color="primary"
            variant="contained"
            {...props}
        >
            <DialogTitle>Create new client</DialogTitle>
            <ClientForm
                actionName="Create"
                onSubmit={clientDetails => createMutation.mutateAsync([clientDetails])}
            />
        </DialogButton>
    )
}
