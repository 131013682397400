import { Box, Button, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { useDialogContext } from "src/common/dialogs/LockableDialog"
import { Bar } from "src/common/layout/Bar"
import { Loading } from "src/common/loading/Loading"
import { useEvent } from "src/events/event-context"
import { CreateSegmentButton } from "src/events/segments/CreateSegmentButton"
import { EventSegmentsGrid } from "src/events/segments/EventSegmentsGrid"


export const SegmentsDialogContent: FC = () => {
    const client = useClient()!
    const event = useEvent()!
    const dialogContext = useDialogContext()
    const segmentsQuery = backend.eventSegments.useGetAll([client.id, event.id])

    return <>
        <Bar>
            <DialogTitle>
                Event segments
            </DialogTitle>
            <Typography variant="subtitle1">
                ({event.timezone})
            </Typography>
        </Bar>
        <DialogContent>
            <Loading request={segmentsQuery} sizing="fit-content" render={segments =>
                <EventSegmentsGrid segments={segments} />}
            />
        </DialogContent>
        <DialogActions>
            <CreateSegmentButton />
            <Box m="auto" />
            <Button color="inherit" onClick={() => dialogContext.close()}>Close</Button>
        </DialogActions>
    </>
}