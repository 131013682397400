import { customUiRoles } from "src/common/routing/custom-ui-roles"
import { link } from "src/common/routing/routes"
import { LoginContext } from "src/login/LoginContext"

export function getDefaultPageForLogin(login: LoginContext) {
    const customUi = login.singleRole && customUiRoles[login.singleRole]
    if (customUi && customUi.defaultPage) {
        return customUi.defaultPage(login)
    }

    if (login.singleClientId && login.singleEventId) {
        return link.toEvent(login.singleClientId, login.singleEventId)
    }
    if (login.singleClientId) {
        return link.toClient(login.singleClientId)
    }
    return "/clients"
}