import { Grid, TextField } from "@mui/material"
import { FC, useState } from "react"
import { DialogForm, ExtendableDialogFormProps } from "src/common/dialogs/DialogForm"

export type UserFormData = {
    name: string
    email: string
}

export type UserFormProps = ExtendableDialogFormProps<UserFormData>

export const UserForm: FC<UserFormProps> = ({
    ...props
}) => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')

    return <DialogForm
        isValid={Boolean(name && email)}
        formData={{
            name,
            email
        }}
        {...props}
    >
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
                <TextField
                    value={name}
                    onChange={e => setName(e.target.value)}
                    label="Name"
                    required
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    label="Email"
                    required
                    fullWidth
                />
            </Grid>
        </Grid>
    </DialogForm>
}
