import { RelatedContent, Scope } from "@marketpartner/backend-api";
import { LoadedSelector } from "@marketpartner/mp-common-mui";
import { DialogTitle } from "@mui/material";
import { FC, useState } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { DialogForm } from "src/common/dialogs/DialogForm";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { useEvent } from "src/events/event-context";


export type ChangeRelatedContentCategoryDialogProps = LockableDialogProps & ChangeRelatedContentCategoryDialogContentProps

const ChangeRelatedContentCategoryDialog: FC<ChangeRelatedContentCategoryDialogProps> = ({
    relatedContent,
    ...props
}) => {
    return <LockableDialog
        maxWidth="xs"
        fullWidth
        {...props}
    >
        <DialogTitle>Change link category</DialogTitle>
        <ChangeRelatedContentCategoryDialogContent
            relatedContent={relatedContent}
        />
    </LockableDialog>
}

export type ChangeRelatedContentCategoryDialogContentProps = {
    relatedContent: RelatedContent
}

const ChangeRelatedContentCategoryDialogContent: FC<ChangeRelatedContentCategoryDialogContentProps> = ({
    relatedContent
}) => {
    const client = useClient()!
    const event = useEvent()!

    const [categoryId, setCategoryId] = useState(relatedContent.categoryId)

    const categoriesQuery = backend.relatedContentCategories.useGetAll([client.id, event.id])
    const updateRelationshipMutation = backend.contentRelationships.useUpdateRelationship(withNotification({
        errorMessage: "Error changing category"
    }))

    return <DialogForm
        formData={{}}
        actionName="Move"
        onSubmit={() => updateRelationshipMutation.mutateAsync([client.id, event.id, relatedContent.id, {
            [relatedContent.side]: {
                categoryId,
            }
        }])}
        isValid={categoryId !== relatedContent.categoryId}
    >
        <LoadedSelector
            fetchRequest={categoriesQuery}
            label="Category"
            selectedId={categoryId}
            onSelectId={setCategoryId}
            fullWidth
        />
    </DialogForm>
}

export const useChangeRelatedContentCategoryDialog = createPopupHook({
    element: ChangeRelatedContentCategoryDialog,
    scope: Scope.Event,
})