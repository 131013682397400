import { Registration, RegistrationApprovalState, RegistrationType } from "@marketpartner/backend-api"
import { filterType } from "@marketpartner/mp-common"
import { Collapse, DialogTitle, Stack, Typography } from "@mui/material"
import { countBy } from "lodash"
import { FC, ReactNode } from "react"
import { ConfirmDialogForm } from "src/common/dialogs/ConfirmDialogForm"
import { Bar } from "src/common/layout/Bar"
import { ProgressWithLabel } from "src/common/loading/ProgressWithLabel"
import { EmailResendWarning } from "src/registrations/bulk/EmailResendWarning"
import { BulkRegistrationState, useBulkApproval } from "src/registrations/bulk/useBulkApproval"
import { ApprovedIcon, CancelledIcon, PendingApprovalIcon, RejectedIcon } from "src/registrations/RegistrationStatusIcon"

export type BulkApprovalDialogProps = {
    registrations: Registration[]
    newStatus: BulkRegistrationState
}

const actionNames: Record<BulkRegistrationState, string> = {
    Approved: "approval",
    Rejected: "rejection",
    Cancelled: "cancellation",
    Delete: "deletion",
}

export const BulkApprovalDialog: FC<BulkApprovalDialogProps> = ({
    registrations,
    newStatus,
}) => {
    const toUpdate = filterType(registrations, RegistrationType.Primary as const)
        .filter(it => it.approvalState !== newStatus)
    // FIXME: if you bulk approve twice without deselecting and reselecting the registrations, 
    // the second attempt is still using the old statuses so the filter result is wrong
    const bulkApproval = useBulkApproval({
        newStatus,
    })

    const statusCounts = countBy(registrations, "approvalState")

    return <>
        <DialogTitle>
            Confirm {actionNames[newStatus]}
        </DialogTitle>
        <ConfirmDialogForm
            action={() => bulkApproval.mutateAsync(
                toUpdate.map(it => it.id),
            )}
            submitColor="error"
            submitText={`Confirm ${actionNames[newStatus]}`}
            text={<Stack spacing={2}>
                <Typography>
                    Current statuses:
                </Typography>
                <Stack spacing={0.5}>
                    <StatusCount
                        icon={<PendingApprovalIcon />}
                        text="Pending"
                        count={statusCounts[RegistrationApprovalState.PendingApproval]}
                    />
                    <StatusCount
                        icon={<ApprovedIcon />}
                        text="Approved"
                        count={statusCounts[RegistrationApprovalState.Approved]}
                    />
                    <StatusCount
                        icon={<RejectedIcon />}
                        text="Rejected"
                        count={statusCounts[RegistrationApprovalState.Rejected]}
                    />
                    <StatusCount
                        icon={<CancelledIcon />}
                        text="Cancelled"
                        count={statusCounts[RegistrationApprovalState.Cancelled]}
                    />
                </Stack>
                { newStatus !== "Delete" && <EmailResendWarning newStatus={newStatus} /> }
                <Typography variant="body2">
                    Note: This may take a while for large numbers of registrations
                </Typography>
                <Collapse in={bulkApproval.isPending}>
                    <ProgressWithLabel percentage={bulkApproval.progress * 100} />
                </Collapse>
            </Stack>}
        />
    </>
}

type StatusCountProps = {
    icon: ReactNode
    text: string
    count: number
}

const StatusCount: FC<StatusCountProps> = ({
    icon,
    text,
    count,
}) => {
    if (!count) {
        return <></>
    }

    return <Bar spacing={0.5} p={2}>
        {icon}
        <Typography variant="body1" sx={{ width: 80, }}>
            {text}
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {count}
        </Typography>
    </Bar>
}
