import { Scope } from "@marketpartner/backend-api";
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { ContentCategoryForm } from "src/content/categories/ContentCategoryForm";
import { useEvent } from "src/events/event-context";


export type CreateContentCategoryDialogProps = LockableDialogProps

const CreateContentCategoryDialog: FC<CreateContentCategoryDialogProps> = (props) => {
    const client = useClient()!
    const event = useEvent()!
    const createMutation = backend.contentCategories.useCreateCategory(withNotification({
        errorMessage: "Error creating content category",
    }))

    return <LockableDialog
        maxWidth="sm"
        {...props}
    >
        <DialogTitle>Create content category</DialogTitle>
        <ContentCategoryForm
            actionName="Create"
            onSubmit={category => createMutation.mutateAsync([client.id, event.id, category])}
        />
    </LockableDialog>
}

export const useCreateContentCategoryDialog = createPopupHook({
    element: CreateContentCategoryDialog,
    scope: Scope.Event,
})
