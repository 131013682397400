import { useState } from "react"
import { ColumnMatch } from "src/registrations/import/pre-process/auto-match-columns"
import { ValidMergedRegistration } from "src/registrations/import/pre-process/validate-core-properties"
import { PreparedRegistration, prepareForImport } from "src/registrations/import/preview/prepare-for-import"

export type UsePreparedImport = {
    prepare: (registrations: ValidMergedRegistration[], columnMapping: Record<string, ColumnMatch>) => void
    reset: () => void
    isPrepared: boolean
    columnMapping: Record<string, ColumnMatch>
    preparedRegistrations: PreparedRegistration[]
}

export const usePreparedImport = (): UsePreparedImport => {
    const [columnMapping, setColumnMapping] = useState<Record<string, ColumnMatch>>()
    const [preparedRegistrations, setPreparedRegistrations] = useState<PreparedRegistration[]>()

    const prepare: UsePreparedImport["prepare"] = (registrations, columnMapping) => {
        setColumnMapping(columnMapping)
        setPreparedRegistrations(prepareForImport(registrations, columnMapping))
    }

    const reset = () => {
        setColumnMapping(undefined)
        setPreparedRegistrations(undefined)
    }

    return {
        isPrepared: preparedRegistrations !== undefined,
        preparedRegistrations: preparedRegistrations ?? [],
        columnMapping: columnMapping ?? {},
        prepare,
        reset,
    }
}