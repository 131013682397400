import { Permission } from '@marketpartner/backend-api';
import AddIcon from '@mui/icons-material/Add';
import { DialogTitle } from "@mui/material";
import { FC } from 'react';
import { TokenForm } from "src/access/tokens/admin-tokens/TokenForm";
import { backend } from 'src/common/api';
import { DialogButton, DialogButtonProps } from 'src/common/dialogs/DialogButton';
import { withNotification } from 'src/common/notifications/with-notification';
import { ifHas } from 'src/login/IfHas';

export type CreateTokenButtonProps = Partial<DialogButtonProps>

export const CreateTokenButton: FC<CreateTokenButtonProps> = ({
    ...props
}) => {
    const createMutation = backend.accessTokens.useCreateAdminAccessToken(withNotification({
        successMessage: "Token created",
        errorMessage: "Error creating token",
    }))

    return ifHas(Permission.AdministerSystem, undefined, undefined,
        <DialogButton
            icon={<AddIcon />}
            text="Create token"
            color="primary"
            variant="contained"
            {...props}
        >
            <DialogTitle>Create access token</DialogTitle>
            <TokenForm
                actionName='Create'
                onSubmit={tokenDetails => createMutation.mutateAsync([tokenDetails])}
            />
        </DialogButton>
    )
}