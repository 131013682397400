import { PhoneNumberConfig, countriesByCode } from "@marketpartner/backend-api";
import { CountrySelect } from "@marketpartner/mp-common-mui";
import { Grid, TextField } from "@mui/material";
import { StandardFields } from "src/forms/elements/types/StandardFields";
import { FormElementConfigComponent } from "src/forms/elements/types/formElementConfigTypes";

export const PhoneNumberConfigComponent: FormElementConfigComponent<PhoneNumberConfig> = ({
    config,
    updateConfigProperty,
}) => {
    return <>
        <StandardFields config={config} updateConfigProperty={updateConfigProperty} />
        <Grid item xs={12}>
            <TextField
                label="Placeholder"
                fullWidth
                value={config.placeholder}
                onChange={(e) => updateConfigProperty('placeholder', e.target.value)}
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <CountrySelect
                label="Preferred countries"
                value={config.preferredCountries.map(code => countriesByCode[code])}
                onChange={(_e, countries) => updateConfigProperty("preferredCountries", countries.map(it => it.code))}
                disableClearable={false}
                multiple={true}
                fullWidth
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <CountrySelect
                label="Default country"
                value={config.defaultCountryCode && countriesByCode[config.defaultCountryCode]}
                onChange={(_e, country) => updateConfigProperty("defaultCountryCode", country?.code ?? undefined)}
                disableClearable={false}
                multiple={false}
                fullWidth
            />
        </Grid>
    </>
}