import { RegistrationFieldBooleanCondition } from "@marketpartner/backend-api"
import { Collapse, Grid } from "@mui/material"
import { FC } from "react"
import { RegistrationFieldSelector } from "src/registrations/configuration/categories/reg-form/RegistrationFieldSelector"
import { RegistrationFieldValueSelector } from "src/registrations/configuration/categories/reg-form/RegistrationFieldValueSelector"


export type RegistrationFieldConditionFormProps = {
    condition: RegistrationFieldBooleanCondition
    onChangeProperties: (changes: Partial<RegistrationFieldBooleanCondition>) => void
}

export const RegistrationFieldConditionForm: FC<RegistrationFieldConditionFormProps> = ({
    condition,
    onChangeProperties,
}) => {
    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <RegistrationFieldSelector
                selectedFieldId={condition.fieldId}
                onSelectFieldId={fieldId => fieldId && onChangeProperties({ fieldId })}
                fullWidth
            />
        </Grid>
        <Grid item xs={12}>
            <Collapse in={Boolean(condition.fieldId)}>
                <RegistrationFieldValueSelector
                    fieldId={condition.fieldId}
                    value={condition.values}
                    onChange={(_, values) => onChangeProperties({ values })}
                    multiple
                    label="Values"
                />
            </Collapse>
        </Grid>
    </Grid>
}