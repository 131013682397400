import { Registration, RegistrationApprovalState } from "@marketpartner/backend-api"
import { fullName } from "@marketpartner/mp-common"
import { DialogTitle } from "@mui/material"
import { createContext, FC, ReactNode, useCallback, useContext, useState } from "react"
import { ConfirmDialogForm } from "src/common/dialogs/ConfirmDialogForm"
import { LockableDialog } from "src/common/dialogs/LockableDialog"
import { EmailResendWarning, WarningText } from "src/registrations/bulk/EmailResendWarning"

export type UpdateApprovalDialogContext = {
    showConfirmDialog: (
        registration: Registration, 
        action: () => Promise<unknown>,
        newApprovalState: RegistrationApprovalState
    ) => void
}

const context = createContext<UpdateApprovalDialogContext>(undefined!)

export const useAlterApprovalDialog = () => useContext(context)

export type AlterApprovalDialogProps = {
    children: ReactNode
}

type AlterApprovalParameters = {
    registration: Registration
    action: () => Promise<unknown>
    newApprovalState: RegistrationApprovalState
}

export const AlterApprovalDialog: FC<AlterApprovalDialogProps> = ({
    children,
}) => {
    const [parameters, setParameters] = useState<AlterApprovalParameters | undefined>()

    const showConfirmDialog = useCallback((
        registration: Registration,
        action: () => Promise<unknown>,
        newApprovalState: RegistrationApprovalState
    ) => {
        setParameters({ registration, action, newApprovalState })
    }, [])

    return <>
        <context.Provider children={children} value={{
            showConfirmDialog,
        }} />

        <LockableDialog open={Boolean(parameters)} onClose={() => setParameters(undefined)}>
            <DialogTitle>Confirm change</DialogTitle>
            {parameters && <DialogContent {...parameters}/>}
        </LockableDialog>
    </>
}

const DialogContent: FC<AlterApprovalParameters> = ({
    registration,
    action,
    newApprovalState
}) => {
    const text = useGetConfirmationText(fullName(registration), newApprovalState)
    return <ConfirmDialogForm
        text={text}
        action={action}
    />
}

function useGetConfirmationText(
    name: string,
    newApprovalState: RegistrationApprovalState
) {
    
    if (newApprovalState === RegistrationApprovalState.PendingApproval) {
        return <></>
    }

    const accessWarning = getAccessWarning(name, newApprovalState)

    return <>
        <EmailResendWarning
            newStatus={newApprovalState}
            userName={name}
        />
        { accessWarning}
    </>
}

function getAccessWarning(name: string, newApprovalState: RegistrationApprovalState) {
    if (newApprovalState === RegistrationApprovalState.Rejected) {
        return <WarningText>Rejecting {name} will revoke their access to the event and any apps set up for the event.</WarningText>
    }
    if (newApprovalState === RegistrationApprovalState.Cancelled) {
        return <WarningText>Cancelling {name} will revoke their access to the event and any apps set up for the event.</WarningText>
    }
    return null
}