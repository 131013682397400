import { RelatedContent, Scope } from "@marketpartner/backend-api";
import { Clear, MoveDown } from "@mui/icons-material";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { createPopupHook } from "src/common/dialogs/Popup";
import { LoadingMenuItem } from "src/common/menus/LoadingMenuItem";
import { LockableMenu, LockableMenuProps } from "src/common/menus/LockableMenu";
import { withNotification } from "src/common/notifications/with-notification";
import { useChangeRelatedContentCategoryDialog } from "src/content/relationships/ChangeRelatedContentCategoryDialog";
import { useEvent } from "src/events/event-context";


export type RelatedContentContextMenuProps = LockableMenuProps & {
    relatedContent: RelatedContent
}

const RelatedContentContextMenu: FC<RelatedContentContextMenuProps> = ({
    relatedContent,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!

    const unlinkMutation = backend.contentRelationships.useDeleteRelationship(withNotification({
        errorMessage: "Error unlinking content",
    }))

    const changeCategoryDialog = useChangeRelatedContentCategoryDialog()

    return <LockableMenu
        closeOnClick
        {...props}
    >
        <LoadingMenuItem onClick={() => unlinkMutation.mutateAsync([client.id, event.id, relatedContent.id])}>
            <ListItemIcon><Clear color="error" /></ListItemIcon>
            <ListItemText>Unlink</ListItemText>
        </LoadingMenuItem>
        <MenuItem onClick={() => changeCategoryDialog.open({ relatedContent })}>
            <ListItemIcon><MoveDown /></ListItemIcon>
            <ListItemText>Change category</ListItemText>
        </MenuItem>
    </LockableMenu>
}

export const useRelatedContentContextMenu = createPopupHook({
    element: RelatedContentContextMenu,
    scope: Scope.Event,
})