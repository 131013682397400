import { Registration } from "@marketpartner/backend-api"
import Cancel from "@mui/icons-material/Cancel"
import { IconButton, Typography } from "@mui/material"
import { FC } from "react"
import { Bar } from "src/common/layout/Bar"


export type SelectedRegistrationsTextProps = {
    selectedRegistrations: Registration[]
    clearSelection: () => void
}

export const SelectedRegistrationsText: FC<SelectedRegistrationsTextProps> = ({
    selectedRegistrations,
    clearSelection,
}) => {
    return <Bar spacing={0.5}>
        <Typography>
            {selectedRegistrations.length} selected
        </Typography>
        <IconButton size="small" onClick={clearSelection}>
            <Cancel />
        </IconButton>
    </Bar>
}