import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { DeleteButton } from "src/common/dialogs/DeleteButton"
import { useEvent } from "src/events/event-context"


export const DeleteScanningTokenButton = () => {
    const client = useClient()!
    const event = useEvent()!
    const deleteMutation = backend.scanning.useDeleteToken()

    return <DeleteButton
        buttonText="Delete URL"
        itemName="scanner access token & URL"
        text="If re-enabled, a new token and URL will be generated."
        deleteAction={() => deleteMutation.mutateAsync([client.id, event.id])}
    />
}