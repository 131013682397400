import { reorderBody, Scope } from "@marketpartner/backend-api";
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { EditableDialogList } from "src/common/dialogs/EditableDialogList";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { useCreateRelatedContentCategoryDialog } from "src/content/related-content-categories/CreateRelatedContentCategoryDialog";
import { useDeleteRelatedContentCategoryDialog } from "src/content/related-content-categories/DeleteRelatedContentCategoryDialog";
import { useEditRelatedContentCategoryDialog } from "src/content/related-content-categories/EditRelatedContentCategoryDialog";
import { useEvent } from "src/events/event-context";


export type RelatedContentCategoriesDialogProps = LockableDialogProps

const RelatedContentCategoriesDialog: FC<RelatedContentCategoriesDialogProps> = ({
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!

    const categoriesQuery = backend.relatedContentCategories.useGetAll([client.id, event.id])
    const reorderMutation = backend.relatedContentCategories.useReorderCategories(withNotification({
        errorMessage: "Error reordering categories",
    }))

    const createCategoryDialog = useCreateRelatedContentCategoryDialog()
    const editCategoryDialog = useEditRelatedContentCategoryDialog()
    const deleteCategoryDialog = useDeleteRelatedContentCategoryDialog()

    return <LockableDialog
        maxWidth="sm"
        fullWidth
        {...props}
    >
        <DialogTitle>Linked content categories</DialogTitle>
        <EditableDialogList
            itemsQuery={categoriesQuery}
            getPrimaryText={category => category.name}
            getSecondaryText={category => category.id}
            addProps={{
                onClickAdd: () => createCategoryDialog.open({}),
            }}
            contextMenuProps={category => ({
                onClickEdit: () => editCategoryDialog.open({ category }),
                onClickDelete: () => deleteCategoryDialog.open({ category }),
            })}
            reorderProps={{
                onReorder: categories => reorderMutation.mutateAsync([client.id, event.id, reorderBody(categories)]),
            }}
        />
    </LockableDialog>
}

export const useRelatedContentCategoriesDialog = createPopupHook({
    element: RelatedContentCategoriesDialog,
    scope: Scope.Event,
})