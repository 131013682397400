import { DataGridProProps, GridFilterModel, GridValidRowModel } from "@mui/x-data-grid-pro"
import { useMemo } from "react"
import { idListFilterItem, idListFilterOperator } from "src/common/grid/id-filter"

export type GridRowFilterProps<Row extends GridValidRowModel> =
    Partial<DataGridProProps<Row>> &
    Pick<DataGridProProps<Row>, "columns" | "rows">

export type UseGridRowFilterOptions<Row extends GridValidRowModel> = {
    filterRows?: (rows: Row[]) => Row[]
} & GridRowFilterProps<Row>

/**
 * This hook takes a callback that filters rows, and converts
 * this to a native grid filter.
 * 
 * If a filter function is provided, the filter will be applied
 * statically to the first column of the grid, and user filters
 * will be disabled.
 */
export const useCustomGridRowFilter = <Row extends GridValidRowModel>({
    filterRows,
    ...gridProps
}: UseGridRowFilterOptions<Row>): GridRowFilterProps<Row> => {
    const firstColumnField = gridProps.columns[0].field
    const getRowId = gridProps.getRowId ?? defaultGetRowId
    const hasFilter = Boolean(filterRows)

    const columns = useMemo(() => {
        if (!hasFilter) {
            return gridProps.columns
        }
        return gridProps.columns.map((column, idx) => idx === 0 ? {
            ...column,
            filterOperators: [
                ...column.filterOperators ?? [],
                idListFilterOperator,
            ]
        } : column)
    }, [hasFilter, gridProps.columns])

    const filterModel: GridFilterModel | undefined = useMemo(() => {
        if (!filterRows) {
            return gridProps.filterModel
        }
        const filteredRowIds = filterRows(gridProps.rows as Row[]).map(getRowId)
        return {
            items: [
                idListFilterItem(firstColumnField, filteredRowIds),
                ...(gridProps.filterModel?.items ?? [])
            ]
        }
    }, [filterRows, gridProps.rows, gridProps.filterModel, getRowId, firstColumnField])

    return {
        ...gridProps,
        columns,
        filterModel,
        disableColumnFilter: filterRows !== undefined || gridProps.disableColumnFilter,
    }
}

const defaultGetRowId = <Row extends GridValidRowModel>(row: Row) => row.id