import { FC } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { useClientId } from 'src/clients/client-context';
import { getDefaultPageForLogin } from 'src/common/routing/default-page';
import { routes } from 'src/common/routing/routes';
import { useEventId } from 'src/events/event-context';
import { useLoginContext } from 'src/login/LoginContext';

export const RouteMainPage: FC = () => {
    const login = useLoginContext()
    const clientId = useClientId()
    const eventId = useEventId()
    const defaultPage = getDefaultPageForLogin(login)

    const permittedRoutes = Object.values(routes)
        .filter(route => !route.restrictedTo || route.restrictedTo(login, clientId, eventId))

    const element = useRoutes(permittedRoutes)

    return element ?? <Navigate replace to={defaultPage} />
}
