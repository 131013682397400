import { Permission, RegistrationCategoryType, reorderBody } from "@marketpartner/backend-api"
import { filterType } from "@marketpartner/mp-common"
import Box from "@mui/material/Box/Box"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { BarAndFlex } from "src/common/layout/BarAndFlex"
import { Bar } from "src/common/layout/Bar"
import { Spacer } from "src/common/layout/Spacer"
import { Loading } from "src/common/loading/Loading"
import { ReorderableStack } from "src/common/reordering/ReorderableStack"
import { ReorderButtons } from "src/common/reordering/ReorderButtons"
import { useBulkReorder } from "src/common/reordering/useBulkReorder"
import { fullSize } from "src/common/styles"
import { EditableSpeakerCategory } from "src/content/speakers/EditableSpeakerCategory"
import { useEvent } from "src/events/event-context"
import { ifHas } from "src/login/IfHas"

export const EditableSpeakers: FC = () => {
    const client = useClient()!
    const event = useEvent()!
    const categoriesQuery = backend.registrationCategories.useGetAll([client.id, event.id], {
        select: categories => filterType(categories, RegistrationCategoryType.Speaker)
    })
    const reorderMutation = backend.registrationCategories.useReorder()

    const reorder = useBulkReorder({
        items: categoriesQuery.data ?? [],
        onReorder: items => reorderMutation.mutateAsync([client.id, event.id, reorderBody(items)]),
    })

    return <BarAndFlex barContent={<Bar
        spacing={1}
        p={1}
    >
        <Spacer />
        {ifHas(Permission.EditEventContent, client.id, event.id,
            <ReorderButtons reorder={reorder} reorderButtonProps={{ children: "Reorder categories" }} />
        )}
    </Bar>}>
        <Box sx={{ ...fullSize, overflowY: "auto" }}>
            <Loading
                request={categoriesQuery}
                sizing="fit-content"
                render={() => <ReorderableStack
                    items={reorder.orderedItems}
                    onReorder={reorder.reorderItems}
                    reorderEnabled={reorder.isReordering}
                    loading={reorder.isLoading}
                    renderItem={category => <EditableSpeakerCategory
                        key={category.id}
                        category={category}
                        collapse={reorder.isReordering}
                    />}
                />}
            />
        </Box>
    </BarAndFlex>
}