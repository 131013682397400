import { Timezone } from "@marketpartner/backend-api"
import { Stack } from "@mui/material"
import Typography from "@mui/material/Typography"
import { DateTime } from "luxon"
import { FC } from "react"
import { Bar } from "src/common/layout/Bar"
import { DateTimeText } from "src/common/time/DateTimeText"
import { localTimezone, utcTimezone } from "src/common/time/time-utils"
import { useEvent } from "src/events/event-context"

type LocalizedDayTimeRangeTextProps = Omit<ZonedDayTimeRangeTextProps, "timezone">

export type EventDayTimeRangeTextProps = LocalizedDayTimeRangeTextProps
export const EventDayTimeRangeText: FC<EventDayTimeRangeTextProps> = (props) => {
    const event = useEvent()!
    return <ZonedDayTimeRangeText {...props} timezone={event.timezone} />
}

export type LocalDayTimeRangeTextProps = LocalizedDayTimeRangeTextProps
export const LocalDayTimeRangeText: FC<LocalDayTimeRangeTextProps> = (props) => {
    return <ZonedDayTimeRangeText {...props} timezone={localTimezone} />
}

export type UtcDayTimeRangeTextProps = LocalizedDayTimeRangeTextProps
export const UtcDayTimeRangeText: FC<UtcDayTimeRangeTextProps> = (props) => {
    return <ZonedDayTimeRangeText {...props} timezone={utcTimezone} />
}

export type ZonedDayTimeRangeTextProps = {
    start: DateTime
    end: DateTime
    timezone: Timezone
    hideTimezone?: boolean
}

export const ZonedDayTimeRangeText = ({
    start,
    end,
    timezone,
    hideTimezone = false
}: ZonedDayTimeRangeTextProps) => {
    return <Stack>
        <Typography>
            {start.toLocaleString(DateTime.DATE_MED)}
        </Typography>
        <Bar spacing={0.5}>
            <DateTimeText dateTime={start} timezone={timezone} hideDate hideTimezone />
            <Typography>-</Typography>
            <DateTimeText dateTime={end} timezone={timezone} hideDate hideTimezone />
        </Bar>
        {!hideTimezone && <Typography variant='caption' color="textSecondary" >
            {timezone}
        </Typography>}
    </Stack>
}