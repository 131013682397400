import { Scope } from "@marketpartner/backend-api";
import { DialogTitle } from "@mui/material";
import { FC, useState } from "react";
import { BooleanConditionProperties } from "src/common/boolean-conditions/boolean-condition-properties";
import { DialogForm } from "src/common/dialogs/DialogForm";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";


export type EditConditionDialogProps<Condition> = Omit<LockableDialogProps, "onChange"> & EditConditionFormProps<Condition>

const EditConditionDialog: FC<EditConditionDialogProps<any>> = ({
    condition,
    properties,
    onChange,
    ...props
}) => {
    return <LockableDialog
        maxWidth="sm"
        fullWidth
        {...props}
    >
        <DialogTitle>{properties.typeName}</DialogTitle>
        <EditConditionForm
            condition={condition}
            properties={properties}
            onChange={onChange}
        />
    </LockableDialog>
}

type EditConditionFormProps<Condition> = {
    condition: Condition
    properties: BooleanConditionProperties<Condition>
    onChange: (condition: Condition) => void
}

const EditConditionForm: FC<EditConditionFormProps<any>> = ({
    condition,
    properties,
    onChange,
}) => {
    const [conditionState, setConditionState] = useState(condition)

    return <DialogForm
        formData={{}}
        isValid={properties.isValid(conditionState)}
        actionName="Save"
        onSubmit={async () => onChange(conditionState)}
    >
        {properties.renderForm(conditionState, changedProperties => setConditionState({
            ...conditionState,
            ...changedProperties,
        }))}
    </DialogForm>
}

export const useEditConditionDialog = createPopupHook({
    element: EditConditionDialog,
    scope: Scope.Event,
})