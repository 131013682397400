import { AdjacentItemDetails, AgendaHeading } from "@marketpartner/backend-api"
import { DeleteForever, Edit } from "@mui/icons-material"
import { Divider, ListItemIcon, ListItemText, Menu, MenuItem, MenuProps } from "@mui/material"
import { FC } from "react"
import { MoveAgendaItemButtons } from "src/content/agendas/MoveAgendaItemButtons"
import { useDeleteAgendaHeadingDialog } from "src/content/agendas/headings/DeleteAgendaHeadingDialog"
import { useEditAgendaHeadingDialog } from "src/content/agendas/headings/EditAgendaHeadingDialog"

export type AgendaHeadingContextMenuProps = MenuProps & {
    heading: AgendaHeading & AdjacentItemDetails,
    onClose: () => void
}

export const AgendaHeadingContextMenu: FC<AgendaHeadingContextMenuProps> = ({
    heading,
    ...props
}) => {
    const editHeadingDialog = useEditAgendaHeadingDialog()
    const deleteHeadingDialog = useDeleteAgendaHeadingDialog()

    return <Menu {...props} onClick={props.onClose}>
        <MenuItem disabled>
            <ListItemText sx={{ textAlign: "center" }}>Heading</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => editHeadingDialog.open({ heading })}>
            <ListItemIcon><Edit color="primary" /></ListItemIcon>
            <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => deleteHeadingDialog.open({ heading })}>
            <ListItemIcon><DeleteForever color="error" /></ListItemIcon>
            <ListItemText>Delete</ListItemText>
        </MenuItem>
        <Divider />
        <MoveAgendaItemButtons item={heading} onMove={props.onClose} />
    </Menu>
}