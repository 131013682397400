import { EventDetails, Registration, RegistrationCategory, Scope } from "@marketpartner/backend-api";
import { Warning } from "@mui/icons-material";
import { Button, DialogTitle } from "@mui/material";
import { FC, useState } from "react";
import { DialogStepper } from "src/common/dialogs/DialogStepper";
import { LockableDialog, LockableDialogProps, useDialogContext } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { ProgressWithLabel } from "src/common/loading/ProgressWithLabel";
import { CopySpeakersSelector } from "src/content/speakers/copy/CopySpeakersSelector";
import { CopySpeakersSourceCategorySelector } from "src/content/speakers/copy/CopySpeakersSourceCategorySelector";
import { CopyRegistrationsResults } from "src/registrations/actions/copy/CopyRegistrationResults";
import { useRegistrationCategoryCompatibility } from "src/registrations/actions/copy/registration-category-compatibility";
import { useCopyRegistrations } from "src/registrations/actions/copy/useCopyRegistrations";


export type CopySpeakersDialogProps = LockableDialogProps & CopySpeakersDialogContentProps

const CopySpeakersDialog: FC<CopySpeakersDialogProps> = ({
    targetCategory,
    ...props
}) => {
    return <LockableDialog
        maxWidth="sm"
        fullWidth
        allowFullScreen
        disableClickaway
        {...props}
    >
        <DialogTitle>Copy {targetCategory.pluralName}</DialogTitle>
        <CopySpeakersDialogContent targetCategory={targetCategory} />
    </LockableDialog>
}

export type CopySpeakersDialogContentProps = {
    targetCategory: RegistrationCategory
}

const CopySpeakersDialogContent: FC<CopySpeakersDialogContentProps> = ({
    targetCategory,
}) => {
    const [sourceEvent, setSourceEvent] = useState<EventDetails | null>(null)
    const [sourceCategory, setSourceCategory] = useState<RegistrationCategory | null>(null)
    const [selectedRegistrations, setSelectedRegistrations] = useState<Registration[]>([])
    const compatibility = useRegistrationCategoryCompatibility(sourceEvent, sourceCategory, targetCategory, false)

    const [stepIdx, setStepIdx] = useState(0)
    const next = () => setStepIdx(idx => idx + 1)

    const dialog = useDialogContext()
    const copyRegistrations = useCopyRegistrations({
        sourceEventId: sourceEvent?.id!,
        targetCategoryId: targetCategory?.id!,
        copyGuests: false,
        onMutate: () => {
            next()
            dialog.disableClose()
        },
        onSuccess: results => {
            if (!results?.errors?.length) {
                dialog.close(true)
            }
        },
        onSettled: () => {
            next()
            dialog.enableClose()
        },
    })

    return <DialogStepper
        stepIdx={stepIdx}
        steps={[
            {
                label: "Select source",
                content: <CopySpeakersSourceCategorySelector
                    onChangeSourceEvent={setSourceEvent}
                    onChangeSourceCategory={setSourceCategory}
                    compatibility={compatibility}
                />,
                nextAction: <Button
                    children="Next"
                    variant="contained"
                    onClick={next}
                    startIcon={targetCategory && compatibility.level === "warning" && <Warning />}
                    color={targetCategory && compatibility.level === "warning" ? "warning" : "primary"}
                    disabled={!sourceEvent || !sourceCategory || !targetCategory || compatibility.level === "error"}
                />,
            },
            {
                label: "Select speakers",
                content: <CopySpeakersSelector
                    sourceEventId={sourceEvent?.id!}
                    sourceCategoryId={sourceCategory?.id!}
                    selectedRegistrations={selectedRegistrations}
                    onChangeSelectedRegistrations={setSelectedRegistrations}
                    sx={{
                        minHeight: 300,
                    }}
                />,
                contentProps: {
                    sx: {
                        display: "flex",
                        flexDirection: "column",
                    }
                },
                nextAction: <Button
                    children={`Copy ${selectedRegistrations.length} ${sourceCategory?.pluralName}`}
                    variant="contained"
                    disabled={selectedRegistrations.length === 0}
                    onClick={() => copyRegistrations.mutate(selectedRegistrations.map(r => r.id))}
                />,
            },
            {
                label: "Copy",
                content: <ProgressWithLabel
                    percentage={copyRegistrations.progress * 100}
                />,
            },
            {
                label: "Results",
                content: <CopyRegistrationsResults
                    registrations={selectedRegistrations}
                    results={copyRegistrations.data?.results?.flat() ?? []}
                    sx={{ height: "initial" }}
                />,
                contentProps: {
                    sx: {
                        overflow: "hidden",
                        display: "flex",
                    }
                },
            }
        ]}
    />
}

export const useCopySpeakersDialog = createPopupHook({
    element: CopySpeakersDialog,
    scope: Scope.Event,
})