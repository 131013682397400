import _ from "lodash"
import { DateTime } from "luxon"

export type GroupedByDay<T> = {
    localDay: DateTime
    items: T[]
}

export const groupByDay = <T>(
    items: T[],
    getTime: (item: T) => DateTime,
    timezone: string
): GroupedByDay<T>[] => {
    return _(items)
        .groupBy(item => extractLocalDayMsString(getTime(item), timezone))
        .map((items, localDayMsString) => ({
            localDay: DateTime.fromMillis(parseInt(localDayMsString), { zone: timezone }),
            items,
        }))
        .sortBy(groupedItems => groupedItems.localDay)
        .value()
}

const extractLocalDayMsString = (time: DateTime, timezone: string): number => {
    return time
        .setZone(timezone)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toMillis()
}