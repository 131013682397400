import { ConfiguredEmailTemplate, LocalDateTime, ScheduledEmail, ScheduledEmailBooleanCondition, ScheduledEmailFilter } from "@marketpartner/backend-api";
import { Box, Checkbox, Collapse, FormControlLabel, Grid, Stack, TextField } from "@mui/material";
import { DateTime } from "luxon";
import { FC, useState } from "react";
import { isValidExpressionRecursive } from "src/common/boolean-conditions/boolean-condition-validity";
import { EditableBooleanExpressionGroup } from "src/common/boolean-conditions/EditableBooleanExpressionGroup";
import { DialogForm, ExtendableDialogFormProps } from "src/common/dialogs/DialogForm";
import { LuxonDateTimePicker } from "src/common/time/LuxonDateTimePickers";
import { registrationCategoryConditionProperties } from "src/email/scheduling/registration-category-condition-properties";
import { registrationFieldConditionProperties } from "src/email/scheduling/registration-field-condition-properties";
import { registrationStateConditionProperties } from "src/email/scheduling/registration-state-condition-properties";
import { EditableConfiguredEmailTemplate } from "src/email/templates/EditableConfiguredEmailTemplate";
import { useEvent } from "src/events/event-context";


export type ScheduledEmailFormData = {
    template: ConfiguredEmailTemplate
    name: string
    localScheduledTime: LocalDateTime
    filter: ScheduledEmailFilter | null
}

export type ScheduledEmailFormProps = ExtendableDialogFormProps<ScheduledEmailFormData> & {
    scheduledEmail?: ScheduledEmail
}

export const ScheduledEmailForm: FC<ScheduledEmailFormProps> = ({
    scheduledEmail,
    ...props
}) => {
    const event = useEvent()!
    const [template, setTemplate] = useState(scheduledEmail?.template ?? null)
    const [name, setName] = useState(scheduledEmail?.name ?? "")
    const [scheduledTime, setScheduledTime] = useState(scheduledEmail?.scheduledTime ?? DateTime.now().setZone(event.timezone))
    const [filter, setFilter] = useState<ScheduledEmailFilter>(scheduledEmail?.filter ?? {
        type: "Group",
        operator: "AND",
        expressions: [],
        name: "",
    })
    // Use an allRegistrations flag instead of just relying on filter being null
    // so that the filter configuration isn't lost if the checkbox is checked then unchecked
    const [allRegistrations, setAllRegistrations] = useState(scheduledEmail?.filter === null ?? false)

    return <DialogForm
        formData={{
            template: template!,
            name,
            localScheduledTime: LocalDateTime.fromZonedDateTime(scheduledTime),
            filter: allRegistrations ? null : filter,
        }}
        isValid={
            Boolean(template) &&
            (allRegistrations || (filter !== null && isValidExpressionRecursive(filter)))
        }
        {...props}
    >
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <EditableConfiguredEmailTemplate
                    configuredTemplate={template}
                    onChange={setTemplate}
                    label="Template"
                    editLabel="scheduled email template"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <LuxonDateTimePicker
                    label="Scheduled time"
                    timezone={event.timezone}
                    value={scheduledTime}
                    onChange={time => time && setScheduledTime(time)}
                    fullWidth
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <Stack>
                    <FormControlLabel
                        label="All registrations"
                        control={<Checkbox
                            checked={allRegistrations}
                            onChange={() => setAllRegistrations(!allRegistrations)}
                        />}
                    />
                    <Collapse in={!allRegistrations}>
                        <Box pt={2}>
                            {filter && <EditableBooleanExpressionGroup<ScheduledEmailBooleanCondition>
                                group={filter}
                                onChangeGroup={setFilter}
                                onDelete={() => { }}
                                conditionPropertiesLookup={{
                                    RegistrationCategory: registrationCategoryConditionProperties,
                                    RegistrationState: registrationStateConditionProperties,
                                    RegistrationField: registrationFieldConditionProperties,
                                }}
                                isRoot
                            />}
                        </Box>
                    </Collapse>
                </Stack>
            </Grid>
        </Grid>
    </DialogForm>
}