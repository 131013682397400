import { Permission, PollQuestion } from "@marketpartner/backend-api"
import { Assignment } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid-pro"
import { FC } from "react"
import { Link } from "react-router-dom"
import { useClient } from "src/clients/client-context"
import { QueryDataGrid, QueryDataGridProps } from "src/common/grid/QueryDataGrid"
import { Bar } from "src/common/layout/Bar"
import { ContextMenuButton } from "src/common/menus/ContextMenuButton"
import { link } from "src/common/routing/routes"
import { useEvent } from "src/events/event-context"
import { ifHas } from "src/login/IfHas"
import { HighlightPollQuestionButton } from "src/polls/questions/HighlightPollQuestionButton"
import { usePollQuestionContextMenu } from "src/polls/questions/PollQuestionContextMenu"

type PollQuestionsGridProps = Omit<QueryDataGridProps<PollQuestion>, "columns">

const columns: GridColDef<PollQuestion>[] = [{
    field: 'name',
    headerName: 'Name',
    sortable: false,
    flex: 1,
    renderCell: ({ row }) => <PollQuestionLink pollQuestion={row} />
}, {
    field: 'isHighlighted',
    headerName: 'Highlight',
    sortable: true,
    renderCell: ({ row }) => <HighlightPollQuestionButton pollQuestion={row} />
}, {
    field: "responsesCount",
    headerName: "Responses",
    sortable: false,
    width: 100,
    valueGetter: ({ row }) => row.responsesCount,
    renderCell: ({ row }) => <ResponsesLink pollQuestion={row} />
}, {
    field: 'actions',
    headerName: '',
    sortable: false,
    width: 100,
    renderCell: ({ row }) => <Bar>
        <PollQuestionElementsFormButton pollQuestion={row} />
        <PollQuestionContextMenuButton pollQuestion={row} />
    </Bar>
}]

export const PollQuestionsGrid: FC<PollQuestionsGridProps> = ({
    ...props
}) => {


    return <QueryDataGrid
        columns={columns}
        {...props}
    />
}

const PollQuestionContextMenuButton: FC<{ pollQuestion: PollQuestion }> = ({ pollQuestion }) => {

    const client = useClient()!
    const event = useEvent()!

    const menu = usePollQuestionContextMenu()

    return ifHas(Permission.EditPolls, client.id, event.id, <ContextMenuButton
        onClick={e => menu.open({
            anchorEl: e.currentTarget,
            pollQuestion
        })}
    />)
}

type PollQuestionFormElementsButtonProps = {
    pollQuestion: PollQuestion
}

const PollQuestionElementsFormButton: FC<PollQuestionFormElementsButtonProps> = ({
    pollQuestion
}) => {
    const client = useClient()!
    const event = useEvent()!

    return <IconButton
        href={`#${link.toPollQuestionElements(client.id, event.id, pollQuestion.pollId, pollQuestion.id)}`}
    >
        <Assignment />
    </IconButton>
}

type PollQuestionLinkProps = {
    pollQuestion: PollQuestion
}

const PollQuestionLink: FC<PollQuestionLinkProps> = ({ pollQuestion }) => {
    const client = useClient()!
    const event = useEvent()!
    return <Link
        to={link.toPollQuestion(client.id, event.id, pollQuestion.pollId, pollQuestion.id)}
    >
        {pollQuestion.name}
    </Link>
}

type ResponsesLinkProps = {
    pollQuestion: PollQuestion
}

const ResponsesLink: FC<ResponsesLinkProps> = ({ pollQuestion }) => {
    const client = useClient()!
    const event = useEvent()!
    return <Link
        to={link.toPollQuestionResponses(client.id, event.id, pollQuestion.pollId, pollQuestion.id)}
    >
        {pollQuestion.responsesCount}
    </Link>
}