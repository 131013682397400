import { Permission } from "@marketpartner/backend-api"
import { Edit } from "@mui/icons-material"
import { Button, Stack, SxProps, Typography } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { Bar } from "src/common/layout/Bar"
import { BasicProperties } from "src/common/layout/BasicProperties"
import { Spacer } from "src/common/layout/Spacer"
import { EditableConfiguredEmailTemplate } from "src/email/templates/EditableConfiguredEmailTemplate"
import { useEvent } from "src/events/event-context"
import { useLoginContext } from "src/login/LoginContext"
import { EditableAvailability } from "src/networking/meetings/availability/EditableAvailability"
import { useNetworkingPeriodsDialog } from "src/networking/meetings/periods/NetworkingPeriodsDialog"


export type EventMeetingSettingsProps = {
    sx?: SxProps
}

export const EventMeetingSettings: FC<EventMeetingSettingsProps> = ({
    sx,
}) => {
    const client = useClient()!
    const event = useEvent()!
    const login = useLoginContext()

    const updateEventMutation = backend.events.useUpdateEvent()

    const networkingPeriodsDialog = useNetworkingPeriodsDialog()

    const canEdit = login.hasPermission(Permission.EditEventDetails, client.id, event.id)

    return <Stack sx={sx}>
        <BasicProperties sx={{ p: 2, alignItems: "center" }}>
            <EditableConfiguredEmailTemplate
                label="Meeting requested email"
                editLabel="meeting requested email template"
                configuredTemplate={event.triggeredEmailTemplates.meetingRequested}
                onChange={template => updateEventMutation.mutateAsync([client.id, event.id, {
                    triggeredEmailTemplates: {
                        meetingRequested: template,
                    },
                }])}
            />
            <EditableConfiguredEmailTemplate
                label="Meeting accepted email"
                editLabel="meeting accepted email template"
                configuredTemplate={event.triggeredEmailTemplates.meetingAccepted}
                onChange={template => updateEventMutation.mutateAsync([client.id, event.id, {
                    triggeredEmailTemplates: {
                        meetingAccepted: template,
                    },
                }])}
            />
        </BasicProperties>
        <Bar sx={{ p: 2, mt: 4 }}>
            <Typography variant="h5">
                Default availability (all users)
            </Typography>
            <Spacer />
            {canEdit && <Button
                children="Networking periods"
                startIcon={<Edit />}
                variant="contained"
                onClick={() => networkingPeriodsDialog.open({})}
            />}
        </Bar>
        <EditableAvailability
            initialAvailability={event.defaultMeetingAvailability}
            onChangeAvailability={availability => updateEventMutation.mutateAsync([client.id, event.id, {
                localDefaultMeetingAvailability: availability.toLocalIntervalList(),
            }])}
            canEdit={canEdit}
            sx={{ p: 2 }}
        />
    </Stack>
}