import { PollQuestion, Scope } from "@marketpartner/backend-api"
import { Delete, Edit } from "@mui/icons-material"
import { ListItemIcon, ListItemText, Menu, MenuItem, MenuProps } from "@mui/material"
import { FC } from "react"
import { createPopupHook } from "src/common/dialogs/Popup"
import { useDeletePollQuestionDialog } from "src/polls/questions/DeletePollQuestionDialog"
import { useEditPollQuestionDialog } from "src/polls/questions/EditPollQuestionDialog"

export type PollQuestionContextMenuProps = MenuProps & {
    onClose: () => void
    pollQuestion: PollQuestion
}

const PollQuestionContextMenu: FC<PollQuestionContextMenuProps> = ({
    pollQuestion,
    ...props
}) => {

    const editPollQuestionDialog = useEditPollQuestionDialog()
    const deletePollQuestionDialog = useDeletePollQuestionDialog()

    return <Menu {...props} onClick={props.onClose}>
        <MenuItem
            onClick={() => {
                editPollQuestionDialog.open({
                    pollQuestion,
                })
            }}
        >
            <ListItemIcon><Edit color="primary" /></ListItemIcon>
            <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem
            onClick={() => {
                deletePollQuestionDialog.open({
                    pollQuestion,
                })
            }}
        >
            <ListItemIcon><Delete color="error" /></ListItemIcon>
            <ListItemText>Delete</ListItemText>
        </MenuItem>
    </Menu>
}

export const usePollQuestionContextMenu = createPopupHook({
    element: PollQuestionContextMenu,
    scope: Scope.Event,
})