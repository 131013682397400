import { CopyRegistrationsResult } from "@marketpartner/backend-api";
import { useBatchedMutation, UseBatchedMutationOptions } from "@marketpartner/mp-common-react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { useEvent } from "src/events/event-context";

export type UseCopyRegistrationsOptions = Partial<UseBatchedMutationOptions<CopyRegistrationsResult, string>> & {
    sourceEventId: string,
    targetCategoryId: string,
    copyGuests: boolean,
}

export const useCopyRegistrations = ({
    sourceEventId,
    targetCategoryId,
    copyGuests,
    ...options
}: UseCopyRegistrationsOptions) => {
    const client = useClient()!
    const event = useEvent()!
    const invalidate = backend.registrations.useInvalidate()
    const copyMutation = backend.registrations.useCopyRegistrations({
        disableInvalidation: true,
    })
    return useBatchedMutation({
        processBatch: (registrationIds: string[]) => copyMutation.mutateAsync([client.id, event.id, {
            sourceEventId,
            targetCategoryId,
            registrationIds,
            copyGuests,
        }]),
        maxBatchSize: 25,
        ...options,
        onSettled: async (data, error, variables, context) => {
            await invalidate()
            options?.onSettled?.(data, error, variables, context)
        }
    })
}
