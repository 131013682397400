import { Timezone } from "@marketpartner/backend-api";
import { Button, Stack, SxProps, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { FC } from "react";
import { Bar } from "src/common/layout/Bar";
import { activeStatusColors, ActiveStatusDetails, GenericActiveStatus, getGenericActiveStatus } from "src/common/statuses/active-status-details";
import { ActiveStatus } from "src/common/statuses/ActiveStatus";
import { LuxonDateTimePicker } from "src/common/time/LuxonDateTimePickers";

export type ActiveStatusEditorProps = {
    details: ActiveStatusDetails
    timezone: Timezone
    start: DateTime | null
    end: DateTime | null
    setStart: (start: DateTime | null) => void
    setEnd: (start: DateTime | null) => void
    required?: boolean
    sx?: SxProps
}

export const ActiveStatusEditor: FC<ActiveStatusEditorProps> = ({
    details,
    timezone,
    start,
    end,
    setStart,
    setEnd,
    required = false,
    sx,
}) => {
    const status = getGenericActiveStatus(start, end)

    return <Stack spacing={1} sx={sx}>
        <Bar spacing={1}>
            <Typography sx={{ fontWeight: "bold" }}>
                Status:
            </Typography>
            <ActiveStatus details={details} start={start} end={end} />
            {status === GenericActiveStatus.pre && <Button
                color={activeStatusColors.active}
                startIcon={details.active.icon}
                onClick={() => setStart(DateTime.now().setZone(timezone).minus({ seconds: 1 }))}
            >
                Open now
            </Button>}
            {status === GenericActiveStatus.active && <Button
                color={activeStatusColors.post}
                startIcon={details.post.icon}
                onClick={() => setEnd(DateTime.now().setZone(timezone).minus({ seconds: 1 }))}
            >
                Close now
            </Button>}
            {status === GenericActiveStatus.post && <Button
                color={activeStatusColors.active}
                startIcon={details.active.icon}
                onClick={() => setEnd(null)}
            >
                Re-open now
            </Button>}
        </Bar>
        <Bar spacing={1}>
            <LuxonDateTimePicker value={start} onChange={setStart} timezone={timezone} required={required} label={"Start"} />
            <LuxonDateTimePicker value={end} onChange={setEnd} timezone={timezone} required={required} label={"End"} />
        </Bar>
    </Stack >
}