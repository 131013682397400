import { FeedItemDisplayStatus } from "@marketpartner/backend-api"
import { MpFeedItem } from "@marketpartner/react-feed"
import { HierarchicalFeedItem } from "@marketpartner/react-feed/dist/items/hierarchical-feed-item"
import { Collapse } from "@mui/material"
import { memo, MouseEvent, useCallback, useMemo, useState } from "react"
import { FeedItem } from "src/feeds/items/FeedItem"
import { FeedItemReplyCount } from "src/feeds/items/FeedItemReplyCount"
import { useFeedDisplayOptions } from "src/feeds/items/options/feed-display-context"


export type HierarchicalFeedItemComponentProps = {
    hItem: HierarchicalFeedItem<MpFeedItem>
    indentationLevel?: number
}

export const HierarchicalFeedItemComponent = memo<HierarchicalFeedItemComponentProps>(({
    hItem,
    indentationLevel = 0,
}) => {
    const [showReplies, setShowReplies] = useState(false)
    const { showDeletedItems } = useFeedDisplayOptions()
    const { item, replies } = hItem

    const handleToggleReplies = useCallback((e: MouseEvent) => {
        e.stopPropagation()
        setShowReplies(showReplies => !showReplies)
    }, [])

    let filteredReplies = replies
        .filter(reply => showDeletedItems || reply.item.displayStatus !== FeedItemDisplayStatus.Removed)

    const showRepliesLink = useMemo(() =>
        <FeedItemReplyCount
            count={filteredReplies.length}
            isExpanded={showReplies}
            onClick={handleToggleReplies}
        />,
        [filteredReplies.length, showReplies, handleToggleReplies]
    )

    if (!showDeletedItems && item.displayStatus === FeedItemDisplayStatus.Removed) {
        return <></>
    }

    return <>
        <FeedItem
            item={item}
            indentationLevel={indentationLevel}
            memoFooterComponent={showRepliesLink}
        />
        <Collapse in={showReplies}>
            {filteredReplies.map(reply => <HierarchicalFeedItemComponent
                key={reply.id}
                hItem={reply}
                indentationLevel={indentationLevel + 1}
            />)}
        </Collapse>
    </>
})