import { LockOutlined, Edit } from "@mui/icons-material"
import { Box, SvgIconProps } from "@mui/material"
import { cloneElement, FC } from "react"


export type EditableProps = Partial<SvgIconProps> & {
    editable: boolean
}

export const Editable: FC<EditableProps> = ({
    editable,
    sx,
    ...props
}) => {
    const indicator = editable ?
        <></> :
        <LockOutlined color="error" />

    return <Box
        sx={{
            ...sx,
            position: "relative",
            height: 24,
        }}
    >
        <Edit
            color={editable ? "inherit" : "disabled"}
            sx={{ position: "relative" }}
            {...props}
        />
        {cloneElement(indicator, {
            fontSize: "xsmall",
            sx: {
                position: "absolute",
                bottom: -6,
                right: -6,
            }
        })}
    </Box>
}