import { EffectivePermissions, Permission } from "@marketpartner/backend-api"

// NOTE: This function is duplicated in the back-end
// Make sure it is updated with any changes that occur here (and vice versa)
// (and make sure tests are run in back-end project to check for breakage)
export function hasPermissionForContext(
    permissions: EffectivePermissions,
    permission: Permission,
    clientId?: string,
    eventId?: string
): boolean {
    const context = permissions[permission]

    // Permission is not granted in any context. Never allow
    if (!context) {
        return false
    }

    // Not client/event specific. Allow if permission is granted in any context at all
    if (!clientId) {
        return true
    }

    // Client-specific. Allow if global permission or client permission, or permission for any of client's events is granted
    const grantedForClient = context.clients.includes(clientId) ?? false
    const grantedForAnyOfClientsEvents = context.events[clientId] !== undefined
    if (!eventId) {
        return context.global || grantedForClient || grantedForAnyOfClientsEvents
    }

    // Event specific. Allow if global permission, or client permission, or event permission is granted
    const grantedForEvent = context.events?.[clientId]?.includes(eventId) ?? false
    return context.global || grantedForClient || grantedForEvent
}