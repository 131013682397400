import { CheckboxesConfig } from "@marketpartner/backend-api"
import { Grid } from "@mui/material"
import { ChoicesConfig } from "src/forms/elements/types/ChoicesConfig"
import { FormElementConfigComponent } from "src/forms/elements/types/formElementConfigTypes"
import { StandardFields } from "src/forms/elements/types/StandardFields"


export const CheckboxesConfigComponent: FormElementConfigComponent<CheckboxesConfig> = ({
    config,
    updateConfigProperty
}) => {
    return <>
        <StandardFields config={config} updateConfigProperty={updateConfigProperty}/>
        <Grid item xs={12}>
            <ChoicesConfig
                config={config}
                headerText="Checkbox choices"
                updateConfigProperty={updateConfigProperty}
            />
        </Grid>
    </>
}