import { Permission, StructuredAgenda } from "@marketpartner/backend-api"
import { Add, Edit } from "@mui/icons-material"
import { Box, Button, Stack, Typography } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { Loading } from "src/common/loading/Loading"
import { RoutedTabs } from "src/common/routing/RoutedTabs"
import { useRestrictedRoutedTabs } from "src/common/routing/useRestrictedRoutedTabs"
import { center, fullSize } from "src/common/styles"
import { useCreateAgendaDialog } from "src/content/agendas/CreateAgendaDialog"
import { EditableAgenda } from "src/content/agendas/EditableAgenda"
import { useAgendasDialog } from "src/content/agendas/AgendasDialog"
import { useEvent } from "src/events/event-context"


export const Agendas: FC = () => {
    const client = useClient()!
    const event = useEvent()!
    const agendasRequest = backend.agendas.useGetAll([client.id, event.id])


    return <Loading request={agendasRequest} render={agendas => agendas.length === 0 ?
        <NoAgendas /> :
        <AgendaTabs agendas={agendas} />
    } />
}

const NoAgendas: FC = () => {
    const createAgendaDialog = useCreateAgendaDialog()

    return <Stack spacing={2} sx={{ alignItems: "center", ...center }}>
        <Typography>No agendas</Typography>
        <Button
            onClick={() => createAgendaDialog.open({})}
            startIcon={<Add />}
        >
            Create agenda
        </Button>
    </Stack>
}

type AgendaTabsProps = {
    agendas: StructuredAgenda[]
}

const AgendaTabs: FC<AgendaTabsProps> = ({
    agendas
}) => {
    const client = useClient()!
    const event = useEvent()!
    const editAgendasDialog = useAgendasDialog()

    const editTab = useRestrictedRoutedTabs(Permission.EditEventContent, client.id, event.id, [{
        path: "_edit_",
        label: <Edit />,
        element: <></>,
        onClick: e => {
            editAgendasDialog.open({})
            return e.preventDefault()
        },
    }])

    return <RoutedTabs
        tabs={[
            ...agendas.map(agenda => ({
                path: agenda.id.toString(),
                label: agenda.name,
                element: <Box sx={{ ...fullSize, overflow: "auto", pt: 2 }}>
                    <EditableAgenda agenda={agenda} sx={{ mx: "auto" }} />
                </Box>,
            })),
            ...editTab
        ]}
        textColor="inherit"
        variant="standard"
        centered
        sx={{
            '& .MuiTabs-indicator': {
                backgroundColor: theme => theme.palette.text.primary
            }
        }}
    />
}
