import { DynamicEmailTemplate, Scope } from "@marketpartner/backend-api"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { ConfirmDeleteDialog } from "src/common/dialogs/ConfirmDeleteDialog"
import { LockableDialogProps } from "src/common/dialogs/LockableDialog"
import { createPopupHook } from "src/common/dialogs/Popup"
import { withNotification } from "src/common/notifications/with-notification"


export type DeleteDynamicEmailTemplateDialogProps = LockableDialogProps & {
    template: DynamicEmailTemplate
}

const DeleteDynamicEmailTemplateDialog: FC<DeleteDynamicEmailTemplateDialogProps> = ({
    template,
    ...props
}) => {
    const client = useClient()!

    const deleteMutation = backend.emailTemplates.useDeleteDynamicEmailTemplate(withNotification({
        errorMessage: "Error deleting dynamic email template"
    }))

    return <ConfirmDeleteDialog
        itemName={template.name}
        formProps={{
            action: () => deleteMutation.mutateAsync([client.id, template.id]),
            text: <>
                WARNING: If this template is in use, deleting it will cause any associated actions to fail (e.g. registration, meeting requests, etc).<br />
                <br />
                It is recommended that old templates are hidden instead.
            </>,
            confirmText: template.name,
        }}
        {...props}
    />
}

export const useDeleteDynamicEmailTemplateDialog = createPopupHook({
    element: DeleteDynamicEmailTemplateDialog,
    scope: Scope.Client,
})