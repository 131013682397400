import { EventSegment } from "@marketpartner/backend-api"
import { SxProps } from "@mui/material"
import { Box } from "@mui/system"
import { FC, useMemo } from "react"
import { Plot } from "src/common/Plot"
import { toUtcDateTime } from "src/common/time/time-utils"
import { useEvent } from "src/events/event-context"

export type ActivityUsageGraphProps = {
    usage: Record<string, number>
    segment?: EventSegment
    sx?: SxProps
}

export const ActivityUsageGraph: FC<ActivityUsageGraphProps> = ({
    usage,
    segment,
    sx
}) => {
    const event = useEvent()!
    const dates = useMemo(() =>
        Object.keys(usage).map(time => toTimezone(time, event.timezone)),
        [usage, event.timezone]
    )
    const data = useMemo((): Partial<Plotly.Data>[] => ([
        {
            x: dates,
            y: Object.values(usage),
            type: "bar",
            name: "Total users"
        }
    ]), [usage, dates])

    const minDate = dates[0]
    const maxDate = dates[dates.length - 1]

    const layout = useMemo((): Partial<Plotly.Layout> => ({
        xaxis: {
            rangeselector: {
                buttons: [
                    {
                        step: 'all',
                        label: "Show all",
                    },
                ],
            },
            rangeslider: {
                bgcolor: "#eee",
                range: [minDate, maxDate],
            },
            fixedrange: true,
            range: segment && [
                toTimezone(segment.startTime?.toISO(), event.timezone),
                toTimezone(segment.endTime?.toISO(), event.timezone)
            ]
        },
        yaxis: {
            fixedrange: true
        },
        annotations: [{
            text: event.timezone,
            showarrow: false,
            xref: "paper",
            yref: "paper",
            yanchor: "bottom",
            y: 1,
            font: {
                color: 'gray',
            },
        }],
        autosize: true,
        margin: {
            t: 0,
            b: 0,
            l: 35,
            r: 0,
        },
        // width & height is a minor hack to force graph to re-layout on container size change
        // as plotly is not behaving itself
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [event.timezone, minDate, maxDate, (sx as any)?.width, (sx as any)?.height])

    const config = useMemo((): Partial<Plotly.Config> => ({
        displayModeBar: true,
        displaylogo: false,
        modeBarButtonsToRemove: [
            'select2d', 'lasso2d'
        ],
    }), [])

    return <Box sx={sx}>
        <Plot
            data={data}
            layout={layout}
            config={config}
            useResizeHandler
            style={{ width: "100%", height: "100%" }}
        />
    </Box>
}

function toTimezone(dateTime: string | null | undefined, timezone: string): string {
    return toUtcDateTime(dateTime).setZone(timezone).toISO()
}