import { RegistrationCategoryType } from "@marketpartner/backend-api"
import { Person, RecordVoiceOver } from "@mui/icons-material"
import { SvgIconProps } from "@mui/material"
import { FC } from "react"

export type RegistrationCategoryTypeIconProps = Partial<SvgIconProps> & {
    type: RegistrationCategoryType
}

export const RegistrationCategoryTypeIcon: FC<RegistrationCategoryTypeIconProps> = ({
    type,
    ...props
}) => {
    switch (type) {
        case RegistrationCategoryType.Speaker:
            return <RecordVoiceOver color="primary" {...props} />
        default:
            return <Person {...props} />
    }
}
