import { Check, Remove, Warning } from "@mui/icons-material"
import { FC } from "react"
import { ColumnMatch } from "src/registrations/import/pre-process/auto-match-columns"


export type ColumnMatchIconProps = {
    match: ColumnMatch | null
}

export const ColumnMatchIcon: FC<ColumnMatchIconProps> = ({
    match,
}) => {
    switch (match?.type) {
        case undefined: return <Warning color="warning" />
        case "ignored": return <Remove />
        default: return <Check color="success" />
    }
}