import { Registration } from "@marketpartner/backend-api"
import { FC } from "react"
import { Bar } from "src/common/layout/Bar"
import { Spacer } from "src/common/layout/Spacer"
import { BulkApprovalButtons } from "src/registrations/bulk/BulkApprovalButtons"
import { SelectedRegistrationsText } from "src/registrations/bulk/SelectedRegistrationsText"


export type RegistrationsFooterProps = {
    selectedRegistrations: Registration[]
    clearSelection: () => void
}

export const RegistrationsFooter: FC<RegistrationsFooterProps> = ({
    selectedRegistrations,
    clearSelection,
}) => {
    return <Bar spacing={4} p={1}>
        <BulkApprovalButtons selectedRegistrations={selectedRegistrations} />
        <Spacer />
        <SelectedRegistrationsText selectedRegistrations={selectedRegistrations} clearSelection={clearSelection} />
    </Bar>
}