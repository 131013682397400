import { FormFieldChoice } from "@marketpartner/backend-api"
import { Grid, TextField, Checkbox, FormControlLabel } from "@mui/material"
import { FC } from "react"
import { PropertyUpdater } from "src/forms/elements/types/formElementConfigTypes"

export type ChoiceConfigProps = {
    choice: FormFieldChoice
    editChoice?: PropertyUpdater<FormFieldChoice>
}

export const ChoiceConfig: FC<ChoiceConfigProps> = ({
    choice,
    editChoice
}) => {
    return <Grid container spacing={2}>
        <Grid item xs={5}>
            <TextField
                label="Label"
                fullWidth
                value={choice.label}
                onChange={(e) => editChoice && editChoice('label', e.target.value)}
                required
            />
        </Grid>
        <Grid item xs={5}>
            <TextField
                label="Value"
                fullWidth
                value={choice.value}
                onChange={(e) => editChoice && editChoice('value', e.target.value)}
            />
        </Grid>
        <Grid item xs={2}>
            <FormControlLabel
                label="Disabled?"
                control={<Checkbox
                    color="primary"
                    checked={choice.disabled}
                    onChange={(e) => editChoice && editChoice('disabled', e.target.checked)}
                />}
                labelPlacement="top"
            />
        </Grid>
    </Grid>
}