import { LoadingButton } from "@mui/lab";
import { Button, Fade, Input, Typography } from "@mui/material";
import { FC } from "react";
import { DialogStepper } from "src/common/dialogs/DialogStepper";
import { MatchCsvColumns } from "src/registrations/import/column-matching/MatchCsvColumns";
import { useColumnMatches } from "src/registrations/import/column-matching/useColumnMatches";
import { ImportProgress } from "src/registrations/import/import-process/ImportProgress";
import { useImportMutation } from "src/registrations/import/import-process/useImportMutation";
import { ColumnMatch } from "src/registrations/import/pre-process/auto-match-columns";
import { useCsvPreprocessor } from "src/registrations/import/pre-process/useCsvPreprocessor";
import { ImportPreviewGrid } from "src/registrations/import/preview/ImportPreviewGrid";
import { usePreparedImport } from "src/registrations/import/preview/usePreparedImport";
import { ResultCounts } from "src/registrations/import/results/ResultCounts";
import { ResultsGrid } from "src/registrations/import/results/ResultsGrid";
import { SelectCsvFileText } from "src/registrations/import/SelectCsvFileText";

export type ImportRegistrationsDialogProps = {
}

export const ImportRegistrationsDialog: FC<ImportRegistrationsDialogProps> = () => {
    const preprocessor = useCsvPreprocessor()
    const columnMatches = useColumnMatches(
        preprocessor.preprocessedCsv?.columnMatches,
        preprocessor.preprocessedCsv?.formFields ?? []
    )
    const preparedImport = usePreparedImport()
    const importMutation = useImportMutation()

    const stepIdx = !preprocessor.preprocessedCsv ? 0 :
        !preparedImport.isPrepared ? 1 :
            importMutation.isIdle ? 2 :
                !importMutation.isSuccess ? 3 :
                    4

    return <DialogStepper
        title="Import registrations"
        stepIdx={stepIdx}
        steps={[
            {
                label: "Select file",
                content: <SelectCsvFileText
                    error={preprocessor.preprocessingError}
                    sx={{ width: "fit-content", mx: "auto" }}
                />,
                nextAction: <SelectFileButton
                    onSelectFile={preprocessor.preprocessCsv}
                    isLoading={preprocessor.isProcessing}
                />,
            },
            {
                label: "Match columns",
                content: <MatchCsvColumns
                    columnMatches={columnMatches}
                    sx={{ maxWidth: 950, mx: "auto" }}
                />,
                nextAction: <>
                    <Fade in={!columnMatches.isComplete}>
                        <Typography color="text.secondary">
                            ({columnMatches.unmatchedCount} remaining)
                        </Typography>
                    </Fade>
                    <Button
                        variant="contained"
                        disabled={!columnMatches.isComplete}
                        onClick={() => preparedImport.prepare(
                            preprocessor.preprocessedCsv!.rows,
                            columnMatches.matches as Record<string, ColumnMatch>,
                        )}
                    >
                        Preview changes
                    </Button>
                </>,
                back: preprocessor.reset,
            },
            {
                label: "Review",
                content: <ImportPreviewGrid
                    preparedRegistrations={preparedImport.preparedRegistrations}
                    columnMapping={preparedImport.columnMapping}
                />,
                nextAction: <Button
                    variant="contained"
                    onClick={() => importMutation.mutate(preparedImport.preparedRegistrations)}
                >
                    Import
                </Button>,
                back: preparedImport.reset
            },
            {
                label: "Import",
                content: <ImportProgress
                    importMutation={importMutation}
                    registrationCount={preparedImport.preparedRegistrations.length}
                />,
            },
            {
                label: "Results",
                header: <ResultCounts
                    results={importMutation.data ?? []}
                    sx={{ px: 4, mb: 2 }}
                />,
                content: <ResultsGrid
                    columnMapping={preparedImport.columnMapping}
                    registrations={preparedImport.preparedRegistrations}
                    results={importMutation.data ?? []}
                />
            }
        ]}
    />
}

type SelectFileButtonProps = {
    onSelectFile: (file: File) => void
    isLoading: boolean
}

const SelectFileButton: FC<SelectFileButtonProps> = ({
    onSelectFile,
    isLoading,
}) => {
    return <label htmlFor="contained-button-file">
        <Input
            sx={{ display: "none" }}
            inputProps={{ accept: '.csv' }}
            id="contained-button-file"
            type="file"
            onChange={e => onSelectFile((e.target as HTMLInputElement).files![0])}
        />
        <LoadingButton
            variant="contained"
            component="span"
            loading={isLoading}
        >Select CSV</LoadingButton>
    </label>
}
