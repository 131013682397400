import { Agenda, Scope } from "@marketpartner/backend-api";
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { AgendaForm } from "src/content/agendas/AgendaForm";
import { useEvent } from "src/events/event-context";


type EditAgendaDialogProps = LockableDialogProps & {
    agenda: Agenda
}

const EditAgendaDialog: FC<EditAgendaDialogProps> = ({
    agenda,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const updateAgendaMutation = backend.agendas.useUpdate(withNotification({
        successMessage: "Updated agenda",
        errorMessage: "Error updating agenda",
    }))

    return <LockableDialog {...props}>
        <DialogTitle>
            Edit agenda
        </DialogTitle>
        <AgendaForm
            onSubmit={formData => updateAgendaMutation.mutateAsync([client.id, event.id, agenda.id, {
                ...formData,
            }])}
            agenda={agenda}
            actionName="Save"
        />
    </LockableDialog>
}

export const useEditAgendaDialog = createPopupHook({
    scope: Scope.Event,
    element: EditAgendaDialog,
})
