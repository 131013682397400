import { Scope } from '@marketpartner/backend-api'
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon"
import { LicenseInfo } from '@mui/x-license-pro'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { SnackbarProvider } from 'notistack'
import { HashRouter } from 'react-router-dom'
import { UserUsagePopupContextProvider } from 'src/activities/user-usage/UserUsagePopup'
import { AppLayout } from 'src/AppLayout'
import { ClientContextProvider, EnsureClientContextLoaded } from 'src/clients/client-context'
import { RenderPopups } from 'src/common/dialogs/PopupRegister'
import { MpThemeProvider } from 'src/common/MpTheme'
import { RouteMainPage } from 'src/common/routing/RouteMainPage'
import { EnsureEventContextLoaded, EventContextProvider } from 'src/events/event-context'
import { AdminMfaCheck } from 'src/login/mfa/AdminMfaCheck'
import { RequireLogin } from 'src/login/RequireLogin'

LicenseInfo.setLicenseKey('befd6d8a00721be477b09c2079a03ca3Tz03NTE2MSxFPTE3MjY5MzI0NTMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=')

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 0,
        }
    }
})

export default function App() {
    return <>
        <QueryClientProvider client={queryClient}>
            <MpThemeProvider>
                <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={Intl.DateTimeFormat().resolvedOptions().locale} >
                    <HashRouter>
                        <SnackbarProvider>
                            <RequireLogin>
                                <AdminMfaCheck />
                                <ClientContextProvider>
                                    <EventContextProvider>
                                        <RenderPopups scope={Scope.Global} />
                                            <UserUsagePopupContextProvider>
                                                <AppLayout>
                                                    <EnsureClientContextLoaded>
                                                        <RenderPopups scope={Scope.Client} />
                                                        <EnsureEventContextLoaded>
                                                            <RenderPopups scope={Scope.Event} />
                                                            <RouteMainPage />
                                                        </EnsureEventContextLoaded>
                                                    </EnsureClientContextLoaded>
                                                </AppLayout>
                                            </UserUsagePopupContextProvider>

                                    </EventContextProvider>
                                </ClientContextProvider>
                            </RequireLogin>
                        </SnackbarProvider>
                    </HashRouter>
                </LocalizationProvider>
            </MpThemeProvider>
        </QueryClientProvider>
    </>
}
