import { Box } from "@mui/system"
import { ReactNode } from "react"
import { EventPartnerGroupedItems } from "src/content/event-partner/EventPartnerGroupedItems"

export type EventPartnerItem = {
    categoryTitle: string
    entity_token: string
}

export type EventPartnerCategorizedGridProps<Item extends EventPartnerItem> = {
    data: Item[]
    renderItem: (item: Item) => ReactNode
}

export const EventPartnerCategorizedGrid = <Item extends EventPartnerItem>({
    data,
    renderItem
}: EventPartnerCategorizedGridProps<Item>) => {
    return <EventPartnerGroupedItems
        data={data}
        groupByProperty="categoryTitle"
        renderItems={items => <Items items={items} renderItem={renderItem} />}
    />
}

type ItemsProps<Item extends EventPartnerItem> = {
    items: Item[]
    renderItem: (item: Item) => ReactNode
}

const Items = <Item extends EventPartnerItem>({ 
    items,
    renderItem
}: ItemsProps<Item>) => {
    return <Box sx={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "flex-start",
        justifyContent: "space-evenly",
    }}>
        {items.map(item =>
            <Box key={item.entity_token} sx={{ mx: 1, my: 2 }}>
                {renderItem(item)}
            </Box>
        )}
    </Box>
}
