import { ClientAppTemplate, ClientAppTemplateScope, Permission } from "@marketpartner/backend-api"
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack } from "@mui/material"
import { FC, useCallback, useState } from "react"
import { ClientAppTemplateSelector } from "src/app-templates/client-templates/ClientAppTemplateSelector"
import { AppTemplateSelector } from "src/app-templates/templates/AppTemplateSelector"
import { useClient } from "src/clients/client-context"
import { useLoginContext } from "src/login/LoginContext"

export type NewClientDeploymentTemplateSelectorProps = {
    scope: ClientAppTemplateScope
    clientTemplateId?: string
    onChangeClientTemplateId: (id: string | undefined) => void
    onLoadSelectedTemplate: (template: ClientAppTemplate | undefined) => void
    appTemplateId?: string
    onChangeAppTemplateId: (id: string | undefined) => void
    appTemplateVersion?: string
    onChangeAppTemplateVersion: (version: string | undefined) => void
}

type TemplateType = "client" | "template"

export const NewClientDeploymentTemplateSelector: FC<NewClientDeploymentTemplateSelectorProps> = ({
    scope,
    onLoadSelectedTemplate,
    clientTemplateId,
    onChangeClientTemplateId,
    appTemplateId,
    onChangeAppTemplateId,
    appTemplateVersion,
    onChangeAppTemplateVersion,
}) => {
    const login = useLoginContext()
    const client = useClient()!
    const isAdmin = login.hasPermission(Permission.AppAdmin)
    const [templateType, setTemplateType] = useState<TemplateType>(isAdmin ? "template" : "client")

    const handleSelectClientTemplateId = (newClientTemplateId: string | null) => {
        if (newClientTemplateId !== clientTemplateId) {
            onChangeAppTemplateId(undefined)
            onChangeAppTemplateVersion(undefined)
        }
        onChangeClientTemplateId(newClientTemplateId ?? undefined)
    }

    const handleLoadSelectedTemplate = useCallback((template: ClientAppTemplate | null) => {
        onChangeAppTemplateId(template?.appTemplateId ?? undefined)
        onChangeAppTemplateVersion(template?.appTemplateVersion ?? undefined)
        onLoadSelectedTemplate(template ?? undefined)
    }, [onChangeAppTemplateId, onChangeAppTemplateVersion, onLoadSelectedTemplate])

    const chooseTemplateType = useCallback((type: TemplateType) => {
        onChangeAppTemplateId(undefined)
        onChangeAppTemplateVersion(undefined)
        onChangeClientTemplateId(undefined)
        onLoadSelectedTemplate(undefined)
        setTemplateType(type)
    }, [onChangeAppTemplateId, onChangeAppTemplateVersion, onChangeClientTemplateId, onLoadSelectedTemplate])

    return <Stack spacing={2}>
        {isAdmin && <FormControl>
            <FormLabel>Template type</FormLabel>
            <RadioGroup 
                value={templateType}
                onChange={e => chooseTemplateType(e.target.value as TemplateType)}
                row
            >
                <FormControlLabel
                    label="Specific template version"
                    control={<Radio value={"template"} />}
                />
                <FormControlLabel
                    label="Client template"
                    control={<Radio value={"client"} />}
                />
            </RadioGroup>
        </FormControl>}
        {templateType === "template" && <AppTemplateSelector
            templateId={appTemplateId ?? null}
            version={appTemplateVersion ?? null}
            onChangeTemplateId={id => onChangeAppTemplateId(id ?? undefined)}
            onChangeVersion={version => onChangeAppTemplateVersion(version ?? undefined)}
        />}
        {templateType === "client" && <ClientAppTemplateSelector
            label="Template"
            scope={scope}
            clientId={client.id}
            selectedId={clientTemplateId ?? null}
            onSelectId={handleSelectClientTemplateId}
            onLoadSelectedTemplate={handleLoadSelectedTemplate}
            required
        />}
    </Stack>
}
