import DownloadIcon from '@mui/icons-material/Download'
import { LoadingButton, LoadingButtonProps } from "@mui/lab"
import { saveAs } from 'file-saver'
import { DateTime } from 'luxon'
import { FC } from 'react'
import { useClient } from "src/clients/client-context"
import { backend } from 'src/common/api'
import { withNotification } from 'src/common/notifications/with-notification'
import { useEvent } from "src/events/event-context"

export type ExportActivitiesButtonProps = Partial<LoadingButtonProps>

export const ExportActivitiesButton: FC<ExportActivitiesButtonProps> = (props) => {
    const client = useClient()!
    const event = useEvent()!
    const statsExport = backend.activityStatistics.useExportRegistrationStats(withNotification({
        onSuccess: exportData => saveAs(
            new Blob([exportData], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }),
            `${event.id}-activities-${DateTime.now().toISODate()}.xlsx`
        ),
        errorMessage: "Error exporting registrations with statistics",
    }))

    return <LoadingButton
        startIcon={<DownloadIcon />}
        loading={statsExport.isPending}
        onClick={() => statsExport.mutate([client.id, event.id])}
        color="inherit"
        {...props}
    >
        Export
    </LoadingButton>
}