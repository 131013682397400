import { Client, Permission } from '@marketpartner/backend-api'
import { FC } from 'react'
import { backend } from 'src/common/api'
import { DeleteButton, DeleteButtonProps } from 'src/common/dialogs/DeleteButton'
import { ifHas } from 'src/login/IfHas'

export type DeleteClientButtonProps = Partial<DeleteButtonProps> & {
    client: Client
    onDelete?: () => void
}

export const DeleteClientButton: FC<DeleteClientButtonProps> = ({
    client,
    onDelete,
    ...props
}) => {
    const deleteMutation = backend.clients.useDelete({
        onSuccess: onDelete,
    })

    return ifHas(Permission.AdministerSystem, client.id, undefined,
        <DeleteButton
            buttonText="Delete"
            itemName={client.name}
            text={`This will permanently delete the ${client.name} database and all client data.`}
            confirmText={`I am permanently deleting ${client.id}`}
            deleteAction={() => deleteMutation.mutateAsync([client.id])}
            {...props}
        />
    )
}