import { Registration } from "@marketpartner/backend-api"
import { Add, Edit } from "@mui/icons-material"
import { Typography } from "@mui/material"
import { FC } from "react"
import { Bar } from "src/common/layout/Bar"
import { NameAndEmail } from "src/registrations/NameAndEmail"


export type ImportActionDetailsProps = {
    existingRegistration?: Registration
}

export const ImportActionDetails: FC<ImportActionDetailsProps> = ({
    existingRegistration
}) => {
    return <Bar spacing={1}>
        {existingRegistration ? <>
            <Edit color="secondary" />
            <NameAndEmail registration={existingRegistration} />
        </> : <>
            <Add color="primary" />
            <Typography color="text.secondary" variant="body2" sx={{ fontStyle: "italic" }}>
                Create
            </Typography>
        </>}
    </Bar>
}