import { ContentEntity, ContentEntityId, ContentTypeId, FormData } from "@marketpartner/backend-api"
import { LoadedSelector, MuiFormElements } from "@marketpartner/mp-common-mui"
import { useDynamicFormState } from "@marketpartner/mp-common-react"
import { Collapse, Stack } from "@mui/material"
import { FC, useMemo, useState } from "react"
import { useClient } from "src/clients/client-context"
import { asyncBackend, backend } from "src/common/api"
import { DialogForm, ExtendableDialogFormProps } from "src/common/dialogs/DialogForm"
import { useEvent } from "src/events/event-context"
import { v4 as uuidV4 } from "uuid"

export type ContentEntityFormData = {
    id: ContentEntityId
    typeId: ContentTypeId
    fields: FormData
}

export type ContentEntityFormProps = ExtendableDialogFormProps<ContentEntityFormData> & {
    entity?: ContentEntity
}

export const ContentEntityForm: FC<ContentEntityFormProps> = ({
    entity,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!

    const newEntityUuid = useMemo(() => uuidV4(), [])
    const [typeId, setTypeId] = useState<ContentTypeId | null>(entity?.typeId ?? null)

    const id: ContentEntityId | null = entity ? entity.id :
        typeId ? `${typeId}:${newEntityUuid}` :
            null

    const typesQuery = backend.contentTypes.useGetTypes([client.id, event.id])
    const type = typesQuery.data?.find(type => type.id === typeId)

    const formState = useDynamicFormState({
        elements: type?.formElements ?? [],
        requirement: "complete",
        initialValues: entity?.fields,
    })

    return <DialogForm
        isValid={
            Boolean(typeId) &&
            formState.invalidFields.length === 0
        }
        formData={{
            id: id!,
            typeId: typeId!,
            fields: formState.values,
        }}
        {...props}
    >
        <Stack spacing={2}>
            {!entity && <LoadedSelector
                label="Content type"
                fetchRequest={typesQuery}
                selectedId={typeId}
                onSelectId={setTypeId}
                required
            />}
            <Collapse in={Boolean(type)}>
                <MuiFormElements
                    backend={asyncBackend}
                    state={formState}
                    uploadPrefix={`clients/${client.id}/events/${event.id}/content/entities/${id!}`}
                    validationEnabled
                />
            </Collapse>
        </Stack>
    </DialogForm>
}