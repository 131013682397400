import { Permission } from "@marketpartner/backend-api";
import { DeleteForever, Edit } from "@mui/icons-material";
import { Button } from "@mui/material";
import { FC } from "react";
import { useNavigate, useParams } from "react-router";
import { useClient } from "src/clients/client-context";
import { Page } from "src/common/layout/Page";
import { backend } from "src/common/api";
import { RoutedTabs } from "src/common/routing/RoutedTabs";
import { link } from "src/common/routing/routes";
import { useEvent } from "src/events/event-context";
import { ifHas } from "src/login/IfHas";
import { useDeletePollQuestionDialog } from "src/polls/questions/DeletePollQuestionDialog";
import { useEditPollQuestionDialog } from "src/polls/questions/EditPollQuestionDialog";
import { PollQuestionElements } from "src/polls/questions/elements/PollQuestionElements";
import { PollResponses } from "src/polls/questions/responses/PollResponses";

export const PollQuestionPage: FC = () => {

    const client = useClient()!
    const event = useEvent()!
    const params = useParams()
    const pollId = params.pollId!
    const questionId = parseInt(params.questionId!)
    const navigate = useNavigate()

    const editPollQuestionDialog = useEditPollQuestionDialog()
    const deletePollQuestionDialog = useDeletePollQuestionDialog()

    const questionQuery = backend.pollQuestions.useGet([client.id, event.id, pollId, questionId])

    return <Page
        title="Question"
        actions={ifHas(Permission.EditPolls, client.id, event.id, <>
            <Button
                startIcon={<Edit />}
                color="primary"
                variant="contained"
                onClick={() => editPollQuestionDialog.open({ pollQuestion: questionQuery.data! })}
            >Edit</Button>
            <Button
                startIcon={<DeleteForever />}
                color="error"
                onClick={
                    () => deletePollQuestionDialog.open({ 
                        pollQuestion: questionQuery.data!, 
                        onDelete: () => navigate(link.toPoll(client.id, event.id, pollId))
                    })
                }
            >Delete</Button>
        </>)}
    >
        <RoutedTabs tabs={[
            {
                label: "Responses",
                path: "responses",
                element: <PollResponses />,
            }, {
                label: "Elements",
                path: "elements",
                element: <PollQuestionElements />
            }
        ]} />
    </Page>
}