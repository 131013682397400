import { commonRegistrationFields, defaultRegistrationElementMetadata, isFormField, Permission, RegistrationCategory } from "@marketpartner/backend-api"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { useEvent } from "src/events/event-context"
import { FormElementSourceType } from "src/forms/elements/copying/useFormElementSources"
import { EditableFormElements } from "src/forms/elements/EditableFormElements"
import { useLoginContext } from "src/login/LoginContext"
import { registrationFormChecks } from "src/registrations/configuration/categories/reg-form/registration-form-checks"
import { RegistrationElementMetadataForm } from "src/registrations/configuration/categories/reg-form/RegistrationElementMetadataForm"
import { RegistrationElementMetadataIcons } from "src/registrations/configuration/categories/reg-form/RegistrationElementMetadataIcons"


export type EditableRegistrationCategoryFormProps = {
    category: RegistrationCategory
}

export const EditableRegistrationCategoryForm: FC<EditableRegistrationCategoryFormProps> = ({
    category,
}) => {
    const client = useClient()!
    const event = useEvent()!
    const login = useLoginContext()

    const updateMutation = backend.registrationCategories.useUpdate()

    return <EditableFormElements
        canEdit={login.hasPermission(Permission.EditEventDetails, client.id, event.id)}
        elements={category.elements}
        onChangeElements={elements => updateMutation.mutateAsync([client.id, event.id, category.id, {
            elements
        }])}
        defaultSourceType={FormElementSourceType.RegistrationCategories}
        commonElements={commonRegistrationFields}
        elementChecks={registrationFormChecks}
        renderExtraControls={config => isFormField(config) ? <RegistrationElementMetadataIcons 
            metadata={config.metadata}
            sx={{ mx: 1 }}
        /> : undefined}
        renderMetadataControls={(config, onChange) => <RegistrationElementMetadataForm
            config={config}
            onChange={onChange}
        />}
        defaultMetadata={defaultRegistrationElementMetadata()}
        sizing="full-size"
    />
}