import { Permission, Registration, RegistrationCategoryType, RegistrationType, RelatedContentType } from "@marketpartner/backend-api";
import { casualName } from "@marketpartner/mp-common";
import { DeleteForever, Edit } from "@mui/icons-material";
import { Box, Button, Paper, SxProps } from "@mui/material";
import { FC } from "react";
import { useNavigate, useParams } from "react-router";
import { UserTimeline } from "src/activities/user-usage/UserTimeline";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { Page } from "src/common/layout/Page";
import { Loading } from "src/common/loading/Loading";
import { RoutedTabs } from "src/common/routing/RoutedTabs";
import { fullSize } from "src/common/styles";
import { relatedContentTab } from "src/content/relationships/relationship-tabs";
import { useEvent } from "src/events/event-context";
import { ifHas } from "src/login/IfHas";
import { useLoginContext } from "src/login/LoginContext";
import { EditableIndividualAvailability } from "src/networking/meetings/availability/individual/EditableIndividualAvailability";
import { useDeleteRegistrationDialog } from "src/registrations/actions/DeleteRegistrationDialog";
import { useEditGuestDialog } from "src/registrations/actions/EditGuestDialog";
import { useEditPrimaryRegistrationDialog } from "src/registrations/actions/EditPrimaryRegistrationDialog";
import { ProfileDetails } from "src/registrations/profiles/ProfileDetails";
import { RegistrationDetails } from "src/registrations/RegistrationDetails";


export const RegistrationPage: FC = () => {
    const client = useClient()!
    const event = useEvent()!
    const login = useLoginContext()
    const registrationId = useParams().registrationId!
    const registrationQuery = backend.registrations.useGet<Registration>([client.id, event.id, registrationId!])
    const editPrimaryRegistrationDialog = useEditPrimaryRegistrationDialog()
    const editGuestDialog = useEditGuestDialog()
    const deleteDialog = useDeleteRegistrationDialog()
    const navigate = useNavigate()

    return <Loading request={registrationQuery} render={registration => {
        const canEdit = login.hasPermission(Permission.EditRegistrations, client.id, event.id) ||
            (login.hasPermission(Permission.EditEventContent, client.id, event.id) && registration.categoryType === RegistrationCategoryType.Speaker)
        return <Page
            title={casualName(registration)}
            actions={canEdit && <>
                <Button
                    startIcon={<Edit />}
                    children="Edit"
                    color="primary"
                    variant="contained"
                    onClick={() => registration.type === RegistrationType.Primary ?
                        editPrimaryRegistrationDialog.open({ registration }) :
                        editGuestDialog.open({ registration })} />
                {ifHas(Permission.ImportAndDeleteRegistrations, client.id, event.id, <Button
                    startIcon={<DeleteForever />}
                    children="Delete"
                    color="error"
                    onClick={() => deleteDialog.open({
                        registration,
                        onDelete: () => navigate(-1)
                })} />)}
            </>}
        >
            <Paper sx={{ ...fullSize }}>
                <RoutedTabs
                    tabs={[
                        {
                            label: "Registration details",
                            path: "registration",
                            element: <RegistrationDetails registration={registration} />,
                            scrollable: true,
                        },
                        {
                            label: "Profile",
                            path: "profile",
                            element: <ProfileDetails registration={registration} />,
                            scrollable: true,
                        },
                        {
                            label: "Activities",
                            path: "activities",
                            element: <Activities registration={registration} />,
                            hidden: !login.hasPermission(Permission.ViewActivities, client.id, event.id),
                        },
                        {
                            label: "Meeting availability",
                            path: "meeting-availability",
                            element: <EditableIndividualAvailability registration={registration} sx={{ pt: 2 }} />,
                            scrollable: true,
                        },
                        ...(registration.categoryType === RegistrationCategoryType.Speaker ?
                            [relatedContentTab(RelatedContentType.Speaker, registration.id)] :
                            []
                        ),
                    ]}
                />
            </Paper>
        </Page>;
    }
    } />
}


type ActivitiesProps = {
    registration: Registration
    sx?: SxProps
}

const Activities: FC<ActivitiesProps> = ({
    registration,
    sx,
}) => {
    const client = useClient()!
    const event = useEvent()!
    const usageQuery = backend.activities.useGetUserActivityUsage([client.id, event.id, registration.principal], {
        refetchInterval: 10_000
    })

    return <Loading request={usageQuery} sx={sx} render={usage =>
        <Box sx={{ ...fullSize, overflowY: "auto" }}>
            <UserTimeline usage={usage} sx={{
                maxWidth: 500,
                mx: "auto",
            }} />
        </Box>
    } />
}