import { RegistrationApprovalState, RegistrationStateBooleanCondition } from "@marketpartner/backend-api";
import { Check } from "@mui/icons-material";
import { Checkbox, FormControlLabel, FormGroup, FormLabel, Stack, Typography } from "@mui/material";
import { BooleanConditionProperties } from "src/common/boolean-conditions/boolean-condition-properties";
import { registrationStateTexts } from "src/registrations/registration-state-texts";


export const registrationStateConditionProperties: BooleanConditionProperties<RegistrationStateBooleanCondition> = {
    typeName: "Approval state",
    iconComponent: Check,
    getPrimaryText: () => "Approval state is",
    getSecondaryContent: ({ states }) => <Stack>
        {Object.entries(registrationStateTexts)
            .filter(([state]) => states.includes(state as any))
            .map(([state, text]) => <Typography key={state}>{text}</Typography>)
        }
    </Stack>,
    createDefault: () => ({
        type: "RegistrationState",
        states: [],
    }),
    isValid: ({ states }) => states.length > 0,
    renderForm: (condition, onChangeProperties) => <>
        <FormLabel component="legend">Registration is in state:</FormLabel>
        <FormGroup>
            {Object.entries(registrationStateTexts).map(([state, text]) => <FormControlLabel
                key={state}
                label={text}
                control={<Checkbox
                    checked={condition.states.includes(state as any)}
                    onChange={e => {
                        const states = e.target.checked ?
                            [...condition.states, state as RegistrationApprovalState] :
                            condition.states.filter(it => it !== state)
                        onChangeProperties({ states })
                    }}
                />}
            />)}
        </FormGroup>
    </>,
}