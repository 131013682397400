import { UseQueryResult } from "@tanstack/react-query"
import { ReactNode } from "react"
import { BarAndFlex } from "src/common/layout/BarAndFlex"
import { Bar } from "src/common/layout/Bar"
import { Spacer } from "src/common/layout/Spacer"
import { Loading } from "src/common/loading/Loading"
import { EventPartnerEditLink } from "src/content/event-partner/EventPartnerEditLink"


export type EventPartnerContentProps<Item> = {
    name: string
    link: string
    request: UseQueryResult<Item[]>
    render: (items: Item[]) => ReactNode
}

export const EventPartnerContent = <Item,>({
    name,
    link,
    request,
    render
}: EventPartnerContentProps<Item>) => {
    return <BarAndFlex barContent={
        <Bar p={2}>
            <Spacer />
            <EventPartnerEditLink href={link} hideIfDenied>
                Edit {name} in Event Partner
            </EventPartnerEditLink>
        </Bar>
    }>
        <Loading request={request} render={render} />
    </BarAndFlex>
}