import { ContentTypeId } from "@marketpartner/backend-api"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { useEvent } from "src/events/event-context"


export type ContentTypeNameTextProps = {
    typeId: ContentTypeId
    plural?: boolean
}

export const ContentTypeNameText: FC<ContentTypeNameTextProps> = ({
    typeId,
    plural = false,
}) => {
    const client = useClient()!
    const event = useEvent()!

    const type = backend.contentTypes.useGetTypes([client.id, event.id], {
        select: types => types.find(type => type.id === typeId) ?? null
    })
    return <>{plural ?
        type.data?.pluralName :
        type.data?.name
    }</>
}