import { TextField, TextFieldProps } from "@mui/material";
import { FC } from "react";


export type NumberTextFieldProps = Omit<TextFieldProps, "value" | "onChange"> & {
    value: number
    onChange: (value: number) => void
}

export const NumberTextField: FC<NumberTextFieldProps> = ({
    value,
    onChange,
    ...props
}) => {
    return <TextField
        value={`${value.toString()}`}
        onChange={e => {
            const stringValue = e.target.value
            if (stringValue === "") {
                onChange(0)
            }
            const newValue = parseInt(e.target.value)
            if (!isNaN(newValue)) {
                onChange(newValue)
            }
        }}
        {...props}
    />
}