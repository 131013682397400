import { LoadingButton } from "@mui/lab"
import { ButtonProps, DialogProps, IconButton } from "@mui/material"
import { FC, ReactNode, useState } from "react"
import { LockableDialog } from "src/common/dialogs/LockableDialog"

export type DialogButtonProps = Omit<ButtonProps, "onClick"> & {
    text?: string
    icon?: ReactNode
    onClose?: () => {}
    children: ReactNode
    dialogProps?: Omit<DialogProps, "open" | "onClose">
}

export const DialogButton: FC<DialogButtonProps> = ({
    text,
    icon,
    onClose,
    children,
    dialogProps,
    ...buttonProps
}) => {
    const [isOpen, setIsOpen] = useState(false)

    const button = text ?
        <LoadingButton 
            startIcon={icon}
            color="inherit"
            {...buttonProps}
            onClick={() => setIsOpen(true)}
        >{text}</LoadingButton> :
        <IconButton size="large" {...buttonProps} onClick={() => setIsOpen(true)}>{icon}</IconButton>
    
    function close() {
        setIsOpen(false)
        onClose?.()
    }
    
    return <>
        {button}
        <LockableDialog open={isOpen} onClose={close} {...dialogProps}>
            {children}
        </LockableDialog>
    </>
}