import { ScanningToken } from "@marketpartner/backend-api"
import { CopyButton, ExternalLink } from "@marketpartner/mp-common-mui"
import { LoadingButton } from "@mui/lab"
import { Stack, Typography } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { Bar } from "src/common/layout/Bar"
import { Spacer } from "src/common/layout/Spacer"
import { useEvent } from "src/events/event-context"
import { DeleteScanningTokenButton } from "src/scanning/DeleteScanningTokenButton"


export type ScanningUrlProps = {
    tokenDetails?: ScanningToken
}

export const ScanningUrl: FC<ScanningUrlProps> = ({
    tokenDetails
}) => {
    if (!tokenDetails) {
        return <NoToken />
    }

    return <Bar alignItems="flex-start">
        <Stack>
            <AccessToken token={tokenDetails.token} />
            <Url url={tokenDetails.scannerUrl} />
        </Stack>
        <Spacer />
        <DeleteScanningTokenButton />
    </Bar>
}

type AccessTokenProps = {
    token: string
}

const AccessToken: FC<AccessTokenProps> = ({
    token
}) => <Bar spacing={2}>
        <Typography>
            <b>Access token:</b> {token}
        </Typography>
        <CopyButton
            value={token}
            text="Copy token"
        />
    </Bar>

type UrlProps = {
    url: string
}

const Url: FC<UrlProps> = ({
    url
}) => <Bar spacing={2}>
        <ExternalLink
            href={url}
            children="Open scanner app"
        />
        <CopyButton
            value={url}
            text="Copy link"
        />
    </Bar>

const NoToken = () => {
    const client = useClient()!
    const event = useEvent()!

    const createMutation = backend.scanning.useCreateToken()

    return <Stack spacing={2} sx={{ alignItems: "center", my: 4, }}>
        <Typography variant="body1" color="text.secondary" sx={{ textAlign: "center" }}>
            Scanning not enabled. Generate an access token to enable scanning.
        </Typography>
        <LoadingButton
            onClick={() => createMutation.mutate([client.id, event.id])}
            loading={createMutation.isPending}
            variant="contained"
        >
            Generate token
        </LoadingButton>
    </Stack>
}