import { EventDetails } from '@marketpartner/backend-api'
import { createContext, FC, ReactNode, useContext } from 'react'
import { useLocation } from 'react-router'
import { useClientId } from 'src/clients/client-context'
import { backend } from 'src/common/api'
import { EnsureLoaded } from 'src/common/loading/EnsureLoaded'

export type EventContext = EventDetails & {
    reload: () => void
    isLoading: boolean
}

const eventIdContext = createContext<string | undefined>(undefined)
const eventContext = createContext<EventContext | undefined>(undefined)

export const useEventId = () => useContext(eventIdContext)
export const useEvent = () => useContext(eventContext)

export type EnsureEventContextLoadedProps = {
    children: ReactNode
}

export const EnsureEventContextLoaded: FC<EnsureEventContextLoadedProps> = ({
    children
}) => {
    const id = useEventId()
    const event = useEvent()

    return <EnsureLoaded
        isLoading={Boolean(id && !event?.id)}
        isReloading={Boolean(event?.isLoading)}
        children={children}
    />
}

export type EventContextProviderProps = {
    children: ReactNode
}

export const EventContextProvider: FC<EventContextProviderProps> = ({
    children
}) => {
    return <EventIdContextProvider>
        <EventContextProviderLoader children={children} />
    </EventIdContextProvider>
}

const EventContextProviderLoader: FC<EventContextProviderProps> = ({
    children
}) => {
    const clientId = useClientId()
    const eventId = useContext(eventIdContext)
    const { data, refetch, isLoading } = backend.events.useGetEvent([clientId!, eventId!], {
        enabled: Boolean(clientId && eventId)
    })

    if (!data) {
        return <>{children}</>
    }

    return <eventContext.Provider value={{
        ...data as EventDetails,
        reload: refetch,
        isLoading
    }} children={children} />
}

type EventIdContextProviderProps = {
    children: ReactNode
}

const EventIdContextProvider: FC<EventIdContextProviderProps> = ({
    children
}) => {
    const location = useLocation()
    const eventMatch = location.pathname.match(/\/events\/([^/]*)/)

    return <eventIdContext.Provider value={eventMatch?.[1]} children={children} />
}